var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RSelect from 'react-select';
import MenuList from './MenuList';
import Option from './Option';
import { Wrapper } from './Select.style';
var Select = React.memo(function (_a) {
    var id = _a.id, _b = _a.focusLabel, focusLabel = _b === void 0 ? '' : _b, selectProps = __rest(_a, ["id", "focusLabel"]);
    var _c = React.useState(focusLabel), ariaFocusMessage = _c[0], setAriaFocusMessage = _c[1];
    var _d = React.useState(false), keyDown = _d[0], setKeyDown = _d[1];
    var onFocus = function (_a) {
        var focused = _a.focused;
        var msg = "".concat(focusLabel, " ").concat(focused.label);
        setAriaFocusMessage(msg);
        return msg;
    };
    React.useEffect(function () {
        var handleMouseDown = function () {
            setKeyDown(false);
        };
        var handleKeyDown = function () {
            setKeyDown(true);
        };
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    return (React.createElement(Wrapper, { className: keyDown ? 'keyDown' : '' },
        React.createElement(RSelect, __assign({ "aria-label": ariaFocusMessage, ariaLiveMessages: {
                onFocus: onFocus,
            }, inputId: id, components: { Option: Option, MenuList: MenuList } }, selectProps))));
});
export default Select;
