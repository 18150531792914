import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RESTRICT_TO_VALUES, SPACE_ASSETS_FILTER } from 'consts';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import Item from './Item';
import { ErrorWrapper, ListWrapper, StyledFixedSizeList } from './styled';

const ITEM_HEIGHT = 32;
const ITEMS_COUNT = 6;

const FilterList = ({ query, onToggle, selected, spaceType, filterType }) => {
  const { t } = useTranslation();
  const { data, isError } = query({ spaceType });

  if (isError) {
    return (
      <ErrorWrapper data-testid="filter-error">
        {t('components.toggle.error')}
      </ErrorWrapper>
    );
  }

  return (
    <ListWrapper data-testid="filter-list" id={`${filterType}${spaceType}`}>
      <AutoSizer>
        {({ width, height }) => (
          <StyledFixedSizeList
            height={height}
            itemCount={data?.length ?? ITEMS_COUNT}
            itemSize={ITEM_HEIGHT}
            width={width}
            itemData={{ items: data, selected, onToggle }}
          >
            {Item}
          </StyledFixedSizeList>
        )}
      </AutoSizer>
    </ListWrapper>
  );
};

FilterList.propTypes = {
  selected: PropTypes.array.isRequired,
  query: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  spaceType: PropTypes.oneOf([
    RESTRICT_TO_VALUES.SPACE,
    RESTRICT_TO_VALUES.DESK,
  ]).isRequired,
  filterType: PropTypes.oneOf([...Object.values(SPACE_ASSETS_FILTER)])
    .isRequired,
};

export default memo(FilterList);
