var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Icon } from 'components/base';
import styled from 'styled-components';
export var List = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  margin: 0;\n  padding: 0;\n"], ["\n  list-style: none;\n  margin: 0;\n  padding: 0;\n"])));
export var DeskItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 13px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  height: 56px;\n  font-weight: 600;\n  color: ", ";\n  cursor: ", ";\n"], ["\n  padding: 0 13px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  height: 56px;\n  font-weight: 600;\n  color: ", ";\n  cursor: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color4;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, function (_a) {
    var clickable = _a.clickable;
    return (clickable ? 'pointer' : 'default');
});
export var DeskIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: rgb(223, 228, 234);\n  margin-right: 26px;\n"], ["\n  color: rgb(223, 228, 234);\n  margin-right: 26px;\n"])));
export var Summary = styled.summary(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  list-style: none;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  margin-bottom: 15px;\n\n  h2 {\n    margin-bottom: 0;\n  }\n\n  svg {\n    margin-left: 8px;\n    margin-top: 2px;\n    transition: transform 0.25s;\n  }\n  &::-webkit-details-marker {\n    display: none;\n  }\n"], ["\n  list-style: none;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  margin-bottom: 15px;\n\n  h2 {\n    margin-bottom: 0;\n  }\n\n  svg {\n    margin-left: 8px;\n    margin-top: 2px;\n    transition: transform 0.25s;\n  }\n  &::-webkit-details-marker {\n    display: none;\n  }\n"])));
export var Details = styled.details(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &[open] summary svg {\n    transform: rotate(90deg);\n  }\n"], ["\n  &[open] summary svg {\n    transform: rotate(90deg);\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
