import PropTypes from 'prop-types';

export const CONCIERGE_MENU_ANIMATION_DURATION = 350;
export const CONCIERGE_MENU_PREV_BUTTON_CLASSNAME = 'concierge-menu-prev';
export const CONCIERGE_MENU_NEXT_BUTTON_CLASSNAME = 'concierge-menu-next';
export const CONCIERGE_MENU_IMAGE_KEY = 'menu-item-image';
export const CONCIERGE_MENU_BREAKPOINTS = {
  HORIZONTAL: 1030,
  HORIZONTAL_WIDE: 1200,
};

export const menuItemPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onMenuItemClick: PropTypes.func,
  url: PropTypes.string,
  backgroundImage: PropTypes.shape({
    id: PropTypes.number,
    path: PropTypes.string,
    name: PropTypes.string,
  }),
});
