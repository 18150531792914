import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { StyledFixedList, StyledVariableList } from './styled';

const List = ({
  fixed,
  itemCount,
  isNextPageLoading,
  data,
  loadNextPage,
  renderLoader,
  renderItem,
  itemSize,
  listRef,
  hasNextPage,
}) => {
  const loadMoreItems =
    isNextPageLoading || !hasNextPage ? () => {} : loadNextPage;

  const isItemLoaded = (index) => index < data.length && data[index] !== null;

  const Item = ({ index, style }) =>
    data[index]
      ? renderItem({ item: data[index], index, style })
      : renderLoader({ index, style });

  const StyledList = fixed ? StyledFixedList : StyledVariableList;

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <AutoSizer ref={ref}>
          {({ height, width }) => (
            <StyledList
              innerElementType="ul"
              height={height}
              width={width}
              itemCount={itemCount}
              itemSize={itemSize}
              onItemsRendered={onItemsRendered}
              ref={listRef}
            >
              {Item}
            </StyledList>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

List.propTypes = {
  renderItem: PropTypes.func.isRequired,
  itemSize: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loadNextPage: PropTypes.func,
  itemCount: PropTypes.number,
  isNextPageLoading: PropTypes.bool,
  renderLoader: PropTypes.func,
  fixed: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  listRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};
List.defaultProps = {
  data: [],
  loadNextPage: () => {},
  itemCount: 10,
  isNextPageLoading: false,
  hasNextPage: false,
  renderLoader: () => {},
  fixed: true,
  listRef: null,
};

export default memo(List);
