var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { teamReservationsSelectors } from 'store';
import { MODALS } from 'consts';
import { useDialog, useEscapeListener, useModal } from 'utils';
import { isElectron } from 'utils/electron';
import { useConciergeMenuAllowed } from 'components/scenes/ConciergeMenu/hooks/useConciergeMenuAllowed';
import Header from './components/Header';
import Content from './components/Content';
import { useModalSearchQuery } from './hooks/useModalSearchQuery';
var MODALS_WITH_NO_HEADER = [
    MODALS.SOCIAL_DISTANCING,
    MODALS.BOOKING_LOCATION_UNAVAILABLE,
    MODALS.HEALTH_DECLARATION,
];
var Modals = function (_a) {
    var _b = _a.hasAgenda, hasAgenda = _b === void 0 ? true : _b, _c = _a.hasDateTime, hasDateTime = _c === void 0 ? true : _c, _d = _a.isKiosk, isKiosk = _d === void 0 ? isElectron() : _d, _e = _a.headerProps, headerProps = _e === void 0 ? {} : _e;
    var conciergeMenuAllowed = useConciergeMenuAllowed().conciergeMenuAllowed;
    var _f = useModal(), layout = _f.layout, content = _f.content, closeModal = _f.closeModal;
    var isDialogOpen = useDialog().isOpen;
    var isInDeskCheckInMode = useSelector(teamReservationsSelectors.getIsInDeskCheckInMode);
    var clearModal = useCallback(function () {
        // since dialog is always on top of everything
        // we don't want to clear the modal if it is opened
        if (isDialogOpen)
            return;
        closeModal();
    }, [closeModal, isDialogOpen]);
    useEscapeListener({ onEscapePress: clearModal });
    useModalSearchQuery();
    // we want to hide header if one of these modals are displayed
    var isHeaderVisible = !MODALS_WITH_NO_HEADER.includes(layout);
    return (React.createElement(React.Fragment, null,
        isHeaderVisible ? (React.createElement(Header, __assign({ hasAgendaButton: hasAgenda && !isInDeskCheckInMode, hasUserButton: !isInDeskCheckInMode, hasDateTime: hasDateTime, conciergeMenuAllowed: conciergeMenuAllowed, isDeskCheckInOn: isInDeskCheckInMode, isKiosk: isKiosk }, headerProps))) : null,
        React.createElement(Content, { layout: layout, content: content })));
};
export default memo(Modals);
