import styled from 'styled-components';

export const AcknowledgementContainer = styled.div`
  background-color: ${({ theme }) => theme.color6};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AcknowledgementButtonContainer = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: ${({ theme }) => (theme.isLayoutRTL ? 'row-reverse' : 'row')};
  align-items: center;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const AcknowledgementLearnMoreButton = styled.a`
  text-decoration: none;
  font-size: 17px;
  color: ${({ theme }) => theme.typo2};
  /* @noflip */
  margin-right: 40px;
  @media only screen and (max-width: 700px) {
    margin-top: 20px;
    order: 2;
    margin-right: 0;
  }

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const AcknowledgementTitle = styled.h3`
  margin-bottom: 30px;
  @media only screen and (max-width: 700px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

export const AcknowledgementMessageContainer = styled.div`
  max-width: 790px;
  max-height: 200px;
  overflow: auto;
  direction: ltr;
  scrollbar-color: ${({ theme }) => theme.color10}
    ${({ theme }) => theme.color3}; /* thumb and track color only for Firefox */
  scrollbar-width: thin; /* only for Firefox */

  @media (max-width: 1200px) and (min-width: 700px) {
    margin: 0 90px;
  }

  @media (max-width: 700px) and (min-width: 400px) {
    max-height: 360px;
  }

  @media only screen and (max-width: 400px) {
    max-height: 300px;
  }

  @media only screen and (max-width: 700px) {
    margin: 0 30px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    overflow-x: hidden;
    background: ${({ theme }) => theme.color3};
  }

  &::-webkit-scrollbar-track:horizontal {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.color10};
  }
`;
