import React, { memo, useEffect, useState } from 'react';
import { FocusId, useFocus } from 'utils/hooks';
import { DurationInput } from './DurationInput';
import { DurationSelectorContainer, Label } from './styled';
var DEFAULT_HOURS_FOR_OTHER = 1;
var DEFAULT_MINUTES_FOR_OTHER = 30;
var DurationSelector = memo(function (_a) {
    var onChange = _a.onChange;
    var _b = useState(DEFAULT_HOURS_FOR_OTHER), hours = _b[0], setHours = _b[1];
    var _c = useState(DEFAULT_MINUTES_FOR_OTHER), minutes = _c[0], setMinutes = _c[1];
    useEffect(function () {
        onChange(hours * 60 + minutes);
    }, [hours, minutes, onChange]);
    useFocus({
        focusOn: FocusId.DurationSelectorHourUpButton,
    });
    return (React.createElement(DurationSelectorContainer, { "data-testid": "DurationSelectorComponent" },
        React.createElement(DurationInput, { onChange: setHours, max: 12, selectorType: "h", value: hours }),
        React.createElement(Label, null, ":"),
        React.createElement(DurationInput, { onChange: setMinutes, step: 15, max: 45, selectorType: "m", value: minutes })));
});
export default DurationSelector;
