import { useEffect, useRef } from 'react';

/**
 * Run provided as first argument callback function in interval
 * with delay provided by second argument.
 * If delay is null, timeout will be reset and cleared
 * @param { Function } callback
 * @param { number | null } delay
 */
function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
