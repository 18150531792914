import { tenantSelectors } from 'store';
import { useSelector } from 'react-redux';
import { getModifiedTenantConfig, useBuilding, } from '@serraview/engage-shared/api';
/**
 * Returns building or global configuration
 * @param buildingId - if passed, building configuration will be based on passed buildingId
 *                     and not on globally set buildingId
 */
export var useConfig = function (_a) {
    var _b;
    var _c = _a === void 0 ? {} : _a, passedBuildingId = _c.buildingId;
    var tenantConfig = useSelector(tenantSelectors.getConfig);
    var currentLocation = useSelector(tenantSelectors.getCurrentLocation);
    var buildingId = passedBuildingId || ((_b = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.building) === null || _b === void 0 ? void 0 : _b.id);
    var buildingConfig = useBuilding({
        buildingId: buildingId,
    }).data;
    return getModifiedTenantConfig({
        buildingConfig: buildingConfig,
        tenantConfig: tenantConfig,
    });
};
