var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var LogoWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 150px;\n  display: flex;\n  align-items: center;\n  border-radius: 25px;\n\n  svg {\n    width: ", ";\n    height: ", ";\n    ", "\n"], ["\n  width: 100%;\n  max-width: 150px;\n  display: flex;\n  align-items: center;\n  border-radius: 25px;\n\n  svg {\n    width: ", ";\n    height: ", ";\n    ", "\n"])), function (_a) {
    var $width = _a.$width;
    return $width;
}, function (_a) {
    var $height = _a.$height;
    return $height;
}, function (_a) {
    var $fillColor = _a.$fillColor;
    return $fillColor &&
        "\n       * {\n         fill: ".concat($fillColor, "\n       }\n    ");
});
export var LogoFallback = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 22px;\n  font-weight: 700;\n  ", ";\n"], ["\n  font-size: 22px;\n  font-weight: 700;\n  ", ";\n"])), function (_a) {
    var $textColor = _a.$textColor;
    return $textColor && "color: ".concat($textColor, ";");
});
var templateObject_1, templateObject_2;
