var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Avatar, Capacity } from 'base-components';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { humanizeNumber, noop, replaceLineFeedWithSpace } from 'utils';
import { IconWrapper, ListItemOuterWrapper, ListItemWrapper, Subtitle, SubtitleWrapper, TextWrapper, Title, } from 'components/layouts/GlobalSearch/styled';
import SizeRecognitionWrapper from 'components/layouts/GlobalSearch/SizeRecognitionWrapper';
import { getBadgeA11yLabel } from './utils';
var TitleWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding-right: 10px;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding-right: 10px;\n"])));
var SpaceItem = function (_a) {
    var item = _a.item, _b = _a.showBadge, showBadge = _b === void 0 ? true : _b, style = _a.style, _c = _a.onPress, onPress = _c === void 0 ? noop : _c, _d = _a.onSizeFound, onSizeFound = _d === void 0 ? noop : _d, index = _a.index, as = _a.as;
    var _e = useTheme(), status2 = _e.status2, status3 = _e.status3;
    var t = useTranslation().t;
    var name = item.name, description = item.description, location = item.location, capacity = item.capacity, available = item.available;
    var level = location.level;
    var floorName = "".concat(humanizeNumber(level, t), " ").concat(t('common.floor'));
    var spaceName = replaceLineFeedWithSpace(name);
    var onItemPress = function () { return onPress(item); };
    var badgeVariant = showBadge ? 'dot' : 'standard';
    var badgeStyle = { backgroundColor: available ? status2 : status3 };
    var badgeA11yLabel = showBadge ? getBadgeA11yLabel({ t: t, available: available }) : '';
    return (React.createElement(ListItemOuterWrapper, __assign({}, (!!as && { as: as }), { "$style": style }),
        React.createElement(SizeRecognitionWrapper, { onSizeFound: onSizeFound, index: index },
            React.createElement(ListItemWrapper, { "aria-label": "".concat(t('accessibilityLabels.viewSpaceItem'), " ").concat(spaceName), onClick: onItemPress },
                React.createElement(Avatar, { icon: "space", size: "small", badgeVariant: badgeVariant, badgeStyle: badgeStyle, badgeA11yLabel: badgeA11yLabel, style: { background: 'transparent' } }),
                React.createElement(TextWrapper, null,
                    React.createElement(TitleWrapper, null,
                        React.createElement(Title, null, spaceName)),
                    React.createElement(SubtitleWrapper, null,
                        React.createElement(Subtitle, { className: "btn Subtitle" },
                            floorName,
                            React.createElement("span", null, description)))),
                React.createElement(IconWrapper, null, capacity ? React.createElement(Capacity, { capacity: capacity }) : null)))));
};
SpaceItem.getSize = function () { return 75; };
export default SpaceItem;
var templateObject_1;
