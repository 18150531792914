import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ViewerOptions } from 'floorplan/types';
import {
  ANIMATION_FINISH,
  CANVAS_CLICK_EVENT,
  CANVAS_DRAG_END_EVENT,
  CANVAS_DRAG_EVENT,
  CANVAS_PINCH_EVENT,
  CANVAS_PRESS_EVENT,
  CANVAS_RELEASE_EVENT,
  doubleClickTime,
  OPEN_EVENT,
  ROTATE_EVENT,
  ZOOM_EVENT,
} from 'floorplan/constants/OpenSeadragon';
import FESContext from 'floorplan/contexts/FESContext';
import OpenSeadragon from 'openseadragon';
import Api from 'floorplan/services/api';
import {
  FES_EVENT_SHOW_LABELS,
  FES_EVENTS,
} from 'floorplan/constants/FESEvents';
import {
  DEFAULT_ANGLE_COEFFICIENT,
  DEFAULT_FLOORPLAN_SCALE,
} from 'floorplan/constants/Floorplan';
import FloorplanScaleContext from 'floorplan/contexts/FloorplanScaleContext';
import FloorplanViewerContext from 'floorplan/contexts/FloorplanViewerContext';
import FloorplanRotationContext from 'floorplan/contexts/FloorplanRotationContext';
import { debounce } from 'floorplan/utils/debounce';
import ResetViewButton from 'floorplan/components/ResetViewButton/ResetViewButton';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const generateTransparentImage = () => {
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  const imgData = ctx.createImageData(1, 1);

  ctx.putImageData(imgData, 0, 0);
  // eslint-disable-next-line jsx-a11y/alt-text
  const img = <img src={c.toDataURL('image/png')} />;
  return img;
};

const isKeyboardRotationActivated = (event) =>
  window.navigator.platform.toUpperCase().indexOf('MAC') >= 0
    ? !!event.originalEvent.metaKey
    : !!event.originalEvent.ctrlKey;

// Used to determine if rotation or zoom was the user's intention
// Gesture track time is the amount of time the events can fire before deciding
const GESTURE_TRACK_TIME = 200;
// Gesture min distance is the minimum required distance of the pinch gesture before
// deciding this is a zoom instead of a rotate
const GESTURE_MIN_DISTANCE = 40;
const GESTURE_STATE = {
  NONE: 'none',
  ZOOM: 'zoom',
  ROTATION: 'rotation',
};

class FloorplanContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewer: null,
      floorplanScale: DEFAULT_FLOORPLAN_SCALE,
      rotation: 0,
      dragRotation: 0,
      startPoint: 0,
    };
  }

  /* lifecycle hooks */

  componentDidMount() {
    this.loadZoomDescriptor();
  }

  componentDidUpdate(prevProps) {
    const { floorId, viewerPanEnabled } = this.props;
    if (floorId !== prevProps.floorId) {
      this.loadZoomDescriptor();
    }
    if (this.state.viewer) {
      this.state.viewer.panHorizontal = viewerPanEnabled;
      this.state.viewer.panVertical = viewerPanEnabled;
    }
  }

  defaultOptions = {
    zoomPerClick: 1.8, // default: 2
    dblClickTimeThreshold: doubleClickTime, // default: 300
    visibilityRatio: 0.25, // default: 0.5
    maxZoomPixelRatio: 2, // default 1.1
    zoomPerScroll: 1.4, // default: 1.2
    springStiffness: 5.5, // default: 6.5
    minZoomImageRatio: 0.8, // default: 0.9
    clickDistThreshold: 0, // default: 5
    minPixelRatio: 0.5, // default: 0.5
    animationTime: 1.2, // default: 1.2
    preserveImageSizeOnResize: true, // requires autoResize=true
    autoResize: true,
    showNavigator: false,
    showNavigationControl: false,
    autoHideControls: false,
    constrainDuringPan: false,
    immediateRender: true,
    debugMode: false,
    defaultZoomLevel: 1,
    gestureSettingsTouch: {
      flickEnabled: true,
      flickMinSpeed: 60,
      flickMomentum: 0.02,
      pinchToZoom: false,
      pinchRotate: false,
    },
    gestureSettingsMouse: {
      clickToZoom: false,
      dblClickToZoom: true,
      pinchToZoom: false,
      pinchRotate: false,
    },
  };

  canvasGestureState = {
    startTimestamp: new Date(),
    startDistance: 0,
    isTracking: false,
    state: GESTURE_STATE.NONE,
  };

  updateViewerWithGestureState = () => {
    const { viewer } = this.state;
    const { state } = this.canvasGestureState;

    switch (state) {
      case GESTURE_STATE.ZOOM:
        viewer.gestureSettingsTouch.pinchRotate = false;
        viewer.gestureSettingsTouch.pinchToZoom = true;
        viewer.gestureSettingsMouse.pinchRotate = false;
        viewer.gestureSettingsMouse.pinchToZoom = true;
        break;
      case GESTURE_STATE.ROTATION:
        viewer.gestureSettingsTouch.pinchRotate = true;
        viewer.gestureSettingsTouch.pinchToZoom = true;
        viewer.gestureSettingsMouse.pinchRotate = true;
        viewer.gestureSettingsMouse.pinchToZoom = true;
        break;
      case GESTURE_STATE.NONE:
      default:
        viewer.gestureSettingsTouch.pinchRotate = false;
        viewer.gestureSettingsTouch.pinchToZoom = false;
        viewer.gestureSettingsMouse.pinchRotate = false;
        viewer.gestureSettingsMouse.pinchToZoom = false;
        break;
    }
  };

  /* event handlers / callbacks */

  showFloorplanLabels = (zoom) => {
    const { viewer } = this.state;
    if (viewer) {
      const maxZoom = viewer.viewport.getMaxZoom();
      const showLabels = zoom >= 0.7 * maxZoom; // 70% zoom
      this.context.trigger(FES_EVENT_SHOW_LABELS, { showLabels });
    }
  };

  handleCanvasPinched = (e) => {
    const { startTimestamp, startDistance, isTracking, state } =
      this.canvasGestureState;

    const timestamp = new Date();

    if (!isTracking) {
      this.canvasGestureState = {
        ...this.canvasGestureState,
        startTimestamp: timestamp,
        startDistance: e.distance,
        state: GESTURE_STATE.NONE,
        isTracking: true,
      };
    }

    if (
      isTracking &&
      state === GESTURE_STATE.NONE &&
      timestamp - startTimestamp >= GESTURE_TRACK_TIME
    ) {
      if (Math.abs(startDistance - e.distance) >= GESTURE_MIN_DISTANCE) {
        this.canvasGestureState.state = GESTURE_STATE.ZOOM;
      } else {
        this.canvasGestureState.state = GESTURE_STATE.ROTATION;
      }
    }

    this.updateViewerWithGestureState();
  };

  handleCanvasReleased = () => {
    const { isTracking } = this.canvasGestureState;
    if (isTracking) {
      this.canvasGestureState = {
        ...this.canvasGestureState,
        state: GESTURE_STATE.NONE,
        isTracking: false,
      };

      this.updateViewerWithGestureState();
    }
  };

  handleError = () => {
    this.context.send({
      type: FES_EVENTS.UNRECOVERABLE_ERROR,
      message: 'Unable to load floorplan',
    });
  };

  handleFloorplanOpened = () => {
    const { viewer } = this.state;
    if (viewer) {
      viewer.viewport.defaultZoomLevel = 0;
      viewer.viewport.zoomTo(viewer.viewport.getMinZoom());
    }
  };

  handleFloorplanRotateOnDrag = (event) => {
    if (!isKeyboardRotationActivated(event)) return;
    const { startPoint, viewer, dragRotation } = this.state;
    // eslint-disable-next-line no-param-reassign
    event.preventDefaultAction = true;
    viewer.viewport.setRotation(
      DEFAULT_ANGLE_COEFFICIENT * (event.position.x - startPoint) +
        dragRotation,
    );
  };

  handleFloorplanRotateOnDragEnd = () => {
    const { viewer } = this.state;
    // Saving previous rotation
    this.setState({
      dragRotation: viewer.viewport.getRotation(),
    });
  };

  setStartPointForDraggingFloorplan = (e) => {
    if (!isKeyboardRotationActivated(e)) return;
    this.setState({
      startPoint: e.position.x,
    });
  };

  handleCanvasClicked = (e) => {
    // This is a very bad hack to allow clicking on floorplan for iOS 12 safari
    // This goes around an issue that older safari's had where an svg elements children
    // Cannot have a click event, only touch events.
    // Flag variable is set through engage
    if (window.FLAG_ios12Compatibility) {
      if (e?.quick) {
        const event = new MouseEvent('click', e?.originalEvent);
        if (e?.originalEvent?.target) {
          e.originalEvent.target.dispatchEvent(event);
        }
      }
    }
  };

  handleFloorplanRotate = () => {
    const { viewer } = this.state;
    if (viewer) {
      const rotation = viewer.viewport.getRotation();
      this.setState({
        rotation: -rotation,
      });

      if (this.debouncedFesTrigger) {
        this.debouncedFesTrigger(rotation);
      }
    }
  };

  handleZoomDescriptorLoaded(zoomDescriptor) {
    if (
      zoomDescriptor &&
      zoomDescriptor.url &&
      zoomDescriptor.width &&
      zoomDescriptor.height
    ) {
      const { scale } = zoomDescriptor;
      this.setState({
        floorplanScale: scale,
      });
      this.initialiseViewer(zoomDescriptor);
      this.context.trigger(FES_EVENTS.SET_VIEWER, { viewer: zoomDescriptor });
    }
  }

  handleZoom = (args) => {
    const { zoom } = args;
    this.showFloorplanLabels(zoom);
  };

  resetFloorplanRotation = () => {
    this.context.trigger(FES_EVENTS.RESET_ORIENTATION);
    this.setState({ dragRotation: 0 });
  };

  showResetViewButton = () =>
    // Allow minimal rotation without showing reset view button
    this.state.rotation < -2 && this.state.rotation > -358;

  /* services */

  loadZoomDescriptor() {
    const { viewer } = this.state;
    this.destroyViewer(viewer);

    const { floorId } = this.props;

    const url = `/engage_api/v1/floors/${floorId}/zoom_descriptor`;

    Api.fetch({
      method: 'GET',
      url,
      useOnlyHostForURL: true,
    })
      .then((zoomDescriptor) => {
        this.handleZoomDescriptorLoaded(zoomDescriptor);
      })
      .catch(() =>
        Api.fetch({
          method: 'GET',
          url,
        }).then((zoomDescriptor) => {
          this.handleZoomDescriptorLoaded(zoomDescriptor);
        }),
      )
      .catch((error) => {
        this.handleError(error);
      });
  }

  /* helpers */

  addViewerHandlers(viewer) {
    if (viewer) {
      // eslint-disable-next-line func-names
      this.debouncedFesTrigger = debounce((rotation) => {
        this.context.send({
          type: FES_EVENTS.ROTATION_CHANGED,
          rotation,
        });
      }, 250);

      viewer.addHandler(CANVAS_PINCH_EVENT, this.handleCanvasPinched);
      viewer.addHandler(CANVAS_RELEASE_EVENT, this.handleCanvasReleased);
      viewer.addHandler(OPEN_EVENT, this.handleFloorplanOpened);
      viewer.addHandler(CANVAS_DRAG_EVENT, this.handleFloorplanRotateOnDrag);
      viewer.addHandler(
        CANVAS_PRESS_EVENT,
        this.setStartPointForDraggingFloorplan,
      );
      viewer.addHandler(
        CANVAS_DRAG_END_EVENT,
        this.handleFloorplanRotateOnDragEnd,
      );
      viewer.addHandler(ROTATE_EVENT, this.handleFloorplanRotate);

      const handleFirstAnimation = () => {
        viewer.addHandler(CANVAS_CLICK_EVENT, this.handleCanvasClicked);

        this.context.trigger(FES_EVENTS.FLOORPLAN_READY);
        this.context.send({
          type: FES_EVENTS.ROTATION_CHANGED,
          rotation: 0,
        });

        viewer.removeHandler(ANIMATION_FINISH, handleFirstAnimation);
      };
      viewer.addHandler(ANIMATION_FINISH, handleFirstAnimation);
      viewer.addHandler(ZOOM_EVENT, this.handleZoom);
    }
  }

  removeViewerHandlers(viewer) {
    if (viewer) {
      viewer.removeHandler(OPEN_EVENT, this.handleFloorplanOpened);
      viewer.removeHandler(CANVAS_CLICK_EVENT, this.handleCanvasClicked);
      viewer.removeHandler(ROTATE_EVENT, this.handleFloorplanRotate);
      viewer.removeHandler(CANVAS_PINCH_EVENT, this.handleCanvasPinched);
      viewer.removeHandler(CANVAS_DRAG_EVENT, this.handleFloorplanRotateOnDrag);
      viewer.removeHandler(
        CANVAS_PRESS_EVENT,
        this.setStartPointForDraggingFloorplan,
      );
      viewer.removeHandler(
        CANVAS_DRAG_END_EVENT,
        this.handleFloorplanRotateOnDragEnd,
      );
      viewer.removeHandler(CANVAS_RELEASE_EVENT, this.handleCanvasReleased);
      viewer.removeHandler(ZOOM_EVENT, this.handleZoom);
    }
  }

  destroyViewer(viewer) {
    if (viewer) {
      this.removeViewerHandlers(viewer);
      viewer.destroy();
    }
  }

  initialiseViewer(zoomDescriptor) {
    const { viewerOptions, floorId, hideZoomTiles } = this.props;
    const { width, height, url } = zoomDescriptor;
    const transparentImage = generateTransparentImage().props.src;

    const viewerParams = {
      id: `osd-viewer-${floorId}`,
      loadTilesWithAjax: true,
      tileSources: [
        {
          minLevel: 1,
          width,
          height,
          tileSize: 256,
          tileOverlap: 1,
          getTileUrl: (zoom, x, y) =>
            hideZoomTiles
              ? transparentImage
              : url.replace('{z}', zoom).replace('{x}', x).replace('{y}', y),
        },
      ],
    };

    // create the OpenSeadragon image viewer based on the options provided
    const options = {
      ...this.defaultOptions,
      ...viewerOptions,
      ...viewerParams,
      customOptions: zoomDescriptor,
    };

    const openSeadragonViewer = new OpenSeadragon.Viewer(options);

    const {
      userLocation: {
        building: { name: buildingName },
        floor: { name: floorName },
      },
      t,
    } = this.props;

    openSeadragonViewer.drawer.canvas.setAttribute(
      'aria-label',
      t('accessibilityLabels.canvasFloorplan', { buildingName, floorName }),
    );

    this.addViewerHandlers(openSeadragonViewer);
    this.setState({
      viewer: openSeadragonViewer,
    });

    this.context.setViewer(openSeadragonViewer);

    const { onViewerChanged } = this.props;
    onViewerChanged(openSeadragonViewer);
  }

  render() {
    const { floorId, children } = this.props;
    const { floorplanScale, viewer, rotation } = this.state;

    return (
      <div id={`osd-viewer-${floorId}`} className="floorplan">
        <FloorplanViewerContext.Provider value={viewer}>
          <FloorplanRotationContext.Provider value={rotation}>
            <FloorplanScaleContext.Provider value={floorplanScale}>
              {children}
            </FloorplanScaleContext.Provider>
          </FloorplanRotationContext.Provider>
        </FloorplanViewerContext.Provider>
        {this.showResetViewButton() && (
          <ResetViewButton resetRotation={this.resetFloorplanRotation} />
        )}
      </div>
    );
  }
}

FloorplanContainer.propTypes = {
  floorId: PropTypes.number.isRequired,
  viewerOptions: ViewerOptions,
  onViewerChanged: PropTypes.func,
  hideZoomTiles: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  viewerPanEnabled: PropTypes.bool,
  userLocation: PropTypes.shape({
    building: PropTypes.shape({ name: PropTypes.string }),
    floor: PropTypes.shape({ name: PropTypes.string }),
  }),
  t: PropTypes.func,
};

FloorplanContainer.defaultProps = {
  children: null,
  viewerOptions: {},
  hideZoomTiles: true,
  onViewerChanged: () => {},
  viewerPanEnabled: true,
  userLocation: {
    building: {
      name: '',
    },
    floor: {
      name: '',
    },
  },
  t: () => {},
};

FloorplanContainer.contextType = FESContext;

const mapStateToProps = (state) => ({
  userLocation: state.tenant.userLocation,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(FloorplanContainer);
