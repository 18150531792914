export var USER_CONFIG_KEYS = {
    SOCIAL_DISTANCING: 'sdi',
    LANDING_PAGE: 'lp',
    ACKNOWLEDGEMENT_POLICY: 'ap',
    UI_LANGUAGE: 'uil',
    ENABLE_VIEWS: 'ev',
};
export var VISIBILITY = {
    SHOW: 'show',
    HIDE: 'hide',
};
export var GLOBAL_SEARCH_RESULT_ID = 'id_global_search_result';
export var DATE_TIME_PICKER_VARIANT = {
    TIMELINE: 'timeline',
    INTERVAL_START: 'start',
    INTERVAL_END: 'end',
};
export var DOCUMENT_TITLE_SEPARATOR = ' | ';
export var DOCUMENT_TITLE_LEVEL = {
    PAGE: 0,
    MODAL: 1,
};
export var UNKNOWN_PERSON = 'Unknown Person';
