import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PERSON_QUERY_KEY, useAddToFavourite, useRemoveFromFavourite, } from '@serraview/engage-shared/api';
import { inAppNotificationsActions } from 'store';
import { SPACE_KEY } from 'api/queries';
import { useQueryClient } from 'react-query';
import { useFavouritesMutationErrorHandler } from 'utils';
export var useToggleFavouriteMenuItem = function (_a) {
    var isFavourite = _a.isFavourite, itemId = _a.itemId, itemType = _a.itemType, itemOriginalType = _a.itemOriginalType;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var addToFavourite = useAddToFavourite().mutate;
    var removeFromFavourite = useRemoveFromFavourite().mutate;
    var onError = useFavouritesMutationErrorHandler(itemOriginalType).onError;
    var sendSuccessNotification = function (isAdd) {
        var message = t("layout.notifications.".concat(isAdd ? 'addToFavouriteSuccess' : 'removeFromFavouriteSuccess'));
        dispatch(inAppNotificationsActions.addSuccessNotification({
            message: message,
        }));
    };
    var updateItemQueryData = function () {
        var itemQueryKey = itemType === 'person' ? [PERSON_QUERY_KEY, itemId] : [SPACE_KEY, itemId];
        queryClient.invalidateQueries(itemQueryKey);
    };
    var onSuccess = function (isAdd) {
        sendSuccessNotification(isAdd);
        updateItemQueryData();
    };
    if (!itemId)
        return null;
    var textTransId = isFavourite
        ? 'layout.drawer.menu.removeFromFavourites'
        : 'layout.drawer.menu.addToFavourites';
    var onPress = function () {
        if (isFavourite) {
            removeFromFavourite({ id: itemId, type: itemType }, {
                onError: onError,
                onSuccess: function () { return onSuccess(false); },
            });
            return;
        }
        addToFavourite({ id: itemId, type: itemType }, {
            onError: onError,
            onSuccess: function () { return onSuccess(true); },
        });
    };
    return {
        name: t(textTransId),
        onPress: onPress,
    };
};
