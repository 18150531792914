import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'base-components';
import { useRegionsBuildings, useRegionsChildren, } from '@serraview/engage-shared/api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import Building from './Building';
import { BuildingsListWrapper, RegionHeader, RegionWrapper } from './styled';
import BuildingListSkeleton from './BuildingListSkeleton';
import { ADD_REGION_TO_PATH, locationStateTypes, REMOVE_REGION_FROM_PATH, } from './useLocationSelector';
var Region = function (_a) {
    var region = _a.region, parentId = _a.parentId, locationState = _a.locationState, locationDispatch = _a.locationDispatch, _b = _a.subRegion, subRegion = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var regionPath = locationState.regionPath;
    var open = regionPath.includes(region.id);
    var _c = useRegionsChildren({
        tenantId: tenantId,
        regionId: region.id,
        shouldSortRegions: true,
        options: {
            enabled: open && !region.isLeafNode,
        },
    }), childRegions = _c.data, isChildRegionsLoading = _c.isLoading, isChildRegionsIdle = _c.isIdle, isChildRegionsError = _c.isError;
    var _d = useRegionsBuildings({
        tenantId: tenantId,
        regionId: region.id,
        sortBuildings: true,
        options: {
            enabled: open && isChildRegionsIdle,
        },
    }), buildings = _d.data, isBuildingsLoading = _d.isLoading, isBuildingsError = _d.isError;
    var renderRegionsOrBuildingsList = function () {
        if (!open) {
            return null;
        }
        if (isChildRegionsLoading || isBuildingsLoading) {
            return React.createElement(BuildingListSkeleton, null);
        }
        if (isChildRegionsError || isBuildingsError) {
            return (React.createElement(BuildingsListWrapper, null,
                React.createElement("div", { style: { maxWidth: 250 } }, t('layout.locationSelector.subregionOrBuildingError'))));
        }
        if (childRegions && (childRegions === null || childRegions === void 0 ? void 0 : childRegions.length) > 0) {
            return childRegions.map(function (childRegion) { return (React.createElement(Region, { key: childRegion.id, region: childRegion, parentId: region.id, locationState: locationState, locationDispatch: locationDispatch, subRegion: true })); });
        }
        if (!buildings)
            return null;
        return (React.createElement(BuildingsListWrapper, null, buildings.map(function (building) { return (React.createElement(Building, { key: building.id, building: building, locationState: locationState, locationDispatch: locationDispatch })); })));
    };
    var openRegion = function () {
        locationDispatch({
            type: ADD_REGION_TO_PATH,
            payload: { region: region, parentId: parentId },
        });
    };
    var closeRegion = function () {
        locationDispatch({
            type: REMOVE_REGION_FROM_PATH,
            payload: region,
        });
    };
    return (React.createElement(RegionWrapper, null,
        React.createElement(RegionHeader, { isActive: open, onClick: function () { return (open ? closeRegion() : openRegion()); } },
            React.createElement("div", { className: "region-name", "data-testid": "".concat(subRegion ? 'child' : 'top-level', "-region-name") }, region.name),
            React.createElement("div", { className: "region-icon" },
                React.createElement(Icon, { name: "chevron-right", size: 14 }))),
        renderRegionsOrBuildingsList()));
};
Region.propTypes = {
    region: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        isLeafNode: PropTypes.bool,
    }).isRequired,
    parentId: PropTypes.number,
    locationState: PropTypes.shape(locationStateTypes).isRequired,
    locationDispatch: PropTypes.func.isRequired,
};
Region.defaultProps = {
    parentId: null,
};
export default Region;
