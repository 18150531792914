import PropTypes from 'prop-types';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

const stylePropType = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
);

// function which performs no operation
const noop = () => {};

export { childrenPropTypes, stylePropType, noop };
