export default {
    AGENDA: 'agenda',
    AGENDA_ITEM: 'agenda_item',
    TEAM_AGENDA_ITEM: 'team_agenda_item',
    CONFIGURATION: 'configuration',
    USER_PROFILE: 'user_profile',
    IMAGE_VIEWER: 'image_viewer',
    DATE_TIME_PICKER: 'date_time_picker',
    SOCIAL_DISTANCING: 'social_distancing',
    BOOKING_LOCATION_UNAVAILABLE: 'booking_location_unavailable',
    HEALTH_DECLARATION: 'health_declaration',
};
