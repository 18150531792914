import React, { memo } from 'react';
import { Avatar, Icon } from 'base-components';
import { ATTENDEE_STATUS } from 'consts';
import { useTheme } from 'styled-components';
import { getAttendeeStatus } from './getAttendeeStatus';
import { BadgeWrapper, DetailsWrapper, OrganizerBadgeWrapper, Subtitle, SubtitleWrapper, Title, Wrapper, } from './styled';
var Attendee = function (_a) {
    var item = _a.item;
    var name = item.name, imageUrl = item.imageUrl, description = item.description, status = item.status, isOrganiser = item.isOrganiser;
    var theme = useTheme();
    var _b = getAttendeeStatus({
        status: status,
        theme: theme,
    }), iconName = _b.iconName, iconColor = _b.iconColor, avatarStatus = _b.avatarStatus, iconBackgroundColor = _b.iconBackgroundColor;
    var renderBadge = function () {
        if (isOrganiser) {
            return (React.createElement(OrganizerBadgeWrapper, null,
                React.createElement(Icon, { name: "crown", size: 14 })));
        }
        if (!status ||
            status === ATTENDEE_STATUS.NONE ||
            status === ATTENDEE_STATUS.INVITED) {
            return null;
        }
        return (React.createElement(BadgeWrapper, { iconBackgroundColor: iconBackgroundColor, iconColor: iconColor },
            React.createElement(Icon, { name: iconName, size: 8 })));
    };
    return (React.createElement(Wrapper, null,
        React.createElement(Avatar, { source: imageUrl, size: "small", badgeContent: renderBadge(), status: avatarStatus }),
        React.createElement(DetailsWrapper, null,
            React.createElement(Title, { "data-testid": "attendee-item-name" }, name),
            React.createElement(SubtitleWrapper, null,
                React.createElement(Subtitle, { className: "btn", "data-testid": "attendee-item-description" }, description)))));
};
export default memo(Attendee);
