import { createElement } from 'react';
import PropTypes from 'prop-types';
import { childrenPropTypes } from 'utils/propTypesHelpers';

const Text = (props) => {
  const { as, children, ...rest } = props;

  return createElement(as, rest, children);
};

Text.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div']),
  children: childrenPropTypes,
};

Text.defaultProps = {
  as: 'div',
  children: null,
};

export { Text };
