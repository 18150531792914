import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tenantActions } from 'store';
import { generateFloorPath } from 'router/utils';
import { CircularProgressBar } from 'components/base';
import { FloorName, FloorWrapper } from './styled';
var FloorItem = function (_a) {
    var floor = _a.floor, locationState = _a.locationState;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var activeBuilding = locationState.activeBuilding, activeFloor = locationState.activeFloor;
    var id = floor.id, name = floor.name, _b = floor.available_count, availableCount = _b === void 0 ? 0 : _b, bookableCount = floor.bookable_count;
    var setCurrentLocation = function () {
        dispatch(tenantActions.setCurrentLocation({
            building: {
                id: activeBuilding.id,
                name: activeBuilding.name,
                timeZone: activeBuilding.timeZone,
                latitude: activeBuilding.latitude,
                longitude: activeBuilding.longitude,
            },
            floor: {
                id: id,
                name: name,
            },
        }));
        navigate(generateFloorPath("".concat(id)));
    };
    return (React.createElement(FloorWrapper, { onClick: setCurrentLocation },
        React.createElement(FloorName, { isActive: (activeFloor === null || activeFloor === void 0 ? void 0 : activeFloor.id) === id, "data-testid": "floor-list-item" }, name),
        React.createElement(CircularProgressBar, { maxValue: bookableCount || 100, value: availableCount, text: bookableCount !== null && bookableCount !== void 0 ? bookableCount : 0 })));
};
export default FloorItem;
