import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHasChineseCharacters } from 'utils/hooks/useHasChineseCharacters';
import { DurationButtonInput, DurationButtonLabel } from './styled';

const DurationButton = ({
  text,
  selected,
  onClick,
  value,
  disabled,
  isOther,
}) => {
  const { t } = useTranslation();

  const hasChineseCharacters = useHasChineseCharacters();

  return (
    <DurationButtonLabel
      className={isOther && selected ? 'sr-only' : undefined}
      data-testid="duration-button-label"
      selected={selected}
      disabled={disabled}
      hasChineseCharacters={hasChineseCharacters}
    >
      <DurationButtonInput
        disabled={disabled}
        onClick={() => onClick(value)}
        type="radio"
        name={t('accessibilityLabels.bookingDuration')}
      />
      <span>{text}</span>
    </DurationButtonLabel>
  );
};

export { DurationButton };

DurationButton.propTypes = {
  text: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  isOther: PropTypes.bool,
};

DurationButton.defaultProps = {
  text: '',
  disabled: false,
  isOther: false,
};
