import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RESTRICT_TO_VALUES, SPACE_ASSETS_FILTER } from 'consts';
import { AutoRotateIcon, FilterSelectorButton as Button } from './styled';

const FilterSelectorButton = ({
  onClick,
  type,
  selectedType,
  children,
  spaceType,
}) => {
  const expanded = selectedType === type;
  return (
    <Button
      aria-expanded={expanded}
      aria-controls={expanded ? `${type}${spaceType}` : undefined}
      onClick={() => onClick(type)}
      data-testid="filter-selector-button"
      role="button"
      name={type}
    >
      <AutoRotateIcon
        active={selectedType === type}
        name="chevron-right"
        size={11}
        data-testid="icon-arrow"
      />
      <span>{children}</span>
    </Button>
  );
};

FilterSelectorButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf([...Object.values(SPACE_ASSETS_FILTER)]).isRequired,
  selectedType: PropTypes.oneOf([...Object.values(SPACE_ASSETS_FILTER)]),
  spaceType: PropTypes.oneOf([
    RESTRICT_TO_VALUES.SPACE,
    RESTRICT_TO_VALUES.DESK,
  ]).isRequired,
};

FilterSelectorButton.defaultProps = {
  selectedType: null,
};

export default memo(FilterSelectorButton);
