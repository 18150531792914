var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Icon } from 'components/base';
import { FocusId } from 'utils/hooks';
import { testProps } from 'utils';
import { DurationSelectorWrapper, NumberInput, SelectorButton } from './styled';
export function DurationInput(_a) {
    var _b = _a.min, min = _b === void 0 ? 0 : _b, _c = _a.step, step = _c === void 0 ? 1 : _c, max = _a.max, onChange = _a.onChange, value = _a.value, selectorType = _a.selectorType;
    var t = useTranslation().t;
    var gray5 = useTheme().gray5;
    var upButtonId = selectorType === 'h' ? FocusId.DurationSelectorHourUpButton : undefined;
    var handleIncreaseClick = function () {
        var isMax = value === max;
        // if reached max value – reset to min (creates loop effect)
        onChange(isMax ? +min : value + +step);
    };
    var handleDecreaseClick = function () {
        var isMin = value === min;
        // if reached max value – reset to min (creates loop effect)
        onChange(isMin ? max : value - +step);
    };
    return (React.createElement(DurationSelectorWrapper, { "data-testid": "DurationSelectorWrapper" },
        React.createElement(SelectorButton, __assign({ id: upButtonId, onClick: handleIncreaseClick }, testProps(t, "accessibilityLabels.durationSelector_".concat(selectorType, "_upward")).props),
            React.createElement(Icon, { name: "arrow-upward", size: 6, style: { color: gray5 } })),
        React.createElement(NumberInput, { type: "number", value: value.toString().padStart(2, '0'), min: min, max: max, step: step, readOnly: true }),
        React.createElement(SelectorButton, __assign({ onClick: handleDecreaseClick }, testProps(t, "accessibilityLabels.durationSelector_".concat(selectorType, "_downward")).props),
            React.createElement(Icon, { name: "arrow-downward", size: 6, style: { color: gray5 } }))));
}
