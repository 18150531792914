import styled from 'styled-components';
import { Z_INDEX } from 'consts';

export const Wrapper = styled.div`
  position: absolute;
  padding: 30px;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: ${Z_INDEX.FLOORPLAN_ERROR_PANE};
  @media only screen and (max-width: 1200px) {
    background-color: ${({ theme }) => theme.color4};
    border-radius: 6px;
  }
`;

export const ErrorLabel = styled.span`
  display: inline-block;
  margin-right: 5px;
`;

export const ErrorName = styled.span`
  display: inline-block;
  font-weight: bold;
`;
