import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyCard } from 'components/base/EmptyCard';
import { TeamMembersHeader } from './TeamMembersHeader';
export function TeamMembersError() {
    var t = useTranslation().t;
    return (React.createElement(React.Fragment, null,
        React.createElement(TeamMembersHeader, null),
        React.createElement(EmptyCard, { iconName: "warning" }, t('common.networkError'))));
}
