var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Z_INDEX } from 'consts';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  color: ", ";\n  flex-direction: column;\n  align-items: center;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n  z-index: ", ";\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 45px 48px;\n  box-shadow: 0px 40px 60px 0px #00000026;\n  width: 100%;\n  max-width: 425px;\n\n  @media screen and (min-width: 430px) {\n    min-width: 425px;\n  }\n"], ["\n  display: flex;\n  color: ", ";\n  flex-direction: column;\n  align-items: center;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n  z-index: ", ";\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 45px 48px;\n  box-shadow: 0px 40px 60px 0px #00000026;\n  width: 100%;\n  max-width: 425px;\n\n  @media screen and (min-width: 430px) {\n    min-width: 425px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, Z_INDEX.DIALOG);
export var Message = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 18px;\n  text-align: center;\n  margin-top: 12px;\n"], ["\n  font-size: 18px;\n  text-align: center;\n  margin-top: 12px;\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 1.8;\n  text-align: center;\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 1.8;\n  text-align: center;\n"])));
export var Button = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 30px;\n  font-size: 12px;\n  padding: 12px 20px;\n  background-color: ", ";\n  color: ", ";\n  min-width: 152px;\n  cursor: pointer;\n  transition: transform 0.25s;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n  }\n\n  &:active {\n    transform: scale(0.96);\n  }\n"], ["\n  border-radius: 30px;\n  font-size: 12px;\n  padding: 12px 20px;\n  background-color: ", ";\n  color: ", ";\n  min-width: 152px;\n  cursor: pointer;\n  transition: transform 0.25s;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n  }\n\n  &:active {\n    transform: scale(0.96);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color3;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var PrimaryButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color1;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
export var Actions = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 24px;\n  margin-top: 37px;\n\n  @media screen and (min-width: 430px) {\n    grid-template-columns: repeat(\n      ", ",\n      1fr\n    );\n  }\n"], ["\n  display: grid;\n  grid-gap: 24px;\n  margin-top: 37px;\n\n  @media screen and (min-width: 430px) {\n    grid-template-columns: repeat(\n      ", ",\n      1fr\n    );\n  }\n"])), function (_a) {
    var hasCancelButton = _a.hasCancelButton;
    return (hasCancelButton ? 2 : 1);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
