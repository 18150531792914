var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { testProps } from 'utils';
import { tenantSelectors } from 'store';
import { ContentOuterWrapper, ResizeHandle, Wrapper } from './styled';
import { AnimatedHeight } from './AnimatedHeight';
var Drawer = memo(function () {
    var t = useTranslation().t;
    var _a = useState(false), minimized = _a[0], setMinimized = _a[1];
    var _b = useSelector(tenantSelectors.getCurrentLocation), building = _b.building, floor = _b.floor;
    var hasLocation = !!(building && floor);
    var toggleMinimized = function () { return setMinimized(!minimized); };
    return (React.createElement(Wrapper, null,
        React.createElement(AnimatedHeight, null,
            React.createElement(ContentOuterWrapper, { minimized: minimized },
                hasLocation && (React.createElement(ResizeHandle, __assign({}, testProps(t, 'accessibilityLabels.resizeHandle').props, { onClick: toggleMinimized }))),
                React.createElement(Outlet, { context: { minimized: minimized, setMinimized: setMinimized } })))));
});
export var useDrawerContext = function () {
    return useOutletContext();
};
export default Drawer;
