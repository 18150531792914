import React, { useContext, useEffect } from 'react';
import useReservationFilters from 'utils/hooks/useReservationFilters';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { inAppNotificationsActions } from 'store';
import { useMakeReservation } from 'components/layouts/Reserve/Card/hooks/useMakeReservation';
import { isElectron } from 'utils/electron';
import { useDialog } from 'utils';
import { KioskMakeReservationDialog } from 'components/kiosk';
import { useTimer } from 'utils/hooks/useTimer';
import { getReserveBtnText } from '../../reserveCardHelpers';
import { ReserveCardContext } from '../ReserveCardContext';
import { ReserveButton as StyledReserveButton } from '../../styled';
export var ReserveButton = function () {
    var _a = useContext(ReserveCardContext), spaceItem = _a.spaceItem, reserveButtonRef = _a.reserveButtonRef, isOccupied = _a.isOccupied, durationObj = _a.durationObj, spaceStatusInfo = _a.spaceStatusInfo, reservee = _a.reservee, reserveDisabled = _a.reserveDisabled, bookingsAllowed = _a.bookingsAllowed, bookingDeniedMessage = _a.bookingDeniedMessage;
    var isKiosk = isElectron();
    var isDesk = spaceItem.isDesk, bookable = spaceItem.bookable;
    var duration = durationObj.duration, selectedDays = durationObj.selectedDays;
    var reservationFilter = useReservationFilters(isDesk);
    var enforceAllDayDeskReservations = reservationFilter.enforceAllDayDeskReservations;
    var t = useTranslation().t;
    var reduxDispatch = useDispatch();
    var _b = useDialog(), openDialog = _b.openDialog, closeDialog = _b.closeDialog;
    useEffect(function () {
        if (!(reservee === null || reservee === void 0 ? void 0 : reservee.id))
            return;
        if (!bookingsAllowed) {
            reduxDispatch(inAppNotificationsActions.addWarningNotification({
                message: bookingDeniedMessage,
            }));
        }
    }, [bookingDeniedMessage, bookingsAllowed, reduxDispatch, reservee]);
    var isMultiDayDeskReservation = isDesk && enforceAllDayDeskReservations;
    var daysAreNotSelected = !selectedDays.length && isMultiDayDeskReservation;
    var _c = useMakeReservation(), makeReservation = _c.makeReservation, isLoading = _c.isLoading;
    var onTimeOver = function () {
        makeReservation();
        closeDialog();
    };
    var _d = useTimer({ seconds: 5, onTimeOver: onTimeOver }), activateTimer = _d.activateTimer, cancelTimer = _d.cancelTimer;
    var onClickHandler = function () {
        if (isKiosk) {
            activateTimer();
            openDialog(React.createElement(KioskMakeReservationDialog, { spaceNames: [spaceItem.name], onOkPress: function () {
                    makeReservation();
                    closeDialog();
                    cancelTimer();
                }, onUndoPress: function () {
                    closeDialog();
                    cancelTimer();
                } }));
            return;
        }
        makeReservation();
    };
    var reserveBtnText = getReserveBtnText({ isOccupied: isOccupied, isLoading: isLoading, t: t });
    var reserveButtonDisabled = (!isDesk && !duration) ||
        (spaceStatusInfo === null || spaceStatusInfo === void 0 ? void 0 : spaceStatusInfo.disableReservation) ||
        reserveDisabled ||
        !bookable ||
        isLoading ||
        isOccupied ||
        daysAreNotSelected ||
        !bookingsAllowed;
    return (React.createElement(StyledReserveButton, { ref: reserveButtonRef, disabled: reserveButtonDisabled, onClick: onClickHandler, className: "ReserveButton" }, reserveBtnText));
};
