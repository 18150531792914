import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { getCountryColorByInitials, getInitials, } from '@serraview/engage-shared';
import { usePersonImage } from 'api/queries';
import { getPersonImageUrl, isDownloadPath, } from 'components/layouts/Person/Card/Details/utils';
import { CommonMarker, MARKER, } from 'components/floorplan/components/Marker/CommonMarker';
var IMAGE_SIZE = 2 * MARKER.RADIUS;
var IMAGE_URL_PATTERN_ID = "imageUrl";
export var PersonMarker = function (_a) {
    var _b;
    var space = _a.space, person = _a.person;
    var t = useTranslation().t;
    var _c = useTheme(), color9 = _c.color9, typo2 = _c.typo2;
    var personName = person.personName;
    var imageUrl = person.imageUrl;
    var isDownloadImage = isDownloadPath(imageUrl);
    var personImageQuery = usePersonImage({
        imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : '',
        enabled: isDownloadImage && !!imageUrl,
    });
    var personImageUrl = getPersonImageUrl({
        imageUrl: imageUrl,
        isDownloadImage: isDownloadImage,
        isSuccess: personImageQuery.isSuccess,
        fileImageUrl: (_b = personImageQuery === null || personImageQuery === void 0 ? void 0 : personImageQuery.data) !== null && _b !== void 0 ? _b : null,
    });
    var profanities = t('profanities', { returnObjects: true });
    var initials = getInitials(personName);
    var displayName = profanities.indexOf(initials) === -1 ? initials : initials[0];
    var _d = getCountryColorByInitials(initials), personBackgroundColor = _d.backgroundColor, personTextColor = _d.textColor;
    var textFillColor = personTextColor || typo2;
    var circleFill = personBackgroundColor || color9;
    if (imageUrl) {
        circleFill = "url(#".concat(IMAGE_URL_PATTERN_ID, ")");
    }
    return (React.createElement(CommonMarker, { space: space, circleFill: circleFill },
        !imageUrl && (React.createElement("text", { x: MARKER.CENTER, y: MARKER.CENTER, textAnchor: "middle", fill: textFillColor, dy: ".3em" }, displayName)),
        imageUrl && (React.createElement("defs", { id: "pattern_def" },
            React.createElement("pattern", { id: IMAGE_URL_PATTERN_ID, width: "100%", height: "100%" },
                React.createElement("image", { id: "person_image", href: personImageUrl, width: IMAGE_SIZE, height: IMAGE_SIZE, preserveAspectRatio: "none" }))))));
};
