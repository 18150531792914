import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { stylePropType } from 'utils';
import { ListItem } from './styled';

const ItemSkeleton = ({ index, style }) => (
  <ListItem key={index} style={style} data-testid="filter-item-skeleton">
    <Skeleton width={120} />
    <Skeleton width={40} height={24} style={{ borderRadius: 12 }} />
  </ListItem>
);

ItemSkeleton.propTypes = {
  style: stylePropType.isRequired,
  index: PropTypes.number.isRequired,
};

export default memo(ItemSkeleton);
