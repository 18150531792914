import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { formatLocalizedDate, TEAM_BOOKING_QUERY_KEY, DateFormat, } from '@serraview/engage-shared';
import { useTeamBookingDetails } from 'api/queries';
import { teamReservationsSelectors, tenantSelectors, userConfigSelectors, } from 'store';
import { useCurrentTimeZone } from './useCurrentTimeZone';
import { useLocalTimeFormatH12 } from './useLocalTimeFormatH12';
/**
 * Get team booking start date.
 *
 * If there is cached team booking date – it will be used.
 * Otherwise, team booking query will be executed.
 *
 * @returns Team booking `startDate`.
 */
export function useTeamBookingStartDate() {
    var _a;
    var queryClient = useQueryClient();
    var timeZone = useCurrentTimeZone();
    var isTimeFormatH12 = useLocalTimeFormatH12();
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var cachedTeamBookingDetails = queryClient.getQueryData([TEAM_BOOKING_QUERY_KEY, tenantId, "".concat(teamBookingId)]);
    var teamBookingDetails = useTeamBookingDetails(teamBookingId, {
        enabled: !cachedTeamBookingDetails,
    }).data;
    return formatLocalizedDate((_a = teamBookingDetails === null || teamBookingDetails === void 0 ? void 0 : teamBookingDetails.startDate) !== null && _a !== void 0 ? _a : new Date(), {
        timeZone: timeZone,
        locale: locale,
        format: DateFormat.weekdayShortWithMonthAndDayAndTime,
        hour12: isTimeFormatH12,
    });
}
