import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_SEGMENT } from 'consts';
import { useAppLocation } from 'router/hooks';
import { removeSegmentFromPath } from 'router/utils';
export function useCloseFilters() {
    var pathname = useAppLocation().pathname;
    var navigate = useNavigate();
    return useCallback(function () {
        navigate(removeSegmentFromPath(PATH_SEGMENT.FILTERS, pathname));
    }, [navigate, pathname]);
}
