var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useContext } from 'react';
import { PersonButton } from 'components/base';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_SEGMENT } from 'consts';
import { useRestrictTo } from 'utils';
import { PersonButtonWrapper } from '../../styled';
import { ReserveCardContext } from '../ReserveCardContext';
export var BookForPersonButton = function (_a) {
    var _b;
    var disabled = _a.disabled;
    var _c = useContext(ReserveCardContext), spaceItem = _c.spaceItem, user = _c.user, durationObj = _c.durationObj, reservee = _c.reservee, isOccupied = _c.isOccupied, isTeam = _c.isTeam;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var shouldDisableBookFor = disabled || (isTeam ? false : isOccupied);
    var bookFor = (reservee === null || reservee === void 0 ? void 0 : reservee.id) ? reservee : user;
    var restrictTo = useRestrictTo();
    var dataKey = (_b = restrictTo === null || restrictTo === void 0 ? void 0 : restrictTo.toLowerCase()) !== null && _b !== void 0 ? _b : '';
    var showReservees = useCallback(function () {
        var _a;
        navigate(PATH_SEGMENT.RESERVEE, {
            state: (_a = {},
                _a[dataKey] = __assign({ spaceItem: spaceItem, user: user }, durationObj),
                _a),
        });
    }, [dataKey, navigate, spaceItem, user, durationObj]);
    return (React.createElement(PersonButtonWrapper, null,
        React.createElement(PersonButton, __assign({ title: "".concat(t('layout.reserve.desk.bookFor'), " :") }, bookFor, { onClick: showReservees, disabled: shouldDisableBookFor }))));
};
