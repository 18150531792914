import React, { memo } from 'react';
import Attendee from './Attendee/Attendee';
import { AttendeeItemWrapper, AttendeeList } from './styled';
var AttendeesList = function (_a) {
    var _b = _a.attendees, attendees = _b === void 0 ? [] : _b, isDesk = _a.isDesk;
    if (isDesk) {
        return React.createElement(Attendee, { item: attendees[0] });
    }
    var renderItem = function (item, index) { return (React.createElement(AttendeeItemWrapper, { "data-testid": "AttendeesList-item-".concat(index), key: "".concat(item.name, "_").concat(index) },
        React.createElement(Attendee, { item: item }))); };
    return (React.createElement(AttendeeList, { "data-testid": "AttendeesList-wrapper" }, attendees.map(renderItem)));
};
export default memo(AttendeesList);
