import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { testProps, isInputValid, noop } from 'utils';
import { Icon } from '../Icons/Icon';
import {
  IconHit,
  Icons,
  IconWrapper,
  Number,
  NumberInputCounterElement,
  NumberInputCounterWrapper,
  SearchFilterInputLabel,
  Splitter,
  Wrapper,
} from './styled';

const NumberInputCounter = ({ label, value, onChange, maxValue, minValue }) => {
  const { t } = useTranslation();
  const inputNumberCounterRef = useRef(value);
  const validatedOnChange = (inputValue) => {
    if (isInputValid(inputValue, maxValue, minValue)) {
      onChange(inputValue);
    }
  };

  const onInputNumber = (e) => {
    inputNumberCounterRef.current.value = e.target.value;
    const number = parseInt(inputNumberCounterRef.current.value, 10);
    validatedOnChange(number);
  };

  const onFocusOutInput = () => {
    const inputValue = inputNumberCounterRef.current.value
      ? inputNumberCounterRef.current.value
      : 1;
    validatedOnChange(parseInt(inputValue, 10));
  };

  return (
    <Wrapper>
      <SearchFilterInputLabel for={label}>{label}</SearchFilterInputLabel>
      <NumberInputCounterElement>
        <NumberInputCounterWrapper>
          <Number
            data-testid="numberInputCounter"
            type="number"
            id={label}
            ref={inputNumberCounterRef}
            onChange={onInputNumber}
            onBlur={onFocusOutInput}
            min="1"
            value={value || 1}
            aria-label={value}
          />
          <Icons>
            <IconWrapper>
              <Icon name="arrow-upward" size={10} />
            </IconWrapper>
            <Splitter />
            <IconWrapper>
              <Icon name="arrow-downward" size={10} />
            </IconWrapper>
          </Icons>
        </NumberInputCounterWrapper>
        <IconHit
          style={{ top: 0 }}
          onClick={() => validatedOnChange(value + 1)}
          {...testProps(t, 'accessibilityLabels.counterIncrementValue').props}
        />
        <IconHit
          style={{ top: 20 }}
          onClick={() => validatedOnChange(value - 1)}
          {...testProps(t, 'accessibilityLabels.counterDecrementValue').props}
        />
      </NumberInputCounterElement>
    </Wrapper>
  );
};

NumberInputCounter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func,
};

NumberInputCounter.defaultProps = {
  label: '',
  value: 0,
  minValue: 0,
  maxValue: 999,
  onChange: noop,
};

export default memo(NumberInputCounter);
