var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { humanizeNumber, noop, replaceLineFeedWithSpace } from 'utils';
import { fontClassesHeights, fontTagHeights } from 'theme';
import { useTheme } from 'styled-components';
import AvatarImage from 'components/base/Avatar/AvatarImage';
import AvatarBadge from 'components/base/Avatar/AvatarBadge';
import { LIST_ITEM_PADDING_VERTICAL, ListItemOuterWrapper, ListItemWrapper, Subtitle, SubtitleWrapper, TextWrapper, Title, } from './styled';
import SizeRecognitionWrapper from './SizeRecognitionWrapper';
import { getBadgeA11yLabel } from './utils';
var isSearchResponse = function (item) {
    return item.name !== undefined;
};
var DeskItem = function (_a) {
    var _b;
    var item = _a.item, overrideText = _a.overrideText, _c = _a.onPress, onPress = _c === void 0 ? noop : _c, _d = _a.showBadge, showBadge = _d === void 0 ? true : _d, style = _a.style, _e = _a.onSizeFound, onSizeFound = _e === void 0 ? noop : _e, index = _a.index, as = _a.as, renderAvatar = _a.renderAvatar;
    var t = useTranslation().t;
    var _f = useTheme(), status2 = _f.status2, status3 = _f.status3;
    var level = (_b = item === null || item === void 0 ? void 0 : item.location) === null || _b === void 0 ? void 0 : _b.level;
    var floorName = level
        ? "".concat(humanizeNumber(level, t), " ").concat(t('common.floor'))
        : '';
    var name = '';
    var description = '';
    var available;
    if (isSearchResponse(item)) {
        name = item.name;
        description = item.description;
        available = !!item.available;
    }
    var title = replaceLineFeedWithSpace(name);
    var subtitle = description;
    var badgeVariant = showBadge ? 'dot' : 'standard';
    var badgeStyle = { backgroundColor: available ? status2 : status3 };
    var badgeA11yLabel = showBadge ? getBadgeA11yLabel({ t: t, available: available }) : '';
    if (overrideText) {
        title = overrideText.title;
        subtitle = overrideText.subtitle;
    }
    var avatarProps = {
        icon: 'desk',
        size: 'small',
        style: { background: 'transparent' },
    };
    var onItemPress = function () { return onPress(item); };
    return (React.createElement(ListItemOuterWrapper, __assign({}, (!!as && { as: as }), { "$style": style }),
        React.createElement(SizeRecognitionWrapper, { onSizeFound: onSizeFound, index: index },
            React.createElement(ListItemWrapper, { "aria-label": "".concat(t('accessibilityLabels.viewDeskItem'), " ").concat(title), onClick: onItemPress },
                React.createElement(AvatarBadge, { variant: badgeVariant, overlap: "circle", style: badgeStyle, badgeA11yLabel: badgeA11yLabel, size: "small" }, renderAvatar ? (renderAvatar(avatarProps)) : (React.createElement(AvatarImage, __assign({}, avatarProps)))),
                React.createElement(TextWrapper, null,
                    React.createElement(Title, null, title),
                    React.createElement(SubtitleWrapper, null,
                        React.createElement(Subtitle, { className: "btn Subtitle" },
                            floorName,
                            React.createElement("span", null, subtitle))))))));
};
var MemoDeskItem = memo(DeskItem);
MemoDeskItem.getSize = function () {
    return LIST_ITEM_PADDING_VERTICAL * 2 +
        // @ts-ignore
        fontTagHeights.h6.withMargins +
        // @ts-ignore
        fontClassesHeights.btn.withMargins;
};
export default MemoDeskItem;
