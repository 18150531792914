var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, inAppNotificationsActions, userConfigSelectors, } from 'store';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatLocalizedTime, getAppliedFilterDates, getDayStartTime, getDisabledDaysFromBookingLimit, RESTRICT_TO_VALUES, } from '@serraview/engage-shared';
import { DaysSelector, Icon } from 'base-components';
import { useConfig, useDatePicker, useModal, useReservationFilters, useRestrictTo, } from 'utils/hooks';
import { isSameDay } from 'date-fns';
import { DATE_TIME_PICKER_VARIANT, MODALS } from 'consts';
import { testProps, useLocalTimeFormatH12 } from 'utils';
import { isElectron } from 'utils/electron';
import { SearchFilterInputGroup, SearchFilterInputLabel, StyledDatePickerButton, StyledDatePickerButtonText, } from './styled';
var DateInterval = function () {
    var t = useTranslation().t;
    var openModal = useModal().openModal;
    var dispatch = useDispatch();
    var restrictTo = useRestrictTo();
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var _a = useConfig(), futureBookingsLimit = _a.futureBookingsLimit, kioskRestrictBookingToCurrentDay = _a.kioskRestrictBookingToCurrentDay;
    var isDesk = restrictTo === RESTRICT_TO_VALUES.DESK;
    var isKiosk = isElectron();
    var _b = useReservationFilters(isDesk, true), selectedDates = _b.selectedDates, enforceAllDayDeskReservations = _b.enforceAllDayDeskReservations;
    var setFilterValues = useCallback(function (values) {
        return dispatch(filtersActions.setFilterValues(__assign(__assign({}, values), { isDraft: true })));
    }, [dispatch]);
    var _c = useDatePicker({ isDraft: true }), dateStart = _c.dateStart, dateEnd = _c.dateEnd, allDay = _c.allDay, defaultEndDate = _c.defaultEndDate, defaultStartDate = _c.defaultStartDate, timeZone = _c.timeZone;
    var showDaysSelector = enforceAllDayDeskReservations && isDesk;
    var labelText = showDaysSelector
        ? t('layout.searchFilter.forLabel')
        : "".concat(t('common.to'), ":");
    useEffect(function () {
        if (isDesk && enforceAllDayDeskReservations && !allDay) {
            setFilterValues({ allDay: true });
        }
    }, [
        isDesk,
        allDay,
        dispatch,
        enforceAllDayDeskReservations,
        setFilterValues,
    ]);
    var setSelectedDates = useCallback(function (dates) { return setFilterValues({ selectedDates: dates }); }, [setFilterValues]);
    var _d = getAppliedFilterDates({
        dateStart: dateStart,
        dateEnd: dateEnd,
        setFilterValues: setFilterValues,
        defaultStartDate: defaultStartDate,
        defaultEndDate: defaultEndDate,
    }), dateStartApplied = _d.dateStartApplied, dateEndApplied = _d.dateEndApplied;
    var showBookingIsRestrictedToNowNotification = function () {
        dispatch(inAppNotificationsActions.addWarningNotification({
            message: t('layout.notifications.kioskCurrentDayBookingOnly'),
        }));
    };
    var toggleStartDatePicker = function () {
        if (isKiosk && kioskRestrictBookingToCurrentDay) {
            showBookingIsRestrictedToNowNotification();
            return;
        }
        openModal(MODALS.DATE_TIME_PICKER, {
            variant: DATE_TIME_PICKER_VARIANT.INTERVAL_START,
        });
    };
    var toggleEndDatePicker = function () {
        if (isKiosk && kioskRestrictBookingToCurrentDay) {
            showBookingIsRestrictedToNowNotification();
            return;
        }
        openModal(MODALS.DATE_TIME_PICKER, {
            variant: DATE_TIME_PICKER_VARIANT.INTERVAL_END,
            dateStart: dateStartApplied,
        });
    };
    // mark current(first) date as selected
    useEffect(function () {
        if (isDesk && enforceAllDayDeskReservations) {
            var dateStartLocalized = getDayStartTime({
                date: dateStart,
                timeZone: timeZone,
            });
            // prevents infinite loop
            if (isSameDay(selectedDates[0], dateStartLocalized)) {
                return;
            }
            setSelectedDates(__spreadArray([dateStartLocalized], selectedDates.slice(1), true));
        }
    }, [
        isDesk,
        dateStart,
        selectedDates,
        setSelectedDates,
        timeZone,
        enforceAllDayDeskReservations,
    ]);
    var displayTime = !enforceAllDayDeskReservations || !isDesk;
    var isTimeFormatH12 = useLocalTimeFormatH12();
    var dateText = function (date) {
        return "".concat(formatLocalizedTime(date, {
            timeZone: timeZone,
            locale: locale,
            format: displayTime
                ? DateFormat.weekdayShortWithMonthAndDayAndTime
                : DateFormat.weekdayShortWithMonthAndDay,
            hour12: isTimeFormatH12,
        }));
    };
    var disabledDaysFromLimit = getDisabledDaysFromBookingLimit({
        filterDateStart: dateStart || new Date(),
        futureBookingsLimit: futureBookingsLimit,
        timeZone: timeZone,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(SearchFilterInputGroup, null,
            React.createElement(SearchFilterInputLabel, null, t('layout.searchFilter.fromLabel')),
            React.createElement(StyledDatePickerButton
            // data-testid="searchFilterButtonFrom"
            , __assign({ 
                // data-testid="searchFilterButtonFrom"
                onClick: toggleStartDatePicker, isHighlighted: true }, testProps(t, 'accessibilityLabels.selectDateIntervalFrom').props),
                React.createElement(StyledDatePickerButtonText, { isHighlighted: true }, dateText(dateStartApplied)),
                React.createElement(Icon, { name: "calendar", size: 18 }))),
        React.createElement(SearchFilterInputGroup, null,
            React.createElement(SearchFilterInputLabel, null, labelText),
            showDaysSelector ? (React.createElement(DaysSelector, { selectedDays: selectedDates, disabledDays: disabledDaysFromLimit, onDaySelected: setSelectedDates, dateStart: dateStartApplied, timeZone: timeZone, currentDayAlwaysSelected: true })) : (React.createElement(React.Fragment, null,
                React.createElement(StyledDatePickerButton, __assign({ onClick: toggleEndDatePicker }, testProps(t, 'accessibilityLabels.selectDateIntervalTo')
                    .props),
                    React.createElement(StyledDatePickerButtonText, null, dateText(dateEndApplied)),
                    React.createElement(Icon, { name: "calendar", size: 18 })))))));
};
export default memo(DateInterval);
