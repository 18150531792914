var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { cancelTeamBooking, } from '@serraview/engage-shared';
import { tenantSelectors } from 'store';
import { DeleteButton } from 'components/layouts/Booking/Card/styled';
import { ConfirmationDialog, Icon } from 'components/base';
import { bookingDetailsButtonStatuses as STATUSES } from 'consts';
import { FocusId, useDialog } from 'utils';
export function Delete(_a) {
    var _this = this;
    var status = _a.status, meetingId = _a.meetingId, onBack = _a.onBack;
    var DISPLAY = STATUSES.DISPLAY, DISABLED = STATUSES.DISABLED;
    var _b = status, _c = DISPLAY, display = _b[_c], _d = DISABLED, disabled = _b[_d];
    var t = useTranslation().t;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var openDialog = useDialog().openDialog;
    var color1 = useTheme().color1;
    var onDeleteReservation = function () { return __awaiter(_this, void 0, void 0, function () {
        var handleErrorOccurred, confirmed, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    handleErrorOccurred = function () {
                        return openDialog(React.createElement(ConfirmationDialog, { title: t('layout.floorplanError.errorOccurred'), message: t('layout.reserve.team.errorOccurred'), key: "team-booking-delete-error-dialog", triggerId: FocusId.TeamBookingDelete, hasCancelButton: false }));
                    };
                    return [4 /*yield*/, openDialog(React.createElement(ConfirmationDialog, { title: "", message: t('layout.bookingCard.deleteEvent'), key: "team-booking-delete-confirmation-dialog", triggerId: FocusId.TeamBookingDelete, hasCancelButton: true }))];
                case 1:
                    confirmed = _a.sent();
                    if (!confirmed) return [3 /*break*/, 5];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, cancelTeamBooking({ tenantId: tenantId, id: meetingId })];
                case 3:
                    _a.sent();
                    onBack();
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    handleErrorOccurred();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return display ? (React.createElement(DeleteButton, { onClick: onDeleteReservation, text: t('common.delete'), disabled: disabled, opacity: disabled ? 0.4 : 1, color: color1, backgroundColor: "transparent" },
        React.createElement(Icon, { name: "bin", size: 13, style: { color: color1 } }))) : null;
}
