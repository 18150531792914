import log from 'log';
var spacesRegExp = /\s+/;
var extractViewBox = function (_a) {
    var viewBox = _a.viewBox;
    if (!viewBox) {
        log.error('[extractViewBox] No viewBox defined')();
        return null;
    }
    var params = (Array.isArray(viewBox) ? viewBox : viewBox.trim().split(spacesRegExp)).map(Number);
    if (params.length !== 4 || params.some(Number.isNaN)) {
        log.error("[extractViewBox] Invalid `viewBox` prop:".concat(viewBox))();
        return null;
    }
    return {
        minX: params[0],
        minY: params[1],
        vbWidth: params[2],
        vbHeight: params[3],
    };
};
/**
 * Calculate svg aspect ratio based on svg viewBox.
 * @param props of svg
 * @returns {{width: string, height: string, aspectRatio: number}}
 */
export var calculateAspectRatio = function (_a) {
    var viewBox = _a.viewBox;
    var width = 100;
    var height = 100;
    var aspectRatio = 1;
    var size = extractViewBox({ viewBox: viewBox });
    if (size) {
        var vbHeight = size.vbHeight, vbWidth = size.vbWidth;
        aspectRatio = vbWidth / vbHeight;
        if (aspectRatio <= 2) {
            // tends to square; set aspect ratio to 2, so it fits into the container
            aspectRatio = 2;
            // adjust the width based omn aspectRatio, so it is contained from left
            width /= aspectRatio;
        }
    }
    return {
        width: "".concat(width, "%"),
        height: "".concat(height, "%"),
        aspectRatio: aspectRatio,
    };
};
/**
 * Check that all elements are svg elements and have a tagName defined (e.g. path, g, etc.), prevents wrong svg definition.
 * @param parsedXML
 * @returns {boolean}
 */
var isValidSVG = function (parsedXML) {
    var _a, _b;
    return parsedXML &&
        ((_a = parsedXML.documentElement) === null || _a === void 0 ? void 0 : _a.nodeName.toLowerCase()) === 'svg' &&
        Array.from((_b = parsedXML.documentElement) === null || _b === void 0 ? void 0 : _b.children).filter(function (c) {
            var isSvgElement = c instanceof SVGElement;
            return !isSvgElement && !c.tagName;
        }).length === 0;
};
var getParseDom = function (xml) {
    var parser = new DOMParser();
    return parser.parseFromString(xml, 'image/svg+xml');
};
export var getParsedSVG = function (svgString) {
    var validSvg = {
        xml: null,
        parsedXML: null,
        props: {},
    };
    var parsedXML = getParseDom(svgString);
    if (isValidSVG(parsedXML)) {
        var svgEl_1 = parsedXML.querySelector('svg');
        var props_1 = {};
        if (svgEl_1) {
            svgEl_1.getAttributeNames().forEach(function (name) {
                var attribute = svgEl_1.getAttribute(name);
                if (attribute) {
                    props_1[name] = attribute;
                }
            });
            validSvg.xml = parsedXML.documentElement.outerHTML;
            validSvg.parsedXML = parsedXML;
            validSvg.props = props_1;
        }
    }
    else {
        log.error('[getParsedSVG] SVG element is not valid')();
    }
    return validSvg;
};
