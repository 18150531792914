import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  copyLocalizedTime,
  filterDateArrayByWeek,
  getClosestTime,
  QUARTER_HOUR_VALUES,
} from '@serraview/engage-shared';
import { mapObject } from 'utils/helpers';
import { GLOBAL_FILTER_TYPES, PERSON_OF_INTEREST, EVENTS_STATUS } from 'consts';
import logger from 'log/logger';

export const initialFilterValues = {
  [GLOBAL_FILTER_TYPES.DATE_START]: null,
  [GLOBAL_FILTER_TYPES.DATE_END]: null,
  [GLOBAL_FILTER_TYPES.ALL_DAY]: false,
  [GLOBAL_FILTER_TYPES.SELECTED_DATES]: [],
  [GLOBAL_FILTER_TYPES.SPACE_TYPES]: [],
  [GLOBAL_FILTER_TYPES.SPACE_ATTRIBUTES]: [],
  [GLOBAL_FILTER_TYPES.CAPACITY]: 1,
};

export const initialPersonFilterValues = {
  [PERSON_OF_INTEREST.MEDICAL_OFFICER]: false,
  [PERSON_OF_INTEREST.FIRE_OFFICER]: false,
};

/**
 * Returns cleared filters specified in filters param
 * @param state - Redux State
 * @param {string[]} filters - Filter names(keys) to clean
 * */
function getClearedFilterValues(state, filters) {
  const filtersWithoutStartDate = [
    GLOBAL_FILTER_TYPES.SPACE_ATTRIBUTES,
    GLOBAL_FILTER_TYPES.DATE_END,
    GLOBAL_FILTER_TYPES.SPACE_TYPES,
    GLOBAL_FILTER_TYPES.SELECTED_DATES,
    GLOBAL_FILTER_TYPES.ALL_DAY,
    GLOBAL_FILTER_TYPES.CAPACITY,
    PERSON_OF_INTEREST.MEDICAL_OFFICER,
    PERSON_OF_INTEREST.FIRE_OFFICER,
  ];

  const filtersToClear = filters ? filters : filtersWithoutStartDate;

  if (!Array.isArray(filtersToClear)) {
    logger.error('filtersToClear should be an array!');
    return state;
  }

  return {
    ...state,
    filterValues: mapObject(state.filterValues, (key, value) => {
      if (filtersToClear.includes(key)) {
        return initialFilterValues[key];
      }
      return value;
    }),
    personFilters: mapObject(state.personFilters, (key, value) => {
      if (filtersToClear.includes(key)) {
        return initialPersonFilterValues[key];
      }
      return value;
    }),
  };
}

/**
 * updates timezone for passed filter values
 * @param prevTimeZone
 * @param newTimeZone
 * @param filterValues
 * @returns new object with updated filter values
 */
function getUpdatedTimezoneFilterValues(
  { prevTimeZone, newTimeZone },
  filterValues,
) {
  const newFilterValues = { ...filterValues };

  if (!!prevTimeZone && !!newTimeZone) {
    const { dateStart, dateEnd, selectedDates } = filterValues;
    if (dateStart) {
      newFilterValues[GLOBAL_FILTER_TYPES.DATE_START] = copyLocalizedTime({
        date: dateStart,
        prevTimeZone,
        newTimeZone,
      });
    }
    if (dateEnd) {
      newFilterValues[GLOBAL_FILTER_TYPES.DATE_END] = copyLocalizedTime({
        date: dateEnd,
        prevTimeZone,
        newTimeZone,
      });
    }
    newFilterValues[GLOBAL_FILTER_TYPES.SELECTED_DATES] = selectedDates.map(
      (selectedDate) =>
        copyLocalizedTime({
          date: selectedDate,
          prevTimeZone,
          newTimeZone,
        }),
    );
  }

  return newFilterValues;
}

const initialState = {
  agendaEventsType: EVENTS_STATUS.ONLY_MINE,
  errorMessage: '',
  filterValues: { ...initialFilterValues },
  draftFilterValues: { ...initialFilterValues },
  personFilters: { ...initialPersonFilterValues },
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterValues(state, action) {
      // remove selected days which are not in the currently displayed week
      const { dateStart, selectedDates: payloadSelectedDates } = action.payload;

      let newSelectedDates = [
        ...(payloadSelectedDates || state.filterValues.selectedDates),
      ];

      if (dateStart) {
        newSelectedDates = filterDateArrayByWeek({
          datesArray: newSelectedDates,
          firstDay: dateStart,
        });
      }

      const { isDraft, ...payload } = action.payload;

      const key = isDraft ? 'draftFilterValues' : 'filterValues';

      return {
        ...state,
        [key]: {
          ...state[key],
          ...payload,
          selectedDates: newSelectedDates,
        },
      };
    },
    updateTimezone(state, action) {
      return {
        ...state,
        filterValues: getUpdatedTimezoneFilterValues(
          action.payload,
          state.filterValues,
        ),
        draftFilterValues: getUpdatedTimezoneFilterValues(
          action.payload,
          state.draftFilterValues,
        ),
      };
    },
    setPersonFilters(state, action) {
      return {
        ...state,
        personFilters: action.payload,
      };
    },
    setPersonOfInterest(state, action) {
      const personFilters = { ...state.personFilters };
      personFilters[action.payload.pofType] = action.payload.value;
      return { ...state, personFilters };
    },
    setAgendaEventsType(state, action) {
      state.agendaEventsType = action.payload;
    },
    saveFiltersDraft(state) {
      return {
        ...state,
        filterValues: { ...state.draftFilterValues },
      };
    },
    synchronizeFiltersDraft(state) {
      return {
        ...state,
        draftFilterValues: { ...state.filterValues },
      };
    },
    clearFilterValues(state, action) {
      return {
        ...getClearedFilterValues(state, action.payload),
      };
    },
    clearDraftFilterValues(state) {
      return {
        ...state,
        draftFilterValues: { ...initialState.draftFilterValues },
      };
    },
    clearDates(state, action) {
      const key = action.payload?.isDraft
        ? 'draftFilterValues'
        : 'filterValues';

      return {
        ...state,
        [key]: {
          ...state[key],
          dateStart: null,
          dateEnd: null,
          selectedDates: [],
        },
      };
    },
  },
});

const getFilters = (state) => state.filters;
const getDraftFilter = (state) => state.filters.draftFilterValues;
const getFilter = (state) => state.filters.filterValues;
const getPersonFilters = (state) => state.filters.personFilters;

const getDatePickerStartDate = createSelector(
  getFilter,
  (filter) =>
    filter.dateStart || getClosestTime(new Date(), QUARTER_HOUR_VALUES),
);

const getFilterDates = createSelector(
  getFilter,
  ({ dateStart, dateEnd, allDay, selectedDates }) => ({
    dateStart,
    dateEnd,
    allDay,
    selectedDates,
  }),
);

const getDraftFilterDates = createSelector(
  getDraftFilter,
  ({ dateStart, dateEnd, allDay, selectedDates }) => ({
    dateStart,
    dateEnd,
    allDay,
    selectedDates,
  }),
);

const getAgendaEventsType = createSelector(
  getFilters,
  (filters) => filters.agendaEventsType,
);

export const filtersSelectors = {
  getFilter,
  getDraftFilter,
  getFilters,
  getPersonFilters,
  getFilterDates,
  getDraftFilterDates,
  getDatePickerStartDate,
  getAgendaEventsType,
};

export default filters;
