import React, { useCallback, useContext } from 'react';
import { removeSegmentFromPath } from 'router/utils';
import { teamReservationsActions } from 'store';
import { PATH_SEGMENT } from 'consts';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppLocation } from 'router/hooks';
import { TeamButton } from '../../TeamButton';
import { ReserveCardContext } from '../ReserveCardContext';
export var BookForTeamButton = function () {
    var item = useContext(ReserveCardContext).item;
    var reduxDispatch = useDispatch();
    var navigate = useNavigate();
    var pathname = useAppLocation().pathname;
    var navigateToTeamMembersList = useCallback(function () {
        // Explicitly clear team reservation store to prevent useEffect from TeamReserveButton to navigate back to ReserveCard
        reduxDispatch(teamReservationsActions.clear());
        reduxDispatch(teamReservationsActions.clearSelection());
        // Show team members list
        navigate(removeSegmentFromPath(PATH_SEGMENT.RESERVE, pathname));
    }, [navigate, pathname, reduxDispatch]);
    return (React.createElement(TeamButton, { team: item, isBottomButton: false, onPress: navigateToTeamMembersList }));
};
