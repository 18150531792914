var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import { isTimeNow, RESTRICT_TO_VALUES, } from '@serraview/engage-shared';
import { useAppLocation } from 'router/hooks';
import { getLocalTimeZone, useConfig, useCurrentTimeZone, useFocusSpace, useRestrictTo, } from 'utils';
import { getIsBookingsAllowed, getLatestPresenceEvent, getSpaceStatusInfo, getSpaceSVLivePresenceEventOccupancy, getSVLivePresenceStatus, pipe, } from '@serraview/engage-shared/utils';
import useReservationFilters from 'utils/hooks/useReservationFilters';
import { useSelector } from 'react-redux';
import { filtersSelectors, tenantSelectors } from 'store';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../styled';
import { BookForPersonButton, BookForTeamButton, DaysSelector, DurationAndAvailability, ImageAndTitle, OccupantButton, ReserveButton, } from './Parts';
import { ReserveCardContext } from './ReserveCardContext';
import { updateDurationObjFromState } from './updateDurationObjFromState';
var defaultReservee = {};
export var ReserveCard = function (_a) {
    var _b, _c;
    var item = _a.item, spaceItem = _a.spaceItem, user = _a.user, reservee = _a.reservee, propBookings = _a.bookings;
    var _d = useState({
        duration: 0,
        allDayDuration: false,
        selectedDays: [],
    }), durationObj = _d[0], setDurationObj = _d[1];
    var _e = useState(false), reserveDisabled = _e[0], setReserveDisabled = _e[1];
    var reserveButtonRef = useRef(null);
    var hidePeopleData = useSelector(tenantSelectors.getKioskConfig).hidePeopleData;
    // use current building's timezone or user's timezone if that is not available
    var timeZone = (_b = useCurrentTimeZone()) !== null && _b !== void 0 ? _b : getLocalTimeZone();
    var spaceId = spaceItem.id, buildingId = spaceItem.buildingId, isDesk = spaceItem.isDesk, sensorStatus = spaceItem.sensorStatus, presenceEvents = spaceItem.presenceEvents, showDaysSelector = spaceItem.showDaysSelector, bookable = spaceItem.bookable, email = spaceItem.email;
    var isTeam = (item === null || item === void 0 ? void 0 : item.type) === RESTRICT_TO_VALUES.TEAM;
    var _f = propBookings || {}, occupant = _f.occupant, bookings = _f.bookings, isOccupied = _f.isOccupied, bookedIntervals = _f.bookedIntervals;
    var config = useConfig({ buildingId: buildingId });
    var hideCalendarReservationControls = config.hideCalendarReservationControls;
    var isCalendarReservationHidden = hideCalendarReservationControls && !isDesk;
    var reservationFilter = useReservationFilters(isDesk);
    var filterDateStart = reservationFilter.dateStart;
    var location = useAppLocation();
    var restrictTo = useRestrictTo();
    var dataKey = (_c = restrictTo === null || restrictTo === void 0 ? void 0 : restrictTo.toLowerCase()) !== null && _c !== void 0 ? _c : '';
    var state = useState(location.state || {})[0];
    var navigate = useNavigate();
    useEffect(function () {
        // preserve selected days when returning from Reservee List
        // @ts-ignore
        if (state.fromReserveeList) {
            updateDurationObjFromState({
                state: state,
                dataKey: dataKey,
                setDurationObj: setDurationObj,
            });
            // reset flag
            navigate('.', {
                replace: true,
                state: __assign(__assign({}, state), { fromReserveeList: false }),
            });
        }
        // update duration object from state only on mount
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);
    useFocusSpace(!isTeam && spaceId);
    var latestPresenceEventStatus = pipe(getLatestPresenceEvent, getSVLivePresenceStatus)(presenceEvents);
    // Days from Reservation assist
    var dateStart = useSelector(filtersSelectors.getFilterDates).dateStart;
    var isOccupiedBySvLive = getSpaceSVLivePresenceEventOccupancy(spaceItem).isOccupied;
    var latestPresenceEvent = getLatestPresenceEvent(spaceItem === null || spaceItem === void 0 ? void 0 : spaceItem.presenceEvents);
    var isNow = isTimeNow(filterDateStart);
    // display SVLive occupant only if timeline indicates 'Now'
    var svLiveOccupant = isOccupiedBySvLive && isNow ? latestPresenceEvent : null;
    var hasOccupant = !!occupant || !!svLiveOccupant;
    var spaceStatusInfo = getSpaceStatusInfo({
        filterDateStart: dateStart,
        svLiveStatus: latestPresenceEventStatus,
        sensorStatus: sensorStatus,
        isReserved: hasOccupant,
        isOccupied: !!isOccupied,
    });
    var _g = getIsBookingsAllowed({
        config: config,
        userData: reservee || user,
    }), bookingsAllowed = _g.bookingsAllowed, bookingDeniedMessage = _g.bookingDeniedMessage;
    var renderCard = function () {
        if (hasOccupant) {
            return (React.createElement(React.Fragment, null,
                React.createElement(ImageAndTitle, null),
                React.createElement(OccupantButton, { shouldShowGuest: hidePeopleData })));
        }
        var shouldHideReservationControls = isCalendarReservationHidden && !!email;
        if (!bookable ||
            !bookingsAllowed ||
            (spaceStatusInfo === null || spaceStatusInfo === void 0 ? void 0 : spaceStatusInfo.disableReservation) ||
            shouldHideReservationControls) {
            return (React.createElement(React.Fragment, null,
                React.createElement(ImageAndTitle, { "$marginBottom": 30 }),
                !showDaysSelector && shouldHideReservationControls && (React.createElement(DurationAndAvailability, { showButtons: false }))));
        }
        return isTeam ? (React.createElement(React.Fragment, null,
            React.createElement(ImageAndTitle, null),
            React.createElement(BookForTeamButton, null),
            showDaysSelector ? React.createElement(DaysSelector, null) : React.createElement(DurationAndAvailability, null),
            React.createElement(ReserveButton, null))) : (React.createElement(React.Fragment, null,
            React.createElement(ImageAndTitle, null),
            showDaysSelector ? React.createElement(DaysSelector, null) : React.createElement(DurationAndAvailability, null),
            React.createElement(BookForPersonButton, { disabled: hidePeopleData }),
            React.createElement(ReserveButton, null)));
    };
    var reserveeValue = reservee !== null && reservee !== void 0 ? reservee : defaultReservee;
    var contextValue = {
        spaceItem: spaceItem,
        item: item,
        reservee: reserveeValue,
        user: user,
        isTeam: isTeam,
        reserveDisabled: reserveDisabled,
        setReserveDisabled: setReserveDisabled,
        reserveButtonRef: reserveButtonRef,
        durationObj: durationObj,
        setDurationObj: setDurationObj,
        spaceStatusInfo: spaceStatusInfo,
        timeZone: timeZone,
        hasOccupant: hasOccupant,
        bookings: bookings,
        bookedIntervals: bookedIntervals,
        bookingsAllowed: bookingsAllowed,
        bookingDeniedMessage: bookingDeniedMessage,
        isOccupied: !!isOccupied,
        occupant: occupant,
        svLiveOccupant: svLiveOccupant,
    };
    return (React.createElement(Wrapper, null,
        React.createElement(ReserveCardContext.Provider, { value: contextValue }, renderCard())));
};
