import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { getTime } from '@serraview/engage-shared';
import { isToday } from 'date-fns';
import { AgendaEventWrapper } from 'layouts/Agenda/content/Items/styled';
import AgendaEvent from 'layouts/Agenda/content/Items/AgendaEvent';
import Separator from 'components/layouts/Agenda/content/Separator/Separator';

const AgendaItem = ({ item, onSelectEvent }) => {
  const { color9 } = useTheme();
  const { day, events } = item;

  const _isToday = isToday(day);
  // Cloning the array here is required to prevent the separator from being duplicated.
  // The Agenda list renders the items multiple times.
  const _dayEvents = _isToday ? [...events] : events;
  if (_isToday) {
    const _now = new Date();
    _dayEvents.push({
      type: 'separator',
      start: _now,
      end: _now,
    });
  }

  return (
    <AgendaEventWrapper>
      {_dayEvents
        .sort((a, b) => getTime(a.start) - getTime(b.start))
        .map((event) => {
          const { type, meetingId } = event;
          if (type === 'separator') {
            return <Separator key="separator" color={color9} />;
          }
          return (
            <AgendaEvent
              key={`${meetingId}`}
              event={event}
              onSelectEvent={onSelectEvent}
            />
          );
        })}
    </AgendaEventWrapper>
  );
};

AgendaItem.propTypes = {
  item: PropTypes.shape({
    day: PropTypes.instanceOf(Date),
    events: PropTypes.array,
  }).isRequired,
  onSelectEvent: PropTypes.func,
};
AgendaItem.defaultProps = {
  onSelectEvent: () => {},
};

export default memo(AgendaItem);
