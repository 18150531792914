import { useUser } from 'api/queries';
import LanguageSelect from 'components/layouts/LanguageSelect/LanguageSelect';
import React from 'react';
import { ToggleSwitch } from 'components/base/ToggleSwitch';
import { AppVersion, BackButton } from 'base-components';
import { useOptOut } from '@serraview/engage-shared/api';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tenantSelectors, userConfigActions, userConfigSelectors } from 'store';
import useConfigLanguages from 'utils/hooks/useConfigLanguages';
import { DOCUMENT_TITLE_LEVEL, MODALS } from 'consts';
import { FocusId, useConfig, useFocus, useModal, useSetPageTitle, } from 'utils/hooks';
import { Center, Footer, Header, Label, Left, Option, Options, Right, Row, Wrapper, } from './styled';
var Configuration = function () {
    var t = useTranslation().t;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var enableFloorplanViews = useSelector(userConfigSelectors.getEnableFloorplanViews);
    var dispatch = useDispatch();
    var openModal = useModal().openModal;
    var allowUsersToOptOut = useConfig().allowUsersToOptOut;
    var data = useUser().data;
    var person = data || {};
    var _a = useOptOut({
        tenantId: tenantId,
        personId: person === null || person === void 0 ? void 0 : person.personId,
    }), setOptOutChange = _a.setOptOutChange, isPersonOptOut = _a.isPersonOptOut, isPersonOptOutLoading = _a.isPersonOptOutLoading;
    var setEnableFloorplanViews = function (value) {
        dispatch(userConfigActions.setEnableFloorplanViews(value));
    };
    var instanceTitle = useSelector(tenantSelectors.getConfig).instanceTitle;
    var configurationLanguages = useConfigLanguages();
    var isDefaultOptionOnly = (configurationLanguages === null || configurationLanguages === void 0 ? void 0 : configurationLanguages.length) === 1;
    var titleStr = t('layout.configuration.title');
    var organizationStr = t('layout.configuration.organization');
    var outFromSearchStr = t('layout.configuration.outFromSearch');
    var languageLabel = t('layout.configuration.languageLabel');
    var enableFloorplanViewsStr = t('layout.configuration.enableViews');
    var handleBackButtonClick = function () {
        openModal(MODALS.USER_PROFILE);
    };
    useFocus({ returnFocusTo: FocusId.UserSettingsButton });
    useSetPageTitle(t('documentTitles.configuration'), DOCUMENT_TITLE_LEVEL.MODAL);
    return (React.createElement(Wrapper, null,
        React.createElement(Header, null,
            React.createElement(Left, null,
                React.createElement(BackButton, { onClick: handleBackButtonClick })),
            React.createElement(Center, null,
                React.createElement("h2", { className: "title" }, titleStr)),
            React.createElement(Right, null)),
        React.createElement(Options, null,
            React.createElement(Row, null,
                React.createElement(Option, null,
                    React.createElement(Label, null, organizationStr)),
                React.createElement(Option, null, instanceTitle)),
            allowUsersToOptOut && (React.createElement(Row, { "data-testid": "opt-out-from-search-switch" },
                React.createElement(Option, null,
                    React.createElement(Label, { htmlFor: "outFromSearch" }, outFromSearchStr)),
                React.createElement(Option, null,
                    React.createElement(ToggleSwitch, { id: "outFromSearch", disabled: isPersonOptOutLoading, checked: !!isPersonOptOut, onChange: setOptOutChange, ariaLabel: outFromSearchStr })))),
            !isDefaultOptionOnly && (React.createElement(Row, null,
                React.createElement(Option, null,
                    React.createElement(Label, null,
                        languageLabel,
                        ":")),
                React.createElement(Option, null,
                    React.createElement(LanguageSelect, { configurationLanguages: configurationLanguages })))),
            React.createElement(Row, { "data-testid": "enable-views-switch" },
                React.createElement(Option, null,
                    React.createElement(Label, { htmlFor: "enableFloorplanViews" }, enableFloorplanViewsStr)),
                React.createElement(Option, null,
                    React.createElement(ToggleSwitch, { id: "enableFloorplanViews", "data-testid": "enable-views-toggle", checked: enableFloorplanViews, onChange: setEnableFloorplanViews, ariaLabel: enableFloorplanViewsStr })))),
        React.createElement(Footer, null,
            React.createElement(AppVersion, null))));
};
export default Configuration;
