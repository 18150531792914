import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Z_INDEX } from 'consts';
import { Spinner } from '../Spinner/Spinner';

const Mask = ({ isLoading, ...rest }) => (
  <StyledMask data-testid="loading-mask" isLoading={isLoading} {...rest}>
    <Spinner />
  </StyledMask>
);

const StyledMask = styled.div`
  display: ${(props) => (props.isLoading ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: black;
  z-index: ${Z_INDEX.MASK};
`;

Mask.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export { Mask };
