var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PATH_SEGMENT } from 'consts';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { floorplanSelectors, teamReservationsActions, teamReservationsSelectors, } from 'store';
import { FlatList } from 'base-components';
import DeskItem from 'components/layouts/GlobalSearch/DeskItem';
import { GlobalSearchOuterWrapper, GlobalSearchResultsWrapper, } from 'components/layouts/GlobalSearch/styled';
import SelectableDeskItem from 'components/layouts/Team/TeamMembers/SelectableDeskItem';
import { DesksSelectionCardContainer, EmptyList, SelectionHint, } from './TeamMembers.style';
export function DeskSelectionCard(_a) {
    var team = _a.team;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var isDeskSelectionCompleted = useSelector(teamReservationsSelectors.getIsSelectionComplete);
    var selectedDesks = useSelector(teamReservationsSelectors.getTeamReservationSelectedDesks);
    var teamSize = useSelector(teamReservationsSelectors.getTeamSize);
    var nonOccupantDesks = useSelector(floorplanSelectors.getNonOccupantDesks);
    useEffect(function () {
        if (isDeskSelectionCompleted) {
            navigate(PATH_SEGMENT.RESERVE, {
                state: {
                    team: team,
                },
            });
        }
    }, [isDeskSelectionCompleted, team, dispatch, navigate]);
    var onDeskPress = useCallback(function (_a) {
        var id = _a.id;
        if (selectedDesks.length < teamSize) {
            var isSelected = selectedDesks.includes(id);
            var newSelectedDesks = isSelected
                ? selectedDesks.filter(function (deskId) { return deskId !== id; })
                : __spreadArray(__spreadArray([], selectedDesks, true), [id], false);
            dispatch(teamReservationsActions.addDesksToTeamReservation(newSelectedDesks));
        }
    }, [dispatch, selectedDesks, teamSize]);
    var renderItem = function (_a) {
        var item = _a.item, index = _a.index, style = _a.style;
        var isSelected = selectedDesks.includes(item.id);
        return (React.createElement(SelectableDeskItem, { isSelected: isSelected, onPress: onDeskPress, item: item, as: "div", showBadge: true, index: index, key: item.id, style: style }));
    };
    var renderEmptyList = function () { return (React.createElement(EmptyList, { "aria-live": "polite", "aria-atomic": "true" },
        React.createElement("p", null, t('layout.teamMembersList.emptyDesksList')))); };
    return (React.createElement(DesksSelectionCardContainer, null,
        React.createElement(SelectionHint, null, t('layout.reserve.team.deskSelectionHint')),
        React.createElement(GlobalSearchOuterWrapper, { id: "desk_selection_list_wrapper", "aria-live": "polite", "aria-atomic": "true" }, nonOccupantDesks && nonOccupantDesks.length > 0 ? (React.createElement(GlobalSearchResultsWrapper, null,
            React.createElement(FlatList, { itemCount: nonOccupantDesks.length, data: nonOccupantDesks, itemSize: DeskItem.getSize(), renderItem: renderItem }))) : (renderEmptyList()))));
}
