import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { notificationPropTypes, useCheckInStatus } from 'utils';
import Notification from './Notification/Notification';

const NotificationItem = ({ item, onSelectNotification }) => {
  const { title, localTime, location, isRead } = item;
  const { event } = item;
  const { isDesk } = event;
  const avatarIcon = isDesk ? 'desk' : 'space';

  const { status: checkInStatus, statusMessage } = useCheckInStatus({
    event,
  });

  const _onSelectNotification = (e) => {
    e.stopPropagation();
    // TODO analytics
    // Analytics.track('EVT-078');
    onSelectNotification({ item });
  };

  return (
    <Notification
      icon={avatarIcon}
      title={title}
      localTime={localTime}
      location={location}
      checkInStatus={checkInStatus}
      statusMessage={statusMessage}
      isRead={isRead}
      onDetails={_onSelectNotification}
    />
  );
};

NotificationItem.propTypes = {
  item: PropTypes.shape(notificationPropTypes),
  onSelectNotification: PropTypes.func,
};
NotificationItem.defaultProps = {
  item: notificationPropTypes.defaultProps,
  onSelectNotification: () => {},
};

export default memo(NotificationItem);
