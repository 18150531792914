import serraviewLogo from './SerraviewLogo.png';
import locationPointer from './location-pointer.svg';
import phoneMockup from './PhoneMockup.png';
import appStore from './AppStore.svg';
import googlePlay from './GooglePlay.svg';
import heatmap from './Heatmap.png';
import floorplan from './Floorplan.png';

export {
  serraviewLogo,
  locationPointer,
  phoneMockup,
  appStore,
  googlePlay,
  heatmap,
  floorplan,
};
