import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    searchValue: '',
};
var teamMembersSearch = createSlice({
    name: 'teamMembersSearch',
    initialState: initialState,
    reducers: {
        setSearchValue: function (state, action) {
            state.searchValue = action.payload;
        },
        clearSearchValue: function () { return initialState; },
    },
});
export var teamMembersSearchSelectors = {
    // @ts-ignore
    getTeamMembersSearchValue: function (state) { return state.teamMembersSearch.searchValue; },
};
export default teamMembersSearch;
