var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 25px 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 25px 0;\n"])));
export var AvatarWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n"], ["\n  margin: 0 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n"])));
export var NameWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 10px 0;\n  padding: 0 26px;\n"], ["\n  margin: 10px 0;\n  padding: 0 26px;\n"])));
export var Name = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 20px;\n  margin-top: 0;\n  margin-bottom: 0;\n  color: ", ";\n  font-weight: 600;\n"], ["\n  font-size: 20px;\n  margin-top: 0;\n  margin-bottom: 0;\n  color: ", ";\n  font-weight: 600;\n"])), function (props) { return props.theme.typo2; });
export var Row = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var Text = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 500;\n  color: ", ";\n  letter-spacing: 1px;\n  margin-top: 0;\n  margin-bottom: 0;\n  padding: 0 20px;\n  opacity: 0.8;\n"], ["\n  font-weight: 500;\n  color: ", ";\n  letter-spacing: 1px;\n  margin-top: 0;\n  margin-bottom: 0;\n  padding: 0 20px;\n  opacity: 0.8;\n"])), function (props) { return props.theme.color2; });
export var TeamText = styled(Text)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-align: center;\n  font-size: 15px;\n"], ["\n  text-align: center;\n  font-size: 15px;\n"])));
export var Location = styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  margin: 0 0 10px 0;\n\n  width: 350px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: center;\n"], ["\n  color: ", ";\n  margin: 0 0 10px 0;\n\n  width: 350px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: center;\n"])), function (props) { return props.theme.color1; });
/*
export const Separator = styled.div`
  width: ${fontTagHeights.h6.clean}px;
  height: 2px;
  background-color: ${(props) => `${props.theme.color2}1A`}; // alpha 10%
  transform: rotate(90deg);
`;

 */
export var OfficerRow = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 6px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 6px;\n"])));
export var OfficerIcon = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  width: 22px;\n  height: 22px;\n  border-radius: 22px;\n  background-color: #eaeaea;\n  color: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  width: 22px;\n  height: 22px;\n  border-radius: 22px;\n  background-color: #eaeaea;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var Officer = styled(Text)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  margin: 0 0 0 10px;\n"], ["\n  color: ", ";\n  margin: 0 0 0 10px;\n"])), function (props) { return props.theme.color9; });
export var MoreLocations = styled.button(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  border: none;\n  background-color: ", ";\n  color: #fff;\n  padding: 7px 12px;\n  border-radius: 18px;\n  font-size: 14px;\n  font-weight: 600;\n  cursor: pointer;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  border: none;\n  background-color: ", ";\n  color: #fff;\n  padding: 7px 12px;\n  border-radius: 18px;\n  font-size: 14px;\n  font-weight: 600;\n  cursor: pointer;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (props) { return props.theme.color9; });
export var LocationWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  margin-top: 20px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  margin-top: 20px;\n"])));
export var SensorStatusIconWrapper = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: absolute;\n  top: 3px;\n  left: 5px;\n  border: 3px solid #fff;\n  border-radius: 22px;\n  width: 28px;\n  height: 28px;\n"], ["\n  position: absolute;\n  top: 3px;\n  left: 5px;\n  border: 3px solid #fff;\n  border-radius: 22px;\n  width: 28px;\n  height: 28px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
