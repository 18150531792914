import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { floorplanActions } from 'store';
/**
 * Focuses space on a floor plan view.
 * @param spaceId Space id or false if space shouldn't be focused.
 */
export function useFocusSpace(spaceId) {
    var dispatch = useDispatch();
    useEffect(function () {
        if (spaceId) {
            dispatch(floorplanActions.setFocusedSpace({ id: Number(spaceId) }));
        }
    }, [dispatch, spaceId]);
}
