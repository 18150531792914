// https://openseadragon.github.io/docs/openseadragon.js.html#line14428
export const ANIMATION_EVENT = 'animation';
export const ANIMATION_FINISH = 'animation-finish';
export const RESIZE_EVENT = 'resize';
export const OPEN_EVENT = 'open';
export const ROTATE_EVENT = 'rotate';
export const CANVAS_CLICK_EVENT = 'canvas-click';
export const CANVAS_DRAG_EVENT = 'canvas-drag';
export const CANVAS_KEY = 'canvas-key';
export const CANVAS_DRAG_END_EVENT = 'canvas-drag-end';
export const CANVAS_PRESS_EVENT = 'canvas-press';
export const CANVAS_ENTER = 'canvas-enter';
export const CANVAS_EXIT = 'canvas-exit';
export const CANVAS_RELEASE_EVENT = 'canvas-release';
export const CANVAS_PINCH_EVENT = 'canvas-pinch';
export const CANVAS_SCROLL_EVENT = 'canvas-scroll';
export const ZOOM_EVENT = 'zoom';
export const doubleClickTime = 200;
