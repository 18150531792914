import React, { memo } from 'react';
import { useConfig, useSetPageTitle } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { navigationActions } from 'store';
import { ClientLogo, MarkDownText } from 'components/base';
import { useTheme } from 'styled-components';
import { Container, LogoContainer, Title, MessageContainer, ButtonContainer, ConfirmButtonWrapper, ConfirmButton, ConfirmButtonText, CancelButton, } from './styled';
var HealthDeclaration = function (_a) {
    var _b = _a.onConfirm, onConfirmCallback = _b === void 0 ? function () { } : _b;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var healthDeclaration = useConfig().healthDeclaration;
    var title = t('layout.healthDeclaration.title');
    var cancelText = t('layout.healthDeclaration.cancel');
    var confirmText = t('layout.healthDeclaration.confirm');
    useSetPageTitle(t('documentTitles.healthDeclaration'));
    var onCancel = function () {
        dispatch(navigationActions.clearModal());
    };
    var onConfirm = function () {
        onConfirmCallback();
        dispatch(navigationActions.clearModal());
    };
    // @ts-ignore
    var typo2 = useTheme().typo2;
    var messageStyle = {
        fontSize: '16px',
        color: typo2,
    };
    return (React.createElement(Container, { "data-testid": "health-declaration-container" },
        React.createElement(LogoContainer, null,
            React.createElement(ClientLogo, null)),
        React.createElement(Title, null, title),
        React.createElement(MessageContainer, null,
            React.createElement(MarkDownText, { style: messageStyle }, healthDeclaration)),
        React.createElement(ButtonContainer, null,
            React.createElement(CancelButton, { "data-testid": "health-declaration-cancel-button", onClick: onCancel }, cancelText),
            React.createElement(ConfirmButtonWrapper, null,
                React.createElement(ConfirmButton, { "data-testid": "health-declaration-confirm-button", onClick: onConfirm },
                    React.createElement(ConfirmButtonText, null, confirmText))))));
};
export default memo(HealthDeclaration);
