import styled from 'styled-components';

const backgroundColorOpacity = '0.3';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.color2}
    ${({ bgImage }) => `url("${bgImage}") center center no-repeat`};
  background-size: cover;
  color: white;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background: rgba(0, 0, 0, ${backgroundColorOpacity});
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 0 40px;
  z-index: 1;
  ${({ isKiosk }) => (isKiosk ? 'margin-bottom: 15%' : '')};
  @media screen and (min-width: 768px) {
    padding: 0 70px;
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 700;
  max-width: 600px;

  @media screen and (min-width: 768px) {
    font-size: 80px;
  }
`;

export const Message = styled.p`
  font-size: 18px;
  line-height: 25px;
  max-width: 600px;

  @media screen and (min-width: 768px) {
    font-size: 26px;
    line-height: 45px;
  }
`;

export const Button = styled.button`
  display: inline-flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.color9};
  color: ${({ theme }) => theme.typo1};
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  margin-top: 60px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 36px 66px;
  justify-content: center;
  cursor: pointer;

  &:active {
    opacity: 0.85;
  }
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }

  svg {
    margin-top: 1px;
  }

  @media screen and (min-width: 768px) {
    width: auto;
    padding: 19px 55px;
    border-radius: 50px;
    justify-content: flex-start;
    position: static;
    box-shadow: 0 20px 20px rgba(84, 96, 111, 0.2);
  }
`;

export const ButtonText = styled.span`
  margin-right: 12px;
`;
