import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
var useGenerateCurrentLocationPath = function () {
    var floor = useSelector(tenantSelectors.getCurrentLocation).floor;
    if (floor) {
        return "/floor/".concat(floor.id);
    }
    return null;
};
export default useGenerateCurrentLocationPath;
