var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
var Title = styled.h3(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 14px;\n  padding: 20px 20px 0px 20px;\n  font-weight: bold;\n  line-height: 20px;\n  color: ", ";\n  opacity: 0.5;\n"], ["\n  font-size: 14px;\n  padding: 20px 20px 0px 20px;\n  font-weight: bold;\n  line-height: 20px;\n  color: ", ";\n  opacity: 0.5;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
var TeamsSectionHeader = function (_a) {
    var title = _a.title, style = _a.style;
    var t = useTranslation().t;
    return React.createElement(Title, { style: style }, t(title));
};
TeamsSectionHeader.getSize = function () { return 50; };
export default TeamsSectionHeader;
var templateObject_1;
