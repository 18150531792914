var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useTeamBookingDetails as useTeamBookingDetailsQuery, useRefreshTeamBooking as useRefreshTeamBookingHook, } from '@serraview/engage-shared/api';
import { useSelector } from 'react-redux';
import { useUser } from 'api/queries/useUser';
import { tenantSelectors } from 'store';
var QUERY_STALE_TIME = 1000 * 60 * 10; // 10 minutes
export var useTeamBookingDetails = function (id, options) {
    var _a;
    if (options === void 0) { options = {}; }
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var user = useUser();
    var userData = (user === null || user === void 0 ? void 0 : user.data) || {};
    var opts = __assign(__assign({}, options), { enabled: ((_a = options.enabled) !== null && _a !== void 0 ? _a : true) && !!id && user.isSuccess, staleTime: QUERY_STALE_TIME });
    return useTeamBookingDetailsQuery({
        tenantId: tenantId,
        id: id,
        userData: userData,
        options: opts,
    });
};
export var useRefreshTeamBooking = function () {
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var userId = useSelector(tenantSelectors.getUserId);
    return useRefreshTeamBookingHook({ tenantId: tenantId, userId: userId });
};
