import React from 'react';
import { useSelector } from 'react-redux';
import { useUser } from 'api/queries/useUser';
import { useModal } from 'utils/hooks/useModal';
import { notificationsSelectors } from 'store';
import { MODALS } from 'consts';
import { FocusId } from 'utils';
import User from './User';
export function UserContainer() {
    var _a = useUser(), isLoading = _a.isLoading, isIdle = _a.isIdle, isError = _a.isError, user = _a.data;
    var openModal = useModal().openModal;
    var hasUnreadNotifications = useSelector(notificationsSelectors.getHasUnread);
    var toggleUserModal = function () {
        openModal(MODALS.USER_PROFILE);
    };
    return (React.createElement(User, { id: FocusId.UserProfileButton, user: user, onClick: toggleUserModal, hasUnreadNotifications: hasUnreadNotifications, isLoading: isLoading || isIdle, isError: isError }));
}
