import { SearchInput } from 'components/base/SearchInput';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { teamMembersSearchActions, teamMembersSearchSelectors } from 'store';
import { SearchInputWrapper } from './TeamMembers.style';
export function TeamMembersSearch() {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var defaultValue = useSelector(teamMembersSearchSelectors.getTeamMembersSearchValue);
    var searchPlaceholderText = t('layout.teamMembersList.findPeople');
    var setSearchValue = useCallback(function (text) { return dispatch(teamMembersSearchActions.setSearchValue(text)); }, [dispatch]);
    var clearSearchValue = useCallback(function () { return dispatch(teamMembersSearchActions.clearSearchValue()); }, [dispatch]);
    useEffect(function () { return function () {
        clearSearchValue();
    }; }, [clearSearchValue]);
    return (React.createElement(SearchInputWrapper, null,
        React.createElement(SearchInput, { defaultValue: defaultValue, setSearchValue: setSearchValue, clearSearchValue: clearSearchValue, searchPlaceholderText: searchPlaceholderText })));
}
