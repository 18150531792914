var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var updateDurationObjFromState = function (_a) {
    var _b;
    var state = _a.state, dataKey = _a.dataKey, setDurationObj = _a.setDurationObj;
    if (state && dataKey) {
        // @ts-ignore
        var stateData = (_b = state[dataKey]) !== null && _b !== void 0 ? _b : {};
        var stateDuration_1 = stateData.duration, stateAllDayDuration = stateData.allDayDuration, stateSelectedDays_1 = stateData.selectedDays;
        if (stateDuration_1 > 0) {
            setDurationObj(function (state) { return (__assign(__assign({}, state), { duration: stateDuration_1, allDayDuration: false })); });
        }
        if (stateAllDayDuration === true) {
            setDurationObj(function (state) { return (__assign(__assign({}, state), { duration: 0, allDayDuration: true })); });
        }
        if (stateSelectedDays_1 && stateSelectedDays_1 !== []) {
            setDurationObj(function (state) { return (__assign(__assign({}, state), { selectedDays: stateSelectedDays_1 })); });
        }
    }
};
