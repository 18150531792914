import { RESTRICT_TO_VALUES } from '@serraview/engage-shared/constants';
import { useMemo } from 'react';
import { useUser } from 'api/queries/useUser';
import { useSearch } from './useSearch';
export var useFavouritePeople = function (_a) {
    var _b = _a === void 0 ? {} : _a, enabled = _b.enabled, _c = _b.shouldIncludeCurrentUser, shouldIncludeCurrentUser = _c === void 0 ? true : _c;
    var _d = useSearch({
        restrictTo: RESTRICT_TO_VALUES.PERSON,
        disableSearchString: true,
        enabled: enabled,
    }), data = _d.data, isLoading = _d.isLoading;
    var _e = useUser({
        enabled: enabled && shouldIncludeCurrentUser,
    }), isUserLoading = _e.isLoading, user = _e.data;
    var people = useMemo(function () {
        var _people = data === null || data === void 0 ? void 0 : data.pages.flatMap(function (page) { return page.data; }).filter(function (page) { return page.isFavourite; });
        if (user && shouldIncludeCurrentUser && (_people === null || _people === void 0 ? void 0 : _people.length)) {
            var containsCurrentUser = _people === null || _people === void 0 ? void 0 : _people.some(function (_a) {
                var id = _a.id;
                return id === user.id;
            });
            if (!containsCurrentUser) {
                _people === null || _people === void 0 ? void 0 : _people.push(user);
            }
        }
        return _people;
    }, [data === null || data === void 0 ? void 0 : data.pages, shouldIncludeCurrentUser, user]);
    var peopleCount = people === null || people === void 0 ? void 0 : people.reduce(function (prev) { return prev + 1; }, 0);
    return { people: people, peopleCount: peopleCount, isLoading: isUserLoading || isLoading };
};
