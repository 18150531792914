import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GlobalSearchResults from 'layouts/GlobalSearch/GlobalSearchResults';
import PersonItem from 'components/layouts/GlobalSearch/PersonItem/PersonItem';
import { useRestrictTo, useSetPageTitle } from 'utils/hooks';
import logger from 'log';
import { usePersonFetch, useUser } from 'api/queries';
import { Mask } from 'components/base';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { PATH_SEGMENT } from 'consts';
import { useAppLocation } from 'router/hooks';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
import { removeSegmentFromPath } from 'router/utils';
import GlobalSearchInput from 'components/base/GlobalSearchInput/GlobalSearchInput';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { isElectron } from 'utils/electron';
import { BookAsText, ReserveeListWrapper, SearchInputWrapper } from './styled';

const ReserveeList = () => {
  const { data } = useUser();
  const { state, pathname } = useAppLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchPersonQuery } = usePersonFetch();
  const [loading, setIsLoading] = useState(false);
  const restrictTo = useRestrictTo();

  const dataKey = restrictTo?.toLowerCase() ?? '';
  const stateData = state?.[dataKey] ?? {};

  const onPersonSelect = async (person) => {
    let personDetails;

    setIsLoading(true);

    try {
      personDetails = await fetchPersonQuery(person.id);
    } catch (e) {
      logger.error(e)();
    } finally {
      setIsLoading(false);
    }

    const path = removeSegmentFromPath(PATH_SEGMENT.RESERVEE, pathname);

    navigate(path, {
      replace: true,
      state: {
        [dataKey]: {
          ...stateData,
          reservee: personDetails ?? person,
        },
        fromReserveeList: true,
      },
    });
  };

  const renderPersonItem = ({ item, style, index }) => (
    <PersonItem
      onClick={onPersonSelect}
      shouldShowGuest={isElectronApp && item.id === data?.id}
      style={style}
      item={item}
      index={index}
    />
  );

  useSetPageTitle(t('documentTitles.bookForSelector'));

  const isElectronApp = isElectron();

  return (
    <>
      {isElectronApp && (
        <DrawerHeader
          hasButton
          closeIconA11yLabel="accessibilityLabels.closeLayout_locationSelector"
          isCloseIcon
        />
      )}
      <ReserveeListWrapper>
        {isElectronApp && <BookAsText>{t('layout.reserve.bookAs')}</BookAsText>}
        <Mask isLoading={loading} />
        <SearchInputWrapper>
          <GlobalSearchInput />
        </SearchInputWrapper>
        <DrawerCardContent>
          <div aria-live="polite" aria-atomic="true">
            <GlobalSearchResults
              renderItemOverride={renderPersonItem}
              showUserFirst
              restrictTo={RESTRICT_TO_VALUES.PERSON}
              useSections={false}
            />
          </div>
        </DrawerCardContent>
      </ReserveeListWrapper>
    </>
  );
};

export default ReserveeList;
