var _a;
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
export var PATH_SEGMENT = {
    PEOPLE: 'people',
    DESKS: 'desks',
    SPACES: 'spaces',
    TEAMS: 'teams',
    FAV_PEOPLE_TEAM: 'favourite-people',
    EMPLOYEE: 'employee',
    FILTERS: 'filters',
    RESERVEE: 'reservee',
    LOCATIONS: 'locations',
    LOCATION_SWITCHER: 'wayfinder',
    FLOORPLAN_VIEWS: 'views',
    RESERVE: 'reserve',
    SPACE_ATTRIBUTES: 'space-attributes',
    HEATMAP: 'heatmap',
};
export var PATH_PARAM = {
    DESK_ID: ':deskId',
    SPACE_ID: ':spaceId',
    PERSON_ID: ':personId',
    EMPLOYEE_ID: ':employeeId',
    TEAM_ID: ':teamId',
    TENANT: ':tenant',
};
export var ROOT_PATH = '/';
export var LOGIN_PATH = '/login';
export var SSO_LOGOUT_PATH = '/ssoLogout';
export var CALLBACK_PATH = '/callback';
export var WORKSPACE_PATH = '/workspace';
export var WORKSPACE_PATH_INSTANCE = "".concat(WORKSPACE_PATH, "/").concat(PATH_PARAM.TENANT);
export var APP_LAUNCH_PATH = '/launch';
export var APP_LAUNCH_INTUNE_PATH = '/intune/launch';
export var DEMO_PATH = '/demo';
export var FLOOR_PATH = '/floor/:floorId';
export var PERSON_PATH = "/".concat(PATH_SEGMENT.PEOPLE, "/").concat(PATH_PARAM.PERSON_ID);
export var TEAM_PATH = "/".concat(PATH_SEGMENT.TEAMS, "/").concat(PATH_PARAM.TEAM_ID);
export var PEOPLE_PATH = "/".concat(PATH_SEGMENT.PEOPLE);
export var ORGANIZATION_POLICY_PATH = '/acknowledgement';
export var CONCIERGE_MENU_PATH = '/menu';
export var BROWSER_PATH = '/browser';
export var KIOSK_ADMIN = '/kiosk_admin';
export var WAYFINDER_PATH = "/".concat(PATH_SEGMENT.LOCATION_SWITCHER);
export var LANDING_PATH = '/landing';
export var KEYBOARD_ACCESSIBILITY = '/keyboard_accessibility';
export var SEARCH_QUERY = {
    SPACE: 'space',
    DESK: 'desk',
    PERSON: 'person',
    TEAM: 'team',
    EMPLOYEE: 'employee',
    BOOKING_SPACE: 'bsid',
    BOOKING_MEETING: 'bmid',
    TEAM_BOOKING_MEETING: 'tbmid',
    ATTENDEE_ID: 'attid',
    DATE: 'date',
    TIME: 'time',
    WORKSPACE: 'workspace',
};
export var DATE_TIME_DEEP_LINK_PARAMS = {
    DATE: SEARCH_QUERY.DATE,
    TIME: SEARCH_QUERY.TIME,
};
export var MODAL_DEEP_LINK_PARAMS = {
    BOOKING_SPACE: SEARCH_QUERY.BOOKING_SPACE,
    BOOKING_MEETING: SEARCH_QUERY.BOOKING_MEETING,
    TEAM_BOOKING_MEETING: SEARCH_QUERY.TEAM_BOOKING_MEETING,
    ATTENDEE_ID: SEARCH_QUERY.ATTENDEE_ID,
};
export var DRAWER_DEEP_LINK_PARAMS = {
    SPACE: SEARCH_QUERY.SPACE,
    DESK: SEARCH_QUERY.DESK,
    PERSON: SEARCH_QUERY.PERSON,
    EMPLOYEE: SEARCH_QUERY.EMPLOYEE,
};
export var RESTRICT_TO_TO_PATH_SEGMENT = (_a = {},
    _a[RESTRICT_TO_VALUES.SPACE] = PATH_SEGMENT.SPACES,
    _a[RESTRICT_TO_VALUES.DESK] = PATH_SEGMENT.DESKS,
    _a[RESTRICT_TO_VALUES.PERSON] = PATH_SEGMENT.PEOPLE,
    _a[RESTRICT_TO_VALUES.TEAM] = PATH_SEGMENT.TEAMS,
    _a);
