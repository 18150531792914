import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
import { globalSearchSelectors, tenantSelectors } from 'store';
import { GlobalSearchResults } from 'layouts';
import { GlobalSearchWrapper, Wrapper, } from 'components/layouts/GlobalSearch/styled';
import { GlobalSearchFavourites, GlobalSearchFilter, GlobalSearchInput, RestrictToSearchFilter, } from 'base-components';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { useRestrictTo } from 'utils/hooks';
import { isElectron } from 'utils/electron';
var GlobalSearch = function () {
    var _a = useState(false), showToggleFavourite = _a[0], setShowToggleFavourite = _a[1];
    var hidePeopleData = useSelector(tenantSelectors.getKioskConfig).hidePeopleData;
    // hide Favorite button for kiosks
    var isKiosk = isElectron();
    var restrictTo = useRestrictTo();
    useEffect(function () {
        setShowToggleFavourite(false);
    }, [restrictTo]);
    var hasSearchValue = useSelector(globalSearchSelectors.getHasUserInput);
    var toggleShowFavourite = useCallback(function () {
        setShowToggleFavourite(function (prevShowToggleFavourite) { return !prevShowToggleFavourite; });
    }, []);
    var showFilters = restrictTo && restrictTo !== RESTRICT_TO_VALUES.TEAM;
    var showResults = hasSearchValue || !!restrictTo;
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, null),
        React.createElement(DrawerCardContent, null,
            React.createElement(GlobalSearchWrapper, { showResults: showResults },
                !hidePeopleData && React.createElement(GlobalSearchInput, null),
                React.createElement(RestrictToSearchFilter, null),
                showFilters && (React.createElement(Wrapper, null,
                    !isKiosk && (React.createElement(GlobalSearchFavourites, { showToggleFavourite: showToggleFavourite, toggleFavourites: toggleShowFavourite })),
                    React.createElement(GlobalSearchFilter, null))),
                showResults && (React.createElement("div", { "aria-live": "polite", "aria-atomic": "true" },
                    React.createElement(GlobalSearchResults, { showToggleFavourite: showToggleFavourite })))))));
};
export default GlobalSearch;
