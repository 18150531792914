import { useTheme } from 'styled-components';

export const useSelectedStyles = () => {
  const { typo2, color3, color4, gray4, isLayoutRTL } = useTheme();
  return {
    container: (base) => ({
      ...base,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 19,
      color: typo2,
    }),
    valueContainer: (base) => ({
      ...base,
      direction: 'ltr',
      justifyContent: 'center',
      ...(isLayoutRTL
        ? {
            paddingRight: 30,
          }
        : { paddingLeft: 30 }),
    }),
    indicatorsContainer: (base) => ({
      ...base,
      ...(isLayoutRTL
        ? {
            paddingLeft: 20,
          }
        : { paddingRight: 20 }),
      color: typo2,
    }),
    control: (base) => ({
      ...base,
      background: color4,
      borderWidth: 0,
      borderRadius: '40px',
      // Removes weird border around container
      boxShadow: null,
      outline: 0,
      paddingTop: 10,
      paddingBottom: 10,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: gray4,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      background: isFocused ? color3 : color4,
      color: typo2,
      fontWeight: isSelected ? 'bold' : 'normal',
      borderRadius: 5,
      cursor: 'pointer',
    }),
    menu: (base) => ({
      ...base,
      background: color4,
      borderRadius: 10,
      marginTop: 2,
    }),
  };
};
