import { DATE_TIME_PICKER_VARIANT, DOCUMENT_TITLE_SEPARATOR } from 'consts';
import { getBuildingAndFloorNames } from '../../helpers';

export const getCurrentTitleValues = (title) => {
  const parts = title.split(DOCUMENT_TITLE_SEPARATOR);

  if (parts.length === 1) parts.unshift(undefined, undefined);
  if (parts.length === 2) parts.unshift(undefined);

  return {
    component: parts[0],
    location: parts[1],
    base: parts[2],
  };
};

export const getTitleStringFromValues = (titleParts) =>
  Object.values(titleParts)
    .filter((part) => !!part)
    .join(DOCUMENT_TITLE_SEPARATOR);

export const getLocationTitle = ({ building, floor, t }) => {
  const { buildingName, floorName } = getBuildingAndFloorNames(
    building,
    floor,
    t,
  );

  return `${buildingName} ${floorName}`.trim();
};

export const getInfoCardTitle = ({ title, subtitle }) => {
  let result = title;
  if (subtitle) result += ` ${subtitle}`;
  return result;
};

export const getModalDateTimePickerTitle = (variant, t) => {
  if (variant === DATE_TIME_PICKER_VARIANT.INTERVAL_START)
    return t('documentTitles.reservationStartDateSelector');
  if (variant === DATE_TIME_PICKER_VARIANT.INTERVAL_END)
    return t('documentTitles.reservationEndDateSelector');
  return t('documentTitles.timelineSelector');
};
