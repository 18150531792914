import { getTimeQueryString } from '@serraview/engage-shared';
import Api from 'floorplan/services/api';
import { filterTeamBookingSpaces } from '../reducers/utils/space';

export const spaceActions = {
  SET_SPACE_DATA: 'SET_SPACE_DATA',
  SET_REFRESHED_SPACE_DATA: 'SET_REFRESHED_SPACE_DATA',
  UPDATE_SPACE_DATA: 'UPDATE_SPACE_DATA',
  REFRESH_SPACE_DATA: 'REFRESH_SPACE_DATA',
  CLEAR_SPACE_DATA: 'CLEAR_SPACE_DATA',
  RESET_MODE: 'RESET_MODE',
  SET_TEAM_BOOKING_AVAILABILITY_MODE: 'SET_TEAM_BOOKING_AVAILABILITY_MODE',
  SET_TEAM_BOOKING_AVAILABILITY_DATA: 'SET_TEAM_BOOKING_AVAILABILITY_DATA',
};

const updateSpaceData = (data, floorId) => ({
  type: spaceActions.UPDATE_SPACE_DATA,
  floorId,
  payload: data,
});

export const setSpaceData = (data, floorId) => ({
  type: spaceActions.SET_SPACE_DATA,
  floorId,
  payload: data,
});

const setRefreshedSpaceData = (data, floorId) => ({
  type: spaceActions.SET_REFRESHED_SPACE_DATA,
  floorId,
  payload: data,
});

const setTeamBookingAvailabilityData = (data, floorId) => ({
  type: spaceActions.SET_TEAM_BOOKING_AVAILABILITY_DATA,
  floorId,
  payload: data,
});

export const setTeamBookingAvailabilityMode = (floorId) => ({
  type: spaceActions.SET_TEAM_BOOKING_AVAILABILITY_MODE,
  floorId,
});

export const resetMode = () => ({
  type: spaceActions.RESET_MODE,
});

const getSpaceData = (data) => {
  const { floorId, startTime, endTime } = data;

  return Api.fetch({
    method: 'GET',
    url: `/floors/${floorId}/spaces${getTimeQueryString(startTime, endTime)}`,
  });
};

const getDeskAvailablityData = (data) => {
  const { floorId, startTime, endTime } = data;

  return Api.fetch({
    method: 'GET',
    url: `/floors/${floorId}/available/desks${getTimeQueryString(
      startTime,
      endTime,
    )}`,
  });
};

const getTeamBookingAvailablityData = async (data) => {
  const { floorId, startTime, endTime, teamBookingId } = data;

  const spaces = await Api.fetch({
    method: 'GET',
    url: `/floors/${floorId}/available/desks${getTimeQueryString(
      startTime,
      endTime,
    )}`,
  });

  if (Number.isInteger(teamBookingId)) {
    return filterTeamBookingSpaces({
      spaces,
      startTime,
      endTime,
      teamBookingId,
      floorId,
    });
  }

  return spaces;
};

// APi is not returning correct data
const getSpaceAvailablityData = (data) => {
  const { floorId, startTime, endTime } = data;

  return Api.fetch({
    method: 'GET',
    url: `/floors/${floorId}/available${getTimeQueryString(
      startTime,
      endTime,
    )}`,
  });
};

export const loadDeskAvailabiltyData = (query, callback, errCallback) => ({
  type: spaceActions.REFRESH_SPACE_DATA,
  async: true,
  floorId: query.floorId,
  httpService: () => getDeskAvailablityData(query),
  then: (response, dispatch) => {
    dispatch(
      updateSpaceData(
        {
          spaces: response.data,
        },
        query.floorId,
      ),
    );

    if (callback) {
      callback();
    }
  },
  error: (err) => {
    if (errCallback) {
      errCallback(err);
    }
  },
});

export const loadSpaceAvailabiltyData = (query, callback, errCallback) => ({
  type: spaceActions.REFRESH_SPACE_DATA,
  async: true,
  floorId: query.floorId,
  httpService: () => getSpaceAvailablityData(query),
  then: (response, dispatch) => {
    dispatch(
      updateSpaceData(
        {
          spaces: response.data,
        },
        query.floorId,
      ),
    );

    if (callback) {
      callback();
    }
  },
  error: (err) => {
    if (errCallback) {
      errCallback(err);
    }
  },
});

export const loadTeamBookingAvailabiltyData = (
  query,
  callback,
  errCallback,
) => ({
  type: spaceActions.REFRESH_SPACE_DATA,
  async: true,
  floorId: query.floorId,
  httpService: () => getTeamBookingAvailablityData(query),
  then: (response, dispatch) => {
    dispatch(
      setTeamBookingAvailabilityData(
        {
          spaces: response.data,
        },
        query.floorId,
      ),
    );

    if (callback) {
      callback();
    }
  },
  error: (err) => {
    if (errCallback) {
      errCallback(err);
    }
  },
});

export const loadSpaceData = (query, callback, errCallback) => ({
  type: spaceActions.CLEAR_SPACE_DATA,
  async: true,
  floorId: query.floorId,
  httpService: () => getSpaceData(query),
  then: (response, dispatch) => {
    dispatch(
      setSpaceData(
        {
          spaces: response.data,
        },
        query.floorId,
      ),
    );

    if (callback) {
      callback();
    }
  },
  error: (err) => {
    if (errCallback) {
      errCallback(err);
    }
  },
});

export const refreshSpaceData = (query, callback, errCallback) => ({
  type: spaceActions.REFRESH_SPACE_DATA,
  async: true,
  floorId: query.floorId,
  httpService: () => getSpaceData(query),
  then: (response, dispatch) => {
    dispatch(
      setRefreshedSpaceData(
        {
          spaces: response.data,
        },
        query.floorId,
      ),
    );

    if (callback) {
      callback();
    }
  },
  error: (err) => {
    if (errCallback) {
      errCallback(err);
    }
  },
});
