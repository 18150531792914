import { useSelector } from 'react-redux';
import { filtersSelectors } from 'store';
import { usePersonFetch as usePersonFetchHook, usePersonQuery, } from '@serraview/engage-shared/api';
var usePerson = function (_a) {
    var id = _a.id, searchSpaceId = _a.searchSpaceId, currentFloorId = _a.currentFloorId, _b = _a.options, options = _b === void 0 ? {} : _b;
    var _c = useSelector(filtersSelectors.getFilterDates), dateStart = _c.dateStart, dateEnd = _c.dateEnd;
    return usePersonQuery({
        id: id,
        dateStart: dateStart,
        dateEnd: dateEnd,
        searchSpaceId: searchSpaceId,
        currentFloorId: currentFloorId,
        options: options,
    });
};
var usePersonFetch = function () {
    var _a = useSelector(filtersSelectors.getFilterDates), dateStart = _a.dateStart, dateEnd = _a.dateEnd;
    return usePersonFetchHook({ dateStart: dateStart, dateEnd: dateEnd });
};
export { usePerson, usePersonFetch };
