var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { COMMON_STYLES } from 'consts';
import React from 'react';
import Switch from 'react-switch';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToggleSwitchLabelStyled, ToggleSwitchWrapperStyled } from './styled';
var ENTER_CODE = 'Enter';
// it's wrapper of react-switch component
var ToggleSwitch = function (_a) {
    var id = _a.id, _b = _a.checked, checked = _b === void 0 ? false : _b, onChange = _a.onChange, _c = _a.disabled, disabled = _c === void 0 ? false : _c, label = _a.label, ariaLabel = _a.ariaLabel, options = __rest(_a, ["id", "checked", "onChange", "disabled", "label", "ariaLabel"]);
    var _d = useTheme(), color9 = _d.color9, gray4 = _d.gray4;
    var t = useTranslation().t;
    var onKeyPress = function (event) {
        if (event.code === ENTER_CODE) {
            // @ts-ignore - cannot convert event to SyntheticEvent
            onChange(!checked, event, id);
        }
    };
    return (React.createElement(ToggleSwitchWrapperStyled, { disabled: disabled },
        React.createElement(Switch, __assign({ id: id, onChange: onChange, onKeyPress: onKeyPress, uncheckedIcon: false, checkedIcon: false, boxShadow: COMMON_STYLES.SWITCH_BOX_SHADOW, activeBoxShadow: COMMON_STYLES.SWITCH_BOX_SHADOW, handleDiameter: 20, onColor: color9, offColor: gray4, height: 24, width: 40, disabled: disabled, checked: checked, "aria-label": ariaLabel || id, "aria-checked": checked, "aria-disabled": disabled, role: "switch", name: t('accessibilityLabels.optOutFromSearch') }, options)),
        !!label && (React.createElement(ToggleSwitchLabelStyled, { "data-testid": "switch-Label", htmlFor: id }, label))));
};
export default ToggleSwitch;
