import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyCard } from 'components/base';
import { teamReservationsActions, teamReservationsSelectors } from 'store';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useNavigate } from 'react-router-dom';
import { PATH_SEGMENT } from 'consts';
import { joinPaths } from 'router/utils';
import { useGenerateCurrentLocationPath } from 'utils';
import { useTeam } from 'api/queries/useTeam';
import { ReserveCard } from './Card/ReserveCard/ReserveCard';
import ReserveCardSkeleton from './Card/ReserveCardSkeleton';
import { useReserveQueries } from './hooks/useReserveQueries';
import { useReserveRouteData } from './hooks/useReserveRouteData';
var emptyObject = {};
export function ReserveTeamContainer() {
    var _a, _b;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var currentLocationPath = useGenerateCurrentLocationPath();
    var teamReservationSelectedDesks = useSelector(teamReservationsSelectors.getTeamReservationSelectedDesks);
    var isDeskSelectionCompleted = useSelector(teamReservationsSelectors.getIsSelectionComplete);
    // using first space item as a placeholder for now
    var firstSelectedDeskId = teamReservationSelectedDesks === null || teamReservationSelectedDesks === void 0 ? void 0 : teamReservationSelectedDesks[0];
    var reserveRouteData = useReserveRouteData();
    var _c = reserveRouteData !== null && reserveRouteData !== void 0 ? reserveRouteData : {}, id = _c.id, closeLinkPath = _c.closeLinkPath, stateData = _c.data;
    var _d = useReserveQueries(firstSelectedDeskId), isLoading = _d.isLoading, spaceItem = _d.spaceItem, userData = _d.userData, bookingsData = _d.bookingsData;
    var teamQuery = useTeam({
        id: id,
        teamFromState: stateData,
    });
    var teamData = (_b = (_a = teamQuery === null || teamQuery === void 0 ? void 0 : teamQuery.data) !== null && _a !== void 0 ? _a : stateData) !== null && _b !== void 0 ? _b : emptyObject;
    useEffect(function () {
        // move back to desks selection since user de-selected some of the desks
        if (!isDeskSelectionCompleted) {
            navigate(joinPaths(currentLocationPath, PATH_SEGMENT.TEAMS, "".concat(id)), {
                state: {
                    team: teamData,
                },
            });
        }
    }, [isDeskSelectionCompleted, navigate, id, currentLocationPath, teamData]);
    var resetTeamReservation = function () {
        dispatch(teamReservationsActions.clear());
        dispatch(teamReservationsActions.clearSelection());
    };
    if (isLoading || teamQuery.isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, onPress: resetTeamReservation, isCloseIcon: true }),
            React.createElement(ReserveCardSkeleton, null)));
    }
    if (!reserveRouteData || !userData || !spaceItem || !teamData) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, onPress: resetTeamReservation, isCloseIcon: true, closeIconA11yLabel: "accessibilityLabels.closeLayout_teamReserve" }),
            React.createElement(EmptyCard, { iconName: "warning" }, t('common.networkError'))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, isCloseIcon: true, onPress: resetTeamReservation, closeIconA11yLabel: "accessibilityLabels.closeLayout_teamReserve" }),
        React.createElement(ReserveCard, { spaceItem: spaceItem, item: teamData, user: userData, bookings: bookingsData })));
}
