var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePersonLocationsQuery } from '@serraview/engage-shared';
import DeskItem from 'components/layouts/GlobalSearch/DeskItem';
import { replaceLineFeedWithSpace } from 'utils';
import { Avatar, EmptyCard } from 'base-components';
import { useTranslation } from 'react-i18next';
import SearchItemSkeleton from 'components/layouts/GlobalSearch/SearchItemSkeleton';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { usePerson } from 'api/queries/usePerson';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { generatePersonPath, removeSegmentFromPath } from 'router/utils';
import { PATH_SEGMENT } from 'consts';
import { useAppLocation, useAppParams } from 'router/hooks';
import { ContentWrapper } from './styled';
var getPersonId = function (personIdFromProps, personIdFromParams, personIdFromState) {
    if (personIdFromProps)
        return personIdFromProps;
    if (personIdFromParams)
        return parseInt(personIdFromParams, 10);
    if (personIdFromState)
        return personIdFromState;
    return 0;
};
var PersonLocations = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var personIdFromProps = _a.personId;
    var personIdFromParams = useAppParams().personId;
    var _h = useAppLocation(), state = _h.state, pathname = _h.pathname;
    var personFromState = state === null || state === void 0 ? void 0 : state.person;
    var personId = getPersonId(personIdFromProps, personIdFromParams, personFromState === null || personFromState === void 0 ? void 0 : personFromState.id);
    var navigate = useNavigate();
    var t = useTranslation().t;
    var personQuery = usePerson({
        id: personId,
        options: { enabled: !!personId },
    });
    var personData = personQuery.data;
    var name = (_c = (_b = personFromState === null || personFromState === void 0 ? void 0 : personFromState.name) !== null && _b !== void 0 ? _b : personData === null || personData === void 0 ? void 0 : personData.personName) !== null && _c !== void 0 ? _c : undefined;
    var imageUrl = (_d = personFromState === null || personFromState === void 0 ? void 0 : personFromState.imageUrl) !== null && _d !== void 0 ? _d : personData === null || personData === void 0 ? void 0 : personData.imageUrl;
    var personLocation = (_f = (_e = personFromState === null || personFromState === void 0 ? void 0 : personFromState.location) !== null && _e !== void 0 ? _e : personData === null || personData === void 0 ? void 0 : personData.location) !== null && _f !== void 0 ? _f : [];
    var personLocations = usePersonLocationsQuery({
        personId: personId,
        personLocation: personLocation,
        translationFn: t,
    });
    var data = (_g = personLocations.data) !== null && _g !== void 0 ? _g : [];
    // TODO: desk Any type
    var onDeskSelect = function (desk) {
        var _a;
        var pathname = generatePersonPath({
            floorId: desk.floorId,
            personId: desk.personId,
        });
        navigate(pathname, {
            state: {
                isFinalLocation: true,
                person: __assign({}, personData),
                searchSpaceId: (_a = desk === null || desk === void 0 ? void 0 : desk.location) === null || _a === void 0 ? void 0 : _a.spaceId,
            },
        });
    };
    var renderHeader = function () {
        var closeLinkPath = removeSegmentFromPath(PATH_SEGMENT.LOCATIONS, pathname);
        if (personLocations.isLoading || personLocations.isError) {
            return (React.createElement(DrawerHeader, { title: !!personFromState && name, subtitle: false, icon: !!personFromState && React.createElement(Avatar, { source: imageUrl, name: name }), closeLinkPath: closeLinkPath, closeIconA11yLabel: "accessibilityLabels.closeLayout_personLocations", isCloseIcon: true }));
        }
        return (React.createElement(DrawerHeader, { title: name, subtitle: false, icon: React.createElement(Avatar, { source: imageUrl, name: name }), closeLinkPath: closeLinkPath, closeIconA11yLabel: "accessibilityLabels.closeLayout_personLocations", isCloseIcon: true }));
    };
    var renderContent = function () {
        if (personLocations.isLoading) {
            return (React.createElement(ContentWrapper, { "data-testid": "loading-skeleton" },
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null)));
        }
        if (personLocations.isError) {
            return React.createElement(EmptyCard, { iconName: "info" }, t('common.networkError'));
        }
        return (React.createElement(DrawerCardContent, null,
            React.createElement(ContentWrapper, { "data-testid": "locations" }, data.map(function (location, index) { return (React.createElement(DeskItem, { key: "".concat(location.building, "-").concat(location.label), onPress: onDeskSelect, item: location, overrideText: {
                    title: location.building,
                    subtitle: "".concat(replaceLineFeedWithSpace(location.label)),
                }, index: index })); }))));
    };
    return (React.createElement(React.Fragment, null,
        renderHeader(),
        renderContent()));
};
export default memo(PersonLocations);
