var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
export var InfoWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var LineWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  color: ", ";\n\n  span {\n    font-size: 14px;\n    line-height: 19px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  color: ", ";\n\n  span {\n    font-size: 14px;\n    line-height: 19px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var IconWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 30px;\n  margin-right: 5px;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 30px;\n  margin-right: 5px;\n"])));
export var LocatorAction = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 40px;\n  height: 40px;\n  border-radius: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n  cursor: pointer;\n  color: ", ";\n  opacity: ", ";\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  width: 40px;\n  height: 40px;\n  border-radius: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n  cursor: pointer;\n  color: ", ";\n  opacity: ", ";\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo1;
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 0.5 : 1);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
