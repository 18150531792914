import { css } from 'styled-components';

const LINE_HEIGHT_MULTIPLIER = 1.3;

const fontSizesPX = {
  xl: 50,
  subtitle: 19,
  bd: 16,
  bdl: 18,
  btn: 14,
  xsm: 12,
};

const headerSizesPX = {
  h1: 40,
  h2: 32,
  h3: 28,
  h4: 24,
  h5: 20,
  h6: 16,
  body: fontSizesPX.bd,
  p: fontSizesPX.bd,
};

const headerMarginsPX = Object.keys(headerSizesPX).reduce((acc, v) => {
  acc[v] = {
    marginTop: (headerSizesPX[v] / 10) * 1.5,
    marginBottom: (headerSizesPX[v] / 10) * 3.4,
  };
  return acc;
}, {});
headerMarginsPX.p = {
  marginTop: 0,
  marginBottom: 5,
};
headerMarginsPX.body = {
  marginTop: 0,
  marginBottom: 5,
};

const cssString =
  Object.keys(headerSizesPX).reduce(
    (acc, v) => `${acc}
      ${v} {
        font-size: ${headerSizesPX[v]}px;
        margin-top:${headerMarginsPX[v].marginTop}px;
        margin-bottom:${headerMarginsPX[v].marginBottom}px;
      }
    `,
    '',
  ) +
  Object.keys(fontSizesPX).reduce(
    (acc, v) => `${acc}
    .${v}{
        font-size:${fontSizesPX[v]}px;
    }`,
    '',
  );

const fontTagHeights = Object.keys(headerSizesPX).reduce((acc, v) => {
  const clean = headerSizesPX[v];
  const marginTop = headerMarginsPX[v].marginTop;
  const marginBottom = headerMarginsPX[v].marginBottom;
  const lineHeight = clean * LINE_HEIGHT_MULTIPLIER;
  const withMargins = lineHeight + marginBottom + marginTop;
  acc[v] = {
    clean,
    marginTop,
    marginBottom,
    lineHeight,
    withMargins,
  };
  return acc;
}, {});

const fontClassesHeights = Object.keys(fontSizesPX).reduce((acc, v) => {
  const clean = fontSizesPX[v];
  const marginTop = 0;
  const marginBottom = 0;
  const lineHeight = clean * LINE_HEIGHT_MULTIPLIER;
  const withMargins = lineHeight + marginBottom + marginTop;
  acc[v] = {
    clean,
    marginTop,
    marginBottom,
    lineHeight,
    withMargins,
  };
  return acc;
}, {});

const fontSizes = css`
  ${cssString}
  * {
    line-height: ${LINE_HEIGHT_MULTIPLIER};
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }
  h5,
  h6 {
    font-weight: 600;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }
  p,
  body {
    margin-top: 0;
    margin-bottom: 5px;
  }
`;

export {
  fontSizes,
  LINE_HEIGHT_MULTIPLIER,
  headerSizesPX,
  fontSizesPX,
  fontTagHeights,
  headerMarginsPX,
  fontClassesHeights,
};
