var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { testProps } from 'utils';
import { Icon } from '../Icons/Icon';
import { FavouritesButton } from './styled';
var GlobalSearchFavourites = function (_a) {
    var toggleFavourites = _a.toggleFavourites, showToggleFavourite = _a.showToggleFavourite;
    var t = useTranslation().t;
    return (React.createElement(FavouritesButton, __assign({ onClick: toggleFavourites, favouritesActive: showToggleFavourite }, testProps(t, 'layout.globalSearch.favourites').props, { name: t('accessibilityLabels.selectGlobalSearchFavourites') }),
        React.createElement(Icon, { name: "star", size: 18 })));
};
export default memo(GlobalSearchFavourites);
