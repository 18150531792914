import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { inAppNotificationsActions } from 'store';
import { TOAST_STYLES } from 'consts';
import { useDispatch } from 'react-redux';

const BannerNotificationsDemo = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('Event was successfully deleted!');
  const [ttl, setTTL] = useState(5000);
  const [type, setType] = useState(toast.TYPE.DARK);

  // eslint-disable-next-line no-unused-vars
  const types = Object.entries(toast.TYPE).map(([_, value]) => value);

  const addNotification = () => {
    dispatch(
      inAppNotificationsActions.addNotification({
        message,
        ttl,
        type,
        style: TOAST_STYLES.BANNER,
      }),
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <h3>Banner Notifications Demo</h3>
      <label htmlFor="message">Message</label>
      <input
        id="message"
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <label htmlFor="type">Notification Type</label>
      <select id="type" value={type} onChange={(e) => setType(e.target.value)}>
        {types.map((t) => (
          <option value={t}>{t}</option>
        ))}
      </select>
      <label htmlFor="ttl">Notification Timeout</label>

      <input
        id="ttl"
        type="number"
        value={ttl}
        onChange={(e) => setTTL(parseInt(e.target.value, 10))}
      />
      <button
        onClick={addNotification}
        style={{
          border: '1px solid black',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
      >
        Add Notification
      </button>
    </div>
  );
};

export default BannerNotificationsDemo;
