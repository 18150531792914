var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n  pointer-events: auto;\n"], ["\n  display: flex;\n  align-items: flex-end;\n  flex-direction: column;\n  pointer-events: auto;\n"])));
var DateS = styled.h5(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 20px;\n  color: ", ";\n"], ["\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 20px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return textColor !== null && textColor !== void 0 ? textColor : theme.typo2;
});
var TimeS = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 32px;\n  line-height: 25px;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  font-size: 32px;\n  line-height: 25px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return textColor !== null && textColor !== void 0 ? textColor : theme.typo2;
});
export { Wrapper, DateS, TimeS };
var templateObject_1, templateObject_2, templateObject_3;
