import React from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { testProps } from 'utils';
import { Icon } from '../Icons/Icon';
import { BackButtonStyled } from './styled';

function BackButton({ onClick, iconSize, iconColor }) {
  const { t } = useTranslation();
  const { typo2, isLayoutRTL } = useTheme();

  return (
    <BackButtonStyled
      onClick={onClick}
      {...testProps(t, 'accessibilityLabels.goBack').props}
    >
      <Icon
        name={isLayoutRTL ? 'arrow-right' : 'arrow-left'}
        size={iconSize}
        style={{
          color: iconColor ? iconColor : typo2,
        }}
      />
    </BackButtonStyled>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
};

BackButton.defaultProps = {
  iconSize: 32,
  iconColor: '',
};

export default BackButton;
