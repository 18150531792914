import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CHECK_IN_STATUS } from 'consts';
import { useBookingFetch } from 'api/queries/useBookingDetails';
import { getCheckInStatus, getStatusMessage, } from '@serraview/engage-shared';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import { useConfig, useLocalTimeFormatH12 } from 'utils';
import useInterval from './useInterval';
export var useCheckInStatus = function (_a) {
    var _b, _c, _d, _e;
    var event = _a.event, booking = _a.booking;
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var t = useTranslation().t;
    var isTimeFormatH12 = useLocalTimeFormatH12();
    var buildingId = (_c = (_b = booking === null || booking === void 0 ? void 0 : booking.location.buildingId) !== null && _b !== void 0 ? _b : event === null || event === void 0 ? void 0 : event.buildingId) !== null && _c !== void 0 ? _c : (_d = event === null || event === void 0 ? void 0 : event.building) === null || _d === void 0 ? void 0 : _d.id;
    var config = useConfig({ buildingId: buildingId });
    var fetchBookingQuery = useBookingFetch({
        calculateCheckInStatus: true,
    }).fetchBookingQuery;
    var _f = React.useState(true), intervalCheckEnabled = _f[0], setIntervalCheckEnabled = _f[1];
    var _g = React.useState(''), statusMessage = _g[0], setStatusMessage = _g[1];
    var _h = React.useState(CHECK_IN_STATUS.NONE), checkInStatus = _h[0], setCheckInStatus = _h[1];
    var timeZone = (_e = event === null || event === void 0 ? void 0 : event.startTimeZone) !== null && _e !== void 0 ? _e : booking === null || booking === void 0 ? void 0 : booking.localTimeZone;
    var updateStatus = function () {
        getCheckInStatus({
            event: event,
            booking: booking,
            config: config,
            fetchBookingQuery: fetchBookingQuery,
        }).then(function (newStatus) {
            var hours = newStatus.hours, minutes = newStatus.minutes, status = newStatus.status, date = newStatus.date;
            setCheckInStatus(status);
            var messageResponse = getStatusMessage({
                hours: hours,
                minutes: minutes,
                date: date,
                status: status,
                locale: locale,
                timeZone: timeZone,
                isTimeFormatH12: isTimeFormatH12,
            });
            if (messageResponse) {
                var messageLabel = messageResponse[0], options = messageResponse[1];
                setStatusMessage(t(messageLabel, options));
            }
            else {
                setStatusMessage('');
            }
        });
    };
    useInterval(updateStatus, intervalCheckEnabled ? 60 * 1000 : null);
    React.useEffect(function () {
        // will run once on component mount
        updateStatus();
        // will run only before component will be unmounted
        return function () {
            setIntervalCheckEnabled(false);
        };
        // we're intentionally breaking rules here, because we don't care about updateStatus function changes
        // we just want it to run once after mounting
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { status: checkInStatus, statusMessage: statusMessage };
};
