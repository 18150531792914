var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { fetchAndParseEvents, useBuildingFetch, } from '@serraview/engage-shared/api';
import { tenantSelectors } from 'store';
import { getDayStartTime, getUtcIsoString, } from '@serraview/engage-shared/utils';
import { addDays, subDays } from 'date-fns';
import { getDayEndTime } from '@serraview/engage-shared';
export var NOTIFICATION_EVENTS_KEY = 'events';
export var useNotificationEvents = function (_a) {
    var startTime = _a.startTime, endTime = _a.endTime, _b = _a.options, options = _b === void 0 ? {} : _b, eventsFilter = _a.eventsFilter;
    var userId = useSelector(tenantSelectors.getUserId);
    var fetchBuildingQuery = useBuildingFetch().fetchBuildingQuery;
    return useQuery([NOTIFICATION_EVENTS_KEY, userId, startTime, endTime], function () {
        return fetchAndParseEvents({
            userId: userId,
            startTime: startTime,
            endTime: endTime,
            eventsFilter: eventsFilter,
            fetchBuildingQuery: fetchBuildingQuery,
        });
    }, __assign({ enabled: !!userId }, options));
};
var STALE_TIME = 15 * 60 * 1000; // stale for 15 minutes
var queryOptions = {
    staleTime: STALE_TIME,
};
export var useNotifications = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.options, options = _c === void 0 ? {} : _c;
    var today = new Date();
    var startTime = getUtcIsoString(getDayStartTime({ date: new Date(subDays(today, 7)) }));
    var endTime = getUtcIsoString(getDayEndTime({ date: new Date(addDays(today, 1)) }));
    return useNotificationEvents({
        startTime: startTime,
        endTime: endTime,
        options: __assign(__assign({}, queryOptions), options),
    });
};
