import { getApiUrl } from '@serraview/engage-shared/api';
import logger from 'log';
import { API_VERSIONS, RESTRICT_TO_VALUES } from 'consts';

const getUserData = (user) => ({
  id: user.id,
  name: user.name,
  initials: user.initials,
  description: user.jobTitle,
  imageUrl: user.imageUrl,
  location: {}, // value not needed, want to display it first, only to avoid error on processing sections
});

export const getSearchData = ({
  tenantId,
  pageParam,
  floorId,
  isFirstAidOfficer,
  isFireWarden,
  startTime,
  endTime,
  capacity,
  filterDates,
  restrictTo,
  searchString,
  spaceTypes,
  spaceAttributes,
}) => {
  const data = {
    tenantId,
    floorId,
    startTime,
    endTime,
    searchString,
    restrictTo: [restrictTo],
    page: pageParam,
    perPage: 40,
  };

  if (filterDates) {
    data.selectedDates = filterDates;
  }

  if (restrictTo === RESTRICT_TO_VALUES.SPACE) {
    data.capacity = capacity === 1 ? 0 : capacity;
  }

  if (
    restrictTo === RESTRICT_TO_VALUES.SPACE ||
    restrictTo === RESTRICT_TO_VALUES.DESK
  ) {
    if (spaceTypes?.length > 0) {
      data.meetingRoomTypeId = spaceTypes;
    }

    if (spaceAttributes?.length > 0) {
      data.meetingRoomAttributes = spaceAttributes;
    }
  }

  if (restrictTo === RESTRICT_TO_VALUES.PERSON) {
    data.isFirstAidOfficer = isFirstAidOfficer;
    data.isFireWarden = isFireWarden;
  }

  if (
    !searchString &&
    (restrictTo === RESTRICT_TO_VALUES.PERSON ||
      restrictTo === RESTRICT_TO_VALUES.TEAM)
  ) {
    data.showFloorOnly = true;
  }

  logger.debug(`${getApiUrl(tenantId, API_VERSIONS.V2)}/search`)();

  return data;
};

export const createTransformSearchResponse =
  ({ showUserFirst, searchString, user }) =>
  (response) => {
    let responseData = response.data;

    if (responseData.some((item) => item.type === RESTRICT_TO_VALUES.PERSON)) {
      if (user?.id && showUserFirst && !searchString) {
        const userData = getUserData(user);
        responseData = [userData, ...responseData];
      }

      responseData = responseData.filter(
        (item, index, items) =>
          items.findIndex((t) => t.id === item.id) === index,
      );
    }

    return { data: responseData, meta: response.meta };
  };
