var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n\n  @media screen and (min-height: 1024px) {\n    justify-content: flex-start;\n  }\n"], ["\n  background-color: ", ";\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n\n  @media screen and (min-height: 1024px) {\n    justify-content: flex-start;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
export var LogoContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 30px 0 20px;\n\n  @media only screen and (min-height: 800px) {\n    margin-bottom: 50px;\n    margin-top: 80px;\n  }\n\n  @media screen and (min-height: 1024px) {\n    margin-bottom: 100px;\n    margin-top: 150px;\n  }\n\n  @media screen and (max-width: 480px) and (max-height: 799px) {\n    align-self: start;\n    margin-left: 20px;\n  }\n"], ["\n  margin: 30px 0 20px;\n\n  @media only screen and (min-height: 800px) {\n    margin-bottom: 50px;\n    margin-top: 80px;\n  }\n\n  @media screen and (min-height: 1024px) {\n    margin-bottom: 100px;\n    margin-top: 150px;\n  }\n\n  @media screen and (max-width: 480px) and (max-height: 799px) {\n    align-self: start;\n    margin-left: 20px;\n  }\n"])));
export var ButtonContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: row;\n  margin-top: 55px;\n  display: flex;\n  align-items: center;\n\n  @media only screen and (max-width: 700px) {\n    flex-direction: column;\n  }\n\n  @media screen and (max-height: 580px) {\n    margin: 20px 0;\n    flex-direction: row-reverse;\n  }\n\n  @media screen and (min-height: 768px) {\n    margin: 30px 0;\n  }\n"], ["\n  flex-direction: row;\n  margin-top: 55px;\n  display: flex;\n  align-items: center;\n\n  @media only screen and (max-width: 700px) {\n    flex-direction: column;\n  }\n\n  @media screen and (max-height: 580px) {\n    margin: 20px 0;\n    flex-direction: row-reverse;\n  }\n\n  @media screen and (min-height: 768px) {\n    margin: 30px 0;\n  }\n"])));
export var CancelButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-decoration: none;\n  font-size: 17px;\n  color: ", ";\n  margin-right: 40px;\n  padding: 10px 20px;\n\n  @media screen and (min-height: 580px) {\n    cursor: pointer;\n    padding: 22px;\n  }\n\n  @media only screen and (max-width: 700px) {\n    margin-top: 20px;\n    order: 2;\n    margin-right: 0;\n  }\n\n  @media screen and (max-height: 580px) {\n    margin-top: 0;\n    margin-right: 20px;\n  }\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  text-decoration: none;\n  font-size: 17px;\n  color: ", ";\n  margin-right: 40px;\n  padding: 10px 20px;\n\n  @media screen and (min-height: 580px) {\n    cursor: pointer;\n    padding: 22px;\n  }\n\n  @media only screen and (max-width: 700px) {\n    margin-top: 20px;\n    order: 2;\n    margin-right: 0;\n  }\n\n  @media screen and (max-height: 580px) {\n    margin-top: 0;\n    margin-right: 20px;\n  }\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var Title = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 40px;\n  text-align: center;\n\n  @media only screen and (max-width: 700px) {\n    font-size: 22px;\n    margin-bottom: 20px;\n  }\n"], ["\n  margin-bottom: 40px;\n  text-align: center;\n\n  @media only screen and (max-width: 700px) {\n    font-size: 22px;\n    margin-bottom: 20px;\n  }\n"])));
export var MessageContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 790px;\n  max-height: 300px;\n  overflow: auto;\n  text-align: left;\n  scrollbar-color: ", "\n    ", "; /* thumb and track color only for Firefox */\n  scrollbar-width: thin; /* only for Firefox */\n  min-height: 120px;\n\n  @media (max-width: 1200px) and (min-width: 700px) {\n    margin: 0 90px;\n  }\n\n  @media (max-width: 700px) and (min-width: 400px) {\n    max-height: 360px;\n  }\n\n  @media only screen and (max-width: 400px) {\n    max-height: 300px;\n  }\n\n  @media only screen and (max-width: 700px) {\n    margin: 0 30px;\n  }\n\n  &::-webkit-scrollbar {\n    width: 6px;\n  }\n\n  &::-webkit-scrollbar-track {\n    overflow-x: hidden;\n    background: ", ";\n  }\n  &::-webkit-scrollbar-track:horizontal {\n    display: none;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background: ", ";\n  }\n"], ["\n  max-width: 790px;\n  max-height: 300px;\n  overflow: auto;\n  text-align: left;\n  scrollbar-color: ", "\n    ", "; /* thumb and track color only for Firefox */\n  scrollbar-width: thin; /* only for Firefox */\n  min-height: 120px;\n\n  @media (max-width: 1200px) and (min-width: 700px) {\n    margin: 0 90px;\n  }\n\n  @media (max-width: 700px) and (min-width: 400px) {\n    max-height: 360px;\n  }\n\n  @media only screen and (max-width: 400px) {\n    max-height: 300px;\n  }\n\n  @media only screen and (max-width: 700px) {\n    margin: 0 30px;\n  }\n\n  &::-webkit-scrollbar {\n    width: 6px;\n  }\n\n  &::-webkit-scrollbar-track {\n    overflow-x: hidden;\n    background: ", ";\n  }\n  &::-webkit-scrollbar-track:horizontal {\n    display: none;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color10;
}, function (_a) {
    var theme = _a.theme;
    return theme.color3;
}, function (_a) {
    var theme = _a.theme;
    return theme.color3;
}, function (_a) {
    var theme = _a.theme;
    return theme.color10;
});
export var ConfirmButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: inline-block;\n  border-radius: 99px;\n  text-align: center;\n  padding-left: 0;\n  background-color: ", ";\n  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.3);\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: inline-block;\n  border-radius: 99px;\n  text-align: center;\n  padding-left: 0;\n  background-color: ", ";\n  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.3);\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color10;
});
export var ConfirmButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 10px 20px;\n\n  @media screen and (min-height: 580px) {\n    cursor: pointer;\n    padding: 22px 105px;\n  }\n"], ["\n  cursor: pointer;\n  padding: 10px 20px;\n\n  @media screen and (min-height: 580px) {\n    cursor: pointer;\n    padding: 22px 105px;\n  }\n"])));
export var ConfirmButtonText = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  color: ", ";\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
