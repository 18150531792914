import React, { memo, useEffect } from 'react';
import { DURATION_TYPES } from 'consts';
import { Duration, DurationSelector } from 'base-components';
// default values for 'Other' selector
var INITIAL_DURATION_FOR_SPACES = 30;
var ReserveDuration = memo(function (_a) {
    var spaceId = _a.spaceId, isDesk = _a.isDesk, isTeam = _a.isTeam, duration = _a.duration, occupied = _a.occupied, onChangeDuration = _a.onChangeDuration, allDayDuration = _a.allDayDuration, onAllDayToggle = _a.onAllDayToggle, allDayDisabled = _a.allDayDisabled, onDurationOtherClick = _a.onDurationOtherClick;
    useEffect(function () {
        if (isDesk || isTeam) {
            // by default button 'All Day' is selected for desks
            onAllDayToggle(true);
            onChangeDuration(0);
        }
        else {
            // initial duration for spaces is 30 minutes
            onChangeDuration(INITIAL_DURATION_FOR_SPACES);
        }
    }, [spaceId, isDesk, onAllDayToggle, onChangeDuration, isTeam]);
    if (isDesk || isTeam) {
        return (React.createElement(Duration, { type: DURATION_TYPES.HOURS, value: duration, canDeselect: false, disabled: occupied, allDaySelected: allDayDuration, allDayDisabled: allDayDisabled, onChange: onChangeDuration, onAllDayToggle: onAllDayToggle }));
    }
    var renderDurationSelector = function () { return (React.createElement(DurationSelector, { onChange: onChangeDuration })); };
    return (React.createElement(Duration, { type: DURATION_TYPES.MINUTES, value: duration, canDeselect: false, disabled: occupied, onChange: onChangeDuration, onDurationOtherClick: onDurationOtherClick, renderOtherComponent: renderDurationSelector }));
});
export { ReserveDuration };
