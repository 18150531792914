var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Icon } from '../../Icons/Icon';
import { DateButtonDateClip, DateButtonDateClipWrapper, DateButtonDateTextWrapper, DateButtonIconWrapper, DateButtonWrapper, } from './styled';
export function DateButtonView(_a) {
    var onClick = _a.onClick, _b = _a.clearButtonProps, clearButtonProps = _b === void 0 ? {} : _b, _c = _a.isNow, isNow = _c === void 0 ? true : _c, _d = _a.isOpen, isOpen = _d === void 0 ? false : _d, _e = _a.disableInteractions, disableInteractions = _e === void 0 ? true : _e, children = _a.children, rest = __rest(_a, ["onClick", "clearButtonProps", "isNow", "isOpen", "disableInteractions", "children"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DateButtonWrapper, __assign({ disableInteractions: disableInteractions, isOpen: isOpen, onClick: onClick }, rest),
            React.createElement(DateButtonIconWrapper, null,
                React.createElement(Icon, { name: "clock", size: 20 })),
            React.createElement(DateButtonDateTextWrapper, { isActive: !isNow },
                React.createElement("span", { className: "xsm date-button__text", "data-testid": "dateButton-text" }, children))),
        !isNow ? (React.createElement(DateButtonDateClipWrapper, __assign({}, clearButtonProps),
            React.createElement(DateButtonDateClip, { isOpen: isOpen, isActive: !isNow }))) : (React.createElement(DateButtonDateClip, { isOpen: isOpen, isActive: !isNow }))));
}
