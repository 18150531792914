import React, { useEffect } from 'react';
import { useLogout } from 'api/mutations/useLogout';
import { useUser } from 'api/queries';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'utils/hooks';
import { userHasGeneralAccess } from '@serraview/engage-shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors, uiActions } from 'store';
import { ConfirmationDialog } from 'components/base';
export var AppAccessRestrictedDialog = function () {
    var t = useTranslation().t;
    return (React.createElement(ConfirmationDialog, { title: t('disableGeneralAccessTitle'), message: t('disabledGeneralAccess'), key: "app-access-disabled", hasCancelButton: false }));
};
export function useAppAccess() {
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var dispatch = useDispatch();
    var user = useUser().data;
    var disableWebGeneralAccess = useConfig().disableWebGeneralAccess;
    var logout = useLogout().mutate;
    useEffect(function () {
        if (!userHasGeneralAccess({
            disableGeneralAccess: disableWebGeneralAccess,
            user: user,
        })) {
            logout({ tenantId: tenantId });
            dispatch(uiActions.showAppAccessDialog());
        }
    }, [disableWebGeneralAccess, dispatch, logout, tenantId, user]);
}
