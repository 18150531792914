import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'base-components';
import { Subtitle, SubtitleWrapper, } from 'components/layouts/GlobalSearch/SearchItemSkeleton.style';
import { IconWrapper, ListItemOuterWrapper, ListItemWrapper, TextWrapper, } from './styled';
var SearchItemSkeleton = function () { return (React.createElement(SkeletonTheme, { style: { width: '100%' } },
    React.createElement(ListItemOuterWrapper, null,
        React.createElement(ListItemWrapper, null,
            React.createElement(Skeleton, { circle: true, height: 36, width: 36 }),
            React.createElement(TextWrapper, null,
                React.createElement("h6", null,
                    React.createElement(Skeleton, { width: 206 })),
                React.createElement(SubtitleWrapper, null,
                    React.createElement(Subtitle, null,
                        React.createElement(Skeleton, { width: 100 })),
                    React.createElement(Subtitle, null,
                        React.createElement(Skeleton, { width: 100 })))),
            React.createElement(IconWrapper, null,
                React.createElement(Skeleton, { circle: true, height: 26, width: 26 })))))); };
export default SearchItemSkeleton;
