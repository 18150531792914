import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color9};
  padding: 28px 0;
`;

const Title = styled.div`
  color: ${(props) => props.theme.typo1};
  font-weight: bold;
  font-size: 18px;
  margin-right: 8px;
`;

const NameWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FullName = styled.div`
  color: ${(props) => props.theme.typo1};
  font-size: 13px;
  margin-left: 5px;
`;

export { Wrapper, NameWrapper, Title, FullName };
