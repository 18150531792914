var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { getKioskConfigQueryKey, useAvailableKiosks, } from 'api/queries';
import { useQueryClient } from 'react-query';
import { Mask } from 'components/base';
import { useLoadKioskConfigDetails } from 'utils/hooks/useLoadKioskConfigDetails';
import logger from 'utils/logger';
import { inAppNotificationsActions } from 'store';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AvailableKiosksErrorDialog } from 'components/kiosk';
import { useResetKioskConfig } from 'utils/hooks/useResetKioskConfig';
import { Wrapper, ListWrapper, Header } from './AvailableKiosks.styled';
import { AvailableKioskBuildings } from './AvailableKioskBuildings';
import { AvailableKiosksContext } from './AvailableKiosksContext';
export var AvailableKiosks = function (_a) {
    var onSuccessfullySelectKiosk = _a.onSuccessfullySelectKiosk;
    var queryClient = useQueryClient();
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = useState(false), isDetailsLoading = _b[0], setDetailsLoading = _b[1];
    var _c = useAvailableKiosks(), data = _c.data, isLoading = _c.isLoading, isError = _c.isError, refetch = _c.refetch;
    var loadConfigDetails = useLoadKioskConfigDetails().loadConfigDetails;
    var resetKioskConfig = useResetKioskConfig().resetKioskConfig;
    var showErrorNotification = function () {
        dispatch(inAppNotificationsActions.addErrorNotification({
            message: t('layout.notifications.wrongKioskConfig'),
        }));
    };
    var onSelectConfig = function (config) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    setDetailsLoading(true);
                    return [4 /*yield*/, resetKioskConfig(false)];
                case 1:
                    _a.sent();
                    queryClient.setQueryData(getKioskConfigQueryKey(config.id), config);
                    return [4 /*yield*/, loadConfigDetails(config)];
                case 2:
                    _a.sent();
                    onSuccessfullySelectKiosk === null || onSuccessfullySelectKiosk === void 0 ? void 0 : onSuccessfullySelectKiosk();
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    logger.error(e_1)();
                    showErrorNotification();
                    return [3 /*break*/, 5];
                case 4:
                    setDetailsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (isLoading || isDetailsLoading) {
        return React.createElement(Mask, { isLoading: true });
    }
    if (isError) {
        return React.createElement(AvailableKiosksErrorDialog, { onRetryPress: refetch });
    }
    if (!data) {
        return (React.createElement(AvailableKiosksErrorDialog, { onRetryPress: refetch, title: t('components.availableKiosks.noKioskFound') }));
    }
    return (React.createElement(AvailableKiosksContext.Provider, { value: { onSelectConfig: onSelectConfig } },
        React.createElement(Wrapper, null,
            React.createElement(Header, null, t('components.availableKiosks.header')),
            React.createElement(ListWrapper, null,
                React.createElement(AvailableKioskBuildings, { data: data })))));
};
