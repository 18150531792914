import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AVAILABILITY_MESSAGES, useSpaceAttributesQuery, } from '@serraview/engage-shared';
import { useBookings, useSpace, useUser } from 'api/queries';
import { inAppNotificationsActions, userConfigSelectors } from 'store';
import { useReserveAnalytics } from 'utils/analytics/useReserveAnalytics';
import { FocusId, useFocus } from 'utils/hooks';
import logger from 'utils/logger';
var SENSOR_REFETCH_INTERVAL = 1000 * 60; // one minute
export function useReserveQueries(spaceId) {
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    // TODO: what to do when spaceId is null?
    var id = spaceId ? parseInt(spaceId, 10) : 0;
    // space query
    var spaceQuery = useSpace({
        id: id,
        options: {
            enabled: !!spaceId,
            refetchInterval: SENSOR_REFETCH_INTERVAL,
        },
    });
    var spaceItem = spaceQuery.data;
    // bookings query
    var onAvailabilityError = function (error) {
        var _a, _b, _c;
        logger.error(error)();
        var status = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status;
        if (status === 422) {
            // do not display the availability error message when status is 422
            return;
        }
        var spaceType = spaceItem === null || spaceItem === void 0 ? void 0 : spaceItem.type;
        var errorMsg = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error;
        var uiMessageRef = 
        // @ts-ignore
        status && AVAILABILITY_MESSAGES[spaceType][status]
            ? // @ts-ignore
                AVAILABILITY_MESSAGES[spaceType][status][errorMsg] ||
                    // @ts-ignore
                    AVAILABILITY_MESSAGES[spaceType][status].default
            : // @ts-ignore
                AVAILABILITY_MESSAGES[spaceType].default;
        dispatch(inAppNotificationsActions.addErrorNotification({
            message: t(uiMessageRef),
        }));
    };
    var bookingsQuery = useBookings({
        spaceItem: spaceItem,
        options: {
            retry: function (failureCount, error) {
                var _a;
                var status = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status;
                // do not retry fetching if status is 422
                return status !== 422;
            },
            onError: onAvailabilityError,
            refetchInterval: SENSOR_REFETCH_INTERVAL,
        },
    });
    var bookingsData = bookingsQuery.data;
    // space attributes query
    var spaceAttributesQuery = useSpaceAttributesQuery({
        spaceId: Number(spaceId),
        locale: locale,
    });
    var spaceAttributes = spaceAttributesQuery.data;
    // user query
    var userQuery = useUser();
    var userData = userQuery.data;
    var isLoading = spaceQuery.isLoading ||
        bookingsQuery.isLoading ||
        userQuery.isLoading ||
        spaceAttributesQuery.isLoading;
    useReserveAnalytics(spaceItem);
    useFocus({ focusOn: FocusId.DrawerCloseButton });
    return {
        isLoading: isLoading,
        bookingsData: bookingsData,
        spaceAttributes: spaceAttributes,
        userData: userData,
        spaceItem: spaceItem,
    };
}
