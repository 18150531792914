var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns';
import { DateFormat } from '@serraview/engage-shared';
import { formatLocalizedDate } from '@serraview/engage-shared/utils';
import { firstLetterCapitalize } from 'utils';
import { Icon } from 'base-components';
import { LanguageTypes } from 'consts';
import { CalendarStyled } from './styled';
// all available props can be found here https://github.com/wojtekmaj/react-calendar#user-guide
var Calendar = function (_a) {
    var _b = _a.locale, locale = _b === void 0 ? LanguageTypes.enUS : _b, calendarProps = __rest(_a, ["locale"]);
    var t = useTranslation().t;
    var formatLongDate = function (_, date) {
        var formattedDate = formatLocalizedDate(date, {
            locale: locale,
            format: DateFormat.yearWithShortMonthAndDay,
        });
        var calendarDateValue = calendarProps.value;
        if (isSameDay(calendarDateValue, date)) {
            formattedDate += " - ".concat(t('accessibilityLabels.selectedDate'));
        }
        return formattedDate;
    };
    var formatDay = function (locale, date) {
        var formattedDay = formatLocalizedDate(date, {
            locale: locale,
            format: DateFormat.dayNumeric,
        });
        // for Japanese and Chinese the formattedDay contains a character after the numeric value
        var matches = formattedDay.match(/(\d+)/);
        return matches && matches.length > 0 ? matches[0] : formattedDay;
    };
    return (React.createElement(CalendarStyled, __assign({ expandedDayFormat: false, prevLabel: React.createElement(Icon, { shouldRotateOnRtl: true, name: "arrow-left", size: 32 }), prev2Label: null, nextLabel: React.createElement(Icon, { shouldRotateOnRtl: true, name: "arrow-right", size: 32 }), formatShortWeekday: function (_, value) {
            return t("calendar.".concat(locale === LanguageTypes.esES ? 'dayNamesMin' : 'dayNamesShort'), { returnObjects: true })[value.getDay()];
        }, next2Label: null, navigationLabel: function (_a) {
            var date = _a.date, label = _a.label, view = _a.view;
            return view === 'month'
                ? firstLetterCapitalize(formatLocalizedDate(date, {
                    locale: locale,
                    format: DateFormat.monthLong,
                }))
                : label;
        }, formatLongDate: formatLongDate, locale: locale, formatDay: formatDay }, calendarProps)));
};
export default Calendar;
