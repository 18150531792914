import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTeamBookingDesksAvailabilities, } from '@serraview/engage-shared';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useTeamBookingDetails } from 'api/queries';
import { filtersActions, floorplanActions, teamReservationsActions, teamReservationsSelectors, tenantSelectors, } from 'store';
import { DeskList } from '../Booking/DeskList/DeskList';
import { ListWrapper } from './styled';
import { DeskListSkeleton } from './DeskListSkeleton';
export function TeamBookingDesks() {
    var _a;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _b = useTeamBookingDetails(teamBookingId), teamBookingDetails = _b.data, isLoading = _b.isLoading;
    var desks = (_a = teamBookingDetails === null || teamBookingDetails === void 0 ? void 0 : teamBookingDetails.deskBookings) !== null && _a !== void 0 ? _a : [];
    var _c = useTeamBookingDesksAvailabilities({
        tenantId: tenantId,
        teamBookingDetails: teamBookingDetails,
        options: { enabled: !!teamBookingDetails },
    }), desksAvailabilities = _c.data, isLoadingAvailabilities = _c.isLoading;
    var handleDeskClick = function (desk) {
        navigate("".concat(desk.spaceId));
    };
    var handleCloseClick = function (event) {
        event.preventDefault();
        dispatch(teamReservationsActions.clearTeamBookingId());
        dispatch(floorplanActions.refreshSpace());
        dispatch(filtersActions.clearDates({}));
    };
    var closeIconA11yLabel = 'accessibilityLabels.closeLayout_teamBookingDesks';
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, { isCloseIcon: true, onPress: handleCloseClick, closeIconA11yLabel: closeIconA11yLabel }),
        React.createElement(DrawerCardContent, null, isLoading || isLoadingAvailabilities ? (React.createElement(DeskListSkeleton, null)) : (React.createElement(ListWrapper, null,
            React.createElement(DeskList, { desks: desks, desksAvailabilities: desksAvailabilities, onItemClick: handleDeskClick }))))));
}
