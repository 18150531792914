var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title, CommonMessage, PrimaryButton } from './styled';
var Message = styled(CommonMessage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 42px;\n  margin-bottom: 28px;\n  margin-left: 4px;\n  margin-right: 4px;\n"], ["\n  line-height: 42px;\n  margin-bottom: 28px;\n  margin-left: 4px;\n  margin-right: 4px;\n"])));
var AvailableKiosksErrorDialog = function (_a) {
    var title = _a.title, onRetryPress = _a.onRetryPress;
    var t = useTranslation().t;
    var _title = title !== null && title !== void 0 ? title : t('components.configDialogError.title');
    return (React.createElement(Wrapper, { "data-testid": "availableKiosksErrorWrapper" },
        React.createElement(Title, null, _title),
        React.createElement(Message, null, t('components.configDialogError.message')),
        React.createElement(PrimaryButton, { onClick: onRetryPress }, t('components.configDialogError.button'))));
};
export default AvailableKiosksErrorDialog;
var templateObject_1;
