import { useToggleFavouriteMenuItem } from 'utils/hooks/useToggleFavouriteMenuItem';
import { SpaceType } from '@serraview/engage-shared';
export var usePersonMenu = function (_a) {
    var personId = _a.personId, _b = _a.isFavourite, isFavourite = _b === void 0 ? false : _b;
    var toggleFavouriteMenuItem = useToggleFavouriteMenuItem({
        isFavourite: isFavourite,
        itemId: personId,
        itemType: 'person',
        itemOriginalType: SpaceType.PERSON,
    });
    if (!personId)
        return null;
    return toggleFavouriteMenuItem ? [toggleFavouriteMenuItem] : null;
};
