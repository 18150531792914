import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInMilliseconds } from 'date-fns';
import ms from 'ms';
import { useIdleTimer } from 'react-idle-timer';
import { useSearchParams } from 'react-router-dom';
import { useLogout } from 'api/mutations/useLogout';
import { sessionTimeoutActions, sessionTimeoutSelectors, tenantSelectors, } from 'store';
var SESSION_TIMEOUT_MS = ms('2 hrs');
export var SessionTimeoutMiddleware = function (_a) {
    var children = _a.children;
    var query = useSearchParams()[0];
    var testTenant = query.get('sessionTimeoutTestTenant');
    var testTimeout = query.get('sessionTimeoutTestTimeout');
    var dispatch = useDispatch();
    // selectors
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var sessionTimeoutAffectedTenants = useSelector(sessionTimeoutSelectors.getAffectedTenants);
    var lastActiveAt = useSelector(sessionTimeoutSelectors.getLastActiveAt);
    // state
    var _b = useState(false), isIdle = _b[0], setIsIdle = _b[1];
    // queries
    var logout = useLogout().mutate;
    var tenant = testTenant || tenantId;
    var timeout = parseInt(testTimeout, 10) || SESSION_TIMEOUT_MS;
    var onTimeout = function () {
        if (!sessionTimeoutAffectedTenants.includes(tenant))
            return;
        logout({ tenantId: tenantId });
    };
    // check last active time after user come back to the app
    useEffect(function () {
        var msDiff = differenceInMilliseconds(Date.now(), lastActiveAt);
        if (msDiff > timeout) {
            onTimeout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastActiveAt]);
    // call on timeout after user is idle for SESSION_TIMEOUT time
    useEffect(function () {
        var timer = null;
        if (isIdle) {
            timer = setTimeout(function () {
                onTimeout();
            }, timeout);
        }
        return function () {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIdle]);
    var onIdle = function () {
        setIsIdle(true);
    };
    var onActive = function () {
        setIsIdle(false);
    };
    var onAction = function () {
        dispatch(sessionTimeoutActions.setLastActiveAt(Date.now()));
    };
    useIdleTimer({
        timeout: 10000,
        onIdle: onIdle,
        onActive: onActive,
        onAction: onAction,
        debounce: 500,
    });
    return children;
};
