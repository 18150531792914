import { createContext } from 'react';
import { noop } from 'utils';
export var ReserveCardContext = createContext({
    item: {},
    spaceItem: {},
    user: {},
    reservee: {},
    hasOccupant: false,
    isTeam: false,
    reserveDisabled: false,
    setReserveDisabled: noop,
    reserveButtonRef: null,
    durationObj: {
        duration: 0,
        allDayDuration: false,
        selectedDays: [],
    },
    setDurationObj: noop,
    spaceStatusInfo: {},
    timeZone: '',
    bookings: [],
    bookedIntervals: [],
    bookingsAllowed: true,
    bookingDeniedMessage: '',
    isOccupied: false,
    occupant: {},
    svLiveOccupant: {},
});
