var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { sizeDef, variantDef } from './config';
var getImageStyle = function (variant, size, style, placeHolderColor, errorStyles) {
    if (style === void 0) { style = {}; }
    if (errorStyles === void 0) { errorStyles = {}; }
    var roundCoefficient = variantDef[variant] === undefined ? variantDef.circle : variantDef[variant];
    var dimensions = typeof size === 'number' ? size : sizeDef[size] || sizeDef.medium;
    var baseStyle = __assign(__assign({ width: dimensions, height: dimensions, backgroundColor: placeHolderColor }, style), errorStyles);
    var height = baseStyle.height;
    var borderRadius = roundCoefficient * height;
    return __assign({ borderRadius: borderRadius }, baseStyle);
};
export { getImageStyle };
