var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useImageViewer } from 'components/layouts/ImageViewer/hooks/useImageViewer';
import { AvatarWrapper } from './styled';
import Avatar from './Avatar';
export function ViewableAvatar(props) {
    var alt = props.alt, source = props.source, avatarProps = __rest(props, ["alt", "source"]);
    var openImageViewer = useImageViewer().openImageViewer;
    var viewAvatar = function () {
        openImageViewer({ imageSource: source, imageDescription: alt });
    };
    return (React.createElement(AvatarWrapper, null,
        React.createElement(Avatar, __assign({}, avatarProps, { source: source, onImageClick: viewAvatar }))));
}
