import React from 'react';
import { useAppParams } from 'router/hooks';
import Marker from 'components/floorplan/components/Marker/Marker';
import { getShapeCoords } from 'components/floorplan/utils/shapeUtils';
import { usePerson } from 'api/queries';
import { FocusMarker } from 'components/floorplan/components/Icon/FocusMarker';
var MarkerIcon = function (_a) {
    var space = _a.space;
    var personId = useAppParams().personId;
    var id = personId ? parseInt(personId, 10) : 0;
    var personQuery = usePerson({
        id: id,
        options: {
            enabled: !!personId,
        },
    });
    var person = personQuery.data;
    var coordinate = getShapeCoords(space);
    return (React.createElement(React.Fragment, null,
        React.createElement(FocusMarker, { coordinate: coordinate }),
        React.createElement(Marker, { space: space, person: person })));
};
export default MarkerIcon;
