var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])));
export var TitleContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n  width: 350px;\n  margin-bottom: 40px;\n  margin-top: 40px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n  width: 350px;\n  margin-bottom: 40px;\n  margin-top: 40px;\n"])));
export var Title = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 700;\n  color: ", ";\n  font-size: 20px;\n  padding: 0;\n  margin: 0;\n"], ["\n  font-weight: 700;\n  color: ", ";\n  font-size: 20px;\n  padding: 0;\n  margin: 0;\n"])), function (props) { return props.theme.color1; });
export var SubTitle = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 20px;\n  padding: 0;\n  margin-top: 8px;\n"], ["\n  color: ", ";\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 20px;\n  padding: 0;\n  margin-top: 8px;\n"])), function (props) { return props.theme.color2; });
export var ButtonContents = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var StyledButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  color: rgba(255, 255, 255, 0.5);\n  height: 60px;\n  border-radius: 30px;\n  min-width: 200px;\n  font-size: 18px;\n  font-weight: 700;\n  padding: 0 30px;\n  border: none;\n  line-height: 25px;\n  outline: none;\n  cursor: pointer;\n  &:hover {\n    opacity: 0.8;\n  }\n  &:focus {\n    box-shadow: 0 0 0 2px #005fff;\n  }\n"], ["\n  background: ", ";\n  color: rgba(255, 255, 255, 0.5);\n  height: 60px;\n  border-radius: 30px;\n  min-width: 200px;\n  font-size: 18px;\n  font-weight: 700;\n  padding: 0 30px;\n  border: none;\n  line-height: 25px;\n  outline: none;\n  cursor: pointer;\n  &:hover {\n    opacity: 0.8;\n  }\n  &:focus {\n    box-shadow: 0 0 0 2px #005fff;\n  }\n"])), function (props) { return props.theme.typo2; });
export var IconButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  margin: 0;\n  padding: 0;\n  align-items: center;\n  justify-content: center;\n  height: 40px;\n  width: 40px;\n  border: none;\n  outline: none;\n  background: transparent;\n  border-radius: 20px;\n  opacity: 0.5;\n  cursor: pointer;\n  &:hover {\n    opacity: 0.8;\n  }\n  &:focus {\n    box-shadow: 0 0 0 3px #005fff;\n  }\n"], ["\n  display: flex;\n  margin: 0;\n  padding: 0;\n  align-items: center;\n  justify-content: center;\n  height: 40px;\n  width: 40px;\n  border: none;\n  outline: none;\n  background: transparent;\n  border-radius: 20px;\n  opacity: 0.5;\n  cursor: pointer;\n  &:hover {\n    opacity: 0.8;\n  }\n  &:focus {\n    box-shadow: 0 0 0 3px #005fff;\n  }\n"])));
export var Actions = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  width: 350px;\n  height: 60px;\n  margin-top: 40px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  width: 350px;\n  height: 60px;\n  margin-top: 40px;\n"])));
export var Error = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 50px;\n  width: 500px;\n  text-align: center;\n  color: red;\n  margin-top: 25px;\n"], ["\n  height: 50px;\n  width: 500px;\n  text-align: center;\n  color: red;\n  margin-top: 25px;\n"])));
export var IconContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-left: 5px;\n  color: ", ";\n  opacity: 0.5;\n"], ["\n  margin-left: 5px;\n  color: ", ";\n  opacity: 0.5;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
export var Form = styled.form(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
