import React from 'react';
import { useTranslation } from 'react-i18next';
import * as styleComponents from './styled';
var mapStyledComponents = function (isBottomButton) {
    var prefix = isBottomButton ? 'Booked' : 'BookFor';
    return {
        Wrapper: styleComponents["".concat(prefix, "Wrapper")],
        Title: styleComponents["".concat(prefix, "Title")],
        TeamWrapper: styleComponents["".concat(prefix, "TeamWrapper")],
        TeamName: styleComponents["".concat(prefix, "TeamName")],
        TeamNumberWrapper: styleComponents["".concat(prefix, "TeamNumberWrapper")],
    };
};
var TeamButton = function (_a) {
    var team = _a.team, isBottomButton = _a.isBottomButton, onPress = _a.onPress;
    var t = useTranslation().t;
    var title = isBottomButton
        ? t('layout.reserve.booked')
        : t('layout.reserve.desk.bookFor');
    var _b = mapStyledComponents(isBottomButton), Wrapper = _b.Wrapper, Title = _b.Title, TeamWrapper = _b.TeamWrapper, TeamName = _b.TeamName, TeamNumberWrapper = _b.TeamNumberWrapper;
    var TeamNumber = styleComponents.TeamNumber;
    return (React.createElement(Wrapper, null,
        React.createElement(Title, null, "".concat(title, ": ")),
        React.createElement(TeamWrapper, { "data-testid": "team-button", onClick: onPress },
            React.createElement(TeamName, null, team.name),
            React.createElement(TeamNumberWrapper, null,
                React.createElement(TeamNumber, null, team.people)))));
};
export default TeamButton;
