import { Overlay } from 'components/base/Modal/styled';
import React from 'react';
import Header from 'components/scenes/modals/components/Header';
import { useTranslation } from 'react-i18next';
import { InstructionTitle, Wrapper, InstructionsContainer, Title, } from './KeyboardAccessibility.style';
var KeyboardAccessibility = function () {
    var t = useTranslation().t;
    return (React.createElement(Overlay, null,
        React.createElement(Header, { hasAgendaButton: false, hasUserButton: false, hasDateTime: false, conciergeMenuAllowed: true, isDeskCheckInOn: false, isKiosk: false }),
        React.createElement(Wrapper, null,
            React.createElement(Title, null, t('keyboardAccessibility.floorplanTitle')),
            React.createElement("p", null, t('keyboardAccessibility.floorplanMessage1')),
            React.createElement("p", null, t('keyboardAccessibility.floorplanMessage2')),
            React.createElement(InstructionsContainer, null,
                React.createElement(InstructionTitle, null, "[ w, up arrow ]"),
                " -",
                ' ',
                t('keyboardAccessibility.upArrow'),
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ s, down arrow ]"),
                " -",
                ' ',
                t('keyboardAccessibility.downArrow'),
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ a, left arrow ]"),
                " -",
                ' ',
                t('keyboardAccessibility.leftArrow'),
                " ",
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ d, right arrow ]"),
                " -",
                ' ',
                t('keyboardAccessibility.rightArrow'),
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ 0 ]"),
                " -",
                ' ',
                t('keyboardAccessibility.zero'),
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ - / _, shift+W, shift+up arrow ]"),
                ' ',
                "- ",
                t('keyboardAccessibility.zoomOut'),
                " ",
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ = / +, shift+S, shift+down arrow ]"),
                ' ',
                "- ",
                t('keyboardAccessibility.zoomIn'),
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ r ]"),
                " -",
                ' ',
                t('keyboardAccessibility.rotateClockwise'),
                " ",
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ R ]"),
                " -",
                ' ',
                t('keyboardAccessibility.rotateCounterClockwise'),
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ f ]"),
                " -",
                ' ',
                t('keyboardAccessibility.flipHorizontally'),
                " ",
                React.createElement("br", null),
                React.createElement(InstructionTitle, null, "[ cmd/ctrl hold + drag mouse horizontally ]"),
                ' ',
                "- ",
                t('keyboardAccessibility.changeDraggingRotation'),
                React.createElement("br", null)))));
};
export default KeyboardAccessibility;
