var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ImageWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var InitialsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n"])), function (_a) {
    var textColor = _a.textColor;
    return textColor || 'inherit';
});
export var BadgeWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var Badge = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  border: ", "px solid #ffffff;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  border: ", "px solid #ffffff;\n"])), function (props) { return props.borderWidth; });
export var ImageButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: block;\n  padding: 0;\n  cursor: pointer;\n"], ["\n  display: block;\n  padding: 0;\n  cursor: pointer;\n"])));
export var AvatarWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  :focus-within {\n    .avatar__image {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n    }\n  }\n"], ["\n  :focus-within {\n    .avatar__image {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
