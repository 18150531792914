import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addDays, isDate, isSameDay } from 'date-fns';
import {
  DateFormat,
  formatConsecutiveSelectedDatesString,
  formatLocalizedDate,
  getDayStartTime,
  getUtcIsoString,
} from '@serraview/engage-shared';
import {
  FocusId,
  isDateInArray,
  noop,
  testProps,
  useConfig,
  useModal,
} from 'utils';
import {
  DATE_TIME_PICKER_VARIANT,
  DAYS_SELECTOR_NUMBER_OF_DAYS,
  MODALS,
} from 'consts';
import { inAppNotificationsActions, userConfigSelectors } from 'store';
import { isElectron } from 'utils/electron';
import {
  DaysLegend,
  DaysSelectorContainer,
  DaysWrapper,
  SelectedDates,
  SelectedDatesWrapper,
} from './styled';
import { Day } from './Day';

const getDisplayedDays = ({
  dateStart,
  selectedDays,
  disabledDays,
  numberOfDays,
  timeZone,
  locale,
}) => {
  let nextDay = isDate(dateStart) ? dateStart : new Date();
  nextDay = getDayStartTime({ date: nextDay, timeZone });

  const newDays = [];
  for (let i = 0; i < numberOfDays; i++) {
    const isDisabled = isDateInArray(nextDay, disabledDays);
    const isSelected = isDateInArray(nextDay, selectedDays);
    const label = formatLocalizedDate(nextDay, {
      timeZone,
      locale,
      format: DateFormat.weekDayNarrow,
    })
      .substr(0, 1)
      .toUpperCase();
    newDays[i] = {
      date: nextDay,
      label,
      isCurrentDate: i === 0,
      isSelected: isSelected && !isDisabled,
      isDisabled,
    };
    nextDay = addDays(nextDay, 1);
  }

  return {
    disabledDays,
    displayedDays: newDays,
  };
};

const DaysSelector = ({
  onDaySelected,
  selectedDays,
  disabledDays,
  dateStart,
  numberOfDays,
  timeZone,
  currentDayAlwaysSelected,
  // true when is from ReserveCard, false when is from ReservationAssist
  isMultiDayDeskReservation,
}) => {
  const dispatch = useDispatch();
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const isKiosk = isElectron();
  const { t } = useTranslation();
  const { kioskRestrictBookingToCurrentDay } = useConfig();
  const { openModal } = useModal();

  const shouldDisplaySelectedDates =
    isMultiDayDeskReservation && selectedDays.length;
  const { displayedDays } = getDisplayedDays({
    dateStart,
    selectedDays,
    disabledDays,
    numberOfDays,
    timeZone,
    locale,
  });

  const openTimelineDatePicker = () => {
    if (isKiosk && kioskRestrictBookingToCurrentDay) {
      dispatch(
        inAppNotificationsActions.addWarningNotification({
          message: t('layout.notifications.kioskCurrentDayBookingOnly'),
        }),
      );
      return;
    }
    openModal(MODALS.DATE_TIME_PICKER, {
      variant: DATE_TIME_PICKER_VARIANT.TIMELINE,
      returnFocusTo: FocusId.ReserveSelectedDatesButton,
    });
  };

  const onPressDay = ({ date, isSelected, isCurrentDate }) => {
    if (isCurrentDate && currentDayAlwaysSelected) {
      return;
    }

    if (isKiosk && kioskRestrictBookingToCurrentDay) {
      dispatch(
        inAppNotificationsActions.addWarningNotification({
          message: t('layout.notifications.kioskCurrentDayBookingOnly'),
        }),
      );
      return;
    }

    if (isSelected) {
      onDaySelected(
        selectedDays.filter((selectedDate) => !isSameDay(selectedDate, date)),
      );
    } else {
      onDaySelected([...selectedDays, date]);
    }
  };

  return (
    <DaysSelectorContainer>
      <DaysWrapper>
        <DaysLegend>{t('accessibilityLabels.daysSelector')}</DaysLegend>
        {displayedDays.map((day) => (
          <Day
            day={day}
            key={getUtcIsoString(day.date)}
            locale={locale}
            onPressDay={onPressDay}
          />
        ))}
      </DaysWrapper>
      {shouldDisplaySelectedDates ? (
        <SelectedDatesWrapper>
          <SelectedDates
            id={FocusId.ReserveSelectedDatesButton}
            data-testid="timeline-date"
            as="button"
            onClick={openTimelineDatePicker}
            {...testProps(t, 'accessibilityLabels.selectedDates').props}
          >
            {formatConsecutiveSelectedDatesString(
              selectedDays,
              timeZone,
              locale,
            )}
          </SelectedDates>
        </SelectedDatesWrapper>
      ) : null}
    </DaysSelectorContainer>
  );
};

DaysSelector.propTypes = {
  selectedDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onDaySelected: PropTypes.func,
  isMultiDayDeskReservation: PropTypes.bool,
  disabledDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  numberOfDays: PropTypes.number,
  dateStart: PropTypes.instanceOf(Date),
  timeZone: PropTypes.string,
  currentDayAlwaysSelected: PropTypes.bool,
};

DaysSelector.defaultProps = {
  selectedDays: [],
  onDaySelected: noop,
  disabledDays: [],
  isMultiDayDeskReservation: false,
  numberOfDays: DAYS_SELECTOR_NUMBER_OF_DAYS,
  dateStart: new Date(),
  currentDayAlwaysSelected: false,
  timeZone: null,
};

export default DaysSelector;
