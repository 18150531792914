import React, { memo } from 'react';
import { Mask } from 'base-components';
import { Button as StyledButton, ButtonText, Wrapper } from './styled';
var Button = function (_a) {
    var children = _a.children, text = _a.text, onClick = _a.onClick, isLoading = _a.isLoading, disabled = _a.disabled, _b = _a.backgroundColor, backgroundColor = _b === void 0 ? '#FFF' : _b, _c = _a.opacity, opacity = _c === void 0 ? 1 : _c, _d = _a.color, color = _d === void 0 ? '#000' : _d, _e = _a.style, style = _e === void 0 ? {} : _e, className = _a.className, id = _a.id;
    var renderComponent = function () {
        if (children && React.isValidElement(children)) {
            return children;
        }
        if (text) {
            return (React.createElement(ButtonText, { color: color },
                React.createElement("span", { className: "text" }, text)));
        }
        return null;
    };
    return (React.createElement(Wrapper, { backgroundColor: backgroundColor, opacity: opacity, className: className },
        React.createElement(Mask, { isLoading: !!isLoading }),
        React.createElement(StyledButton, { id: id, onClick: onClick, disabled: disabled, style: style, "aria-label": text, "aria-disabled": disabled, className: "btn" }, renderComponent())));
};
export default memo(Button);
