import styled from 'styled-components';

export const Subtitle = styled.span`
  color: ${(props) => props.theme.typo2};
`;

export const SubtitlePersonOfInterest = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color9};
`;
