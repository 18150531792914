var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 350px;\n  height: 50px;\n  border: solid 1px;\n  border-color: ", ";\n  border-width: 1px;\n  box-shadow: ", ";\n  border-radius: 25px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 350px;\n  height: 50px;\n  border: solid 1px;\n  border-color: ", ";\n  border-width: 1px;\n  box-shadow: ", ";\n  border-radius: 25px;\n"])), function (props) { return props.theme.color4; }, function (props) { return (props.hasFocus ? '0 0 0 2px #005FFF' : 'none'); });
var Domain = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #eff2f6;\n  transform: rotateY(", ");\n  direction: ", ";\n  border-radius: 25px;\n  height: 100%;\n  padding: 0 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #eff2f6;\n  transform: rotateY(", ");\n  direction: ", ";\n  border-radius: 25px;\n  height: 100%;\n  padding: 0 16px;\n"])), function (_a) {
    var theme = _a.theme;
    return (theme.isLayoutRTL ? '180deg' : '0');
}, function (_a) {
    var theme = _a.theme;
    return (theme.isLayoutRTL ? 'rtl' : 'ltr');
});
var WorkspaceInput = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 90%;\n  margin: 0 6px 0 25px;\n  font-size: 12pt;\n  outline: none;\n  border: none;\n"], ["\n  width: 100%;\n  height: 90%;\n  margin: 0 6px 0 25px;\n  font-size: 12pt;\n  outline: none;\n  border: none;\n"])));
export { Container, Domain, WorkspaceInput };
var templateObject_1, templateObject_2, templateObject_3;
