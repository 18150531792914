import styled, { useTheme } from 'styled-components';
import { FROM_NOW, getTimeFromNow } from 'layouts/Agenda/utils';
import {
  LIST_ITEM_HEIGHT,
  LIST_ITEM_PADDING_VERTICAL,
} from 'layouts/Agenda/content/styled';

const AgendaEventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${LIST_ITEM_PADDING_VERTICAL}px;
`;

const AgendaWrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const AgendaWrapperItem = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: ${({ theme }) =>
    theme.isLayoutRTL ? 'flex-end' : 'space-between'};
  align-items: center;
  border-radius: 4px;
  padding: ${LIST_ITEM_PADDING_VERTICAL}px ${LIST_ITEM_PADDING_VERTICAL}px;
  margin-bottom: ${LIST_ITEM_PADDING_VERTICAL}px;
  height: ${(props) =>
    props.extraHeight
      ? props.extraHeight + LIST_ITEM_HEIGHT
      : LIST_ITEM_HEIGHT}px;
  cursor: pointer;
  ${(props) => props.$style || {}}
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
  text-align: start;
`;

const AgendaItemTitle = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 0;
  margin-bottom: 0;

  & .separator {
    margin: 0 5px;
  }

  & .building-name {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) => props.$style || {}}
`;

const AgendaItemBookedFor = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;

  ${(props) => props.$style || {}}
`;

const AgendaItemTime = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  max-width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.$style || {}}
`;

const LocalTime = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  padding: 10px;
  text-align: right;
`;

const useAgendaItemStyle = (start, end) => {
  const { typo1, color1, color2, color4, color6, color9 } = useTheme();
  const timing = getTimeFromNow(start, end);
  const localStyles = {
    wrapperBackgroundColor: color6,
    titleColor: '#000000',
    titleOpacity: 1,
    timeColor: '#ECF1F8',
    timeOpacity: 1,
    actionBackgroundColor: color2,
    actionTextColor: color6,
  };
  switch (timing) {
    case FROM_NOW.PAST:
      localStyles.wrapperBackgroundColor = color4;
      localStyles.titleColor = color1;
      localStyles.titleOpacity = 0.7;
      localStyles.timeColor = color1;
      localStyles.timeOpacity = 0.7;
      break;
    case FROM_NOW.ONGOING:
      localStyles.wrapperBackgroundColor = color9;
      localStyles.titleColor = typo1;
      localStyles.timeColor = typo1;
      localStyles.actionBackgroundColor = color6;
      localStyles.actionTextColor = color9;
      break;
    case FROM_NOW.FUTURE:
      localStyles.wrapperBackgroundColor = `${color9}1A`;
      localStyles.titleColor = color1;
      localStyles.timeColor = color1;
      localStyles.timeOpacity = 0.7;
      localStyles.actionBackgroundColor = color1;
      localStyles.actionTextColor = color6;

      break;
    default:
      break;
  }
  return {
    wrapper: {
      backgroundColor: localStyles.wrapperBackgroundColor,
    },
    title: {
      color: localStyles.titleColor,
      opacity: localStyles.titleOpacity,
    },
    time: {
      color: localStyles.timeColor,
      opacity: localStyles.timeOpacity,
    },
  };
};

export {
  AgendaEventWrapper,
  AgendaWrapperContent,
  AgendaWrapperItem,
  AgendaItemTitle,
  AgendaItemBookedFor,
  AgendaItemTime,
  LocalTime,
  IconWrapper,
  useAgendaItemStyle,
};
