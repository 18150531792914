import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHasTeamBookingsInInterval } from '@serraview/engage-shared/api';
import { useReservationFilters } from 'utils/hooks';
import { tenantSelectors } from 'store';
import { TeamReservationButton } from './TeamMembers.style';
export function TeamReserveButton(_a) {
    var team = _a.team, onPress = _a.onPress;
    var t = useTranslation().t;
    var id = team.id, favPeopleTeam = team.favPeopleTeam;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var filterDateStart = useReservationFilters(true).dateStart;
    var hasTeamBookings = useHasTeamBookingsInInterval({
        filterDateStart: filterDateStart,
        teamId: id,
        tenantId: tenantId,
        options: {
            enabled: !favPeopleTeam,
        },
    });
    var shouldDisableReserveButton = team.people <= 0 || hasTeamBookings;
    var reservationButtonTitle = shouldDisableReserveButton
        ? t('layout.teamMembersList.reservationExists')
        : t('layout.teamMembersList.teamReservation');
    var _b = useState(true), showButton = _b[0], setShowButton = _b[1];
    var onReservationButtonClick = function () {
        onPress();
        setShowButton(false);
    };
    if (!showButton)
        return null;
    return (React.createElement(TeamReservationButton, { onClick: onReservationButtonClick, disabled: shouldDisableReserveButton }, reservationButtonTitle));
}
