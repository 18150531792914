import { useEffect } from 'react';
import { RESERVATION_ANALYTICS_TYPE } from 'consts';
import Analytics from 'analytics';
var useReserveAnalytics = function (spaceItem) {
    return useEffect(function () {
        if (spaceItem) {
            if (spaceItem.isDesk) {
                // Desk card loaded
                Analytics.track('EVT-041');
                var code = spaceItem.available ? 'EVT-029' : 'EVT-031';
                Analytics.track(code, spaceItem);
            }
            else {
                // Space card loaded
                Analytics.track('EVT-034');
                // Floorplan - Space selected
                Analytics.track('EVT-030', spaceItem);
            }
        }
        return function () {
            if (spaceItem) {
                var code = spaceItem.isDesk ? 'EVT-046' : 'EVT-039';
                Analytics.track(code);
            }
        };
    }, [spaceItem]);
};
var trackReservationAnalytics = function (_a) {
    var spaceItem = _a.spaceItem, analyticsType = _a.analyticsType, durationPayload = _a.durationPayload, reservee = _a.reservee, reservation = _a.reservation;
    var isDesk = spaceItem.isDesk;
    // eslint-disable-next-line default-case
    switch (analyticsType) {
        case RESERVATION_ANALYTICS_TYPE.START: {
            Analytics.track(isDesk ? 'EVT-043' : 'EVT-036', spaceItem);
            break;
        }
        case RESERVATION_ANALYTICS_TYPE.SUCCESS: {
            Analytics.track(isDesk ? 'EVT-044' : 'EVT-037', reservation);
            break;
        }
        case RESERVATION_ANALYTICS_TYPE.FAIL: {
            Analytics.track(isDesk ? 'EVT-045' : 'EVT-038', spaceItem);
            break;
        }
        case RESERVATION_ANALYTICS_TYPE.DURATION_CHANGED: {
            Analytics.track(isDesk ? 'EVT-042' : 'EVT-035', durationPayload);
            break;
        }
        case RESERVATION_ANALYTICS_TYPE.ADMIN_BUTTON_TAP: {
            Analytics.track('EVT-083', spaceItem);
            break;
        }
        case RESERVATION_ANALYTICS_TYPE.RESERVEE_SELECTED: {
            Analytics.track('EVT-084', { spaceItem: spaceItem, reservee: reservee });
            break;
        }
    }
};
export { RESERVATION_ANALYTICS_TYPE, trackReservationAnalytics, useReserveAnalytics, };
