import React, { useLayoutEffect, useRef, useState, } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import AnimateHeight from 'react-animate-height';
export var AnimatedHeight = function (_a) {
    var children = _a.children, _b = _a.duration, duration = _b === void 0 ? 150 : _b;
    var containerRef = useRef(null);
    var _c = useState('auto'), height = _c[0], setHeight = _c[1];
    useLayoutEffect(function () {
        var _a, _b;
        setHeight((_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height) !== null && _b !== void 0 ? _b : 'auto');
    }, [containerRef]);
    useResizeObserver(containerRef, function (entry) {
        return setHeight(entry.contentRect.height);
    });
    return (React.createElement(AnimateHeight, { height: height, duration: duration },
        React.createElement("div", { id: "animated-height-div", ref: containerRef }, children)));
};
