var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { testProps } from 'utils/testProps';
import { Icon } from '../Icons/Icon';
import { Wrapper, ClearButton, FiltersStatusIconWrapper, FiltersStatusText, } from './styled';
export var GlobalSearchClear = function (_a) {
    var _b = _a.isFilterChanged, isFilterChanged = _b === void 0 ? false : _b, onPress = _a.onPress;
    // @ts-ignore
    var t = useTranslation().t;
    var _c = testProps(t, ['layout.globalSearch.filtersAccent', 'layout.globalSearch.filters'], function (_a) {
        var filtersAccentText = _a[0], filtersText = _a[1];
        return "".concat(filtersAccentText, " ").concat(filtersText);
    }), a11yAndTestProps = _c.props, _d = _c.text, filtersAccentText = _d[0], filtersText = _d[1];
    return (React.createElement(Wrapper, null, isFilterChanged && (React.createElement(ClearButton
    // @ts-ignore
    , __assign({ 
        // @ts-ignore
        clickable: isFilterChanged, tabIndex: isFilterChanged ? 0 : -1, onClick: onPress }, a11yAndTestProps),
        React.createElement(FiltersStatusIconWrapper, null,
            React.createElement(Icon, { name: "close", size: 12 })),
        React.createElement(FiltersStatusText, { bold: true }, filtersAccentText),
        React.createElement(FiltersStatusText, null, filtersText)))));
};
