var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Button } from 'components/base';
export var LocationSelectorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: calc(100vh - 400px);\n  width: 100%;\n  overflow-y: scroll;\n  padding: 30px 20px 0 20px;\n  margin-bottom: 30px;\n  display: flex;\n  flex-direction: column;\n  color: ", ";\n  border-top: 1px solid ", ";\n  @media (max-width: 450px) {\n    padding-left: 15px;\n    padding-right: 15px;\n  }\n"], ["\n  max-height: calc(100vh - 400px);\n  width: 100%;\n  overflow-y: scroll;\n  padding: 30px 20px 0 20px;\n  margin-bottom: 30px;\n  display: flex;\n  flex-direction: column;\n  color: ", ";\n  border-top: 1px solid ", ";\n  @media (max-width: 450px) {\n    padding-left: 15px;\n    padding-right: 15px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, function (_a) {
    var theme = _a.theme;
    return theme.color4;
});
export var RegionHeader = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px 30px;\n  border-radius: 6px;\n  background: ", ";\n  cursor: pointer;\n  color: ", ";\n  & .region-name {\n    font-weight: 600;\n    font-size: 17px;\n    font-style: normal;\n    line-height: 22px;\n  }\n  & .region-icon {\n    transform-origin: ", ";\n    transition: transform 200ms;\n    transform: rotate(", ");\n  }\n  & + div {\n    margin-top: 10px;\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px 30px;\n  border-radius: 6px;\n  background: ", ";\n  cursor: pointer;\n  color: ", ";\n  & .region-name {\n    font-weight: 600;\n    font-size: 17px;\n    font-style: normal;\n    line-height: 22px;\n  }\n  & .region-icon {\n    transform-origin: ", ";\n    transition: transform 200ms;\n    transform: rotate(", ");\n  }\n  & + div {\n    margin-top: 10px;\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return (isActive ? theme.typo2 : 'white');
}, function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return (isActive ? theme.typo1 : theme.typo2);
}, function (_a) {
    var isActive = _a.isActive;
    return isActive ? '5px center' : '2px center';
}, function (_a) {
    var isActive = _a.isActive;
    return (isActive ? '-90deg' : '90deg');
});
export var BuildingsListWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 10px 30px;\n  border-radius: 0 0 6px 6px;\n  border-top: none;\n  margin-top: 0 !important;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 10px 30px;\n  border-radius: 0 0 6px 6px;\n  border-top: none;\n  margin-top: 0 !important;\n"])));
export var RegionWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  border: 1px solid ", ";\n  border-radius: 6px;\n  flex-shrink: 0;\n  margin: 0 10px 10px 10px;\n  // Fix for border that appears on top of region wrapper's skeleton\n  & > span {\n    line-height: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  border: 1px solid ", ";\n  border-radius: 6px;\n  flex-shrink: 0;\n  margin: 0 10px 10px 10px;\n  // Fix for border that appears on top of region wrapper's skeleton\n  & > span {\n    line-height: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color4;
});
export var BuildingWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n  padding: 15px 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n  padding: 15px 0;\n"])));
export var BuildingName = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  color: ", ";\n  padding-left: 0;\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  color: ", ";\n  padding-left: 0;\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme, isActive = _a.isActive;
    return (isActive ? theme.typo2 : theme.gray4);
});
export var FloorsListWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding-top: 20px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding-top: 20px;\n"])));
export var LabelsContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
export var LabelFloorAvailability = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 8px;\n  text-transform: uppercase;\n  opacity: 0.4;\n"], ["\n  font-size: 8px;\n  text-transform: uppercase;\n  opacity: 0.4;\n"])));
export var FloorWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  cursor: pointer;\n  margin: 0 -20px;\n  padding: 20px;\n  border-bottom: 1px solid ", ";\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n"], ["\n  display: flex;\n  cursor: pointer;\n  margin: 0 -20px;\n  padding: 20px;\n  border-bottom: 1px solid ", ";\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color4;
});
export var FloorName = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  color: ", ";\n  padding-left: 0;\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  font-style: normal;\n  font-weight: bold;\n  font-size: 16px;\n  line-height: 22px;\n  color: ", ";\n  padding-left: 0;\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme, isActive = _a.isActive;
    return (isActive ? theme.typo2 : theme.gray4);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
