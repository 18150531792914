import React from 'react';
import { useDrawerContext } from './Drawer';
/**
 * Renders drawer's card content if drawer is not minimized.
 * Otherwise, renders nothing.
 */
export function DrawerCardContent(_a) {
    var children = _a.children;
    var minimized = useDrawerContext().minimized;
    return React.createElement(React.Fragment, null, minimized ? null : children);
}
