import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from 'base-components';
import { Z_INDEX } from 'consts';
import { useSetPageTitle } from 'utils/hooks';

const Wrapper = styled.div`
  position: absolute;
  background: rgba(3, 13, 48, 0.7);
  backdrop-filter: blur(20px);
  z-index: ${Z_INDEX.NO_LOCATION_BACKGROUND};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Text)`
  font-weight: bold;
  color: ${(props) => props.theme.typo1};
`;

const Description = styled(Text)`
  color: ${(props) => props.theme.typo1};
`;

const NoLocationBackground = () => {
  const { t } = useTranslation();

  useSetPageTitle(t('documentTitles.noLocation'));

  return (
    <Wrapper>
      <Title as="h3">{t('components.noLocationBackground.getStarted')}</Title>
      <Description as="h6">
        {t('components.noLocationBackground.select')}
      </Description>
    </Wrapper>
  );
};

export default memo(NoLocationBackground);
