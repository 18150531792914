import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FlatList } from 'base-components';
import { useSearch } from 'api/queries/useSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
  mapRestrictToFavouriteType,
  SEARCH_QUERY_KEY,
  useToggleFavouriteItem,
} from '@serraview/engage-shared';
import {
  floorplanActions,
  globalSearchSelectors,
  tenantSelectors,
} from 'store';
import { GLOBAL_SEARCH_RESULT_ID, PATH_SEGMENT } from 'consts';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared/constants';
import {
  useFavouritesMutationErrorHandler,
  useGenerateCurrentLocationPath,
  useRestrictTo,
} from 'utils/hooks';
import PersonItem from 'components/layouts/GlobalSearch/PersonItem/PersonItem';
import { isObjectEmpty, pipe } from 'utils';
import SpaceItem from 'components/layouts/GlobalSearch/SpaceItem';
import SectionHeader from 'components/layouts/GlobalSearch/SectionHeader';
import {
  GlobalSearchOuterWrapper,
  GlobalSearchResultsWrapper,
} from 'components/layouts/GlobalSearch/styled';
import TeamsSectionHeader from 'components/layouts/GlobalSearch/TeamsSectionHeader';
import { generateFloorPath, joinPaths } from 'router/utils';
import { useAppLocation } from 'router/hooks';
import { useTranslation } from 'react-i18next';
import { useFavouritePeople } from 'api/queries/useFavouritePeople';
import GlobalSearchNoData from './GlobalSearchNoData';
import DeskItem from './DeskItem';
import FavouriteItem from './FavouriteItem';
import TeamItem from './TeamItem';
import SearchItemSkeleton from './SearchItemSkeleton';
import {
  addFavoritePeopleToTeamsList,
  composeItems,
  composeItemsWithPeopleSections,
  composeItemsWithSections,
  composeItemsWithTeamSections,
  SECTION_HEADER_ITEM_TYPE,
} from './utils';

const EMPTY_ARRAY = [];

const GlobalSearchResults = ({
  renderItemOverride,
  showUserFirst,
  restrictTo: restrictToFromProps,
  useSections,
  showToggleFavourite,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useAppLocation();
  const currentLocation = useSelector(tenantSelectors.getCurrentLocation);
  const currentLocationPath = useGenerateCurrentLocationPath();
  const restrictToFromPath = useRestrictTo();
  const restrictTo = restrictToFromProps ?? restrictToFromPath;
  const searchString = useSelector(globalSearchSelectors.getGlobalSearchValue);

  const isTeamFilter = restrictTo === RESTRICT_TO_VALUES.TEAM;

  const {
    data,
    error,
    isError,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    queryKey,
  } = useSearch({ showUserFirst, restrictTo });

  const { isLoading: favPeopleLoading, peopleCount: favPeopleCount } =
    useFavouritePeople({ enabled: isTeamFilter });

  const dataPages = data?.pages || EMPTY_ARRAY;

  const { onError } = useFavouritesMutationErrorHandler(restrictTo);
  const { mutate: toggleFavouriteItem } = useToggleFavouriteItem(
    [SEARCH_QUERY_KEY, ...queryKey],
    {
      onError,
    },
  );

  const listRef = useRef(null);

  const items = useMemo(() => {
    if (!useSections) {
      return composeItems(dataPages);
    }
    if (
      restrictTo === RESTRICT_TO_VALUES.DESK ||
      restrictTo === RESTRICT_TO_VALUES.SPACE
    ) {
      return composeItemsWithSections(dataPages);
    }

    if (restrictTo === RESTRICT_TO_VALUES.TEAM) {
      const favPeopleTeamName = t('common.favouritePeopleTeamName');
      const nameIncludesSearchString = favPeopleTeamName.includes(
        searchString.trim(),
      );

      if (nameIncludesSearchString) {
        return pipe(
          addFavoritePeopleToTeamsList({
            favPeopleCount,
            favPeopleLoading,
            favPeopleTeamName,
          }),
          composeItemsWithTeamSections,
        )(dataPages);
      }
    }

    return composeItemsWithPeopleSections(dataPages);
  }, [
    dataPages,
    favPeopleCount,
    favPeopleLoading,
    restrictTo,
    useSections,
    searchString,
  ]);

  const onToggleFavourites = ({ id, isFavourite }) => {
    const type = mapRestrictToFavouriteType(restrictTo);
    toggleFavouriteItem({ id, type, isCurrentlyFavourite: isFavourite });
  };

  const itemSizes = useRef({});

  const onSizeFound = useCallback(({ height }, index) => {
    // if size don't change we don't need to re-render list
    if (height !== itemSizes.current[index]) {
      itemSizes.current = { ...itemSizes.current, [index]: height };
      listRef.current?.resetAfterIndex(index);
    }
  }, []);

  useEffect(() => {
    // clear sizes map on switching between people/spaces/desks
    itemSizes.current = {};
  }, [restrictTo, dataPages]);

  const numberOfItems =
    items.length && hasNextPage ? items.length + 1 : items.length;
  // Set the numberOfItems to a large number to force the loading state to display;
  const totalItems = numberOfItems === 0 ? 10000 : numberOfItems;

  useEffect(() => {
    dispatch(floorplanActions.setFocusedSpace(null));
  }, [dispatch]);

  // Set space's layout based on item type
  const onSpaceSelect = async (space, path) => {
    navigate(joinPaths(path, PATH_SEGMENT.SPACES, space.id), {
      state: {
        fromPath: pathname,
        space,
      },
    });
  };

  // Set desk's layout based on item type
  const onDeskSelect = async (desk, path) => {
    navigate(joinPaths(path, PATH_SEGMENT.DESKS, desk.id), {
      state: {
        fromPath: pathname,
        desk,
      },
    });
  };

  const onPersonSelect = (person) => {
    navigate(
      joinPaths(currentLocationPath, PATH_SEGMENT.PEOPLE, `${person.id}`),
      {
        state: {
          fromPath: pathname,
          person,
          searchSpaceId: person.location?.spaceId,
        },
      },
    );
  };

  const onTeamSelect = useCallback(
    (team) =>
      navigate(
        joinPaths(currentLocationPath, PATH_SEGMENT.TEAMS, `${team.id}`),
        {
          state: {
            team,
          },
        },
      ),
    [currentLocationPath, navigate],
  );

  // both PersonItem and DeskItem has the same skeleton shape, so lets just reuse it as SearchItemSkeleton
  const renderLoader = ({ style }) => (
    <div style={style} data-testid="search-item-skeleton">
      <SearchItemSkeleton />
    </div>
  );

  const renderItem = ({ item, style, index }) => {
    if (renderItemOverride) {
      return renderItemOverride({ item, style, index });
    }

    let path = currentLocationPath;
    const location = item?.location;

    if (location && !isObjectEmpty(location)) {
      const { floorId } = location;
      path = generateFloorPath(floorId);
    }

    const showBadge =
      item.location?.buildingId === currentLocation?.building?.id;

    switch (item.type) {
      case RESTRICT_TO_VALUES.DESK: {
        return (
          <FavouriteItem
            item={item}
            style={style}
            onToggleFavourites={onToggleFavourites}
            showToggleFavourite={showToggleFavourite}
          >
            <DeskItem
              item={item}
              as="div"
              onPress={(desk) => onDeskSelect(desk, path)}
              showBadge={showBadge}
              index={index}
              onSizeFound={onSizeFound}
            />
          </FavouriteItem>
        );
      }
      case RESTRICT_TO_VALUES.SPACE: {
        return (
          <FavouriteItem
            item={item}
            style={style}
            onToggleFavourites={onToggleFavourites}
            showToggleFavourite={showToggleFavourite}
          >
            <SpaceItem
              as="div"
              item={item}
              onPress={(space) => onSpaceSelect(space, path)}
              showBadge={showBadge}
              index={index}
              onSizeFound={onSizeFound}
            />
          </FavouriteItem>
        );
      }
      case RESTRICT_TO_VALUES.PERSON: {
        return (
          <FavouriteItem
            item={item}
            style={style}
            onToggleFavourites={onToggleFavourites}
            showToggleFavourite={showToggleFavourite}
          >
            <PersonItem
              item={item}
              as="div"
              onClick={onPersonSelect}
              index={index}
              onSizeFound={onSizeFound}
            />
          </FavouriteItem>
        );
      }
      case RESTRICT_TO_VALUES.TEAM: {
        return (
          <TeamItem
            style={style}
            item={item}
            onPress={onTeamSelect}
            index={index}
            onSizeFound={onSizeFound}
          />
        );
      }
      case SECTION_HEADER_ITEM_TYPE: {
        if (isTeamFilter) {
          return <TeamsSectionHeader title={item.title} style={style} />;
        }

        return <SectionHeader style={style} item={item} />;
      }
      default:
        return null;
    }
  };

  const getItemSize = (index) => {
    const item = items[index];

    // if there are no item by given index get DeskItem or TeamsSectionHeader size as default size
    if (!item) {
      // for some reason react-window VariableSizeList does not call itemSize prop for 0 index second time
      // first item for team filter will always be a section header, that's why we know it's size
      if (isTeamFilter && index === 0) {
        return TeamsSectionHeader.getSize();
      }
      return DeskItem.getSize();
    }

    if (item.type === SECTION_HEADER_ITEM_TYPE) {
      return isTeamFilter
        ? TeamsSectionHeader.getSize()
        : SectionHeader.getSize(item);
    }

    if (itemSizes.current[index]) {
      return itemSizes.current[index];
    }

    switch (item.type) {
      case RESTRICT_TO_VALUES.DESK: {
        return DeskItem.getSize();
      }
      case RESTRICT_TO_VALUES.SPACE: {
        return SpaceItem.getSize();
      }
      case RESTRICT_TO_VALUES.PERSON: {
        return PersonItem.getSize();
      }
      default:
        return DeskItem.getSize();
    }
  };

  const globalSearchOuterWrapperId = `${GLOBAL_SEARCH_RESULT_ID}${
    restrictTo ? `_${restrictTo}` : ''
  }`;

  if (isError) {
    return <p data-testid="search-results-error">Error: {error.message}</p>;
  }

  if (!isFetching && numberOfItems === 0) {
    return <GlobalSearchNoData htmlId={globalSearchOuterWrapperId} />;
  }

  return (
    <GlobalSearchOuterWrapper
      id={globalSearchOuterWrapperId}
      $isTeamFilter={isTeamFilter}
    >
      <GlobalSearchResultsWrapper>
        <FlatList
          listRef={listRef}
          fixed={false}
          hasNextPage={hasNextPage}
          isNextPageLoading={isFetchingNextPage}
          data={items}
          itemCount={totalItems}
          loadNextPage={fetchNextPage}
          renderLoader={renderLoader}
          renderItem={renderItem}
          itemSize={getItemSize}
        />
      </GlobalSearchResultsWrapper>
    </GlobalSearchOuterWrapper>
  );
};

GlobalSearchResults.propTypes = {
  renderItemOverride: PropTypes.func,
  showUserFirst: PropTypes.bool,
  restrictTo: PropTypes.oneOf(Object.values(RESTRICT_TO_VALUES)),
  showToggleFavourite: PropTypes.bool,
  useSections: PropTypes.bool,
};
GlobalSearchResults.defaultProps = {
  renderItemOverride: null,
  showUserFirst: false,
  restrictTo: undefined,
  showToggleFavourite: false,
  useSections: true,
};

export default memo(GlobalSearchResults);
