import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { globalSearchActions } from 'store';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../SearchInput';
function GlobalSearchInput() {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var placeholderText = t('layout.globalSearch.inputPlaceholder');
    var setSearchValue = function (searchQuery) {
        return dispatch(globalSearchActions.setSearchValue(searchQuery));
    };
    var clearSearchValue = function () {
        return dispatch(globalSearchActions.clearSearchValue());
    };
    return (React.createElement(SearchInput, { setSearchValue: setSearchValue, clearSearchValue: clearSearchValue, searchPlaceholderText: placeholderText }));
}
export default memo(GlobalSearchInput);
