import React, { useMemo, useState } from 'react';
import { groupConfigsByBuildingAndFloor, } from 'components/kiosk/AvailableKiosks/utils';
import { LocationSelectorWrapper, RegionHeader, RegionWrapper, } from 'components/layouts/LocationSelector/components/styled';
import { AvailableKioskFloors } from 'components/kiosk/AvailableKiosks/AvailableKioskFloors';
import { Icon } from 'components/base';
var AvailableKioskBuildings = function (_a) {
    var data = _a.data;
    var _b = useState(null), openedId = _b[0], setOpenedId = _b[1];
    var buildings = useMemo(function () {
        return groupConfigsByBuildingAndFloor(data, function (_a) {
            var buildingName = _a.buildingName, buildingId = _a.buildingId;
            return ({
                id: buildingId,
                name: buildingName,
            });
        });
    }, [data]);
    var renderBuilding = function (_a) {
        var id = _a.id, name = _a.name, kioskOriginalData = _a.kioskOriginalData;
        var isOpened = id === openedId;
        return (React.createElement(RegionWrapper, { key: "".concat(id, "-").concat(name) },
            React.createElement(RegionHeader, { isActive: isOpened, onClick: function () { return setOpenedId(isOpened ? null : id); } },
                React.createElement("div", { className: "region-name" }, name),
                React.createElement("div", { className: "region-icon" },
                    React.createElement(Icon, { name: "chevron-right", size: 14 }))),
            isOpened && React.createElement(AvailableKioskFloors, { data: kioskOriginalData })));
    };
    return (React.createElement(LocationSelectorWrapper, null, buildings.map(renderBuilding)));
};
export default AvailableKioskBuildings;
