import PropTypes from 'prop-types';
import { eventPropTypes } from './event';

const notificationPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  localTime: PropTypes.string,
  location: PropTypes.string,
  checkInStartDate: PropTypes.string,
  checkInCloseDate: PropTypes.string,
  expirationDate: PropTypes.string,
  fireDate: PropTypes.string,
  isRead: PropTypes.bool,
  event: PropTypes.shape(eventPropTypes),
};

Object.defineProperty(notificationPropTypes, 'defaultProps', {
  value: {
    localTime: null,
    location: null,
    checkInStartDate: null,
    checkInCloseDate: null,
    expirationDate: null,
    fireDate: null,
    isRead: null,
    event: eventPropTypes.defaultProps,
  },
});

export { notificationPropTypes };
