import React, { memo, useCallback, useEffect } from 'react';
import BookingCardSkeleton from 'components/layouts/Booking/Card/BookingCardSkeleton';
import { useTranslation } from 'react-i18next';
import { EmptyCard } from 'base-components';
import { MODALS, SEARCH_QUERY } from 'consts';
import { useOnNavigateBack } from 'utils/hooks/useOnNavigateBack';
import { useBookingDetails } from 'api/queries/useBookingDetails';
import BookingCard from 'components/layouts/Booking/Card/BookingCard';
import { useShowLocation, useGoBackFromBooking, } from 'components/layouts/Booking/hooks';
var Booking = function (_a) {
    var event = _a.event;
    var t = useTranslation().t;
    var _b = event || {}, spaceId = _b.spaceId, meetingId = _b.meetingId;
    var _c = useBookingDetails(spaceId, meetingId), booking = _c.data, isFetching = _c.isFetching, isFetched = _c.isFetched, isSuccess = _c.isSuccess, isIdle = _c.isIdle, isError = _c.isError;
    var _d = useGoBackFromBooking({ booking: booking }), goBack = _d.goBack, openModal = _d.openModal;
    useOnNavigateBack(goBack);
    var addUrlSuffix = useCallback(function (location) {
        var _a = booking || {}, spaceId = _a.spaceId, isDesk = _a.isDesk;
        return "".concat(location, "?").concat(isDesk ? SEARCH_QUERY.DESK : SEARCH_QUERY.SPACE, "=").concat(spaceId);
    }, [booking]);
    var showLocation = useShowLocation({ booking: booking, addUrlSuffix: addUrlSuffix }).showLocation;
    var bookingQueryIsLoading = isFetching || !isFetched;
    var querySuccess = isSuccess && isFetched;
    var queryError = isIdle || // This only happens if a query is initialized with enabled: false.
        isError;
    var showLocationUnavailable = useCallback(function () {
        openModal(MODALS.BOOKING_LOCATION_UNAVAILABLE, { event: event }, { shouldClearBookingQuery: false });
    }, [event, openModal]);
    useEffect(function () {
        if (querySuccess &&
            (booking === null || booking === void 0 ? void 0 : booking.isInInactiveLocation) &&
            !event.locationUnavailableWasDisplayed) {
            showLocationUnavailable();
        }
    }, [
        booking === null || booking === void 0 ? void 0 : booking.isInInactiveLocation,
        event.locationUnavailableWasDisplayed,
        querySuccess,
        showLocationUnavailable,
    ]);
    if (bookingQueryIsLoading) {
        return React.createElement(BookingCardSkeleton, null);
    }
    if (querySuccess && !!booking) {
        return (React.createElement(BookingCard, { booking: booking, onBack: goBack, onShowLocation: showLocation }));
    }
    if (queryError || (querySuccess && !booking)) {
        var error = t('common.networkError');
        return React.createElement(EmptyCard, { iconName: "info" }, error);
    }
    return null;
};
export default memo(Booking);
