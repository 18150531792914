import styled from 'styled-components';
import { fontClassesHeights, fontTagHeights } from 'theme';

const LIST_ITEM_PADDING_VERTICAL = 10;
const LIST_ITEM_HEIGHT = 52;
const LIST_ITEM_EXTRA_HEIGHT = 16;

const AgendaListWrapper = styled.div`
  flex: 1 1 auto;
`;

const AgendaItemWrapper = styled.li`
  ${(props) => props.$style || {}}
  display: flex;
  flex-direction: ${({ theme }) => (theme.isLayoutRTL ? 'row-reverse' : 'row')};
  padding: ${LIST_ITEM_PADDING_VERTICAL}px 0;
`;

const getAgendaItemSize = () =>
  LIST_ITEM_PADDING_VERTICAL * 2 +
  fontTagHeights.h5.withMargins +
  fontClassesHeights.xsm.withMargins;

const getAgendaItemsSize = (noOfItems, separatorHeight, extraHeights) =>
  // add size based on no of items
  noOfItems * getAgendaItemSize() +
  // add separator height if exists
  2 * separatorHeight +
  // add extra height for extra height items
  extraHeights +
  // add padding vertical
  LIST_ITEM_PADDING_VERTICAL -
  // subtract padding vertical based on no of items
  noOfItems * LIST_ITEM_PADDING_VERTICAL;

export {
  LIST_ITEM_PADDING_VERTICAL,
  LIST_ITEM_HEIGHT,
  LIST_ITEM_EXTRA_HEIGHT,
  AgendaListWrapper,
  AgendaItemWrapper,
  getAgendaItemSize,
  getAgendaItemsSize,
};
