import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { getDefaultTheme } from 'theme';

export const InfoWrapper = styled.div`
  border-radius: 5px;
  background-color: ${(props) => props.theme.color9};
  color: ${(props) => props.theme.typo1};
`;

export const StyledToastBanner = styled(ToastContainer)`
  width: 100%;
  top: 0;
  padding: 0;

  & > .Toastify__toast {
    font-family: 'Eptura Flexible', 'Noto Sans', sans-serif;
    position: relative;
    margin-bottom: 0.25rem;
    padding: 8px 8px 8px 25px;
    border-radius: 0;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    min-height: 64px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-height: 300px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    direction: ltr;

    & > .Toastify__close-button {
      margin: 0 10px;
      align-self: center;
      color: ${(props) => props?.theme?.typo2 || getDefaultTheme().typo2};
      opacity: 0.9;
      & svg {
        fill: currentColor;
        height: 16px;
        width: 14px;
      }
    }
    & > .Toastify__progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: #00000022;
      animation: Toastify__trackProgress linear 1 forwards;
      transform-origin: left;
    }
  }

  & > .Toastify__toast--info {
    background-color: ${(props) =>
      props?.theme?.status1 || getDefaultTheme().status1};
    color: ${(props) => props?.theme?.typo2 || getDefaultTheme().typo2};
  }
  & > .Toastify__toast--dark {
    background-color: ${(props) =>
      props?.theme?.typo2 || getDefaultTheme().typo2};
    color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
    & > .Toastify__close-button {
      opacity: 0.6;
      color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
    }
  }
  & > .Toastify__toast--success {
    background-color: ${(props) =>
      props?.theme?.status2 || getDefaultTheme().status2};
    color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
    & > .Toastify__close-button {
      opacity: 0.6;
      color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
    }
    & > .Toastify__toast--warning {
      background-color: ${(props) =>
        props?.theme?.status3 || getDefaultTheme().status3};
      color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      & > .Toastify__close-button {
        opacity: 0.6;
        color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      }
    }
    & > .Toastify__toast--error {
      background-color: ${(props) =>
        props?.theme?.status4 || getDefaultTheme().status4};
      color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      & > .Toastify__close-button {
        opacity: 0.6;
        color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      }
    }
  }
`;

export const StyledToastRightBox = styled(ToastContainer)`
  & > .Toastify__toast {
    font-family: 'Eptura Flexible', 'Noto Sans', sans-serif;
    position: relative;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    min-height: 64px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-height: 300px;
    overflow: hidden;
    cursor: pointer;
    width: 300px;
    direction: ltr;

    & > .Toastify__close-button {
      align-self: flex-start;
      color: ${(props) => props?.theme?.typo2 || getDefaultTheme().typo2};
      opacity: 0.9;
      & svg {
        fill: currentColor;
        height: 16px;
        width: 14px;
      }
    }
    & > .Toastify__progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: #00000022;
      animation: Toastify__trackProgress linear 1 forwards;
      transform-origin: left;
    }

    & > .Toastify__toast-body {
      direction: ${({ isLayoutRTL }) => (isLayoutRTL ? 'rtl' : 'ltr')};
    }
  }
  &.Toastify__toast-container--top-right {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  & > .Toastify__toast--info {
    background-color: ${(props) =>
      props?.theme?.status1 || getDefaultTheme().status1};
    color: ${(props) => props?.theme?.typo2 || getDefaultTheme().typo2};
  }
  & > .Toastify__toast--success {
    background-color: ${(props) =>
      props?.theme?.status2 || getDefaultTheme().status2};
    color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
    & > .Toastify__close-button {
      opacity: 0.6;
      color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
    }
    & > .Toastify__toast--warning {
      background-color: ${(props) =>
        props?.theme?.status3 || getDefaultTheme().status3};
      color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      & > .Toastify__close-button {
        opacity: 0.6;
        color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      }
    }
    & > .Toastify__toast--error {
      background-color: ${(props) =>
        props?.theme?.status4 || getDefaultTheme().status4};
      color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      & > .Toastify__close-button {
        opacity: 0.6;
        color: ${(props) => props?.theme?.typo1 || getDefaultTheme().typo1};
      }
    }
  }
`;
