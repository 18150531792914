var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var CalendarModal = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  max-width: 420px;\n  height: max-content;\n  margin: 75px auto 0;\n  background-color: #fff;\n  border-radius: 6px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  max-width: 420px;\n  height: max-content;\n  margin: 75px auto 0;\n  background-color: #fff;\n  border-radius: 6px;\n"])));
export var TimePickerWrapper = styled.fieldset(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  border: 0;\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  border: 0;\n"])));
export var TimePickerLabel = styled.legend(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: bold;\n  margin-right: 10px;\n  max-width: 110px;\n  text-align: center;\n  float: left;\n"], ["\n  font-weight: bold;\n  margin-right: 10px;\n  max-width: 110px;\n  text-align: center;\n  float: left;\n"])));
export var CalendarModalInnerWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 20px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
