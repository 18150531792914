import PropTypes from 'prop-types';

const eventPropTypes = {
  spaceId: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
  name: PropTypes.string,
  summary: PropTypes.string,
  start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  isAllDayBooking: PropTypes.bool,
  buildingId: PropTypes.number,
  isDesk: PropTypes.bool,
  reservee: PropTypes.number,
  reserveeName: PropTypes.string,
};

Object.defineProperty(eventPropTypes, 'defaultProps', {
  value: {
    name: null,
    summary: null,
    start: null,
    end: null,
    buildingId: null,
    isAllDayBooking: false,
    isDesk: true,
    reservee: 0,
    reserveeName: null,
  },
});

export { eventPropTypes };
