// there is a conflict between prettier and eslint formatting
/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { teamReservationsSelectors } from 'store';
import { APP_LAUNCH_INTUNE_PATH, APP_LAUNCH_PATH, BROWSER_PATH, CALLBACK_PATH, CONCIERGE_MENU_PATH, DEMO_PATH, FLOOR_PATH, KEYBOARD_ACCESSIBILITY, KIOSK_ADMIN, LANDING_PATH, LOGIN_PATH, ORGANIZATION_POLICY_PATH, PATH_PARAM, PATH_SEGMENT, ROOT_PATH, SSO_LOGOUT_PATH, WAYFINDER_PATH, WORKSPACE_PATH, WORKSPACE_PATH_INSTANCE, } from 'consts';
import { AppLaunch, DeskCheckInContainer, Employee, FirstResponders, FloorplanViewsContainer, GlobalSearch, LocationSelector, Person, PersonLocations, ReservationAssist, ReserveeList, ReserveSpaceContainer, ReserveTeamContainer, SpaceAttributes, TeamBookingDesks, TeamMembersContainer, } from 'layouts';
import { Browser, ConciergeMenu, Drawer, KeyboardAccessibility, KioskConfiguration, LandingPage, Tenant, TenantWorkspace, Wayfinder, } from 'scenes';
import { isElectron } from 'utils/electron';
import { AcknowledgementRouter, CallbackRouter, Demo, DrawerRedirect, FloorCheckRouter, LoginRouter, MainRouter, PrivateRoute, PublicRoute, SsoLogoutRouter, } from './components';
var isKiosk = isElectron();
var SPACES = PATH_SEGMENT.SPACES, DESKS = PATH_SEGMENT.DESKS, PEOPLE = PATH_SEGMENT.PEOPLE, TEAMS = PATH_SEGMENT.TEAMS, EMPLOYEE = PATH_SEGMENT.EMPLOYEE, FILTERS = PATH_SEGMENT.FILTERS, RESERVEE = PATH_SEGMENT.RESERVEE, LOCATIONS = PATH_SEGMENT.LOCATIONS, LOCATION_SWITCHER = PATH_SEGMENT.LOCATION_SWITCHER, FLOORPLAN_VIEWS = PATH_SEGMENT.FLOORPLAN_VIEWS, RESERVE = PATH_SEGMENT.RESERVE, SPACE_ATTRIBUTES = PATH_SEGMENT.SPACE_ATTRIBUTES, HEATMAP = PATH_SEGMENT.HEATMAP;
var SPACE_ID = PATH_PARAM.SPACE_ID, DESK_ID = PATH_PARAM.DESK_ID, PERSON_ID = PATH_PARAM.PERSON_ID, TEAM_ID = PATH_PARAM.TEAM_ID, EMPLOYEE_ID = PATH_PARAM.EMPLOYEE_ID;
/**
 * Main app routing component.
 */
export var Routing = function () {
    var isInDeskCheckInMode = useSelector(teamReservationsSelectors.getIsInDeskCheckInMode);
    var drawerRoutes = useMemo(function () {
        return isInDeskCheckInMode ? (React.createElement(Route, { path: "".concat(FLOOR_PATH, "/*") },
            React.createElement(Route, { path: "".concat(DESKS, "/*"), element: React.createElement(Drawer, null) },
                React.createElement(Route, { path: DESK_ID, element: React.createElement(DeskCheckInContainer, null) }),
                React.createElement(Route, { path: "".concat(DESK_ID, "/").concat(SPACE_ATTRIBUTES), element: React.createElement(SpaceAttributes, null) }),
                React.createElement(Route, { index: true, element: React.createElement(TeamBookingDesks, null) }),
                React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: DESKS }) })),
            React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: DESKS }) }))) : (React.createElement(React.Fragment, null,
            React.createElement(Route, { path: "".concat(PEOPLE, "/*"), element: React.createElement(Drawer, null) },
                React.createElement(Route, { path: PERSON_ID, element: React.createElement(Person, null) }),
                React.createElement(Route, { path: "".concat(PERSON_ID, "/").concat(LOCATIONS), element: React.createElement(PersonLocations, null) }),
                React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, null) })),
            React.createElement(Route, { path: "".concat(FLOOR_PATH, "/*") },
                React.createElement(Route, { path: "".concat(SPACES, "/*"), element: React.createElement(Drawer, null) },
                    React.createElement(Route, { path: FILTERS, element: React.createElement(ReservationAssist, null) }),
                    React.createElement(Route, { path: SPACE_ID, element: React.createElement(ReserveSpaceContainer, null) }),
                    React.createElement(Route, { path: "".concat(SPACE_ID, "/").concat(RESERVEE), element: React.createElement(ReserveeList, null) }),
                    React.createElement(Route, { path: "".concat(SPACE_ID, "/").concat(SPACE_ATTRIBUTES), element: React.createElement(SpaceAttributes, null) }),
                    React.createElement(Route, { index: true, element: React.createElement(GlobalSearch, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: SPACES }) })),
                React.createElement(Route, { path: "".concat(DESKS, "/*"), element: React.createElement(Drawer, null) },
                    React.createElement(Route, { path: FILTERS, element: React.createElement(ReservationAssist, null) }),
                    React.createElement(Route, { path: DESK_ID, element: React.createElement(ReserveSpaceContainer, null) }),
                    React.createElement(Route, { path: "".concat(DESK_ID, "/").concat(RESERVEE), element: React.createElement(ReserveeList, null) }),
                    React.createElement(Route, { path: "".concat(DESK_ID, "/").concat(SPACE_ATTRIBUTES), element: React.createElement(SpaceAttributes, null) }),
                    React.createElement(Route, { index: true, element: React.createElement(GlobalSearch, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: DESKS }) })),
                React.createElement(Route, { path: "".concat(PEOPLE, "/*"), element: React.createElement(Drawer, null) },
                    React.createElement(Route, { path: FILTERS, element: React.createElement(FirstResponders, null) }),
                    React.createElement(Route, { path: PERSON_ID, element: React.createElement(Person, null) }),
                    React.createElement(Route, { path: "".concat(PERSON_ID, "/").concat(LOCATIONS), element: React.createElement(PersonLocations, null) }),
                    React.createElement(Route, { index: true, element: React.createElement(GlobalSearch, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: PEOPLE }) })),
                React.createElement(Route, { path: "".concat(TEAMS, "/*"), element: React.createElement(Drawer, null) },
                    React.createElement(Route, { path: TEAM_ID, element: React.createElement(TeamMembersContainer, null) }),
                    React.createElement(Route, { path: "".concat(TEAM_ID, "/").concat(RESERVE), element: React.createElement(ReserveTeamContainer, null) }),
                    React.createElement(Route, { index: true, element: React.createElement(GlobalSearch, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: TEAMS }) })),
                React.createElement(Route, { path: "".concat(LOCATION_SWITCHER, "/*"), element: React.createElement(Drawer, null) },
                    React.createElement(Route, { index: true, element: React.createElement(LocationSelector, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: LOCATION_SWITCHER }) })),
                React.createElement(Route, { path: "".concat(FLOORPLAN_VIEWS, "/*"), element: React.createElement(Drawer, null) },
                    React.createElement(Route, { index: true, element: React.createElement(FloorplanViewsContainer, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(DrawerRedirect, { path: FLOORPLAN_VIEWS }) })),
                React.createElement(Route, { path: "".concat(HEATMAP, "/*") }),
                React.createElement(Route, { path: "*", element: React.createElement(Drawer, null) },
                    React.createElement(Route, { index: true, element: React.createElement(GlobalSearch, null) })))));
    }, [isInDeskCheckInMode]);
    var publicRoutes = (React.createElement(React.Fragment, null,
        React.createElement(Route, { path: LOGIN_PATH, element: React.createElement(PublicRoute, null,
                React.createElement(LoginRouter, null)) }),
        React.createElement(Route, { path: SSO_LOGOUT_PATH, element: React.createElement(PublicRoute, null,
                React.createElement(SsoLogoutRouter, null)) }),
        React.createElement(Route, { path: CALLBACK_PATH, element: React.createElement(PublicRoute, null,
                React.createElement(CallbackRouter, null)) }),
        React.createElement(Route, { path: WORKSPACE_PATH, element: React.createElement(PublicRoute, null,
                React.createElement(Tenant, null)) }),
        React.createElement(Route, { path: WORKSPACE_PATH_INSTANCE, element: React.createElement(PublicRoute, null,
                React.createElement(TenantWorkspace, null)) }),
        React.createElement(Route, { path: "".concat(APP_LAUNCH_PATH, "/*"), element: React.createElement(PublicRoute, null,
                React.createElement(AppLaunch, null)) }),
        React.createElement(Route, { path: "".concat(APP_LAUNCH_INTUNE_PATH, "/*"), element: React.createElement(PublicRoute, null,
                React.createElement(AppLaunch, { inTune: true })) })));
    var privateRoutes = (React.createElement(React.Fragment, null,
        React.createElement(Route, { element: React.createElement(PrivateRoute, null,
                React.createElement(FloorCheckRouter, null)) }, drawerRoutes),
        isKiosk && (React.createElement(Route, { path: KIOSK_ADMIN, element: React.createElement(PrivateRoute, null,
                React.createElement(KioskConfiguration, null)) })),
        React.createElement(Route, { path: "".concat(WAYFINDER_PATH, "/*"), element: React.createElement(PrivateRoute, null,
                React.createElement(Wayfinder, null)) },
            React.createElement(Route, { element: React.createElement(Drawer, null) },
                React.createElement(Route, { index: true, element: React.createElement(LocationSelector, null) }),
                React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: WAYFINDER_PATH }) }))),
        React.createElement(Route, { path: "".concat(EMPLOYEE, "/").concat(EMPLOYEE_ID), element: React.createElement(PrivateRoute, null,
                React.createElement(Employee, null)) }),
        React.createElement(Route, { path: DEMO_PATH, element: React.createElement(PrivateRoute, null,
                React.createElement(Demo, null)) }),
        React.createElement(Route, { path: ORGANIZATION_POLICY_PATH, element: React.createElement(PrivateRoute, null,
                React.createElement(AcknowledgementRouter, null)) }),
        React.createElement(Route, { path: LANDING_PATH, element: React.createElement(PrivateRoute, null,
                React.createElement(LandingPage, null)) }),
        React.createElement(Route, { path: CONCIERGE_MENU_PATH, element: React.createElement(PrivateRoute, null,
                React.createElement(ConciergeMenu, null)) }),
        React.createElement(Route, { path: BROWSER_PATH, element: React.createElement(PrivateRoute, null,
                React.createElement(Browser, null)) }),
        React.createElement(Route, { path: ROOT_PATH, element: React.createElement(PrivateRoute, null,
                React.createElement(MainRouter, null)) }),
        React.createElement(Route, { path: KEYBOARD_ACCESSIBILITY, element: React.createElement(PrivateRoute, null,
                React.createElement(KeyboardAccessibility, null)) })));
    return (React.createElement(Routes, null,
        publicRoutes,
        privateRoutes,
        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: ROOT_PATH }) })));
};
