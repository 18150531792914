import { PATH_SEGMENT } from 'consts';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { joinPaths } from 'router/utils';
import { inAppNotificationsActions } from 'store';
import { useGenerateCurrentLocationPath } from 'utils';
export function useTeamBookingDetailsCallbacks() {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var t = useTranslation().t;
    var currentLocationPath = useGenerateCurrentLocationPath();
    return {
        onError: function (error) {
            if (error.httpStatus === 404) {
                dispatch(inAppNotificationsActions.addWarningNotification({
                    message: t('router.location.invalidTeamBooking'),
                }));
                navigate(joinPaths(currentLocationPath, PATH_SEGMENT.TEAMS));
            }
        },
    };
}
