import { shape, bool, number, arrayOf } from 'prop-types';

const Shape = shape({
  isPolygon: bool,
  labelPoint: shape({
    x: number.isRequired,
    y: number.isRequired,
  }),
  coordinates: arrayOf(
    shape({
      x: number,
      y: number,
    }),
  ).isRequired,
  holes: arrayOf(
    arrayOf(
      shape({
        x: number,
        y: number,
      }),
    ),
  ),
});

export default Shape;
