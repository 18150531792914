var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { humanizeNumber } from 'utils';
import { useTranslation } from 'react-i18next';
var SectionHeaderWrapper = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px 20px 0 20px;\n  color: ", ";\n  & .title {\n    font-size: 20px;\n    font-weight: bold;\n  }\n  & .subtitle {\n    font-size: 16px;\n  }\n"], ["\n  padding: 20px 20px 0 20px;\n  color: ", ";\n  & .title {\n    font-size: 20px;\n    font-weight: bold;\n  }\n  & .subtitle {\n    font-size: 16px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
var StatusTitle = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  font-weight: bold;\n  color: ", ";\n  opacity: 0.5;\n"], ["\n  font-size: 14px;\n  font-weight: bold;\n  color: ", ";\n  opacity: 0.5;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
var SectionHeader = function (_a) {
    var _b = _a.item, item = _b === void 0 ? {} : _b, style = _a.style;
    var statusTitle = item.statusTitle, title = item.title, subtitle = item.subtitle;
    var t = useTranslation().t;
    return (React.createElement(SectionHeaderWrapper, { style: style },
        !!statusTitle && React.createElement(StatusTitle, null, t(statusTitle)),
        !!title && React.createElement("div", { className: "title" }, title),
        !!subtitle && (React.createElement("div", { className: "subtitle" }, "".concat(humanizeNumber(subtitle, t), " ").concat(t('common.floor'))))));
};
// if there is building name set size to 90 if not - to 75
SectionHeader.getSize = function (item) {
    return item.title ? 90 : 75;
};
export default SectionHeader;
var templateObject_1, templateObject_2;
