import { useDispatch } from 'react-redux';
import { navigationActions } from 'store';
/**
 * A custom hook that returns wrapped setBypassSSO method to simplify
 * using of the method at the component level
 * @returns {{
 * activateBypassSSO: (function(): void),
 * deactivateBypassSSO: (function(): void)}}
 */
export function useBypassSSO() {
    var dispatch = useDispatch();
    var activateBypassSSO = function () {
        return dispatch(navigationActions.setBypassSSO(true));
    };
    var deactivateBypassSSO = function () {
        return dispatch(navigationActions.setBypassSSO(false));
    };
    return {
        activateBypassSSO: activateBypassSSO,
        deactivateBypassSSO: deactivateBypassSSO,
    };
}
