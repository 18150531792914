/**
 * Transforms KioskRemoteConfig array to object that
 * is easy applicable to LocationSelector-like usage
 * @param data - kiosk remote configurations
 * @param getMetadata - data selector - whether we want to transform data based on floor or building id and name
 */
export var groupConfigsByBuildingAndFloor = function (data, getMetadata) {
    var result = data.reduce(function (previousValue, currentValue) {
        var _a;
        var keyToIndex = previousValue.keyToIndex, result = previousValue.result;
        var _b = getMetadata(currentValue), id = _b.id, name = _b.name;
        var index = keyToIndex.get(id);
        if (index !== undefined && index >= 0) {
            (_a = result[index]) === null || _a === void 0 ? void 0 : _a.kioskOriginalData.push(currentValue);
        }
        else {
            var length_1 = result.push({
                id: id,
                name: name,
                kioskOriginalData: [currentValue],
            });
            keyToIndex.set(id, length_1 - 1);
        }
        return previousValue;
    }, {
        keyToIndex: new Map(),
        result: [],
    }).result;
    return result;
};
