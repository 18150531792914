import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentTimeZone, useDatePicker } from 'utils';
import { SEARCH_QUERY } from 'consts';
import { getDate, getMonth, getYear, isAfter, isBefore, set } from 'date-fns';
import { getClosestTime, QUARTER_HOUR_VALUES } from '@serraview/engage-shared';
import moment from 'moment-timezone';
import logger from 'log';

// this formats can only be used with moment JS!
const DATE_FORMAT = 'yyyy-MM-D';
const TIME_FORMAT = 'HH:mm';

const parseDateTime = ({ date, time, timeZone }) => {
  if (date && !time) {
    return moment.tz(date, DATE_FORMAT, true, timeZone).toDate();
  }

  if (time && !date) {
    return moment.tz(time, TIME_FORMAT, true, timeZone).toDate();
  }

  return moment
    .tz(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`, true, timeZone)
    .toDate();
};

const validate = ({ date, time }) => {
  if (!date && !time) return { isValid: false };

  // third param in moment call enables strict mode, which will check if date is in right format
  if (date && !moment(date, DATE_FORMAT, true).isValid()) {
    return {
      isValid: false,
      message: `Deep Link Date does not match format ${DATE_FORMAT}!`,
    };
  }

  if (time && !moment(time, TIME_FORMAT, true).isValid()) {
    return {
      isValid: false,
      message: `Deep Link Time does not match format ${TIME_FORMAT}!`,
    };
  }

  return { isValid: true };
};

const validateAndParse = ({ date, time, timeZone }) => {
  const { isValid, message } = validate({ date, time });

  if (!isValid) {
    if (message) logger.warn(message)();
    return null;
  }

  return getClosestTime(
    parseDateTime({ date, time, timeZone }),
    QUARTER_HOUR_VALUES,
  );
};

export default function useDeepLinkDateTime() {
  const { setDateStart, maxDate, minDate } = useDatePicker();
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const timeZone = useCurrentTimeZone();

  const deepLinkDate = query.get(SEARCH_QUERY.DATE);
  const deepLinkTime = query.get(SEARCH_QUERY.TIME);

  const clearSearch = () => {
    query.delete(SEARCH_QUERY.DATE);
    query.delete(SEARCH_QUERY.TIME);

    navigate({
      search: query.toString(),
      replace: true,
    });
  };

  useEffect(() => {
    let parsedDateTime = validateAndParse({
      date: deepLinkDate,
      time: deepLinkTime,
      timeZone,
    });

    if (parsedDateTime === null) {
      return;
    }

    if (isBefore(parsedDateTime, minDate)) {
      clearSearch();
      return;
    }

    if (isAfter(parsedDateTime, maxDate)) {
      parsedDateTime = set(parsedDateTime, {
        year: getYear(maxDate),
        month: getMonth(maxDate),
        date: getDate(maxDate),
      });
    }

    setDateStart(parsedDateTime);

    clearSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deepLinkDate, deepLinkTime, timeZone]);
}
