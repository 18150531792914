var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ToggleSwitchLabelStyled = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 15px;\n  line-height: 22px;\n  padding-left: 10px;\n  transition: opacity 0.25s ease 0s;\n"], ["\n  font-size: 15px;\n  line-height: 22px;\n  padding-left: 10px;\n  transition: opacity 0.25s ease 0s;\n"])));
export var ToggleSwitchWrapperStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n\n  display: flex;\n  align-items: center;\n\n  label {\n    cursor: pointer;\n  }\n\n  ", ";\n"], ["\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n\n  display: flex;\n  align-items: center;\n\n  label {\n    cursor: pointer;\n  }\n\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n  label {\n    cursor: default;\n  }\n\n  ".concat(ToggleSwitchLabelStyled, " {\n      opacity: .5;\n  }\n  ");
});
var templateObject_1, templateObject_2;
