var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePerson } from 'api/queries';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_SEGMENT, ROOT_PATH, WAYFINDER_PATH } from 'consts';
import { filtersSelectors, floorplanActions, floorplanSelectors, inAppNotificationsActions, tenantActions, tenantSelectors, } from 'store';
import { useGenerateCurrentLocationPath } from 'utils';
import { getPresenceSensorStatusForPerson, getUserFloorLocation, } from '@serraview/engage-shared/utils';
import { EmptyCard } from 'base-components';
import { FocusId, useFocus } from 'utils/hooks';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import usePersonCheck from 'components/scenes/drawer/hooks/usePersonCheck';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { generatePersonPath, joinPaths } from 'router/utils';
import { useAppLocation, useAppParams } from 'router/hooks';
import { getHasWifiPresenceData, getHasWiredPresenceData, getPersonLocation, isTimeNow, } from '@serraview/engage-shared';
import { usePersonMenu } from 'utils/hooks/usePersonMenu';
import PersonCard from './Card/PersonCard';
import PersonCardSkeleton from './Card/PersonCardSkeleton';
var SHOW_FLOORPLAN_OVERLAY_DELAY = 500;
var Person = function (_a) {
    var _b, _c, _d;
    var personIdFromProps = _a.personId;
    var personIdFromParams = useAppParams().personId;
    var personId = personIdFromProps !== null && personIdFromProps !== void 0 ? personIdFromProps : parseInt(personIdFromParams !== null && personIdFromParams !== void 0 ? personIdFromParams : '0', 10);
    var state = useAppLocation().state;
    var searchSpaceId = state === null || state === void 0 ? void 0 : state.searchSpaceId;
    var isFinalLocation = (_b = state === null || state === void 0 ? void 0 : state.isFinalLocation) !== null && _b !== void 0 ? _b : false;
    var currentLocationPath = useGenerateCurrentLocationPath();
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var queryParams = useSearchParams()[0];
    var t = useTranslation().t;
    var currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
    var dateStart = useSelector(filtersSelectors.getDatePickerStartDate);
    var isDateStartEqualToNow = isTimeNow(dateStart);
    var demoSensorStatus = queryParams.get('demoSensorStatus');
    var closeLinkPath = currentLocationPath
        ? joinPaths(currentLocationPath, PATH_SEGMENT.PEOPLE)
        : WAYFINDER_PATH;
    // person check flow
    var checkPersonQuery = usePersonCheck().checkPersonQuery;
    useEffect(function () {
        checkPersonQuery(personId).then(function (_a) {
            var errorMessage = _a.errorMessage;
            if (errorMessage) {
                dispatch(inAppNotificationsActions.addWarningNotification({
                    message: t(errorMessage),
                }));
                navigate(currentLocationPath || ROOT_PATH);
            }
        });
    }, [checkPersonQuery, currentLocationPath, dispatch, navigate, personId, t]);
    var sensorStatusRef = useRef(null);
    var personQueryOptions = sensorStatusRef.current
        ? { refetchOnMount: true, refetchInterval: 60 * 1000 }
        : {};
    var personQuery = usePerson({
        id: personId,
        searchSpaceId: searchSpaceId,
        currentFloorId: currentFloorId,
        options: personQueryOptions,
    });
    var person = personQuery.data;
    var primaryLocation = (_c = person === null || person === void 0 ? void 0 : person.primaryLocation) !== null && _c !== void 0 ? _c : {};
    var spaceId = primaryLocation.spaceId, spaceIds = primaryLocation.spaceIds, floorId = primaryLocation.floorId, hoodName = primaryLocation.hoodName;
    var isDeskReserved = !!(person === null || person === void 0 ? void 0 : person.reserved);
    var floorplanIsLoaded = useSelector(floorplanSelectors.getIsLoaded);
    // display overlay while checking person's location. If person has a location – hide overlay
    // otherwise – do nothing
    // also clear overlay when the card is unmounted
    useEffect(function () {
        var overlayTimeout = window.setTimeout(function () { return dispatch(floorplanActions.setShowOverlay(true)); }, SHOW_FLOORPLAN_OVERLAY_DELAY);
        if ((primaryLocation === null || primaryLocation === void 0 ? void 0 : primaryLocation.spaceId) || (person === null || person === void 0 ? void 0 : person.presenceStatus)) {
            window.clearTimeout(overlayTimeout);
            dispatch(floorplanActions.setShowOverlay(false));
        }
        return function () {
            window.clearTimeout(overlayTimeout);
            dispatch(floorplanActions.setShowOverlay(false));
        };
    }, [primaryLocation === null || primaryLocation === void 0 ? void 0 : primaryLocation.spaceId, person === null || person === void 0 ? void 0 : person.presenceStatus, dispatch]);
    var menu = usePersonMenu({
        personId: "".concat(personId),
        isFavourite: person === null || person === void 0 ? void 0 : person.isFavorite,
    });
    var locationName = person === null || person === void 0 ? void 0 : person.spaceName;
    var sensorStatus = getPresenceSensorStatusForPerson({
        sensorStatus: person === null || person === void 0 ? void 0 : person.sensorStatus,
        isTimeFilterSetToNow: isDateStartEqualToNow,
        isDeskReserved: isDeskReserved,
        demoSensorStatus: demoSensorStatus,
    });
    sensorStatusRef.current = sensorStatus;
    var queryIsLoading = personQuery.isFetching;
    var isDefaultLocationNotInCurrent = floorId && floorId !== currentFloorId;
    var querySuccess = personQuery.isSuccess;
    var queryError = personQuery.isError;
    var isSkeletonVisible = queryIsLoading ||
        !floorplanIsLoaded ||
        (!personIdFromProps && isDefaultLocationNotInCurrent && !isFinalLocation);
    var hasWiredPresenceData = getHasWiredPresenceData(person);
    var hasWifiPresenceData = getHasWifiPresenceData(person);
    var personLocation = getPersonLocation(person);
    var updateLocation = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var floorId, selectedLocation, personId_1, pathname;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    floorId = personLocation.floorId;
                    if (!floorId) return [3 /*break*/, 2];
                    return [4 /*yield*/, getUserFloorLocation(floorId)];
                case 1:
                    selectedLocation = _a.sent();
                    _a.label = 2;
                case 2:
                    if (selectedLocation) {
                        personId_1 = (person === null || person === void 0 ? void 0 : person.id) ? "".concat(person.id) : '';
                        pathname = generatePersonPath({
                            floorId: selectedLocation.floor.id,
                            personId: personId_1,
                        });
                        navigate(pathname, {
                            replace: true,
                            state: __assign(__assign({}, state), { isFinalLocation: true, person: __assign({}, (person !== null && person !== void 0 ? person : {})), searchSpaceId: spaceId }),
                        });
                        dispatch(tenantActions.setCurrentLocation(selectedLocation));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [person, navigate, state, spaceId, dispatch, personLocation.floorId]);
    /**
     * Location should be updated if the person query has succeeded and:
     * 1. Person's default location differs from the current one.
     * 2. This is not the final location (obtained from location state and will be true if location was replaced or
     * if the person card was opened from person locations list).
     * 3. Person id from props is undefined – because this case correct only when we're
     * showing an employee.
     */
    var shouldUpdateLocation = querySuccess &&
        (!!isDefaultLocationNotInCurrent ||
            personLocation.floorId !== currentFloorId) &&
        !isFinalLocation &&
        !personIdFromProps;
    useEffect(function () {
        if (shouldUpdateLocation) {
            updateLocation();
        }
    }, [shouldUpdateLocation, updateLocation]);
    useEffect(function () {
        // if person has flexible workplace (spaceIds.length > 1) -> highlight all of them
        // if person has only one workplace -> highlight it
        var spacesToFocus = spaceIds && spaceIds.length > 1 ? spaceIds : spaceId;
        // If SVLive presence data exists and person has wired connection,
        // wired connection location has precedence over any other person locations.
        if (hasWiredPresenceData) {
            spacesToFocus = personLocation.spaceId;
        }
        // If SVLive Wi-Fi presence data exists and desk is reserved,
        // Wi-Fi location has precedence over reserved desk.
        var shouldFocusSpaces = querySuccess && spacesToFocus && !hasWifiPresenceData;
        if (shouldFocusSpaces) {
            dispatch(floorplanActions.setFocusedSpace({
                id: spacesToFocus,
            }));
        }
    }, [
        dispatch,
        hasWifiPresenceData,
        hasWiredPresenceData,
        personLocation.spaceId,
        querySuccess,
        spaceId,
        spaceIds,
    ]);
    useEffect(function () {
        var _a;
        // If we have svLivePresenceLocation and person has Wi-Fi connection, draw marker on the floorplan
        if (hasWifiPresenceData && ((_a = person === null || person === void 0 ? void 0 : person.latestPresenceEvent) === null || _a === void 0 ? void 0 : _a.location)) {
            dispatch(floorplanActions.setSVLivePersonLocation(person.latestPresenceEvent.location));
        }
        return function () {
            // once component updated and there are no svLivePresenceLocation - remove it from floorplan
            if (hasWifiPresenceData) {
                dispatch(floorplanActions.setSVLivePersonLocation(null));
            }
        };
    }, [dispatch, hasWifiPresenceData, (_d = person === null || person === void 0 ? void 0 : person.latestPresenceEvent) === null || _d === void 0 ? void 0 : _d.location]);
    useFocus({ focusOn: FocusId.DrawerCloseButton });
    if (isSkeletonVisible) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerHeader, { title: false, subtitle: false, isCloseIcon: true, closeLinkPath: closeLinkPath, closeIconA11yLabel: "accessibilityLabels.closeLayout_personDetails" }),
            React.createElement(PersonCardSkeleton, null)));
    }
    if (queryError) {
        return React.createElement(EmptyCard, { iconName: "info" }, t('common.networkError'));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, { menu: menu, title: !!spaceId, subtitle: !!spaceId, isCloseIcon: true, closeLinkPath: closeLinkPath, closeIconA11yLabel: "accessibilityLabels.closeLayout_personDetails" }),
        React.createElement(DrawerCardContent, null, person ? (React.createElement(PersonCard, { person: person, locationName: locationName, hoodName: hoodName, sensorStatus: sensorStatus })) : null)));
};
export default memo(Person);
