import React from 'react';
import { AvailableKiosks } from 'components/kiosk';
import { Modals } from 'components/scenes';
import { useNavigate } from 'react-router-dom';
import { ROOT_PATH } from 'consts';
var headerProps = {
    hasAgendaButton: false,
    hasDateTime: false,
};
var KioskConfiguration = function () {
    var navigate = useNavigate();
    var handleSuccessfulKioskSelection = function () {
        navigate(ROOT_PATH, { replace: true });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modals, { isKiosk: false, headerProps: headerProps }),
        React.createElement(AvailableKiosks, { onSuccessfullySelectKiosk: handleSuccessfulKioskSelection })));
};
export default KioskConfiguration;
