var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from 'react-query';
import { downloadImage } from 'api/spaces';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
var cacheTime = 1000 * 60 * 30; // 30 min
var staleTime = 1000 * 60 * 10; // 10 min
export function useFetchImageURL(_a) {
    var url = _a.url, queryKey = _a.queryKey, _b = _a.options, options = _b === void 0 ? {} : _b;
    var _c = options.enabled, enabled = _c === void 0 ? true : _c, otherOptions = __rest(options, ["enabled"]);
    var tenantId = useSelector(tenantSelectors.getTenantId);
    return useQuery(queryKey, function () { return downloadImage(url, tenantId); }, __assign({ enabled: enabled && !!url && !!tenantId, cacheTime: cacheTime, staleTime: staleTime, retry: false }, otherOptions));
}
