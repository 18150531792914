import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Icon } from 'components/base';
import { Wrapper, ButtonText } from './styled';

const ResetViewButton = ({ resetRotation }) => {
  const { t } = useTranslation();
  return (
    <Wrapper onClick={() => resetRotation()}>
      <ButtonText>{t('layout.floorplanViewSelector.resetView')}</ButtonText>
      <Icon name="rotate-floorplan" size={14} />
    </Wrapper>
  );
};

ResetViewButton.propTypes = {
  resetRotation: PropTypes.func,
};
ResetViewButton.defaultProps = {
  resetRotation: () => {},
};

export default memo(ResetViewButton);
