import { useState } from 'react';

export function useLoadingState() {
  const [value, setValue] = useState(null);
  const isSuccess = !!value;
  const isLoading = value === null;
  const isError = value === false;

  const onLoadSuccess = () => setValue(true);
  const onLoadError = () => setValue(false);

  return {
    isLoading,
    isError,
    isSuccess,
    onLoadSuccess,
    onLoadError,
  };
}
