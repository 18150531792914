import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, Icon } from 'components/base';
import AnimateHeight from 'react-animate-height';
import {
  DateFormat,
  formatDate,
  LanguageTypes,
} from '@serraview/engage-shared';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userConfigSelectors } from 'store';
import { firstLetterCapitalize, testProps } from 'utils';
import { formatLocalizedDate } from '@serraview/engage-shared/utils';
import { A11yHiddenLabel } from 'styles';
import {
  AgendaCalendarWrapper,
  CalendarToggler,
  Month,
  MonthSelector,
  MonthSelectorActionsWrapper,
  MonthSelectorWrapper,
  MonthWrapper,
  ReservedMark,
} from './styled';
import {
  changeAgendaMonth,
  DATE_NAVIGATION,
  getReservedDaysFromAgendaItems,
} from './utils';

export const ANIMATION_DURATION = 250;
export const AGENDA_CALENDAR_ID = 'id_agenda_calendar';

const AgendaCalendar = ({
  agendaItems,
  selectedDate,
  onDateChange,
  initiallyOpened,
}) => {
  const { t } = useTranslation();
  const [calendarOpen, setCalendarOpen] = useState(initiallyOpened);
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const month = firstLetterCapitalize(
    formatLocalizedDate(selectedDate, {
      locale,
      format: DateFormat.monthLong,
    }),
  );
  const reservedDays = getReservedDaysFromAgendaItems(agendaItems);

  const goToPrevMonth = () =>
    onDateChange(changeAgendaMonth(DATE_NAVIGATION.PREVIOUS, selectedDate));
  const goToNextMonth = () =>
    onDateChange(changeAgendaMonth(DATE_NAVIGATION.NEXT, selectedDate));

  const markReservedTiles = ({ date }) => {
    if (!reservedDays.includes(formatDate(date))) {
      return null;
    }
    return (
      <ReservedMark data-testid="reserved-calendar-mark">
        <A11yHiddenLabel>{t('accessibilityLabels.dayBooked')}</A11yHiddenLabel>
      </ReservedMark>
    );
  };

  const calendarNavigationAriaLabel = formatLocalizedDate(selectedDate, {
    format: DateFormat.monthLong,
    locale,
  });

  return (
    <>
      <MonthSelectorWrapper>
        <MonthWrapper>
          <Month data-testid="current-month">{month}</Month>
          <CalendarToggler
            open={calendarOpen}
            onClick={() => setCalendarOpen(!calendarOpen)}
            aria-expanded={calendarOpen}
            aria-pressed={calendarOpen}
            aria-controls={AGENDA_CALENDAR_ID}
            aria-label={calendarNavigationAriaLabel}
            role="button"
            name={t('accessibilityLabels.toggleAgendaCalendar')}
            data-testid={formatLocalizedDate(selectedDate, {
              locale: LanguageTypes.en,
              format: DateFormat.monthLong,
            })}
          >
            <Icon name="chevron-right" size={12} />
          </CalendarToggler>
        </MonthWrapper>
        <MonthSelectorActionsWrapper>
          <MonthSelector
            onClick={goToPrevMonth}
            {...testProps(t, 'accessibilityLabels.agendaCalendarNavPrevMonth')
              .props}
          >
            <Icon shouldRotateOnRtl name="chevron-left" size={10} />
          </MonthSelector>
          <MonthSelector
            onClick={goToNextMonth}
            {...testProps(t, 'accessibilityLabels.agendaCalendarNavNextMonth')
              .props}
          >
            <Icon shouldRotateOnRtl name="chevron-right" size={10} />
          </MonthSelector>
        </MonthSelectorActionsWrapper>
      </MonthSelectorWrapper>
      <AnimateHeight
        height={calendarOpen ? 295 : 0}
        duration={ANIMATION_DURATION}
      >
        <AgendaCalendarWrapper
          data-testid="agenda-calendar-wrapper"
          id={AGENDA_CALENDAR_ID}
        >
          <Calendar
            locale={locale}
            value={selectedDate}
            activeStartDate={selectedDate}
            onChange={onDateChange}
            minDetail="month"
            showNeighboringMonth={false}
            showNavigation={false}
            tileContent={markReservedTiles}
          />
        </AgendaCalendarWrapper>
      </AnimateHeight>
    </>
  );
};

AgendaCalendar.propTypes = {
  agendaItems: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onDateChange: PropTypes.func.isRequired,
  initiallyOpened: PropTypes.bool,
};

AgendaCalendar.defaultProps = {
  agendaItems: {},
  initiallyOpened: false,
};

export default AgendaCalendar;
