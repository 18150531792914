var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { checkInBooking, useRefreshBooking, } from '@serraview/engage-shared/api';
import { CHECK_IN_MESSAGES, getBookingDetailsStatus, } from '@serraview/engage-shared';
import { DeleteButton, LineWrapper, StatusMessage, } from 'components/layouts/Booking/Card/styled';
import { ConfirmationDialog, Icon } from 'components/base';
import Button from 'components/layouts/Booking/Button/Button';
import { inAppNotificationsActions, tenantSelectors } from 'store';
import { bookingDetailsButtons as BUTTONS, bookingDetailsButtonStatuses as STATUSES, CHECK_IN_STATUS, } from 'consts';
import { useCancelBooking } from 'api/mutations';
import { useCheckInStatus } from 'utils';
import { FocusId, useConfig, useDialog, useStartCheckIn } from 'utils/hooks';
export var SpaceBookingButtonsBar = function (_a) {
    var booking = _a.booking;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var openDialog = useDialog().openDialog;
    var _b = useTheme(), color1 = _b.color1, color3 = _b.color3, color6 = _b.color6;
    var enableDeskCheckIn = useConfig({
        buildingId: booking.location.buildingId,
    }).enableDeskCheckIn;
    var statusObj = useCheckInStatus({ booking: booking });
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var refreshBooking = useRefreshBooking().refresh;
    var spaceId = booking.spaceId, meetingId = booking.meetingId, isDesk = booking.isDesk, isInInactiveLocation = booking.isInInactiveLocation, isBookedForOther = booking.isBookedForOther;
    var _c = useState(false), isCheckOut = _c[0], setIsCheckOut = _c[1];
    var _d = useCancelBooking(booking), cancelBooking = _d.mutate, deleteMutationStatus = _d.status;
    var onDeleteReservation = function (message, checkOut) { return __awaiter(void 0, void 0, void 0, function () {
        var confirmed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, openDialog(React.createElement(ConfirmationDialog, { key: "delete-dialog", triggerId: FocusId.BookingDeleteButton, title: message }))];
                case 1:
                    confirmed = _a.sent();
                    if (confirmed) {
                        setIsCheckOut(checkOut);
                        cancelBooking({ tenantId: tenantId, spaceId: spaceId, meetingId: meetingId });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    // this state is hack for P2-1733, API will not update isCheckInAvailable on booking
    // when check-in window actually starts
    var _e = useState(false), bookingRefreshedOnCheckInOpen = _e[0], setBookingRefreshedOnCheckInOpen = _e[1];
    // refresh when check in opens
    var onCheckInOpen = useCallback(function () {
        // if user has the details screen open while check-in window opens,
        // refetch booking details to get isCheckInAvailable: true and activate the Check-in button
        if (!(booking === null || booking === void 0 ? void 0 : booking.isCheckInAvailable) &&
            !(booking === null || booking === void 0 ? void 0 : booking.isCheckedIn) &&
            !bookingRefreshedOnCheckInOpen) {
            refreshBooking(booking);
            setBookingRefreshedOnCheckInOpen(true);
        }
    }, [booking, bookingRefreshedOnCheckInOpen, refreshBooking]);
    var _f = useMutation(checkInBooking, {
        onSuccess: function () {
            openDialog(React.createElement(ConfirmationDialog, { title: t('layout.bookingCard.checkInSuccessDialogTitle'), message: t('layout.bookingCard.checkInSuccessDialogMessage'), triggerId: FocusId.BookingCheckInButton, key: "successful-check-in-dialog", hasCancelButton: false }));
            refreshBooking(booking);
        },
        onError: function (error) {
            var _a, _b;
            var errorMessage = CHECK_IN_MESSAGES[(_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error] ||
                'layout.booking.errorMessages.genericCheckIn';
            dispatch(inAppNotificationsActions.addErrorNotification({
                message: t(errorMessage),
            }));
        },
    }), onCheckInMutation = _f.mutate, checkInMutationStatus = _f.status;
    var startCheckIn = useStartCheckIn(function () {
        return onCheckInMutation({ tenantId: tenantId, spaceId: spaceId, meetingId: meetingId });
    });
    var onUpdateReservation = function () { };
    var actionInProgress = '';
    if (deleteMutationStatus === 'loading' && !isCheckOut)
        actionInProgress = BUTTONS.DELETE;
    if (deleteMutationStatus === 'loading' && isCheckOut)
        actionInProgress = BUTTONS.CHECK_OUT;
    if (checkInMutationStatus === 'loading')
        actionInProgress = BUTTONS.CHECK_IN;
    useEffect(function () {
        if (statusObj.status === CHECK_IN_STATUS.CHECK_IN_OPEN) {
            onCheckInOpen();
        }
    }, [onCheckInOpen, statusObj.status]);
    var buttonStatuses = getBookingDetailsStatus({
        booking: booking,
        statusObj: statusObj,
        enableDeskCheckIn: enableDeskCheckIn,
        actionInProgress: actionInProgress,
    });
    var DELETE = BUTTONS.DELETE, CHECK_IN = BUTTONS.CHECK_IN, CHECK_OUT = BUTTONS.CHECK_OUT, UPDATE = BUTTONS.UPDATE;
    var DISPLAY = STATUSES.DISPLAY, DISABLED = STATUSES.DISABLED, LOADING = STATUSES.LOADING;
    var showCheckInMessage = !!statusObj.statusMessage &&
        isDesk &&
        !isInInactiveLocation &&
        !isBookedForOther;
    return (React.createElement(React.Fragment, null,
        React.createElement(LineWrapper, null,
            buttonStatuses[DELETE][DISPLAY] && (React.createElement(DeleteButton, { id: FocusId.BookingDeleteButton, onClick: function () {
                    return onDeleteReservation(t('layout.bookingCard.deleteEvent'), false);
                }, text: t('common.delete'), disabled: buttonStatuses[DELETE][DISABLED], opacity: buttonStatuses[DELETE][DISABLED] ? 0.4 : 1, isLoading: buttonStatuses[DELETE][LOADING], color: color1, backgroundColor: "transparent" },
                React.createElement(Icon, { name: "bin", size: 13, style: { color: color1 } }))),
            buttonStatuses[UPDATE][DISPLAY] && (React.createElement(Button, { onClick: onUpdateReservation, disabled: buttonStatuses[UPDATE][DISABLED], opacity: buttonStatuses[UPDATE][DISABLED] ? 0.4 : 1, color: color1, backgroundColor: color3, text: t('common.updateReservation') })),
            buttonStatuses[CHECK_IN][DISPLAY] && (React.createElement(Button, { id: FocusId.BookingCheckInButton, onClick: startCheckIn, disabled: buttonStatuses[CHECK_IN][DISABLED], opacity: buttonStatuses[CHECK_IN][DISABLED] ? 0.4 : 1, isLoading: buttonStatuses[CHECK_IN][LOADING], text: t('common.checkIn'), color: color6, backgroundColor: color1 })),
            buttonStatuses[CHECK_OUT][DISPLAY] && (React.createElement(Button, { onClick: function () {
                    return onDeleteReservation(t('layout.bookingCard.checkOutReservation'), true);
                }, disabled: buttonStatuses[CHECK_OUT][DISABLED], opacity: buttonStatuses[CHECK_OUT][DISABLED] ? 0.4 : 1, isLoading: buttonStatuses[CHECK_OUT][LOADING], text: t('common.checkOut'), color: color6, backgroundColor: color1 }))),
        showCheckInMessage && (React.createElement(LineWrapper, { "data-testid": "checkInMessage" },
            React.createElement(StatusMessage, { isClosed: statusObj.status === CHECK_IN_STATUS.CHECK_IN_CLOSED }, statusObj.statusMessage)))));
};
