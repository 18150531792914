var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var DEFAULT_SIZE = 40;
export var ProgressText = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 8px;\n  line-height: 11px;\n  color: ", ";\n  opacity: 0.5;\n"], ["\n  font-weight: 600;\n  font-size: 8px;\n  line-height: 11px;\n  color: ", ";\n  opacity: 0.5;\n"])), function (_a) {
    var color = _a.color;
    return color;
});
export var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", "px;\n  height: ", "px;\n"], ["\n  width: ", "px;\n  height: ", "px;\n"])), function (_a) {
    var size = _a.size;
    return size;
}, function (_a) {
    var size = _a.size;
    return size;
});
var templateObject_1, templateObject_2;
