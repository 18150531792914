var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Z_INDEX } from 'consts';
export var DateButtonWrapper = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border-radius: 25px;\n  background-color: ", ";\n  padding: 2px 2px 2px 7px;\n  align-items: center;\n  font-weight: 700;\n  position: relative;\n  color: ", ";\n  z-index: ", ";\n  pointer-events: ", ";\n  cursor: pointer;\n\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: flex;\n  border-radius: 25px;\n  background-color: ", ";\n  padding: 2px 2px 2px 7px;\n  align-items: center;\n  font-weight: 700;\n  position: relative;\n  color: ", ";\n  z-index: ", ";\n  pointer-events: ", ";\n  cursor: pointer;\n\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var isOpen = _a.isOpen;
    return (isOpen ? 'transparent' : '#fff');
}, function (_a) {
    var isOpen = _a.isOpen, theme = _a.theme;
    return (isOpen ? '#fff' : theme.typo2);
}, function (_a) {
    var isOpen = _a.isOpen;
    return (isOpen ? Z_INDEX.MODALS : 0);
}, function (_a) {
    var disableInteractions = _a.disableInteractions;
    return disableInteractions ? 'none' : 'unset';
});
export var DateButtonIconWrapper = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  line-height: 0;\n  top: 1px;\n  user-select: none;\n"], ["\n  position: relative;\n  line-height: 0;\n  top: 1px;\n  user-select: none;\n"])));
export var DateButtonDateTextWrapper = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 25px;\n  background-color: ", ";\n  padding: 6px 10px;\n  color: #fff;\n  margin-left: 10px;\n  display: flex;\n  align-items: center;\n  user-select: none;\n  cursor: pointer;\n  position: relative;\n  & .date-button__text {\n    margin-right: 20px;\n    font-weight: 700;\n  }\n"], ["\n  border-radius: 25px;\n  background-color: ", ";\n  padding: 6px 10px;\n  color: #fff;\n  margin-left: 10px;\n  display: flex;\n  align-items: center;\n  user-select: none;\n  cursor: pointer;\n  position: relative;\n  & .date-button__text {\n    margin-right: 20px;\n    font-weight: 700;\n  }\n"])), function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return isActive ? theme.color9 : theme.typo2;
});
export var DateButtonDateClipWrapper = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 50%;\n  height: 27px;\n  width: 27px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  right: 0;\n  cursor: pointer;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  border-radius: 50%;\n  height: 27px;\n  width: 27px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  right: 0;\n  cursor: pointer;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])));
export var DateButtonDateClip = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 6px;\n  border-radius: 50px;\n  background-color: #fff;\n  transition: height 0.1s;\n  height: ", ";\n  position: relative;\n  left: ", ";\n  z-index: ", ";\n"], ["\n  width: 6px;\n  border-radius: 50px;\n  background-color: #fff;\n  transition: height 0.1s;\n  height: ", ";\n  position: relative;\n  left: ", ";\n  z-index: ", ";\n"])), function (_a) {
    var isOpen = _a.isOpen;
    return (isOpen ? '12px' : '6px');
}, function (_a) {
    var isActive = _a.isActive;
    return (isActive ? '-2px' : '-20px');
}, Z_INDEX.MODALS);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
