import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSetUserConfig, hashString, } from '@serraview/engage-shared';
import { navigationSelectors } from 'store';
import { useConfig } from 'utils';
/**
 * Returns whether the landing page should be shown or not based
 * on tenant and user configs.
 * If there is a deeplink in the store – landing page will be hidden.
 */
export var useShowLandingPage = function (userConfig) {
    var config = useConfig();
    var setUserConfig = useSetUserConfig().setUserConfig;
    var deepLinkPath = useSelector(navigationSelectors.getDeepLinkPath);
    var deepLinkSearch = useSelector(navigationSelectors.getDeepLinkQueryString);
    var hasDeepLink = !!(deepLinkPath || deepLinkSearch);
    var landingPageContentHash = hashString(config.welcomeTitle + config.welcomeMessage);
    // means title and message are empty
    var hashIsEmpty = landingPageContentHash.length === 0;
    var showLandingPage = (userConfig === null || userConfig === void 0 ? void 0 : userConfig.landingPageContentHash)
        ? landingPageContentHash !== userConfig.landingPageContentHash
        : true;
    useEffect(function () {
        var setConfigTimeout;
        if (showLandingPage && hasDeepLink) {
            // postpone setting landing page content cache
            // because if user will initially load page with the deep link there will be user_config fetch
            // then user config set (here) and automatic refetch in other places
            setConfigTimeout = setTimeout(function () {
                setUserConfig({ landingPageContentHash: landingPageContentHash });
            }, 2000);
        }
        return function () {
            if (setConfigTimeout) {
                clearTimeout(setConfigTimeout);
            }
        };
    }, [hasDeepLink, showLandingPage, landingPageContentHash, setUserConfig]);
    return !hasDeepLink && showLandingPage && !hashIsEmpty;
};
