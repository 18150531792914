import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchValue: '',
  restrictTo: undefined,
};

const globalSearch = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setRestrictToSearch: (state, action) => {
      state.restrictTo =
        state.restrictTo === action.payload ? undefined : action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    clearSearchValue: (state) => {
      state.searchValue = '';
    },
  },
});

export const globalSearchSelectors = {
  getRestrictToSearchFilterValue: (state) => state.globalSearch.restrictTo,
  getGlobalSearchValue: (state) => state.globalSearch.searchValue,
  getHasUserInput: ({ globalSearch }) =>
    !!globalSearch.searchValue || !!globalSearch.restrictTo,
};

export default globalSearch;
