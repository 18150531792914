var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Icon } from 'components/base';
import React from 'react';
import { FocusId } from 'utils/hooks/useFocus';
import { useTranslation } from 'react-i18next';
import { testProps, useGenerateCurrentLocationPath } from 'utils';
import { PATH_SEGMENT, ROOT_PATH } from 'consts';
import { useAppLocation } from 'router/hooks';
import { joinPaths } from 'router/utils';
import { StyledButton } from './styled';
export function CloseButton(_a) {
    var _b, _c;
    var isCloseIcon = _a.isCloseIcon, closeLinkPath = _a.closeLinkPath, onPress = _a.onPress, isMoreMenuVisible = _a.isMoreMenuVisible, closeIconA11yLabel = _a.closeIconA11yLabel;
    var t = useTranslation().t;
    var _d = useAppLocation(), state = _d.state, search = _d.search, pathname = _d.pathname;
    var currentLocationPath = useGenerateCurrentLocationPath() || ROOT_PATH;
    var to = isCloseIcon
        ? (_c = (_b = state === null || state === void 0 ? void 0 : state.fromPath) !== null && _b !== void 0 ? _b : closeLinkPath) !== null && _c !== void 0 ? _c : currentLocationPath
        : joinPaths(currentLocationPath, PATH_SEGMENT.LOCATION_SWITCHER);
    var a11yAndTestProps = testProps(t, [closeIconA11yLabel !== null && closeIconA11yLabel !== void 0 ? closeIconA11yLabel : '', 'accessibilityLabels.changeLocation'], function (_a) {
        var closeLayout = _a[0], changeLocation = _a[1];
        return isCloseIcon ? closeLayout : changeLocation;
    }).props;
    return (React.createElement(StyledButton, __assign({ id: FocusId.DrawerCloseButton, to: {
            pathname: to,
            search: search,
        }, state: {
            // right now we use fromPath only on wayfinder route
            // to get back to the correct route
            // !isCloseButton because only location selector has chevron icon (>)
            fromPath: !isCloseIcon ? pathname : null,
        }, onClick: onPress }, a11yAndTestProps, { "$withoutBackground": isMoreMenuVisible }),
        React.createElement(Icon, { shouldRotateOnRtl: true, name: isCloseIcon ? 'close' : 'chevron-right', size: 11 })));
}
