import React from 'react';
import { CurrentDateTime } from 'components/base';
import { useSelector } from 'react-redux';
import { tenantSelectors, userConfigSelectors } from 'store';
import { useNavigate } from 'react-router-dom';
import { KIOSK_ADMIN } from 'consts';
var KioskCurrentDateTime = function (_a) {
    var textColor = _a.textColor;
    var navigate = useNavigate();
    var building = useSelector(tenantSelectors.getKioskConfig).userLocation.building;
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var handleAdminPress = function () {
        navigate(KIOSK_ADMIN);
    };
    var timeZone = building === null || building === void 0 ? void 0 : building.timeZone;
    return (React.createElement(CurrentDateTime, { locale: locale, timeZone: timeZone, textColor: textColor, onAdminPress: handleAdminPress }));
};
export default KioskCurrentDateTime;
