import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { tenantSelectors } from 'store';
import { hasTokens } from 'utils/config/tokenConfig';
import { useAppLocation } from 'router/hooks';
import { getShouldRedirect, getRedirectPath } from 'router/utils';

export const PublicRoute = React.memo(({ children }) => {
  const { search, pathname } = useAppLocation();
  const tenantId = useSelector(tenantSelectors.getTenantId);

  const isAuthenticated = hasTokens();
  const hasTenantId = !!tenantId;
  const shouldRedirect = getShouldRedirect({
    isAuthenticated,
    hasTenantId,
    path: pathname,
  });

  // Prevent authenticated user from navigating to the login or tenant scenes
  return shouldRedirect ? (
    <Navigate
      replace
      to={{
        pathname: getRedirectPath({ isAuthenticated, hasTenantId }),
        search,
      }}
    />
  ) : (
    children
  );
});

PublicRoute.propTypes = {
  children: PropTypes.any.isRequired,
};
