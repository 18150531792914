import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTimeText } from 'layouts/Agenda/utils';
import { getEventExtraHeight } from 'layouts/Agenda/content/utils';
import { userConfigSelectors } from 'store';
import {
  AgendaItemBookedFor,
  AgendaItemTime,
  AgendaItemTitle,
  AgendaWrapperContent,
  AgendaWrapperItem,
  IconWrapper,
  LocalTime,
  useAgendaItemStyle,
} from 'layouts/Agenda/content/Items/styled';
import Analytics from 'analytics';
import moment from 'moment-timezone';
import { agendaEventPropTypes } from 'utils';
import {
  formatLocalizedDate,
  isAllDayBookingLocalized,
} from '@serraview/engage-shared/utils';
import { useBuilding } from '@serraview/engage-shared/api';
import { BOOKING_TYPE, DateFormat } from '@serraview/engage-shared';
import { Icon } from 'components/base';

const getIconName = (bookingType, isDesk) => {
  switch (bookingType) {
    case BOOKING_TYPE.DESK:
      return 'desk';
    case BOOKING_TYPE.SPACE:
      return 'space';
    case BOOKING_TYPE.TEAM:
      return 'teams';
    default:
      return isDesk ? 'desk' : 'space';
  }
};

const AgendaEvent = ({ event, onSelectEvent }) => {
  const {
    name,
    summary,
    start,
    end,
    timeZone,
    buildingId,
    isAllDayBooking,
    isBookedForOther,
    reserveeName,
    isDesk,
    bookingType,
    isInInactiveLocation,
  } = event;

  const { t } = useTranslation();
  const styles = useAgendaItemStyle(start, end);
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  const { data: building } = useBuilding({ buildingId });
  const buildingName = isInInactiveLocation ? null : building?.name;

  const localTimeStr = t('layout.agendaList.localTime');
  const bookedForStr = t('layout.agendaList.bookedFor');

  // get browser timezone; true means ignore cache
  const localTimeZoneString = moment.tz.guess(true);
  const isLocalTimeSameAsBuilding = localTimeZoneString === timeZone;
  const allDay =
    isAllDayBooking ||
    isAllDayBookingLocalized({
      dateS: start,
      dateE: end,
      timeZone: localTimeZoneString,
    });

  const bookingStartDateTimeString = formatLocalizedDate(start, {
    timeZone: localTimeZoneString,
    locale,
    format: DateFormat.weekDayShortWithTime,
  });

  const buildingStartEndTimeString = getTimeText({
    start,
    end,
    isAllDayBooking: allDay,
    timeZone,
    locale,
  });

  const _onSelectEvent = (e) => {
    e.stopPropagation();
    Analytics.track('EVT-078');
    onSelectEvent({ event });
  };

  const extraHeight = getEventExtraHeight(event);

  return (
    <AgendaWrapperItem
      $style={styles.wrapper}
      onClick={_onSelectEvent}
      extraHeight={extraHeight}
      data-testid="event-item"
      role="button"
      name={`${name || summary}-${buildingName}-${buildingStartEndTimeString}`}
    >
      <AgendaWrapperContent>
        <AgendaItemTitle $style={styles.title}>
          <span data-testid="event-summary">{name || summary}</span>
          {buildingName && (
            <>
              <span className="separator">&ndash;</span>
              <span className="building-name" data-testid="building-name">
                {' '}
                {buildingName}
              </span>
            </>
          )}
        </AgendaItemTitle>
        {isBookedForOther && (
          <AgendaItemBookedFor $style={styles.title}>
            {bookedForStr}: &nbsp; {reserveeName}
          </AgendaItemBookedFor>
        )}
        <AgendaItemTime $style={styles.time} data-testid="event-time-text">
          {buildingStartEndTimeString}
        </AgendaItemTime>
        {!isLocalTimeSameAsBuilding && (
          <LocalTime data-testid="local-time-text-wrapper">
            <AgendaItemTime $style={styles.time}>
              {localTimeStr} {bookingStartDateTimeString} ({localTimeZoneString}
              )
            </AgendaItemTime>
          </LocalTime>
        )}
      </AgendaWrapperContent>
      <IconWrapper>
        <Icon name={getIconName(bookingType, isDesk)} size={20} />
      </IconWrapper>
    </AgendaWrapperItem>
  );
};

AgendaEvent.propTypes = {
  event: PropTypes.shape(agendaEventPropTypes),
  onSelectEvent: PropTypes.func,
};
AgendaEvent.defaultProps = {
  event: agendaEventPropTypes.defaultProps,
  onSelectEvent: () => {},
};

export default memo(AgendaEvent);
