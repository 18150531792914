import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useBuildingFloors } from '@serraview/engage-shared/api';
import Floor from './Floor';
import {
  BuildingName,
  BuildingWrapper,
  FloorsListWrapper,
  LabelsContainer,
  LabelFloorAvailability,
} from './styled';
import FloorListSkeleton from './FloorListSkeleton';
import { locationStateTypes, SET_ACTIVE_BUILDING } from './useLocationSelector';

const FLOORS_REFETCH_TIME = 5 * 60 * 1000;

const Building = ({ building, locationState, locationDispatch }) => {
  const { t } = useTranslation();
  const { activeBuilding } = locationState;

  const open = activeBuilding && activeBuilding.id === building.id;

  const {
    data: floors,
    isLoading,
    isError,
    refetch,
  } = useBuildingFloors({
    buildingId: building.id,
    options: {
      enabled: !!open,
      refetchInterval: FLOORS_REFETCH_TIME,
    },
  });

  // re-fetch floors availability info on building opening
  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const renderFloorsList = () => {
    if (!open) {
      return null;
    }
    if (isLoading) {
      return <FloorListSkeleton />;
    }
    if (isError) {
      return (
        <FloorsListWrapper>
          <div style={{ maxWidth: 200 }}>
            {t('layout.locationSelector.floorsError')}
          </div>
        </FloorsListWrapper>
      );
    }
    return (
      <FloorsListWrapper>
        {floors?.length && (
          <LabelsContainer>
            <LabelFloorAvailability>{t('common.floor')}</LabelFloorAvailability>
            <LabelFloorAvailability>
              {t('common.availability')}
            </LabelFloorAvailability>
          </LabelsContainer>
        )}
        {floors.map((floor) => (
          <Floor key={floor.id} floor={floor} locationState={locationState} />
        ))}
      </FloorsListWrapper>
    );
  };

  const openBuilding = () => {
    locationDispatch({ type: SET_ACTIVE_BUILDING, payload: building });
  };

  const closeBuilding = () => {
    locationDispatch({ type: SET_ACTIVE_BUILDING, payload: null });
  };

  return (
    <BuildingWrapper>
      <BuildingName
        data-testid="building-list-item"
        isActive={open}
        onClick={() => (open ? closeBuilding() : openBuilding())}
      >
        {building.name}
      </BuildingName>
      {renderFloorsList()}
    </BuildingWrapper>
  );
};

Building.propTypes = {
  building: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  locationState: PropTypes.shape(locationStateTypes).isRequired,
  locationDispatch: PropTypes.func.isRequired,
};

export default Building;
