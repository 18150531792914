var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Video = styled.video(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  object-fit: cover;\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: ", ";\n"], ["\n  object-fit: cover;\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color2;
});
var templateObject_1;
