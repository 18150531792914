export var AZURE_TENANTS = {
    uhg: {
        queryParam: 'spg_engage_token',
    },
    'uhg.uat': {
        queryParam: 'spg_engage_token',
    },
    'engage1.tst': {
        queryParam: 'idToken',
    },
};
export var getQueryParamForTenant = function (tenantId) { var _a, _b; return (_b = (_a = AZURE_TENANTS[tenantId]) === null || _a === void 0 ? void 0 : _a.queryParam) !== null && _b !== void 0 ? _b : ''; };
