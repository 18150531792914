var downloadPathRegex = /engage_api\/v1\/stored_files\/[0-9]*\/download/i;
export var isDownloadPath = function (path) {
    return path ? downloadPathRegex.test(path) : false;
};
export var getPersonImageUrl = function (_a) {
    var imageUrl = _a.imageUrl, isDownloadImage = _a.isDownloadImage, isSuccess = _a.isSuccess, fileImageUrl = _a.fileImageUrl;
    if (!imageUrl)
        return null;
    if (!isDownloadImage)
        return imageUrl !== null && imageUrl !== void 0 ? imageUrl : null;
    if (isDownloadImage && isSuccess)
        return fileImageUrl;
    return null;
};
