import React from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useSelectFloor } from 'utils/hooks/useSelectFloor';
import { Loader, LogoAnimation, Wrapper } from 'components/scenes/Main/styled';
import { ClientLogo, CornerGradient, Spinner } from 'components/base';
import Modals from 'components/scenes/modals/Modals';
import { NoLocationBackground } from 'layouts';
import { SEARCH_QUERY } from 'consts';
import { useSpaceLocation } from 'api/queries';
import { generateFloorPath } from 'router/utils';
import { useAppLocation } from 'router/hooks';
export function Wayfinder() {
    var location = useAppLocation();
    var search = location.search;
    var query = useSearchParams()[0];
    var _a = useSelector(tenantSelectors.getCurrentLocation), building = _a.building, floor = _a.floor;
    var userId = useSelector(tenantSelectors.getUserId);
    var spaceId = query.get(SEARCH_QUERY.SPACE);
    var deskId = query.get(SEARCH_QUERY.DESK);
    var bookingSpaceId = query.get(SEARCH_QUERY.BOOKING_SPACE);
    var bookingMeetingId = query.get(SEARCH_QUERY.BOOKING_MEETING);
    var spaceTypeId = spaceId || deskId;
    var hasLocation = !!(building && floor);
    var setSpaceLocation = useSpaceLocation().setSpaceLocation;
    var checkForDefaultFloor = useSelectFloor();
    var _b = React.useState(false), locationVerified = _b[0], setLocationVerified = _b[1];
    React.useEffect(function () {
        if (!!bookingSpaceId && !!bookingMeetingId && !hasLocation) {
            setSpaceLocation({ spaceId: bookingSpaceId }).then(function () {
                setLocationVerified(true);
            });
        }
        else if (!!userId && !hasLocation) {
            checkForDefaultFloor(function () { return setLocationVerified(true); });
        }
        else {
            setLocationVerified(true);
        }
    }, [
        bookingMeetingId,
        bookingSpaceId,
        hasLocation,
        location,
        setSpaceLocation,
        spaceTypeId,
        userId,
        checkForDefaultFloor,
    ]);
    if (!locationVerified) {
        return (React.createElement(Loader, null,
            React.createElement(LogoAnimation, null,
                React.createElement(ClientLogo, { fallback: React.createElement(Spinner, null) }))));
    }
    if (hasLocation) {
        return (React.createElement(Navigate, { replace: true, to: {
                pathname: generateFloorPath(floor.id),
                search: search,
            } }));
    }
    return (React.createElement(Wrapper, { "data-testid": "main-screen" },
        React.createElement(CornerGradient, null),
        React.createElement(Modals, null),
        React.createElement(NoLocationBackground, null),
        React.createElement(Outlet, null)));
}
