import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'components/base';
import { SkeletonItemWrapper, SkeletonWrapper } from './styled';
var items = new Array(5).fill(null);
export function DeskListSkeleton() {
    var renderItem = function (_, index) { return (React.createElement(SkeletonItemWrapper, { key: index },
        React.createElement(Skeleton, { circle: true, height: 34, width: 34 }),
        React.createElement(Skeleton, { width: 100 }))); };
    return (React.createElement(SkeletonTheme, null,
        React.createElement(SkeletonWrapper, null, items.map(renderItem))));
}
