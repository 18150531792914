import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
var useConfigLanguages = function () {
    var availableLanguages = useSelector(tenantSelectors.getConfig).availableLanguages;
    return Object.keys(availableLanguages).map(function (key) { return ({
        label: availableLanguages[key],
        value: key,
    }); });
};
export default useConfigLanguages;
