import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  ${(props) => props.style || {}}

  & .react-loading-skeleton {
    background-color: ${(props) => props.color};
    background-image: linear-gradient(
      90deg,
      ${(props) => props.color},
      ${(props) => props.highlightColor},
      ${(props) => props.color}
    );
  }
`;

const SkeletonTheme = ({ color, highlightColor, style, children }) => {
  const { color3, color4, isLayoutRTL } = useTheme();
  const _color = color || color3;
  const _highlightColor = highlightColor || color4;
  return (
    <Wrapper
      color={_color}
      isLayoutRTL={isLayoutRTL}
      highlightColor={_highlightColor}
      style={style}
    >
      {children}
    </Wrapper>
  );
};

SkeletonTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  highlightColor: PropTypes.string,
  style: PropTypes.object,
};

SkeletonTheme.defaultProps = {
  color: null,
  highlightColor: null,
  style: {},
};

export default memo(SkeletonTheme);
