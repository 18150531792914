import React from 'react';
import { getFileExtension } from '@serraview/engage-shared';
import { isElectron } from 'utils/electron';
import { Video } from './CoverVideo.styles.';
var CoverVideo = function (_a) {
    var coverImage = _a.coverImage;
    if (!coverImage)
        return null;
    // enable kiosk mode here
    var isKiosk = isElectron();
    var ext = getFileExtension(coverImage);
    var isVideo = coverImage && ext === 'mp4';
    if (!isKiosk || !isVideo)
        return null;
    return (React.createElement(Video, { loop: true, autoPlay: true, muted: true, playsInline: true },
        React.createElement("source", { src: coverImage, type: "video/mp4" }),
        "Your browser does not support the video tag."));
};
export default CoverVideo;
