var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { testProps } from 'utils';
import { useClientLogo } from './useClientLogo';
import { calculateAspectRatio } from './svgUtils';
import { LogoFallback, LogoWrapper } from './styled';
var ClientLogo = function (_a) {
    var fallback = _a.fallback, _b = _a.logoURL, logoURL = _b === void 0 ? '' : _b, _c = _a.title, title = _c === void 0 ? '' : _c, _d = _a.id, id = _d === void 0 ? '' : _d, textColor = _a.textColor;
    var t = useTranslation().t;
    var clientLogQuery = useClientLogo(logoURL, title, id);
    var data = (clientLogQuery === null || clientLogQuery === void 0 ? void 0 : clientLogQuery.data) || {};
    var xml = data.xml, svgProps = data.props, instanceTitle = data.instanceTitle;
    var color = textColor !== null && textColor !== void 0 ? textColor : 'none';
    if (!xml) {
        return (fallback || (React.createElement(LogoFallback, { "$textColor": color, "data-testid": "client-logo-fallback" }, instanceTitle)));
    }
    if (!svgProps)
        return null;
    if ('viewBox' in svgProps) {
        var _e = calculateAspectRatio({
            viewBox: svgProps.viewBox,
        }), width = _e.width, height = _e.height;
        return (React.createElement(LogoWrapper, __assign({ "$fillColor": color }, testProps(t, 'accessibilityLabels.clientLogo').props, { dangerouslySetInnerHTML: { __html: xml }, "$width": width, "$height": height })));
    }
    return null;
};
export default ClientLogo;
