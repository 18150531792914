import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TimeSelect from 'base-components/TimeSelect/TimeSelect';
import { TIME_TYPES } from './data';
import { TimePickerWrapper } from './styled';

const TimePicker = ({ value, onChange, timeOptions, isTimeFormatH12 }) => (
  <TimePickerWrapper>
    <TimeSelect
      data={timeOptions.hours}
      isPickedTimeInPast={timeOptions.isPickedTimeInPast}
      onChange={onChange}
      selectedID={value.hour}
      type={TIME_TYPES.HOUR}
    />
    <TimeSelect
      data={timeOptions.minutes}
      isPickedTimeInPast={timeOptions.isPickedTimeInPast}
      onChange={onChange}
      selectedID={value.minute}
      type={TIME_TYPES.MINUTE}
    />
    {isTimeFormatH12 && (
      <TimeSelect
        data={timeOptions.periods}
        isPickedTimeInPast={timeOptions.isPickedTimeInPast}
        onChange={onChange}
        selectedID={value.period}
        type={TIME_TYPES.PERIOD}
        isTimeFormatH12
      />
    )}
  </TimePickerWrapper>
);

TimePicker.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  timeOptions: PropTypes.object.isRequired,
  isTimeFormatH12: PropTypes.bool,
};

TimePicker.defaultProps = {
  isTimeFormatH12: false,
};

export default memo(TimePicker);
