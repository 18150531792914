var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo, useCallback } from 'react';
import DeskItem from 'components/layouts/GlobalSearch/DeskItem';
import SelectableAvatar from 'components/base/Avatar/SelectableAvatar';
import { SelectableItemWrapper } from 'components/layouts/Team/TeamMembers/TeamMembers.style';
var SelectableDeskItem = function (_a) {
    var isSelected = _a.isSelected, item = _a.item, style = _a.style, rest = __rest(_a, ["isSelected", "item", "style"]);
    var renderAvatar = useCallback(function (avatarProps) { return (React.createElement(SelectableAvatar, __assign({ a11LabelSuffix: item.name, isSelected: isSelected }, avatarProps))); }, [isSelected, item.name]);
    return (React.createElement(SelectableItemWrapper, { style: style, isSelected: isSelected },
        React.createElement(DeskItem, __assign({ item: item }, rest, { renderAvatar: renderAvatar, showBadge: !isSelected }))));
};
var MemoSelectableDeskItem = memo(SelectableDeskItem);
MemoSelectableDeskItem.getSize = DeskItem.getSize;
export default MemoSelectableDeskItem;
