var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getMenuItemClickHandler } from 'components/scenes/ConciergeMenu/utils/getMenuItemClickHandler';
import { isElectron } from 'utils/electron';
import { getIsMenuItemHidden } from 'components/scenes/ConciergeMenu/utils/getIsMenuItemHidden';
export var sortBySequenceAsc = function (itemA, itemB) { return itemA.sequence - itemB.sequence; };
export var transformMenuItems = function (_a) {
    var menuItems = _a.menuItems, dispatch = _a.dispatch, navigate = _a.navigate, currentFloorId = _a.currentFloorId, email = _a.email, heatmapKiosk = _a.heatmapKiosk;
    return menuItems
        .map(function (item) { return (__assign(__assign({}, item), { onMenuItemClick: getMenuItemClickHandler({
            url: item.url,
            openWithExternalBrowser: isElectron()
                ? false
                : item.openWithExternalBrowser,
            dispatch: dispatch,
            navigate: navigate,
            currentFloorId: currentFloorId,
            email: email,
            heatmapKiosk: heatmapKiosk,
        }) })); })
        .filter(function (item) {
        return !getIsMenuItemHidden(item.url, item.hiddenForWeb, item.hiddenForKiosk, process.env.NODE_ENV);
    })
        .sort(sortBySequenceAsc);
};
