export const bindRetryCallback = (self, call, onError, retries, timeout) => {
  let retryCount = 0;
  let lastCallTime = new Date();
  const errCallback = () => {
    if (retryCount < retries && new Date() - lastCallTime < timeout) {
      // eslint-disable-next-line no-use-before-define
      boundCall();
    } else {
      onError();
    }

    retryCount += 1;
    lastCallTime = new Date();
  };

  const boundCall = call.bind(self, errCallback);
  return boundCall;
};
