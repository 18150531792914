import OpenSeadragon from 'openseadragon';

export const getShapeCoords = (space) => {
  if (!space) return null;
  if (space.shape) {
    return space.shape.labelPoint
      ? space.shape.labelPoint
      : space.shape.coordinates[0];
  }
  return space.shapes[0].labelPoint
    ? space.shapes[0].labelPoint
    : space.shapes[0].coordinates[0];
};

export const getShapeBoundingBox = (shape) => {
  if (!shape) return null;

  const coords = shape.shapes[0].coordinates;

  let bounds = new OpenSeadragon.Rect(coords[0].x, coords[0].y);

  if (shape.shapes[0].isPolygon) {
    coords.forEach((point) => {
      bounds = bounds.union(new OpenSeadragon.Rect(point.x, point.y));
    });
  } else {
    bounds = bounds.union(
      new OpenSeadragon.Rect(coords[0].x - 100, coords[0].y - 100),
    );
    bounds = bounds.union(
      new OpenSeadragon.Rect(coords[0].x + 100, coords[0].y + 100),
    );
  }

  return bounds;
};

export const svLivePersonLocationToShape = (personLocation) => {
  if (!personLocation) return null;

  return {
    shape: { isPolygon: false, coordinates: [personLocation] },
    shapes: [{ coordinates: [personLocation] }],
  };
};
