import React from 'react';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { TenantButton } from 'components/scenes/Tenant/TenantButton/TenantButton';
import { Container } from './styled';
export var PastTenants = function (_a) {
    var onItemClick = _a.onItemClick;
    var pastTenants = useSelector(tenantSelectors.getPastTenants);
    if (!pastTenants.length) {
        return null;
    }
    return (React.createElement(Container, { "data-testid": "past-tenants" }, pastTenants.map(function (tenant) { return (React.createElement(TenantButton, { key: tenant.id, tenant: tenant, onClick: function () { return onItemClick(tenant.id); } })); })));
};
