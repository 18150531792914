import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDialog } from 'utils/hooks';
import { Overlay } from './styled';
export function Dialog() {
    var _a = useDialog(), isOpen = _a.isOpen, closeDialog = _a.closeDialog;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "dialog-root" }),
        React.createElement(CSSTransition, { unmountOnExit: true, in: isOpen, timeout: 200, classNames: "overlay" },
            React.createElement(Overlay, { onClick: function () { return closeDialog(false); } }))));
}
