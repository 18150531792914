import { useBookingModal, useModal } from 'utils';
import { isElectron } from 'utils/electron';
import { MODALS } from 'consts';
import { useCallback } from 'react';
export var useGoBackFromBooking = function (_a) {
    var booking = _a.booking;
    var closeBookingModal = useBookingModal().close;
    var openModal = useModal().openModal;
    var goBack = useCallback(function () {
        closeBookingModal();
        if (isElectron()) {
            openModal(MODALS.USER_PROFILE);
        }
        else {
            openModal(MODALS.AGENDA, { startDate: booking === null || booking === void 0 ? void 0 : booking.startDate });
        }
    }, [booking === null || booking === void 0 ? void 0 : booking.startDate, closeBookingModal, openModal]);
    return { goBack: goBack, openModal: openModal };
};
