import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Wrapped } from './styled';
import { generateImagePoint, generateWebPoint, handleWheel } from './helpers';

const DragSelection = ({
  onDragComplete,
  onDragMove,
  onClick,
  viewer,
  active,
}) => {
  const elementRef = useRef(null);
  const isDraggingRef = useRef(false);

  const element = elementRef.current;
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const startPoint = useRef(null);
  const endPoint = useRef(null);

  const endDrag = (event) => {
    if (!element || !isDraggingRef.current) {
      return;
    }

    const webPoint = generateWebPoint({ event, element });
    const imagePoint = generateImagePoint({ webPoint, viewer });

    setEndX(webPoint.x);
    setEndY(webPoint.y);
    endPoint.current = imagePoint;
    isDraggingRef.current = false;
    setIsDragging(false);

    onDragComplete({
      isDragging: false,
      startX,
      startY,
      endX: webPoint.x,
      endY: webPoint.y,
      startPoint,
      endPoint: endPoint.current,
    });
  };

  useEffect(() => {
    // Page scrolling must be prevented while zooming floorplan, since React uses SynteticEvent's which doesnt allow preventing
    // we added a workaround with passive attribute where we can access native event
    // and cancel further propagation and disable page scroll
    // this could cause problems in future versions of React when logic of Syntetic events will be changed
    if (element?.addEventListener) {
      element.addEventListener('wheel', handleWheel, {
        passive: false,
      });
    }

    return () => {
      element?.removeEventListener('wheel', handleWheel);
    };
    // we are only interested in onMount and onUnmount stages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startDrag = (event) => {
    if (!element || isDraggingRef.current || !viewer) {
      return;
    }

    const webPoint = generateWebPoint({ event, element });
    const imagePoint = generateImagePoint({ webPoint, viewer });

    setStartX(webPoint.x);
    setStartY(webPoint.y);
    setEndX(webPoint.x);
    setEndY(webPoint.y);
    isDraggingRef.current = true;
    setIsDragging(true);

    startPoint.current = imagePoint;
    endPoint.current = imagePoint;
  };

  const moveMouse = (event) => {
    if (!element || !isDraggingRef.current) {
      return;
    }

    const webPoint = generateWebPoint({ event, element });
    const imagePoint = generateImagePoint({ webPoint, viewer });

    setEndX(webPoint.x);
    setEndY(webPoint.y);
    endPoint.current = imagePoint;

    onDragMove({
      isDragging: isDraggingRef.current,
      startX,
      startY,
      endX: webPoint.x,
      endY: webPoint.y,
      startPoint: startPoint.current,
      endPoint: endPoint.current,
    });
  };

  const onWheel = (event) => {
    if (viewer && !isDragging && element) {
      const currentZoom = viewer.viewport.getZoom(true) || 1.0;
      const zoomByFactor = 0.25;
      const direction = event.deltaY > 0 ? -1 : 1;
      const webPoint = generateWebPoint({ event, element });
      const viewportPoint = viewer.viewport.pointFromPixel(webPoint);
      const factor = Math.max(
        currentZoom + zoomByFactor * direction,
        viewer.viewport.getMinZoom(),
      );
      viewer.viewport.zoomTo(factor, viewportPoint);
    }

    event.preventDefault();
    event.stopPropagation();
    if (event.nativeEvent) {
      event.nativeEvent.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
  };

  const onClickSvg = (event) => {
    if (!element) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    onClick({ x: startX, y: startY });
  };

  const _xMin = Math.min(startX, endX);
  const _yMin = Math.min(startY, endY);
  const _xMax = Math.max(startX, endX);
  const _yMax = Math.max(startY, endY);

  return (
    <Container ref={elementRef}>
      {active && (
        <Wrapped>
          <svg
            onMouseUp={endDrag}
            onMouseDown={startDrag}
            onMouseMove={moveMouse}
            onClick={onClickSvg}
            onWheel={onWheel}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {isDragging && (
              <rect
                id="drag_area"
                x={_xMin}
                y={_yMin}
                width={_xMax - _xMin}
                height={_yMax - _yMin}
                style={{
                  fill: 'rgba(0,0,0,.2)',
                  stroke: 'grey',
                  strokeWidth: '2px',
                  opacity: 0.5,
                }}
              />
            )}
          </svg>
        </Wrapped>
      )}
    </Container>
  );
};

DragSelection.propTypes = {
  onDragComplete: PropTypes.func.isRequired,
  onDragMove: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  viewer: PropTypes.shape({
    viewport: PropTypes.shape({
      getZoom: PropTypes.func,
      pointFromPixel: PropTypes.func,
      getMinZoom: PropTypes.func,
      zoomTo: PropTypes.func,
    }),
  }),
  active: PropTypes.bool.isRequired,
};

DragSelection.defaultProps = {
  viewer: {
    viewport: {},
  },
};

export default DragSelection;
