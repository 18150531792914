import { getLogger, setLogger } from '@serraview/engage-shared/utils';
import { getConfig, TokenManager } from '@serraview/engage-shared/api';
import { APP_NAMES } from '@serraview/engage-shared/constants';

import { LOGIN_PATH } from 'consts';
import logger from 'utils/logger';

getConfig().config = {
  appName: APP_NAMES.ENGAGE_WEB,
  appVersion: __CONF__.version,
  api: {
    onResponseError: async (error) => {
      getLogger().log(error);
      await TokenManager.removeTokens();
      window.history.push(LOGIN_PATH);
    },
  },
};

setLogger({
  log: (message) => logger.debug(message)(),
  info: (message) => logger.info(message)(),
  warn: (message) => logger.warn(message)(),
  error: (message) => logger.error(message)(),
});
