var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ReactFocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { Z_INDEX } from 'consts';
var POSITION = 40;
var Overlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  z-index: ", ";\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(3, 13, 48, 0.7);\n  backdrop-filter: blur(5px);\n"], ["\n  position: fixed;\n  z-index: ", ";\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(3, 13, 48, 0.7);\n  backdrop-filter: blur(5px);\n"])), Z_INDEX.MODAL);
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: ", "px;\n  left: ", "px;\n  right: ", "px;\n  bottom: ", "px;\n  border: 1px solid #ccc;\n  background: #fff;\n  box-shadow: 0 40px 60px rgba(10, 38, 50, 0.15);\n  border-radius: 6px;\n  outline: none;\n  ", "\n"], ["\n  position: absolute;\n  top: ", "px;\n  left: ", "px;\n  right: ", "px;\n  bottom: ", "px;\n  border: 1px solid #ccc;\n  background: #fff;\n  box-shadow: 0 40px 60px rgba(10, 38, 50, 0.15);\n  border-radius: 6px;\n  outline: none;\n  ", "\n"])), POSITION, POSITION, POSITION, POSITION, function (props) { return props.$style || {}; });
var CloseButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  width: 1px;\n  height: 1px;\n  padding: 0;\n  margin: -1px;\n  overflow: hidden;\n  clip: rect(0, 0, 0, 0);\n  white-space: nowrap; /* added line */\n  border: 0;\n"], ["\n  position: absolute;\n  width: 1px;\n  height: 1px;\n  padding: 0;\n  margin: -1px;\n  overflow: hidden;\n  clip: rect(0, 0, 0, 0);\n  white-space: nowrap; /* added line */\n  border: 0;\n"])));
export var StyledFocusLock = styled(ReactFocusLock)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export { Overlay, Content, CloseButton };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
