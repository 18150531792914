import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import FloorplanScaleContext from '../contexts/FloorplanScaleContext';
import { DEFAULT_FLOORPLAN_SCALE } from '../constants/Floorplan';
import { setAlpha } from '../utils/colorUtils';

function RadarPing({ shape: { coordinates }, pointSize, baseStyle, repeat }) {
  const { x, y } = coordinates[0];
  const value = useContext(FloorplanScaleContext);

  const maxR = Math.ceil((pointSize * DEFAULT_FLOORPLAN_SCALE) / value) * 6;
  const { fill, fillOpacity, stroke, strokeWidth } = baseStyle;
  const gradientStartStyle = {
    stopColor: setAlpha(fill, fillOpacity / 6),
    stopOpacity: 0,
  };
  const gradientStopStyle = {
    // setting this to transparent will make
    // ping animation without a background color
    // also looks OK
    stopColor: setAlpha(fill, fillOpacity),
    stopOpacity: 1,
  };

  const circleProps = {
    cx: '50%',
    cy: '50%',
    r: '0%',
    stroke,
    strokeWidth,
    fill: 'url(#grad1)',
    style: {
      animationIterationCount: parseInt(repeat, 10) || repeat,
    },
  };

  return (
    <svg
      className="RadarPing"
      expanded="true"
      height={maxR}
      width={maxR}
      x={x - maxR / 2}
      y={y - maxR / 2}
    >
      <defs>
        <radialGradient id="grad1" cx="50%" cy="50%" r="100%" fx="50%" fy="50%">
          <stop offset="0%" style={gradientStartStyle} />
          <stop offset="100%" style={gradientStopStyle} />
        </radialGradient>
      </defs>
      <circle
        className="innerCircle"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...circleProps}
      />
      <circle
        className="innerCircle2"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...circleProps}
      />
      <circle
        className="innerCircle3"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...circleProps}
      />
    </svg>
  );
}

RadarPing.propTypes = {
  shape: PropTypes.shape({
    coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    ),
  }).isRequired,
  pointSize: PropTypes.number.isRequired,
  baseStyle: PropTypes.shape({
    fill: PropTypes.string,
    fillOpacity: PropTypes.number,
    stroke: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    strokeWidth: PropTypes.string,
  }).isRequired,
  repeat: PropTypes.string,
};
RadarPing.defaultProps = {
  repeat: 'infinite',
};

export default memo(RadarPing);
