var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import logger from 'utils/logger';
import ModalButton from 'components/base/ModalButton/ModalButton';
import { Container, AlertContainer, Title, Subtitle, } from './ErrorBoundary.styled';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.onRestartClick = function () {
            window.location.href = '/';
            _this.setState({ hasFatalError: false });
        };
        _this.state = {
            hasFatalError: false,
        };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        logger.error({
            message: error.message,
            info: errorInfo.componentStack,
        });
        this.setState({ hasFatalError: true });
    };
    ErrorBoundary.prototype.renderErrorAlert = function () {
        var t = this.props.t;
        return (React.createElement(Container, { "data-testid": "errorBoundaryContainer" },
            React.createElement(AlertContainer, null,
                React.createElement(Title, null, t('components.errorBoundary.title')),
                React.createElement(Subtitle, null, t('components.errorBoundary.subtitle')),
                React.createElement(ModalButton, { "data-testid": "restart-button", text: t('components.errorBoundary.action'), onClick: this.onRestartClick }))));
    };
    ErrorBoundary.prototype.render = function () {
        var hasFatalError = this.state.hasFatalError;
        var children = this.props.children;
        return hasFatalError ? this.renderErrorAlert() : children;
    };
    return ErrorBoundary;
}(Component));
var ErrorBoundaryWithTranslation = withTranslation()(ErrorBoundary);
export { ErrorBoundaryWithTranslation as ErrorBoundary };
