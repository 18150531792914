import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'base-components';
import { noop } from 'utils';
import { fontClassesHeights, fontTagHeights } from 'theme';
import {
  Actions,
  ACTIONS_VERTICAL_MARGIN,
  BUTTON_VERTICAL_PADDING,
  CheckInMessage,
  Content,
  ContentWrapper,
  Details,
  DetailsAction,
  LIST_ITEM_PADDING_VERTICAL,
  Message,
  Title,
  Wrapper,
} from './styled';

const Notification = ({
  icon,
  title,
  localTime,
  location,
  checkInStatus,
  statusMessage,
  isRead,
  onDetails,
}) => {
  const { t } = useTranslation();
  const { color2 } = useTheme();
  const detailsStr = t('layout.notifications.reservationDetails');

  return (
    <Wrapper isRead={isRead}>
      <Content>
        <Avatar
          icon={icon}
          size="small"
          iconStyle={{ color: color2, opacity: 0.5 }}
        />
        <ContentWrapper>
          <Details>
            <Title>{title}</Title>
            <Message>{localTime}</Message>
            <Message id={`${location}-${localTime}`}>{location}</Message>
          </Details>
          <Actions>
            <DetailsAction>
              <button
                id={`${detailsStr}-${location}-${localTime}`}
                onClick={onDetails}
                aria-labelledby={`${detailsStr}-${location}-${localTime} ${location}-${localTime}`}
              >
                {detailsStr}
              </button>
            </DetailsAction>
          </Actions>
          <CheckInMessage status={checkInStatus}>
            {statusMessage}
          </CheckInMessage>
        </ContentWrapper>
      </Content>
    </Wrapper>
  );
};

Notification.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  localTime: PropTypes.string,
  location: PropTypes.string,
  checkInStatus: PropTypes.string,
  statusMessage: PropTypes.string,
  isRead: PropTypes.bool,
  onDetails: PropTypes.func,
};
Notification.defaultProps = {
  icon: null,
  title: null,
  localTime: null,
  location: null,
  checkInStatus: null,
  statusMessage: null,
  isRead: false,
  onDetails: noop,
};

export const getSize = () =>
  // list vertical padding
  LIST_ITEM_PADDING_VERTICAL * 2 +
  // title
  fontTagHeights.h6.withMargins * 2 +
  // localTime + location
  fontClassesHeights.btn.withMargins * 2 +
  // actions
  ACTIONS_VERTICAL_MARGIN +
  // details action
  BUTTON_VERTICAL_PADDING * 2 +
  fontClassesHeights.btn.withMargins +
  // status message
  ACTIONS_VERTICAL_MARGIN * 2 +
  fontClassesHeights.btn.withMargins;

export default memo(Notification);
