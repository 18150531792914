var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var deepLinkingReducer = function (state, action) {
    switch (action.type) {
        case 'setData': {
            return __assign(__assign({}, state), action.payload);
        }
        case 'clear':
            return {
                to: null,
                isChecking: false,
            };
        default:
            return state;
    }
};
export var setDeepLinkData = function (payload) { return ({
    type: 'setData',
    payload: payload,
}); };
export var clearData = function () { return ({
    type: 'clear',
}); };
