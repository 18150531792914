import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { isToday } from 'date-fns';
import { formatDate } from '@serraview/engage-shared';
import { useUser } from 'api/queries';
import { FlatList, Mask } from 'base-components';
import { useBookingModal } from 'utils/hooks';
import Filter from './Filter/Filter';
import AgendaDay from './Day/AgendaDay';
import AgendaItem from './Items/AgendaItem';
import {
  AgendaItemWrapper,
  AgendaListWrapper,
  getAgendaItemSize,
  getAgendaItemsSize,
} from './styled';
import { parseAgendaItems, getEventExtraHeight } from './utils';

const AgendaList = ({ selectedDate, agendaItems, isLoadingData }) => {
  const listRef = useRef(null);
  const { show: showBookingModal } = useBookingModal();
  const items = parseAgendaItems({ selectedDate, agendaItems });
  const isLoading = isLoadingData;

  const onSelectEvent = ({ event }) => {
    showBookingModal(event);
  };

  const renderItem = ({ index, item, style }) => {
    const { day } = item;

    return (
      <AgendaItemWrapper $style={style} data-testid={`AgendaItem-${index}`}>
        <AgendaDay day={day} />
        <AgendaItem item={item} onSelectEvent={onSelectEvent} />
      </AgendaItemWrapper>
    );
  };

  const getItemSize = (index) => {
    const { day, events } = items[index];
    const noOfEvents = events.length;
    const extraHeights = events.reduce(
      (accumulator, event) => accumulator + getEventExtraHeight(event),
      0,
    );

    const defaultSize = getAgendaItemSize();
    if (noOfEvents > 0) {
      let separatorHeight = 0;
      if (isToday(day)) {
        separatorHeight = 8;
      }
      return getAgendaItemsSize(noOfEvents, separatorHeight, extraHeights);
    }
    return defaultSize;
  };

  const scrollToSelectedDate = (list) => {
    const key = formatDate(selectedDate);
    const scrollToIndex = items.findIndex(({ day }) => formatDate(day) === key);
    list.scrollToItem(scrollToIndex, 'start');
  };

  const afterListMounts = (list) => {
    listRef.current = list;
    if (list && !isLoading) {
      // reset the index to re-render because of item's size changes
      list.resetAfterIndex(0, true);
      // scroll to today
      scrollToSelectedDate(list);
    }
  };

  // The filter is only visible by delegated employees
  const { data: user } = useUser();
  const showFilter = user?.canReserveForOthers;

  return (
    <>
      {showFilter && <Filter />}
      <AgendaListWrapper>
        <Mask isLoading={isLoading} />

        <FlatList
          listRef={afterListMounts}
          fixed={false}
          data={items}
          itemCount={items.length}
          renderItem={renderItem}
          itemSize={getItemSize}
        />
      </AgendaListWrapper>
    </>
  );
};

AgendaList.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  agendaItems: PropTypes.object,
  isLoadingData: PropTypes.bool,
};
AgendaList.defaultProps = {
  agendaItems: {},
  isLoadingData: false,
};

export default memo(AgendaList);
