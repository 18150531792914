var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSelector } from 'react-redux';
import { useBookingDetailsQuery, useBookingFetch as useBookingFetchHook, } from '@serraview/engage-shared/api';
import { tenantSelectors } from 'store';
import { useUser } from 'api/queries/useUser';
export var useBookingDetails = function (spaceId, meetingId, options) {
    if (options === void 0) { options = {}; }
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var user = useUser();
    var userData = (user === null || user === void 0 ? void 0 : user.data) || {};
    var opts = __assign(__assign({}, options), { enabled: user.isSuccess && !!spaceId && !!meetingId });
    return useBookingDetailsQuery({
        tenantId: tenantId,
        spaceId: spaceId,
        meetingId: meetingId,
        userData: userData,
        options: opts,
    });
};
export var useBookingFetch = function (_a) {
    var _b = _a.calculateCheckInStatus, calculateCheckInStatus = _b === void 0 ? false : _b;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    return useBookingFetchHook({ tenantId: tenantId, calculateCheckInStatus: calculateCheckInStatus });
};
