import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BackButton, DateCard, LocationCard } from 'base-components';
import { useSetPageTitle } from 'utils/hooks';
import { DOCUMENT_TITLE_LEVEL } from 'consts';
import { BOOKING_TYPE } from '@serraview/engage-shared/constants';
import { SpaceBookingButtonsBar } from './SpaceBookingButtonsBar';
import { TeamBookingButtonsBar } from './TeamBookingButtonsBar/TeamBookingButtonsBar';
import AttendeesList from '../AttendeesList/AttendeesList';
import { CollapsibleDeskList } from '../DeskList/CollapsibleDeskList';
import { Content, Header, Section, SectionTitle, TitleWrapper, CardWrapper, Wrapper, Description, CardTitle, } from './styled';
var BookingCard = function (_a) {
    var booking = _a.booking, attendeeId = _a.attendeeId, onBack = _a.onBack, onShowLocation = _a.onShowLocation;
    var title = booking.title, startDate = booking.startDate, endDate = booking.endDate, allDay = booking.allDay, description = booking.description, localTimeZone = booking.localTimeZone, location = booking.location, attendees = booking.attendees, canUpdate = booking.canUpdate, isInInactiveLocation = booking.isInInactiveLocation, bookingType = booking.bookingType;
    var teamBooking = booking;
    var spaceBooking = booking;
    var desk = spaceBooking.desk, meetingRoom = spaceBooking.meetingRoom, isDesk = spaceBooking.isDesk, bookedFor = spaceBooking.bookedFor;
    var deskBookings = teamBooking.deskBookings;
    var t = useTranslation().t;
    var color6 = useTheme().color6;
    var hasLimitPer15Minutes = false;
    var showDateCard = !!startDate || !!endDate || allDay;
    var hasAttendees = !isDesk && !!attendees && attendees.length > 0;
    var hasReservee = (bookedFor === null || bookedFor === void 0 ? void 0 : bookedFor.length) > 0;
    useSetPageTitle(t('documentTitles.bookingDetails'), DOCUMENT_TITLE_LEVEL.MODAL);
    return (React.createElement(Wrapper, null,
        React.createElement(Header, null,
            React.createElement(BackButton, { onClick: onBack }),
            React.createElement(TitleWrapper, null,
                !!title && React.createElement(CardTitle, null, title),
                showDateCard && (React.createElement(CardWrapper, null,
                    React.createElement(DateCard, { dateStart: startDate, dateEnd: endDate, allDay: allDay, localTimeZone: localTimeZone, color: color6, hasLimitPer15Minutes: hasLimitPer15Minutes }))))),
        React.createElement(Content, null,
            React.createElement(Section, null,
                React.createElement(LocationCard, { isDesk: isDesk, location: location, desk: desk, meetingRoom: meetingRoom, onShowLocation: onShowLocation, disableButton: isInInactiveLocation }),
                canUpdate &&
                    (bookingType === BOOKING_TYPE.TEAM ? (React.createElement(TeamBookingButtonsBar, { booking: teamBooking, onBack: onBack, attendeeId: attendeeId, onShowLocation: onShowLocation })) : (React.createElement(SpaceBookingButtonsBar, { booking: spaceBooking })))),
            React.createElement(Section, null,
                React.createElement(SectionTitle, null, isDesk
                    ? t('layout.bookingCard.deskType')
                    : t('layout.bookingCard.meetingDetails')),
                !!description && React.createElement(Description, null, description)),
            (hasAttendees || hasReservee) && (React.createElement(Section, { id: "BookingCard_attendee_id" },
                React.createElement(SectionTitle, null, hasAttendees
                    ? t('layout.bookingCard.attendees')
                    : t('layout.bookingCard.bookedFor')),
                React.createElement(AttendeesList, { isDesk: isDesk, attendees: hasAttendees ? attendees : bookedFor }))),
            deskBookings ? React.createElement(CollapsibleDeskList, { desks: deskBookings }) : null)));
};
export default memo(BookingCard);
