var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getDayStartTime, getUtcIsoString, mapLocationBuildingData, mapLocationFloorData, } from '@serraview/engage-shared/utils';
import logger from 'log';
import { addDays, addWeeks, isAfter, isBefore, isToday, subWeeks, } from 'date-fns';
import { useFetchUser, useNotificationEvents, useSpaceFetch, useUser, } from 'api/queries';
import { useConfig } from 'utils/hooks/useConfig';
import { tenantActions } from 'store';
var getReservationsForTimeframe = function (_a) {
    var events = _a.events, startDate = _a.startDate, endDate = _a.endDate;
    return events.filter(function (event) {
        var start = event.start;
        var _start = getDayStartTime({ date: new Date(start) });
        return isAfter(_start, startDate) && isBefore(_start, endDate);
    });
};
var getTodayReservations = function (_a) {
    var events = _a.events;
    return events.filter(function (event) {
        var start = event.start;
        var _start = getDayStartTime({ date: new Date(start) });
        return isToday(_start);
    });
};
export function useSelectFloor() {
    var _this = this;
    var dispatch = useDispatch();
    var now = getDayStartTime({ date: new Date() });
    var futureBookingsLimit = useConfig().futureBookingsLimit;
    var eventsStartTimeFilter = subWeeks(now, futureBookingsLimit);
    var eventsEndTimeFilter = addWeeks(now, futureBookingsLimit);
    var fetchUserQuery = useFetchUser().fetchUserQuery;
    var fetchSpaceQuery = useSpaceFetch().fetchSpaceQuery;
    var _a = useUser(), user = _a.data, isUserFetched = _a.isFetched;
    var _b = 
    // @ts-ignore
    useNotificationEvents({
        startTime: getUtcIsoString(eventsStartTimeFilter),
        endTime: getUtcIsoString(eventsEndTimeFilter),
    }), events = _b.data, isEventsQueryFetched = _b.isFetched;
    var isReadyForCheck = isUserFetched && isEventsQueryFetched;
    var getEventLocation = useCallback(function (event) { return __awaiter(_this, void 0, void 0, function () {
        var spaceId, building, _a, floorId, floorName, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    spaceId = event.spaceId, building = event.building;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchSpaceQuery({ id: spaceId })];
                case 2:
                    _a = _b.sent(), floorId = _a.floorId, floorName = _a.floorName;
                    return [2 /*return*/, {
                            floor: floorId
                                ? mapLocationFloorData({ id: floorId, name: floorName })
                                : null,
                            building: building ? mapLocationBuildingData(building) : null,
                        }];
                case 3:
                    e_1 = _b.sent();
                    logger.error(e_1)();
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [fetchSpaceQuery]);
    return useCallback(function (callback) { return __awaiter(_this, void 0, void 0, function () {
        var todayReservations, next7DaysReservations, nextFutureBookingsLimitDaysReservations, pastFutureBookingsLimitDaysReservations, hasReservationToday, hasReservationWithinNext7Days, hasReservationWithinNext30Days, hasReservationWithinPast30Days, hasAssignedLocation, eventLocation, userLocation;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!isReadyForCheck) {
                        return [2 /*return*/];
                    }
                    if (!((events === null || events === void 0 ? void 0 : events.length) && user)) return [3 /*break*/, 11];
                    todayReservations = getTodayReservations({ events: events });
                    next7DaysReservations = getReservationsForTimeframe({
                        events: events,
                        startDate: now,
                        endDate: addDays(now, 7),
                    });
                    nextFutureBookingsLimitDaysReservations = getReservationsForTimeframe({
                        events: events,
                        startDate: now,
                        endDate: addWeeks(now, futureBookingsLimit),
                    });
                    pastFutureBookingsLimitDaysReservations = getReservationsForTimeframe({
                        events: events,
                        startDate: subWeeks(now, futureBookingsLimit),
                        endDate: now,
                    });
                    hasReservationToday = !!todayReservations.length;
                    hasReservationWithinNext7Days = !!next7DaysReservations.length;
                    hasReservationWithinNext30Days = !!nextFutureBookingsLimitDaysReservations.length;
                    hasReservationWithinPast30Days = !!pastFutureBookingsLimitDaysReservations.length;
                    hasAssignedLocation = !!((_a = user === null || user === void 0 ? void 0 : user.location) === null || _a === void 0 ? void 0 : _a.length);
                    eventLocation = void 0;
                    if (!hasReservationToday) return [3 /*break*/, 2];
                    return [4 /*yield*/, getEventLocation(todayReservations[0])];
                case 1:
                    eventLocation = _b.sent();
                    return [3 /*break*/, 10];
                case 2:
                    if (!hasReservationWithinNext7Days) return [3 /*break*/, 4];
                    return [4 /*yield*/, getEventLocation(next7DaysReservations[0])];
                case 3:
                    eventLocation = _b.sent();
                    return [3 /*break*/, 10];
                case 4:
                    if (!hasAssignedLocation) return [3 /*break*/, 6];
                    return [4 /*yield*/, fetchUserQuery({
                            // @ts-ignore
                            userId: user.userId,
                        })];
                case 5:
                    userLocation = (_b.sent()).userLocation;
                    eventLocation = userLocation;
                    return [3 /*break*/, 10];
                case 6:
                    if (!hasReservationWithinNext30Days) return [3 /*break*/, 8];
                    return [4 /*yield*/, getEventLocation(nextFutureBookingsLimitDaysReservations[0])];
                case 7:
                    eventLocation = _b.sent();
                    return [3 /*break*/, 10];
                case 8:
                    if (!hasReservationWithinPast30Days) return [3 /*break*/, 10];
                    return [4 /*yield*/, getEventLocation(pastFutureBookingsLimitDaysReservations[pastFutureBookingsLimitDaysReservations.length - 1])];
                case 9:
                    eventLocation = _b.sent();
                    _b.label = 10;
                case 10:
                    if (eventLocation) {
                        dispatch(tenantActions.setCurrentLocation(eventLocation));
                    }
                    _b.label = 11;
                case 11:
                    callback === null || callback === void 0 ? void 0 : callback();
                    return [2 /*return*/];
            }
        });
    }); }, [
        events,
        getEventLocation,
        user,
        now,
        futureBookingsLimit,
        dispatch,
        fetchUserQuery,
        isReadyForCheck,
    ]);
}
