var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])));
export var ListWrapper = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-height: 50%;\n  border-radius: 8px;\n  width: 40%;\n  transition: max-height 0.25s ease-out;\n"], ["\n  max-height: 50%;\n  border-radius: 8px;\n  width: 40%;\n  transition: max-height 0.25s ease-out;\n"])));
export var Header = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 24px;\n  margin-bottom: 10px;\n  color: ", ";\n"], ["\n  font-size: 24px;\n  margin-bottom: 10px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
var templateObject_1, templateObject_2, templateObject_3;
