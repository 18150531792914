import { useTimeOptions } from 'components/base/DateTime/TimePicker/hooks';
import { getTimePickerValues } from 'components/base/DateTime/TimePicker/utils';
import { useLocalTimeFormatH12 } from 'utils';
export var usePickerTimeOptions = function (_a) {
    var date = _a.date, timeZone = _a.timeZone, minTime = _a.minTime;
    var isTimeFormatH12 = useLocalTimeFormatH12();
    var roundedStartDate = getTimePickerValues({
        date: date,
        timeZone: timeZone,
        isTimeFormatH12: isTimeFormatH12,
    }).roundedDate;
    return useTimeOptions({
        selectedTime: roundedStartDate,
        minTime: minTime,
        isTimeFormatH12: isTimeFormatH12,
    });
};
