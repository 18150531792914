import React from 'react';
import { getUtcIsoString } from '@serraview/engage-shared';
import { useTranslation } from 'react-i18next';
import { useAvailability } from 'api/queries/useAvailability';
import { useTheme } from 'styled-components';
import { Icon } from '../../Icons/Icon';
import { InfoTextWrap, InfoText } from './styled';
import AvailabilityInfoSkeleton from './AvailabilityInfoSkeleton';
var AvailabilityInfo = function (_a) {
    var dateStart = _a.dateStart;
    var availabilityQuery = useAvailability({
        startTime: getUtcIsoString(dateStart),
    });
    var typo2 = useTheme().typo2;
    var t = useTranslation().t;
    var reservationsLeft = t('scenes.dateTimePicker.reservationsLeft');
    var _b = availabilityQuery.data || {
        existingReservations: 0,
        allowedReservations: 0,
    }, existingReservations = _b.existingReservations, allowedReservations = _b.allowedReservations;
    var availableReservations = allowedReservations - existingReservations > 0
        ? allowedReservations - existingReservations
        : 0;
    var renderAvailabilityText = function () {
        if (availabilityQuery.isSuccess) {
            return (React.createElement(React.Fragment, null, allowedReservations > 0 && (React.createElement(InfoTextWrap, null,
                React.createElement(Icon, { name: "question", size: 26, style: { color: typo2 } }),
                React.createElement(InfoText, null,
                    availableReservations,
                    " ",
                    reservationsLeft)))));
        }
        if (availabilityQuery.isLoading) {
            return React.createElement(AvailabilityInfoSkeleton, null);
        }
        return null;
    };
    return renderAvailabilityText();
};
export default AvailabilityInfo;
