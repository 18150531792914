var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var PADDING_USER_PROFILE = 30;
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #ffffff;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  background-color: #ffffff;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
export var Actions = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding: ", "px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding: ", "px;\n"])), PADDING_USER_PROFILE);
export var NotificationsWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  padding: ", "px 0;\n  background-color: ", ";\n"], ["\n  display: flex;\n  height: 100%;\n  padding: ", "px 0;\n  background-color: ", ";\n"])), 2, function (_a) {
    var theme = _a.theme;
    return theme.color4;
});
export var Logout = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: ", ";\n  opacity: 0.7;\n\n  & .text {\n    margin-left: 10px;\n    font-size: 12px;\n    line-height: 16px;\n  }\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n    border-radius: 50px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: ", ";\n  opacity: 0.7;\n\n  & .text {\n    margin-left: 10px;\n    font-size: 12px;\n    line-height: 16px;\n  }\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n    border-radius: 50px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var Settings = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  border-radius: 50px;\n  background-color: ", ";\n\n  & button {\n    cursor: pointer;\n\n    :first-child {\n      padding: 12px 28px;\n      font-size: 12px;\n      line-height: 16px;\n      color: ", ";\n    }\n\n    :focus-visible {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n      border-radius: 50px;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  border-radius: 50px;\n  background-color: ", ";\n\n  & button {\n    cursor: pointer;\n\n    :first-child {\n      padding: 12px 28px;\n      font-size: 12px;\n      line-height: 16px;\n      color: ", ";\n    }\n\n    :focus-visible {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n      border-radius: 50px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color4;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
