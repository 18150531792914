import { userConfigActions } from 'store';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getLogger } from '@serraview/engage-shared/utils';

export function useSetAppLanguage(i18n) {
  const dispatch = useDispatch();
  return useCallback(
    async (language) => {
      try {
        await i18n.changeLanguage(language);
        dispatch(userConfigActions.setUiLanguage(language));
        document.documentElement.lang = language;
      } catch (error) {
        getLogger().error(error);
      }
    },
    [i18n, dispatch],
  );
}
