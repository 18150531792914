var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { DOCUMENT_TITLE_LEVEL } from 'consts';
import { useSetPageTitle } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { testProps } from 'utils';
import { Icon } from '../Icons/Icon';
import { Wrapper, Message, Title, Button, IconButton, CloseButton, } from './styled';
var FullScreenInfo = function (_a) {
    var title = _a.title, message = _a.message, onClose = _a.onClose, iconName = _a.iconName, iconButtonAriaLabelKey = _a.iconButtonAriaLabelKey, buttonTitle = _a.buttonTitle, onPressButton = _a.onPressButton, iconComponent = _a.iconComponent, _b = _a.pageTitle, pageTitle = _b === void 0 ? '' : _b;
    var t = useTranslation().t;
    useSetPageTitle(pageTitle, DOCUMENT_TITLE_LEVEL.MODAL);
    var a11yAndTestProps = iconButtonAriaLabelKey
        ? testProps(t, iconButtonAriaLabelKey).props
        : {};
    return (React.createElement(Wrapper, { onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(CloseButton, { onClick: onClose },
            React.createElement(Icon, { name: "close", size: 14 })),
        React.createElement(IconButton, __assign({ onClick: onClose }, a11yAndTestProps),
            !!iconName && React.createElement(Icon, { name: iconName, size: 120 }),
            iconComponent),
        React.createElement(Title, { "data-testid": "full-screen-info-title" }, title),
        !!message && (React.createElement(Message, { "data-testid": "full-screen-info-message" }, message)),
        !!buttonTitle && (React.createElement(Button, { onClick: onPressButton, "data-testid": "full-screen-info-button" }, buttonTitle))));
};
FullScreenInfo.defaultProps = {
    message: '',
    iconName: '',
    onClose: null,
    iconButtonAriaLabelKey: '',
    iconComponent: null,
    buttonTitle: '',
    onPressButton: null,
    pageTitle: '',
};
export default memo(FullScreenInfo);
