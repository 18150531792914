export const getDataFromSpaces = (spaces, config) =>
  spaces.reduce((accumulator, space) => {
    if (space.shape) {
      const { coordinates: [coordinate] = [] } = Array.isArray(space.shape)
        ? space.shape[0] ?? {}
        : space.shape;

      if (coordinate) {
        accumulator.push({
          ...coordinate,
          ...config,
        });
      }
    }

    return accumulator;
  }, []);
