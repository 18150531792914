var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useTeamMembers as useTeamMembersQuery } from '@serraview/engage-shared/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useFavouritePeople } from 'api/queries/useFavouritePeople';
import { PATH_SEGMENT } from 'consts';
export var useTeamMembers = function (_a) {
    var teamId = _a.teamId, searchString = _a.searchString;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var isFavPeopleTeam = teamId === PATH_SEGMENT.FAV_PEOPLE_TEAM;
    var _b = useTeamMembersQuery({
        tenantId: tenantId,
        // make id undefined and this query will be disabled
        id: isFavPeopleTeam ? undefined : teamId,
        searchString: searchString,
    }), teamMembers = _b.data, teamMembersQueryLoading = _b.isLoading;
    var _c = useFavouritePeople({
        enabled: isFavPeopleTeam,
    }), people = _c.people, favPeopleIsLoading = _c.isLoading;
    if (!isFavPeopleTeam) {
        return { data: teamMembers, isLoading: teamMembersQueryLoading };
    }
    var data = (people === null || people === void 0 ? void 0 : people.map(function (person) { return (__assign(__assign({}, person), { displayName: person.name })); })) || [];
    if (searchString) {
        data = data.filter(function (item) {
            return item.displayName.toLowerCase().includes(searchString.toLowerCase());
        });
    }
    return { data: data, isLoading: favPeopleIsLoading };
};
