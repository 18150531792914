import { addDays, getDaysInMonth, setDate } from 'date-fns';
import { formatDate } from '@serraview/engage-shared';
import { LIST_ITEM_EXTRA_HEIGHT } from 'layouts/Agenda/content/styled';
import moment from 'moment-timezone';

const getAgendaItemsForDay = (day, data) => {
  const item = data[formatDate(day)];
  if (item && item.length) {
    return item.reduce(
      (acc, v) => {
        acc.events.push(v);
        return acc;
      },
      {
        events: [],
        day,
      },
    );
  }
  return { events: [], day };
};

const parseAgendaItems = ({ selectedDate, agendaItems }) => {
  const days = getDaysInMonth(selectedDate);
  let iterator = setDate(selectedDate, 1);
  let items = [];
  for (let i = 0; i < days; i++) {
    const item = getAgendaItemsForDay(iterator, agendaItems);
    if (item) {
      items = items.concat(item);
    }
    iterator = addDays(iterator, 1);
  }
  return items;
};

const getEventExtraHeight = (event) => {
  const { isBookedForOther, isAllDayBooking, timeZone } = event;
  const localTimeZoneString = moment.tz.guess(true);

  const isLocalTimeSameAsBuilding = localTimeZoneString === timeZone;

  let extraHeight = isBookedForOther ? LIST_ITEM_EXTRA_HEIGHT : 0;
  if (!isAllDayBooking && !isLocalTimeSameAsBuilding) {
    // when Cancel button is displayed, the local time is on two lines (one with timezone string)
    extraHeight += LIST_ITEM_EXTRA_HEIGHT * 2;
  }

  return extraHeight;
};

export { parseAgendaItems, getEventExtraHeight };
