import React from 'react';
import { SkeletonTheme } from 'base-components';
import Skeleton from 'react-loading-skeleton';
import LocationCardSkeleton from 'components/base/LocationCard/LocationCardSkeleton';
import AttendeeSkeleton from 'components/layouts//Booking/AttendeesList/Attendee/AttendeeSkeleton';
import { Content, Header, LineWrapper, Section, Wrapper } from './styled';
var BookingCardSkeleton = function () { return (React.createElement(SkeletonTheme, { style: { width: '100%' } },
    React.createElement(Wrapper, { "data-testid": "booking-card-skeleton" },
        React.createElement(Header, null,
            React.createElement("h2", null,
                React.createElement(Skeleton, { width: 200 })),
            React.createElement("h5", null,
                React.createElement(Skeleton, { width: 200 }))),
        React.createElement(Content, null,
            React.createElement(Section, null,
                React.createElement(LocationCardSkeleton, null),
                React.createElement(LineWrapper, null,
                    React.createElement(Skeleton, { circle: true, height: 36, width: 36 }),
                    React.createElement(Skeleton, { height: 36, width: 90 }),
                    React.createElement(Skeleton, { height: 36, width: 90 }))),
            React.createElement(Section, null,
                React.createElement("h6", null,
                    React.createElement(Skeleton, { width: 200 })),
                React.createElement(Skeleton, { width: "90%", height: 80 })),
            React.createElement(Section, null,
                React.createElement("h6", null,
                    React.createElement(Skeleton, { width: 200 }))),
            React.createElement(AttendeeSkeleton, null))))); };
export default BookingCardSkeleton;
