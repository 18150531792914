import { useEffect } from 'react';
import { focusElementById } from '../helpers';
export var FocusId;
(function (FocusId) {
    FocusId["GlobalSearchFilterButton"] = "global-search-filter-button";
    FocusId["DrawerCloseButton"] = "drawer-close-button";
    FocusId["DrawerMenuButton"] = "drawer-menu-button";
    FocusId["TimelineButton"] = "timeline-button";
    FocusId["TimelineSelectButton"] = "modal-date-time-picker-select-btn";
    FocusId["DurationSelectorHourUpButton"] = "duration-selector-hour-up-button";
    FocusId["TeamDeskReserve"] = "team-desk-reserve";
    FocusId["TeamBookingAccept"] = "team-booking-accept";
    FocusId["TeamBookingDecline"] = "team-booking-decline";
    FocusId["TeamBookingDelete"] = "team-booking-delete";
    FocusId["BookingDeleteButton"] = "booking-delete-button";
    FocusId["BookingCheckInButton"] = "booking-check-in-button";
    FocusId["SpaceAvailability"] = "space-availability";
    FocusId["SpaceAttributes"] = "space-attributes";
    FocusId["SearchFiltersApplyButton"] = "search-filters-apply";
    FocusId["ReserveSelectedDatesButton"] = "reserve-selected-dates";
    FocusId["AgendaButton"] = "agenda-button";
    FocusId["FloorplanViewsButton"] = "floorplan-views-button";
    FocusId["UserProfileButton"] = "user-profile";
    FocusId["UserSettingsButton"] = "user-settings";
})(FocusId || (FocusId = {}));
export var useFocus = function (_a) {
    var focusOn = _a.focusOn, returnFocusTo = _a.returnFocusTo;
    useEffect(function () {
        if (focusOn) {
            focusElementById(focusOn);
        }
        return function () {
            if (!returnFocusTo)
                return;
            focusElementById(returnFocusTo);
        };
    }, [focusOn, returnFocusTo]);
};
