var _a;
import React, { memo, useState } from 'react';
import { useSpaceTypeAttributes, useSpaceTypes } from 'api/queries';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, filtersSelectors } from 'store';
import { useRestrictTo } from 'utils/hooks';
import ItemsList from './FilterList/FilterList';
import FilterSelector from './FilterSelector/FilterSelector';
import { getFilterDefaultType, toggleFilterItem } from './utils';
export var queryMap = (_a = {},
    _a["spaceTypes" /* SPACE_ASSETS_FILTER.TYPE */] = useSpaceTypes,
    _a["spaceAttributes" /* SPACE_ASSETS_FILTER.ATTRIBUTE */] = useSpaceTypeAttributes,
    _a);
var TypesAndAttributesFilter = function (_a) {
    var _b = _a.isDraft, isDraft = _b === void 0 ? false : _b;
    var restrictTo = useRestrictTo();
    var filter = useSelector(isDraft ? filtersSelectors.getDraftFilter : filtersSelectors.getFilter);
    var _c = useState(getFilterDefaultType(filter)), filterType = _c[0], setFilterType = _c[1];
    var dispatch = useDispatch();
    // @ts-ignore
    var activeFilter = filter[filterType];
    var toggleItem = function (_a) {
        var _b;
        var checked = _a.checked, id = _a.id;
        dispatch(filtersActions.setFilterValues((_b = {
                isDraft: isDraft
            },
            // @ts-ignore
            _b[filterType] = toggleFilterItem({
                id: id,
                checked: checked,
                selected: activeFilter,
            }),
            _b)));
    };
    if (!restrictTo)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterSelector, { selectedFilterType: filterType, spaceType: restrictTo, setFilterType: setFilterType }),
        filterType ? (React.createElement(ItemsList, { query: queryMap[filterType], selected: activeFilter, onToggle: toggleItem, spaceType: restrictTo, filterType: filterType })) : null));
};
export default memo(TypesAndAttributesFilter);
