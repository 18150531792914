import { SPACE_ASSETS_FILTER } from 'consts';

export const getFilterDefaultType = (filter) => {
  if (filter[SPACE_ASSETS_FILTER.TYPE].length > 0)
    return SPACE_ASSETS_FILTER.TYPE;
  if (filter[SPACE_ASSETS_FILTER.ATTRIBUTE].length > 0)
    return SPACE_ASSETS_FILTER.ATTRIBUTE;
  return null;
};

export const toggleFilterItem = ({ id, checked, selected }) =>
  checked
    ? [...selected, id]
    : selected.filter((selectedId) => selectedId !== id);
