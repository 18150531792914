import { useModal } from 'utils/hooks/useModal';
import { useDispatch } from 'react-redux';
import { navigationActions } from 'store';
import { MODALS } from 'consts';

export function useImageViewer() {
  const { content, openModal } = useModal();
  const dispatch = useDispatch();

  const openImageViewer = (_content) => {
    openModal(MODALS.IMAGE_VIEWER, _content);
  };

  const closeImageViewer = (event) => {
    event.stopPropagation();
    dispatch(navigationActions.clearModal());
  };

  return {
    content,
    closeImageViewer,
    openImageViewer,
  };
}
