import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
import { tenantSelectors } from 'store';
import { RESTRICT_TO_SEARCH_FILTER_ITEMS } from 'consts';
import { useRestrictTo, useSetPageTitle } from 'utils/hooks';
import { RestrictToSearchFilterWrapper } from './styled';
import { RestrictToLink } from './RestrictToLink';
var RestrictToSearchFilter = function () {
    var hidePeopleData = useSelector(tenantSelectors.getKioskConfig).hidePeopleData;
    var restrictTo = useRestrictTo();
    var t = useTranslation().t;
    useSetPageTitle(restrictTo ? t(RESTRICT_TO_SEARCH_FILTER_ITEMS[restrictTo].text) : '');
    var filterItems = Object.keys(RESTRICT_TO_SEARCH_FILTER_ITEMS);
    return (React.createElement(RestrictToSearchFilterWrapper, { hidePeopleData: hidePeopleData }, filterItems.map(function (key) {
        if (hidePeopleData &&
            (key === RESTRICT_TO_VALUES.PERSON || key === RESTRICT_TO_VALUES.TEAM))
            return null;
        return (React.createElement(RestrictToLink, { key: "".concat(key, "-filter-item"), restrictTo: restrictTo, linkRestrictTo: key }));
    })));
};
export default RestrictToSearchFilter;
