import React from 'react';
import { SkeletonTheme } from 'base-components';
import Skeleton from 'react-loading-skeleton';
import { RegionWrapper } from './styled';

const RegionSkeleton = () => (
  <SkeletonTheme style={{ width: '100%' }}>
    <RegionWrapper data-testid="region-skeleton">
      <Skeleton height={62} />
    </RegionWrapper>
  </SkeletonTheme>
);

export default RegionSkeleton;
