import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import { MODALS } from 'consts';
import { getIsVisible, useModal } from 'utils';

export function useShowSocialDistancingInfo() {
  const { openModal, layout } = useModal();
  const showSocialDistancingInfo = useSelector(
    userConfigSelectors.getShowSocialDistancingInfo,
  );

  useEffect(() => {
    if (
      getIsVisible(showSocialDistancingInfo) &&
      layout !== MODALS.SOCIAL_DISTANCING
    ) {
      openModal(MODALS.SOCIAL_DISTANCING);
    }
  }, [showSocialDistancingInfo, openModal, layout]);
}
