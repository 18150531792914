import { getActionFromEngageUrl, MENU_ACTION_TYPES, } from '@serraview/engage-shared';
import { getYellowboxUrl } from '@serraview/engage-shared/utils';
import { isElectron } from 'utils/electron';
import { goToAgendaList, goToHeatmaps, goToKeyboardDocs, goToPersonOfInterest, goToWayfinder, openExternalURLInApp, openMailAgent, } from './conciergeMenuItemClickHandlers';
/**
 * Parses `url` param to retrieve an onClick handler function for concierge menu item.
 * There are 2 scenarios depending on the value of the `url` parameter:
 * 1. `url` is nullable – early exit is performed and null is returned.
 * 2. `url` is an external link – `openExternalURLInApp` function is returned.
 * 3. `url` is an engage type link (e.g. `engage://bookings`) – dedicated function (or undefined) from `./conciergeMenuItemClickHandlers` is returned.
 */
export var getMenuItemClickHandler = function (_a) {
    var _b;
    var url = _a.url, openWithExternalBrowser = _a.openWithExternalBrowser, dispatch = _a.dispatch, navigate = _a.navigate, currentFloorId = _a.currentFloorId, email = _a.email, heatmapKiosk = _a.heatmapKiosk;
    if (!url)
        return undefined;
    var actionAndPaths = getActionFromEngageUrl(url);
    var engageURLMap = (_b = {},
        _b[MENU_ACTION_TYPES.BROWSER] = function () {
            return openExternalURLInApp(url, navigate, openWithExternalBrowser);
        },
        _b[MENU_ACTION_TYPES.YELLOWBOX] = function () {
            var yellowboxUrl = getYellowboxUrl(email);
            openExternalURLInApp(yellowboxUrl, navigate, openWithExternalBrowser);
        },
        _b[MENU_ACTION_TYPES.WAYFINDER] = function () {
            return heatmapKiosk && isElectron()
                ? goToHeatmaps({ navigate: navigate, dispatch: dispatch, currentFloorId: currentFloorId })
                : goToWayfinder({ navigate: navigate, dispatch: dispatch, currentFloorId: currentFloorId });
        },
        _b[MENU_ACTION_TYPES.BOOKINGS] = function () {
            return goToAgendaList({ dispatch: dispatch, navigate: navigate, currentFloorId: currentFloorId });
        },
        _b[MENU_ACTION_TYPES.PERSON_OF_INTEREST] = function () {
            return goToPersonOfInterest({
                dispatch: dispatch,
                navigate: navigate,
                currentFloorId: currentFloorId,
                action: actionAndPaths,
            });
        },
        _b[MENU_ACTION_TYPES.FIRST_RESPONDERS] = function () {
            return goToPersonOfInterest({
                dispatch: dispatch,
                navigate: navigate,
                currentFloorId: currentFloorId,
                action: actionAndPaths,
            });
        },
        _b[MENU_ACTION_TYPES.MAIL_TO] = function () { return openMailAgent(url); },
        _b[MENU_ACTION_TYPES.KEYBOARD_DOCS] = function () {
            return goToKeyboardDocs({ navigate: navigate, dispatch: dispatch });
        },
        _b[MENU_ACTION_TYPES.HEATMAP] = function () {
            return goToHeatmaps({ navigate: navigate, dispatch: dispatch, currentFloorId: currentFloorId });
        },
        _b);
    return engageURLMap[actionAndPaths.action];
};
