import { arrayOf, number, object, shape, string } from 'prop-types';

const Label = shape({
  id: string.isRequired,
  coordinates: shape({
    x: number.isRequired,
    y: number.isRequired,
  }).isRequired,
  lines: arrayOf(
    shape({
      text: string.isRequired,
      style: object,
    }),
  ).isRequired,
});

export default Label;
