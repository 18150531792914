import { shape, bool, number } from 'prop-types';

// OpenSeadragon Image Viewer Options:
// https://openseadragon.github.io/docs/OpenSeadragon.html#.Options
const ViewerOptions = shape({
  showNavigator: bool,
  showNavigationControl: bool,
  autoHideControls: bool,
  constrainDuringPan: bool,
  immediateRender: bool,
  preserveImageSizeOnResize: bool,
  autoResize: bool,
  debugMode: bool,
  zoomPerClick: number,
  visibilityRatio: number,
  minPixelRatio: number,
  maxZoomPixelRatio: number,
  zoomPerScroll: number,
  springStiffness: number,
  animationTime: number,
  minZoomImageRatio: number,
  clickDistThreshold: number,
});

export default ViewerOptions;
