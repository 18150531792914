import { useSelector } from 'react-redux';
import { teamReservationsSelectors } from 'store';
import { TEAM_RESERVATION_DESKS_SELECTION_MODE } from 'store/teamReservations';
/**
 * Check if team reservation desk selection mode is on.
 * @returns Flag indicating if desk selection mode is active.
 */
export function useIsDeskSelection() {
    var selectionMode = useSelector(teamReservationsSelectors.getTeamReservationDesksSelectionMode);
    return selectionMode === TEAM_RESERVATION_DESKS_SELECTION_MODE.PAINT;
}
