var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { testProps } from 'utils';
import { Icon } from '../Icons/Icon';
import { DetailsContainer, DetailsText } from './styled';
var Capacity = function (_a) {
    var capacity = _a.capacity, _b = _a.iconSize, iconSize = _b === void 0 ? 14 : _b, _c = _a.fontSize, fontSize = _c === void 0 ? 14 : _c, _d = _a.opacity, opacity = _d === void 0 ? 1 : _d;
    var t = useTranslation().t;
    var typo2 = useTheme().typo2;
    var a11yTestProps = testProps(t, 'accessibilityLabels.spaceCapacity', function (_a) {
        var spaceCapacity = _a[0];
        return "".concat(spaceCapacity, " ").concat(capacity);
    }).props;
    return (React.createElement(DetailsContainer, __assign({ role: "img" }, a11yTestProps),
        React.createElement(Icon, { name: "person-fill", size: iconSize, style: { color: typo2, opacity: opacity } }),
        React.createElement(DetailsText, { "data-testid": "capacity", "$fontSize": fontSize, "$opacity": opacity }, capacity)));
};
export default Capacity;
