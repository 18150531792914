import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  affectedTenants: ['intuitivesurgical', 'intuitivesurgical.uat'],
  lastActiveAt: Date.now(),
};

export const sessionTimeout = createSlice({
  name: 'sessionTimeout',
  initialState,
  reducers: {
    setLastActiveAt(state, { payload }) {
      state.lastActiveAt = payload;
    },
  },
});

export const sessionTimeoutSelectors = {
  getAffectedTenants: (state) => state.sessionTimeout.affectedTenants,
  getLastActiveAt: (state) => state.sessionTimeout.lastActiveAt,
};
