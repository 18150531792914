/**
 * Main entry point of the app
 * Apply global wrappers here
 */
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { GlobalStyles, ThemeProvider } from 'styles';
import store, { persistor } from 'store';
import { Routing } from 'router';
import { QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { initializeSentry } from 'utils/analytics/sentry';
import { NotificationController } from 'layouts';
import smoothScroll from 'smoothscroll-polyfill';
import queryClient from 'utils/config/queryConfig';
import 'utils/config/engageConfig';
import { TokenManager } from '@serraview/engage-shared/api';
import { Dialog, DialogProvider, IconsSprite } from 'components/base';
import { SessionTimeoutMiddleware } from 'components/middlewares/SessionTimeoutMiddleware';
import { DeepLinkChecker } from 'router/components/DeepLinkChecker/DeepLinkChecker';
// polyfill for Safari focus-visible support
import 'focus-visible/dist/focus-visible.min';
import { isElectron } from 'utils/electron';
import { ErrorBoundary } from 'components/base/ErrorBoundary';
import { KioskConfigLoader } from 'components/kiosk';
import * as storage from './utils/config/tokenConfig';
// polyfill for .scroll functionality which are not implemented in MS Edge v < 79
smoothScroll.polyfill();
initializeSentry();
var Router = isElectron() ? HashRouter : BrowserRouter;
function App() {
    React.useEffect(function () {
        TokenManager.config({
            storage: storage,
            reduxStore: store,
        });
    }, []);
    return (React.createElement(ReduxProvider, { store: store },
        React.createElement(PersistGate, { persistor: persistor },
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(NotificationController, null,
                    React.createElement(GlobalStyles, null),
                    React.createElement(ThemeProvider, null,
                        React.createElement(ErrorBoundary, null,
                            React.createElement(Router, null,
                                React.createElement(SessionTimeoutMiddleware, null,
                                    React.createElement(DeepLinkChecker, null,
                                        React.createElement(DialogProvider, null,
                                            React.createElement(Routing, null),
                                            React.createElement(Dialog, null),
                                            isElectron() && React.createElement(KioskConfigLoader, null))))))),
                    React.createElement(ReactQueryDevtools, { initialIsOpen: false })))),
        React.createElement(IconsSprite, null)));
}
export default App;
