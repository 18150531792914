var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { floorplanActions, floorplanSelectors, teamReservationsSelectors, tenantSelectors, } from 'store';
import { FocusId, useDialog } from 'utils/hooks';
import { ConfirmationDialog } from '../../components/base/ConfirmationDialog/ConfirmationDialog';
/**
 *  Notify user when there is no space available on the floorplan.
 */
var useSpaceAvailabilityNotification = function () {
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var openDialog = useDialog().openDialog;
    var floorId = useSelector(tenantSelectors.getCurrentFloorId);
    var floorplanLoaded = useSelector(floorplanSelectors.getIsLoaded);
    var floorplanSpaceDataLoaded = useSelector(floorplanSelectors.getIsSpaceDataLoaded);
    var floorplanSpace = useSelector(floorplanSelectors.getFloorplanComponent);
    var showSpaceAvailabilityMessage = useSelector(floorplanSelectors.getShowSpaceAvailabilityMessage);
    var isInDeskCheckInMode = useSelector(teamReservationsSelectors.getIsInDeskCheckInMode);
    var hasAvailableSpaces = useCallback(function () {
        var _a;
        if (floorplanSpace &&
            floorplanSpace[floorId] &&
            ((_a = floorplanSpace[floorId]) === null || _a === void 0 ? void 0 : _a.spaces)) {
            var floorplanFloorSpaces = floorplanSpace[floorId].spaces;
            return floorplanFloorSpaces.some(function (space) { return space.available; });
        }
        return false;
    }, [floorId, floorplanSpace]);
    var onConfirmShowSpaceAvailabilityMessage = useCallback(function () {
        return openDialog(React.createElement(ConfirmationDialog, { title: t('components.floorplan.noSpaceAvailableTitle'), message: t('components.floorplan.noSpaceAvailableMessage'), triggerId: FocusId.SpaceAvailability, key: "space-availability", hasCancelButton: false }));
    }, [openDialog, t]);
    var shouldDisplayNotification = 
    // do not display this notification if in desk check in mode for now
    // see if we may want to do that in the future
    !isInDeskCheckInMode &&
        floorId &&
        floorplanLoaded &&
        floorplanSpaceDataLoaded;
    useEffect(function () {
        if (shouldDisplayNotification) {
            // we need to wait until floorplan component space data is populated
            if (hasAvailableSpaces()) {
                // always show space availability message when floorplan has available spaces
                dispatch(floorplanActions.setShowSpaceAvailabilityMessage(true));
            }
            else if (showSpaceAvailabilityMessage) {
                // show no space available confirmation message
                (function () { return __awaiter(void 0, void 0, void 0, function () {
                    var confirmed;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, onConfirmShowSpaceAvailabilityMessage()];
                            case 1:
                                confirmed = _a.sent();
                                if (confirmed) {
                                    dispatch(floorplanActions.setShowSpaceAvailabilityMessage(false));
                                }
                                return [2 /*return*/];
                        }
                    });
                }); })();
            }
        }
    }, [
        dispatch,
        shouldDisplayNotification,
        hasAvailableSpaces,
        onConfirmShowSpaceAvailabilityMessage,
        showSpaceAvailabilityMessage,
    ]);
};
export default useSpaceAvailabilityNotification;
