import { browserLanguage } from 'utils';
import { AnalyticsBrowser } from '@segment/analytics-next';
import getPlatform from './getPlatform';

const key = __CONF__.SEGMENT_KEY;
const integrations = {
  All: true,
  Sentry: false,
};
const analytics = AnalyticsBrowser.load({ writeKey: key }, { integrations });

class Segment {
  constructor() {
    this.extraData = this.additionalInfoInit();
    this.currentTenant = null;
    this.locale = browserLanguage;
    this.data = {};
    this.init();
    this.sendEvents = true;
    this.queue = [];
    this.onLoaded = false;
    this.os = getPlatform();
  }

  init = () => {
    if (!key || !__CONF__.SEGMENT_LOG_EVENTS) {
      this.sendEvents = false;
    }
  };

  identify = async (id) => {
    if (id === this.id) {
      return;
    }

    const extraData = {};
    if (this.currentTenant?.id) {
      const tenantId = `${this.currentTenant.id}`.toLowerCase();
      extraData.tenantId = tenantId;
      extraData.instanceName = this.currentTenant.instanceName;

      this.id = id;
      if (id && this.sendEvents) {
        await analytics.identify(`${id}-${tenantId}`, { ...extraData });
      }
    }
  };

  setLocale = (locale) => {
    this.locale = locale;
  };

  group = async (id) => {
    if (id === this.currentTenant?.id) {
      return;
    }
    this.currentTenant = { id };
    if (this.sendEvents) {
      await analytics.group(id);
    }
  };

  page = async ({ pathname, ...rest }) => {
    if (this.sendEvents) {
      await analytics.page(pathname, rest);
    }
  };

  /**
   * This is mostly for the reference, since segment automatically tracks a lot of data,
   * like build info. But if we need to put something extra to context we can do it like this
   */
  contextMiddleware = async ({ next, context }) => {
    const { appEnv, buildInfo } = this.extraData;
    // adding stuff to context.app doesnt seem to work...
    const out = {
      ...context,
      appEnv,
      buildInfo,
    };
    next(out);
  };

  // this probably needs a rework
  additionalInfoInit = () => ({
    buildInfo: {
      version: __CONF__.version,
      buildType: __CONF__.buildType,
    },
  });

  track = async (event, props, options, cb) => {
    const extraData = {
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      tenantId: this.currentTenant?.id,
      os: this.os,
      path: window.location.pathname,
      engageLocale: this.locale,
    };

    if (this.sendEvents) {
      await analytics.track(event, { ...props, ...extraData }, options, cb);
    }
  };
}

export default new Segment();
