import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-block;
  border-radius: 99px;
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 0px 11px 22px 0px rgba(0, 0, 0, 0.3);
`;

export const Button = styled.button`
  cursor: pointer;
  padding: 22px 105px;
`;

export const ButtonText = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ color, theme }) => color || theme.typo2};
`;
