var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useMemo } from 'react';
import ReactFocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Wrapper, Title, CommonMessage, PrimaryButton, SecondaryButton, } from './styled';
var SpaceNamesMessage = styled(CommonMessage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 42px;\n  font-weight: 700;\n"], ["\n  line-height: 42px;\n  font-weight: 700;\n"])));
var Message = styled(CommonMessage)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 42px;\n  margin-bottom: 28px;\n  margin-left: 4px;\n  margin-right: 4px;\n"], ["\n  line-height: 42px;\n  margin-bottom: 28px;\n  margin-left: 4px;\n  margin-right: 4px;\n"])));
var KioskMakeReservationDialog = function (_a) {
    var spaceNames = _a.spaceNames, onOkPress = _a.onOkPress, onUndoPress = _a.onUndoPress;
    var t = useTranslation().t;
    var displayName = useMemo(function () { return spaceNames.join(', '); }, [spaceNames]);
    return (React.createElement(ReactFocusLock, null,
        React.createElement(Wrapper, null,
            React.createElement(Title, null, t('components.reservationSuccessDialog.title')),
            React.createElement(Message, null,
                React.createElement(Trans, { count: spaceNames.length, i18nKey: "layout.reserve.kioskReservationSuccess" },
                    React.createElement(SpaceNamesMessage, null, { displayName: displayName }))),
            React.createElement(PrimaryButton, { onClick: onOkPress }, t('common.ok')),
            React.createElement(SecondaryButton, { onClick: onUndoPress }, t('common.undo')))));
};
export default KioskMakeReservationDialog;
var templateObject_1, templateObject_2;
