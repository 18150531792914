import { useSelector } from 'react-redux';
import { filtersSelectors } from 'store';
import { formatISODate, useFilterTimes } from '@serraview/engage-shared/utils';
import { RESTRICT_TO_VALUES } from 'consts';
import { useCurrentTimeZone } from './useCurrentTimeZone';
import { useConfig } from './useConfig';
var useReservationFilters = function (isDesk, isDraft) {
    var _a = useSelector(isDraft
        ? filtersSelectors.getDraftFilterDates
        : filtersSelectors.getFilterDates), dateStart = _a.dateStart, dateEnd = _a.dateEnd, allDay = _a.allDay, selectedDates = _a.selectedDates;
    var _b = useConfig(), deskBookingInterval = _b.deskBookingInterval, meetingRoomBookingInterval = _b.meetingRoomBookingInterval, enforceAllDayDeskReservations = _b.enforceAllDayDeskReservations;
    var timeZone = useCurrentTimeZone();
    var _c = useFilterTimes({
        dateStart: dateStart,
        dateEnd: dateEnd,
        timeZone: timeZone,
        enforceAllDayDeskReservations: enforceAllDayDeskReservations,
        restrictTo: isDesk ? RESTRICT_TO_VALUES.DESK : RESTRICT_TO_VALUES.SPACE,
    }), startTime = _c.startTime, endTime = _c.endTime;
    var bookingIntervalSize = isDesk
        ? deskBookingInterval
        : meetingRoomBookingInterval;
    var filterDates = selectedDates.length
        ? selectedDates.map(function (selectedDate) {
            return formatISODate(selectedDate, {
                timeZone: timeZone,
            });
        })
        : null;
    return {
        dateStart: dateStart,
        dateEnd: dateEnd,
        allDay: allDay,
        selectedDates: selectedDates,
        startTime: startTime,
        endTime: endTime,
        filterDates: filterDates,
        bookingIntervalSize: bookingIntervalSize,
        enforceAllDayDeskReservations: enforceAllDayDeskReservations,
    };
};
export default useReservationFilters;
