import React, { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDialog } from 'utils';
import { KioskCountdownDialog } from 'components/kiosk';
import { useConciergeMenuAllowed } from 'components/scenes/ConciergeMenu/hooks/useConciergeMenuAllowed';
import { useNavigate, matchPath } from 'react-router-dom';
import { CONCIERGE_MENU_PATH, FLOOR_PATH, LANDING_PATH } from 'consts';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, globalSearchActions, tenantActions, tenantSelectors, } from 'store';
import { useAppLocation } from 'router/hooks';
var DEFAULT_IDLE_TIMEOUT = 20;
var DEFAULT_DIALOGUE_SECONDS = 20;
var useIdleDialog = function () {
    var _a;
    var _b = useSelector(tenantSelectors.getKioskConfig), idleDialogueShowTimeout = _b.idleDialogueShowTimeout, idleDialogueSeconds = _b.idleDialogueSeconds;
    var _c = useDialog(), openDialog = _c.openDialog, isOpen = _c.isOpen, closeDialog = _c.closeDialog;
    var conciergeMenuAllowed = useConciergeMenuAllowed().conciergeMenuAllowed;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var pathname = useAppLocation().pathname;
    var isFloorPath = matchPath(FLOOR_PATH, pathname) !== null;
    var onTimeIsOver = useCallback(function () {
        closeDialog();
        dispatch(globalSearchActions.clearSearchValue());
        // @ts-ignore
        dispatch(filtersActions.clearFilterValues());
        dispatch(tenantActions.loadCurrentLocationFromKioskConfig());
        var route = conciergeMenuAllowed ? CONCIERGE_MENU_PATH : LANDING_PATH;
        navigate(route);
    }, [closeDialog, conciergeMenuAllowed, dispatch, navigate]);
    var onIdle = useCallback(function () {
        if (isOpen) {
            return;
        }
        if (isFloorPath && !conciergeMenuAllowed) {
            // do kiosk reset but without showing the dialog
            onTimeIsOver();
            return;
        }
        openDialog(React.createElement(KioskCountdownDialog, { onContinuePress: closeDialog, onTimeIsOver: onTimeIsOver, onExitPress: onTimeIsOver, seconds: idleDialogueSeconds !== null && idleDialogueSeconds !== void 0 ? idleDialogueSeconds : DEFAULT_DIALOGUE_SECONDS }));
    }, [
        closeDialog,
        conciergeMenuAllowed,
        idleDialogueSeconds,
        isOpen,
        onTimeIsOver,
        openDialog,
        isFloorPath,
    ]);
    useIdleTimer({
        onIdle: onIdle,
        // idleDialogueShowTimeout is configured in seconds, need to convert it to milliseconds
        timeout: (_a = idleDialogueShowTimeout * 1000) !== null && _a !== void 0 ? _a : DEFAULT_IDLE_TIMEOUT,
    });
};
export default useIdleDialog;
