import {
  addMinutes,
  addMonths,
  isBefore,
  isSameMonth,
  parseISO,
  startOfMonth as fnsStartOfMonth,
  subMonths,
} from 'date-fns';
import {
  checkOrParse,
  formatLocalizedTime,
  hasBookingFinished,
} from '@serraview/engage-shared';
import { i18n } from 'locales';

export const DATE_NAVIGATION = {
  PREVIOUS: 'previous',
  NEXT: 'next',
};

const FROM_NOW = {
  PAST: 'past',
  ONGOING: 'now',
  FUTURE: 'future',
};

const sameMonth = (date1, date2) => {
  const _date1 = checkOrParse(date1);
  const _date2 = checkOrParse(date2);
  return isSameMonth(_date1, _date2);
};

const startOfMonth = (date) => fnsStartOfMonth(checkOrParse(date));

const getTimeString = (date, timeZone, locale) =>
  date ? formatLocalizedTime(date, { timeZone, locale }) : '';

const getTimeSeparator = (start, end) => (start && end ? '-' : '');

const getTimeText = ({ start, end, timeZone, isAllDayBooking, locale }) => {
  if (isAllDayBooking) {
    return `${i18n.t('layout.agendaList.allDay')} - ${getTimeString(
      start,
      timeZone,
      locale,
    )} ${i18n.t('layout.agendaList.arrival')}`;
  }
  // we're adding 1 minute to end time because we subtract 1 minute from end time on booking creation phase
  // this was done in order to avoid 09:00 - 09:29 displaying
  const endTime = addMinutes(parseISO(end), 1);

  return `${getTimeString(start, timeZone, locale)}
  ${getTimeSeparator(start, end)} ${getTimeString(endTime, timeZone, locale)}`;
};

const getTimeFromNow = (start, end) => {
  const _start = checkOrParse(start);
  const _end = checkOrParse(end);
  const current = new Date();
  const startsBeforeCurrent = isBefore(_start, current);
  const hasFinished = hasBookingFinished(_end);

  if (isBefore(_end, _start)) {
    throw new RangeError('End date cannot start before start date');
  }
  if (hasFinished) {
    return FROM_NOW.PAST;
  }
  if (startsBeforeCurrent && !hasFinished) {
    return FROM_NOW.ONGOING;
  }
  return FROM_NOW.FUTURE;
};

const changeAgendaMonth = (navigation, selectedDate) => {
  let newDate;
  if (navigation === DATE_NAVIGATION.PREVIOUS) {
    newDate = subMonths(selectedDate, 1);
  } else if (navigation === DATE_NAVIGATION.NEXT) {
    newDate = addMonths(selectedDate, 1);
  }
  return sameMonth(newDate, new Date()) ? new Date() : startOfMonth(newDate);
};

const getReservedDaysFromAgendaItems = (agendaItems) =>
  Object.entries(agendaItems)
    .filter(([, events]) => events?.length > 0)
    .map(([date]) => date);

export {
  FROM_NOW,
  sameMonth,
  startOfMonth,
  getTimeText,
  getTimeFromNow,
  changeAgendaMonth,
  getReservedDaysFromAgendaItems,
};
