import React, { memo } from 'react';
import { getChatProvider, getChatURL } from '@serraview/engage-shared/utils';
import { MEDIA_TYPE } from 'consts';
import Button from './Button';
import { Wrapper } from './styled';
// eslint-disable-next-line no-alert
var onError = function (errorMessage) { return alert("Error: ".concat(errorMessage)); };
var MediaLinks = function (_a) {
    var email = _a.email, chatLink = _a.chatLink;
    var chatProvider = getChatProvider(chatLink);
    var chatURL = getChatURL(chatLink);
    if (!email && !chatProvider) {
        return null;
    }
    return (React.createElement(Wrapper, { "data-testid": "person-media-links" },
        email ? (React.createElement(Button, { type: MEDIA_TYPE.EMAIL, mediaString: email, onError: onError })) : null,
        chatProvider ? (React.createElement(Button, { type: chatProvider, mediaString: chatURL, onError: onError })) : null));
};
export default memo(MediaLinks);
