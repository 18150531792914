import { createGlobalStyle } from 'styled-components';
import floorplanStyles from 'floorplan/styles';
import normalize from './normalize';
import { fontSizes } from './fontSizes';
import fontStyles from './fontStyles';
import fonts from './fonts/fonts';
import utilityStyles from './utilityStyles';

const GlobalStyles = createGlobalStyle`
body { 
  overflow: hidden; // prevents showing redundant scrollbars in Firefox
  height: 100vh;
}

@media print {
  body {
    display: none;
  }
}

.sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

${normalize}
${floorplanStyles}
${fonts}
${fontSizes}
${fontStyles}
${utilityStyles}
`;

export default GlobalStyles;
