import { useDispatch } from 'react-redux';
import { inAppNotificationsActions } from 'store';
import { useState } from 'react';
import { getIsBookingsAllowed } from '@serraview/engage-shared/utils';
import { useUser } from 'api/queries/useUser';
import { useConfig } from './useConfig';

export default function useBookingsAllowedNotification({
  notificationAllowed,
}) {
  const config = useConfig();
  const [notificationShowed, setNotificationShowed] = useState(false);
  const dispatch = useDispatch();
  const { data: userData, isSuccess } = useUser();
  const { bookingsAllowed, bookingDeniedMessage } = getIsBookingsAllowed({
    config,
    userData,
  });

  if (!isSuccess || !notificationAllowed) return;

  if (!bookingsAllowed && !notificationShowed) {
    dispatch(
      inAppNotificationsActions.addWarningNotification({
        message: bookingDeniedMessage,
      }),
    );
    setNotificationShowed(true);
  }
}
