import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  width: 52px;
  height: 52px;
  background: white;
  border: 4px solid white;
  pointer-events: auto;
  cursor: pointer;
  color: ${({ theme }) => theme.typo2};
  svg {
    width: 26px;
    height: 26px;
  }
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
