import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { inAppNotificationsActions, inAppNotificationsSelectors } from 'store';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TOAST_STYLES } from 'consts';
import { isLayoutRtlDirection } from 'utils';
import { StyledToastBanner, StyledToastRightBox } from './styled';

const TOAST_STYLES_CONFIG = {
  [TOAST_STYLES.BANNER]: {
    position: toast.POSITION.TOP_CENTER,
    Component: StyledToastBanner,
    newestOnTop: false,
    draggableDirection: 'y',
  },
  [TOAST_STYLES.RIGHT_BOX]: {
    position: toast.POSITION.TOP_RIGHT,
    Component: StyledToastRightBox,
    newestOnTop: true,
    draggableDirection: 'x',
  },
};

function NotificationController({ children }) {
  const dispatch = useDispatch();
  const notification = useSelector(
    inAppNotificationsSelectors.getInAppNotification,
  );
  const isLayoutRTL = isLayoutRtlDirection();
  const toastStyle = notification?.style || TOAST_STYLES.RIGHT_BOX;
  const {
    position,
    newestOnTop,
    draggableDirection,
    Component: ToastPresenter,
  } = TOAST_STYLES_CONFIG[toastStyle];

  useEffect(() => {
    if (!notification) return;
    const { id, ttl, message, type } = notification;
    const removeNotification = () => {
      dispatch(inAppNotificationsActions.removeNotification(id));
    };

    toast(message, {
      onClose: removeNotification,
      autoClose: ttl ? ttl : false,
      position,
      newestOnTop,
      draggableDirection,
      type,
      toastId: id,
    });
  }, [dispatch, position, newestOnTop, draggableDirection, notification]);

  return (
    <>
      <ToastPresenter
        isLayoutRTL={isLayoutRTL}
        pauseOnFocusLoss={false}
        pauseOnHover
        closeOnClick
        transition={Slide}
      />
      {children}
    </>
  );
}

NotificationController.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
NotificationController.defaultProps = {};

export default memo(NotificationController);
