import React, { memo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { formatDate, formatLocalizedDate, isSameLocalizedDay, } from '@serraview/engage-shared/utils';
import { AvailabilityInfo, Calendar, SelectButton, TimePicker, } from 'base-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import { noop } from 'utils';
import { FocusId, useFocus } from 'utils/hooks';
import { getDateFromTimeSegment } from 'components/base/DateTime/TimePicker/utils';
import { DateFormat, getSelectedStartTime } from '@serraview/engage-shared';
import { CalendarModal, CalendarModalInnerWrapper, TimePickerLabel, TimePickerWrapper, } from './styled';
import { useTimePickerConfig } from './useTimePickerConfig';
function DateTimePicker(_a) {
    var _b = _a.initialDate, initialDate = _b === void 0 ? new Date() : _b, _c = _a.showAvailabilityInfo, showAvailabilityInfo = _c === void 0 ? false : _c, minDate = _a.minDate, maxDate = _a.maxDate, _d = _a.timeZone, timeZone = _d === void 0 ? '' : _d, _e = _a.handleClose, handleClose = _e === void 0 ? noop : _e, _f = _a.isTimeFormatH12, isTimeFormatH12 = _f === void 0 ? false : _f, _g = _a.returnFocusTo, returnFocusTo = _g === void 0 ? FocusId.TimelineButton : _g;
    var _h = useState(initialDate), selectedDate = _h[0], setSelectedDate = _h[1];
    var isTodayRef = useRef(false);
    var _j = useTimePickerConfig({
        minDate: minDate,
        selectedDate: selectedDate,
        timeZone: timeZone,
    }), timePickerOptions = _j.timePickerOptions, roundedDate = _j.roundedDate, timeValue = _j.timeValue;
    var t = useTranslation().t;
    var uiLanguage = useSelector(userConfigSelectors.getUiLanguage);
    var _onDateSelected = function (date) {
        // get date part only because dateObject is in user's timeZone
        var _selectedDate = formatDate(date);
        var newDate = moment.tz(_selectedDate, timeZone);
        var now = new Date();
        isTodayRef.current = isSameLocalizedDay(now, _selectedDate, timeZone);
        if (isTodayRef.current) {
            setSelectedDate(getSelectedStartTime(null));
            return;
        }
        newDate.hours(9);
        newDate.minutes(0);
        setSelectedDate(newDate.toDate());
    };
    var onTimeSelected = function (timePickerSegment) {
        var newDate = getDateFromTimeSegment(timePickerSegment, roundedDate, isTimeFormatH12);
        if (newDate) {
            isTodayRef.current = false;
            setSelectedDate(newDate);
        }
    };
    // can not use getLocalizedDate, because we need Date object for Calendar
    var toLocalizedDate = function (date, timeZone) {
        var args = ['en-US'];
        if (timeZone) {
            // @ts-ignore
            args.push({ timeZone: timeZone });
        }
        return new Date(date.toLocaleString.apply(date, args));
    };
    var onSelectClick = function () {
        handleClose(selectedDate, isTodayRef.current);
    };
    var calendarDateValue = toLocalizedDate(selectedDate, timeZone);
    var calendarNavigationAriaLabel = formatLocalizedDate(calendarDateValue, {
        locale: uiLanguage,
        format: DateFormat.monthLong,
    });
    useFocus({ returnFocusTo: returnFocusTo });
    return (React.createElement(CalendarModal, { onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(CalendarModalInnerWrapper, null,
            React.createElement(Calendar, { locale: uiLanguage, 
                // @ts-ignore
                onChange: _onDateSelected, value: calendarDateValue, minDate: toLocalizedDate(minDate, timeZone), maxDate: toLocalizedDate(maxDate, timeZone), navigationAriaLabel: calendarNavigationAriaLabel, prevAriaLabel: t('accessibilityLabels.timelineCalendarNavPrevMonth'), nextAriaLabel: t('accessibilityLabels.timelineCalendarNavNextMonth') }),
            React.createElement(TimePickerWrapper, null,
                React.createElement(TimePickerLabel, null, t('components.dateButton.timePickerLabel')),
                React.createElement(TimePicker, { onChange: onTimeSelected, value: timeValue, isTimeFormatH12: isTimeFormatH12, timeOptions: timePickerOptions })),
            showAvailabilityInfo && React.createElement(AvailabilityInfo, { dateStart: selectedDate })),
        React.createElement(SelectButton, { id: FocusId.TimelineSelectButton, "data-testid": "modal-date-time-picker-select-btn", onClick: onSelectClick }, t('common.select'))));
}
export default memo(DateTimePicker);
