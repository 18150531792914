import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { globalSearchSelectors } from 'store/globalSearch';
import { useTranslation } from 'react-i18next';
import { GlobalSearchNoData as StyledContainer } from './styled';
var getListEmptyText = function (_a) {
    var searchTerm = _a.searchTerm, t = _a.t, restrictTo = _a.restrictTo;
    return searchTerm
        ? getNoSearchedResultsText(t, restrictTo)
        : getNoResultsText(t, restrictTo);
};
var getNoSearchedResultsText = function (t, restrictTo) {
    var genericText = t('layout.globalSearch.noResultsGeneric');
    var emptyText = {
        Person: t('layout.globalSearch.noResultsPerson'),
        Space: t('layout.globalSearch.noResultsSpace'),
        Desk: t('layout.globalSearch.noResultsDesk'),
        Team: t('layout.globalSearch.noResultsTeam'),
    };
    return emptyText[restrictTo] || genericText;
};
var getNoResultsText = function (t, restrictTo) {
    var genericText = t('layout.globalSearch.noResultsFound');
    var emptyText = {
        Person: t('layout.globalSearch.noPeopleFound'),
        Space: t('layout.globalSearch.noSpacesFound'),
        Desk: t('layout.globalSearch.noDesksFound'),
        Team: t('layout.globalSearch.noTeamsFound'),
    };
    return emptyText[restrictTo] || genericText;
};
var GlobalSearchNoData = function (_a) {
    var htmlId = _a.htmlId;
    var t = useTranslation().t;
    var searchTerm = useSelector(globalSearchSelectors.getGlobalSearchValue);
    var restrictTo = useSelector(globalSearchSelectors.getRestrictToSearchFilterValue);
    var listEmptySearchText = getListEmptyText({ t: t, restrictTo: restrictTo, searchTerm: searchTerm });
    return (React.createElement(StyledContainer, { id: htmlId, "aria-live": "polite", "aria-atomic": "true", "data-testid": "search-no-data" },
        React.createElement("ul", { style: { padding: 0, margin: 0 } },
            React.createElement("p", { role: "listitem" },
                listEmptySearchText,
                !!searchTerm && React.createElement("b", null, " \"".concat(searchTerm, "\""))))));
};
export default memo(GlobalSearchNoData);
