import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { inAppNotificationsActions } from 'store';
import { useRefreshBookings } from 'api/queries';
import { getMultiDayBookingFailedMessage, getMultiDayBookingSuccessMessage, } from 'components/layouts/Reserve/Card/hooks/useReserveCallbacks';
export var useTeamReserveCallbacks = function (_a) {
    var spaceItem = _a.spaceItem;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var refreshBookings = useRefreshBookings().refresh;
    var onSuccessHourlyTeamBooking = function () {
        // refresh bookings
        refreshBookings({ spaceItem: spaceItem });
        dispatch(inAppNotificationsActions.addSuccessNotification({
            message: t("layout.reserve.team.successful"),
        }));
    };
    var onErrorTeamBooking = function () {
        dispatch(inAppNotificationsActions.addErrorNotification({
            message: t("layout.reserve.team.errorOccurred"),
        }));
    };
    // display success UI notification if at least one successfully reservation was made
    // and display error message with the list of days which couldn't be reserved
    var onSuccessMultiDayTeamBooking = function (_a) {
        var successfullyReserved = _a.successfullyReserved, successfullyReservedDaysMessage = _a.successfullyReservedDaysMessage, failedDaysMessage = _a.failedDaysMessage;
        // means there are at least one successful reservation
        if (successfullyReserved) {
            dispatch(inAppNotificationsActions.addSuccessNotification({
                message: getMultiDayBookingSuccessMessage({
                    t: t,
                    successfullyReservedDaysMessage: successfullyReservedDaysMessage,
                }),
            }));
        }
        // means there are days failed to reserve
        if (failedDaysMessage) {
            dispatch(inAppNotificationsActions.addErrorNotification({
                message: getMultiDayBookingFailedMessage({
                    t: t,
                    failedDaysMessage: failedDaysMessage,
                }),
            }));
        }
        // refresh bookings
        refreshBookings({ spaceItem: spaceItem });
    };
    return {
        onSuccessHourlyTeamBooking: onSuccessHourlyTeamBooking,
        onErrorTeamBooking: onErrorTeamBooking,
        onSuccessMultiDayTeamBooking: onSuccessMultiDayTeamBooking,
    };
};
