import React, { useEffect, useRef } from 'react';
var SizeRecognitionWrapper = function (_a) {
    var children = _a.children, index = _a.index, onSizeFound = _a.onSizeFound;
    var elementRef = useRef(null);
    useEffect(function () {
        var _a;
        var size = (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (size) {
            onSizeFound(size, index);
        }
    }, [index, onSizeFound]);
    return React.createElement("div", { ref: elementRef }, children);
};
export default SizeRecognitionWrapper;
