var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  height: 38px;\n"], ["\n  display: flex;\n  justify-content: center;\n  height: 38px;\n"])));
export var ClearButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  pointer-events: ", ";\n  cursor: ", ";\n  height: 38px;\n  margin-right: 5px;\n  background-color: transparent;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  ", "\n"], ["\n  pointer-events: ", ";\n  cursor: ", ";\n  height: 38px;\n  margin-right: 5px;\n  background-color: transparent;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  ", "\n"])), function (_a) {
    var clickable = _a.clickable;
    return (clickable ? 'inherit' : 'none');
}, function (_a) {
    var clickable = _a.clickable;
    return (clickable ? 'pointer' : 'inherit');
}, function (_a) {
    var theme = _a.theme;
    return theme.color9;
}, function (_a) {
    var clickable = _a.clickable;
    return clickable &&
        "\n    &.focus-visible {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n    }\n  ";
});
export var FiltersStatusText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 5px;\n  font-size: 14px;\n  font-weight: ", ";\n"], ["\n  margin-right: 5px;\n  font-size: 14px;\n  font-weight: ", ";\n"])), function (_a) {
    var bold = _a.bold;
    return (bold ? 'bold' : 'normal');
});
export var FiltersStatusIconWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 5px;\n"], ["\n  margin-right: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
