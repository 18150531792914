import React from 'react';
import { SkeletonTheme } from 'base-components';
import Skeleton from 'react-loading-skeleton';
import { InfoWrapper, Wrapper } from './styled';
var LocationCardSkeleton = function () { return (React.createElement(SkeletonTheme, { style: { width: '100%' } },
    React.createElement(Wrapper, null,
        React.createElement(InfoWrapper, null,
            React.createElement("h6", null,
                React.createElement(Skeleton, { width: 200 })),
            React.createElement("h6", null,
                React.createElement(Skeleton, { width: 200 }))),
        React.createElement(Skeleton, { circle: true, height: 40, width: 40 })))); };
export default LocationCardSkeleton;
