import { useFetchImageURL } from 'api/queries/useFetchImageURL';
export var usePersonImage = function (_a) {
    var imageUrl = _a.imageUrl, enabled = _a.enabled;
    return useFetchImageURL({
        url: imageUrl,
        queryKey: ['person-image', imageUrl !== null && imageUrl !== void 0 ? imageUrl : '', 'id'],
        options: {
            enabled: enabled,
        },
    });
};
