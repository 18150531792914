import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Agenda,
  Booking,
  Configuration,
  ImageViewer,
  SocialDistancingInfo,
  BookingLocationUnavailable,
  UserProfile,
  HealthDeclaration,
} from 'layouts';
import { AgendaModal, CommonModal } from 'scenes/modals/styled';
import { MODALS } from 'consts';
import { useDispatch } from 'react-redux';
import { navigationActions } from 'store';
import { useBookingModal } from 'utils/hooks';
import { ModalDateTimePicker } from 'base-components';
import { useTranslation } from 'react-i18next';

import TeamBooking from 'components/layouts/Booking/TeamBooking';
import { testProps } from 'utils';

const Content = ({ layout, content }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { close: closeBookingModal } = useBookingModal();

  const onCloseModal = () => {
    dispatch(navigationActions.clearModal());
  };

  const onCloseAgendaItemModal = () => {
    dispatch(navigationActions.clearModal());
    closeBookingModal();
  };

  switch (layout) {
    case MODALS.AGENDA:
      return (
        <AgendaModal
          handleClose={onCloseModal}
          {...testProps(t, 'accessibilityLabels.agenda').props}
        >
          <Agenda />
        </AgendaModal>
      );
    case MODALS.AGENDA_ITEM:
      return (
        <CommonModal
          handleClose={onCloseAgendaItemModal}
          {...testProps(t, 'accessibilityLabels.agendaItemModal').props}
        >
          <Booking {...content} />
        </CommonModal>
      );
    case MODALS.TEAM_AGENDA_ITEM:
      return (
        <CommonModal
          handleClose={onCloseAgendaItemModal}
          aria-label={t('accessibilityLabels.agendaItemModal')}
        >
          <TeamBooking {...content} />
        </CommonModal>
      );
    case MODALS.CONFIGURATION:
      return (
        <CommonModal
          handleClose={onCloseModal}
          {...testProps(t, 'accessibilityLabels.configurationModal').props}
        >
          <Configuration />
        </CommonModal>
      );
    case MODALS.USER_PROFILE:
      return (
        <CommonModal
          handleClose={onCloseModal}
          {...testProps(t, 'accessibilityLabels.userProfileModal').props}
        >
          <UserProfile />
        </CommonModal>
      );
    case MODALS.IMAGE_VIEWER:
      return (
        <CommonModal
          customContent
          handleClose={onCloseModal}
          {...testProps(t, 'accessibilityLabels.imageViewer').props}
        >
          <ImageViewer />
        </CommonModal>
      );
    case MODALS.DATE_TIME_PICKER:
      return (
        <CommonModal
          customContent
          handleClose={onCloseModal}
          {...testProps(t, 'accessibilityLabels.dateTimePickerModal').props}
        >
          <ModalDateTimePicker />
        </CommonModal>
      );
    case MODALS.SOCIAL_DISTANCING:
      return (
        <CommonModal
          customContent
          handleClose={onCloseModal}
          {...testProps(t, 'accessibilityLabels.socialDistancingModal').props}
        >
          <SocialDistancingInfo />
        </CommonModal>
      );
    case MODALS.BOOKING_LOCATION_UNAVAILABLE:
      return (
        <CommonModal
          customContent
          handleClose={onCloseModal}
          {...testProps(t, 'accessibilityLabels.bookingLocationUnavailable')
            .props}
        >
          <BookingLocationUnavailable {...content} />
        </CommonModal>
      );
    case MODALS.HEALTH_DECLARATION:
      return (
        <CommonModal
          customContent
          {...testProps(t, 'accessibilityLabels.healthDeclaration').props}
        >
          <HealthDeclaration {...content} />
        </CommonModal>
      );
    default:
      return null;
  }
};

Content.propTypes = {
  layout: PropTypes.string,
  content: PropTypes.object,
};
Content.defaultProps = {
  layout: null,
  content: null,
};

export default memo(Content);
