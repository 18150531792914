import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { filtersSelectors, tenantSelectors } from 'store';
import { AGENDA_EVENTS_KEY, useAgendaEvents as useAgendaEventsHook, } from '@serraview/engage-shared/api';
import { useUser } from 'api/queries/useUser';
import { getLocalTimeZone } from 'utils';
import { useTranslation } from 'react-i18next';
export var useAgendaEvents = function (month, year, eventsFilter, options) {
    if (options === void 0) { options = {}; }
    var t = useTranslation().t;
    var data = useUser().data;
    var _a = data || {}, personId = _a.personId, userId = _a.userId;
    var userTimeZone = getLocalTimeZone();
    return useAgendaEventsHook({
        month: month,
        year: year,
        eventsFilter: eventsFilter,
        personId: personId,
        userId: userId,
        userTimeZone: userTimeZone,
        translationFn: t,
        options: options,
    });
};
export var useRefreshAgendaEvents = function () {
    var queryClient = useQueryClient();
    var agendaEventsType = useSelector(filtersSelectors.getAgendaEventsType);
    var userId = useSelector(tenantSelectors.getUserId);
    var getQueryKey = useCallback(function (selectedDate) {
        // selectedDate can be null if the bookings is still loading
        var month = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.month();
        var year = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.year();
        return [AGENDA_EVENTS_KEY, userId, month, year, agendaEventsType];
    }, [userId, agendaEventsType]);
    var invalidateQuery = function (_a) {
        var selectedDate = _a.selectedDate;
        return queryClient.invalidateQueries(getQueryKey(selectedDate));
    };
    var refresh = React.useCallback(invalidateQuery, [
        queryClient,
        getQueryKey,
    ]);
    return { refresh: refresh, getQueryKey: getQueryKey };
};
