import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenInfo } from 'base-components';
import { useDispatch } from 'react-redux';
import { navigationActions, userConfigActions } from 'store';
import { VISIBILITY } from 'consts';

const SocialDistancingInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(userConfigActions.setShowSocialDistancingInfo(VISIBILITY.HIDE));
    dispatch(navigationActions.clearModal());
  };

  const title = t('components.socialDistancingInfo.bookSafe');
  const message = t('components.socialDistancingInfo.message');
  const buttonTitle = t('components.socialDistancingInfo.great');
  const pageTitle = t('documentTitles.socialDistancingInfo');

  return (
    <FullScreenInfo
      title={title}
      message={message}
      buttonTitle={buttonTitle}
      onPressButton={handleClick}
      iconName="shield"
      pageTitle={pageTitle}
      onClose={handleClick}
    />
  );
};

export default memo(SocialDistancingInfo);
