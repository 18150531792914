import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useHourlyTeamBookingMutation as useHourlyTeamBookingMutationHook, } from '@serraview/engage-shared';
export var useHourlyTeamBookingMutation = function (_a) {
    var teamId = _a.teamId, summary = _a.summary, filter = _a.filter, timeZone = _a.timeZone, duration = _a.duration, allDayDuration = _a.allDayDuration, desks = _a.desks, attendees = _a.attendees, onSuccess = _a.onSuccess, onError = _a.onError;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    return useHourlyTeamBookingMutationHook({
        tenantId: tenantId,
        teamId: teamId,
        summary: summary,
        filter: filter,
        timeZone: timeZone,
        duration: duration,
        allDayDuration: allDayDuration,
        desks: desks,
        attendees: attendees,
    }, {
        onSuccess: onSuccess,
        onError: onError,
    });
};
