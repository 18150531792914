function getRgbColor(strColor) {
  const s = new Option().style;
  s.color = strColor;
  return s.color;
}

function checkedAlphaValue(alpha) {
  const a = Number(alpha);
  // eslint-disable-next-line
  if (isNaN(a)) throw new Error('Invalid alpha - not a number');
  if (a > 1 || a < 0)
    throw new Error('Invalid alpha - should be 0>= alpha <=1');
  return a;
}

function getRGBAObject(color) {
  const rgb = getRgbColor(color);

  if (!rgb) throw new Error('Not a valid color');
  const [r, g, b, a] = rgb
    .slice(rgb.indexOf('(') + 1, color.indexOf(')'))
    .split(',')
    .map((c) => parseInt(c, 10));
  return {
    r,
    g,
    b,
    a: a || a === 0 ? a : 1,
  };
}

export function setAlpha(color, alpha) {
  try {
    const { r, g, b } = getRGBAObject(color);
    const checkedAlpha = checkedAlphaValue(alpha);
    return `rgba(${r},${g},${b},${checkedAlpha})`;
  } catch (error) {
    // eslint-disable-next-line
    console.warn(error, color, alpha);
    // not sure should this rethrow
    // we might break app over smaller detail
    return 'rgba(0,0,0,0)';
    // TODO: reporting
  }
}

export function getRGBA(color) {
  const { r, g, b, a } = getRGBAObject(color);
  return `rgba(${r},${g},${b},${a || 1})`;
}
