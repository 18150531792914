var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  width: 100vw;\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 40px;\n"], ["\n  height: 100vh;\n  width: 100vw;\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding: 40px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
export var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 600;\n  margin-bottom: 12px;\n  margin-top: 30px;\n"], ["\n  font-size: 24px;\n  font-weight: 600;\n  margin-bottom: 12px;\n  margin-top: 30px;\n"])));
export var Message = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 18px;\n  margin-bottom: 30px;\n"], ["\n  font-size: 18px;\n  margin-bottom: 30px;\n"])));
export var IconButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  color: ", ";\n"], ["\n  cursor: pointer;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
export var CloseButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n  width: 26px;\n  height: 26px;\n  position: absolute;\n  right: 30px;\n  top: 20px;\n  bottom: 0;\n  color: ", ";\n"], ["\n  cursor: pointer;\n  width: 26px;\n  height: 26px;\n  position: absolute;\n  right: 30px;\n  top: 20px;\n  bottom: 0;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
export var Button = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  border: 2px solid ", ";\n  border-radius: 10px;\n  padding: 12px 26px;\n  cursor: pointer;\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  border: 2px solid ", ";\n  border-radius: 10px;\n  padding: 12px 26px;\n  cursor: pointer;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
