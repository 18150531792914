import React, { useEffect, useRef } from 'react';
import { Icon, Spinner } from 'base-components';
import { useImageViewer } from 'layouts/ImageViewer/hooks/useImageViewer';
import {
  Wrapper,
  Image,
  CloseButton,
  CloseButtonWrapper,
  ImageWrapper,
  SpinnerWrapper,
  ErrorMessage,
} from 'layouts/ImageViewer/styled';
import { useTranslation } from 'react-i18next';
import { useLoadingState } from 'layouts/ImageViewer/hooks/useLoadingState';

export function ImageViewer() {
  const { t } = useTranslation();
  const { content, closeImageViewer } = useImageViewer();
  const { isSuccess, isError, isLoading, onLoadSuccess, onLoadError } =
    useLoadingState();
  const closeButtonRef = useRef();
  const { imageSource, imageDescription } = content;

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, [closeButtonRef]);

  return (
    <Wrapper>
      <ImageWrapper>
        <CloseButtonWrapper>
          <CloseButton
            $visible={isSuccess}
            ref={closeButtonRef}
            data-testid="image-viewer-close-button"
            onClick={closeImageViewer}
          >
            <Icon name="close" size={12} />
          </CloseButton>
        </CloseButtonWrapper>
        {isLoading && (
          <SpinnerWrapper>
            <Spinner data-testid="image-viewer-spinner" />
          </SpinnerWrapper>
        )}
        {isError && (
          <ErrorMessage data-testid="image-viewer-error">
            {t('common.networkError')}
          </ErrorMessage>
        )}
        <Image
          src={imageSource}
          onLoad={onLoadSuccess}
          onError={onLoadError}
          alt={imageDescription}
        />
      </ImageWrapper>
    </Wrapper>
  );
}
