var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from 'react';
import { addWeeks, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedStartTime, getSelectedTimes, } from '@serraview/engage-shared/utils';
import { filtersActions, filtersSelectors, navigationSelectors } from 'store';
import { useCurrentTimeZone } from 'utils';
import { useConfig } from './useConfig';
/**
 * Date picker hook
 * @param isDraft - boolean that specifies should we use draft filters data or not
 * @param selectedDate - date selected on Calendar
 */
export default function useDatePicker(_a) {
    var _b = _a === void 0 ? {
        isDraft: false,
    } : _a, isDraft = _b.isDraft;
    var _c = useSelector(isDraft
        ? filtersSelectors.getDraftFilterDates
        : filtersSelectors.getFilterDates), dateStart = _c.dateStart, dateEnd = _c.dateEnd, allDay = _c.allDay;
    var showTimelineDatePicker = useSelector(navigationSelectors.getShowTimelineDatePicker);
    var dispatch = useDispatch();
    var timeZone = useCurrentTimeZone();
    var futureBookingsLimit = useConfig().futureBookingsLimit;
    var minDate = new Date();
    var maxDate = subDays(addWeeks(new Date(), futureBookingsLimit || 4), 1);
    // @ts-ignore
    var start = getSelectedStartTime(null);
    var _d = getSelectedTimes(dateStart || start, null), defaultStartDate = _d.dateStart, defaultEndDate = _d.dateEnd;
    /**
     * Updates `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values.
     * Start date will be set to provided value.
     * End date will be set to `null`.
     *
     * @param {Date} date
     * @returns void
     */
    var setTimelineDate = useCallback(function (value) {
        var dateStart = getSelectedStartTime(value);
        dispatch(filtersActions.setFilterValues({ dateStart: dateStart, dateEnd: null }));
    }, [dispatch]);
    /**
     * Updates `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values.
     * Start date will be set to provided value.
     * End date will be set according to dateEnd if considerDateEnd is set to true,
     * otherwise it will set dateEnd to the provided date + 1 hour.
     *
     * @param {Date} date
     * @param {boolean} considerDateEnd
     * @returns void
     */
    var setDateStart = useCallback(function (date, considerDateEnd) {
        if (considerDateEnd === void 0) { considerDateEnd = false; }
        var start = getSelectedStartTime(date);
        var changes = getSelectedTimes(start, considerDateEnd ? dateEnd : null);
        dispatch(filtersActions.setFilterValues(__assign(__assign({}, changes), { isDraft: isDraft })));
    }, [dateEnd, dispatch, isDraft]);
    /**
     * Updates `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values.
     * End date will be set to provided value.
     * Start date will be set to current `filters.filterValues.dateStart` value if provided `date` argument is after `dateStart`.
     * Otherwise, start date will be set to provided `date` - 1 hour.
     *
     * @param {Date} date
     * @returns void
     */
    var setDateEnd = useCallback(function (date) {
        var changes = getSelectedTimes(dateStart, date);
        dispatch(filtersActions.setFilterValues(__assign(__assign({}, changes), { isDraft: isDraft })));
    }, [dateStart, dispatch, isDraft]);
    /**
     * Clears `filters.filterValues.dateStart` and `filters.filterValues.dateEnd` values (both set to `null`).
     *
     * @returns void
     */
    var onClear = useCallback(function () {
        dispatch(filtersActions.clearDates({ isDraft: isDraft }));
    }, [dispatch, isDraft]);
    return {
        maxDate: maxDate,
        minDate: minDate,
        timeZone: timeZone,
        showTimelineDatePicker: showTimelineDatePicker,
        allDay: allDay,
        onClear: onClear,
        // Calendar
        dateStart: dateStart,
        defaultStartDate: defaultStartDate,
        dateEnd: dateEnd,
        defaultEndDate: defaultEndDate,
        setDateStart: setDateStart,
        setDateEnd: setDateEnd,
        setTimelineDate: setTimelineDate,
    };
}
