import { createSlice, createSelector } from '@reduxjs/toolkit';
var initialState = {
    kioskConfig: {
        userLocation: {},
        kioskLocation: {},
        isLoaded: false,
    },
    tenantId: null,
    nextTenantId: null,
    config: {},
    theme: {},
    userLocation: {},
    userId: null,
    pastTenants: {},
};
var tenant = createSlice({
    name: 'tenant',
    initialState: initialState,
    reducers: {
        setTenant: function (state, action) {
            state.tenantId = action.payload.tenantId;
            state.config = action.payload.config;
            state.theme = action.payload.theme;
        },
        setCurrentLocation: function (state, action) {
            state.userLocation = action.payload;
        },
        setKioskConfig: function (state, action) {
            state.kioskConfig = action.payload;
            state.userLocation = action.payload.userLocation;
        },
        resetKioskConfig: function (state) {
            state.kioskConfig = initialState.kioskConfig;
            state.userLocation = initialState.userLocation;
        },
        loadCurrentLocationFromKioskConfig: function (state) {
            state.userLocation = state.kioskConfig.userLocation;
        },
        setUser: function (state, action) {
            state.userId = action.payload;
        },
        clear: function (state) {
            state.tenantId = null;
            state.config = {};
            state.theme = {};
            state.userLocation = {};
            state.userId = null;
            state.showSocialDistancingInfo = false;
            state.kioskConfig = initialState.kioskConfig;
        },
        addTenant: function (state, action) {
            state.pastTenants[action.payload.id] = action.payload;
        },
        setNextTenantId: function (state, action) {
            state.nextTenantId = action.payload.nextTenantId;
        },
        clearNextTenantId: function (state) {
            state.nextTenantId = null;
        },
    },
});
var getTenant = function (state) { return state.tenant; };
var getConfig = createSelector(getTenant, function (tenant) { return tenant.config; });
var getCurrentLocation = createSelector(getTenant, function (tenant) { return tenant.userLocation; });
var getKioskConfig = createSelector(getTenant, function (tenant) { return tenant.kioskConfig; });
var getKioskId = createSelector(getKioskConfig, function (_a) {
    var spaceId = _a.spaceId;
    return spaceId;
});
var getCurrentFloorId = createSelector(getCurrentLocation, function (location) { var _a; return ((_a = location.floor) === null || _a === void 0 ? void 0 : _a.id) || 0; });
var getUserId = createSelector(getTenant, function (tenant) { return tenant.userId; });
var getCurrentTimezone = createSelector(getCurrentLocation, function (currentLocation) { var _a; return ((_a = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.building) === null || _a === void 0 ? void 0 : _a.timeZone) || null; });
var getPastTenants = createSelector(getTenant, function (tenant) { return Object.values(tenant.pastTenants); });
export var tenantSelectors = {
    getTenantId: function (state) { return state.tenant.tenantId; },
    getTenantIdToLogin: function (state) {
        return state.tenant.nextTenantId || state.tenant.tenantId;
    },
    getTheme: function (state) { return state.tenant.theme; },
    getConfig: getConfig,
    getCurrentLocation: getCurrentLocation,
    getCurrentFloorId: getCurrentFloorId,
    getUserId: getUserId,
    getCurrentTimezone: getCurrentTimezone,
    getPastTenants: getPastTenants,
    getKioskConfig: getKioskConfig,
    getKioskId: getKioskId,
};
export default tenant;
