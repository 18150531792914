import { isBefore, setSeconds, setMilliseconds } from 'date-fns';
export var isIntervalBooked = function (startAvailable, bookedIntervals, currentDateInterval) {
    var current = setSeconds(currentDateInterval, 0);
    current = setMilliseconds(current, 0);
    var isBooked;
    if (bookedIntervals.length > 0) {
        isBooked = bookedIntervals.indexOf(current.getTime()) >= 0;
        if (!isBooked && startAvailable) {
            isBooked = isBefore(current, startAvailable);
        }
    }
    else {
        isBooked = isBefore(current, startAvailable);
    }
    return isBooked;
};
