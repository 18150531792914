import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  CONCIERGE_MENU_ANIMATION_DURATION,
  CONCIERGE_MENU_BREAKPOINTS,
} from './constants';

const TRANSITION_DURATION_SECONDS = CONCIERGE_MENU_ANIMATION_DURATION / 1000;
const DARK_THEME_BASE_COLOR = `#000000`;

export const ConciergeMenuWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const SwiperWrapper = styled.div`
  .swiper-container {
    direction: ${({ theme }) => (theme.isLayoutRTL ? 'rtl' : 'unset')};
    height: 100vh;
    width: 100vw;
  }

  .swiper-slide-active,
  .swiper-slide-prev,
  .swiper-slide-next {
    transition-property: height, width, transform !important;
    transition-duration: ${TRANSITION_DURATION_SECONDS}s;
    transform-origin: center center;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: ${({ theme }) => theme.color3};

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      opacity: 1;
      height: 100%;
      background-color: ${({ theme }) => theme.color4};
      transition: opacity, background-color ${TRANSITION_DURATION_SECONDS}s;
    }

    &.swiper-slide-next::before {
      background-color: ${({ theme }) => theme.color6};
    }
  }

  // Dark theme
  &.theme-dark {
    background-color: ${({ theme }) => theme.color6}; // white
    .swiper-slide {
      background: transparent;
      overflow: visible;

      &::before {
        background: ${DARK_THEME_BASE_COLOR}E6; // alpha 90%
        background-blend-mode: multiply;
      }

      &.swiper-slide-next::before {
        background: ${DARK_THEME_BASE_COLOR}CC; // alpha 80%
        background-blend-mode: multiply;
      }
    }
  }

  .swiper-slide-active {
    width: 100%;
    height: 50% !important;
    background-color: ${({ theme }) => theme.color1};

    &::before {
      background-color: #333;
      opacity: 0.5;
    }

    @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
      width: 40% !important;
      height: 100% !important;
    }

    @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL_WIDE}px) {
      width: 50% !important;
      height: 100% !important;
    }
  }

  .swiper-slide-next,
  .swiper-slide-next + .swiper-slide {
    height: 25% !important;
    width: 100%;

    @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
      width: 30% !important;
      height: 100% !important;
    }

    @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL_WIDE}px) {
      width: 25% !important;
      height: 100% !important;
    }
  }
`;

export const ConciergeItem = styled.div`
  display: flex;
  direction: ltr;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0;

  ${({ backgroundImage }) =>
    backgroundImage
      ? `
    background-image: url("${backgroundImage}");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  `
      : ''}

  &:hover {
    cursor: pointer;
  }

  .swiper-slide-active & {
    justify-content: flex-end;
    .theme-dark & {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .swiper-slide-next &,
  .swiper-slide-next + .swiper-slide & {
    .theme-dark & {
      background: transparent;
    }
  }

  @media screen and (min-width: 420px) {
    padding: 40px 0;
  }

  @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
    justify-content: flex-end;
    height: 100vh;
    width: 40vw;
  }

  @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL_WIDE}px) {
    height: 100vh;
    width: 50vw;
  }

  @media screen and (min-width: 1300px) {
    padding: 96px 66px;
  }
`;

export const ConciergeItemTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.typo2};

  .theme-dark & {
    color: ${({ theme }) => theme.typo1};
  }

  .swiper-slide-active & {
    color: ${({ theme }) => theme.typo1};
  }

  @media screen and (min-width: 760px) {
    font-size: 26px;
  }

  @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL_WIDE}px) {
    font-size: 26px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 32px;
  }
`;

export const ConciergeItemDescription = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.typo2};

  .theme-dark & {
    color: ${({ theme }) => theme.typo1};
  }

  .swiper-slide-active & {
    color: ${({ theme }) => theme.typo1};
  }

  @media screen and (min-height: 680px) {
    font-size: 16px;
  }
`;

export const ConciergeItemText = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 20px;

  @media screen and (min-width: 420px) {
    padding: 0 40px;
  }

  @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
    height: 200px;
    width: 25vw;

    .swiper-slide-active & {
      width: 40vw;
    }
  }

  @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL_WIDE}px) {
    .swiper-slide-active & {
      width: 40vw;
    }
  }

  @media screen and (min-width: 1300px) {
    padding: 0 66px;
  }

  // Absolut position of text when breaks horizontal
  @media screen and (max-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
    .swiper-slide-active & {
      .theme-dark & {
        position: absolute;
        top: 40%;
      }
    }
  }
`;

export const ConciergeMenuAction = styled.button`
  display: block;
  appearance: none;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
  // same style as swiper-slide-active
  .theme-dark & {
    width: 100%;
    height: 50% !important;

    @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
      width: 40% !important;
      height: 100% !important;
    }

    @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL_WIDE}px) {
      width: 50% !important;
      height: 100% !important;
    }
  }
`;

export const ConciergeMenuLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ConciergeMenuNavButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color6};
  width: 50px;
  height: 50px;
  border-radius: 50px;
  opacity: 0.25;
  transition: opacity 0.25s;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: 20px;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &.concierge-menu-next {
    /* @noflip */
    right: 20px;
    transform: rotate(90deg);
  }

  &.concierge-menu-prev {
    /* @noflip */
    right: 90px;
    transform: rotate(90deg);
  }

  @media screen and (min-width: ${CONCIERGE_MENU_BREAKPOINTS.HORIZONTAL}px) {
    top: 50%;
    margin-top: -25px;

    &.concierge-menu-prev,
    &.concierge-menu-next {
      transform: none;
    }

    &.concierge-menu-prev {
      /* @noflip */
      left: 20px;
    }
  }
`;

export const ConciergeMenuErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ConciergeMenuErrorButton = styled(Link)`
  display: block;
  text-decoration: none;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.color9};
  color: ${({ theme }) => theme.color4};
  border-radius: 40px;
  cursor: pointer;
`;
