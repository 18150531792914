// TODO: possible duplicate from mobile project
/**
 * Gets the person of interest filter settings based on the menu URL path.
 * @param {Object} action
 * @returns {{isFirstAidOfficer: boolean, isFireWarden: boolean}}
 */
export var getPersonOfInterestVisibility = function (action) {
    var _a;
    var result = { isFireWarden: true, isFirstAidOfficer: true };
    if ((_a = action === null || action === void 0 ? void 0 : action.paths) === null || _a === void 0 ? void 0 : _a.length) {
        var path = action.paths[0];
        if (path === 'fire_warden') {
            result.isFirstAidOfficer = false;
        }
        if (path === 'first_aid_officer') {
            result.isFireWarden = false;
        }
    }
    return result;
};
