var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { format, isAfter, isSameDay, setMilliseconds, setSeconds, } from 'date-fns';
import { getDisabledDaysFromBookingLimit, getDisabledDaysFromBookings, } from '@serraview/engage-shared/utils';
export var getHasBookingsForTimeInterval = function (_a) {
    var bookedIntervals = _a.bookedIntervals, start = _a.start;
    if (!Array.isArray(bookedIntervals)) {
        return false;
    }
    return bookedIntervals.some(function (interval) {
        var intervalStartDate = new Date(interval);
        var startDateZeroed = setMilliseconds(setSeconds(start, 0), 0);
        if (!isSameDay(intervalStartDate, startDateZeroed))
            return false;
        return isAfter(intervalStartDate, startDateZeroed);
    });
};
export var getReserveBtnText = function (_a) {
    var isOccupied = _a.isOccupied, isLoading = _a.isLoading, t = _a.t;
    if (isOccupied) {
        return t('components.reserveButton.reserveSuccess');
    }
    if (isLoading) {
        return t('components.reserveButton.reserveProcessing');
    }
    return t('components.reserveButton.reserve');
};
export var getFailedDaysFromSelected = function (selectedDays, failedDaysMessage) {
    var failedDaysArray = failedDaysMessage.split(', ');
    var selectedDaysFormatted = selectedDays.map(function (day) { return ({
        original: day,
        formatted: format(day, 'EEE'),
    }); });
    // we need to leave failed days in selected array and remove another
    var filteredSelectedDays = selectedDaysFormatted.filter(function (day) {
        return failedDaysArray.includes(day.formatted);
    });
    // we need to return original day values which DaysSelector can use
    return filteredSelectedDays.map(function (day) { return day.original; });
};
export var getDisabledDays = function (_a) {
    var bookings = _a.bookings, futureBookingsLimit = _a.futureBookingsLimit, filterDateStart = _a.filterDateStart, timeZone = _a.timeZone;
    var disabledDaysFromBookings = bookings
        ? getDisabledDaysFromBookings(bookings, filterDateStart)
        : [];
    var disabledDaysFromLimit = getDisabledDaysFromBookingLimit({
        filterDateStart: filterDateStart || new Date(),
        futureBookingsLimit: futureBookingsLimit,
        timeZone: timeZone,
    });
    return __spreadArray(__spreadArray([], disabledDaysFromBookings, true), disabledDaysFromLimit, true);
};
