import React, { memo } from 'react';
import PropTypes from 'prop-types';

function NoPrint({ children }) {
  return <div className="no_print">{children}</div>;
}

NoPrint.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
NoPrint.defaultProps = {};

export default memo(NoPrint);
