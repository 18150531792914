var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  height: 30px;\n  border-radius: 30px;\n  opacity: ", ";\n  background-color: ", ";\n  margin-right: 6px;\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  height: 30px;\n  border-radius: 30px;\n  opacity: ", ";\n  background-color: ", ";\n  margin-right: 6px;\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (props) { return props.opacity; }, function (props) { return props.backgroundColor; });
export var Button = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  &:disabled {\n    opacity: 0.5;\n    cursor: default;\n  }\n"], ["\n  cursor: pointer;\n  &:disabled {\n    opacity: 0.5;\n    cursor: default;\n  }\n"])));
export var ButtonText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 0 25px;\n  & .text {\n    font-size: 12px;\n    line-height: 16px;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 0 25px;\n  & .text {\n    font-size: 12px;\n    line-height: 16px;\n    color: ", ";\n  }\n"])), function (_a) {
    var color = _a.color, theme = _a.theme;
    return color || theme.typo2;
});
var templateObject_1, templateObject_2, templateObject_3;
