var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo, useCallback } from 'react';
import PersonItem from 'components/layouts/GlobalSearch/PersonItem/PersonItem';
import SelectableAvatar from 'components/base/Avatar/SelectableAvatar';
import { SelectableItemWrapper } from 'components/layouts/Team/TeamMembers/TeamMembers.style';
import { getInitials } from '@serraview/engage-shared';
var mapTeamMember = function (item) {
    var id = item.id, displayName = item.displayName, email = item.email, jobTitle = item.jobTitle;
    var initials = getInitials(displayName);
    return {
        id: id,
        name: displayName,
        email: email,
        description: jobTitle,
        initials: initials,
    };
};
var SelectablePersonItem = function (_a) {
    var isSelected = _a.isSelected, onSelect = _a.onSelect, style = _a.style, item = _a.item, rest = __rest(_a, ["isSelected", "onSelect", "style", "item"]);
    var parsedItem = mapTeamMember(item);
    var renderAvatar = useCallback(function (avatarProps) { return (React.createElement(SelectableAvatar, __assign({ a11LabelSuffix: item.displayName, isSelected: isSelected }, avatarProps, { onClick: function () { return onSelect === null || onSelect === void 0 ? void 0 : onSelect(item.id); } }))); }, [isSelected, item.displayName, item.id, onSelect]);
    return (React.createElement(SelectableItemWrapper, { style: style, isSelected: isSelected },
        React.createElement(PersonItem, __assign({ item: parsedItem }, rest, { renderAvatar: renderAvatar }))));
};
var MemoSelectablePersonItem = memo(SelectablePersonItem);
MemoSelectablePersonItem.getSize = PersonItem.getSize;
export default MemoSelectablePersonItem;
