import React, { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Avatar } from 'base-components';
import { useTheme } from 'styled-components';
import { UserWrapper, UserAvatar } from './styled';
var User = function (_a) {
    var user = _a.user, hasUnreadNotifications = _a.hasUnreadNotifications, isLoading = _a.isLoading, isError = _a.isError, onClick = _a.onClick, id = _a.id;
    var color9 = useTheme().color9;
    if (isLoading) {
        return React.createElement(Skeleton, { circle: true, height: 48, width: 48 });
    }
    if (isError) {
        return React.createElement(Avatar, null);
    }
    var firstName = user === null || user === void 0 ? void 0 : user.firstName;
    var lastName = user === null || user === void 0 ? void 0 : user.lastName;
    var imageUrl = user === null || user === void 0 ? void 0 : user.imageUrl;
    return (React.createElement(UserWrapper, { id: id, "data-testid": "user", onClick: onClick },
        React.createElement(UserAvatar, { source: imageUrl, name: "".concat(firstName, " ").concat(lastName), status: color9, badgeVariant: hasUnreadNotifications ? 'dot' : 'standard' })));
};
export default memo(User);
