import {
  createTransformSearchResponse,
  getSearchData,
} from 'api/util/searchData';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { filtersSelectors, tenantSelectors } from 'store';
import { useSearchQuery } from '@serraview/engage-shared/api';
import { useReservationFilters } from 'utils';
import React from 'react';
import { globalSearchSelectors } from 'store/globalSearch';
import { RESTRICT_TO_VALUES } from 'consts';
import { useUser } from 'api/queries/useUser';
import { useRestrictTo } from 'utils/hooks';

export const SEARCH_QUERY_KEY = 'search';

export const useSearch = ({
  showUserFirst = false,
  restrictTo,
  disableSearchString = false,
  enabled = true,
}) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const { capacity, spaceTypes, spaceAttributes } = useSelector(
    filtersSelectors.getFilter,
  );
  const { fireWarden: isFireWarden, firstAidOfficer: isFirstAidOfficer } =
    useSelector(filtersSelectors.getPersonFilters);

  const { startTime, endTime, filterDates } = useReservationFilters(
    restrictTo === RESTRICT_TO_VALUES.DESK,
  );

  const { data: userData } = useUser();
  const user = userData || {};

  const searchString = useSelector(globalSearchSelectors.getGlobalSearchValue);

  let isRefetchEnabled = enabled;
  if (showUserFirst) {
    // wait for the user data to exist and after that transform the response
    isRefetchEnabled = !!user.id;
  }

  const fetchData = getSearchData({
    tenantId,
    floorId,
    isFirstAidOfficer,
    isFireWarden,
    startTime,
    endTime,
    filterDates,
    capacity,
    restrictTo,
    searchString: disableSearchString ? '' : searchString,
    spaceTypes,
    spaceAttributes,
  });

  const queryKey = [
    floorId,
    isFirstAidOfficer,
    isFireWarden,
    startTime,
    endTime,
    capacity,
    restrictTo,
    filterDates,
    searchString,
    spaceTypes,
    spaceAttributes,
  ];

  const result = useSearchQuery(
    {
      queryKey,
      isRefetchEnabled,
      fetchData,
      timeout: 30 * 1000, // P2-2898 Increase timeout from 10s to 30s. May be better to implement exp backoff in the future
    },
    createTransformSearchResponse({
      showUserFirst,
      searchString,
      user,
      filterDates,
      restrictTo,
    }),
  );

  return { ...result, queryKey };
};

export const useRefreshSearch = () => {
  const queryClient = useQueryClient();
  const floorId = useSelector(tenantSelectors.getCurrentFloorId);
  const restrictTo = useRestrictTo();
  const { startTime, endTime, filterDates } = useReservationFilters(
    restrictTo === RESTRICT_TO_VALUES.DESK,
  );

  const invalidateQuery = () =>
    queryClient.invalidateQueries([
      SEARCH_QUERY_KEY,
      floorId,
      startTime,
      endTime,
      restrictTo,
      filterDates,
    ]);

  const refresh = React.useCallback(invalidateQuery, [
    endTime,
    filterDates,
    floorId,
    queryClient,
    restrictTo,
    startTime,
  ]);

  return { refresh };
};
