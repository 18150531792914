import React from 'react';
import Avatar from 'components/base/Avatar/Avatar';
import { isElectron } from 'utils/electron';
import { useTranslation } from 'react-i18next';
import { useUser } from 'api/queries';
import { PersonButtonName, PersonButtonNameWrapper, PersonButtonTitle, PersonButtonWrapper, } from './styled';
function PersonButton(props) {
    var id = props.id, name = props.name, firstName = props.firstName, lastName = props.lastName, _a = props.imageUrl, imageUrl = _a === void 0 ? null : _a, onClick = props.onClick, title = props.title, disabled = props.disabled;
    var _b = useUser(), data = _b.data, isLoading = _b.isLoading;
    var t = useTranslation().t;
    if (isLoading) {
        return null;
    }
    // for kiosks guest equals to current user id
    var isGuest = (data === null || data === void 0 ? void 0 : data.id) === id;
    var fullName;
    var image = imageUrl;
    var icon;
    if (isElectron() && isGuest) {
        fullName = t('layout.reserve.guest');
        image = null;
        icon = 'personNew';
    }
    else {
        fullName = name || "".concat(firstName, " ").concat(lastName);
    }
    var onPersonButtonClick = function () {
        if (onClick && typeof onClick === 'function')
            onClick();
    };
    return (React.createElement(PersonButtonWrapper, null,
        React.createElement(PersonButtonTitle, null, title),
        React.createElement(PersonButtonNameWrapper, { disabled: disabled, onClick: onPersonButtonClick },
            React.createElement(Avatar, { icon: icon, source: image, name: fullName, size: "tiny" }),
            React.createElement(PersonButtonName, { disabled: disabled }, fullName))));
}
export default PersonButton;
