import styled from 'styled-components';
import { Modal } from 'base-components';
import { COMMON_STYLES } from 'consts';

export const CommonModal = styled(Modal)`
  left: auto;
  top: auto;
  right: ${COMMON_STYLES.SPACE}px;
  bottom: ${COMMON_STYLES.SPACE}px;
  min-width: 500px;
  height: calc(100vh - ${2.5 * COMMON_STYLES.SPACE}px);
  transition: width, height, min-width, min-height, bottom,
    right 350ms ease-in-out;
  @media (max-width: 450px) {
    width: 100%;
    bottom: 0;
    left: 0;
    min-width: inherit;
  }
  @media (min-width: 451px) and (max-width: 800px) {
    bottom: 20px;
    right: 20px;
  }
`;

export const AgendaModal = styled(CommonModal)`
  padding: 20px;
`;
