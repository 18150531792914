var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var HEIGHT = 100;
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", "px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n"], ["\n  height: ", "px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n"])), HEIGHT, function (props) { return props.theme.color9; });
var MediaLinkButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 60px;\n  border-radius: 45px;\n  height: 50px;\n  width: 50px;\n  cursor: pointer;\n  color: ", ";\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  margin: 0 60px;\n  border-radius: 45px;\n  height: 50px;\n  width: 50px;\n  cursor: pointer;\n  color: ", ";\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
export { Wrapper, MediaLinkButton };
var templateObject_1, templateObject_2;
