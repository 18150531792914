var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'base-components';
import { getPersonOfInterestLabels, localizePersonPrimaryLocationStatus, getPersonConnectionStatus, } from '@serraview/engage-shared/utils';
import { useSelector } from 'react-redux';
import { floorplanSelectors } from 'store';
import { PATH_SEGMENT } from 'consts';
import { useSetPageTitle } from 'utils/hooks';
import { useNavigate } from 'react-router-dom';
import { usePersonImage } from 'api/queries';
import PersonAvatar from './PersonAvatar';
import { Location, LocationWrapper, MoreLocations, Name, NameWrapper, Officer, OfficerIcon, OfficerRow, TeamText, Text, Wrapper, } from './styled';
import { isDownloadPath, getPersonImageUrl } from './utils';
var Details = function (_a) {
    var _b;
    var details = _a.details;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var imageUrl = details.imageUrl, jobTitle = details.jobTitle, isFirstAidOfficer = details.isFirstAidOfficer, isFireWarden = details.isFireWarden, locationName = details.locationName, sensorStatus = details.sensorStatus, primaryLocation = details.primaryLocation, presenceStatus = details.presenceStatus, personName = details.personName, defaultTeamName = details.teamName, hasMultipleLocations = details.hasMultipleLocations, hoodName = details.hoodName;
    var isDownloadImage = isDownloadPath(imageUrl);
    var personImageQuery = usePersonImage({
        imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : '',
        enabled: isDownloadImage && !!imageUrl,
    });
    var personImageUrl = getPersonImageUrl({
        imageUrl: imageUrl,
        isDownloadImage: isDownloadImage,
        isSuccess: personImageQuery.isSuccess,
        fileImageUrl: (_b = personImageQuery === null || personImageQuery === void 0 ? void 0 : personImageQuery.data) !== null && _b !== void 0 ? _b : null,
    });
    var floorplanZoneId = useSelector(floorplanSelectors.getFloorplanZoneId);
    var personConnectionStatus = getPersonConnectionStatus(details, floorplanZoneId) || presenceStatus;
    var status = localizePersonPrimaryLocationStatus({
        status: primaryLocation.status,
        t: t,
    });
    var teamName = (primaryLocation === null || primaryLocation === void 0 ? void 0 : primaryLocation.teamName) || defaultTeamName;
    var personOfInterestLabels = getPersonOfInterestLabels({
        isFireWarden: isFireWarden,
        isFirstAidOfficer: isFirstAidOfficer,
        t: t,
    });
    var onMoreLocationsClick = function () {
        navigate(PATH_SEGMENT.LOCATIONS, {
            state: {
                person: __assign(__assign({}, details), { name: personName }),
            },
        });
    };
    var renderMoreLocations = function () {
        return hasMultipleLocations ? (React.createElement(MoreLocations, { "data-testid": "more-locations", onClick: onMoreLocationsClick }, t('layout.peopleCard.more'))) : null;
    };
    useSetPageTitle(t('documentTitles.personCard', { name: personName }));
    return (React.createElement(Wrapper, null,
        React.createElement(PersonAvatar, { imageSource: personImageUrl, personName: personName, sensorStatus: sensorStatus, locationStatus: status, presenceStatus: personConnectionStatus }),
        React.createElement(NameWrapper, null,
            React.createElement(Name, null, personName)),
        jobTitle && (React.createElement(Text, { role: "heading", "aria-level": 6 }, jobTitle)),
        teamName && (React.createElement(TeamText, { role: "heading", "aria-level": 6 }, teamName)),
        React.createElement(LocationWrapper, null,
            locationName && (React.createElement(Location, { role: "heading", "aria-level": 6 }, locationName)),
            hoodName && (React.createElement(Location, { role: "heading", "aria-level": 6 }, hoodName)),
            renderMoreLocations()),
        personOfInterestLabels.map(function (_a) {
            var type = _a.type, icon = _a.icon, name = _a.name;
            return (React.createElement(OfficerRow, { key: type },
                React.createElement(OfficerIcon, null,
                    React.createElement(Icon, { name: icon, size: 10 })),
                React.createElement(Officer, null, name)));
        })));
};
export default memo(Details);
