var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var InfoTextWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  min-height: 28px;\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  min-height: 28px;\n"])));
export var InfoText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 10px;\n  font-size: 12px;\n"], ["\n  margin-left: 10px;\n  font-size: 12px;\n"])));
export var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-left: 10px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-left: 10px;\n"])));
export var SkeletonWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n"], ["\n  display: flex;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
