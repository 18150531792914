import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'components/base';
import { generateFloorPath, joinPaths } from 'router/utils';
import { useAppParams } from 'router/hooks';
import { GLOBAL_SEARCH_RESULT_ID, RESTRICT_TO_SEARCH_FILTER_ITEMS, } from 'consts';
import { filtersActions, userConfigSelectors } from 'store';
import { LanguageTypes } from '@serraview/engage-shared';
import { RestrictToItem, RestrictToItemText } from './styled';
export function RestrictToLink(_a) {
    var restrictTo = _a.restrictTo, linkRestrictTo = _a.linkRestrictTo;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var params = useAppParams();
    var _b = RESTRICT_TO_SEARCH_FILTER_ITEMS[linkRestrictTo], iconName = _b.iconName, text = _b.text, path = _b.path;
    // restrict to filter will never be rendered if there is no floor selected
    var floorPath = generateFloorPath(params.floorId);
    var absoluteTo = joinPaths(floorPath, path);
    var isActive = linkRestrictTo === restrictTo;
    // if the link is active we swap the path with the floorplan path to toggle it
    var to = isActive ? floorPath : absoluteTo;
    var handleClick = function () {
        dispatch(filtersActions.clearFilterValues(null));
    };
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var frenchLocales = [
        LanguageTypes.fr,
        LanguageTypes.frFR,
        LanguageTypes.frCA,
    ];
    var isFrench = !!frenchLocales.find(function (lang) { return lang === locale; });
    return (React.createElement(RestrictToItem, { to: to, className: isActive ? 'active' : '', onClick: handleClick, role: "button", "aria-expanded": isActive, "aria-pressed": isActive, "aria-controls": isActive ? "".concat(GLOBAL_SEARCH_RESULT_ID, "_").concat(linkRestrictTo) : undefined },
        React.createElement(Icon, { size: 20, name: iconName }),
        React.createElement(RestrictToItemText, { useSmallFont: isFrench }, t(text))));
}
