var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { useDialog, useFocus, useEscapeListener } from 'utils/hooks';
import { Wrapper, Actions, Message, Title, Button, PrimaryButton, } from './styled';
export function ConfirmationDialog(_a) {
    var title = _a.title, triggerId = _a.triggerId, message = _a.message, _b = _a.hasCancelButton, hasCancelButton = _b === void 0 ? true : _b;
    var t = useTranslation().t;
    var closeDialog = useDialog().closeDialog;
    var confirm = function () { return closeDialog(true); };
    var cancel = function () { return closeDialog(false); };
    useEscapeListener({ onEscapePress: cancel });
    useFocus({ returnFocusTo: triggerId });
    var a11yProps = {
        'aria-labelledby': 'dialog-title',
    };
    if (message) {
        a11yProps['aria-describedby'] = 'dialog-message';
    }
    return (React.createElement(ReactFocusLock, null,
        React.createElement(Wrapper, __assign({ role: "dialog" }, a11yProps),
            React.createElement(Title, { id: "dialog-title" }, title),
            message ? React.createElement(Message, { id: "dialog-message" }, message) : null,
            React.createElement(Actions, { hasCancelButton: hasCancelButton },
                React.createElement(PrimaryButton, { onClick: confirm }, t('common.ok')),
                hasCancelButton ? (React.createElement(Button, { onClick: cancel }, t('common.cancel'))) : null))));
}
