var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var MenuCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 6px;\n  z-index: 1;\n  background-color: #ffffff;\n  box-shadow: -28px 40px 60px rgba(10, 38, 50, 0.25);\n  border-radius: 6px;\n  padding: 80px 24px 24px 45px;\n  display: none;\n  transition: all 300ms ease-in-out;\n\n  &.drawer-menu-enter {\n    display: flex;\n    opacity: 0;\n  }\n  &.drawer-menu-enter-active {\n    opacity: 1;\n  }\n  &.drawer-menu-enter-done {\n    display: flex;\n  }\n  &.drawer-menu-exit {\n    display: flex;\n    opacity: 1;\n  }\n  &.drawer-menu-exit-active {\n    display: flex;\n    opacity: 0;\n  }\n  &.drawer-menu-exit-done {\n    display: none;\n  }\n"], ["\n  position: absolute;\n  top: 0;\n  right: 6px;\n  z-index: 1;\n  background-color: #ffffff;\n  box-shadow: -28px 40px 60px rgba(10, 38, 50, 0.25);\n  border-radius: 6px;\n  padding: 80px 24px 24px 45px;\n  display: none;\n  transition: all 300ms ease-in-out;\n\n  &.drawer-menu-enter {\n    display: flex;\n    opacity: 0;\n  }\n  &.drawer-menu-enter-active {\n    opacity: 1;\n  }\n  &.drawer-menu-enter-done {\n    display: flex;\n  }\n  &.drawer-menu-exit {\n    display: flex;\n    opacity: 1;\n  }\n  &.drawer-menu-exit-active {\n    display: flex;\n    opacity: 0;\n  }\n  &.drawer-menu-exit-done {\n    display: none;\n  }\n"])));
export var MenuList = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 20px;\n  list-style: none;\n  text-align: right;\n"], ["\n  margin-top: 20px;\n  list-style: none;\n  text-align: right;\n"])));
export var MenuCardItem = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 16px;\n  margin: 10px 0;\n\n  a {\n    padding: 0;\n    color: inherit;\n    text-decoration: none;\n\n    :hover {\n      text-decoration: underline;\n    }\n  }\n"], ["\n  color: ", ";\n  font-size: 16px;\n  margin: 10px 0;\n\n  a {\n    padding: 0;\n    color: inherit;\n    text-decoration: none;\n\n    :hover {\n      text-decoration: underline;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var MenuCardAction = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  margin: 10px 0;\n  cursor: pointer;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n\n  :hover {\n    text-decoration: underline;\n  }\n"], ["\n  color: ", ";\n  margin: 10px 0;\n  cursor: pointer;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n\n  :hover {\n    text-decoration: underline;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
