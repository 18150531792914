import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Label as LabelType } from 'floorplan/types';

const DEFAULT_LABEL_SIZE = 16;

const Label = ({
  label,
  lineIndex,
  labelSize,
  lineHeight,
  offsetY,
  maxLabelChars,
  labelBoxColor,
  fillColor,
}) => {
  const labelRef = React.useRef();
  const [textWidth, setTextWidth] = React.useState(null);

  React.useEffect(() => {
    // measure text width
    const textElement = labelRef.current;
    const textElWidth = textElement ? textElement.getBBox().width : 0;
    setTextWidth(textElWidth);
  }, []);

  const setLabelRef = (element) => {
    labelRef.current = element;
  };

  const defaultLabelStyle = () => ({
    fill: fillColor,
    fontSize: labelSize,
  });

  const getLabelStyle = () => {
    const defaultStyle = defaultLabelStyle();
    const labelStyle = label.lines[lineIndex].style;
    const position = { textAnchor: 'middle' };

    return {
      ...defaultStyle,
      ...labelStyle,
      ...position,
    };
  };

  const exceedsMaxLabelChars = () =>
    maxLabelChars && label.lines[lineIndex].text.length > maxLabelChars;

  const getLabelText = () => {
    const ellipsis = '...';
    const labelText = label.lines[lineIndex].text;

    return exceedsMaxLabelChars()
      ? `${labelText.substring(0, maxLabelChars - ellipsis.length)}${ellipsis}`
      : labelText;
  };

  const labelBoxStyle = () => ({
    fill: labelBoxColor || 'white',
    fillOpacity: 0.7,
  });

  const renderLabelBox = (width) => {
    const boxHeight = labelSize + 4;
    return width ? (
      <rect
        x={label.coordinates.x - width / 2}
        y={label.coordinates.y + offsetY - boxHeight / 2}
        rx="3"
        ry="3"
        width={width}
        height={boxHeight}
        style={labelBoxStyle()}
      >
        {exceedsMaxLabelChars() && <title>{label.lines[lineIndex].text}</title>}
      </rect>
    ) : null;
  };

  const renderText = () => {
    const labelSizeOffset = 5 - Math.ceil((DEFAULT_LABEL_SIZE - labelSize) / 4);
    const calcLineHeight = lineHeight || 20 - (DEFAULT_LABEL_SIZE - labelSize);
    const calcOffsetY =
      offsetY ||
      lineIndex * calcLineHeight -
        ((label.lines.length - 1) * calcLineHeight) / 2;
    return (
      <text
        ref={(r) => {
          setLabelRef(r);
        }}
        x={label.coordinates.x}
        y={label.coordinates.y + calcOffsetY + labelSizeOffset}
        style={getLabelStyle()}
      >
        {getLabelText()}
      </text>
    );
  };

  const width = textWidth ? textWidth + 2 : null;

  return (
    <>
      {renderLabelBox(width)}
      {renderText()}
    </>
  );
};

Label.propTypes = {
  label: LabelType.isRequired,
  lineIndex: PropTypes.number.isRequired,
  labelSize: PropTypes.number, // In pixels
  lineHeight: PropTypes.number,
  maxLabelChars: PropTypes.number,
  offsetY: PropTypes.number,
  labelBoxColor: PropTypes.string,
  fillColor: PropTypes.string,
};

Label.defaultProps = {
  labelSize: DEFAULT_LABEL_SIZE,
  lineHeight: null,
  offsetY: null,
  maxLabelChars: null,
  labelBoxColor: '#0D142F',
  fillColor: '#FFFFFF',
};

export default memo(Label);
