import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parsePersonData } from '@serraview/engage-shared';
import { inAppNotificationsActions } from 'store';
import { ROOT_PATH } from 'consts';
import logger from 'log';
import useEmployeeCheck from 'components/scenes/drawer/hooks/useEmployeeCheck';
import { useGenerateCurrentLocationPath } from 'utils';
import { useAppParams } from 'router/hooks';
import { Mask } from 'components/base';
/**
 * Component which provides `person` data based on `employeeId`.
 * Used for rendering person/person locations cards for employee.
 */
export function EmployeeContainer(_a) {
    var render = _a.render;
    var employeeId = useAppParams().employeeId;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var checkEmployeeQuery = useEmployeeCheck().checkEmployeeQuery;
    var currentLocationPath = useGenerateCurrentLocationPath();
    var _b = useState(null), person = _b[0], setPerson = _b[1];
    useEffect(function () {
        if (employeeId) {
            checkEmployeeQuery(employeeId).then(function (_a) {
                var data = _a.data, errorMessage = _a.errorMessage;
                if (data) {
                    setPerson(parsePersonData({ person: data }));
                }
                else if (errorMessage) {
                    logger.error(t(errorMessage))();
                    dispatch(inAppNotificationsActions.addWarningNotification({
                        message: t(errorMessage),
                    }));
                    // reset url
                    navigate(currentLocationPath || ROOT_PATH);
                }
            });
        }
    }, [
        checkEmployeeQuery,
        currentLocationPath,
        dispatch,
        employeeId,
        navigate,
        t,
    ]);
    if (!person) {
        return React.createElement(Mask, { isLoading: true });
    }
    return render(person);
}
