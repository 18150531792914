import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isAfter, subHours } from 'date-fns';
import { getUtcIsoString } from '@serraview/engage-shared';

const initialState = {
  notifications: {},
};

const updateNotification = (notification) => {
  const now = getUtcIsoString();
  const updatedNotification = {
    ...notification,
  };
  updatedNotification.isRead = true;
  updatedNotification.fireDate = now;
  return updatedNotification;
};

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification(state, action) {
      const notification = action.payload;
      const { id } = notification;
      // Update notifications if locale is changed
      const shouldUpdateNotifications =
        !state.notifications[id] ||
        state.notifications[id]?.title !== notification?.title ||
        state.notifications[id]?.localTime !== notification?.localTime;
      if (shouldUpdateNotifications) {
        // do not update existing notifications
        state.notifications[id] = notification;
      }
    },
    updateNotification(state, action) {
      const notification = updateNotification(action.payload);
      const { id } = notification;
      state.notifications[id] = notification;
    },
    cancelNotification(state, action) {
      const { id } = action.payload;
      delete state.notifications[id];
    },
    clear() {
      return { ...initialState };
    },
  },
});

const getAllNotifications = (state) => state.notifications;

/**
 * Notifications with check-in window open or after, until they expire.
 */
const getNotifications = createSelector(
  getAllNotifications,
  ({ notifications }) =>
    Object.values(notifications).filter((notification) => {
      const now = new Date().getTime();
      const { checkInStartDate, expirationDate } = notification;

      let isScheduledNotification = new Date(checkInStartDate).getTime() <= now;

      if (expirationDate) {
        isScheduledNotification =
          isScheduledNotification && new Date(expirationDate).getTime() >= now;
      }
      return isScheduledNotification;
    }),
);

/**
 * Notifications from 2 hours before check-in window open, until they expire.
 */
const getNotificationsToList = createSelector(
  getAllNotifications,
  ({ notifications }) =>
    Object.values(notifications).filter((notification) => {
      const now = new Date().getTime();

      const { checkInStartDate, expirationDate } = notification;

      let isScheduledNotification =
        subHours(new Date(checkInStartDate), 2).getTime() <= now;

      if (expirationDate) {
        isScheduledNotification =
          isScheduledNotification && new Date(expirationDate).getTime() >= now;
      }
      return isScheduledNotification;
    }),
);

const getUnreadNotifications = createSelector(
  getNotifications,
  (notifications) =>
    Object.values(notifications).filter(
      (notification) => !notification?.isRead,
    ),
);

const getHasUnread = createSelector(
  getUnreadNotifications,
  (notifications) => !!notifications.length,
);

const getSortedNotifications = createSelector(
  getNotificationsToList,
  (notifications) =>
    Object.values(notifications).sort((notification1, notification2) =>
      isAfter(
        new Date(notification1?.checkInStartDate),
        new Date(notification2?.checkInStartDate),
      )
        ? -1
        : 1,
    ),
);

export const notificationsSelectors = {
  getNotifications,
  getNotificationsToList,
  getUnreadNotifications,
  getSortedNotifications,
  getHasUnread,
};

export default notifications;
