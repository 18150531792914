import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'components/base';
import { getBadgeA11yLabel } from 'components/layouts/GlobalSearch/utils';
import { badgeColor } from 'components/base/Avatar/config';
import { DeskItem, List } from './styled';
export function DeskList(_a) {
    var desks = _a.desks, desksAvailabilities = _a.desksAvailabilities, onItemClick = _a.onItemClick;
    var t = useTranslation().t;
    var renderDesk = function (desk) {
        var _a;
        var _b = (_a = desksAvailabilities === null || desksAvailabilities === void 0 ? void 0 : desksAvailabilities.find(function (deskAvailability) { return deskAvailability.id === desk.spaceId; })) !== null && _a !== void 0 ? _a : {}, available = _b.available, bookable = _b.bookable;
        var showBadge = !!desksAvailabilities && bookable;
        return (React.createElement(DeskItem, { key: desk.id, onClick: function () { return onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick(desk); }, clickable: !!onItemClick },
            React.createElement(Avatar, { icon: "desk", size: "small", badgeVariant: showBadge ? 'dot' : 'standard', badgeStyle: {
                    backgroundColor: available
                        ? badgeColor.success
                        : badgeColor.warning,
                }, badgeA11yLabel: showBadge ? getBadgeA11yLabel({ t: t, available: available }) : '', style: { background: 'transparent' } }),
            desk.spaceName));
    };
    return React.createElement(List, null, desks.map(renderDesk));
}
