var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Link } from 'react-router-dom';
export var RestrictToSearchFilterWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: ", ";\n  flex-shrink: 0;\n  padding: 16px 40px 8px 40px;\n  margin-bottom: 20px;\n  border-bottom: 1px solid ", ";\n"], ["\n  display: flex;\n  justify-content: ", ";\n  flex-shrink: 0;\n  padding: 16px 40px 8px 40px;\n  margin-bottom: 20px;\n  border-bottom: 1px solid ", ";\n"])), function (_a) {
    var hidePeopleData = _a.hidePeopleData;
    return hidePeopleData ? 'space-around' : 'space-between';
}, function (_a) {
    var theme = _a.theme;
    return theme.color4;
});
export var RestrictToItem = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 5px;\n  opacity: 0.7;\n  color: ", ";\n  text-decoration: none;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n\n  &.active {\n    color: #18224c;\n    opacity: 1;\n\n    &::before {\n      content: ' ';\n      position: absolute;\n      bottom: 0;\n      left: 0;\n      width: 100%;\n      height: 8px;\n      background-color: ", ";\n      top: 56px;\n    }\n  }\n"], ["\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 5px;\n  opacity: 0.7;\n  color: ", ";\n  text-decoration: none;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n\n  &.active {\n    color: #18224c;\n    opacity: 1;\n\n    &::before {\n      content: ' ';\n      position: absolute;\n      bottom: 0;\n      left: 0;\n      width: 100%;\n      height: 8px;\n      background-color: ", ";\n      top: 56px;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
export var RestrictToItemText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 8px;\n  font-size: ", ";\n  font-weight: 600;\n  text-align: center;\n"], ["\n  margin-top: 8px;\n  font-size: ", ";\n  font-weight: 600;\n  text-align: center;\n"])), function (_a) {
    var useSmallFont = _a.useSmallFont;
    return (useSmallFont ? '11px' : '14px');
});
var templateObject_1, templateObject_2, templateObject_3;
