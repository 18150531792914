var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var SelectedDatesText = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 12px;\n  margin-right: 5px;\n"], ["\n  font-size: 12px;\n  margin-right: 5px;\n"])));
export var SelectedDatesWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: 15px;\n  color: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  height: 15px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
var templateObject_1, templateObject_2;
