var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { isLayoutRtlDirection } from 'utils';
export var Icon = memo(function (_a) {
    var name = _a.name, _b = _a.size, size = _b === void 0 ? 30 : _b, className = _a.className, style = _a.style, shouldRotateOnRtl = _a.shouldRotateOnRtl;
    var isLayoutRTL = isLayoutRtlDirection();
    return (React.createElement("svg", __assign({}, (shouldRotateOnRtl && isLayoutRTL && { transform: 'rotate(180)' }), { "aria-hidden": true, focusable: false, "data-testid": "icons-".concat(name, "-svg"), className: className, width: size, height: size, style: __assign({ 
            // flex is set in order to prevent item from shrinking
            // when placed inside flex container
            flex: "0 0 ".concat(size, "px") }, style) }),
        React.createElement("use", { xlinkHref: "#".concat(name) })));
});
