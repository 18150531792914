import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Icon } from '../Icons/Icon';
import { ClearIconWrapper, SearchIconWrapper, StyledSearchInput, SearchWrapper, } from './SearchInput.style';
var SearchInput = function (_a) {
    var _b = _a.defaultValue, defaultValue = _b === void 0 ? '' : _b, setSearchValue = _a.setSearchValue, clearSearchValue = _a.clearSearchValue, searchPlaceholderText = _a.searchPlaceholderText;
    var dispatch = useDispatch();
    var _c = useState(defaultValue), searchQuery = _c[0], setSearchQuery = _c[1];
    var hasSearchValue = searchQuery.trim() !== '';
    var onSearchChange = function (event) {
        setSearchQuery(event.target.value);
    };
    var onSearchClear = function () {
        setSearchQuery('');
    };
    var debouncedSearchQuery = useDebounce(searchQuery, 500)[0];
    useEffect(function () {
        if (debouncedSearchQuery) {
            if (typeof setSearchValue === 'function') {
                setSearchValue(debouncedSearchQuery);
            }
        }
        else if (typeof clearSearchValue === 'function') {
            clearSearchValue();
        }
    }, [setSearchValue, clearSearchValue, debouncedSearchQuery, dispatch]);
    return (React.createElement(SearchWrapper, null,
        React.createElement(SearchIconWrapper, null,
            React.createElement(Icon, { name: "search", size: 12 })),
        React.createElement(StyledSearchInput, { type: "text", "aria-label": searchPlaceholderText, placeholder: searchPlaceholderText, onChange: onSearchChange, value: searchQuery, "data-testid": "search-input" }),
        hasSearchValue && (React.createElement(ClearIconWrapper, { onClick: onSearchClear, "data-testid": "clear-button" },
            React.createElement(Icon, { name: "close", size: 12 })))));
};
export default memo(SearchInput);
