import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { filtersActions, floorplanActions, teamReservationsActions, } from 'store';
import { FocusId, useModal } from 'utils';
import { bookingDetailsButtonStatuses as STATUSES } from 'consts';
import { Button } from '../../../Button';
export function StartCheckIn(_a) {
    var meetingId = _a.meetingId, status = _a.status, startDate = _a.startDate, endDate = _a.endDate, onPress = _a.onPress;
    var dispatch = useDispatch();
    var closeModal = useModal().closeModal;
    var t = useTranslation().t;
    var _b = useTheme(), color6 = _b.color6, color1 = _b.color1;
    var DISPLAY = STATUSES.DISPLAY, DISABLED = STATUSES.DISABLED;
    var _c = status, _d = DISPLAY, display = _c[_d], _e = DISABLED, disabled = _c[_e];
    var onStartCheckInMode = function () {
        closeModal();
        dispatch(teamReservationsActions.setTeamBookingId(meetingId));
        dispatch(floorplanActions.refreshSpace());
        dispatch(floorplanActions.setFocusedSpace(null));
        dispatch(filtersActions.setFilterValues({
            dateStart: startDate,
            dateEnd: endDate,
        }));
        onPress === null || onPress === void 0 ? void 0 : onPress();
    };
    return display ? (React.createElement(Button, { id: FocusId.BookingCheckInButton, onClick: onStartCheckInMode, disabled: disabled, opacity: disabled ? 0.4 : 1, text: t('common.checkIn'), color: color6, backgroundColor: color1 })) : null;
}
