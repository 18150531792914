import React, { useEffect } from 'react';

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

const useWindowDimensions = () => {
  const getDimensions = () => ({
    width: getWidth(),
    height: getHeight(),
    isMobileScreen: getWidth() <= 450,
  });

  const [dimensions, setDimensions] = React.useState(getDimensions);
  // useEffect will execute only once because it does not have any dependencies.
  useEffect(() => {
    // to improve performance add timeoutId for debounce mechanism
    // that limits the rate at which a function can fire
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width and height from the state object after 250 milliseconds
      timeoutId = setTimeout(() => setDimensions(getDimensions()), 250);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return dimensions;
};

export default useWindowDimensions;
