// Connects to outside API, kind of a bad solution but should be good for the purpose.
import { getApiUrl, getHost } from '@serraview/engage-shared/api';

const Api = {
  tenantId: null,
  setTenantId(tenantId) {
    Api.tenantId = tenantId;
  },
  axiosApi: null,
  setAxios(api) {
    Api.axiosApi = api;
  },
  fetch(options) {
    const { url, method, headers, responseType, useOnlyHostForURL } = options;

    return Api.axiosApi({
      url: !useOnlyHostForURL
        ? `${getApiUrl(Api.tenantId)}${url}`
        : `${getHost(Api.tenantId)}${url}`,
      method,
      headers,
      timeout: 30 * 1000,
      responseType,
    }).then((res) => res.data);
  },
};

export default Api;
