import React from 'react';
import { FloorName, FloorsListWrapper, FloorWrapper, LabelFloorAvailability, LabelsContainer, } from 'components/layouts/LocationSelector/components/styled';
import { useTranslation } from 'react-i18next';
import { useAvailableKioskContext } from 'components/kiosk/AvailableKiosks/AvailableKiosksContext';
var AvailableKioskItems = function (_a) {
    var data = _a.data;
    var t = useTranslation().t;
    var onSelectConfig = useAvailableKioskContext().onSelectConfig;
    var renderKioskItem = function (config) {
        var id = config.id, name = config.name;
        return (React.createElement(FloorWrapper, { onClick: function () { return onSelectConfig(config); }, key: "".concat(id, "-").concat(name) },
            React.createElement(FloorName, { "data-testid": "kiosk-list-item" }, name)));
    };
    return (React.createElement(FloorsListWrapper, null,
        React.createElement(LabelsContainer, null,
            React.createElement(LabelFloorAvailability, null, t('components.availableKiosks.kioskName'))),
        data.map(renderKioskItem)));
};
export default AvailableKioskItems;
