import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { stylePropType } from 'utils';
import { Button as StyledButton, ButtonText, Wrapper } from './styled';

const Button = ({ children, text, onClick, backgroundColor, color, style }) => {
  const renderComponent = () => {
    if (children && React.isValidElement(children)) {
      return children;
    }

    if (text) {
      return <ButtonText color={color}>{text}</ButtonText>;
    }

    return null;
  };

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <StyledButton onClick={onClick} style={style}>
        {renderComponent()}
      </StyledButton>
    </Wrapper>
  );
};

Button.propTypes = {
  children: (props, propName, componentName) => {
    if (!props.children && !props.text) {
      return new Error(
        `One of props 'children' or 'text' was not specified in '${componentName}'.`,
      );
    }
    return null;
  },
  text: (props, propName, componentName) => {
    if (!props.children && !props.text) {
      return new Error(
        `One of props 'children' or 'text' was not specified in '${componentName}'.`,
      );
    }
    return null;
  },
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  style: stylePropType,
};
Button.defaultProps = {
  children: null,
  text: null,
  onClick: () => {},
  color: '#000',
  backgroundColor: '#FFF',
  style: {},
};

export default memo(Button);
