import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { floorplanActions, inAppNotificationsActions } from 'store';
import { RESERVE_MESSAGES } from 'consts';
import { useRefreshBookings } from 'api/queries';
export var getMultiDayBookingSuccessMessage = function (_a) {
    var t = _a.t, successfullyReservedDaysMessage = _a.successfullyReservedDaysMessage;
    return t('components.reserveButton.successfulReservationForDays', {
        days: successfullyReservedDaysMessage,
    });
};
export var getMultiDayBookingFailedMessage = function (_a) {
    var t = _a.t, failedDaysMessage = _a.failedDaysMessage, concurrentDaysMessage = _a.concurrentDaysMessage, limitPerIntervalMessage = _a.limitPerIntervalMessage, limitPerBuildingMessage = _a.limitPerBuildingMessage;
    if (concurrentDaysMessage) {
        return "".concat(t('layout.reserve.desk.selectDays', {
            daysFailed: concurrentDaysMessage,
        }), " ").concat(t('layout.reserve.desk.concurrentLimit'));
    }
    if (limitPerIntervalMessage) {
        return "".concat(t('layout.reserve.desk.selectDays', {
            daysFailed: limitPerIntervalMessage,
        }), " ").concat(t('layout.reserve.desk.intervalLimit'));
    }
    if (limitPerBuildingMessage) {
        return "".concat(t('layout.reserve.desk.selectDays', {
            daysFailed: limitPerBuildingMessage,
        }), " ").concat(t('layout.reserve.desk.buildingLimit'));
    }
    return t('layout.reserve.desk.selectDays', {
        daysFailed: failedDaysMessage,
    });
};
export var useReserveCallbacks = function (_a) {
    var spaceItem = _a.spaceItem;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var refreshBookings = useRefreshBookings().refresh;
    var spaceType = spaceItem.type;
    var onSuccessBooking = function () {
        dispatch(inAppNotificationsActions.addSuccessNotification({
            message: t("layout.reserve.".concat(spaceType.toLowerCase(), ".successful")),
        }));
    };
    var onErrorBooking = function (error) {
        var _a, _b, _c;
        // display UI notification
        var status = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status;
        var errorMsg = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error;
        if (Array.isArray(errorMsg) && errorMsg.length > 0) {
            errorMsg = errorMsg[0];
        }
        var message = 
        // @ts-ignore
        status && RESERVE_MESSAGES[spaceType][status]
            ? // @ts-ignore
                RESERVE_MESSAGES[spaceType][status][errorMsg] ||
                    // @ts-ignore
                    RESERVE_MESSAGES[spaceType][status].default
            : // @ts-ignore
                RESERVE_MESSAGES[spaceType].default;
        dispatch(inAppNotificationsActions.addErrorNotification({
            message: t(message),
        }));
    };
    // display success UI notification if at least one successfully reservation was made
    // and display error message with the list of days which couldn't be reserved
    var onSuccessMultiDayBooking = function (_a) {
        var successfullyReserved = _a.successfullyReserved, successfullyReservedDaysMessage = _a.successfullyReservedDaysMessage, failedDaysMessage = _a.failedDaysMessage, concurrentDaysMessage = _a.concurrentDaysMessage, limitPerIntervalMessage = _a.limitPerIntervalMessage, limitPerBuildingMessage = _a.limitPerBuildingMessage;
        // means there are at least one successful reservation
        if (successfullyReserved) {
            dispatch(inAppNotificationsActions.addSuccessNotification({
                message: getMultiDayBookingSuccessMessage({
                    t: t,
                    successfullyReservedDaysMessage: successfullyReservedDaysMessage,
                }),
            }));
        }
        // means there are days failed to reserve
        if (failedDaysMessage ||
            concurrentDaysMessage ||
            limitPerIntervalMessage ||
            limitPerBuildingMessage) {
            dispatch(inAppNotificationsActions.addErrorNotification({
                message: getMultiDayBookingFailedMessage({
                    t: t,
                    failedDaysMessage: failedDaysMessage,
                    concurrentDaysMessage: concurrentDaysMessage,
                    limitPerIntervalMessage: limitPerIntervalMessage,
                    limitPerBuildingMessage: limitPerBuildingMessage,
                }),
            }));
        }
        // refresh bookings
        refreshBookings({ spaceItem: spaceItem });
        // refresh floorplan space data
        dispatch(floorplanActions.refreshSpace());
    };
    return {
        onSuccessBooking: onSuccessBooking,
        onErrorBooking: onErrorBooking,
        onSuccessMultiDayBooking: onSuccessMultiDayBooking,
    };
};
