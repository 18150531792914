import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select/Select';
import { useSelectedStylesTimeSelect } from './styled';

const TimeSelect = ({
  data,
  onChange,
  selectedID,
  type,
  isTimeFormatH12,
  isPickedTimeInPast,
}) => {
  const [activeID, setActiveID] = useState(selectedID);
  const getTimeState = data.find((obj) => obj.value === activeID);
  const [time, setTime] = useState(getTimeState);

  useEffect(() => {
    if (isPickedTimeInPast) {
      const firstAvailableValue = data.find((obj) => !obj.isDisabled);
      onChange(firstAvailableValue);
    }

    if (activeID !== selectedID) {
      setActiveID(selectedID);
    }

    setTime(getTimeState);
  }, [activeID, data, onChange, isPickedTimeInPast, selectedID, getTimeState]);

  const styles = useSelectedStylesTimeSelect(isTimeFormatH12);
  return (
    <Select
      options={data}
      styles={styles}
      classNamePrefix={`time-select-${type}`}
      maxMenuHeight={160}
      isSearchable={false}
      value={time}
      defaultValue={data[0]}
      isOptionDisabled={(option) => option.isDisabled}
      onChange={onChange}
      id={`time-select-${type}`}
      focusLabel={type}
    />
  );
};

TimeSelect.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  selectedID: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isTimeFormatH12: PropTypes.bool,
  isPickedTimeInPast: PropTypes.bool,
};

TimeSelect.defaultProps = {
  isTimeFormatH12: false,
  isPickedTimeInPast: false,
};

export default memo(TimeSelect);
