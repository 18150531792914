import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RESTRICT_TO_VALUES, SPACE_ASSETS_FILTER } from 'consts';
import { useTranslation } from 'react-i18next';
import { FilterSelectorBackdrop, FilterSelectorWrapper } from './styled';
import FilterSelectorButton from './FilterSelectorButton';

const FilterSelector = ({ setFilterType, selectedFilterType, spaceType }) => {
  const { t } = useTranslation();
  const toggleType = (type) => {
    setFilterType(selectedFilterType === type ? null : type);
  };

  return (
    <FilterSelectorWrapper data-testid="filter-selector">
      <FilterSelectorButton
        type={SPACE_ASSETS_FILTER.TYPE}
        selectedType={selectedFilterType}
        spaceType={spaceType}
        onClick={toggleType}
      >
        {t('components.toggle.type')}
      </FilterSelectorButton>
      <FilterSelectorButton
        type={SPACE_ASSETS_FILTER.ATTRIBUTE}
        selectedType={selectedFilterType}
        spaceType={spaceType}
        onClick={toggleType}
      >
        {t('components.toggle.asset')}
      </FilterSelectorButton>
      <FilterSelectorBackdrop
        selected={selectedFilterType}
        data-testid="filter-selector-backdrop"
      />
    </FilterSelectorWrapper>
  );
};

FilterSelector.propTypes = {
  setFilterType: PropTypes.func.isRequired,
  selectedFilterType: PropTypes.string,
  spaceType: PropTypes.oneOf([
    RESTRICT_TO_VALUES.SPACE,
    RESTRICT_TO_VALUES.DESK,
  ]).isRequired,
};

FilterSelector.defaultProps = {
  selectedFilterType: null,
};

export default memo(FilterSelector);
