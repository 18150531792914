import React, { memo } from 'react';
import { ListItemOuterWrapper, ListItemWrapper, Subtitle, SubtitleWrapper, TextWrapper, Title, } from 'components/layouts/GlobalSearch/styled';
import SizeRecognitionWrapper from 'components/layouts/GlobalSearch/SizeRecognitionWrapper';
import Avatar from 'components/base/Avatar/AvatarImage';
import { useTranslation } from 'react-i18next';
var TeamItem = function (_a) {
    var item = _a.item, onPress = _a.onPress, onSizeFound = _a.onSizeFound, style = _a.style, index = _a.index;
    var id = item.id, name = item.name, people = item.people;
    var t = useTranslation().t;
    return (React.createElement(ListItemOuterWrapper, { style: style },
        React.createElement(SizeRecognitionWrapper, { onSizeFound: onSizeFound, index: index },
            React.createElement(ListItemWrapper, { "aria-label": "".concat(t('accessibilityLabels.viewTeamItem'), " ").concat(name), onClick: function () { return onPress(item); } },
                React.createElement(Avatar, { icon: "teams", size: "small", id: id }),
                React.createElement(TextWrapper, null,
                    React.createElement(Title, null, name),
                    React.createElement(SubtitleWrapper, null,
                        React.createElement(Subtitle, null, t('layout.teamsFilter.membersCount', { count: people }))))))));
};
export default memo(TeamItem);
