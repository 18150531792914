var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ToggleSwitch } from 'components/base/ToggleSwitch';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersSelectors, filtersActions } from 'store';
import { useTranslation } from 'react-i18next';
import { PERSON_OF_INTEREST, PERSON_OF_INTEREST_TRANSLATION_MAPPING, } from 'consts';
import { GlobalSearchClear, SelectButton } from 'base-components';
import { FocusId, useCloseFilters, useFocus } from 'utils/hooks';
import { countAppliedPersonFilters } from '@serraview/engage-shared';
import { initialPersonFilterValues } from 'store/filters';
import { PersonFilterWrapper, Wrapper, TitleWrapper } from './styled';
var PersonFilter = function () {
    var personFilters = useSelector(filtersSelectors.getPersonFilters);
    var _a = useState(personFilters), filters = _a[0], setFilters = _a[1];
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var closeFilters = useCloseFilters();
    var filtersChanged = countAppliedPersonFilters(filters) > 0;
    var onShowClick = function () {
        dispatch(filtersActions.setPersonFilters(filters));
        closeFilters();
    };
    var onTogglePersonType = function (pofType) { return function (value) {
        setFilters(function (oldFilters) {
            var _a;
            return (__assign(__assign({}, oldFilters), (_a = {}, _a[pofType] = value, _a)));
        });
    }; };
    var onClearPress = useCallback(function () {
        setFilters(initialPersonFilterValues);
    }, []);
    useFocus({ returnFocusTo: FocusId.GlobalSearchFilterButton });
    return (React.createElement(Wrapper, { "data-testid": "PersonFilterComponent" },
        React.createElement(PersonFilterWrapper, null, Object.values(PERSON_OF_INTEREST).map(function (pof) { return (React.createElement(TitleWrapper, { "data-testid": "titleWrapper-".concat(pof), key: "PersonFilter_switch_".concat(pof) },
            t(PERSON_OF_INTEREST_TRANSLATION_MAPPING[pof]),
            React.createElement(ToggleSwitch, { onChange: onTogglePersonType(pof), checked: filters[pof], "data-testid": "personFilterSwitch-".concat(pof) }))); })),
        React.createElement(GlobalSearchClear, { isFilterChanged: filtersChanged, onPress: onClearPress }),
        React.createElement(SelectButton, { "data-testid": "firstRespondersShowButton", onClick: onShowClick }, t('layout.personFilter.showResults'))));
};
export default memo(PersonFilter);
