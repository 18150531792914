import * as React from 'react';
import BannerNotificationsDemo from './BannerNotificationsDemo';

export const Demo = () => (
  <>
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      data-testid="demo-screen"
    >
      <div style={{ width: '400px' }}>
        <BannerNotificationsDemo />
      </div>
    </div>
  </>
);
