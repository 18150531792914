import HeatmapAPI from 'heatmap.js/build/heatmap';

const defaultConfig = {
  htmlId: 'heatmap-container',
  backgroundColor: 'rgba(0,0,0,0)',
  // the maximum opacity (the value with the highest intensity will have it)
  maxOpacity: 0.5,
  // minimum opacity. any value > 0 will produce no transparent gradient transition
  minOpacity: 0.05,
  useLocalExtrema: false,
};

export default class HeatmapOverlay {
  constructor(viewer, cfg) {
    if (!viewer) {
      console.error('Viewer is missing or not ready');
      return;
    }
    this.viewer = viewer;
    this.cfg = { ...defaultConfig, ...cfg };
    this.initialize(this.cfg);
  }

  initialize = (cfg) => {
    this.cfg = cfg;

    this.container = document.createElement('div');
    this.width = this.viewer.container.clientWidth;
    this.height = this.viewer.container.clientHeight;

    this.container.setAttribute('id', this.cfg.htmlId);
    this.container.style.cssText = `width:${this.width}px;height:${this.height}px;`;

    this.data = [];
    this.max = 1;

    this.cfg.container = this.container;

    this.onAdd();
  };

  destroy = (htmlId) => {
    const overlay = document.getElementById(htmlId || this.cfg.htmlId);
    overlay.remove();
  };

  setData = ({ data, max }) => {
    this.max = max;

    // transform data to latlngs
    let len = data.length;
    const d = [];

    while (len--) {
      const entry = data[len];
      const dataObj = {};
      dataObj.value = entry.value;
      dataObj.x = entry.x;
      dataObj.y = entry.y;
      if (entry.radius) {
        dataObj.radius = entry.radius;
      }
      d.push(dataObj);
    }
    this.data = d;
    this.update();
  };

  update = () => {
    const zoom = this.viewer.viewport.getZoom(true);

    if (this.data.length === 0) {
      return;
    }

    const generatedData = { max: this.max };
    const points = [];
    // iterate through data
    let len = this.data.length;
    let localMax = 0;

    while (len--) {
      const entry = this.data[len];
      const value = entry.value;

      if (value > localMax) {
        localMax = value;
      }

      const viewportPoint = this.viewer.viewport.imageToViewportCoordinates(
        entry.x,
        entry.y,
      );
      const imagePoint = this.viewer.viewport.pixelFromPoint(
        viewportPoint,
        true,
      );

      // ignore outer point
      if (
        imagePoint.x <= 0 ||
        imagePoint.y <= 0 ||
        imagePoint.x >= this.viewer.viewport.getContainerSize().x ||
        imagePoint.y >= this.viewer.viewport.getContainerSize().y
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const point = {
        x: Math.round(imagePoint.x),
        y: Math.round(imagePoint.y),
        value,
      };

      let radius;

      if (entry.radius) {
        radius = entry.radius * zoom;
      } else {
        radius = (this.cfg.radius || 20) * zoom;
      }
      point.radius = radius;
      points.push(point);
    }
    if (this.cfg.useLocalExtrema) {
      generatedData.max = localMax;
    }

    generatedData.data = points;

    this.heatmap.setData(generatedData);
  };

  onAdd = () => {
    this.viewer.canvas.appendChild(this.container);

    this.viewer.addHandler(
      'update-viewport',
      (arg) => {
        arg.userData.draw.call(arg.userData);
      },
      this,
    );

    if (!this.heatmap) {
      this.heatmap = HeatmapAPI.create(this.cfg);
    }
    this.draw();
  };

  draw = () => {
    if (!this.viewer) {
      return;
    }

    this.update();
  };
}
