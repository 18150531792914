import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlatList } from 'base-components';
import { useTranslation } from 'react-i18next';
import { notificationsActions, notificationsSelectors } from 'store';
import { useBookingModal } from 'utils/hooks';
import {
  EmptyList,
  ListWrapper,
  Loader,
  NotificationItemWrapper,
} from './styled';
import NotificationItem from './NotificationItem';
import { getSize } from './Notification/Notification';
import NotificationSkeleton from './Notification/NotificationSkeleton';

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show: showBookingModal } = useBookingModal();

  const notifications = useSelector(
    notificationsSelectors.getSortedNotifications,
  );

  const onSelectNotification = ({ item }) => {
    const {
      event: { spaceId, meetingId },
    } = item;
    dispatch(notificationsActions.updateNotification(item));
    showBookingModal({ spaceId, meetingId });
  };

  const renderLoader = ({ style }) => (
    <Loader style={style}>
      <NotificationSkeleton />
    </Loader>
  );

  const renderItem = ({ index, item, style }) => (
    <NotificationItemWrapper
      style={style}
      data-testid={`Notification-${index}`}
    >
      <NotificationItem
        item={item}
        onSelectNotification={onSelectNotification}
      />
    </NotificationItemWrapper>
  );

  if (!notifications || notifications.length <= 0) {
    return <EmptyList>{t('layout.notifications.noNotifications')}</EmptyList>;
  }

  return (
    <ListWrapper>
      <FlatList
        data={notifications}
        itemCount={notifications.length}
        renderItem={renderItem}
        renderLoader={renderLoader}
        itemSize={getSize()}
      />
    </ListWrapper>
  );
};

export default memo(Notifications);
