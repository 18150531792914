import React, { memo } from 'react';
import { ClientLogo } from 'components/base';
import { KioskCurrentDateTime } from 'components/kiosk';
import { useDialog } from 'utils';
import { FloorplanViewsIcon } from 'components/scenes/modals/components/FloorplanViewsIcon';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import { UserContainer } from './UserContainer';
import { AgendaButton } from './AgendaButton';
import { BackButton } from './BackButton';
import { ExitDeskCheckInButton } from './ExitDeskCheckInButton';
import { DateTimeSelector } from './DateTimeSelector';
import { TeamBookingStartDate } from './TeamBookingStartDate';
import { Center, FloorplanViewsWrapper, Left, Right, SecondLineWrapper, Wrapper, } from './styled';
function Header(_a) {
    var _b = _a.hasDateTime, hasDateTime = _b === void 0 ? true : _b, _c = _a.hasUserButton, hasUserButton = _c === void 0 ? true : _c, _d = _a.hasAgendaButton, hasAgendaButton = _d === void 0 ? true : _d, _e = _a.isDeskCheckInOn, isDeskCheckInOn = _e === void 0 ? false : _e, _f = _a.conciergeMenuAllowed, conciergeMenuAllowed = _f === void 0 ? false : _f, _g = _a.isKiosk, isKiosk = _g === void 0 ? false : _g;
    var isOpen = useDialog().isOpen;
    var hasFloorplanViews = useSelector(userConfigSelectors.getEnableFloorplanViews);
    var getLeftComponent = function () {
        if (isKiosk) {
            if (!conciergeMenuAllowed)
                return null;
            return React.createElement(BackButton, null);
        }
        if (isDeskCheckInOn) {
            return React.createElement(ExitDeskCheckInButton, null);
        }
        return conciergeMenuAllowed ? React.createElement(BackButton, null) : React.createElement(ClientLogo, null);
    };
    var getCenterComponent = function () {
        if (isDeskCheckInOn)
            return React.createElement(TeamBookingStartDate, null);
        return hasDateTime ? React.createElement(DateTimeSelector, null) : null;
    };
    var getRightComponent = function () {
        if (isKiosk) {
            return React.createElement(KioskCurrentDateTime, null);
        }
        return [
            hasAgendaButton ? React.createElement(AgendaButton, { key: "agenda-button" }) : null,
            hasUserButton ? React.createElement(UserContainer, { key: "user-container" }) : null,
        ];
    };
    var getSecondLine = function () {
        if (hasFloorplanViews) {
            return (React.createElement(SecondLineWrapper, null,
                React.createElement(FloorplanViewsWrapper, null,
                    React.createElement(FloorplanViewsIcon, null))));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Wrapper, { isDialogOpen: isOpen, isKiosk: isKiosk, "data-testid": "header" },
            React.createElement(Left, null, getLeftComponent()),
            React.createElement(Center, null, getCenterComponent()),
            React.createElement(Right, null, getRightComponent())),
        getSecondLine()));
}
export default memo(Header);
