import styled from 'styled-components';
import { FixedSizeList, VariableSizeList } from 'react-window';

const StyledFixedList = styled(FixedSizeList)`
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const StyledVariableList = styled(VariableSizeList)`
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  ul {
    margin: 0;
  }
`;

export { StyledFixedList, StyledVariableList };
