var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'base-components';
import SpaceCardSkeleton from './SpaceCard/SpaceCardSkeleton';
import { InnerWrapper, Wrapper } from './styled';
var DaysSelector = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 80%;\n"], ["\n  width: 80%;\n"])));
var Button = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 20px;\n"], ["\n  width: 100%;\n  margin-top: 20px;\n"])));
var ReserveCardSkeleton = function () { return (React.createElement(SkeletonTheme, { style: { width: '100%' } },
    React.createElement(Wrapper, { "data-testid": "reserve-card-skeleton" },
        React.createElement(InnerWrapper, null,
            React.createElement(SpaceCardSkeleton, null)),
        React.createElement(DaysSelector, null,
            React.createElement(Skeleton, { height: 30 })),
        React.createElement(Button, null,
            React.createElement(Skeleton, { height: 60 }))))); };
export default ReserveCardSkeleton;
var templateObject_1, templateObject_2;
