export default {
    LOCATION_SELECTOR: 'locationSelector',
    DESK_LIST: 'deskList',
    SPACE_RESERVE: 'spaceReserve',
    DESK_RESERVE: 'deskReserve',
    PERSON_DETAILS: 'personDetails',
    PERSON_LOCATIONS: 'personLocations',
    RESERVE_ASSIST: 'reserveAssist',
    RESERVEES_LIST: 'reserveesList',
    FIRST_RESPONDERS: 'firstResponders',
    TEAM_RESERVATION_CONTROLS: 'teamReservationControls',
    TEAM_MEMBERS_LIST: 'teamMembersList',
    FLOORPLAN_VIEWS: 'floorplanViews',
};
