var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin: 80px 30px 30px 30px;\n  flex-direction: column;\n  align-items: center;\n  color: ", ";\n"], ["\n  display: flex;\n  flex: 1;\n  margin: 80px 30px 30px 30px;\n  flex-direction: column;\n  align-items: center;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
export var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
export var InstructionsContainer = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  line-height: 30px;\n  margin-top: 10px;\n"], ["\n  line-height: 30px;\n  margin-top: 10px;\n"])));
export var InstructionTitle = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n"], ["\n  font-weight: 600;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
