const AUTH_TOKEN = '@engage-auth-token@';
const REFRESH_TOKEN = '@engage-refresh-token@';

const setTokens = ({ token, refreshToken }) => {
  localStorage.setItem(AUTH_TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

const getTokens = () => ({
  token: localStorage.getItem(AUTH_TOKEN),
  refreshToken: localStorage.getItem(REFRESH_TOKEN),
});

const removeTokens = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

/**
 * The user is considered to be authenticated if there are token and refresh token persisted.
 * @return {boolean}
 */
const hasTokens = () => {
  const tokens = getTokens();
  return !!tokens?.token && !!tokens?.refreshToken;
};

export { setTokens, getTokens, removeTokens, hasTokens };
