var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var LiOption = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var css = _a.css;
    return css;
});
export var UlMenuList = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 6px;\n  padding-left: 0;\n  margin: 0;\n  ", "\n"], ["\n  border-radius: 6px;\n  padding-left: 0;\n  margin: 0;\n  ", "\n"])), function (_a) {
    var css = _a.css;
    return css;
});
export var Wrapper = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n\n  &.keyDown:focus-within {\n    div[class*='control'] {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n    }\n    outline: 0;\n    box-shadow: none;\n  }\n"], ["\n  width: 100%;\n\n  &.keyDown:focus-within {\n    div[class*='control'] {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n    }\n    outline: 0;\n    box-shadow: none;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
