import { DURATION_TYPES } from 'consts';

export default {
  [DURATION_TYPES.MINUTES]: [
    {
      id: 1,
      value: 15,
      text: "15'",
    },
    {
      id: 2,
      value: 30,
      text: "30'",
    },
    {
      id: 3,
      value: 60,
      display: 1,
      type: 'h',
    },
  ],
  [DURATION_TYPES.HOURS]: [
    {
      id: 5,
      value: 60,
      display: 1,
      type: 'h',
    },
    {
      id: 6,
      value: 120,
      display: 2,
      type: 'h',
    },
    {
      id: 7,
      value: 180,
      display: 3,
      type: 'h',
    },
    {
      id: 8,
      value: 240,
      display: 4,
      type: 'h',
    },
  ],
};
