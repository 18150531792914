var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var AlertContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin: 0 30px;\n  padding: 20px 0;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin: 0 30px;\n  padding: 20px 0;\n  justify-content: center;\n  align-items: center;\n"])));
export var Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  text-align: center;\n  font-size: 22px;\n  color: ", ";\n"], ["\n  margin-bottom: 10px;\n  text-align: center;\n  font-size: 22px;\n  color: ", ";\n"])), function (_a) {
    var color = _a.color, theme = _a.theme;
    return color || theme.typo2;
});
export var Subtitle = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 30px;\n  text-align: center;\n  font-size: 17px;\n  color: ", ";\n"], ["\n  margin-bottom: 30px;\n  text-align: center;\n  font-size: 17px;\n  color: ", ";\n"])), function (_a) {
    var color = _a.color, theme = _a.theme;
    return color || theme.typo2;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
