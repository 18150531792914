var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { LiOption } from './Select.style';
var Option = function (props) {
    var children = props.children, label = props.label, isDisabled = props.isDisabled, innerRef = props.innerRef, getValue = props.getValue, innerProps = props.innerProps, getStyles = props.getStyles, cx = props.cx, className = props.className, isFocused = props.isFocused, isSelected = props.isSelected;
    var _a = getValue()[0], _b = _a === void 0 ? {} : _a, _c = _b.id, id = _c === void 0 ? label : _c;
    var styles = getStyles('option', props);
    return (React.createElement(LiOption, __assign({ css: styles, 
        // @ts-ignore
        ref: innerRef, className: cx({
            option: true,
            'option--is-disabled': isDisabled,
            'option--is-focused': isFocused,
            'option--is-selected': isSelected,
        }, className), key: id }, innerProps, { "aria-disabled": isDisabled, "aria-label": label }), children));
};
export default Option;
