import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  * {
    user-select: none;
  }
`;

export const Wrapped = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  cursor: crosshair;
`;
