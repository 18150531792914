var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var FavouritesButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 38px;\n  padding: 0 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  min-width: 20px;\n  min-height: 20px;\n  font-weight: normal;\n  font-size: 14px;\n  cursor: pointer;\n  background-color: ", ";\n  border-color: ", ";\n  border-radius: 19px;\n  border-width: 2px;\n  color: ", ";\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  position: relative;\n  height: 38px;\n  padding: 0 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  min-width: 20px;\n  min-height: 20px;\n  font-weight: normal;\n  font-size: 14px;\n  cursor: pointer;\n  background-color: ", ";\n  border-color: ", ";\n  border-radius: 19px;\n  border-width: 2px;\n  color: ", ";\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme, favouritesActive = _a.favouritesActive;
    return favouritesActive ? theme.color9 : theme.color4;
}, function (_a) {
    var theme = _a.theme, favouritesActive = _a.favouritesActive;
    return favouritesActive ? theme.color9 : theme.color4;
}, function (_a) {
    var theme = _a.theme, favouritesActive = _a.favouritesActive;
    return favouritesActive ? theme.color6 : theme.color1;
});
var templateObject_1;
