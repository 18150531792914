import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Splitter = styled.div`
  background-color: ${({ theme }) => theme.color4};
  height: 60px;
  margin-left: 14px;
  width: 1px;
`;

export const DatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const LineWrapper = styled.div`
  display: flex;
  & .date-card-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    padding-right: 4px;
  }
  & .date-card-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }
`;
