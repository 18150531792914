const LIGHT_BLUE = '#00FFD1';
const DARK_HIGHLIGHT = '#141922';

const defaultConfig = {
  background: {
    color: '#FFFFFF',
  },
  wall: {
    color: '#C5CCD9',
  },
  focusedSpace: {
    color: LIGHT_BLUE,
    opacity: 0.7,
    stroke: {
      color: LIGHT_BLUE,
      opacity: 1,
      width: 7,
    },
  },
  focusedPoint: {
    color: LIGHT_BLUE,
    opacity: 1,
    stroke: {
      color: LIGHT_BLUE,
      opacity: 1,
      width: 7,
    },
  },
  availableSpace: {
    color: '#FF4700',
    opacity: 0.5,
  },
  availablePoint: {
    color: '#FF4700',
    opacity: 0.5,
  },
  unAvailableSpace: {
    color: '#1F3568',
    opacity: 0.4,
  },
  unAvailablePoint: {
    color: '#1F3568',
    opacity: 0.4,
  },
  focusedUnAvailablePoint: {
    color: '#002E5D',
    opacity: 1,
  },
  focusedUnAvailableSpace: {
    color: '#002E5D',
    opacity: 1,
  },
  teamReservationsDisabled: {
    color: '#878d9d',
    opacity: 0.5,
  },
  teamReservationsSelected: {
    color: LIGHT_BLUE,
    opacity: 1,
    stroke: {
      color: LIGHT_BLUE,
      opacity: 1,
      width: 7,
    },
  },
  unImportantSpace: {
    color: '#A9B2C7',
    opacity: 0.3,
  },
  unImportantPoint: {
    color: '#A9B2C7',
    opacity: 0.3,
  },
  pointSizes: {
    important: 50,
    unImportant: 20,
  },
  distanceMarker: {
    color: '#0b8035',
    opacity: 1,
    stroke: { color: 'none', width: 0 },
  },
};
// array of focus-keys used by getStyle
const FOCUSED_KEYS = ['focused', 'teamReservationsSelected'];

const ThemeManager = {
  config: defaultConfig,

  setTheme(theme) {
    const lastConfig = this.config;
    this.config = { ...lastConfig, ...theme };

    if (!this.config.pointSizes) {
      this.config.pointSizes = lastConfig.pointSizes;
    }
    if (!this.config.distanceMarker) {
      // this doesnt work for some reason, thus { ...this.lastConfig, ...theme }
      this.config.distanceMarker = lastConfig.distanceMarker;
    }
  },

  getThemeObject(key) {
    const themeObj = this.config[key];

    if (!themeObj) {
      // eslint-disable-next-line no-console
      console.warn('No object in theme with key: ', key);
      return {};
    }

    return themeObj;
  },

  getStyle(key, isSpace) {
    let themeObj = this.config[key];
    if (isSpace !== undefined) {
      themeObj = this.config[key + (isSpace ? 'Space' : 'Point')];
    }

    // Try to fallback to space if isSpace is undefined and there is no such defined style
    if (!themeObj) {
      themeObj = this.config[`${key}Space`];
    }

    if (!themeObj) {
      // eslint-disable-next-line no-console
      console.warn('No object in theme with key: ', key);
      return {};
    }

    const style = {
      fill: themeObj.color,
    };

    if (themeObj.opacity !== undefined) {
      style.fillOpacity = themeObj.opacity;
    } else {
      style.fillOpaciy = 1;
    }

    if (themeObj.stroke !== undefined) {
      style.stroke = themeObj.stroke.color;

      if (themeObj.stroke.opacity !== undefined) {
        style.strokeOpacity = themeObj.stroke.opacity;
      }

      if (themeObj.stroke.width !== undefined) {
        style.strokeWidth = `${themeObj.stroke.width}`;
      }
    } else {
      style.stroke = 'none';
      style.strokeWidth = 0;
    }

    // force focused elements to have a white border
    if (FOCUSED_KEYS.includes(key)) {
      style.fill = 'black';
      style.fillOpacity = '0.4';
      style.stroke = DARK_HIGHLIGHT;
    }

    return style;
  },
};

export default ThemeManager;
