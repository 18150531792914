import { LanguageTypes } from 'consts';
import { getSupportedLocale } from '@serraview/engage-shared/utils';
var browserLanguage = getSupportedLocale(navigator.language, Object.values(LanguageTypes));
/**
 * Applying dir attribute with the corresponding value.
 * @param {boolean} isLanguageRTL
 * @returns void
 */
var applyLayoutDirection = function (isLanguageRTL) {
    return document
        .getElementsByTagName('body')[0]
        .setAttribute('dir', isLanguageRTL ? 'rtl' : 'ltr');
};
/**
 * Check if body tag have dir attribute with 'rtl' value
 * @returns void
 */
var isLayoutRtlDirection = function () {
    return document.getElementsByTagName('body')[0].getAttribute('dir') === 'rtl';
};
export { browserLanguage, applyLayoutDirection, isLayoutRtlDirection };
