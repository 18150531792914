var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { testProps, useTeamBookingStartDate } from 'utils';
import { DateButtonView } from 'components/base/DateTime/DateButton/DateButtonView';
import { TeamBookingStartDateWrapper } from './styled';
export function TeamBookingStartDate() {
    var t = useTranslation().t;
    var startDate = useTeamBookingStartDate();
    return (React.createElement(TeamBookingStartDateWrapper, null,
        React.createElement(DateButtonView, __assign({ disabled: true, isNow: false }, testProps(t, 'accessibilityLabels.teamBookingStartDate').props, { clearButtonProps: { disabled: true } }), startDate)));
}
