import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  pointer-events: none;
  width: auto;
  height: auto;
  max-width: 100vw;
  max-height: 100vh;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  position: relative;
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};

  // these colors copied from src/components/scenes/drawer/header/InfoCard/styled.js
  // to achieve similar look with drawer buttons
  // this probably needs to be put into theme/reusable component
  background-color: #eaeaea;
  &:hover {
    background-color: #c1c1c1;
  }
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const CloseButtonWrapper = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  align-self: flex-start;
  height: 26px;
  width: 26px;

  @media screen and (min-height: 600px) {
    position: static;
    margin-bottom: 20px;
  }

  @media screen and (min-height: 600px) and (max-width: 860px) {
    margin-left: 20px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;

  @media screen and (min-width: 860px) {
    padding: 0 20px;
  }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  z-index: -1;
`;

export const ErrorMessage = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.typo1};
`;
