import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { AvatarWrapper, NameWrapper, Wrapper } from './styled';
var DetailsSkeleton = function () { return (React.createElement(Wrapper, null,
    React.createElement(AvatarWrapper, null,
        React.createElement(Skeleton, { circle: true, height: 112, width: 112 })),
    React.createElement(NameWrapper, null,
        React.createElement("h5", null,
            React.createElement(Skeleton, { width: 200 }))),
    React.createElement("h5", null,
        React.createElement(Skeleton, { width: 250 })),
    React.createElement("h5", null,
        React.createElement(Skeleton, { width: 300 })))); };
export default DetailsSkeleton;
