import React, { useEffect, useState } from 'react';
import { CHECK_IN_STATUS, getTeamBookingDetailsStatus, } from '@serraview/engage-shared';
import { teamBookingDetailsButtons as BUTTONS } from 'consts';
import { useUser } from 'api/queries';
import { LineWrapper, StatusMessage, } from 'components/layouts/Booking/Card/styled';
import { useCheckInStatus, useConfig } from 'utils';
import { Accept, Decline, Delete, StartCheckIn } from './Buttons';
var NOT_INVITED = 'Not Invited';
export var TeamBookingButtonsBar = function (_a) {
    var booking = _a.booking, attendeeId = _a.attendeeId, onBack = _a.onBack, onShowLocation = _a.onShowLocation;
    var user = useUser().data;
    var userId = user === null || user === void 0 ? void 0 : user.id;
    var meetingId = booking.meetingId, startDate = booking.startDate, endDate = booking.endDate, attendees = booking.attendees, buildingId = booking.location.buildingId;
    var _b = useState(NOT_INVITED), bookingStatus = _b[0], setBookingStatus = _b[1];
    useEffect(function () {
        var _a;
        var currentAttendee = attendees === null || attendees === void 0 ? void 0 : attendees.find(function (attendee) { return attendee.id === userId; });
        setBookingStatus((_a = currentAttendee === null || currentAttendee === void 0 ? void 0 : currentAttendee.status) !== null && _a !== void 0 ? _a : NOT_INVITED);
    }, [attendees, userId]);
    // User can update invitation's status (accept/decline/check-in/check-out) only if it's his booking item.
    // When opened from agenda the attendeeId is available and it checks if the selected item is for current user, not booked for other.
    // When deep linked ensure that user is an attendee.
    var canUpdateStatus = attendeeId
        ? userId === attendeeId
        : bookingStatus !== NOT_INVITED;
    var isBookedForOther = attendeeId
        ? userId !== attendeeId
        : !booking.isOrganizer;
    var config = useConfig({ buildingId: buildingId });
    var buttonStatuses = getTeamBookingDetailsStatus({
        booking: booking,
        config: config,
        isBookedForOther: isBookedForOther,
        canUpdateStatus: canUpdateStatus,
        bookingStatus: bookingStatus,
    });
    var DELETE = BUTTONS.DELETE, CHECK_IN = BUTTONS.CHECK_IN, ACCEPT = BUTTONS.ACCEPT, DECLINE = BUTTONS.DECLINE;
    var statusObj = useCheckInStatus({ booking: booking });
    var showCheckInMessage = !!statusObj.statusMessage && !isBookedForOther;
    return (React.createElement(React.Fragment, null,
        React.createElement(LineWrapper, null,
            React.createElement(Delete, { status: buttonStatuses[DELETE], meetingId: meetingId, onBack: onBack }),
            React.createElement(Accept, { status: buttonStatuses[ACCEPT], meetingId: meetingId, setBookingStatus: setBookingStatus }),
            React.createElement(Decline, { status: buttonStatuses[DECLINE], meetingId: meetingId, setBookingStatus: setBookingStatus, onBack: onBack }),
            React.createElement(StartCheckIn, { onPress: onShowLocation, status: buttonStatuses[CHECK_IN], meetingId: meetingId, startDate: startDate, endDate: endDate })),
        showCheckInMessage && (React.createElement(LineWrapper, { "data-testid": "checkInMessage" },
            React.createElement(StatusMessage, { isClosed: statusObj.status === CHECK_IN_STATUS.CHECK_IN_CLOSED }, statusObj.statusMessage)))));
};
