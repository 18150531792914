import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'components/base';
import { ItemWrapper, TextWrapper } from './SpaceAttributes.style';
var items = new Array(3).fill(null);
export var SpaceAttributesSkeleton = function () {
    var renderItem = function (_, index) { return (React.createElement(ItemWrapper, { key: index },
        React.createElement(TextWrapper, null,
            React.createElement(Skeleton, { width: 200 })))); };
    return (React.createElement(SkeletonTheme, { style: { width: '100%' } }, items.map(renderItem)));
};
