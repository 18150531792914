import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getDayEndTime } from '@serraview/engage-shared/utils';
import { useSetUserConfig, hashString } from '@serraview/engage-shared';
import { Acknowledgement } from 'layouts';
import Analytics from 'analytics';
import { ROOT_PATH } from 'consts';
import { useAppLocation } from 'router/hooks';

export const AcknowledgementRouter = () => {
  const navigate = useNavigate();
  const { search } = useAppLocation();
  const { setUserConfig } = useSetUserConfig({
    onSettled: () => {
      navigate({
        pathname: ROOT_PATH,
        search,
      });
    },
  });

  const onAcceptOrganizationPolicy = ({
    acknowledgementMessage,
    acknowledgementUrl,
    acknowledgementTitle,
  }) => {
    setUserConfig({
      acknowledgementContentHash: hashString(
        JSON.stringify({
          acknowledgementMessage,
          acknowledgementUrl,
          acknowledgementTitle,
        }),
      ),
      acknowledgementConfirmedAt: getDayEndTime({
        date: new Date(),
      }).toString(),
    });

    Analytics.track('EVT-085');
  };

  return <Acknowledgement onAccept={onAcceptOrganizationPolicy} />;
};
