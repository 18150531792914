import styled from 'styled-components';
import { LIST_ITEM_HEIGHT } from 'layouts/Agenda/content/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => `${props.theme.color4}80`}; // alpha 50%
  border-radius: 6px;
  height: ${LIST_ITEM_HEIGHT}px;
  width: ${LIST_ITEM_HEIGHT}px;
  padding: 8px 16px;
`;

const Day = styled.div`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) =>
    props.highlight ? props.theme.color9 : props.theme.color2};
`;

const DayOfWeek = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${(props) =>
    props.highlight ? props.theme.color9 : props.theme.color1};
  opacity: ${(props) => (props.highlight ? 1 : 0.7)};
  min-width: 45px;
`;

export { Wrapper, Day, DayOfWeek };
