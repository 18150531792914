import userConfig from 'store/userConfig';
import { tenantSelectors } from '../tenant';

const userConfigMiddleware = (store) => (next) => (action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case userConfig.actions.setShowSocialDistancingInfo.type:
    case userConfig.actions.setUiLanguage.type: {
      const state = store.getState();
      const tenantId = tenantSelectors.getTenantId(state);
      const userId = tenantSelectors.getUserId(state);

      next({ type, payload: { value: payload, tenantId, userId } });
      break;
    }
    case userConfig.actions.setEnableFloorplanViews.type: {
      const state = store.getState();
      const tenantId = tenantSelectors.getTenantId(state);
      const userId = tenantSelectors.getUserId(state);

      next({ type, payload: { value: payload, tenantId, userId } });
      break;
    }
    default:
      return next(action);
  }
  return next(action);
};

export default userConfigMiddleware;
