var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useEffect } from 'react';
import Analytics from 'analytics';
import { isElectron } from 'utils/electron';
import MediaLinks from './MediaLinks/MediaLinks';
import Details from './Details/Details';
import { Wrapper } from './styled';
var PersonCard = function (_a) {
    var person = _a.person, _b = _a.locationName, locationName = _b === void 0 ? null : _b, _c = _a.hoodName, hoodName = _c === void 0 ? null : _c, _d = _a.sensorStatus, sensorStatus = _d === void 0 ? null : _d;
    var details = __assign(__assign({}, person), { locationName: locationName, hoodName: hoodName, sensorStatus: sensorStatus });
    var email = person.email, chatLink = person.chatLink;
    var isKiosk = isElectron();
    useEffect(function () {
        Analytics.track('EVT-048');
        return function () {
            Analytics.track('EVT-052');
        };
    }, [person]);
    return (React.createElement(Wrapper, null,
        React.createElement(Details, { details: details }),
        !isKiosk && React.createElement(MediaLinks, { email: email, chatLink: chatLink })));
};
export default memo(PersonCard);
