import { useDispatch, useSelector } from 'react-redux';
import { tenantSelectors, userConfigSelectors } from 'store';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'api/queries/useUser';
import { useQuery } from 'react-query';
import { CONCIERGE_MENU_QUERY_KEY } from 'consts';
import { fetchMenuItems } from '@serraview/engage-shared/api';
import { isElectron } from 'utils/electron';
import { MENU_ITEM_TYPES } from '@serraview/engage-shared/constants';
import { transformMenuItems } from './transformMenuItems';
var getBuildingIdKey = function (buildingId) {
    return buildingId ? "".concat(buildingId) : 'no-bl';
};
export var useMenuItems = function () {
    var _a;
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var isKiosk = isElectron();
    var userData = useUser().data;
    var currentLocation = useSelector(tenantSelectors.getCurrentLocation);
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var buildingId = (_a = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.building) === null || _a === void 0 ? void 0 : _a.id;
    var heatmapKiosk = useSelector(tenantSelectors.getKioskConfig).heatmapKiosk;
    var getMenuItemsFetchFn = function (_a) {
        var tenantId = _a.tenantId, locale = _a.locale, buildingId = _a.buildingId;
        return function () {
            var type = isKiosk ? MENU_ITEM_TYPES.KIOSK : MENU_ITEM_TYPES.WEB;
            return typeof tenantId === 'undefined'
                ? Promise.reject(new Error('Invalid tenantId'))
                : fetchMenuItems({ tenantId: tenantId, locale: locale, buildingId: buildingId, type: type }).then(menuItemsReducer);
        };
    };
    var menuItemsReducer = function (_a) {
        var data = _a.data;
        return transformMenuItems({
            menuItems: data,
            navigate: navigate,
            dispatch: dispatch,
            currentFloorId: currentFloorId,
            email: userData === null || userData === void 0 ? void 0 : userData.email,
            heatmapKiosk: heatmapKiosk,
        });
    };
    return useQuery([CONCIERGE_MENU_QUERY_KEY, tenantId, locale, getBuildingIdKey(buildingId)], getMenuItemsFetchFn({
        tenantId: tenantId,
        locale: locale,
        buildingId: buildingId,
    }), { enabled: !!tenantId && !!(userData === null || userData === void 0 ? void 0 : userData.id) });
};
