import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icons/Icon';
import { Wrapper } from './styled';

const IconButton = ({ icon, onPress, ...rest }) => (
  <Wrapper onClick={onPress} {...rest}>
    <Icon name={icon} />
  </Wrapper>
);

IconButton.displayName = 'IconButton';
IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default React.memo(IconButton);
