import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Wrapper } from 'scenes/Main/styled';
import { tenantSelectors } from 'store';
import Floorplan from 'floorplan';
import { NoLocationBackground } from 'layouts';
import { Modals } from 'scenes/modals';
import { useInitNotifications } from 'utils';
import { useAppAccess } from 'utils/hooks/useAppAccess';
import { CornerGradient } from 'components/base';
import { useShowSocialDistancingInfo } from 'scenes/Main/hooks';
import {
  useBookingsAllowedNotification,
  useSpaceAvailabilityNotification,
} from 'utils/hooks';
import useIdleDialog from 'utils/hooks/useIdleDialog';

const Main = () => {
  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  // it's unlikely that there will be no location when this component is rendered,
  // but I'll leave it here for now as a precaution
  const hasLocation = !!(building && floor);

  useIdleDialog();

  // Initialise notifications
  useInitNotifications();
  // Check if user has access to the app
  useAppAccess();
  // Show social distancing info if needed
  useShowSocialDistancingInfo();
  // Notify user when there is no space available on the floorplan.
  useSpaceAvailabilityNotification();
  // Notify user when bookings are not allowed for him
  useBookingsAllowedNotification({
    notificationAllowed: hasLocation,
  });

  return (
    <Wrapper data-testid="main-screen">
      <CornerGradient />
      <Modals />
      {hasLocation ? <Floorplan /> : <NoLocationBackground />}
      {/* Drawer is rendered in this outlet */}
      <Outlet />
    </Wrapper>
  );
};

export default Main;
