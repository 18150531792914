import { pipe } from 'utils';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared/constants';
import { PATH_SEGMENT } from 'consts';

export const SECTION_HEADER_ITEM_TYPE = 'sectionHeader';

export const appendData = (data) =>
  data.reduce((acc, item) => {
    acc.push(...item.data);
    return acc;
  }, []);

export const collectSectionsByLocation = (data) => {
  const favourites = data[0];
  const suggested = data[1];
  const suggestedSections = suggested?.data.reduce((sections, item) => {
    const statusObj = {
      statusTitle: suggested.statusTitle,
      isSuggested: !suggested.isFavourite,
    };
    const locationObj = {
      title:
        item.grouping === 'building' || item.grouping === 'floor'
          ? ''
          : item.location?.building,
      subtitle: item.grouping === 'floor' ? '' : item.location.level,
    };
    const index = sections.findIndex(
      (el) =>
        el.title === locationObj.title && el.subtitle === locationObj.subtitle,
    );
    if (index >= 0) {
      const newSectionData = [...sections[index].data, item];
      return [
        ...sections.slice(0, index),
        { ...sections[index], data: newSectionData },
        ...sections.slice(index + 1),
      ];
    }
    return [...sections, { ...locationObj, ...statusObj, data: [item] }];
  }, []);

  return [favourites, ...suggestedSections];
};

export const collectSectionsByTeam = (data) => {
  const initialSections = [
    { title: 'layout.teamsFilter.myTeams', data: [] },
    { title: 'layout.globalSearch.suggested', data: [] },
  ];

  return data
    .reduce((sections, item) => {
      const sectionIndex = item.isMine ? 0 : 1;
      sections[sectionIndex].data.push(item);
      return sections;
    }, initialSections)
    .filter((section) => section.data.length > 0);
};

export const collectSectionsByStatus = (data) => {
  const favourites = data.filter((item) => item.isFavourite);
  const suggested = data.filter((item) => !item.isFavourite);

  return [
    {
      statusTitle: favourites.length ? 'layout.globalSearch.favourites' : '',
      data: favourites,
      isFavourite: true,
    },
    {
      statusTitle: suggested.length ? 'layout.globalSearch.suggested' : '',
      data: suggested,
    },
  ];
};

export const flattenSections = (sections) => {
  let suggestedStatusTitleIsShown = false;
  let favouritesStatusTitleIsShown = false;

  return sections
    .map((section) => {
      if (!section.title && !section.subtitle && !section.statusTitle) {
        return [...section.data];
      }

      const itemTitles = {
        type: SECTION_HEADER_ITEM_TYPE,
        title: section.title,
        subtitle: section.subtitle,
        isTranslatable: section.isTranslatable,
      };

      const itemsWithStatusTitle = [
        {
          ...itemTitles,
          statusTitle: section.statusTitle,
        },
        ...section.data,
      ];

      if (!favouritesStatusTitleIsShown && section.isFavourite) {
        favouritesStatusTitleIsShown = true;
        return itemsWithStatusTitle;
      }

      if (!suggestedStatusTitleIsShown && !section.isFavourite) {
        suggestedStatusTitleIsShown = true;
        return itemsWithStatusTitle;
      }

      return [itemTitles, ...section.data];
    })
    .flat();
};

export const addFavoritePeopleToTeamsList =
  ({ favPeopleLoading, favPeopleCount, favPeopleTeamName }) =>
  (data) => {
    const teamsList = data[0]?.data;
    if (!teamsList || favPeopleLoading || favPeopleCount === 0) return data;
    if (teamsList.find((page) => page.name === favPeopleTeamName)) return data;

    teamsList.push({
      id: PATH_SEGMENT.FAV_PEOPLE_TEAM,
      name: favPeopleTeamName,
      people: favPeopleCount,
      type: RESTRICT_TO_VALUES.TEAM,
      isMine: true,
      favPeopleTeam: true,
    });

    return data;
  };

export const composeItems = appendData;

export const composeItemsWithSections = pipe(
  composeItems,
  collectSectionsByStatus,
  collectSectionsByLocation,
  flattenSections,
);

export const composeItemsWithPeopleSections = pipe(
  composeItems,
  collectSectionsByStatus,
  flattenSections,
);

export const composeItemsWithTeamSections = pipe(
  composeItems,
  collectSectionsByTeam,
  flattenSections,
);

export const getBadgeA11yLabel = ({ t, available }) =>
  available
    ? t('accessibilityLabels.available')
    : t('accessibilityLabels.notAvailable');
