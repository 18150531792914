var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ListWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: 500px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 0 30px;\n"], ["\n  max-height: 500px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding: 0 30px;\n"])));
export var SkeletonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 30px;\n"], ["\n  padding: 0 30px;\n"])));
export var SkeletonItemWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 56px;\n  padding: 0 7px;\n  border-bottom: 1px solid ", ";\n  display: flex;\n  align-items: center;\n\n  > span:first-of-type {\n    margin-right: 10px;\n  }\n"], ["\n  height: 56px;\n  padding: 0 7px;\n  border-bottom: 1px solid ", ";\n  display: flex;\n  align-items: center;\n\n  > span:first-of-type {\n    margin-right: 10px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color4;
});
var templateObject_1, templateObject_2, templateObject_3;
