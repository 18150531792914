import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tenantActions, tenantSelectors } from 'store';
import { Mask } from 'base-components';
import { ROOT_PATH } from 'consts';
import { useGenerateCurrentLocationPath } from 'utils/hooks';
import { Main } from 'scenes/Main';
import {
  mapLocationBuildingData,
  mapLocationFloorData,
} from '@serraview/engage-shared/utils';
import { useFloorFetch } from '@serraview/engage-shared/api';
import { useAppParams } from 'router/hooks';
import { useFloorCheck } from '../hooks/useFloorCheck';

/**
 * Check location path '/floor/:floorId' is a correct location.
 * A correct location is when floor exists in a building.
 *
 * This effect is triggered when user has no current location
 * or user current location exists and the floor location url is different.
 * In both cases, the current location is updated.
 *
 * Until floor location is checked and set on the UI the loader spinner is displayed.
 * After the location is set the router routes to Main.
 *
 * If error occurs when setting the location the router redirects to user current location or root path.
 */
export const FloorCheckRouter = () => {
  const dispatch = useDispatch();

  const currentLocationPath = useGenerateCurrentLocationPath();
  const newLocation = useAppParams();
  const navigate = useNavigate();
  const { checkLocationQuery, sameLocation } = useFloorCheck();
  const { fetchFloor } = useFloorFetch();

  const existingLocation = useSelector(tenantSelectors.getCurrentLocation);
  const { building, floor } = existingLocation;
  const hasLocation = !!(building && floor);

  const locationUrlExists = !!newLocation.floorId;

  const locationChanged =
    locationUrlExists &&
    (!hasLocation ||
      (hasLocation && !sameLocation({ newLocation, existingLocation })));

  const setNewUserLocation = React.useCallback(async () => {
    const { building, floor, errorMessage } = await checkLocationQuery({
      newLocation,
    });
    if (building && floor) {
      // get floor name for InfoCard
      const floorData = await fetchFloor({
        buildingId: building.id,
        floorId: floor.id,
      });

      dispatch(
        tenantActions.setCurrentLocation({
          building: mapLocationBuildingData(building),
          floor: mapLocationFloorData(floorData ?? floor),
        }),
      );
    } else if (errorMessage) {
      // reset url to current location or root path
      navigate(currentLocationPath || ROOT_PATH, { replace: true });
    }
  }, [
    checkLocationQuery,
    currentLocationPath,
    dispatch,
    fetchFloor,
    navigate,
    newLocation,
  ]);

  React.useEffect(() => {
    if (locationChanged) {
      setNewUserLocation();
    }
  }, [locationChanged, setNewUserLocation]);

  if (locationChanged) {
    return <Mask isLoading />;
  }

  return <Main />;
};
