import { useSearchParams } from 'react-router-dom';
import { BOOKING_TYPE } from '@serraview/engage-shared/constants';
import { SEARCH_QUERY } from 'consts';

export const useBookingModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const show = ({ spaceId, meetingId, bookingType, isDesk, reservee }) => {
    if (bookingType === BOOKING_TYPE.TEAM && !isDesk) {
      setSearchParams({
        [SEARCH_QUERY.TEAM_BOOKING_MEETING]: meetingId,
        [SEARCH_QUERY.ATTENDEE_ID]: reservee,
      });
    } else {
      setSearchParams({
        [SEARCH_QUERY.BOOKING_SPACE]: spaceId,
        [SEARCH_QUERY.BOOKING_MEETING]: meetingId,
      });
    }
  };

  const close = () => {
    searchParams.delete(SEARCH_QUERY.BOOKING_SPACE);
    searchParams.delete(SEARCH_QUERY.BOOKING_MEETING);
    searchParams.delete(SEARCH_QUERY.TEAM_BOOKING_MEETING);
    searchParams.delete(SEARCH_QUERY.ATTENDEE_ID);

    setSearchParams(searchParams);
  };

  return { show, close };
};
