var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var StyledSearchInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  height: 40px;\n  border-radius: 5px;\n  width: 100%;\n  padding: 0 29px;\n  &:placeholder-shown {\n    text-overflow: ellipsis;\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n  ::placeholder,\n  ::-webkit-input-placeholder {\n    color: ", ";\n    opacity: 0.7;\n  }\n  :-ms-input-placeholder {\n    color: ", ";\n    opacity: 0.7;\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  height: 40px;\n  border-radius: 5px;\n  width: 100%;\n  padding: 0 29px;\n  &:placeholder-shown {\n    text-overflow: ellipsis;\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n  ::placeholder,\n  ::-webkit-input-placeholder {\n    color: ", ";\n    opacity: 0.7;\n  }\n  :-ms-input-placeholder {\n    color: ", ";\n    opacity: 0.7;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color4;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var SearchWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  margin: 0 33px;\n"], ["\n  position: relative;\n  margin: 0 33px;\n"])));
export var SearchIconWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: 10px;\n  top: 10px;\n"], ["\n  position: absolute;\n  left: 10px;\n  top: 10px;\n"])));
export var ClearIconWrapper = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
