import React from 'react';
import { floorplan, heatmap } from 'images';
import { useTranslation } from 'react-i18next';
import { tenantSelectors, uiActions, uiSelectors } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { generateFloorPath, joinPaths } from 'router/utils';
import { useNavigate } from 'react-router-dom';
import { FLOORPLAN_VIEWS, PATH_SEGMENT } from 'consts';
import { ButtonsWrapper, FloorplanView, FloorplanViewText, PNGImage, Wrapper, } from './styled';
export var FloorplanViews = function () {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
    var activeView = useSelector(uiSelectors.getFloorplanView);
    var floorplanLabel = t('layout.floorplanViews.floorplan');
    var heatmapLabel = t('layout.floorplanViews.heatmap');
    var floorplanImageAlt = t('layout.floorplanViews.floorplanImage');
    var heatmapImageAlt = t('layout.floorplanViews.heatmapImage');
    var onSelectFloorplan = function () {
        dispatch(uiActions.setFloorplanView(FLOORPLAN_VIEWS.FLOORPLAN));
        navigate({
            pathname: generateFloorPath(currentFloorId),
        }, { replace: true });
    };
    var onSelectHeatmap = function () {
        dispatch(uiActions.setFloorplanView(FLOORPLAN_VIEWS.HEATMAP));
        navigate(joinPaths(generateFloorPath(currentFloorId), PATH_SEGMENT.HEATMAP), { replace: true });
    };
    return (React.createElement(Wrapper, { "data-testid": "floorplan-views-modal" },
        React.createElement(ButtonsWrapper, null,
            React.createElement(FloorplanView, { onClick: onSelectFloorplan },
                React.createElement(PNGImage, { draggable: false, src: floorplan, alt: floorplanImageAlt, isActive: activeView === FLOORPLAN_VIEWS.FLOORPLAN }),
                React.createElement(FloorplanViewText, { isActive: activeView === FLOORPLAN_VIEWS.FLOORPLAN }, floorplanLabel)),
            React.createElement(FloorplanView, { onClick: onSelectHeatmap },
                React.createElement(PNGImage, { draggable: false, src: heatmap, alt: heatmapImageAlt, isActive: activeView === FLOORPLAN_VIEWS.HEATMAP }),
                React.createElement(FloorplanViewText, { isActive: activeView === FLOORPLAN_VIEWS.HEATMAP }, heatmapLabel)))));
};
