import { ATTENDEE_STATUS } from 'consts';
import { badgeColor, STATUS } from 'components/base/Avatar/config';
export var getAttendeeStatus = function (_a) {
    var status = _a.status, theme = _a.theme;
    var typo1 = theme.typo1, typo2 = theme.typo2;
    var iconName;
    var iconBackgroundColor = badgeColor.primary;
    var iconColor = typo1;
    var avatarStatus = STATUS.PRIMARY;
    switch (status) {
        case ATTENDEE_STATUS.ACCEPTED: {
            iconName = 'check';
            avatarStatus = STATUS.SUCCESS;
            iconBackgroundColor = badgeColor.success;
            break;
        }
        case ATTENDEE_STATUS.DECLINED: {
            iconName = 'times';
            avatarStatus = STATUS.ERROR;
            iconBackgroundColor = badgeColor.error;
            break;
        }
        case ATTENDEE_STATUS.TENTATIVE: {
            iconName = 'question-solid';
            iconBackgroundColor = badgeColor.primary;
            avatarStatus = STATUS.WARNING;
            iconColor = typo2;
            break;
        }
        default: {
            iconName = 'check';
        }
    }
    return {
        iconName: iconName,
        iconColor: iconColor,
        iconBackgroundColor: iconBackgroundColor,
        avatarStatus: avatarStatus,
    };
};
