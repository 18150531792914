import React from 'react';
import { Text } from 'components/base/Text/Text';
import { InitialsWrapper } from './styled';
var Initials = function (_a) {
    var children = _a.children, style = _a.style, _b = _a.textColor, textColor = _b === void 0 ? '#fff' : _b, _c = _a.textSize, textSize = _c === void 0 ? 12 : _c, _d = _a.className, className = _d === void 0 ? '' : _d;
    return (React.createElement(InitialsWrapper, { style: style, textColor: textColor, "data-testid": "avatarInitials", className: "".concat(className, " avatar__initials") },
        React.createElement(Text
        // @ts-ignore
        , { 
            // @ts-ignore
            style: {
                color: textColor,
                fontSize: textSize,
                fontWeight: 'bold',
                textAlign: 'center',
            }, "data-testid": "avatarInitialsText" }, children)));
};
export default Initials;
