var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Icon } from 'components/base';
import { AnimatedHeight } from 'components/scenes/drawer/AnimatedHeight';
import { Section, SectionTitle } from '../Card/styled';
import { DeskList } from './DeskList';
import { Summary, Details } from './styled';
export function CollapsibleDeskList(_a) {
    var desks = _a.desks;
    var detailsProps = {};
    if (desks.length <= 5) {
        detailsProps.open = true;
    }
    return (React.createElement(Section, null,
        React.createElement(AnimatedHeight, { duration: 200 },
            React.createElement(Details, __assign({}, detailsProps),
                React.createElement(Summary, null,
                    React.createElement(SectionTitle, null, "Desks"),
                    React.createElement(Icon, { name: "chevron-right", size: 10 })),
                React.createElement(DeskList, { desks: desks })))));
}
