import styled from 'styled-components';
import { CalendarDate } from '../../CalendarDate/CalendarDate';

export const DaysWrapper = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DaysLegend = styled.legend`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  padding: 0 !important;
  border: 0 !important;
  white-space: nowrap !important;
  clip: rect(1px 1px 1px 1px) !important; /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
`;

export const DayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 16px;

  & input {
    position: absolute;
    top: -20px;
  }

  & label {
    color: ${({ isSelected, theme }) => (isSelected ? '#ffffff' : theme.typo2)};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
    border: ${({ isCurrentDate, theme }) =>
      isCurrentDate ? `2px solid ${theme.typo2}` : 'none'};
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.color9 : theme.color4};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: ${({ isCurrentDate }) => (isCurrentDate ? '28px' : '32px')};
    padding: 0;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    user-select: none;
  }
  :focus-within {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

// Hack for right aligning formatted selected days string and days selector
export const DaysSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectedDates = styled(CalendarDate)`
  cursor: pointer;
  min-height: 15px;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const SelectedDatesWrapper = styled.div`
  margin-top: 15px;
  height: 30px;
  display: flex;
  justify-content: flex-end;
`;
