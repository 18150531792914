import React, { memo } from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  StyleSheetManager,
} from 'styled-components';
import PropTypes from 'prop-types';
import { getDefaultTheme } from '@serraview/engage-shared/theme';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions, tenantSelectors, userConfigSelectors } from 'store';
import { useTenantFetch } from '@serraview/engage-shared/api';
import { isLayoutRtlDirection, isObjectEmpty } from 'utils';
import log from 'log';
import { useQueryClient } from 'react-query';
import {
  CLIENT_LOGO_KEY,
  fetchClientLogo,
} from 'components/base/ClientLogo/useClientLogo';
import rtlPlugin from 'stylis-plugin-rtl';
import { useInitLangFromLocalStore } from 'utils/hooks';

/**
 * The ThemeProvider applies the Styled Components theme.
 * The tenant config data is fetched on app start.
 * The ThemeProvider acts as a gate and waits for the tenant config to finish
 * fetching before rendering the remaining app components.
 * @param children
 * @param loading
 * @returns {JSX.Element}
 * @constructor
 */
function ThemeProvider({ children, loading = null }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const persistedTheme = useSelector(tenantSelectors.getTheme);
  const tenantId = useSelector(tenantSelectors.getTenantId);

  const { fetchTenantQuery } = useTenantFetch();

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [theme, setTheme] = React.useState(getDefaultTheme());

  // Initialize localization.
  // First time i18n languages initialization
  useInitLangFromLocalStore();
  const isLayoutRTL = isLayoutRtlDirection();
  const locale = useSelector(userConfigSelectors.getUiLanguage);

  React.useEffect(() => {
    const doFetchTenantConfig = async (id) => {
      try {
        const { theme, config, tenantId } = await fetchTenantQuery(id, locale);
        await queryClient.prefetchQuery(CLIENT_LOGO_KEY, () =>
          fetchClientLogo(config.logo),
        );

        setTheme(theme);
        dispatch(tenantActions.setTenant({ tenantId, theme, config }));
      } catch (ex) {
        // Set the theme to the default theme if the theme is not persisted
        // and the fetch fails
        if (isObjectEmpty(persistedTheme)) {
          const defaultTheme = getDefaultTheme();
          setTheme(defaultTheme);
        }
        log.error(ex)();
      }
      setIsLoaded(true);
    };

    if (tenantId) {
      doFetchTenantConfig(tenantId);
    } else {
      setIsLoaded(true);
    }
  }, [
    tenantId,
    dispatch,
    fetchTenantQuery,
    persistedTheme,
    queryClient,
    locale,
  ]);

  return (
    <StyleSheetManager {...(isLayoutRTL ? { stylisPlugins: [rtlPlugin] } : {})}>
      <StyledThemeProvider theme={{ ...theme, isLayoutRTL }}>
        {isLoaded ? children : loading}
      </StyledThemeProvider>
    </StyleSheetManager>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loading: PropTypes.node,
};

ThemeProvider.defaultProps = {
  loading: null,
  children: null,
};

export default memo(ThemeProvider);
