import * as React from 'react';
import { useSelector } from 'react-redux';
import { navigationSelectors, tenantSelectors } from 'store';
import { Login } from 'scenes/Auth';
import { isElectron } from 'utils/electron';
import { SsoLoginRouter } from './SsoLoginRouter';

export const LoginRouter = () => {
  const { ssoEnabled } = useSelector(tenantSelectors.getConfig);
  let bypassSSO = useSelector(navigationSelectors.getBypassSSO);
  if (isElectron()) {
    bypassSSO = true;
  }
  return ssoEnabled && !bypassSSO ? <SsoLoginRouter /> : <Login />;
};
