var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useEffect, useState } from 'react';
import { formatLocalizedDate, formatLocalizedTime, } from '@serraview/engage-shared';
import { useLongPress } from 'use-long-press';
import { DateS, TimeS, Wrapper } from './CurrentDateTime.styles';
var LONG_PRESS_THRESHOLD = 5000;
var CurrentDateTime = function (_a) {
    var locale = _a.locale, timeZone = _a.timeZone, textColor = _a.textColor, onAdminPress = _a.onAdminPress;
    var _b = useState(new Date()), dateTime = _b[0], setDateTime = _b[1];
    useEffect(function () {
        var intervalId = setInterval(function () { return setDateTime(new Date()); }, 1000);
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    var dateLabel = formatLocalizedDate(dateTime, {
        format: {
            year: 'numeric',
            weekday: 'short',
            month: 'long',
            day: 'numeric',
        },
        locale: locale,
        timeZone: timeZone,
    });
    var timeLabel = formatLocalizedTime(dateTime, { locale: locale, timeZone: timeZone });
    var longPressHandlers = useLongPress(function () { return onAdminPress(); }, {
        threshold: LONG_PRESS_THRESHOLD,
    });
    return (React.createElement(Wrapper, __assign({}, longPressHandlers, { "data-testid": "current-date-time" }),
        React.createElement(TimeS, { textColor: textColor }, timeLabel),
        React.createElement(DateS, { textColor: textColor }, dateLabel)));
};
export default memo(CurrentDateTime);
