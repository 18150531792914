var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var activeColor = '#81DC44';
export var IntervalContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 4px;\n  height: 7px;\n  background-color: ", ";\n\n  & + & {\n    margin-left: 1px;\n  }\n"], ["\n  min-width: 4px;\n  height: 7px;\n  background-color: ", ";\n\n  & + & {\n    margin-left: 1px;\n  }\n"])), function (_a) {
    var theme = _a.theme, isBooked = _a.isBooked;
    return isBooked ? theme.color2 : activeColor;
});
export var GroupWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  margin-right: 1px;\n\n  & {\n    .fix-size-availability-bar__segment:first-of-type {\n      ", ":first-child {\n        border-top-left-radius: 4px;\n        border-bottom-left-radius: 4px;\n      }\n    }\n  }\n    \n  }\n\n  & {\n    .fix-size-availability-bar__segment:last-of-type {\n      ", ":last-child {\n        border-top-right-radius: 4px;\n        border-bottom-right-radius: 4px;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  align-items: flex-end;\n  margin-right: 1px;\n\n  & {\n    .fix-size-availability-bar__segment:first-of-type {\n      ", ":first-child {\n        border-top-left-radius: 4px;\n        border-bottom-left-radius: 4px;\n      }\n    }\n  }\n    \n  }\n\n  & {\n    .fix-size-availability-bar__segment:last-of-type {\n      ", ":last-child {\n        border-top-right-radius: 4px;\n        border-bottom-right-radius: 4px;\n      }\n    }\n  }\n"])), IntervalContainer, IntervalContainer);
export var LabelContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 10px;\n  opacity: 0.5;\n  text-align: center;\n  margin-bottom: 5px;\n"], ["\n  font-size: 10px;\n  opacity: 0.5;\n  text-align: center;\n  margin-bottom: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
