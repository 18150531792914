import React, { memo, useCallback } from 'react';
import { FlatList, Mask } from 'base-components';
import PersonItem from 'components/layouts/GlobalSearch/PersonItem/PersonItem';
import { useDispatch, useSelector } from 'react-redux';
import { teamMembersSearchSelectors, teamReservationsActions } from 'store';
import { GlobalSearchResultsWrapper } from 'components/layouts/GlobalSearch/styled';
import { useTranslation } from 'react-i18next';
import { A11yHiddenLabel } from 'theme';
import { useNavigate } from 'react-router-dom';
import { useGenerateCurrentLocationPath } from 'utils';
import { joinPaths } from 'router/utils';
import { PATH_SEGMENT } from 'consts';
import { useAppLocation } from 'router/hooks';
import { useUser } from 'api/queries';
import SelectablePersonItem from 'components/layouts/Team/TeamMembers/SelectablePersonItem';
import { useTeamMembers } from 'api/queries/useTeamMembers';
import { TeamReserveButton } from './TeamReserveButton';
import { EmptyList, TeamMembersListWrapper, TeamMembersSearchOuterWrapper, } from './TeamMembers.style';
var TeamMembersList = function (_a) {
    var team = _a.team, selectedIds = _a.selectedIds, toggleSelectedId = _a.toggleSelectedId;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var pathname = useAppLocation().pathname;
    var currentLocationPath = useGenerateCurrentLocationPath();
    var t = useTranslation().t;
    var searchValue = useSelector(teamMembersSearchSelectors.getTeamMembersSearchValue);
    var userData = useUser().data;
    var a11yTeamMembersList = t('layout.teamMembersList.a11yTeamMembersList');
    var _b = useTeamMembers({
        teamId: team === null || team === void 0 ? void 0 : team.id,
        searchString: searchValue.trim(),
    }), teamMembers = _b.data, isLoading = _b.isLoading;
    var isMyTeam = !!(teamMembers === null || teamMembers === void 0 ? void 0 : teamMembers.find(function (member) { return member.id === (userData === null || userData === void 0 ? void 0 : userData.personId); }));
    var isUserAllowedToBook = (userData === null || userData === void 0 ? void 0 : userData.canReserveForAnyTeam) ||
        ((userData === null || userData === void 0 ? void 0 : userData.canReserveForMyTeam) && isMyTeam);
    var onPersonSelect = useCallback(function (person) {
        var personPath = joinPaths(currentLocationPath, PATH_SEGMENT.PEOPLE, "".concat(person.id));
        navigate(personPath, {
            state: {
                person: person,
                fromPath: pathname,
            },
        });
    }, [currentLocationPath, navigate, pathname]);
    var onTeamReserveButtonPress = function () {
        var teamMembersIds = (teamMembers === null || teamMembers === void 0 ? void 0 : teamMembers.map(function (_a) {
            var id = _a.id;
            return id;
        })) || [];
        dispatch(teamReservationsActions.startTeamReservationDesksSelection({
            selectedTeamMemberIds: selectedIds.length
                ? selectedIds
                : teamMembersIds,
        }));
    };
    var renderItem = function (_a) {
        var item = _a.item, index = _a.index, style = _a.style;
        // eslint-disable-next-line react/prop-types
        var isSelected = selectedIds.includes(item.id);
        return (React.createElement(SelectablePersonItem, { isSelected: isSelected, onSelect: isUserAllowedToBook ? toggleSelectedId : undefined, key: item.id, item: item, onClick: onPersonSelect, "data-testid": "team_member_".concat(index), style: style, index: index }));
    };
    var renderEmptyList = function () {
        if (isLoading)
            return null;
        return (React.createElement(EmptyList, { "aria-live": "polite", "aria-atomic": "true" },
            React.createElement("p", null, t('layout.teamMembersList.emptyList'))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TeamMembersListWrapper, null,
            React.createElement(A11yHiddenLabel, null, a11yTeamMembersList),
            React.createElement(Mask, { isLoading: isLoading }),
            React.createElement(TeamMembersSearchOuterWrapper, { id: "team_members_list_wrapper", "aria-live": "polite", "aria-atomic": "true" }, teamMembers && teamMembers.length > 0 ? (React.createElement(GlobalSearchResultsWrapper, null,
                React.createElement(FlatList, { fixed: false, data: teamMembers, itemCount: teamMembers.length, renderItem: renderItem, itemSize: PersonItem.getSize }))) : (renderEmptyList())),
            isUserAllowedToBook && (React.createElement(TeamReserveButton, { onPress: onTeamReserveButtonPress, team: team })))));
};
export default memo(TeamMembersList);
