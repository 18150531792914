import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROOT_PATH } from 'consts';
import { useConfig } from 'utils/hooks';
import { navigationSelectors, tenantActions, tenantSelectors } from 'store';
import { TokenManager } from '@serraview/engage-shared/api';
import logger from 'log/logger';
import { Mask } from 'components/base';
import { useAppLocation } from 'router/hooks';

const useHashQuery = () => {
  const { hash } = useAppLocation();
  const hashQuery = hash.substr(1);
  return new URLSearchParams(hashQuery);
};

export const CallbackRouter = () => {
  const query = useHashQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instanceTitle, logo } = useConfig();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const deepLinkQueryString = useSelector(
    navigationSelectors.getDeepLinkQueryString,
  );
  const token = query.get('access_token');
  const refreshToken = query.get('refresh_token');

  React.useEffect(() => {
    (async () => {
      if (token && refreshToken) {
        try {
          await TokenManager.setTokens({
            token,
            refreshToken,
          });
        } catch (error) {
          logger.error(error)();
        }
        dispatch(tenantActions.setUser(TokenManager.getUserIdFromToken(token)));
        dispatch(
          tenantActions.addTenant({
            id: tenantId,
            name: instanceTitle,
            logo,
          }),
        );
      }

      navigate({
        pathname: ROOT_PATH,
        search: deepLinkQueryString,
      });
    })();
  }, [
    deepLinkQueryString,
    dispatch,
    navigate,
    instanceTitle,
    logo,
    tenantId,
    token,
    refreshToken,
  ]);

  return <Mask isLoading />;
};
