import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 15px;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AgendaCalendarWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.color4};
`;

export const MonthSelectorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const MonthSelector = styled.button`
  width: 46px;
  height: 46px;
  background: linear-gradient(0deg, #ecf1f8, #ecf1f8), #ffffff;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 5px;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const MonthSelectorActionsWrapper = styled.div`
  display: flex;
`;

export const Month = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.41px;
  margin-bottom: 0;
  margin-top: 0;
  color: ${(props) => props.theme.typo2};
`;

export const MonthWrapper = styled.div`
  display: flex;
`;

export const CalendarToggler = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  background: ${({ theme }) => theme.typo2};
  color: #fff;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }

  & svg {
    transition: transform 250ms;
    transform: ${({ open }) =>
      `rotate(${open ? -90 : 90}deg) translate(${
        open ? '0, 0.5px' : '1px, 0'
      })`};
  }
`;

export const ReservedMark = styled.div`
  position: absolute;
  top: 32px;
  left: 22px;
  width: 5px;
  height: 5px;
  background: ${({ theme }) => theme.color9};
  border-radius: 50%;
`;
