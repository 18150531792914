var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarDate, SelectButton } from 'components/base';
import { FocusId } from 'utils';
import SpaceCard from '../Reserve/Card/SpaceCard/SpaceCard';
import { Wrapper } from './styled';
export function DeskCheckIn(_a) {
    var spaceItem = _a.spaceItem, startDate = _a.startDate, isCheckingIn = _a.isCheckingIn, isAvailable = _a.isAvailable, onCheckIn = _a.onCheckIn;
    var t = useTranslation().t;
    return (React.createElement(Wrapper, null,
        React.createElement(SpaceCard, __assign({}, spaceItem, { isReserved: false, status: "" })),
        React.createElement(CalendarDate, { "aria-label": t('accessibilityLabels.teamBookingStartDate') }, startDate),
        React.createElement(SelectButton, { id: FocusId.TeamDeskReserve, disabled: !spaceItem.bookable || !isAvailable || isCheckingIn, onClick: onCheckIn }, t('common.checkIn'))));
}
