import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NumberInputCounterElement = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const NumberInputCounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 84px;
  height: 40px;
  border-radius: 30px;
`;

export const Number = styled.input`
  display: flex;
  width: 58px;
  height: 40px;
  -webkit-appearance: none;
  margin: 0;
  border: none;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 23px;
  background-color: ${({ theme }) => theme.color4};
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Icons = styled.div`
  background-color: white;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 26px;
  color: ${({ theme }) => theme.color2};
`;

export const SearchFilterInputLabel = styled.label`
  min-width: 40px;
  margin-right: 15px;
`;

export const IconHit = styled.button`
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 50%;
  left: 58px;

  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const Splitter = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color3};
`;
