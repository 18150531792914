var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import DatePicker from 'components/base/DateTime/DatePicker/DatePicker';
var SearchFilterInputGroup = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  :not(:first-child) {\n    margin-top: 20px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  :not(:first-child) {\n    margin-top: 20px;\n  }\n"])));
var SearchFilterInputLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-width: 40px;\n  margin-right: 15px;\n"], ["\n  min-width: 40px;\n  margin-right: 15px;\n"])));
var StyledDatePickerButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: ", ";\n  box-sizing: border-box;\n  border-radius: 5px;\n  padding: 5px 15px;\n  width: 100%;\n  display: flex;\n  cursor: pointer;\n  color: ", ";\n\n  &.focus-visible {\n    border: 2px solid Highlight;\n  }\n"], ["\n  border: ", ";\n  box-sizing: border-box;\n  border-radius: 5px;\n  padding: 5px 15px;\n  width: 100%;\n  display: flex;\n  cursor: pointer;\n  color: ", ";\n\n  &.focus-visible {\n    border: 2px solid Highlight;\n  }\n"])), function (_a) {
    var isHighlighted = _a.isHighlighted, theme = _a.theme;
    return isHighlighted ? "2px solid ".concat(theme.color9) : "unset";
}, function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
var StyledDatePickerButtonText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 5px;\n  font-weight: ", ";\n  font-size: 14px;\n"], ["\n  margin-right: 5px;\n  font-weight: ", ";\n  font-size: 14px;\n"])), function (_a) {
    var isHighlighted = _a.isHighlighted;
    return (isHighlighted ? '700' : '600');
});
var StyledDatePicker = styled(DatePicker)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  & .react-date-picker__calendar.react-date-picker__calendar--open {\n    transform: translate(-97%, -6%);\n  }\n"], ["\n  & .react-date-picker__calendar.react-date-picker__calendar--open {\n    transform: translate(-97%, -6%);\n  }\n"])));
export { SearchFilterInputGroup, SearchFilterInputLabel, StyledDatePickerButton, StyledDatePickerButtonText, StyledDatePicker, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
