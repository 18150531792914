import { showAcknowledgementPolicy } from '@serraview/engage-shared/utils';
import { hashString } from '@serraview/engage-shared';
import { useConfig } from 'utils/hooks';
import { ACKNOWLEDGEMENT } from 'consts';
/**
 * Returns whether acknowledgement policy should be shown or not
 * based on tenant and user configs.
 */
export var useShowAcknowledgementPolicy = function (userConfig) {
    var _a = useConfig(), acknowledgementMessage = _a.acknowledgementMessage, acknowledgementUrl = _a.acknowledgementUrl, acknowledgementTitle = _a.acknowledgementTitle, acknowledgementValue = _a.acknowledgementValue;
    var acknowledgementPolicyStoredData = {
        organizationPolicyExpirationTime: userConfig === null || userConfig === void 0 ? void 0 : userConfig.acknowledgementConfirmedAt,
        organizationPolicyMessage: userConfig === null || userConfig === void 0 ? void 0 : userConfig.acknowledgementContentHash,
    };
    return showAcknowledgementPolicy({
        acknowledgementPolicyStoredData: acknowledgementPolicyStoredData,
        acknowledgementMessage: hashString(JSON.stringify({
            acknowledgementMessage: acknowledgementMessage,
            acknowledgementUrl: acknowledgementUrl,
            acknowledgementTitle: acknowledgementTitle,
        })),
        acknowledgementValue: acknowledgementValue || ACKNOWLEDGEMENT.NEVER,
    });
};
