var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { buildStyles, CircularProgressbarWithChildren as ProgressBar, } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTheme } from 'styled-components';
import { DEFAULT_SIZE, ProgressText, Wrapper, } from './CircularProgressBar.style';
export var CircularProgressBar = function (_a) {
    var _b = _a.size, size = _b === void 0 ? DEFAULT_SIZE : _b, rest = __rest(_a, ["size"]);
    var _c = useTheme(), color5 = _c.color5, typo2 = _c.typo2;
    return (React.createElement(Wrapper, { size: size },
        React.createElement(ProgressBar, __assign({ strokeWidth: 16, styles: buildStyles({
                strokeLinecap: 'round',
                pathTransitionDuration: 0.5,
                pathColor: typo2,
                trailColor: color5,
                textSize: 0,
            }) }, rest),
            React.createElement(ProgressText, { color: typo2 }, rest.text))));
};
