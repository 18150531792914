import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyCard } from 'base-components';
import { useTeamBookingDetails } from 'api/queries';
import { useOnNavigateBack } from 'utils/hooks/useOnNavigateBack';
import BookingCard from './Card/BookingCard';
import BookingCardSkeleton from './Card/BookingCardSkeleton';
import { useTeamBookingCallbacks, useGoBackFromBooking } from './hooks';
var TeamBooking = function (_a) {
    var event = _a.event;
    var t = useTranslation().t;
    var teamBookingCallbacks = useTeamBookingCallbacks();
    var _b = event || {}, meetingId = _b.meetingId, attendeeId = _b.attendeeId;
    var _c = useTeamBookingDetails(meetingId, teamBookingCallbacks), booking = _c.data, isLoading = _c.isLoading, isError = _c.isError, isIdle = _c.isIdle;
    var goBack = useGoBackFromBooking({ booking: booking }).goBack;
    useOnNavigateBack(goBack);
    if (isLoading || isIdle) {
        return React.createElement(BookingCardSkeleton, null);
    }
    if (isError || !booking) {
        return React.createElement(EmptyCard, { iconName: "info" }, t('common.networkError'));
    }
    return (React.createElement(BookingCard, { booking: booking, onBack: goBack, attendeeId: attendeeId }));
};
export default memo(TeamBooking);
