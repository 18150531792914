import { useState } from 'react';

const emptyValidator = () => null;

export default function useForm({
  initialValues,
  validators,
  onUpdateValue = () => {},
}) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const updateValue = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const validator = validators[fieldName] || emptyValidator;
    const errorMessage = validator(fieldValue);

    if (errorMessage) {
      setErrors((oldErrors) => ({ ...oldErrors, [fieldName]: errorMessage }));
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, [fieldName]: null }));
    }

    setValues((oldValues) => ({ ...oldValues, [fieldName]: fieldValue }));
    onUpdateValue(e);
  };

  const hasValidationErrors =
    Object.values(errors).filter(Boolean).length !== 0;

  return { values, updateValue, errors, hasValidationErrors };
}
