import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'api/queries';
import { Avatar } from 'base-components';
import { UNKNOWN_PERSON } from 'consts';
import { isElectron } from 'utils/electron';
import { noop } from 'utils';
import { NameWrapper, Title, Wrapper, FullName } from './styled';
var BookedButton = function (_a) {
    var _b = _a.id, personId = _b === void 0 ? 0 : _b, isSvLiveOccupant = _a.isSvLiveOccupant, firstName = _a.firstName, lastName = _a.lastName, imageUrl = _a.imageUrl, _c = _a.onPress, onPress = _c === void 0 ? noop : _c, forceToGuest = _a.forceToGuest;
    var t = useTranslation().t;
    var user = useUser().data;
    var currentPersonId = user === null || user === void 0 ? void 0 : user.personId;
    var isCurrentPerson = personId === currentPersonId;
    var title = t('layout.reserve.bookedFor');
    if (isCurrentPerson || isSvLiveOccupant) {
        title = t('layout.reserve.by');
    }
    var isKioskUser = isElectron() && (isCurrentPerson || forceToGuest);
    var fullName = isKioskUser
        ? t('layout.reserve.guest')
        : "".concat(firstName, " ").concat(lastName);
    var image = isKioskUser ? null : imageUrl;
    var icon = isKioskUser ? 'personNew' : null;
    // UNKNOWN_PERSON is returned as firstName lastName if user is opt outed
    // In required fileds for firstName and lastName in engage configuration spaces can be added
    var nameExists = (fullName === null || fullName === void 0 ? void 0 : fullName.trim()) && fullName !== UNKNOWN_PERSON;
    var personName = nameExists ? fullName : t('layout.reserve.unlisted');
    return (React.createElement(Wrapper, null,
        React.createElement(Title, null, "".concat(title, ": ")),
        React.createElement(NameWrapper, { "data-testid": "booked-by-button", onClick: onPress },
            React.createElement(Avatar, { source: image, name: personName, icon: icon, size: "tiny" }),
            React.createElement(FullName, null, personName))));
};
export default BookedButton;
