var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { InnerWrapper, StyledSpaceCard } from '../../styled';
import { ReserveCardContext } from '../ReserveCardContext';
export var ImageAndTitle = function (props) {
    var _a = useContext(ReserveCardContext), item = _a.item, spaceItem = _a.spaceItem, hasOccupant = _a.hasOccupant, spaceStatusInfo = _a.spaceStatusInfo;
    return (React.createElement(InnerWrapper, __assign({}, props),
        React.createElement(StyledSpaceCard, __assign({}, (item !== null && item !== void 0 ? item : spaceItem), { downloadableImageUrl: spaceItem.downloadableImageUrl, imageUrl: spaceItem.imageUrl, isReserved: hasOccupant, status: spaceStatusInfo === null || spaceStatusInfo === void 0 ? void 0 : spaceStatusInfo.label }))));
};
