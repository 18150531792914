import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloorplanViews } from './FloorplanViews';
export var FloorplanViewsContainer = function () {
    var t = useTranslation().t;
    var title = t('layout.floorplanViews.title');
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, { title: title, subtitle: "", closeIconA11yLabel: "layout.floorplanViews.closeViews", isCloseIcon: true }),
        React.createElement(DrawerCardContent, null,
            React.createElement(FloorplanViews, null))));
};
