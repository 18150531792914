import React from 'react';
import { useAppLocation } from 'router/hooks';
import { ORGANIZATION_POLICY_PATH, CALLBACK_PATH, ROOT_PATH } from 'consts';
import { hasTokens } from 'utils/config/tokenConfig';
import { useDeepLinking } from './hooks/useDeepLinking/useDeepLinking';
import { NavigateToDeepLink } from './NavigateToDeepLink';
import { CheckingMask } from './DeepLinkChecker.style';
/**
 * Route paths which shouldn't be skipped by deep link.
 * Acknowledgement policy should be displayed regardless of deep link presence.
 * Callback and root routes are essential for login flow to work properly.
 */
var NOT_SKIPPABLE_ROUTES = [
    ORGANIZATION_POLICY_PATH,
    CALLBACK_PATH,
    ROOT_PATH,
];
export function DeepLinkChecker(_a) {
    var children = _a.children;
    var pathname = useAppLocation().pathname;
    var _b = useDeepLinking(), isChecking = _b.isChecking, deepLink = _b.to, clearDeepLink = _b.clearDeepLink;
    var isAuthenticated = hasTokens();
    var shouldDisplayLoader = isChecking && isAuthenticated;
    /**
     * Navigate to deep link path if all the following conditions met:
     * 1. deep link path exists
     * 2. user is authenticated
     * 3. user is not on a route which should not be skipped
     */
    var shouldNavigateToDeepLink = !!deepLink &&
        isAuthenticated &&
        NOT_SKIPPABLE_ROUTES.every(function (route) { return route !== pathname; });
    if (shouldNavigateToDeepLink) {
        return React.createElement(NavigateToDeepLink, { clearDeepLink: clearDeepLink, to: deepLink });
    }
    return (React.createElement(React.Fragment, null,
        shouldDisplayLoader ? React.createElement(CheckingMask, { isLoading: true }) : null,
        children));
}
