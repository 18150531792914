import styled from 'styled-components';

export const BackButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: ${({ theme }) => theme.typo1};
  cursor: pointer;
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;
