import React, { useMemo, useState } from 'react';
import { groupConfigsByBuildingAndFloor, } from 'components/kiosk/AvailableKiosks/utils';
import { BuildingName, BuildingsListWrapper, BuildingWrapper, } from 'components/layouts/LocationSelector/components/styled';
import AvailableKioskItems from 'components/kiosk/AvailableKiosks/AvailableKioskFloors/AvailableKioskItems';
var AvailableKioskFloors = function (_a) {
    var data = _a.data;
    var _b = useState(null), openedId = _b[0], setOpenedId = _b[1];
    var floors = useMemo(function () {
        return groupConfigsByBuildingAndFloor(data, function (_a) {
            var floorId = _a.floorId, floorName = _a.floorName;
            return ({
                id: floorId,
                name: floorName,
            });
        });
    }, [data]);
    var renderFloor = function (_a) {
        var id = _a.id, name = _a.name, kioskOriginalData = _a.kioskOriginalData;
        var isOpened = id === openedId;
        return (React.createElement(BuildingWrapper, { key: "".concat(id, "-").concat(name) },
            React.createElement(BuildingName, { "data-testid": "floor-list-item", isActive: isOpened, onClick: function () { return setOpenedId(isOpened ? null : id); } }, name),
            isOpened && React.createElement(AvailableKioskItems, { data: kioskOriginalData })));
    };
    return React.createElement(BuildingsListWrapper, null, floors.map(renderFloor));
};
export default AvailableKioskFloors;
