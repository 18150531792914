import { SENSOR_STATUS } from '@serraview/engage-shared/constants';

/**
 * Returns true is space is occupied by sensor
 * @param space
 * @returns {boolean}
 */
export const isSpaceOccupiedBySensor = (space) =>
  space?.sensorStatus === SENSOR_STATUS.OCCUPIED;

export const getLabelId = (spaceId, shapeIndex) => `${spaceId}.${shapeIndex}`;

export const getLabelsForSpace = ({
  space,
  includeName = true,
  includeOccupants = true,
  includeSpaceType = false,
}) =>
  space.shapes.map((shape, i) => {
    const lines = [];
    if (includeSpaceType) {
      lines.push({ text: space.description });
    }
    if (includeName) {
      if (space.name) {
        lines.push({ text: space.name });
      } else if (!includeSpaceType) {
        lines.push({ text: space.description });
      }
    }
    const items =
      space.reservations || space.wiredSVLivePeople || space.homeLocations;
    if (includeOccupants && items) {
      items.map((occupant) => lines.push({ text: occupant.name }));
    }

    return {
      id: getLabelId(space.id, i),
      coordinates: shape.labelPoint,
      lines,
    };
  });

export const getLabelsForSpaces = ({
  spaces,
  includeName = true,
  includeOccupants = true,
  includeSpaceType = false,
}) =>
  spaces
    .filter(
      (space) =>
        space.shapes &&
        space.name !== 'Traditional Workspace' &&
        space.name !== 'Workspace',
    )
    .map((space) =>
      getLabelsForSpace({
        space,
        includeName,
        includeOccupants,
        includeSpaceType,
      }),
    )
    .reduce((prev, next) => prev.concat(next), []);

export function getLabelOffsetY(label, index, labelSize) {
  const lineHeight = 26 - (16 - labelSize);
  return index * lineHeight - ((label.lines.length - 1) * lineHeight) / 2;
}
