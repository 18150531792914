var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { getCountryColorByInitials, getCountryColorByNumber, getInitials, } from '@serraview/engage-shared';
import { testProps } from 'utils';
import { Icon } from '../Icons/Icon';
import { ImageWrapper, ImageButton } from './styled';
import Initials from './Initials';
import { getImageStyle } from './utils';
import { textSize } from './config';
var ICON_SIZE_FACTOR = 2;
var emptyObject = {};
var AvatarImage = function (props) {
    var source = props.source, icon = props.icon, _a = props.iconStyle, iconStyle = _a === void 0 ? emptyObject : _a, name = props.name, initials = props.initials, country = props.country, _b = props.variant, variant = _b === void 0 ? 'circle' : _b, _c = props.size, size = _c === void 0 ? 'medium' : _c, _d = props.style, style = _d === void 0 ? emptyObject : _d, _e = props.errorStyle, errorStyle = _e === void 0 ? emptyObject : _e, onImageClick = props.onImageClick, _f = props.id, id = _f === void 0 ? 0 : _f, _g = props.className, className = _g === void 0 ? '' : _g;
    var _h = useState(false), imageError = _h[0], setImageError = _h[1];
    var t = useTranslation().t;
    var color3 = useTheme().color3;
    var componentStyle = getImageStyle(variant, size, style, color3, imageError ? errorStyle : {});
    var imageWrapperStyle = {
        width: componentStyle.width,
        height: componentStyle.height,
    };
    var height = componentStyle.height, backgroundColor = componentStyle.backgroundColor;
    var iconSize = height / ICON_SIZE_FACTOR;
    var placeholder = function () { return (React.createElement(ImageWrapper, { className: className, key: "placeholder", style: componentStyle, "data-testid": "avatarPlaceholder" },
        React.createElement(Icon, { name: "person", size: iconSize, style: { color: color3 } }))); };
    var renderIcon = function () {
        var el;
        var _iconStyle = __assign({ color: backgroundColor }, iconStyle);
        var wrapperStyles = __assign({}, componentStyle);
        if (id) {
            var _a = getCountryColorByNumber(id, ''), countryBackgroundColor = _a.backgroundColor, textColor = _a.textColor;
            _iconStyle.color = textColor;
            wrapperStyles.backgroundColor = countryBackgroundColor;
        }
        if (typeof icon === 'function') {
            var iconElement = icon();
            el = React.cloneElement(iconElement, {
                size: iconSize,
                style: __assign(__assign({}, iconElement.props.style), _iconStyle),
            });
        }
        else if (icon) {
            el = React.createElement(Icon, { name: icon, size: iconSize, style: _iconStyle });
        }
        return (React.createElement(ImageWrapper, { key: "icon", style: wrapperStyles, "data-testid": "avatarIcon", className: "".concat(className, " avatar__icon") }, el));
    };
    // Old variant of renderInitials, I will leave it here in case someone will need it
    // or backend will not send us 'initials' with person
    var renderInitialsFromName = function () {
        var profanities = t('profanities', { returnObjects: true });
        var initials = getInitials(name || '');
        var _a = getCountryColorByInitials(initials, country || ''), backgroundColor = _a.backgroundColor, textColor = _a.textColor;
        return (React.createElement(Initials, { key: "initials", style: __assign(__assign({}, componentStyle), { background: backgroundColor }), textColor: textColor, textSize: textSize[size] - 3, className: className }, profanities.indexOf(initials) === -1 ? initials : initials[0]));
    };
    var renderInitials = function () {
        var _a = getCountryColorByInitials(initials || '', country || ''), backgroundColor = _a.backgroundColor, textColor = _a.textColor;
        return (React.createElement(Initials, { key: "initials", style: __assign(__assign({}, componentStyle), { background: backgroundColor }), textColor: textColor, textSize: textSize[size] - 3, className: className }, initials || ''));
    };
    var renderImage = function () {
        if (!imageError) {
            var imageElement = (React.createElement("img", { className: "avatar__image", key: "image", alt: "avatar" // TODO: think of a better alt text
                , src: source || undefined, onError: function () { return setImageError(true); }, style: __assign(__assign({}, componentStyle), { pointerEvents: 'none' }), "data-testid": "avatarImage" }));
            if (onImageClick) {
                return (React.createElement(ImageButton, __assign({}, testProps(t, 'accessibilityLabels.viewImage').props, { onClick: onImageClick, style: imageWrapperStyle, key: "image_button", className: className }), imageElement));
            }
            return imageElement;
        }
        return [
            icon && renderIcon(),
            !icon && name && renderInitialsFromName(),
            !icon && !name && !initials && placeholder(),
        ];
    };
    // priority: source > icon > initials > name > placeholder
    var renderComponent = function () { return [
        source && renderImage(),
        !source && icon && renderIcon(),
        !source && !icon && initials && renderInitials(),
        !source && !icon && !initials && name && renderInitialsFromName(),
        !source && !icon && !initials && !name && placeholder(),
    ]; };
    return renderComponent();
};
// @ts-ignore
export default memo(AvatarImage);
