import { shape, object, number, arrayOf } from 'prop-types';
import Shape from './shape';

const Space = shape({
  id: number,
  style: object,
  shapes: arrayOf(Shape),
});

export default Space;
