import OpenSeadragon from 'openseadragon';
import { getShapeCoords, getShapeBoundingBox } from './shapeUtils';

export const panAndZoom = (viewport, point, zoom) => {
  // Leaving this here in case we want some custom offset in the future

  // This is a weird and hacky way to get the correct bounds of the viewport, so that we can pan correctly
  // I couldn't find anything better in openSeaDragon to do this mathematically
  const lastPan = viewport.getCenter();
  const lastZoom = viewport.getZoom();
  viewport.panTo(point, true);
  viewport.zoomTo(zoom, undefined, true);
  // This is the bounds we are after, that we can use to correctly position the pan point to the upper part of the screen
  // const bounds = viewport.getBounds();

  const offset = viewport.deltaPointsFromPixels(
    new OpenSeadragon.Point(-200, 0),
  );

  viewport.panTo(lastPan, true);
  viewport.zoomTo(lastZoom, undefined, true);
  const newPoint = point.plus(offset.rotate(-viewport.getRotation()));
  viewport.panTo(newPoint);
  viewport.zoomTo(zoom);
};

export const panToShapeCollection = (viewport, fullShapeList, zoom = false) => {
  const firstBound = getShapeBoundingBox(fullShapeList[0]);
  const shapeBounds = fullShapeList.reduce(
    (b, fullShape) => b.union(getShapeBoundingBox(fullShape)),
    firstBound,
  );

  const newZoom =
    1 /
    (viewport.imageToViewportCoordinates(shapeBounds.width, shapeBounds.height)
      .x +
      0.6);

  const zoomTo = zoom ? newZoom : viewport.getZoom();
  const point = viewport.imageToViewportCoordinates(shapeBounds.getCenter());

  panAndZoom(viewport, point, zoomTo);
};

export const panToShape = (viewport, fullShape, zoom) => {
  const coords = getShapeCoords(fullShape);

  if (!coords) {
    return;
  }

  const point = viewport.imageToViewportCoordinates(coords.x, coords.y);

  const boundingBox = getShapeBoundingBox(fullShape);

  let newZoom = 1;
  if (boundingBox) {
    newZoom =
      1 /
      (viewport.imageToViewportCoordinates(
        boundingBox.width,
        boundingBox.height,
      ).x +
        0.6);
  }

  const zoomTo = zoom ? newZoom : viewport.getZoom();

  panAndZoom(viewport, point, zoomTo);
};

// bezier ease in out
const ease = (t) => t * t * (3 - 2 * t);

export const resetRotation = (viewport) => {
  const startRotation = viewport.getRotation() % 360;
  // Choose the smallest possible rotation total
  const endRotation = startRotation > 180 ? 360 : 0;

  if (Math.abs(endRotation - startRotation) < 2) {
    viewport.setRotation(0);
    return;
  }

  const startTime = new Date();
  const animationTime =
    (Math.abs(endRotation - startRotation) / 180) * 1 * 1000;

  const animateRotation = () => {
    if (!viewport) return;
    const time = new Date();
    const t = (time - startTime) / animationTime;

    if (t < 1) {
      viewport.setRotation(
        ease(t) * (endRotation - startRotation) + startRotation,
      );
      requestAnimationFrame(animateRotation);
    } else {
      viewport.setRotation(0);
    }
  };

  requestAnimationFrame(animateRotation);
};
