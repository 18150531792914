var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  background-color: ", ";\n  height: 180px;\n  width: 250px;\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n"], ["\n  flex: 1;\n  background-color: ", ";\n  height: 180px;\n  width: 250px;\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
export var ButtonsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-content: center;\n  flex: 1;\n  padding: 0 30px 30px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-content: center;\n  flex: 1;\n  padding: 0 30px 30px;\n"])));
export var FloorplanView = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 80px;\n  height: 80px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding-right: 30px;\n  padding-left: 0;\n"], ["\n  width: 80px;\n  height: 80px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding-right: 30px;\n  padding-left: 0;\n"])));
export var PNGImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  border: ", " solid\n    ", "px;\n  border-radius: 10px;\n  box-shadow: 4px 4px 10px 0 #00000040;\n  margin-bottom: 15px;\n"], ["\n  width: 100%;\n  border: ", " solid\n    ", "px;\n  border-radius: 10px;\n  box-shadow: 4px 4px 10px 0 #00000040;\n  margin-bottom: 15px;\n"])), function (props) { return props.theme.typo2; }, function (props) { return (props.isActive ? 2 : 0); });
export var FloorplanViewText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n  color: ", ";\n  font-size: 12px;\n"], ["\n  font-weight: ", ";\n  color: ", ";\n  font-size: 12px;\n"])), function (props) { return (props.isActive ? 'bold' : ' normal'); }, function (props) { return props.theme.typo2; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
