var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FocusId, testProps, useModal } from 'utils';
import IconButton from 'components/base/IconButton/IconButton';
import { MODALS } from 'consts';
export function AgendaButton() {
    var t = useTranslation().t;
    var openModal = useModal().openModal;
    var toggleAgendaModal = function () {
        openModal(MODALS.AGENDA);
    };
    return (React.createElement(IconButton, __assign({ id: FocusId.AgendaButton, icon: "calendar-list", onPress: toggleAgendaModal }, testProps(t, 'accessibilityLabels.viewAgenda').props)));
}
