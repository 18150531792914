import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SpaceType } from '@serraview/engage-shared';
import { PATH_SEGMENT } from 'consts';
import { useToggleFavouriteMenuItem } from 'utils/hooks/useToggleFavouriteMenuItem';
import { useOpenBuildingInGoogleMaps } from './useOpenBuildingInGoogleMaps';
export var useSpaceMenu = function (_a) {
    var spaceId = _a.spaceId, isDesk = _a.isDesk, _b = _a.isFavourite, isFavourite = _b === void 0 ? false : _b, spaceAttributes = _a.spaceAttributes;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var openBuildingInGoogleMaps = useOpenBuildingInGoogleMaps();
    var toggleFavouriteMenuItem = useToggleFavouriteMenuItem({
        isFavourite: isFavourite,
        itemId: spaceId,
        itemType: isDesk ? 'desk' : 'space',
        itemOriginalType: isDesk ? SpaceType.DESK : SpaceType.SPACE,
    });
    if (!spaceId) {
        return null;
    }
    var menu = [
        {
            name: t('layout.drawer.menu.openInMapsButton'),
            onPress: openBuildingInGoogleMaps,
        },
    ];
    if (toggleFavouriteMenuItem) {
        // add this item on the first position of the menu
        menu.unshift(toggleFavouriteMenuItem);
    }
    if (spaceAttributes === null || spaceAttributes === void 0 ? void 0 : spaceAttributes.length) {
        menu.push({
            name: t('layout.drawer.menu.equipment'),
            onPress: function () {
                navigate(PATH_SEGMENT.SPACE_ATTRIBUTES, {
                    state: {
                        space: { id: spaceId },
                        spaceAttributes: spaceAttributes,
                    },
                });
            },
        });
    }
    return menu;
};
