import { CONCIERGE_MENU_PATH } from 'consts';
import { useAppLocation } from 'router/hooks';
import { useMenuItems } from 'api/queries/menu/useMenuItems';
import { isElectron } from 'utils/electron';
export var useConciergeMenuAllowed = function () {
    var _a;
    var menuItems = useMenuItems().data;
    var pathname = useAppLocation().pathname;
    var isKiosk = isElectron();
    var conciergeMenuItemsMin = isKiosk ? 1 : 3;
    var numberOfMenuItems = (_a = menuItems === null || menuItems === void 0 ? void 0 : menuItems.length) !== null && _a !== void 0 ? _a : 0;
    var conciergeMenuAllowed = pathname !== CONCIERGE_MENU_PATH &&
        numberOfMenuItems >= conciergeMenuItemsMin;
    return {
        conciergeMenuAllowed: conciergeMenuAllowed,
    };
};
