var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ViewableAvatar, Icon } from 'base-components';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getPresenceStatusIcon, getSensorStatusIcon, localizePersonSVLivePresence, } from '@serraview/engage-shared';
import LocationStatusLabel from 'components/base/LocationStatusLabel/LocationStatusLabel';
import { SensorStatusIconWrapper, AvatarWrapper } from './styled';
var PersonAvatar = function (_a) {
    var personName = _a.personName, sensorStatus = _a.sensorStatus, presenceStatus = _a.presenceStatus, _b = _a.locationStatus, locationStatus = _b === void 0 ? '' : _b, _c = _a.imageSource, imageSource = _c === void 0 ? null : _c;
    var t = useTranslation().t;
    // @ts-ignore
    var _d = useTheme(), color1 = _d.color1, color6 = _d.color6;
    var hasSVLiveData = !!presenceStatus;
    // if user doesn't have SVLive data, fallback to primary location handler
    var labelText = hasSVLiveData
        ? localizePersonSVLivePresence({ t: t, status: presenceStatus })
        : locationStatus;
    var renderIcon = function (props) { return React.createElement(Icon, __assign({}, props)); };
    var renderStatusIcon = function () {
        // if user doesn't have SVLive data, fallback to sensors status handler
        var icon = hasSVLiveData
            ? getPresenceStatusIcon(presenceStatus, renderIcon)
            : getSensorStatusIcon(sensorStatus, renderIcon);
        if (!icon) {
            return null;
        }
        return (React.createElement(SensorStatusIconWrapper, { "aria-label": locationStatus, "data-testid": "presence-sensor-status-icon" }, icon));
    };
    var renderLocationStatusLabel = function () {
        if (!labelText) {
            return null;
        }
        return (React.createElement(LocationStatusLabel, { backgroundColor: color1, color: color6, text: labelText }));
    };
    return (React.createElement(AvatarWrapper, null,
        React.createElement(ViewableAvatar, { source: imageSource, name: personName, size: "giant", alt: t('accessibilityLabels.personImage') }),
        renderStatusIcon(),
        renderLocationStatusLabel()));
};
export default PersonAvatar;
