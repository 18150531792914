import * as React from 'react';
import {
  DateFormat,
  formatLocalizedDate,
  getUtcIsoString,
  LanguageTypes,
} from '@serraview/engage-shared';
import PropTypes from 'prop-types';
import { noop } from 'utils';
import { DayWrapper } from './styled';

export const Day = React.memo(({ onPressDay, day }) => {
  const { date, label, isCurrentDate, isSelected, isDisabled, locale } = day;
  const id = getUtcIsoString(date);
  const name = formatLocalizedDate(date, {
    locale,
    format: DateFormat.weekDayLong,
  });

  const handleClick = () => {
    if (isDisabled) return;
    onPressDay(day);
  };

  return (
    <DayWrapper
      key={id}
      isDisabled={isDisabled}
      isSelected={isSelected}
      isCurrentDate={isCurrentDate}
      data-testid="day_btn"
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        onClick={handleClick}
        aria-label={name}
        data-testid={formatLocalizedDate(date, {
          locale: LanguageTypes.en,
          format: DateFormat.weekDayLong,
        })}
        aria-checked={isSelected}
        aria-disabled={isDisabled}
      />
      <label htmlFor={id}>{label}</label>
    </DayWrapper>
  );
});

Day.propTypes = {
  day: PropTypes.shape({
    date: PropTypes.instanceOf(Date).isRequired,
    label: PropTypes.string.isRequired,
    isCurrentDate: PropTypes.bool,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    locale: PropTypes.string,
  }),
  locale: PropTypes.string,
  onPressDay: PropTypes.func,
};

Day.defaultProps = {
  locale: null,
  onPressDay: noop,
  day: {
    isCurrentDate: false,
    isSelected: false,
    isDisabled: false,
  },
};
