import styled from 'styled-components';

const SelectButton = styled.button`
  width: 100%;
  padding: 24px 0;
  text-align: center;
  background-color: ${(props) => props.theme.color9};
  color: ${(props) => props.theme.typo1};
  font-weight: 700;
  font-size: 18px;
  margin-top: 25px;
  cursor: pointer;
  border-radius: 0 0 6px 6px;

  &[disabled] {
    filter: grayscale(100%);
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.focus-visible {
    outline: none;
    box-shadow: inset 0 0 3px 5px Highlight;
  }
`;

export default SelectButton;
