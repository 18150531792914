import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 140px;
  background-color: ${({ theme }) => theme.color1};
  padding: 10px 17px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.color6};
`;

const ButtonText = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export { Wrapper, ButtonText };
