var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { UlMenuList } from 'components/base/Select/Select.style';
var MenuList = function (props) {
    var children = props.children, innerProps = props.innerProps, innerRef = props.innerRef, getStyles = props.getStyles;
    var styles = getStyles('menuList', props);
    return (React.createElement(UlMenuList, __assign({ css: styles, 
        // @ts-ignore
        ref: innerRef }, innerProps), children));
};
export default MenuList;
