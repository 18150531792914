var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { COMMON_STYLES, Z_INDEX } from 'consts';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  z-index: ", ";\n  left: ", "px;\n  bottom: ", "px;\n  width: 100%;\n  max-width: 400px;\n  height: max-content;\n  max-height: calc(100vh - 120px); // temporary fix\n  background-color: #ffffff;\n  box-shadow: 0 40px 60px rgba(10, 38, 50, 0.15);\n  border-radius: 6px;\n  overflow: auto;\n  transition: width, height, min-width, min-height, bottom,\n    left 350ms ease-in-out;\n  @media (max-width: 450px) {\n    bottom: 0;\n    left: 0;\n  }\n  @media (min-width: 451px) and (max-width: 800px) {\n    bottom: 20px;\n    left: 20px;\n  }\n\n  @media (max-height: 900px) {\n    max-height: calc(100vh - 70px);\n  }\n"], ["\n  position: absolute;\n  z-index: ", ";\n  left: ", "px;\n  bottom: ", "px;\n  width: 100%;\n  max-width: 400px;\n  height: max-content;\n  max-height: calc(100vh - 120px); // temporary fix\n  background-color: #ffffff;\n  box-shadow: 0 40px 60px rgba(10, 38, 50, 0.15);\n  border-radius: 6px;\n  overflow: auto;\n  transition: width, height, min-width, min-height, bottom,\n    left 350ms ease-in-out;\n  @media (max-width: 450px) {\n    bottom: 0;\n    left: 0;\n  }\n  @media (min-width: 451px) and (max-width: 800px) {\n    bottom: 20px;\n    left: 20px;\n  }\n\n  @media (max-height: 900px) {\n    max-height: calc(100vh - 70px);\n  }\n"])), Z_INDEX.DRAWER, COMMON_STYLES.SPACE, COMMON_STYLES.SPACE);
var ContentOuterWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  justify-content: flex-start;\n  height: max-content;\n"], ["\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  justify-content: flex-start;\n  height: max-content;\n"])));
var ContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  min-width: 100%;\n  max-height: 100%;\n  flex: 1;\n"], ["\n  display: flex;\n  height: 100%;\n  min-width: 100%;\n  max-height: 100%;\n  flex: 1;\n"])));
var ResizeHandle = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-top: 8px;\n  padding-left: 0;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n\n  &::before {\n    content: ' ';\n    display: block;\n    width: 36px;\n    height: 5px;\n    background-color: ", ";\n    opacity: 0.5;\n    border-radius: 5px;\n  }\n\n  &:hover::before {\n    background-color: ", ";\n  }\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  padding-top: 8px;\n  padding-left: 0;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n\n  &::before {\n    content: ' ';\n    display: block;\n    width: 36px;\n    height: 5px;\n    background-color: ", ";\n    opacity: 0.5;\n    border-radius: 5px;\n  }\n\n  &:hover::before {\n    background-color: ", ";\n  }\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
export { Wrapper, ContentOuterWrapper, ContentWrapper, ResizeHandle };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
