var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetTenant } from 'api/mutations/useSetTenant';
import { useLogout } from 'api/mutations/useLogout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppVersion, Icon, Mask } from 'base-components';
import { useLongPress } from 'use-long-press';
import { navigationActions, tenantActions, tenantSelectors, uiActions, uiSelectors, } from 'store';
import { DATE_TIME_DEEP_LINK_PARAMS, DRAWER_DEEP_LINK_PARAMS, LOGIN_PATH, MODAL_DEEP_LINK_PARAMS, SEARCH_QUERY, WORKSPACE_PATH, } from 'consts';
import Analytics from 'analytics';
import { useBypassSSO, useDialog, useSetPageTitle } from 'utils';
import { useAppLocation } from 'router/hooks';
import { hasValidParamInSearchQuery } from 'router/utils';
import { AppAccessRestrictedDialog } from 'utils/hooks/useAppAccess';
import { Logo } from 'components/base/Logo';
import { TenantInput } from './TenantInput/TenantInput';
import { Actions, ButtonContents, Error, Form, IconContainer, StyledButton, SubTitle, Title, TitleContainer, Wrapper, } from './styled';
import { PastTenants } from './PastTenants/PastTenants';
var getErrorMessage = function (_a) {
    var error = _a.error, tenantId = _a.tenantId, t = _a.t;
    var status = error.httpStatus;
    var message = t('scenes.tenant.errors.forbidden', { tenantId: tenantId });
    var defaultErrorMessage = t('scenes.tenant.errors.default');
    return status === 403 ? message : defaultErrorMessage;
};
var SSO_OVERRIDE = 5000; /* SSO Override press and hold time */
var isWorkspaceValid = function (tenantId, errorMessages) {
    var workspaceRe = /^[a-zA-Z0-9\\.-]*$/;
    var workspace = tenantId.trim();
    var success = true;
    var error = null;
    if (!workspace) {
        success = false;
        error = errorMessages.REQUIRED;
        return { success: success, error: error };
    }
    if (!workspaceRe.test(workspace)) {
        success = false;
        error = errorMessages.NOT_VALID;
        return { success: success, error: error };
    }
    return { success: success, error: error };
};
var Tenant = function () {
    var activateBypassSSO = useBypassSSO().activateBypassSSO;
    var search = useAppLocation().search;
    var tenantId = new URLSearchParams(search).get(SEARCH_QUERY.WORKSPACE);
    var _a = React.useState(tenantId !== null && tenantId !== void 0 ? tenantId : ''), tenantValue = _a[0], setTenantValue = _a[1];
    var navigate = useNavigate();
    var currentTenant = useSelector(tenantSelectors.getTenantId);
    var appAccessDialogVisible = useSelector(uiSelectors.getIsAppAccessDialogVisible);
    var _b = React.useState(''), error = _b[0], setError = _b[1];
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var openDialog = useDialog().openDialog;
    var _c = useLogout({
        onError: function () {
            // Anytime an error occurs on logout we need to clear existing tenant data.
            // This handles case when a user that is not authenticated and revokeTokens
            // is called (user navigates back  using the browser navigation from the login page).
            dispatch(tenantActions.clear());
        },
    }), logout = _c.mutate, isLogoutLoading = _c.isLoading;
    var workspaceErrorMessages = {
        REQUIRED: t('scenes.tenant.errors.workspaceRequired'),
        NOT_VALID: t('scenes.tenant.errors.workspaceNotValid'),
    };
    var _d = useSetTenant({
        onSuccess: function () {
            var hasDeepLink = hasValidParamInSearchQuery(search, __assign(__assign(__assign({}, DRAWER_DEEP_LINK_PARAMS), MODAL_DEEP_LINK_PARAMS), DATE_TIME_DEEP_LINK_PARAMS));
            if (hasDeepLink) {
                dispatch(navigationActions.setDeepLinkQueryString(search));
            }
            navigate({
                pathname: LOGIN_PATH,
                search: search,
            });
        },
        onError: function (error, tenantId) {
            var errorMessage = getErrorMessage({
                error: error,
                tenantId: tenantId,
                t: t,
            });
            setError(errorMessage);
        },
    }), doSetTenant = _d.mutate, isSetTenantLoading = _d.isLoading, isSetTenantError = _d.isError;
    var hasTenantChanged = Boolean(currentTenant && tenantId && currentTenant !== tenantId);
    var hasWorkspacePathInstance = Boolean(tenantId && !isSetTenantError);
    useEffect(function () {
        if (hasWorkspacePathInstance) {
            doSetTenant(tenantId);
        }
    }, [hasWorkspacePathInstance, tenantId, doSetTenant]);
    useEffect(function () {
        if (hasTenantChanged) {
            dispatch(tenantActions.setNextTenantId({ nextTenantId: tenantId }));
            logout({ tenantId: currentTenant });
        }
    }, [currentTenant, dispatch, hasTenantChanged, logout, tenantId]);
    useEffect(function () {
        if (appAccessDialogVisible) {
            openDialog(React.createElement(AppAccessRestrictedDialog, null));
            dispatch(uiActions.hideAppAccessDialog());
        }
    }, [appAccessDialogVisible, dispatch, openDialog]);
    useSetPageTitle(t('documentTitles.tenantLogin'));
    var onInputChange = function (evt) {
        var value = evt.currentTarget.value;
        if (error) {
            setError('');
        }
        if (tenantId) {
            navigate(WORKSPACE_PATH, { replace: true });
        }
        setTenantValue(value);
    };
    var onTenantClick = function (evt) {
        if (evt && evt.preventDefault)
            evt.preventDefault();
        var result = isWorkspaceValid(tenantValue, workspaceErrorMessages);
        if (result.success) {
            Analytics.track('EVT-004');
            doSetTenant(tenantValue.toLowerCase());
        }
        else if (result.error) {
            setError(result.error);
        }
    };
    var onTenantButtonClick = function (tenantId) {
        Analytics.track('EVT-004');
        doSetTenant(tenantId.toLowerCase());
    };
    var longPressHandlers = useLongPress(function () {
        activateBypassSSO();
        Analytics.track('EVT-003');
        onTenantClick();
    }, { threshold: SSO_OVERRIDE });
    // show loading indicator until the tenant is set
    if (hasWorkspacePathInstance) {
        return React.createElement(Mask, { isLoading: true });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Mask, { isLoading: isSetTenantLoading || isLogoutLoading }),
        React.createElement(Wrapper, null,
            React.createElement("button", __assign({}, longPressHandlers),
                React.createElement(Logo, null)),
            React.createElement(TitleContainer, null,
                React.createElement(Title, null, t('scenes.tenant.title')),
                React.createElement(SubTitle, null, t('scenes.tenant.subtitle'))),
            React.createElement(Form, null,
                React.createElement(TenantInput, { onChange: onInputChange, value: tenantValue }),
                React.createElement(PastTenants, { onItemClick: onTenantButtonClick }),
                React.createElement(Actions, null,
                    React.createElement(StyledButton, { onClick: onTenantClick, type: "submit" },
                        React.createElement(ButtonContents, null,
                            t('common.continue'),
                            React.createElement(IconContainer, null,
                                React.createElement(Icon, { shouldRotateOnRtl: true, name: "disclosure", size: 10 })))))),
            error ? (React.createElement(Error, { "data-testid": "tenant-screen-error-message" }, error)) : null,
            React.createElement(AppVersion, null))));
};
export default Tenant;
