import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsTeamBookingDeskAvailable } from '@serraview/engage-shared';
import { teamReservationsSelectors } from 'store';
import { useTeamBookingDetails } from 'api/queries';
import { EmptyCard } from 'components/base';
import { useFocusSpace, useSpaceMenu, useStartCheckIn, useTeamBookingStartDate, } from 'utils';
import useSpaceCheck from 'components/scenes/drawer/hooks/useSpaceCheck';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useReserveRouteData } from '../Reserve/hooks/useReserveRouteData';
import { useReserveQueries } from '../Reserve/hooks/useReserveQueries';
import ReserveCardSkeleton from '../Reserve/Card/ReserveCardSkeleton';
import { useTeamBookingDetailsCallbacks, useTeamDeskCheckIn } from './hooks';
import { DeskCheckIn } from './DeskCheckIn';
export function DeskCheckInContainer() {
    var t = useTranslation().t;
    var teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);
    var reserveRouteData = useReserveRouteData();
    var _a = reserveRouteData !== null && reserveRouteData !== void 0 ? reserveRouteData : {}, spaceId = _a.id, closeLinkPath = _a.closeLinkPath, isDesk = _a.isDesk;
    var _b = useReserveQueries(spaceId), isLoading = _b.isLoading, spaceItem = _b.spaceItem, bookingsData = _b.bookingsData, spaceAttributes = _b.spaceAttributes;
    var menu = useSpaceMenu({
        spaceId: spaceId,
        spaceAttributes: spaceAttributes,
        isDesk: isDesk,
        isFavourite: spaceItem === null || spaceItem === void 0 ? void 0 : spaceItem.isFavorite,
    });
    var teamBookingDetailsCallbacks = useTeamBookingDetailsCallbacks();
    var _c = useTeamBookingDetails(teamBookingId, teamBookingDetailsCallbacks), teamBookingDetails = _c.data, isTeamBookingLoading = _c.isLoading;
    var _d = useTeamDeskCheckIn({
        teamBookingDetails: teamBookingDetails !== null && teamBookingDetails !== void 0 ? teamBookingDetails : null,
        spaceId: Number(spaceId),
    }), checkIn = _d.checkIn, isCheckingIn = _d.isLoading;
    var startCheckIn = useStartCheckIn(checkIn);
    var teamBookingStartDate = useTeamBookingStartDate();
    var isAvailable = getIsTeamBookingDeskAvailable(teamBookingId, Number(spaceId), bookingsData === null || bookingsData === void 0 ? void 0 : bookingsData.bookings);
    useSpaceCheck({ id: spaceId, isDesk: isDesk });
    useFocusSpace(spaceId);
    var closeIconA11yLabel = 'accessibilityLabels.closeLayout_deskCheckInContainer';
    if (isLoading || isTeamBookingLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, isCloseIcon: true, closeIconA11yLabel: closeIconA11yLabel }),
            React.createElement(ReserveCardSkeleton, null)));
    }
    if (!reserveRouteData || !spaceItem || !teamBookingDetails) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, isCloseIcon: true, closeIconA11yLabel: closeIconA11yLabel }),
            React.createElement(EmptyCard, { iconName: "warning" }, t('common.networkError'))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, menu: menu, isCloseIcon: true, closeIconA11yLabel: closeIconA11yLabel }),
        React.createElement(DeskCheckIn, { spaceItem: spaceItem, startDate: teamBookingStartDate, isAvailable: isAvailable, isCheckingIn: isCheckingIn, onCheckIn: startCheckIn })));
}
