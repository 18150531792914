import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { TextWrapper, Wrapper } from './styled';
var SpaceCardSkeleton = function () { return (React.createElement(Wrapper, null,
    React.createElement(Skeleton, { circle: true, height: 112, width: 112 }),
    React.createElement(TextWrapper, null,
        React.createElement("h5", null,
            React.createElement(Skeleton, { width: 100 })),
        React.createElement("span", { className: "btn" },
            React.createElement(Skeleton, { width: 200 }))))); };
export default SpaceCardSkeleton;
