import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { childrenPropTypes } from 'utils';
import { Icon } from '../Icons/Icon';
import { Wrapper, Children } from './styled';

const EmptyCard = ({ iconName, iconSize, children, isErrorFloorplan }) => {
  const { color9 } = useTheme();
  return (
    <Wrapper data-testid="empty-card" isErrorFloorplan={isErrorFloorplan}>
      {iconName && (
        <Icon name={iconName} size={iconSize} style={{ color: color9 }} />
      )}
      <Children>{children}</Children>
    </Wrapper>
  );
};

EmptyCard.propTypes = {
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  children: childrenPropTypes,
  isErrorFloorplan: PropTypes.bool,
};
EmptyCard.defaultProps = {
  iconName: null,
  iconSize: 24,
  children: null,
  isErrorFloorplan: false,
};

export default memo(EmptyCard);
