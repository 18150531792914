var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'components/base';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 15px 30px 22px 30px;\n"], ["\n  padding: 15px 30px 22px 30px;\n"])));
var TitleFirstRowWrapper = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
var TitleFirstRowAndIconWrapper = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n"])));
var TitleFirstRow = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 20px;\n  margin-top: 2px;\n  margin-bottom: 5px;\n  cursor: pointer;\n  text-decoration: none;\n  color: ", ";\n\n  * + & {\n    margin-left: 10px;\n  }\n"], ["\n  font-weight: bold;\n  font-size: 20px;\n  margin-top: 2px;\n  margin-bottom: 5px;\n  cursor: pointer;\n  text-decoration: none;\n  color: ", ";\n\n  * + & {\n    margin-left: 10px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
var TitleSecondRow = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  pointer-events: ", ";\n  font-size: 16px;\n  margin-top: 2px;\n  margin-bottom: 5px;\n"], ["\n  pointer-events: ", ";\n  font-size: 16px;\n  margin-top: 2px;\n  margin-bottom: 5px;\n"])), function (props) { return props.pointerEvents; });
var StyledSubtitleButton = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 0;\n  display: flex;\n  width: fit-content;\n  font-weight: 400;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  padding: 0;\n  display: flex;\n  width: fit-content;\n  font-weight: 400;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])));
var StyledMenuButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 150px;\n  height: 26px;\n  width: 26px;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 150px;\n  height: 26px;\n  width: 26px;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])));
var StyledMenuIcon = styled(Icon)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  transform: rotate(", "deg);\n  transition: all 300ms;\n"], ["\n  transform: rotate(", "deg);\n  transition: all 300ms;\n"])), function (_a) {
    var $opened = _a.$opened;
    return ($opened ? 0 : 90);
});
var ButtonsWrapper = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  z-index: 2;\n  display: flex;\n\n  .button-wrapper + .button-wrapper {\n    margin-left: 10px;\n  }\n"], ["\n  position: relative;\n  z-index: 2;\n  display: flex;\n\n  .button-wrapper + .button-wrapper {\n    margin-left: 10px;\n  }\n"])));
var StyledButton = styled(Link)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 150px;\n  height: 26px;\n  width: 26px;\n  transition: all 300ms ease-in-out;\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 150px;\n  height: 26px;\n  width: 26px;\n  transition: all 300ms ease-in-out;\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var $withoutBackground = _a.$withoutBackground;
    return $withoutBackground ? 'none' : '#eaeaea';
}, function (_a) {
    var $withoutBackground = _a.$withoutBackground;
    return $withoutBackground ? 'none' : '#c1c1c1';
});
export { Wrapper, TitleFirstRowWrapper, TitleFirstRowAndIconWrapper, TitleFirstRow, TitleSecondRow, StyledButton, StyledSubtitleButton, StyledMenuButton, ButtonsWrapper, StyledMenuIcon, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
