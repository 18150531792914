import { useMemo } from 'react';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
import { PATH_SEGMENT } from 'consts';
import { useAppLocation } from 'router/hooks';
export function useRestrictTo() {
    var pathname = useAppLocation().pathname;
    return useMemo(function () {
        if (pathname.includes("/".concat(PATH_SEGMENT.SPACES)))
            return RESTRICT_TO_VALUES.SPACE;
        if (pathname.includes("/".concat(PATH_SEGMENT.DESKS)))
            return RESTRICT_TO_VALUES.DESK;
        if (pathname.includes("/".concat(PATH_SEGMENT.PEOPLE)))
            return RESTRICT_TO_VALUES.PERSON;
        if (pathname.includes("/".concat(PATH_SEGMENT.TEAMS)))
            return RESTRICT_TO_VALUES.TEAM;
        return null;
    }, [pathname]);
}
