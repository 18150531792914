import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { uiActions, uiSelectors } from 'store';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { FocusId } from 'utils/hooks';
import { MenuCard, MenuCardAction, MenuCardItem, MenuList, } from './DrawerMenu.styles';
export var DrawerMenu = function (_a) {
    var menu = _a.menu;
    var dispatch = useDispatch();
    var ref = useRef(null);
    var isVisible = useSelector(uiSelectors.getDrawerMenuVisibility);
    var closeMenu = useCallback(function () {
        dispatch(uiActions.setDrawerMenuVisibility(false));
    }, [dispatch]);
    useOnClickOutside({
        ref: ref,
        handler: closeMenu,
        disallowList: [FocusId.DrawerMenuButton, FocusId.DrawerCloseButton],
    });
    return (React.createElement(CSSTransition, { in: isVisible, classNames: "drawer-menu", timeout: 300 },
        React.createElement(MenuCard, { "data-testid": "drawer-menu-wrapper", "data-test-is-visible": isVisible, ref: ref },
            React.createElement(MenuList, { role: "menu" }, menu === null || menu === void 0 ? void 0 : menu.map(function (_a) {
                var name = _a.name, onPress = _a.onPress;
                return (React.createElement(MenuCardItem, { role: "menuitem", key: name },
                    React.createElement(MenuCardAction, { "aria-label": name, "data-testid": name, onClick: function () {
                            onPress();
                            closeMenu();
                        } }, name)));
            })))));
};
