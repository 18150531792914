import React from 'react';
import { TimelineSelector } from 'components/base';
import { MODALS, DATE_TIME_PICKER_VARIANT } from 'consts';
import { useDatePicker, useModal } from 'utils/hooks';
import { useConfig } from 'utils';
import { isElectron } from 'utils/electron';
import { useDispatch } from 'react-redux';
import { inAppNotificationsActions } from 'store';
import { useTranslation } from 'react-i18next';
import { TimeLinesWrapper } from './styled';
export function DateTimeSelector() {
    var _a = useDatePicker(), dateStart = _a.dateStart, defaultStartDate = _a.defaultStartDate, onClear = _a.onClear, timeZone = _a.timeZone;
    var _b = useModal(), layout = _b.layout, content = _b.content, openModal = _b.openModal;
    var kioskRestrictBookingToCurrentDay = useConfig().kioskRestrictBookingToCurrentDay;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var isKiosk = isElectron();
    var isDatePickerOpened = layout === MODALS.DATE_TIME_PICKER;
    var disableInteractions = isDatePickerOpened && content.variant !== DATE_TIME_PICKER_VARIANT.TIMELINE;
    var dateStartApplied = dateStart || defaultStartDate;
    var toggleDateTimeSelector = function () {
        if (isKiosk && kioskRestrictBookingToCurrentDay) {
            dispatch(inAppNotificationsActions.addWarningNotification({
                message: t('layout.notifications.kioskCurrentDayBookingOnly'),
            }));
            return;
        }
        openModal(MODALS.DATE_TIME_PICKER, {
            variant: DATE_TIME_PICKER_VARIANT.TIMELINE,
        });
    };
    return (React.createElement(TimeLinesWrapper, null,
        React.createElement(TimelineSelector, { onDateClick: toggleDateTimeSelector, onDateClear: onClear, selectedDate: dateStartApplied, timeZone: timeZone, isDatePickerOpened: isDatePickerOpened, disableInteractions: disableInteractions })));
}
