var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ModalButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  border-radius: 99px;\n  text-align: center;\n  padding-left: 0;\n  background-color: ", ";\n  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.3);\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: inline-block;\n  border-radius: 99px;\n  text-align: center;\n  padding-left: 0;\n  background-color: ", ";\n  box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.3);\n  :focus-within {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color10;
});
export var ModalStyledButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 22px 105px;\n"], ["\n  cursor: pointer;\n  padding: 22px 105px;\n"])));
export var ModalButtonText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: 600;\n  color: ", ";\n"], ["\n  font-size: 18px;\n  font-weight: 600;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
var templateObject_1, templateObject_2, templateObject_3;
