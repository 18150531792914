import { css } from 'styled-components';

/**
 * Add no_select class to component to prevent text selection
 */
const noSelect = css`
  .no_select {
    -webkit-user-select: none; /* Chrome, Opera (older versions) */
    -webkit-touch-callout: none; /* Safari */
    -moz-user-select: none; /* Mozilla */
    -ms-user-select: none;
    user-select: none;
  }
`;

/**
 * Add no_print class to component to prevent printing
 */
const noPrint = css`
  @media print {
    .no_print {
      display: none;
    }
  }
`;

export default css`
  ${noSelect}
  ${noPrint}
`;
