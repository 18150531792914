import styled from 'styled-components';

const HEADER_HEIGHT = 91;

export const BrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding-top: ${HEADER_HEIGHT}px;
`;

export const BrowserIFrame = styled.iframe`
  border: none;
  width: 100vw;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  background-color: ${({ theme }) => theme.color3};
`;

export const BrowserInvalidURL = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BrowserContent = styled.iframe`
  border: none;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: green;
`;

export const BrowserLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: ${({ theme }) => theme.color3};
  left: 0;
  top: ${HEADER_HEIGHT}px;
  right: 0;
  bottom: 0;
`;
