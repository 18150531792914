import styled from 'styled-components';
import { FixedSizeList } from 'react-window';

export const StyledFixedSizeList = styled(FixedSizeList)`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.gray4};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ListWrapper = styled.div`
  margin-top: 15px;
  height: 96px;

  @media (min-height: 800px) {
    height: 192px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
  direction: ltr;
  max-width: 325px;
`;

export const ListItemText = styled.label`
  font-size: 16px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
`;

export const ErrorWrapper = styled.div`
  padding: 15px;
`;
