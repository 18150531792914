var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSelector } from 'react-redux';
import { useMultiDayTeamBookingMutation as useSharedMultiDayTeamBookingMutation, } from '@serraview/engage-shared';
import { tenantSelectors, userConfigSelectors } from 'store';
export var useMultiDayTeamBookingMutation = function (_a) {
    var teamId = _a.teamId, summary = _a.summary, timeZone = _a.timeZone, attendees = _a.attendees, desks = _a.desks, filter = _a.filter, selectedDays = _a.selectedDays, onSuccess = _a.onSuccess, onError = _a.onError;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var multiDayTeamBookingMutation = useSharedMultiDayTeamBookingMutation({
        tenantId: tenantId,
        teamId: teamId,
        summary: summary,
        timeZone: timeZone,
        attendees: attendees,
        desks: desks,
        filter: filter,
        selectedDays: selectedDays,
        successCallback: function () {
            // TODO: add analytics
        },
        errorCallback: function () {
            // TODO: add analytics
        },
        locale: locale,
    }, {
        onSuccess: onSuccess,
        onError: onError,
    });
    var onBooking = multiDayTeamBookingMutation.onBooking;
    return __assign(__assign({}, multiDayTeamBookingMutation), { onBooking: onBooking });
};
