import { toDate } from 'date-fns';
import isEqual from 'lodash.isequal';
import { VISIBILITY } from 'consts';

/**
 * Checks if a string can be converted to a valid number
 * @param value
 * @returns {boolean}
 */
const isStringNumeric = (value) => {
  if (typeof value === 'string' && value.trim() === '') {
    return false;
  }
  //
  if (typeof value === 'string' && value[0] === '0') {
    return false;
  }

  const number = Number(value);
  if (number === Infinity || number === -Infinity) {
    return false;
  }

  // eslint-disable-next-line eqeqeq
  return value == number;
};

// it is possible from BE side to set floor name to be a string
// if it is not a "just number" we just display it as it is
// if it is "just number" we will go format it to be 1st, 2nd, 4th
function humanizeNumber(string, translate) {
  if (!isStringNumeric(string)) return string;
  const sufficeOne = translate('plurals.one');
  const sufficeTwo = translate('plurals.two');
  const sufficeFew = translate('plurals.few');
  const sufficeMany = translate('plurals.many');

  const number = parseInt(string, 10);
  if (number % 100 >= 11 && number % 100 <= 13)
    return `${number}${sufficeMany}`;
  switch (number % 10) {
    case 1:
      return `${number}${sufficeOne}`;
    case 2:
      return `${number}${sufficeTwo}`;
    case 3:
      return `${number}${sufficeFew}`;
    default:
      return `${number}${sufficeMany}`;
  }
}

const removeArrayItem = (array, value) =>
  array.filter((item) => item !== value);

const insertArrayItem = (array, item) => {
  const newArray = [...array];
  newArray.push(item);
  return newArray;
};

/**
 * Check a value is boolean
 * @param value
 * @returns {boolean}
 */
const isBoolean = (value) =>
  typeof value === 'boolean' || value === 'false' || value === 'true';

const replaceLineFeedWithSpace = (text) =>
  text ? text.replace('\x0A', ' ') : '';

const getFileNameFromUrl = (url) =>
  url ? url.substring(url.lastIndexOf('/') + 1, url.indexOf('?')) : null;

const getFileExtension = (fileName) => {
  const lastIndex = fileName.lastIndexOf('.');
  return lastIndex > 0 ? fileName.substr(lastIndex + 1).toLowerCase() : null;
};

const isObjectEmpty = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const getDateFromDateObject = (date) => {
  const dateCopy = toDate(date);
  dateCopy.setHours(0, 0, 0, 0);
  return dateCopy;
};

const isDateInArray = (date, array = []) =>
  array.some(
    (arrayDate) =>
      getDateFromDateObject(arrayDate).getTime() ===
      getDateFromDateObject(date).getTime(),
  );

/**
 * Allows to sequentially call multiple functions with the same dataset
 * Each function takes the output of the previous one
 * @example
 * const join = x => x.join(' ');
 * const toUpper = x => x.toUpperCase();
 * pipe(join, toUpper)(['hello', 'world']) // 'HELLO WORLD'
 */
const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);

/**
 * @callback mapFunctionCallback
 * @param {string} key - key of the given object
 * @param {any} value - value by the key, specified in the first argument
 */

/**
 * Allow to apply map function to object
 * @param {Object} obj - Object which should be iterated
 * @param {mapFunctionCallback} mapFn - map function
 * @returns {Object}
 */
const mapObject = (obj, mapFn) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const newValue = mapFn(key, value);
      return [key, newValue];
    }),
  );

const getClosestNumber = (num, arr) =>
  arr.reduce((a, b) => (Math.abs(b - num) < Math.abs(a - num) ? b : a));

/**
 * Check if a passed number is in a given range or is Nan, used for NumberInputCounter
 * @param number
 * @param maxValue
 * @param minValue
 * @returns {boolean}
 */
const isInputValid = (number, maxValue, minValue) =>
  Number.isNaN(number) || (number <= maxValue && number >= minValue); // When input filed is empty we got Nan

const sortByAlphaNum = (a, b) => {
  if (typeof a !== 'string' || typeof b !== 'string') {
    return 1;
  }
  return a.localeCompare(b, undefined, { numeric: true });
};

const getLocalTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const getIsVisible = (visibility) => visibility === VISIBILITY.SHOW;

const openExternalURL = (url, target) => window.open(url, target);

const firstLetterCapitalize = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const getBuildingAndFloorNames = (building, floor, t) => {
  const buildingName = building?.name ?? '';
  const floorName = floor?.name
    ? `${humanizeNumber(floor.name, t)} ${t('common.floor')}`
    : '';

  return {
    buildingName,
    floorName,
  };
};

const focusElementById = (id) => {
  const element = document.getElementById(id);
  element?.focus();
};

const changeLocationHref = (href) => {
  window.location.href = encodeURI(href);
};

export {
  isStringNumeric,
  isBoolean,
  isEqual as isValuesEqual,
  humanizeNumber,
  replaceLineFeedWithSpace,
  getFileExtension,
  getFileNameFromUrl,
  isObjectEmpty,
  isDateInArray,
  removeArrayItem,
  insertArrayItem,
  pipe,
  mapObject,
  getClosestNumber,
  isInputValid,
  sortByAlphaNum,
  getLocalTimeZone,
  getIsVisible,
  openExternalURL,
  firstLetterCapitalize,
  getBuildingAndFloorNames,
  focusElementById,
  changeLocationHref,
};
