var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { Overlay, Content, StyledFocusLock } from './styled';
export var ModalContext = React.createContext({});
var Modal = function (_a) {
    var handleClose = _a.handleClose, _b = _a.style, style = _b === void 0 ? {} : _b, className = _a.className, _c = _a.children, children = _c === void 0 ? null : _c, _d = _a.customContent, customContent = _d === void 0 ? false : _d, overlayProps = __rest(_a, ["handleClose", "style", "className", "children", "customContent"]);
    var renderContent = function () {
        if (customContent) {
            return children;
        }
        return (React.createElement(Content, { "data-testid": "modal-content", "$style": style, className: className, onClick: function (e) { return e.stopPropagation(); } }, children));
    };
    return (React.createElement(ModalContext.Provider, { value: { handleClose: handleClose } },
        React.createElement(Overlay, __assign({ role: "dialog", "aria-hidden": "true", "data-testid": "modal-overlay", onClick: handleClose }, overlayProps),
            React.createElement(StyledFocusLock, null, renderContent()))));
};
export default memo(Modal);
