import React from 'react';
import ClientLogo from '../../../base/ClientLogo/ClientLogo';
import { ButtonWrapper, TenantName } from './styled';
export var TenantButton = function (_a) {
    var tenant = _a.tenant, onClick = _a.onClick;
    var name = tenant.name, logo = tenant.logo, id = tenant.id;
    var handleKeyDown = function (event) {
        if (event.key === 'Enter') {
            onClick();
        }
    };
    return (React.createElement(ButtonWrapper, { role: "button", onKeyDown: handleKeyDown, tabIndex: 0, onClick: onClick },
        React.createElement(ClientLogo, { id: id, logoURL: logo, title: name, fallback: React.createElement(TenantName, { title: name }, name) })));
};
