var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import i18n from 'i18next';
import { browserLanguage } from 'utils';
import { initReactI18next } from 'react-i18next';
import en from '../../assets/translations/en/en.json';
import fr from '../../assets/translations/fr/fr.json';
import frCA from '../../assets/translations/fr/fr-CA.json';
import enAU from '../../assets/translations/en/en-AU.json';
import enGB from '../../assets/translations/en/en-GB.json';
import es from '../../assets/translations/es/es.json';
import zh from '../../assets/translations/zh/zh.json';
import ja from '../../assets/translations/ja/ja.json';
import he from '../../assets/translations/he/he.json';
import de from '../../assets/translations/de/de.json';
import nl from '../../assets/translations/nl/nl.json';
import ko from '../../assets/translations/ko/ko.json';
var EN = { en: en };
var EN_AU = { 'en-AU': enAU };
var EN_GB = { 'en-GB': enGB };
var FR = { fr: fr };
var FR_CA = { 'fr-CA': frCA };
var ES = { es: es };
var ZH_CN = { 'zh-CN': zh };
var ZH = { zh: zh };
var JA = { ja: ja };
var HE = { he: he };
var DE = { de: de };
var NL = { nl: nl };
var KO = { ko: ko };
i18n.use(initReactI18next).init({
    resources: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, EN), EN_AU), EN_GB), FR), FR_CA), ES), ZH_CN), ZH), JA), HE), DE), NL), KO),
    fallbackLng: 'en',
    lng: browserLanguage,
    debug: __DEV__,
});
document.documentElement.lang = browserLanguage;
export default i18n;
