var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var PersonButtonWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var PersonButtonTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: bold;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  font-weight: bold;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var PersonButtonNameWrapper = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n  padding: 5px 10px;\n  border-radius: 20px;\n  margin-left: 5px;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  cursor: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n  padding: 5px 10px;\n  border-radius: 20px;\n  margin-left: 5px;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'not-allowed' : 'pointer');
}, function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return disabled ? '#cccccc' : theme.color9;
});
export var PersonButtonName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 5px;\n  font-size: 14px;\n  color: ", ";\n"], ["\n  margin-left: 5px;\n  font-size: 14px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return (disabled ? '#000000' : theme.typo1);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
