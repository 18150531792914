import styled from 'styled-components';

export const Version = styled.div`
  position: absolute;
  font-size: 12px;
  padding: 5px;
  bottom: 0;
  right: 0;

  b {
    color: ${({ theme }) => theme.color9};
  }
`;
