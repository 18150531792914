import { ToggleSwitch } from 'components/base/ToggleSwitch';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { stylePropType } from 'utils';
import ItemSkeleton from './ItemSkeleton';
import { ListItem, ListItemText } from './styled';

const Item = ({ index, style, data }) => {
  const { items, selected, onToggle } = data;
  const item = items?.[index];
  const name = item?.name;

  if (!item) {
    return <ItemSkeleton index={index} style={style} />;
  }

  return (
    <ListItem key={index} style={style} title={name} data-testid="filter-item">
      <ListItemText for={name}>{name}</ListItemText>

      <ToggleSwitch
        onChange={(checked) => onToggle({ id: item.id, checked })}
        checked={selected.includes(item.id)}
        ariaLabel={name}
        id={name}
      />
    </ListItem>
  );
};

Item.propTypes = {
  index: PropTypes.number.isRequired,
  style: stylePropType.isRequired,
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    selected: PropTypes.arrayOf(PropTypes.number).isRequired,
    onToggle: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(Item);
