import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isToday } from 'date-fns';
import { Day, DayOfWeek, Wrapper } from 'layouts/Agenda/content/Day/styled';
import { formatLocalizedDate } from '@serraview/engage-shared/utils';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import { DateFormat } from '@serraview/engage-shared';

const AgendaDay = ({ day }) => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const dayDate = day.getDate();
  const dayFormat = formatLocalizedDate(day, {
    locale,
    format: DateFormat.weekDayShort,
  });
  const _isToday = isToday(day);

  return (
    <Wrapper>
      <Day highlight={_isToday} aria-current={_isToday ? 'date' : undefined}>
        {dayDate}
      </Day>
      <DayOfWeek highlight={_isToday}>{dayFormat}</DayOfWeek>
    </Wrapper>
  );
};

AgendaDay.propTypes = {
  day: PropTypes.instanceOf(Date).isRequired,
};

export default memo(AgendaDay);
