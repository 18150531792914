import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateFormat, isTimeNow } from '@serraview/engage-shared';
import { formatLocalizedDate } from '@serraview/engage-shared/utils';
import { userConfigSelectors } from 'store';
import { focusElementById, noop, testProps } from 'utils';
import { FocusId } from 'utils/hooks';
import { DateButtonView } from './DateButtonView';

function DateButton({
  onDateClear,
  isOpen,
  onClick,
  selectedDate,
  disableInteractions,
  timeZone,
  isTimeFormatH12,
}) {
  const { t } = useTranslation();
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  const isNow = isTimeNow(selectedDate);

  // If time format is 24h pass pattern param otherwise use default pattern for 12h time format
  const formattedDate = formatLocalizedDate(selectedDate, {
    timeZone,
    locale,
    format: DateFormat.weekdayShortWithMonthAndDayAndTime,
    hour12: isTimeFormatH12,
  });
  const { props: a11yAndTestProps } = testProps(
    t,
    ['accessibilityLabels.showingAvailabilityFor', 'scenes.wayfinding.now'],
    ([showingAvailabilityFor, now]) =>
      // TODO formatted date is still in user's language
      `${showingAvailabilityFor} ${isNow ? now : formattedDate}`,
  );

  const onDateButtonClick = (e) => {
    onClick();
    e.stopPropagation();
  };

  const onDateClearButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDateClear();
    focusElementById(FocusId.TimelineButton);
  };

  return (
    <DateButtonView
      isNow={isNow}
      isOpen={isOpen}
      disableInteractions={disableInteractions}
      onClick={onDateButtonClick}
      id={FocusId.TimelineButton}
      {...a11yAndTestProps}
      clearButtonProps={{
        ...testProps(t, 'accessibilityLabels.now').props,
        onClick: onDateClearButtonClick,
      }}
    >
      {isNow ? t('scenes.wayfinding.now') : formattedDate}
    </DateButtonView>
  );
}

DateButton.propTypes = {
  onDateClear: PropTypes.func,
  onClick: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  timeZone: PropTypes.string,
  isOpen: PropTypes.bool,
  disableInteractions: PropTypes.bool,
  isTimeFormatH12: PropTypes.bool,
};
DateButton.defaultProps = {
  onDateClear: noop,
  onClick: noop,
  selectedDate: new Date(),
  timeZone: null,
  disableInteractions: false,
  isOpen: false,
  isTimeFormatH12: false,
};

export default memo(DateButton);
