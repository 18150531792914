import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigationActions } from 'store';
import { useBookingModal } from 'utils/hooks';
import { ConfirmationDialog } from 'components/base';
import { useDialog } from 'utils';
export var useTeamBookingCallbacks = function () {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var close = useBookingModal().close;
    var openDialog = useDialog().openDialog;
    var onBookingLoadError = function () {
        close();
        dispatch(navigationActions.clearModal());
        openDialog(React.createElement(ConfirmationDialog, { title: t('layout.booking.errorMessages.cancelledTitle'), message: t('layout.booking.errorMessages.cancelledMessage'), key: "booking-load-error", hasCancelButton: false }));
    };
    return {
        onSuccess: function (data) {
            if (!data) {
                onBookingLoadError();
            }
        },
        onError: onBookingLoadError,
    };
};
