var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getRotationString, getTransformForCoordinates, } from 'components/floorplan/utils/svgUtils';
import FloorplanRotationContext from 'components/floorplan/contexts/FloorplanRotationContext';
import styled from 'styled-components';
var IconWrapper = styled.g(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  path {\n    fill: ", "; // icon comes colored from serraview\n  }\n\n  circle {\n    transform: translate(-16px, -16px);\n  }\n"], ["\n  path {\n    fill: ", "; // icon comes colored from serraview\n  }\n\n  circle {\n    transform: translate(-16px, -16px);\n  }\n"])), function (props) { return props.fill; });
var RemoteMarkerIcon = function (_a) {
    var _b = _a.marker, icon = _b.icon, xCoordinate = _b.xCoordinate, yCoordinate = _b.yCoordinate;
    var rotation = useContext(FloorplanRotationContext);
    var _c = useState(23), width = _c[0], setWidth = _c[1];
    var _d = useState(23), height = _d[0], setHeight = _d[1];
    var gRef = useRef(null);
    useEffect(function () {
        if (gRef.current) {
            // determine real svg size, s we can draw it in accurate place
            var _a = gRef.current.getBBox(), gHeight = _a.height, gWidth = _a.width, x = _a.x, y = _a.y;
            setWidth((gWidth + x) / 2);
            setHeight((gHeight + y) / 2);
        }
    }, [icon]);
    if (!icon) {
        return null;
    }
    var style = getTransformForCoordinates({
        coordinates: {
            x: xCoordinate,
            y: yCoordinate,
        },
        width: width,
        height: height,
        scale: 0.9,
    });
    return (React.createElement("g", { style: style },
        React.createElement(IconWrapper, { fill: "white", ref: gRef, dangerouslySetInnerHTML: {
                // we need to add circle inside of rotating element, in order fot circle to rotate with icon
                __html: "\n          <circle cx=\"40\" cy=\"40\" r=\"45\" fill=\"black\" />\n          ".concat(icon, "\n        "),
            }, 
            // @ts-ignore
            style: getRotationString({ width: width, rotation: rotation, height: height }) })));
};
RemoteMarkerIcon.displayName = 'RemoteMarkerIcon';
export default RemoteMarkerIcon;
var templateObject_1;
