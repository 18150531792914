import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { tenantActions } from 'store';
import { useTenantFetch } from '@serraview/engage-shared/api';

export const useSetTenant = (options = {}) => {
  const dispatch = useDispatch();
  const { fetchTenantQuery } = useTenantFetch();

  return useMutation(fetchTenantQuery, {
    onSuccess: (data, tenantId) => {
      dispatch(
        tenantActions.setTenant({
          tenantId,
          config: data.config,
          theme: data.theme,
        }),
      );
      if (options?.onSuccess) {
        options.onSuccess(data, tenantId);
      }
    },
    onError: options?.onError,
  });
};
