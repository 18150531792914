var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { A11yHiddenLabel } from 'styles';
import { badgeColor, sizeDef } from './config';
import { Text } from '../Text/Text';
import { Badge, BadgeWrapper } from './styled';
var MAX_SIZE = 24;
var DIMENSION_FACTOR = 2.5;
var BORDER_WIDTH = 3;
var emptyObject = {};
var DEFAULT_ANCHOR = {
    horizontal: 'left',
    vertical: 'top',
};
var getBadgeColor = function (color) {
    if (!color)
        return badgeColor.primary;
    // @ts-ignore
    return badgeColor[color] || color;
};
var getBadgePosition = function (badgeSize, anchor, overlap, dimensions) {
    var _a;
    var horizontal = anchor.horizontal, vertical = anchor.vertical;
    var xy = -badgeSize / 2; // rectangle, rounded
    if (overlap === 'circle') {
        xy += dimensions / (BORDER_WIDTH * DIMENSION_FACTOR);
    }
    if (overlap === 'side') {
        xy += (dimensions * 1.9) / (BORDER_WIDTH * DIMENSION_FACTOR);
    }
    return _a = {},
        _a[horizontal] = xy,
        _a[vertical] = xy,
        _a;
};
var getBadgeStyle = function (size, maxSize, color, anchor, overlap) {
    var dimensions;
    if (typeof size === 'number') {
        dimensions = size;
    }
    else {
        dimensions = sizeDef[size] || sizeDef.medium;
    }
    var backgroundColor = getBadgeColor(color);
    var badgeSize = dimensions / DIMENSION_FACTOR;
    if (badgeSize >= maxSize) {
        badgeSize = maxSize;
    }
    var position = getBadgePosition(badgeSize, anchor, overlap, dimensions);
    return {
        width: badgeSize,
        height: badgeSize,
        borderRadius: badgeSize,
        backgroundColor: backgroundColor,
        position: position,
    };
};
var AvatarBadge = function (_a) {
    var _b = _a.color, color = _b === void 0 ? 'primary' : _b, badgeContent = _a.badgeContent, badgeA11yLabel = _a.badgeA11yLabel, _c = _a.max, max = _c === void 0 ? 99 : _c, _d = _a.size, size = _d === void 0 ? 'medium' : _d, _e = _a.maxSize, maxSize = _e === void 0 ? MAX_SIZE : _e, _f = _a.variant, variant = _f === void 0 ? 'standard' : _f, _g = _a.anchor, anchor = _g === void 0 ? DEFAULT_ANCHOR : _g, _h = _a.overlap, overlap = _h === void 0 ? 'circle' : _h, _j = _a.style, style = _j === void 0 ? emptyObject : _j, children = _a.children;
    var _k = getBadgeStyle(size, maxSize, color, anchor, overlap), height = _k.height, width = _k.width, borderRadius = _k.borderRadius, backgroundColor = _k.backgroundColor, position = _k.position;
    // @ts-ignore
    var wrapperSize = sizeDef[size] || size.medium;
    var textStyle = {
        whiteSpace: 'nowrap',
    };
    var renderComponent = function () {
        if (!badgeContent) {
            return null;
        }
        var _a = badgeContent.props, style = _a.style, size = _a.size;
        if (style) {
            style.color = style.color || backgroundColor;
        }
        var sizeProp = size || height;
        // Icon or Text
        var inner = React.cloneElement(badgeContent, __assign(__assign({}, badgeContent.props), { style: style, size: sizeProp }));
        return (React.createElement(Badge, { key: "component", style: __assign({}, position), "data-testid": "badgeComponent" },
            React.createElement(Text, null, inner)));
    };
    var getText = function () {
        var displayValue = (badgeContent || 0) > max ? "".concat(max, "+") : badgeContent;
        textStyle.fontSize = height / 2;
        textStyle.lineHeight = "".concat(height, "px");
        textStyle.paddingLeft = 8;
        textStyle.paddingRight = 8;
        return (React.createElement(Text
        // @ts-ignore
        , { 
            // @ts-ignore
            style: __assign(__assign({}, textStyle), { paddingHorizontal: 3 }), "data-testid": "badgeText" }, displayValue));
    };
    // priority: badgeContent > status
    var renderContent = function () {
        if (React.isValidElement(badgeContent)) {
            return renderComponent();
        }
        if (badgeContent || variant === 'dot') {
            var content = null;
            if (variant === 'standard') {
                content = getText();
            }
            return (React.createElement(Badge, { key: "text", style: __assign(__assign({ height: height, minWidth: width, backgroundColor: backgroundColor, borderRadius: borderRadius }, position), style), "data-testid": "badge", borderWidth: BORDER_WIDTH },
                badgeA11yLabel && (React.createElement(A11yHiddenLabel, null, badgeA11yLabel)),
                content));
        }
        return null;
    };
    return (React.createElement(BadgeWrapper, { style: { width: wrapperSize, height: wrapperSize } },
        children,
        renderContent()));
};
export default memo(AvatarBadge);
