var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getAppliedFilterDates } from '@serraview/engage-shared/utils';
import { filtersActions } from 'store';
import { useDatePicker } from 'utils';
import { DATE_TIME_PICKER_VARIANT } from 'consts';
export var useDTPConfig = function (_a) {
    var _b, _c;
    var variant = _a.variant;
    var dispatch = useDispatch();
    var isDraft = variant !== DATE_TIME_PICKER_VARIANT.TIMELINE;
    var _d = useDatePicker({ isDraft: isDraft }), dateStart = _d.dateStart, defaultStartDate = _d.defaultStartDate, timeZone = _d.timeZone, dateEnd = _d.dateEnd, defaultEndDate = _d.defaultEndDate, minDate = _d.minDate, setTimelineDate = _d.setTimelineDate, setDateStart = _d.setDateStart, setDateEnd = _d.setDateEnd, onClear = _d.onClear, maxDate = _d.maxDate;
    var setFilterValues = useCallback(function (values) {
        return dispatch(filtersActions.setFilterValues(__assign(__assign({}, values), { isDraft: isDraft })));
    }, [dispatch, isDraft]);
    var _e = getAppliedFilterDates({
        dateStart: dateStart,
        dateEnd: dateEnd,
        setFilterValues: setFilterValues,
        defaultStartDate: defaultStartDate,
        defaultEndDate: defaultEndDate,
    }), dateStartApplied = _e.dateStartApplied, dateEndApplied = _e.dateEndApplied;
    var setDateStartEnhanced = useCallback(function (date) {
        setDateStart(date);
    }, [setDateStart]);
    var _f = {
        selectedDate: (_b = {},
            _b[DATE_TIME_PICKER_VARIANT.TIMELINE] = dateStart || defaultStartDate,
            _b[DATE_TIME_PICKER_VARIANT.INTERVAL_START] = dateStartApplied,
            _b[DATE_TIME_PICKER_VARIANT.INTERVAL_END] = dateEndApplied,
            _b),
        onDateSelected: (_c = {},
            _c[DATE_TIME_PICKER_VARIANT.TIMELINE] = setTimelineDate,
            _c[DATE_TIME_PICKER_VARIANT.INTERVAL_START] = setDateStartEnhanced,
            _c[DATE_TIME_PICKER_VARIANT.INTERVAL_END] = setDateEnd,
            _c),
    }, selectedDate = _f.selectedDate, onDateSelected = _f.onDateSelected;
    return {
        selectedDate: selectedDate[variant],
        onDateSelected: onDateSelected[variant],
        onClear: onClear,
        timeZone: timeZone,
        minDate: minDate,
        maxDate: maxDate,
    };
};
