import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PATH_SEGMENT, RESTRICT_TO_VALUES } from 'consts';
import { FocusId, useGlobalSearchFilters, useRestrictTo } from 'utils/hooks';
import { LanguageTypes } from '@serraview/engage-shared';
import { Icon } from '../Icons/Icon';
import { FilterButton, FilterCountNumber } from './styled';

const GlobalSearchFilter = () => {
  const { t } = useTranslation();
  const restrictTo = useRestrictTo();
  const isPersonFilter = restrictTo === RESTRICT_TO_VALUES.PERSON;
  const { filtersChanged, numberOfAppliedFilters } = useGlobalSearchFilters();

  const getFiltersButtonText = (config) =>
    t(
      'layout.globalSearch.numberOfAppliedFilters',
      {
        countAppliedFilters: numberOfAppliedFilters,
        filter: isPersonFilter
          ? t('layout.globalSearch.firstResponders', config)
          : t('layout.globalSearch.buttonNoFilters', config),
      },
      config,
    );

  return (
    <FilterButton
      id={FocusId.GlobalSearchFilterButton}
      $filtersChanged={filtersChanged}
      aria-label={getFiltersButtonText()}
      data-testid={getFiltersButtonText({ lng: LanguageTypes.en })}
      role="button"
      name={t('accessibilityLabels.selectGlobalSearchFilters')}
      to={PATH_SEGMENT.FILTERS}
    >
      <Icon name={isPersonFilter ? 'checked-shield' : 'settings'} size={18} />
      {filtersChanged && (
        <FilterCountNumber>{numberOfAppliedFilters}</FilterCountNumber>
      )}
    </FilterButton>
  );
};

export default memo(GlobalSearchFilter);
