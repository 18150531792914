import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { filtersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import {
  PERSON_OF_INTEREST,
  PERSON_OF_INTEREST_TRANSLATION_MAPPING,
} from 'consts';
import { Subtitle, SubtitlePersonOfInterest } from './styled';

const PersonDescription = ({ item }) => {
  const { description, isFireWarden, isFirstAidOfficer } = item;
  const personFilters = useSelector(filtersSelectors.getPersonFilters);
  const isPoiFilter = personFilters.fireWarden || personFilters.firstAidOfficer;
  const { t } = useTranslation();
  const getNormalDescription = () => (
    <Subtitle className="btn">{description}</Subtitle>
  );

  const getPOIDescription = () => {
    const firstAidText = t(
      PERSON_OF_INTEREST_TRANSLATION_MAPPING[
        PERSON_OF_INTEREST.MEDICAL_OFFICER
      ],
    );
    const firstAidShortText = t('layout.personFilters.firstAid');
    const fireWardenText = t(
      PERSON_OF_INTEREST_TRANSLATION_MAPPING[PERSON_OF_INTEREST.FIRE_OFFICER],
    );

    let subtitle;
    if (isFireWarden && isFirstAidOfficer) {
      subtitle = `${firstAidShortText} / ${fireWardenText}`;
    } else {
      subtitle = isFirstAidOfficer ? firstAidText : fireWardenText;
    }
    return <SubtitlePersonOfInterest>{subtitle}</SubtitlePersonOfInterest>;
  };

  return (
    <>
      {isPoiFilter && (isFirstAidOfficer || isFireWarden)
        ? getPOIDescription()
        : getNormalDescription()}
    </>
  );
};

export default memo(PersonDescription);

PersonDescription.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    isFireWarden: PropTypes.bool,
    isFirstAidOfficer: PropTypes.bool,
  }),
};

PersonDescription.defaultProps = {
  item: {
    name: '',
    description: '',
    imageUrl: '',
    isFireWarden: false,
    isFirstAidOfficer: false,
  },
};
