import { useTeam as useTeamQuery } from '@serraview/engage-shared/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useFavouritePeople } from 'api/queries/useFavouritePeople';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
import { PATH_SEGMENT } from 'consts';
import { useTranslation } from 'react-i18next';
export var useTeam = function (_a) {
    var id = _a.id, teamFromState = _a.teamFromState;
    var t = useTranslation().t;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var isFavPeopleTeam = id === PATH_SEGMENT.FAV_PEOPLE_TEAM;
    var _b = useTeamQuery({
        id: +id,
        tenantId: tenantId,
        options: {
            enabled: !isFavPeopleTeam && !teamFromState,
        },
    }), teamQueryData = _b.data, teamQueryLoading = _b.isLoading, isError = _b.isError;
    var _c = useFavouritePeople({
        enabled: isFavPeopleTeam,
    }), peopleCount = _c.peopleCount, favPeopleIsLoading = _c.isLoading;
    if (isFavPeopleTeam) {
        return {
            data: {
                id: PATH_SEGMENT.FAV_PEOPLE_TEAM,
                name: t('common.favouritePeopleTeamName'),
                people: peopleCount,
                type: RESTRICT_TO_VALUES.TEAM,
                favPeopleTeam: true,
            },
            isLoading: favPeopleIsLoading,
        };
    }
    return { data: teamQueryData, isLoading: teamQueryLoading, isError: isError };
};
