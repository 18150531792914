import React from 'react';
import { useTheme } from 'styled-components';
import { getContrastingColor } from '@serraview/engage-shared';
import { CommonMarker, MARKER, } from 'components/floorplan/components/Marker/CommonMarker';
var ICON_SIZE = 26;
export var SpaceMarker = function (_a) {
    var space = _a.space;
    var _b = useTheme(), color6 = _b.color6, color9 = _b.color9, typo2 = _b.typo2;
    var iconName = space.isDesk ? 'desk' : 'space';
    var iconColor = getContrastingColor(color9, color6, typo2);
    return (React.createElement(CommonMarker, { space: space },
        React.createElement("use", { href: "#".concat(iconName), x: MARKER.CENTER - ICON_SIZE / 2, y: MARKER.CENTER - ICON_SIZE / 2, width: ICON_SIZE, height: ICON_SIZE, style: { color: iconColor } })));
};
