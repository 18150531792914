import styled from 'styled-components';

export const ReserveeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  margin: 15px 0;
`;

export const BookAsText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margin: 0 30px;
  color: ${(props) => props.theme.typo2};
`;
