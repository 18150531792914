import React from 'react';
import { getRotationString, getTransformForCoordinates, } from 'components/floorplan/utils/svgUtils';
import FloorplanRotationContext from 'components/floorplan/contexts/FloorplanRotationContext';
import { BouncingWrapper } from 'components/floorplan/components/HereMarker/styles';
import { HerePin } from 'components/floorplan/components/HereMarker/HerePin';
export var HereMarker = function (_a) {
    var coords = _a.coords, _b = _a.width, width = _b === void 0 ? 114 : _b, _c = _a.height, height = _c === void 0 ? 123 : _c;
    var getHereMarkerStyles = function (_a) {
        var rotation = _a.rotation;
        var coordsStyles = getTransformForCoordinates({
            width: width,
            height: height,
            scale: 1,
            coordinates: coords,
        });
        var rotationStyles = getRotationString({
            width: width,
            height: height,
            rotation: rotation,
        });
        return {
            width: width,
            transform: "".concat(coordsStyles.transform, " ").concat(rotationStyles.transform),
            transformOrigin: "".concat(width, "px ").concat(height, "px"),
        };
    };
    return (React.createElement(FloorplanRotationContext.Consumer, null, function (rotation) { return (React.createElement("g", { style: getHereMarkerStyles({ rotation: rotation }) },
        React.createElement(BouncingWrapper, null,
            React.createElement(HerePin, { width: width, height: height })))); }));
};
