import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Floorplan from 'floorplan/components/Floorplan/Floorplan';
import FESContext from 'floorplan/contexts/FESContext';
import Api from 'floorplan/services/api';
import { Api as axiosApi } from '@serraview/engage-shared/api';

const FloorplanComponent = React.forwardRef(
  (
    {
      onMessage,
      floorId,
      tenantId,
      hideRecoveryPane,
      restrictToSearchFilterValue,
      hidePeopleData,
    },
    ref,
  ) => {
    const FES = useRef({
      viewer: null,
      events: {},
      on(key, func) {
        // eslint-disable-next-line no-prototype-builtins
        if (!FES.current.events.hasOwnProperty(key)) {
          FES.current.events[key] = [func];
        } else {
          FES.current.events[key] = [...FES.current.events[key], func];
        }
      },
      clearAllHandlers(key) {
        FES.current.events[key] = [];
      },
      trigger(key, data) {
        // eslint-disable-next-line no-prototype-builtins
        if (FES.current.events.hasOwnProperty(key)) {
          const dataObj = data || {};

          FES.current.events[key].forEach((handler) => {
            handler(dataObj);
          });
        }
      },
      send(data) {
        onMessage(data);
      },
      setViewer(viewer) {
        FES.current.viewer = viewer;
      },
      getViewer() {
        return FES.current.viewer;
      },
    });

    // Has to be updated, since onMessage could have updated since ref initialization
    FES.current.send = (data) => {
      onMessage(data);
    };

    Api.setTenantId(tenantId);
    Api.setAxios(axiosApi);

    if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = FES.current;
    }

    return (
      <FESContext.Provider value={FES.current}>
        <Floorplan
          floorId={floorId}
          hideRecoveryPane={hideRecoveryPane}
          restrictToSearchFilterValue={restrictToSearchFilterValue}
          hidePeopleData={hidePeopleData}
        />
      </FESContext.Provider>
    );
  },
);

FloorplanComponent.propTypes = {
  onMessage: PropTypes.func,
  floorId: PropTypes.number.isRequired,
  tenantId: PropTypes.string.isRequired,
  hideRecoveryPane: PropTypes.func,
  restrictToSearchFilterValue: PropTypes.string,
  hidePeopleData: PropTypes.bool,
};

FloorplanComponent.defaultProps = {
  onMessage: () => {},
  hideRecoveryPane: () => {},
  restrictToSearchFilterValue: '',
  hidePeopleData: false,
};

export default FloorplanComponent;
