import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useAvailability as useAvailabilityHook } from '@serraview/engage-shared/api';
export var useAvailability = function (_a) {
    var _b;
    var startTime = _a.startTime, buildingId = _a.buildingId, _c = _a.options, options = _c === void 0 ? {} : _c;
    var currentLocation = useSelector(tenantSelectors.getCurrentLocation);
    var blId = buildingId || ((_b = currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.building) === null || _b === void 0 ? void 0 : _b.id);
    return useAvailabilityHook({
        buildingId: blId,
        startTime: startTime,
        options: options,
    });
};
