var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useReservationFilters } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { fetchAndFormatSpace } from 'api/spaces';
import { fetchSpace } from '@serraview/engage-shared/api';
import { useFloorLocation } from 'api/queries/useFloor';
export var SPACE_KEY = 'space';
var STALE_TIME = 10 * 1000;
var useSpaceTime = function (_a) {
    var isDesk = _a.isDesk;
    var _b = useReservationFilters(isDesk), startTime = _b.startTime, endTime = _b.endTime, enforceAllDayDeskReservations = _b.enforceAllDayDeskReservations;
    return {
        startTime: startTime,
        endTime: endTime,
        enforceAllDayDeskReservations: enforceAllDayDeskReservations,
    };
};
export var useSpace = function (_a) {
    var id = _a.id, _b = _a.isDesk, isDesk = _b === void 0 ? true : _b, _c = _a.isUpdate, isUpdate = _c === void 0 ? false : _c, _d = _a.options, options = _d === void 0 ? {} : _d;
    var _e = useSpaceTime({
        isDesk: isDesk,
    }), startTime = _e.startTime, endTime = _e.endTime, enforceAllDayDeskReservations = _e.enforceAllDayDeskReservations;
    return useQuery([SPACE_KEY, "".concat(id), startTime, endTime], function () {
        return fetchAndFormatSpace({
            id: id,
            isUpdate: isUpdate,
            enforceAllDayDeskReservations: enforceAllDayDeskReservations,
            startTime: startTime,
            endTime: endTime,
        });
    }, __assign({ staleTime: STALE_TIME }, options));
};
export var useSpaceFetch = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.isDesk, isDesk = _c === void 0 ? true : _c, _d = _b.isUpdate, isUpdate = _d === void 0 ? false : _d;
    var queryClient = useQueryClient();
    var _e = useSpaceTime({
        isDesk: isDesk,
    }), startTime = _e.startTime, endTime = _e.endTime, enforceAllDayDeskReservations = _e.enforceAllDayDeskReservations;
    var query = function (_a) {
        var id = _a.id;
        return queryClient.fetchQuery([SPACE_KEY, "".concat(id), startTime, endTime], function () {
            return fetchAndFormatSpace({
                id: id,
                isUpdate: isUpdate,
                enforceAllDayDeskReservations: enforceAllDayDeskReservations,
                startTime: startTime,
                endTime: endTime,
            });
        }, { staleTime: STALE_TIME });
    };
    var fetchSpaceQuery = React.useCallback(query, [
        endTime,
        enforceAllDayDeskReservations,
        isUpdate,
        queryClient,
        startTime,
    ]);
    return { fetchSpaceQuery: fetchSpaceQuery };
};
export var useRefreshSpace = function () {
    var queryClient = useQueryClient();
    var invalidateQuery = function (_a) {
        var spaceItem = _a.spaceItem;
        return queryClient.invalidateQueries([SPACE_KEY, spaceItem.id]);
    };
    var refresh = React.useCallback(invalidateQuery, [queryClient]);
    return { refresh: refresh };
};
export var useSpaceLocation = function () {
    var currentLocation = useSelector(tenantSelectors.getCurrentLocation);
    var setFloorLocation = useFloorLocation().setFloorLocation;
    var setSpaceLocation = React.useCallback(function (_a) {
        var spaceId = _a.spaceId;
        return __awaiter(void 0, void 0, void 0, function () {
            var floorLocation, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        floorLocation = currentLocation;
                        if (!spaceId) return [3 /*break*/, 3];
                        return [4 /*yield*/, fetchSpace(spaceId)];
                    case 1:
                        data = _b.sent();
                        if (!((data === null || data === void 0 ? void 0 : data.floorId) && (data === null || data === void 0 ? void 0 : data.floorName))) return [3 /*break*/, 3];
                        return [4 /*yield*/, setFloorLocation(data.floorId)];
                    case 2:
                        floorLocation = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, floorLocation];
                }
            });
        });
    }, [currentLocation, setFloorLocation]);
    return { setSpaceLocation: setSpaceLocation };
};
