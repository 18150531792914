var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTeamDeskReserve as useTeamDeskReserveMutation, } from '@serraview/engage-shared';
import { teamReservationsActions, tenantSelectors } from 'store';
import { ConfirmationDialog } from 'components/base';
import { FocusId, useDialog } from 'utils/hooks';
import { useMutation } from 'react-query';
import { checkInBooking } from '@serraview/engage-shared/api';
import { useRefreshTeamBooking } from 'api/queries';
/**
 * Reserve a desk and then check-in to that desk.
 */
export function useTeamDeskCheckIn(_a) {
    var _b;
    var spaceId = _a.spaceId, teamBookingDetails = _a.teamBookingDetails;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var openDialog = useDialog().openDialog;
    var refreshTeamBooking = useRefreshTeamBooking().refresh;
    var teamBookingId = teamBookingDetails === null || teamBookingDetails === void 0 ? void 0 : teamBookingDetails.meetingId;
    var deskBookingId = (_b = teamBookingDetails === null || teamBookingDetails === void 0 ? void 0 : teamBookingDetails.deskBookings.find(function (deskBooking) { return deskBooking.spaceId === spaceId; })) === null || _b === void 0 ? void 0 : _b.id;
    var handleError = function () {
        openDialog(React.createElement(ConfirmationDialog, { title: t('layout.floorplanError.errorOccurred'), message: t('layout.reserve.team.reserveDeskError'), key: "team-desk-reserve-error-dialog", triggerId: FocusId.TeamDeskReserve, hasCancelButton: false }));
    };
    var handleSuccess = function () {
        dispatch(teamReservationsActions.clearTeamBookingId());
        openDialog(React.createElement(ConfirmationDialog, { title: t('layout.bookingCard.checkInSuccessDialogTitle'), message: t('layout.bookingCard.checkInSuccessDialogMessage'), key: "team-desk-reserve-success-dialog", triggerId: FocusId.TeamDeskReserve, hasCancelButton: false }));
    };
    var _c = useMutation(checkInBooking, {
        onSuccess: function () {
            handleSuccess();
            if (teamBookingId) {
                refreshTeamBooking({ id: teamBookingId });
            }
        },
        onError: handleError,
    }), onCheckInMutation = _c.mutate, checkInMutationStatus = _c.status;
    var startCheckIn = function () {
        return onCheckInMutation({ tenantId: tenantId, spaceId: spaceId, meetingId: deskBookingId });
    };
    var _d = useTeamDeskReserveMutation({
        options: {
            onSuccess: function () { return startCheckIn(); },
            onError: handleError,
        },
    }), mutate = _d.mutate, mutation = __rest(_d, ["mutate"]);
    var reserveDesk = useCallback(function () { return (teamBookingId ? mutate({ tenantId: tenantId, spaceId: spaceId, teamBookingId: teamBookingId }) : {}); }, [tenantId, spaceId, teamBookingId, mutate]);
    return __assign(__assign({}, mutation), { status: checkInMutationStatus, checkIn: reserveDesk });
}
