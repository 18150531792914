import React from 'react';
import { useTheme } from 'styled-components';
import { getRotationString, getTransformString, } from 'components/floorplan/utils/svgUtils';
import FloorplanRotationContext from 'components/floorplan/contexts/FloorplanRotationContext';
var WIDTH = 66;
var HEIGHT = 75.22;
var CENTER = WIDTH / 2;
var SCALE = 2;
var PADDING = 7.5;
var RADIUS = CENTER - PADDING;
export var MARKER = {
    WIDTH: WIDTH,
    HEIGHT: HEIGHT,
    CENTER: CENTER,
    PADDING: PADDING,
    RADIUS: RADIUS,
};
export var CommonMarker = function (_a) {
    var space = _a.space, circleFill = _a.circleFill, children = _a.children;
    var _b = useTheme(), color9 = _b.color9, typo2 = _b.typo2;
    var _circleFill = circleFill || color9;
    return (React.createElement("g", { style: getTransformString({
            space: space,
            width: CENTER * SCALE,
            height: HEIGHT * SCALE,
            scale: SCALE,
        }) },
        React.createElement(FloorplanRotationContext.Consumer, null, function (rotation) { return (React.createElement("g", { style: getRotationString({
                width: CENTER,
                height: HEIGHT,
                rotation: rotation,
            }) },
            React.createElement("path", { d: "M57.5384 53.696C62.7988 47.9953 66 40.4518 66 32.1793C66 14.4072 51.2254 0 33 0C14.7746 0 0 14.4072 0 32.1793C0 41.2208 3.82402 49.3914 9.981 55.2371C10.0374 55.3415 10.1146 55.4398 10.2143 55.5272L32.3746 74.9641C32.7656 75.3069 33.3506 75.3051 33.7394 74.9599L53.6379 57.2907C54.7157 56.4473 55.738 55.5393 56.6988 54.5727L57.2686 54.0667C57.3928 53.9564 57.4815 53.8299 57.5384 53.696Z", fill: typo2 }),
            React.createElement("circle", { r: RADIUS, cx: CENTER, cy: CENTER, fill: _circleFill }),
            children)); })));
};
