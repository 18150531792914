import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DURATION_TYPES } from 'consts';
import { DurationButton } from './DurationButton';
import durationItems from './durationItems';
import {
  OrLabel,
  Label,
  DurationContainer,
  DurationButtonFieldset,
} from './styled';

const addLabelToDurationItems = (durationItems, t) =>
  durationItems.map((item) => {
    if (!item.display) return item;

    if (item.type === 'h') {
      return { ...item, text: `${item.display}${t('plurals.hoursShort')}` };
    }

    return item;
  });

const Duration = ({
  type,
  value,
  canDeselect,
  onChange,
  renderOtherComponent,
  allDaySelected,
  allDayDisabled,
  onAllDayToggle,
  disabled,
  onDurationOtherClick,
}) => {
  const [isOther, setIsOther] = useState(false);
  const { t } = useTranslation();

  const label = t('components.duration.bookingDuration');
  const optionsLabel = t('components.duration.other');
  const orLabel = t('components.duration.or');
  const allDayLabel = t('components.duration.allDay');

  useEffect(() => {
    // if exists preselected other value for space meeting duration
    if (
      type === DURATION_TYPES.MINUTES &&
      value > 0 &&
      value !== 15 &&
      value !== 30 &&
      value !== 60 &&
      !isOther
    ) {
      setIsOther(true);
    }
  }, [type, value, isOther]);

  const onDurationButtonClick = (buttonValue) => {
    let newValue = buttonValue;

    if (type === DURATION_TYPES.MINUTES && buttonValue <= 60) {
      setIsOther(false);
    }

    if (canDeselect && buttonValue === value) {
      // Toggle the selected index if the item is already selected.
      newValue = 0;
    }

    onChange(newValue);
  };

  const onDurationOtherButtonClick = (buttonValue) => {
    if (!renderOtherComponent) {
      onChange(0);
    }

    onDurationButtonClick(buttonValue);
    setIsOther(true);
    onDurationOtherClick();
  };

  const getDurationMinutes = () => (
    <DurationContainer>
      {addLabelToDurationItems(durationItems[type], t).map(
        ({ id, text, value: buttonValue }) => (
          <DurationButton
            key={id}
            text={text}
            value={buttonValue}
            onClick={onDurationButtonClick}
            selected={!isOther && value === buttonValue}
            disabled={disabled}
          />
        ),
      )}

      <OrLabel>{orLabel}</OrLabel>

      <div>
        {!!renderOtherComponent && (
          <DurationButton
            text={optionsLabel}
            width={130}
            value={0}
            selected={isOther}
            isOther={isOther}
            onClick={onDurationOtherButtonClick}
            disabled={disabled}
          />
        )}
        {isOther && renderOtherComponent?.()}
      </div>
    </DurationContainer>
  );

  const getDurationHours = () => (
    <DurationContainer>
      <DurationButton
        id={9}
        text={allDayLabel}
        width={130}
        value={0}
        selected={allDaySelected}
        onClick={onAllDayToggle}
        disabled={disabled || allDayDisabled}
      />

      <OrLabel>{orLabel}</OrLabel>

      {addLabelToDurationItems(durationItems[type], t).map(
        ({ id, text, value: buttonValue }) => (
          <DurationButton
            key={id}
            id={id}
            text={text}
            value={buttonValue}
            onClick={onDurationButtonClick}
            selected={value === buttonValue}
            disabled={disabled}
          />
        ),
      )}
    </DurationContainer>
  );

  return (
    <DurationButtonFieldset>
      <Label data-testid="durationLabel">{label}</Label>
      {type === DURATION_TYPES.MINUTES
        ? getDurationMinutes()
        : getDurationHours()}
    </DurationButtonFieldset>
  );
};

Duration.propTypes = {
  type: PropTypes.oneOf([DURATION_TYPES.MINUTES, DURATION_TYPES.HOURS]),
  onChange: PropTypes.func,
  value: PropTypes.number,
  canDeselect: PropTypes.bool,
  allDaySelected: PropTypes.bool,
  allDayDisabled: PropTypes.bool,
  onAllDayToggle: PropTypes.func,
  renderOtherComponent: PropTypes.func,
  onDurationOtherClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Duration.defaultProps = {
  type: DURATION_TYPES.MINUTES,
  onChange: () => {},
  value: 0,
  canDeselect: true,
  allDaySelected: false,
  allDayDisabled: false,
  onAllDayToggle: () => {},
  onDurationOtherClick: () => {},
  renderOtherComponent: undefined,
  disabled: false,
};

export { Duration };
