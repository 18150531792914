import React, { memo } from 'react';
import { Icon } from 'components/base';
import { useTranslation } from 'react-i18next';
import { IconWrapperFavourites, WrapperFavourites } from './styled';
var FavouriteItem = memo(function (_a) {
    var item = _a.item, children = _a.children, style = _a.style, showToggleFavourite = _a.showToggleFavourite, onToggleFavourites = _a.onToggleFavourites;
    var t = useTranslation().t;
    var isFavourite = item.isFavourite, name = item.name;
    var accessibilityTranslationKey = "accessibilityLabels.".concat(isFavourite ? 'removeFromFavourites' : 'addToFavorites');
    var onFavouritePress = function () { return onToggleFavourites(item); };
    return (React.createElement(WrapperFavourites, { "data-isFavourite": isFavourite ? true : undefined, "$style": style },
        children,
        showToggleFavourite && (React.createElement(IconWrapperFavourites, { "aria-label": t(accessibilityTranslationKey, {
                name: name,
            }), onClick: onFavouritePress },
            React.createElement(Icon, { name: isFavourite ? 'minus' : 'plus', size: 16 })))));
});
export default FavouriteItem;
