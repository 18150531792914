import React from 'react';
import {
  HOURS_12_PERIODS,
  HOURS_24_VALUES,
  HOURS_12_VALUES,
  PERIOD_AM,
  QUARTER_HOUR_VALUES,
} from '@serraview/engage-shared';
import { TIME_TYPES } from '../data';
import { mapTimeValueToObjects, mapPeriodsToObjects } from '../utils';

const formatTime12Options = ({ selectedTime, minTime }) => {
  let hours = HOURS_24_VALUES;
  let periods = HOURS_12_PERIODS;
  let minutes = QUARTER_HOUR_VALUES;
  const isPickedTimeInPast = selectedTime.diff(minTime) < 0;
  if (selectedTime.isSame(minTime, 'day')) {
    // if min time minutes is gt 45 (max possible minute) jump to the next available hour if not disable previous hours
    hours =
      minTime.minutes() > 45
        ? hours.filter((hour) => hour > minTime.hours()) // jump to next hour
        : hours.filter((hour) => hour >= minTime.hours());

    hours =
      selectedTime.hours() >= 12 && selectedTime.hours() <= 23
        ? hours.map((h) => (h === 12 ? 12 : h - 12)) // PM
        : hours.filter((h) => h < 12).map((h) => (h === 0 ? 12 : h)); // AM

    // disable minutes only for minimum hour selected
    minutes =
      selectedTime.hours() === minTime.hours()
        ? minutes.filter((minute) => minute >= minTime.minutes())
        : minutes;

    // if minTime is PM remove AM variant
    if (
      (minTime.hours() >= 12 && minTime.hours() <= 23) ||
      // When is 15 mins before noon AM is displayed but hours are rounded to 12:00
      (minTime.hours() === 11 && minTime.minutes() >= 45)
    ) {
      periods = HOURS_12_PERIODS.filter((period) => period !== PERIOD_AM);
    }
  }

  return {
    hours: mapTimeValueToObjects(HOURS_12_VALUES, TIME_TYPES.HOUR, hours),
    minutes: mapTimeValueToObjects(
      QUARTER_HOUR_VALUES,
      TIME_TYPES.MINUTE,
      minutes,
    ),
    periods: mapPeriodsToObjects(HOURS_12_PERIODS, periods),
    isPickedTimeInPast,
  };
};

const formatTime24Options = ({ selectedTime, minTime }) => {
  let hours = HOURS_24_VALUES;
  let minutes = QUARTER_HOUR_VALUES;
  const isPickedTimeInPast = selectedTime.diff(minTime) < 0;
  // we are only interested in current day
  if (selectedTime.isSame(minTime, 'day')) {
    // if min time minutes is gt 45 (max possible minute) jump to the next available hour
    // if not disable just previous hours
    hours =
      minTime.minutes() > 45
        ? hours.filter((hour) => hour > minTime.hours()) // jump to next hour
        : hours.filter((hour) => hour >= minTime.hours());

    // disable minutes only for minimum hour selected
    minutes =
      selectedTime.hours() === minTime.hours()
        ? minutes.filter((minute) => minute >= minTime.minutes())
        : minutes;
  }
  return {
    hours: mapTimeValueToObjects(HOURS_24_VALUES, TIME_TYPES.HOUR, hours),
    minutes: mapTimeValueToObjects(
      QUARTER_HOUR_VALUES,
      TIME_TYPES.MINUTE,
      minutes,
    ),
    isPickedTimeInPast,
  };
};

export const getTimeOptions = (selectedTime, minTime, isTimeFormatH12) =>
  isTimeFormatH12
    ? formatTime12Options({ selectedTime, minTime })
    : formatTime24Options({ selectedTime, minTime });

const useTimeOptions = ({ selectedTime, minTime, isTimeFormatH12 }) => {
  const timeOptions = React.useMemo(
    () => getTimeOptions(selectedTime, minTime, isTimeFormatH12),
    [minTime, selectedTime, isTimeFormatH12],
  );

  return timeOptions;
};

export default useTimeOptions;
