const asyncActionsMiddleware = (store) => (next) => (action) => {
  const isActionAsync = Object.prototype.hasOwnProperty.call(action, 'async');
  if (!isActionAsync) {
    return next(action);
  }

  const { httpService, then, error } = action;

  httpService()
    .then(
      (resultsObj) =>
        then && then(resultsObj, store.dispatch, store.getState()),
    )
    .catch((err) => error && error(err, store.dispatch, store.getState()));

  return next(action);
};

export { asyncActionsMiddleware };
