import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { A11yHiddenLabel } from 'styles';
import { Line, Oval, Wrapper } from './styled';
var Separator = function (_a) {
    var _b = _a.color, color = _b === void 0 ? '#000000' : _b;
    var t = useTranslation().t;
    return (React.createElement(Wrapper, { "aria-label": t('accessibilityLabels.today') },
        React.createElement(Oval, { color: color }),
        React.createElement(Line, { color: color }),
        React.createElement(A11yHiddenLabel, null, t('accessibilityLabels.today'))));
};
export default memo(Separator);
