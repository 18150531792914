import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { notificationsActions } from 'store';
import { isEqual } from 'date-fns';
import {
  DateFormat,
  formatLocalizedDate,
  formatLocalizedTime,
  getDayEndTime,
  getNotificationDates,
  isSameLocalizedDay,
  shouldListNotification,
} from '@serraview/engage-shared';
import { useConfig } from './useConfig';

const getNotificationMessage = ({
  event,
  dateStart,
  dateEnd,
  allDay,
  timeZone,
  t,
}) => {
  const todayText = t('components.dateCard.today');
  const allDayText = t('components.dateCard.allDay');
  const toText = t('components.dateCard.to');

  const isMultiDay = !isSameLocalizedDay(dateStart, dateEnd, timeZone);
  let dateText = isMultiDay ? todayText : '';

  if (isMultiDay) {
    if (allDay) {
      dateText += `, ${allDayText}`;
    } else {
      dateText += ` ${formatLocalizedTime(dateStart, { timeZone })}`;
    }

    dateText += ` ${toText} ${formatLocalizedDate(dateEnd, {
      format: DateFormat.weekdayShortWithMonthAndDay,
      timeZone,
    })}, `;

    dateText += isEqual(getDayEndTime({ date: dateEnd, timeZone }), dateEnd)
      ? allDayText
      : formatLocalizedTime(dateEnd, timeZone);
  } else if (allDay) {
    dateText += `${allDayText}`;
  } else {
    dateText += `${formatLocalizedTime(dateStart, {
      timeZone,
    })} ${toText} ${formatLocalizedTime(dateEnd, { timeZone })}`;
  }

  const { name, buildingName } = event;
  let locationText = '';
  if (name || buildingName) {
    locationText = `${name ? name : ''}${name && buildingName ? ' - ' : ''}${
      buildingName ? buildingName : ''
    }`;
  }

  return {
    localTime: dateText,
    location: locationText,
  };
};

const getNotification = ({ event, tenantConfig, t }) => {
  const { meetingId, start, end, isAllDayBooking, timeZone } = event;
  const dateStart = new Date(start);
  const dateEnd = new Date(end);

  const { localTime, location } = getNotificationMessage({
    event,
    dateStart,
    dateEnd,
    allDay: isAllDayBooking,
    timeZone,
    t,
  });

  const { checkInStartDate, checkInCloseDate, expirationDate } =
    getNotificationDates({
      tenantConfig,
      reservationStartDate: dateStart,
      timeZone,
      isAllDayBooking,
    });

  return {
    id: meetingId,
    title: t('layout.notifications.deskCheckInTitle'),
    localTime,
    location,
    checkInStartDate,
    checkInCloseDate,
    expirationDate,
    fireDate: checkInStartDate, // Timestamp notification was fired
    isRead: false,
    event,
  };
};

export const useReservationNotification = () => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();
  const tenantConfig = useConfig();

  const handleLocalNotification = React.useCallback(
    ({ event, isUpdate }) => {
      // Cancel previous scheduled local notification
      if (isUpdate) {
        reduxDispatch(
          notificationsActions.cancelNotification({ id: event.meetingId }),
        );
      }
      const {
        isDesk,
        start: reservationStartDate,
        timeZone,
        isAllDayBooking,
      } = event;
      if (
        shouldListNotification({
          isDesk,
          reservationStartDate,
          timeZone,
          tenantConfig,
          isAllDayBooking,
        })
      ) {
        const notification = getNotification({
          event,
          tenantConfig,
          t,
        });

        reduxDispatch(notificationsActions.setNotification(notification));
      }
    },
    [reduxDispatch, t, tenantConfig],
  );

  return { handleLocalNotification };
};
