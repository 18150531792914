import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGenerateCurrentLocationPath, useIsDeskSelection } from 'utils';
import { joinPaths } from 'router/utils';
import { PATH_SEGMENT } from 'consts';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useDispatch, useSelector } from 'react-redux';
import { teamReservationsActions, teamReservationsSelectors } from 'store';
import { useAppLocation } from 'router/hooks';
import { Icon } from 'components/base';
import { SubtitleWrapper, Subtitle } from './TeamMembersHeader.style';
export function TeamMembersHeader(_a) {
    var _b;
    var teamName = _a.teamName, membersCount = _a.membersCount;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var pathname = useAppLocation().pathname;
    var currentLocationPath = useGenerateCurrentLocationPath();
    var isDeskSelection = useIsDeskSelection();
    var selectedDesks = useSelector(teamReservationsSelectors.getTeamReservationSelectedDesks);
    var teamSize = useSelector(teamReservationsSelectors.getTeamSize);
    var teamsPath = joinPaths(currentLocationPath, PATH_SEGMENT.TEAMS);
    var closeLinkPath = isDeskSelection ? pathname : teamsPath;
    var infoCardTitle = isDeskSelection
        ? t('layout.teamMembersList.selectDesks')
        : teamName;
    var infoCardSubtitle = isDeskSelection
        ? t('layout.teamMembersList.desksSelected', {
            selected: (_b = selectedDesks === null || selectedDesks === void 0 ? void 0 : selectedDesks.length) !== null && _b !== void 0 ? _b : 0,
            total: teamSize !== null && teamSize !== void 0 ? teamSize : 0,
        })
        : !!(membersCount && membersCount > 0) &&
            t('layout.teamMembersList.members', {
                numberOfMembers: membersCount,
            });
    // if close button is clicked when team reservation desks selection is active,
    // clear selected desk from floorplan and clear team reservation store
    var handleCloseClick = function () {
        if (isDeskSelection) {
            dispatch(teamReservationsActions.clearSelection());
        }
    };
    var renderSubtitle = function (subtitle) { return (React.createElement(SubtitleWrapper, null,
        React.createElement(Icon, { name: "desk", size: 18 }),
        React.createElement(Subtitle, null, subtitle))); };
    return (React.createElement(DrawerHeader, { title: infoCardTitle, subtitle: infoCardSubtitle, renderSubtitle: isDeskSelection ? renderSubtitle : undefined, closeLinkPath: closeLinkPath, onPress: handleCloseClick, closeIconA11yLabel: isDeskSelection
            ? 'accessibilityLabels.closeLayout_teamDesksList'
            : 'accessibilityLabels.closeLayout_teamMembersList', isCloseIcon: true }));
}
