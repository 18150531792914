var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import AvatarImage from 'components/base/Avatar/AvatarImage';
var WrapperButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 0;\n"], ["\n  cursor: pointer;\n  padding: 0;\n"])));
var SELECTED_COLOR = 'rgba(0, 2, 154, 0.15)';
var SelectableAvatar = function (_a) {
    var onClick = _a.onClick, isSelected = _a.isSelected, icon = _a.icon, iconStyle = _a.iconStyle, style = _a.style, a11LabelSuffix = _a.a11LabelSuffix, rest = __rest(_a, ["onClick", "isSelected", "icon", "iconStyle", "style", "a11LabelSuffix"]);
    var t = useTranslation().t;
    var color2 = useTheme().color2;
    var a11Label = t(isSelected
        ? 'accessibilityLabels.unselectItem'
        : 'accessibilityLabels.selectItem', {
        item: a11LabelSuffix,
    });
    var handleClick = function (e) {
        if (onClick) {
            e.stopPropagation();
            onClick();
        }
    };
    return (React.createElement(WrapperButton, { onClick: handleClick, "aria-label": a11Label },
        React.createElement(AvatarImage, __assign({}, rest, { icon: isSelected ? 'tick' : icon, iconStyle: isSelected ? { color: color2 } : iconStyle, style: isSelected ? { backgroundColor: SELECTED_COLOR } : style }))));
};
export default SelectableAvatar;
var templateObject_1;
