import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 4px 15px;
  border-radius: 50px;
  text-align: center;
  background-color: ${(props) => props.backgroundColor || props.theme.color9};
  color: ${(props) => props.color || '#fff'};
  font-size: 12px;
  font-weight: bold;
  transform: translate(0px, -15px);
`;

export { Wrapper };
