import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tenantSelectors } from 'store';
import { Icon, Mask } from 'base-components';
import { DOCUMENT_TITLE_LEVEL, MODALS } from 'consts';
import { useLogout } from 'api/mutations/useLogout';
import { useSetPageTitle, useModal, useFocus, FocusId } from 'utils/hooks';
import Notifications from '../Notifications/Notifications';
import { Actions, Logout, NotificationsWrapper, Settings, Wrapper, } from './styled';
var UserProfile = function () {
    var t = useTranslation().t;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var openModal = useModal().openModal;
    var _a = useLogout(), mutate = _a.mutate, isLoading = _a.isLoading;
    var onLogout = function () { return mutate({ tenantId: tenantId }); };
    var logoutStr = t('layout.userProfile.logout');
    var settingsStr = t('layout.userProfile.settings');
    var handleSettingsClick = function () {
        openModal(MODALS.CONFIGURATION);
    };
    useFocus({ returnFocusTo: FocusId.UserProfileButton });
    useSetPageTitle(t('documentTitles.userProfile'), DOCUMENT_TITLE_LEVEL.MODAL);
    return (React.createElement(Wrapper, { "data-testid": "user-profile" },
        React.createElement(Mask, { isLoading: isLoading }),
        React.createElement(Actions, null,
            React.createElement(Logout, { onClick: onLogout },
                React.createElement(Icon, { shouldRotateOnRtl: true, name: "logout", size: 16 }),
                React.createElement("span", { className: "text" }, logoutStr)),
            React.createElement(Settings, null,
                React.createElement("button", { id: FocusId.UserSettingsButton, onClick: handleSettingsClick }, settingsStr))),
        React.createElement(NotificationsWrapper, null,
            React.createElement(Notifications, null))));
};
export default memo(UserProfile);
