import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { inAppNotificationsActions, navigationActions, navigationSelectors, } from 'store';
import { DATE_TIME_PICKER_VARIANT, DOCUMENT_TITLE_LEVEL } from 'consts';
import { FocusId, getModalDateTimePickerTitle, useFocus, useLocalTimeFormatH12, useSetPageTitle, } from 'utils/hooks';
import { useDTPConfig } from 'components/base/DateTime/DateTimePicker/useDTPConfig';
import DateTimePicker from './DateTimePicker';
/**
 * DateTimePicker to be used in components/scenes/modals/content/Content.js
 * @returns {JSX.Element}
 */
export function ModalDateTimePicker() {
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var isTimeFormatH12 = useLocalTimeFormatH12();
    var _a = useSelector(navigationSelectors.getModalLayout).content, variant = _a.variant, dateStart = _a.dateStart, _b = _a.returnFocusTo, returnFocusTo = _b === void 0 ? FocusId.TimelineButton : _b;
    var _c = useDTPConfig({
        variant: variant,
    }), selectedDate = _c.selectedDate, onDateSelected = _c.onDateSelected, onClear = _c.onClear, minDate = _c.minDate, maxDate = _c.maxDate, timeZone = _c.timeZone;
    useFocus({
        focusOn: FocusId.TimelineSelectButton,
        returnFocusTo: returnFocusTo,
    });
    useSetPageTitle(getModalDateTimePickerTitle(variant, t), DOCUMENT_TITLE_LEVEL.MODAL);
    var showAvailabilityInfo = variant !== DATE_TIME_PICKER_VARIANT.INTERVAL_END;
    var handleClose = useCallback(function (selectedDate, isToday) {
        // validate To date to be after From date
        if (variant === DATE_TIME_PICKER_VARIANT.INTERVAL_END &&
            dateStart &&
            !isAfter(selectedDate, dateStart)) {
            dispatch(inAppNotificationsActions.addErrorNotification({
                message: t('layout.notifications.datePickerInvalidToDateError'),
            }));
            return;
        }
        if (isToday) {
            onClear();
        }
        else {
            onDateSelected(selectedDate);
        }
        dispatch(navigationActions.clearModal());
    }, [dispatch, dateStart, onClear, onDateSelected, variant, t]);
    return (React.createElement(DateTimePicker, { initialDate: selectedDate, showAvailabilityInfo: showAvailabilityInfo, minDate: minDate, maxDate: maxDate, isTimeFormatH12: isTimeFormatH12, handleClose: handleClose, timeZone: timeZone, returnFocusTo: returnFocusTo }));
}
