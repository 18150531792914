import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FilterButton = styled(Link)`
  margin-left: 5px;
  position: relative;
  height: 38px;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;

  font-weight: normal;
  font-size: 14px;
  cursor: pointer;

  background-color: ${({ theme, $filtersChanged }) =>
    $filtersChanged ? theme.color9 : theme.color4};
  border-color: ${({ theme, $filtersChanged }) =>
    $filtersChanged ? theme.color9 : theme.color4};
  border-radius: 19px;
  border-width: 2px;
  color: ${({ theme, $filtersChanged }) =>
    $filtersChanged ? theme.color6 : theme.color1};
  &.focus-visible {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

export const FilterCountNumber = styled.div`
  border: 2px solid ${({ theme }) => theme.color6};
  background-color: ${({ theme }) => theme.color9};
  position: absolute;
  top: -12px;
  right: -4px;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color6};
  font-size: 12px;
`;
