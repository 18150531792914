import React from 'react';
import { EmptyCard } from 'base-components';
import {
  ConciergeMenuErrorButton,
  ConciergeMenuErrorWrapper,
} from 'scenes/ConciergeMenu/styled';
import { useTranslation } from 'react-i18next';
import { ROOT_PATH } from 'consts';

export const ConciergeMenuError = () => {
  const { t } = useTranslation();

  return (
    <ConciergeMenuErrorWrapper data-testid="concierge-menu-error">
      <EmptyCard iconName="info">
        <div data-testid="concierge-menu-error-message">
          {t('common.networkError')}
        </div>
      </EmptyCard>
      <ConciergeMenuErrorButton
        data-testid="concierge-menu-error-button"
        to={ROOT_PATH}
      >
        {t('components.menu.wayfinder')}
      </ConciergeMenuErrorButton>
    </ConciergeMenuErrorWrapper>
  );
};
