var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SEARCH_QUERY } from 'consts';
import { testProps } from 'utils';
import { Icon } from '../Icons/Icon';
import { IconWrapper, InfoWrapper, LineWrapper, LocatorAction, Wrapper, } from './styled';
var LocationCard = function (_a) {
    var isDesk = _a.isDesk, location = _a.location, desk = _a.desk, meetingRoom = _a.meetingRoom, onShowLocation = _a.onShowLocation, disableButton = _a.disableButton;
    var t = useTranslation().t;
    var showSpaceRow = (isDesk && !!desk) || (!isDesk && !!meetingRoom);
    var a11yAndTestProps = testProps(t, [
        'accessibilityLabels.deskGeoLocation',
        'accessibilityLabels.spaceGeoLocation',
    ], function (_a) {
        var deskGeoLocation = _a[0], spaceGeoLocation = _a[1];
        return isDesk ? deskGeoLocation : spaceGeoLocation;
    }).props;
    if (!location && !showSpaceRow) {
        return null;
    }
    return (React.createElement(Wrapper, null,
        React.createElement(InfoWrapper, null,
            showSpaceRow && (React.createElement(LineWrapper, { style: { marginBottom: 20 } },
                React.createElement(IconWrapper, null,
                    React.createElement(Icon
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        name: isDesk ? SEARCH_QUERY.DESK : SEARCH_QUERY.SPACE, size: 20 })),
                React.createElement("span", { "data-testid": "locationCard_space_text" }, isDesk ? desk : meetingRoom))),
            location && (React.createElement(LineWrapper, null,
                React.createElement(IconWrapper, null,
                    React.createElement(Icon, { name: "location-pin", size: 20 })),
                React.createElement("span", { "data-testid": "locationCard_location_text" }, "".concat(location.buildingName, ", ").concat(location.floorName))))),
        (!!desk || !!meetingRoom) && (React.createElement(LocatorAction, __assign({ onClick: onShowLocation }, a11yAndTestProps, { disabled: disableButton }),
            React.createElement(Icon, { name: "locator", size: 18 })))));
};
export default memo(LocationCard);
