import React from 'react';
import SearchItemSkeleton from 'components/layouts/GlobalSearch/SearchItemSkeleton';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'components/base/Skeleton';
import { TeamMembersHeader } from './TeamMembersHeader';
import { TeamMembersSearch } from './TeamMembersSearch';
import { TeamMembersListWrapper, TeamMembersListSkeleton, } from './TeamMembers.style';
export function TeamMembersSkeleton() {
    return (React.createElement(React.Fragment, null,
        React.createElement(TeamMembersHeader, null),
        React.createElement(TeamMembersSearch, null),
        React.createElement(TeamMembersListWrapper, null,
            React.createElement(TeamMembersListSkeleton, null,
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null),
                React.createElement(SearchItemSkeleton, null))),
        React.createElement(SkeletonTheme, null,
            React.createElement(Skeleton, { width: "100%", height: 80 }))));
}
