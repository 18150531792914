import { generateFloorPath, hasValidParamInSearchQuery, joinPaths, } from 'router/utils';
import { DRAWER_DEEP_LINK_PARAMS, PATH_SEGMENT, ROOT_PATH, SEARCH_QUERY, } from 'consts';
import { STRIP_SEARCH_PARAMS } from './constants';
export var getSpaceOrDeskPath = function (space, hasDeskOrSpaceId, isDesk) {
    if (isDesk === void 0) { isDesk = true; }
    if (!space || !space.floorId)
        return ROOT_PATH;
    // in case if this is a booking deeplink we also isChecking
    // if search has booking or space id to display the related
    // drawer card, otherwise – just navigate to the floor path
    var result = generateFloorPath("".concat(space.floorId));
    if (hasDeskOrSpaceId) {
        result = joinPaths(generateFloorPath("".concat(space.floorId)), isDesk ? PATH_SEGMENT.DESKS : PATH_SEGMENT.SPACES, "".concat(space.id));
    }
    return result;
};
export var getPersonPath = function (person) {
    var _a;
    var result = ROOT_PATH;
    if (person) {
        result = joinPaths(ROOT_PATH, PATH_SEGMENT.PEOPLE, "".concat(person.id));
        if ((_a = person.primaryLocation) === null || _a === void 0 ? void 0 : _a.floorId) {
            result = joinPaths(generateFloorPath("".concat(person.primaryLocation.floorId)), result);
        }
    }
    return result;
};
export var getEmployeePath = function (employeeId) {
    return joinPaths(ROOT_PATH, PATH_SEGMENT.EMPLOYEE, employeeId);
};
export var getDeepLinkSearchParams = function (searchString) {
    var searchParams = new URLSearchParams(searchString);
    return {
        spaceId: searchParams.get(SEARCH_QUERY.SPACE),
        deskId: searchParams.get(SEARCH_QUERY.DESK),
        bookingSpaceId: searchParams.get(SEARCH_QUERY.BOOKING_SPACE),
        employeeId: searchParams.get(SEARCH_QUERY.EMPLOYEE),
        personId: searchParams.get(SEARCH_QUERY.PERSON),
        date: searchParams.get(SEARCH_QUERY.DATE),
        time: searchParams.get(SEARCH_QUERY.TIME),
        teamBookingId: searchParams.get(SEARCH_QUERY.TEAM_BOOKING_MEETING),
    };
};
export var stripDeepLinkSearchParams = function (searchString) {
    if (!searchString)
        return '';
    var searchParams = new URLSearchParams(searchString);
    STRIP_SEARCH_PARAMS.forEach(function (searchParam) {
        return searchParams.delete(searchParam);
    });
    return searchParams.toString();
};
export var getDeepLinkString = function (path, search) {
    var cleanSearch = stripDeepLinkSearchParams(search);
    return cleanSearch ? "".concat(path, "?").concat(cleanSearch) : path;
};
export var getIsChecking = function (deepLinkSearch, deepLinkPath, search) {
    return !!deepLinkPath ||
        !!deepLinkSearch ||
        hasValidParamInSearchQuery(search, DRAWER_DEEP_LINK_PARAMS);
};
