var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_SEGMENT } from 'consts';
import { teamReservationsActions, tenantSelectors } from 'store';
import { useNavigate } from 'react-router-dom';
import { generatePersonPath } from 'router/utils';
import { getLatestPresenceEvent, getSpaceSVLivePresenceEventOccupancy, } from '@serraview/engage-shared/utils';
import BookedByButton from '../../BookedButton/BookedButton';
import { ReserveCardContext } from '../ReserveCardContext';
export var OccupantButton = function (_a) {
    var shouldShowGuest = _a.shouldShowGuest;
    var _b = useContext(ReserveCardContext), spaceItem = _b.spaceItem, item = _b.item, isTeam = _b.isTeam, occupant = _b.occupant, svLiveOccupant = _b.svLiveOccupant;
    var spaceId = spaceItem.id;
    var occupiedBy = __assign(__assign({}, occupant), __assign(__assign({}, svLiveOccupant), (svLiveOccupant && { id: svLiveOccupant === null || svLiveOccupant === void 0 ? void 0 : svLiveOccupant.personId })));
    var reduxDispatch = useDispatch();
    var navigate = useNavigate();
    var navigateToTeamsList = function () {
        // Show teams lists
        navigate(PATH_SEGMENT.TEAMS, {
            state: {
                team: item,
            },
        });
        reduxDispatch(teamReservationsActions.clearSelection());
    };
    var floor = useSelector(tenantSelectors.getCurrentLocation).floor;
    var isOccupiedBySvLive = getSpaceSVLivePresenceEventOccupancy(spaceItem).isOccupied;
    var latestPresenceEvent = getLatestPresenceEvent(spaceItem === null || spaceItem === void 0 ? void 0 : spaceItem.presenceEvents);
    var showOccupant = function () {
        if (shouldShowGuest) {
            return;
        }
        var pathname = generatePersonPath({
            floorId: floor.id,
            personId: isOccupiedBySvLive ? latestPresenceEvent.personId : occupant.id,
        });
        navigate(pathname, {
            state: {
                searchSpaceId: spaceId,
            },
        });
    };
    return (React.createElement(BookedByButton, __assign({ isSvLiveOccupant: !!svLiveOccupant }, occupiedBy, { onPress: isTeam ? navigateToTeamsList : showOccupant, forceToGuest: shouldShowGuest })));
};
