import { generatePath, matchPath } from 'react-router-dom';
import { FLOOR_PATH, LOGIN_PATH, PERSON_PATH, ROOT_PATH, TEAM_PATH, WORKSPACE_PATH, } from 'consts';
import { AZURE_TENANTS } from 'utils/config/azureConfig';
export var generateFloorPath = function (floorId) {
    return generatePath(FLOOR_PATH, { floorId: floorId });
};
export var generatePersonPath = function (params) {
    return generatePath("".concat(FLOOR_PATH).concat(PERSON_PATH), params);
};
export var generateTeamPath = function (params) {
    return generatePath("".concat(FLOOR_PATH).concat(TEAM_PATH), params);
};
export var joinPaths = function () {
    var paths = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        paths[_i] = arguments[_i];
    }
    return paths
        .filter(function (p) { return !!p; })
        .join('/')
        .replace(/\/+/g, '/');
};
export var getRedirectPath = function (_a) {
    var isAuthenticated = _a.isAuthenticated, hasTenantId = _a.hasTenantId;
    if (hasTenantId) {
        return isAuthenticated ? ROOT_PATH : LOGIN_PATH;
    }
    return WORKSPACE_PATH;
};
export var getShouldRedirect = function (_a) {
    var isAuthenticated = _a.isAuthenticated, hasTenantId = _a.hasTenantId, path = _a.path;
    return (isAuthenticated && (path === LOGIN_PATH || path === WORKSPACE_PATH)) ||
        (!isAuthenticated && !hasTenantId && path === LOGIN_PATH);
};
export var getIsValidDeepLink = function (maybeDeepLinkPath) {
    if (!maybeDeepLinkPath)
        return false;
    var matchFloorRouter = matchPath(maybeDeepLinkPath, FLOOR_PATH);
    return !!matchFloorRouter;
};
export var removeSegmentFromPath = function (segment, path) {
    return path.replace(RegExp("/".concat(segment)), '');
};
export var hasValidParamInSearchQuery = function (searchQuery, params) {
    if (!searchQuery)
        return false;
    var query = new URLSearchParams(searchQuery);
    return Object.values(params).some(function (param) { return query.has(param); });
};
export var getIdToken = function (searchQuery, queryParam) {
    var _a;
    if (!searchQuery)
        return null;
    var query = new URLSearchParams(searchQuery);
    return (_a = query.get(queryParam)) !== null && _a !== void 0 ? _a : null;
};
export var getIsAzureTenant = function (tenantId, search) {
    var _a;
    if (!tenantId || !search) {
        return { isAzureTenant: false, idToken: null };
    }
    var isAzureTenant = Object.keys(AZURE_TENANTS).includes(tenantId);
    var queryParam = (_a = AZURE_TENANTS[tenantId]) === null || _a === void 0 ? void 0 : _a.queryParam;
    if (queryParam && isAzureTenant) {
        var idToken = getIdToken(search, queryParam);
        return { isAzureTenant: isAzureTenant, idToken: idToken };
    }
    return { isAzureTenant: false, idToken: null };
};
