import ReactDatePicker from 'react-date-picker';
import styled from 'styled-components';

const DatePicker = styled(ReactDatePicker)`
  & .react-date-picker__calendar {
    left: 50% !important;
    transform: translate(-50%, 0);
  }
  & .react-date-picker__inputGroup,
  .react-date-picker__wrapper {
    display: none;
  }
  & .react-calendar__navigation {
    margin: 5px;
    height: auto;
    & button {
      min-width: 30px;
      min-height: 30px;
      margin: 0 3px;
    }
  }
  & .react-calendar {
    border-radius: 10px;
  }
  & .react-calendar__month-view__days {
    justify-content: space-around;
  }
  & .react-calendar__month-view__weekdays__weekday {
    width: 44px;
    flex-basis: 12.6% !important;
    padding: 5px 0;

    font-size: 14px;
  }
  & .react-calendar__month-view__weekdays {
    justify-content: space-around;
    margin-bottom: 5px;
  }
  & .react-calendar button {
    border-radius: 100px;
  }
  & .react-calendar__month-view__days__day {
    flex-basis: 12.6% !important;
    width: 44px;
    height: 44px;
    font-size: 14px;
    padding: 0;
    margin: 2px 0;
  }
`;

export default DatePicker;
