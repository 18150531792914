var _a;
import { SpaceType } from '@serraview/engage-shared';
import { useTranslation } from 'react-i18next';
import { inAppNotificationsActions } from 'store';
import { useDispatch } from 'react-redux';
var SpaceTypeToTranslation = (_a = {},
    _a[SpaceType.DESK] = 'favouriteReachedLimitDesks',
    _a[SpaceType.PERSON] = 'favouriteReachedLimitPeople',
    _a[SpaceType.SPACE] = 'favouriteReachedLimitSpaces',
    _a);
export var useFavouritesMutationErrorHandler = function (restrictTo) {
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var onError = function (_error, variables) {
        var _a;
        var message = '';
        // 403 status means favourites limit reached
        if (((_a = _error.response) === null || _a === void 0 ? void 0 : _a.status) === 403) {
            var translationKey = SpaceTypeToTranslation[restrictTo];
            message = t("layout.notifications.".concat(translationKey));
        }
        else {
            message = t("layout.notifications.".concat(variables.isCurrentlyFavourite
                ? 'favouriteUnselectFailure'
                : 'favouriteSelectFailure'));
        }
        dispatch(inAppNotificationsActions.addErrorNotification({ message: message }));
    };
    return { onError: onError };
};
