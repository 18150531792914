var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uiActions } from 'store';
import { FocusId } from 'utils/hooks/useFocus';
import { testProps } from 'utils';
import { StyledMenuButton, StyledMenuIcon } from './styled';
export var MenuButton = memo(function (_a) {
    var isMenuVisible = _a.isMenuVisible;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var toggleMoreMenu = function () {
        return dispatch(uiActions.setDrawerMenuVisibility(!isMenuVisible));
    };
    return (React.createElement(StyledMenuButton, __assign({ id: FocusId.DrawerMenuButton, role: "button" }, testProps(t, 'accessibilityLabels.drawerMenuButton').props, { onClick: toggleMoreMenu }),
        React.createElement(StyledMenuIcon, { "$opened": isMenuVisible, name: "three-dots", size: 20 })));
});
