import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useConfig,
  useCurrentTimeZone,
  useGenerateCurrentLocationPath,
  useRestrictTo,
} from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OverlayFloorplan } from 'floorplan/styles';
import {
  FLOORPLAN_VIEWS,
  PATH_SEGMENT,
  RESTRICT_TO_TO_PATH_SEGMENT,
} from 'consts';
import {
  filtersSelectors,
  floorplanActions,
  floorplanSelectors,
  inAppNotificationsActions,
  teamReservationsActions,
  teamReservationsSelectors,
  tenantSelectors,
  uiActions,
  uiSelectors,
} from 'store';
import logger from 'log';
import { useFilterTimes } from '@serraview/engage-shared';
import {
  FES_EVENT_FLOORPLAN_DATA_LOADED,
  FES_EVENTS,
} from 'floorplan/constants/FESEvents';
import FloorplanComponent from 'floorplan/base/FloorplanComponent';
import trackFloorplanActions from 'components/floorplan/utils/trackFloorplanActions';
import FloorplanError from 'components/floorplan/components/FloorplanError/FloorplanError';
import { joinPaths } from 'router/utils';
import { useAppLocation, useAppParams } from 'router/hooks';
import { isElectron } from 'utils/electron';
import { useFloorDrawableMarkers } from '@serraview/engage-shared/api';

const FloorplanHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { spaceId, deskId } = useAppParams();

  const isDrawerMoreMenuVisible = useSelector(
    uiSelectors.getDrawerMenuVisibility,
  );
  const restrictTo = useRestrictTo();
  const { hidePeopleData, kioskLocation, userLocation } = useSelector(
    tenantSelectors.getKioskConfig,
  );

  const lastActiveRestrictToPath = useRef(null);

  const currentLocationPath = useGenerateCurrentLocationPath();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const isInDeskCheckInMode = useSelector(
    teamReservationsSelectors.getIsInDeskCheckInMode,
  );
  const {
    enforceAllDayDeskReservations,
    highlightReservedDesks,
    isCleverSocialDistancingActive,
  } = useConfig();
  const [showRecovery, setShowRecovery] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const floorplanRef = useRef(null);
  const { floorplanTheme } = useSelector(tenantSelectors.getTheme);

  const floorId = useSelector(tenantSelectors.getCurrentFloorId);

  const focusedSpace = useSelector(floorplanSelectors.getFocusedSpace);
  const distanceMarker = useSelector((s) => s.floorplan.distanceMarker);
  const shouldRefreshSpace = useSelector(
    floorplanSelectors.getShouldRefreshSpace,
  );
  const showOverlay = useSelector(floorplanSelectors.getShouldShowOverlay);

  const floorplanIsLoaded = useSelector(floorplanSelectors.getIsLoaded);

  const shouldClearDesksSelection = useSelector(
    teamReservationsSelectors.getShouldClearDesksSelection,
  );

  const { dateStart, dateEnd } = useSelector(filtersSelectors.getFilterDates);
  const timeZone = useCurrentTimeZone();

  const { startTime, endTime } = useFilterTimes({
    dateStart,
    dateEnd,
    timeZone,
    enforceAllDayDeskReservations,
    restrictTo,
  });

  const svLivePersonLocation = useSelector(
    floorplanSelectors.getSVLivePersonLocation,
  );

  const teamReservationDesksSelectionMode = useSelector(
    teamReservationsSelectors.getTeamReservationDesksSelectionMode,
  );
  const teamSize = useSelector(teamReservationsSelectors.getTeamSize);
  const teamReservationSelectedDesks = useSelector(
    teamReservationsSelectors.getTeamReservationSelectedDesks,
  );

  const { pathname } = useAppLocation();
  const lastPathSegment = pathname.split('/').pop();

  useEffect(() => {
    if (lastPathSegment === PATH_SEGMENT.HEATMAP) {
      dispatch(uiActions.setFloorplanView(FLOORPLAN_VIEWS.HEATMAP));
    } else if (lastPathSegment !== PATH_SEGMENT.FLOORPLAN_VIEWS) {
      dispatch(uiActions.setFloorplanView(FLOORPLAN_VIEWS.FLOORPLAN));
    }
  }, [dispatch, lastPathSegment]);

  const heatmapVisible =
    useSelector(uiSelectors.getFloorplanView) === FLOORPLAN_VIEWS.HEATMAP;

  const hereMarkerVisible = isElectron();

  const drawableMarkers = useFloorDrawableMarkers({ tenantId, floorId });

  const zoomTilesShown = true;
  const reservedAlwaysEnabled = highlightReservedDesks;

  useEffect(() => {
    if (!!spaceId || !!deskId) {
      return;
    }

    // track if click on close button should open filters after closing desk/space card
    lastActiveRestrictToPath.current = RESTRICT_TO_TO_PATH_SEGMENT[restrictTo];
  }, [spaceId, deskId, restrictTo]);

  const openDrawerCard = useCallback(
    ({ path, state }) => {
      // remember previous path, so that restrict to filter
      // will not get selected by default (P2-3080), check if we should open filters
      const fromPath = lastActiveRestrictToPath.current
        ? joinPaths(currentLocationPath, lastActiveRestrictToPath.current)
        : currentLocationPath;

      navigate(joinPaths(currentLocationPath, path), {
        state: {
          ...state,
          fromPath,
        },
      });

      trackFloorplanActions(state);
    },
    [currentLocationPath, navigate],
  );

  // Here we should add handlers provided to useOnClickOutside hook
  // because click on floorplan are not propagated and can be caught outside
  const callOnClickOutsideHandlers = useCallback(() => {
    if (isDrawerMoreMenuVisible) {
      dispatch(uiActions.setDrawerMenuVisibility(false));
    }
  }, [dispatch, isDrawerMoreMenuVisible]);

  const onFloorPlanClickHandler = useCallback(
    ({ data }) => {
      if (!data) {
        return;
      }
      // Set card layout based on given params
      if (data.isDesk) {
        openDrawerCard({
          path: joinPaths(PATH_SEGMENT.DESKS, data.id),
          state: {
            desk: data,
          },
        });
      } else {
        openDrawerCard({
          path: joinPaths(PATH_SEGMENT.SPACES, data.id),
          state: {
            space: data,
          },
        });
      }
    },
    [openDrawerCard],
  );

  // example of "injecting" js
  const sendMessage = useCallback((key, data) => {
    floorplanRef.current?.trigger(key, data);
  }, []);

  useEffect(() => {
    if (Array.isArray(drawableMarkers)) {
      sendMessage(FES_EVENTS.SET_FLOOR_MARKERS, { drawableMarkers });
    }
  }, [drawableMarkers, sendMessage]);

  /**
   * Flag when space data is loaded.
   */
  const onFloorplanDataLoaded = React.useCallback(
    ({ loaded = false }) => {
      dispatch(floorplanActions.setIsSpaceDataLoaded(loaded));
      if (loaded) {
        dispatch(floorplanActions.setIsLoaded(loaded));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const viewer = floorplanRef.current?.viewer;

    if (!viewer) return () => {};

    const onCanvasClick = () => {
      callOnClickOutsideHandlers();
    };

    viewer.addHandler('canvas-click', onCanvasClick);
    return () => {
      viewer.removeHandler('canvas-click', onCanvasClick);
    };
  }, [callOnClickOutsideHandlers]);

  useEffect(() => {
    if (isCleverSocialDistancingActive && distanceMarker) {
      sendMessage(
        // TODO: get ttl from config
        FES_EVENTS.SET_DISTANCE_MARKER,
        { distanceMarker },
      );
    }
  }, [distanceMarker, isCleverSocialDistancingActive, sendMessage]);

  useEffect(() => {
    if (floorId) {
      sendMessage(FES_EVENTS.FLOORPLAN_CHANGED, { id: floorId });
    }
    // on unmount reset isLoaded flag
    return () => {
      dispatch(floorplanActions.setIsLoaded(false));
    };
  }, [dispatch, floorId, sendMessage]);

  useEffect(() => {
    if (focusedSpace) {
      sendMessage(FES_EVENTS.SHOW_SPACE, {
        id: focusedSpace.id,
        zoom: true,
        select: true,
      });
    } else {
      sendMessage(FES_EVENTS.CLEAR_SHOW_SPACE);
    }
  }, [focusedSpace, sendMessage]);

  useEffect(() => {
    if (svLivePersonLocation) {
      sendMessage(
        FES_EVENTS.SHOW_SV_LIVE_PERSON_LOCATION,
        svLivePersonLocation,
      );
    } else {
      sendMessage(FES_EVENTS.HIDE_SV_LIVE_PERSON_LOCATION);
    }
  }, [sendMessage, svLivePersonLocation]);

  useEffect(() => {
    sendMessage(
      FES_EVENTS.SET_TEAM_RESERVATION_DESKS_SELECTION_MODE,
      teamReservationDesksSelectionMode,
    );
  }, [sendMessage, teamReservationDesksSelectionMode]);

  useEffect(() => {
    sendMessage(FES_EVENTS.SET_TEAM_RESERVATION_TEAM_SIZE, teamSize);
  }, [sendMessage, teamSize]);

  useEffect(() => {
    if (shouldClearDesksSelection) {
      sendMessage(FES_EVENTS.CLEAR_TEAM_RESERVATION_DESKS_SELECTION);
      dispatch(teamReservationsActions.clear());
    }
  }, [shouldClearDesksSelection, dispatch, sendMessage]);

  useEffect(() => {
    sendMessage(
      FES_EVENTS.TEAM_RESERVATION_SELECTED_DESKS_CHANGED,
      teamReservationSelectedDesks,
    );
  }, [sendMessage, teamReservationSelectedDesks]);

  const isDeskCheckInModeOn = useRef(false);
  const teamBookingId = useSelector(teamReservationsSelectors.getTeamBookingId);

  useEffect(() => {
    if (Number.isInteger(teamBookingId)) {
      isDeskCheckInModeOn.current = true;
      sendMessage(FES_EVENTS.SET_AVAILABILITY_DATE, {
        startTime: dateStart,
        endTime: dateEnd,
      });
      sendMessage(FES_EVENTS.ENTER_TEAM_BOOKING_AVAILABILITY_MODE, {
        teamBookingId,
      });
    }
  }, [teamBookingId, dateStart, dateEnd, sendMessage]);

  useEffect(() => {
    if (!teamBookingId && isDeskCheckInModeOn.current) {
      sendMessage(FES_EVENTS.EXIT_TEAM_BOOKING_AVAILABILITY_MODE);
    }
  }, [teamBookingId, sendMessage]);

  useEffect(() => {
    // do not set date separately if in desk check in mode
    // because it leads to an incorrect date provided
    if (isInDeskCheckInMode) return;
    sendMessage(FES_EVENTS.SET_AVAILABILITY_DATE, {
      startTime,
      endTime,
    });
  }, [startTime, endTime, sendMessage, isInDeskCheckInMode]);

  useEffect(() => {
    if (shouldRefreshSpace) {
      sendMessage(FES_EVENTS.REFRESH_SPACE_DATA);
      dispatch(floorplanActions.updateSpace());
    }
  }, [dispatch, sendMessage, shouldRefreshSpace]);

  useEffect(() => {
    if (floorplanIsLoaded) {
      if (heatmapVisible) {
        dispatch(floorplanActions.setFocusedSpace(null));
        setTimeout(() => sendMessage(FES_EVENTS.SHOW_HEATMAP), 500);
      } else {
        sendMessage(FES_EVENTS.HIDE_HEATMAP);
      }
    }
  }, [heatmapVisible, sendMessage, floorplanIsLoaded, dispatch]);

  const userIsOnKioskFloor = userLocation?.floor?.id === floorId;

  useEffect(() => {
    if (hereMarkerVisible && kioskLocation && userIsOnKioskFloor) {
      sendMessage(FES_EVENTS.SHOW_HERE_MARKER, { kioskLocation });
    } else {
      sendMessage(FES_EVENTS.HIDE_HERE_MARKER);
    }
  }, [userIsOnKioskFloor, hereMarkerVisible, kioskLocation, sendMessage]);

  // same as mobile on message
  const onMessage = useCallback(
    (data) => {
      switch (data.type) {
        case FES_EVENTS.UNRECOVERABLE_ERROR:
          setErrorMessage(data.message);
          setShowRecovery(true);
          dispatch(
            inAppNotificationsActions.addErrorNotification({
              message: data.message,
            }),
          );
          break;
        case FES_EVENTS.RECOVERABLE_ERROR:
          dispatch(
            inAppNotificationsActions.addErrorNotification({
              message: data.message,
            }),
          );
          break;
        case FES_EVENTS.FLOORPLAN_CLICKED: {
          onFloorPlanClickHandler(data);
          break;
        }
        case FES_EVENTS.INIT:
          sendMessage(FES_EVENTS.INIT_DATA, {
            zoomTilesShown,
            reservedAlwaysEnabled,
            theme: floorplanTheme,
            labelOptions: {
              showOccupantLabels: true,
              showSpaceNameLabels: false,
              showSpaceTypeLabels: false,
              labelFontSize: 10,
            },
          });
          sendMessage(FES_EVENTS.SET_AVAILABILITY_DATE, {
            startTime,
            endTime,
          });

          sendMessage(FES_EVENTS.FLOORPLAN_CHANGED, {
            id: floorId,
          });

          sendMessage(FES_EVENTS.TOGGLE_VIEW, {
            all: true,
            showRooms: false,
            showDesks: false,
            showOccupants: false,
          });

          sendMessage(FES_EVENTS.INITIAL_LOAD);
          break;
        case FES_EVENT_FLOORPLAN_DATA_LOADED:
          onFloorplanDataLoaded(data);
          break;
        case FES_EVENTS.FLOORPLAN_LOADED:
          // flag floorplan is loaded
          dispatch(floorplanActions.setIsLoaded(true));
          if (focusedSpace) {
            sendMessage(FES_EVENTS.SHOW_SPACE, {
              id: focusedSpace.id,
              zoom: true,
              select: true,
            });
          }
          break;
        case FES_EVENTS.SHOW_SPACE: {
          const { data: _data } = data;
          if (!_data) {
            dispatch(floorplanActions.setFocusedSpace(null));
          }
          break;
        }
        case FES_EVENTS.TEAM_RESERVATION_ADD_DESKS: {
          const { desks } = data;
          dispatch(teamReservationsActions.addDesksToTeamReservation(desks));
          break;
        }
        default:
          logger.info(data)();
      }
    },
    [
      dispatch,
      sendMessage,
      zoomTilesShown,
      reservedAlwaysEnabled,
      floorplanTheme,
      startTime,
      endTime,
      floorId,
      onFloorplanDataLoaded,
      focusedSpace,
      onFloorPlanClickHandler,
    ],
  );

  const onFloorplanErrorRetry = () => {
    setShowRecovery(false);
    // On retry run floorplan reinitialization
    onMessage({ type: FES_EVENTS.INIT });
  };

  return (
    <>
      <OverlayFloorplan showOverlay={showOverlay}>
        <FloorplanComponent
          hideRecoveryPane={() => setShowRecovery(false)}
          onMessage={onMessage}
          restrictToSearchFilterValue={restrictTo}
          ref={floorplanRef}
          floorId={floorId}
          tenantId={tenantId}
          hidePeopleData={hidePeopleData}
        />
      </OverlayFloorplan>
      {showRecovery && (
        <FloorplanError name={errorMessage} onRetry={onFloorplanErrorRetry} />
      )}
    </>
  );
};

FloorplanHandler.propTypes = {};

FloorplanHandler.defaultProps = {};

export default FloorplanHandler;
