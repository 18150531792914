import { fontSizesPX, getDefaultTheme, headerSizesPX } from 'theme';
export var variantDef = {
    circle: 0.5,
    rounded: 0.3,
    square: 0.0,
    side: 0.0,
};
export var sizeDef = {
    tiny: 28,
    small: 36,
    medium: 52,
    large: 72,
    giant: 112,
};
export var textSize = {
    tiny: fontSizesPX.btn,
    small: fontSizesPX.bdl,
    medium: headerSizesPX.h6,
    large: headerSizesPX.h3,
    giant: headerSizesPX.h1,
};
export var STATUS;
(function (STATUS) {
    STATUS["PRIMARY"] = "primary";
    STATUS["SUCCESS"] = "success";
    STATUS["WARNING"] = "warning";
    STATUS["ERROR"] = "error";
})(STATUS || (STATUS = {}));
var _a = getDefaultTheme(), primary = _a.status1, success = _a.status2, warning = _a.status3, error = _a.status4;
export var badgeColor = {
    primary: primary,
    success: success,
    warning: warning,
    error: error,
};
