import { createSelector, createSlice } from '@reduxjs/toolkit';

const layoutInitialState = {
  layout: null,
  content: {},
};

const initialState = {
  bypassSSO: false,
  deepLinkQueryString: null,
  deepLinkPath: null,
  modal: { ...layoutInitialState },
  showTimelineDatePicker: false,
  conciergeMenuShowed: false,
};

const navigation = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setBypassSSO(state, action) {
      state.bypassSSO = action.payload;
    },
    setDeepLinkQueryString(state, action) {
      state.deepLinkQueryString = action.payload;
    },
    setDeepLinkPath(state, action) {
      state.deepLinkPath = action.payload;
    },
    clearDeepLinkPath(state) {
      state.deepLinkPath = null;
    },
    setModalLayout(state, action) {
      state.modal = {
        layout: action.payload.layout,
        content: action.payload.content,
      };
    },
    setShowTimelineDatePicker(state, action) {
      state.showTimelineDatePicker = action.payload;
    },
    setConciergeMenuShowed(state, action) {
      state.conciergeMenuShowed = action.payload;
    },
    clearDeepLinkQueryString(state) {
      state.deepLinkQueryString = null;
    },
    clearModal(state) {
      state.modal = { ...layoutInitialState };
    },
    clear() {
      return { ...initialState };
    },
  },
});

const getNavigation = (state) => state.navigation;
const getBypassSSO = createSelector(
  getNavigation,
  (navigation) => navigation.bypassSSO,
);
const getDeepLinkQueryString = createSelector(
  getNavigation,
  (navigation) => navigation.deepLinkQueryString,
);
const getModalLayout = createSelector(
  getNavigation,
  (navigation) => navigation.modal,
);
const getShowTimelineDatePicker = createSelector(
  getNavigation,
  (navigation) => navigation.showTimelineDatePicker,
);
const getDeepLinkPath = createSelector(
  getNavigation,
  (navigation) => navigation.deepLinkPath,
);

export const navigationSelectors = {
  getBypassSSO,
  getDeepLinkQueryString,
  getModalLayout,
  getShowTimelineDatePicker,
  getDeepLinkPath,
};

export default navigation;
