var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import SpaceCard from './SpaceCard/SpaceCard';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n\n  @media (max-height: 760px) {\n    height: calc(100vh - 218px);\n    overflow-y: auto;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n\n  @media (max-height: 760px) {\n    height: calc(100vh - 218px);\n    overflow-y: auto;\n  }\n"])));
var InnerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  ", ";\n"])), function (_a) {
    var $marginBottom = _a.$marginBottom;
    return $marginBottom && "margin-bottom: ".concat($marginBottom, "px");
});
var StyledSpaceCard = styled(SpaceCard)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var ReserveButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  padding: 24px 0;\n  text-align: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: ", ";\n  font-weight: 700;\n  margin-top: 20px;\n  &.focus-visible {\n    outline: none;\n    box-shadow: inset 0 0 3px 5px Highlight;\n  }\n"], ["\n  width: 100%;\n  padding: 24px 0;\n  text-align: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: ", ";\n  font-weight: 700;\n  margin-top: 20px;\n  &.focus-visible {\n    outline: none;\n    box-shadow: inset 0 0 3px 5px Highlight;\n  }\n"])), function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled ? '#cccccc' : theme.color9;
}, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return (disabled ? '#000000' : theme.typo1);
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'not-allowed' : 'pointer');
});
var AvailabilityBarWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
var PersonButtonWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 20px 0 10px 0;\n"], ["\n  margin: 20px 0 10px 0;\n"])));
var DaysSelectorWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 300px;\n"], ["\n  width: 300px;\n"])));
export { Wrapper, InnerWrapper, StyledSpaceCard, ReserveButton, AvailabilityBarWrapper, PersonButtonWrapper, DaysSelectorWrapper, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
