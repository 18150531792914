var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppLocation, useAppParams } from 'router/hooks';
import { floorplanActions, inAppNotificationsActions, teamReservationsSelectors, tenantActions, tenantSelectors, } from 'store';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { TEAM_RESERVATION_DESKS_SELECTION_MODE } from 'store/teamReservations';
import { useTeam } from 'api/queries/useTeam';
import { useTranslation } from 'react-i18next';
import { generateTeamPath } from 'router/utils';
import { useNavigate } from 'react-router-dom';
import { useTeamBookingsInCurrentInterval } from '@serraview/engage-shared';
import { useReservationFilters } from 'utils';
import { TeamMembersError } from './TeamMembersError';
import { TeamMembersCard } from './TeamMembersCard';
import { TeamMembersSkeleton } from './TeamMembersSkeleton';
import { TeamMembersHeader } from './TeamMembersHeader';
import { DeskSelectionCard } from './DeskSelectionCard';
export function TeamMembersContainer() {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var teamId = useAppParams().teamId;
    var state = useAppLocation().state;
    var selectionMode = useSelector(teamReservationsSelectors.getTeamReservationDesksSelectionMode);
    var currentFloorId = useSelector(tenantSelectors.getCurrentFloorId);
    var teamFromState = state === null || state === void 0 ? void 0 : state.team;
    var _a = useTeam({
        id: teamId,
        teamFromState: teamFromState,
    }), data = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var team = data !== null && data !== void 0 ? data : teamFromState;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var filterDateStart = useReservationFilters(true).dateStart;
    var teamBookings = useTeamBookingsInCurrentInterval({
        filterDateStart: filterDateStart,
        teamId: team === null || team === void 0 ? void 0 : team.id,
        tenantId: tenantId,
        options: {
            enabled: team && !team.favPeopleTeam,
        },
    });
    useEffect(function () {
        if (!(team === null || team === void 0 ? void 0 : team.location)) {
            return;
        }
        // display team's floorplan if team is on a different floor than the currently displayed floor
        if (currentFloorId !== team.location.floorId) {
            var pathname = generateTeamPath({
                floorId: team.location.floorId,
                teamId: team.id,
            });
            navigate(pathname, {
                replace: true,
                state: __assign(__assign({}, state), { isFinalLocation: true, team: team }),
            });
            dispatch(tenantActions.setCurrentLocation(team.location));
        }
    }, [currentFloorId, dispatch, navigate, state, team]);
    useEffect(function () {
        var _a;
        if (!(team === null || team === void 0 ? void 0 : team.location)) {
            return;
        }
        // highlight team booking's desks
        if ((teamBookings === null || teamBookings === void 0 ? void 0 : teamBookings.length) > 0) {
            dispatch(floorplanActions.setFocusedSpace({
                id: teamBookings[0].spaceIds,
            }));
        }
        else {
            var shouldFocusSpaces = ((_a = team.location.spaceIds) === null || _a === void 0 ? void 0 : _a.length) > 0;
            // highlight team's desks
            if (shouldFocusSpaces && team.location.floorId === currentFloorId) {
                dispatch(floorplanActions.setFocusedSpace({
                    id: team.location.spaceIds,
                }));
            }
        }
    }, [dispatch, t, team, currentFloorId, teamBookings]);
    useEffect(function () {
        var _a;
        if (!(team === null || team === void 0 ? void 0 : team.location)) {
            return;
        }
        // show a notification if team doesn't have desks
        if (((_a = team.location.spaceIds) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            var message = void 0;
            if (team.name) {
                message = t('layout.teamMembersList.noSpacesTeam', {
                    teamName: team.name,
                });
            }
            else {
                message = t('layout.teamMembersList.genericNoSpacesTeam');
            }
            dispatch(inAppNotificationsActions.addWarningNotification({
                message: message,
            }));
        }
    }, [dispatch, t, team]);
    if (isLoading) {
        return React.createElement(TeamMembersSkeleton, null);
    }
    if (isError) {
        return React.createElement(TeamMembersError, null);
    }
    var isDeskSelection = selectionMode !== TEAM_RESERVATION_DESKS_SELECTION_MODE.DISABLED;
    return (React.createElement(React.Fragment, null,
        React.createElement(TeamMembersHeader, { teamName: team.name, membersCount: team.people }),
        React.createElement(DrawerCardContent, null, isDeskSelection ? (React.createElement(DeskSelectionCard, { team: team })) : (React.createElement(TeamMembersCard, { team: team })))));
}
