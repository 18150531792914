import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useSpaceImage } from 'api/queries/useSpaceImage';
import { ViewableAvatar, Capacity } from 'base-components';
import { useSetPageTitle } from 'utils/hooks';
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
import { TextWrapper, TitleWrapper, CapacityWrapper, StyledDescription, Wrapper, StyledTitle, } from './styled';
import SpaceCardStatus from './SpaceCardStatus';
function SpaceCard(_a) {
    var _b;
    var id = _a.id, name = _a.name, description = _a.description, _c = _a.type, type = _c === void 0 ? RESTRICT_TO_VALUES.DESK : _c, isReserved = _a.isReserved, downloadableImageUrl = _a.downloadableImageUrl, imageUrl = _a.imageUrl, status = _a.status, capacity = _a.capacity;
    var t = useTranslation().t;
    var _d = useTheme(), gray4 = _d.gray4, color5 = _d.color5;
    var isDesk = type === RESTRICT_TO_VALUES.DESK;
    var hasCapacity = !isDesk && Boolean(capacity);
    var title = (_b = name !== null && name !== void 0 ? name : id) !== null && _b !== void 0 ? _b : '';
    var iconName = isDesk ? 'desk' : 'space';
    var iconStyle = {
        color: gray4,
    };
    var spaceImageQuery = useSpaceImage({
        spaceId: id,
        imageSrc: downloadableImageUrl,
        options: {
            // only start img download if the ready-to-use image URL is not provided
            enabled: !imageUrl,
        },
    });
    var downloadImageUrl = spaceImageQuery === null || spaceImageQuery === void 0 ? void 0 : spaceImageQuery.data;
    var documentTitle = type
        ? t("documentTitles.".concat(type.toLowerCase(), "Card"), { name: title })
        : 'Card';
    useSetPageTitle(documentTitle);
    return (React.createElement(Wrapper, null,
        React.createElement(ViewableAvatar, { source: imageUrl || downloadImageUrl, size: "giant", icon: iconName, iconStyle: iconStyle, alt: t("accessibilityLabels.".concat(type.toLowerCase(), "Image")), style: {
                borderWidth: 2,
                borderColor: color5,
                borderStyle: 'solid',
                background: 'transparent',
            } }),
        React.createElement(SpaceCardStatus, { status: status }),
        React.createElement(TextWrapper, { isReserved: isReserved },
            React.createElement(TitleWrapper, null,
                React.createElement(StyledTitle, { "data-testid": "space-title" }, title),
                hasCapacity && (React.createElement(CapacityWrapper, null,
                    React.createElement(Capacity, { capacity: capacity, fontSize: 13, iconSize: 12, opacity: 0.5 })))),
            React.createElement(StyledDescription, null, description))));
}
export default SpaceCard;
