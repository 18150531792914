import React from 'react';
import { useTranslation } from 'react-i18next';
import { Version } from './styled';

export const AppVersion = () => {
  const { t } = useTranslation();
  const versionText = t('components.appVersion.version');

  return (
    <Version data-testid="app-version">
      <b>{versionText}:</b> {__CONF__.version}
    </Version>
  );
};
