var _a;
import { RESTRICT_TO_VALUES } from '@serraview/engage-shared/constants';
import { PATH_SEGMENT } from './router';
var RESTRICT_TO_SEARCH_FILTER_ITEMS = (_a = {},
    _a[RESTRICT_TO_VALUES.SPACE] = {
        iconName: 'space',
        text: 'layout.floorplanViewSelector.spaces',
        path: PATH_SEGMENT.SPACES,
    },
    _a[RESTRICT_TO_VALUES.DESK] = {
        iconName: 'desk',
        text: 'layout.floorplanViewSelector.desks',
        path: PATH_SEGMENT.DESKS,
    },
    _a[RESTRICT_TO_VALUES.PERSON] = {
        iconName: 'people',
        text: 'layout.floorplanViewSelector.people',
        path: PATH_SEGMENT.PEOPLE,
    },
    _a[RESTRICT_TO_VALUES.TEAM] = {
        iconName: 'teams',
        text: 'layout.floorplanViewSelector.teams',
        path: PATH_SEGMENT.TEAMS,
    },
    _a);
export { RESTRICT_TO_SEARCH_FILTER_ITEMS };
