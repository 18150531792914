import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_QUERY, MODALS } from 'consts';
import { navigationSelectors } from 'store';
import { useModal } from 'utils';
/**
 * This hook opens/closes booking details modal (team and space)
 * based on search query parameters.

 * Previously this was a part of `useModalLayout`, but since that hook
 * was called in multiple places – duplicated actions were dispatched.
 */
export function useModalSearchQuery() {
    var _a = useModal(), openModal = _a.openModal, closeModal = _a.closeModal;
    var layout = useSelector(navigationSelectors.getModalLayout).layout;
    var query = useSearchParams()[0];
    var bookingSpaceId = query.get(SEARCH_QUERY.BOOKING_SPACE);
    var bookingMeetingId = query.get(SEARCH_QUERY.BOOKING_MEETING);
    var teamBookingMeetingId = query.get(SEARCH_QUERY.TEAM_BOOKING_MEETING);
    var attendeeId = Number(query.get(SEARCH_QUERY.ATTENDEE_ID));
    var showBooking = (!!bookingSpaceId && !!bookingMeetingId) || !!teamBookingMeetingId;
    useEffect(function () {
        if (layout !== MODALS.AGENDA_ITEM &&
            layout !== MODALS.TEAM_AGENDA_ITEM &&
            showBooking &&
            layout !== MODALS.BOOKING_LOCATION_UNAVAILABLE &&
            layout !== MODALS.HEALTH_DECLARATION) {
            if (teamBookingMeetingId) {
                openModal(MODALS.TEAM_AGENDA_ITEM, {
                    event: {
                        meetingId: teamBookingMeetingId,
                        attendeeId: attendeeId,
                    },
                });
            }
            else {
                openModal(MODALS.AGENDA_ITEM, {
                    event: {
                        spaceId: bookingSpaceId,
                        meetingId: bookingMeetingId,
                        teamBookingMeetingId: teamBookingMeetingId,
                    },
                });
            }
        }
    }, [
        bookingMeetingId,
        bookingSpaceId,
        teamBookingMeetingId,
        attendeeId,
        layout,
        showBooking,
        openModal,
    ]);
    useEffect(function () {
        if (layout && layout === MODALS.AGENDA_ITEM && !showBooking) {
            closeModal();
        }
    }, [closeModal, layout, showBooking]);
}
