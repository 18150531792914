import React from 'react';
import { FocusId, useFocus } from 'utils/hooks';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { useTranslation } from 'react-i18next';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { PATH_SEGMENT } from 'consts';
import { useAppLocation } from 'router/hooks';
import { removeSegmentFromPath } from 'router/utils';
import SearchFilter from './SearchFilter';
import { Content } from './styled';
var ReservationAssist = function () {
    var pathname = useAppLocation().pathname;
    var t = useTranslation().t;
    var title = t('layout.reservationAssist.title');
    var subtitle = t('layout.reservationAssist.subtitle');
    var closeLinkPath = removeSegmentFromPath(PATH_SEGMENT.FILTERS, pathname);
    useFocus({ returnFocusTo: FocusId.GlobalSearchFilterButton });
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, { title: title, subtitle: subtitle, closeLinkPath: closeLinkPath, closeIconA11yLabel: "accessibilityLabels.closeLayout_reserveAssist", isCloseIcon: true }),
        React.createElement(DrawerCardContent, null,
            React.createElement(Content, { "data-testid": "ReservationAssistScreen" },
                React.createElement(SearchFilter, null)))));
};
export default ReservationAssist;
