import React from 'react';
import { EmptyCard } from 'base-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Button from '../Button/Button';
import { Wrapper, ErrorLabel, ErrorName } from './styled';

const FloorplanError = ({ name, onRetry }) => {
  const { t } = useTranslation();
  const { color9, color4 } = useTheme();
  return (
    <Wrapper>
      <EmptyCard iconName="info" isErrorFloorplan>
        <p>{t('layout.floorplanError.errorOccurred')}</p>
        {!!name?.length && (
          <p>
            <ErrorLabel>
              {`${t('layout.floorplanError.errorMessage')} `}
            </ErrorLabel>
            <ErrorName>{name}</ErrorName>
          </p>
        )}
      </EmptyCard>
      <Button
        onClick={onRetry}
        text={t('layout.floorplanError.retry')}
        color={color4}
        backgroundColor={color9}
      />
    </Wrapper>
  );
};

FloorplanError.propTypes = {
  name: PropTypes.string,
  onRetry: PropTypes.func,
};

FloorplanError.defaultProps = {
  name: '',
  onRetry: () => {},
};

export default FloorplanError;
