import React, { memo } from 'react';
import { SpaceMarker } from 'components/floorplan/components/Marker/SpaceMarker';
import { PersonMarker } from 'components/floorplan/components/Marker/PersonMarker';
var Marker = function (_a) {
    var space = _a.space, person = _a.person;
    if (person) {
        return React.createElement(PersonMarker, { space: space, person: person });
    }
    return React.createElement(SpaceMarker, { space: space });
};
export default memo(Marker);
