import {
  getClosestNumber,
  getLocalizedDate,
  HOURS_12_VALUES,
  QUARTER_HOUR_VALUES,
} from '@serraview/engage-shared';
import { format, isSameMinute, isToday, parse } from 'date-fns';
import { TIME_TYPES } from '../data';

// Key code used to open dropdown in order to test some functionalities
const ARROW_DOWN_KEY_CODE = 40;

const time12FormatWithDash = 'h-m-aaa'; // 11-45-PM
const time24FormatWithDash = 'H-m'; // to 23-45

export const splitTime = (value, isTimeFormatH12) => {
  const formatHour = isTimeFormatH12 ? 'hh' : 'HH';
  return {
    [TIME_TYPES.HOUR]: value.format(formatHour),
    [TIME_TYPES.MINUTE]: value.format('mm'),
    ...(isTimeFormatH12 && {
      [TIME_TYPES.PERIOD]: value.format('A'),
    }),
  };
};

export const getTimeStringFromPortions = (portions, isTimeFormatH12) =>
  `${portions[TIME_TYPES.HOUR]}-${portions[TIME_TYPES.MINUTE]}${
    isTimeFormatH12 ? `-${portions[TIME_TYPES.PERIOD]}` : ''
  }`;

export const convert12hto24hFormat = ({ minute, hour, period, fullDate }) =>
  // the easiest way to convert from 12h format to 24h format
  // 1. parse 'hour-minute-period' to Date 2. format date to hour-minute in 24h format
  format(
    parse(
      `${hour}-${minute}-${period}`,
      time12FormatWithDash, // 11-45-PM
      fullDate.toDate(),
    ),
    time24FormatWithDash, // to 23-45
  ).split('-'); // to [23, 45] means [hours, minutes]

export const timePortionsToDate = (portions, fullDate) => {
  const isTimeFormatH12 = !!portions.period;
  if (isTimeFormatH12) {
    const [hours, minutes] = convert12hto24hFormat({ ...portions, fullDate });
    return fullDate.clone().hours(hours).minutes(minutes);
  }

  return fullDate
    .clone()
    .hours(parseInt(portions.hour, 10))
    .minutes(parseInt(portions.minute, 10));
};

export const getClosestValidTime = (momentDate) => {
  const roundedDate = momentDate.clone().seconds(0);
  const roundedDateMinutes = roundedDate.minutes();
  const closestMinutes = getClosestNumber(
    roundedDateMinutes,
    QUARTER_HOUR_VALUES,
  );
  if (
    roundedDate
      .clone()
      .add(closestMinutes - roundedDateMinutes, 'm')
      .isBefore(roundedDate)
  ) {
    // assuming we always start from 0, use next step to add extra minutes
    // this is in case steps change in the future (ex. step = 10min)
    return roundedDate
      .clone()
      .add(closestMinutes + QUARTER_HOUR_VALUES[1] - roundedDateMinutes, 'm');
  }
  return roundedDate.clone().add(closestMinutes - roundedDateMinutes, 'm');
};

export const getDateFromTimeSegment = (
  timePickerSegment,
  baseDate,
  isTimeFormatH12,
) => {
  const newState = {
    ...splitTime(baseDate, isTimeFormatH12),
    [timePickerSegment.type]: timePickerSegment.id,
  };

  const newTime = timePortionsToDate(newState, baseDate);
  const nextDate = newTime.toDate();

  // Prevents calling callback function when we clicked current date in the calendar
  if (isToday(nextDate) && isSameMinute(nextDate, baseDate.toDate())) {
    return null;
  }

  return nextDate;
};

// First hour is 12 when time format is AM/PM
export const formattedHours12Options = () => {
  HOURS_12_VALUES.unshift(HOURS_12_VALUES[HOURS_12_VALUES.length - 1]);
  HOURS_12_VALUES.pop();
  return HOURS_12_VALUES;
};

export const mapTimeValueToObjects = (timeValues, timeType, enabledValues) =>
  timeValues.map((value) => ({
    id: `${value}`,
    value: value < 10 ? `0${value}` : `${value}`,
    label: value < 10 ? `0${value}` : `${value}`,
    type: timeType,
    isDisabled: !enabledValues.includes(value),
  }));

export const mapPeriodsToObjects = (periodValues, enabledValues) =>
  periodValues.map((period) => ({
    id: period,
    value: period.toUpperCase(),
    label: period.toUpperCase(),
    type: TIME_TYPES.PERIOD,
    isDisabled: !enabledValues.includes(period),
  }));

export const openSelectDropdown = (fireEvent, input) => {
  fireEvent.keyDown(input, { keyCode: ARROW_DOWN_KEY_CODE });
};

export const getTimePickerValues = ({ date, timeZone, isTimeFormatH12 }) => {
  const localizedDate = getLocalizedDate({
    date,
    timeZone,
  });
  const roundedDate = getClosestValidTime(localizedDate);
  const timeValue = splitTime(roundedDate, isTimeFormatH12);

  return { timeValue, roundedDate };
};
