import { useSelector } from 'react-redux';
import { getBuildingGoogleMapsLink } from '@serraview/engage-shared';
import { tenantSelectors } from 'store';
/**
 * Opens building location into a new browser tab.
 */
export var useOpenBuildingInGoogleMaps = function () {
    var building = useSelector(tenantSelectors.getCurrentLocation).building;
    var buildingGoogleMapsLink = getBuildingGoogleMapsLink(building);
    return function () { return window.open(buildingGoogleMapsLink, '_blank'); };
};
