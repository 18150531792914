import { useSelector, useDispatch } from 'react-redux';
import { navigationSelectors, navigationActions } from 'store';
import { MODALS } from 'consts';
import { useBookingModal } from './useBookingModal';
var defaultOptions = {
    shouldClearBookingQuery: true,
};
export function useModal() {
    var _a = useSelector(navigationSelectors.getModalLayout), layout = _a.layout, content = _a.content;
    var dispatch = useDispatch();
    var closeBookingModal = useBookingModal().close;
    var isBookingDetailsOpened = layout === MODALS.AGENDA_ITEM || layout === MODALS.TEAM_AGENDA_ITEM;
    var openModal = function (nextLayout, nextContent, options) {
        if (options === void 0) { options = defaultOptions; }
        if (layout === nextLayout) {
            dispatch(navigationActions.clear());
            return;
        }
        if (isBookingDetailsOpened && options.shouldClearBookingQuery) {
            closeBookingModal();
        }
        dispatch(navigationActions.setModalLayout({
            layout: nextLayout,
            content: nextContent,
        }));
    };
    var closeModal = function () {
        if (isBookingDetailsOpened) {
            closeBookingModal();
        }
        dispatch(navigationActions.clearModal());
    };
    return {
        layout: layout,
        content: content,
        openModal: openModal,
        closeModal: closeModal,
    };
}
