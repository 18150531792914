var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var HEIGHT = '36px';
var MIN_WIDTH = '36px';
var MAX_WIDTH = '46px';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var BookedWrapper = styled(Wrapper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n  padding: 28px 10px 28px 45px;\n  cursor: pointer;\n"], ["\n  width: 100%;\n  background-color: ", ";\n  padding: 28px 10px 28px 45px;\n  cursor: pointer;\n"])), function (props) { return props.theme.color9; });
export var BookForWrapper = styled(Wrapper)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-bottom: 30px;\n"], ["\n  padding-bottom: 30px;\n"])));
export var BookedTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 18px;\n  font-weight: bold;\n  color: ", ";\n"], ["\n  font-size: 18px;\n  font-weight: bold;\n  color: ", ";\n"])), function (props) { return props.theme.typo1; });
export var BookForTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: bold;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  font-weight: bold;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var BookedTeamWrapper = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: ", ";\n  cursor: pointer;\n  overflow: hidden;\n  max-width: 70%;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: ", ";\n  cursor: pointer;\n  overflow: hidden;\n  max-width: 70%;\n"])), HEIGHT);
export var BookForTeamWrapper = styled(BookedTeamWrapper)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    position: relative;\n    background-color: ", ";\n    border-radius: 20px;\n    margin-left: 5px;\n  }\n"], ["\n  && {\n    position: relative;\n    background-color: ", ";\n    border-radius: 20px;\n    margin-left: 5px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
export var BookedTeamName = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 18px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n"], ["\n  color: ", ";\n  font-size: 18px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n"])), function (props) { return props.theme.typo1; });
export var BookForTeamName = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  margin-left: 5px;\n  padding: 10px ", " 10px 10px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  margin-left: 5px;\n  padding: 10px ", " 10px 10px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
}, MAX_WIDTH);
export var BookedTeamNumberWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n  color: ", ";\n  background-color: rgba(0, 0, 0, 0.45);\n  min-width: ", ";\n  max-width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  margin: 0 5px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n  color: ", ";\n  background-color: rgba(0, 0, 0, 0.45);\n  min-width: ", ";\n  max-width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  margin: 0 5px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
}, MIN_WIDTH, MAX_WIDTH, HEIGHT, MAX_WIDTH);
export var BookForTeamNumberWrapper = styled(BookedTeamNumberWrapper)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  top: 0;\n  margin: 0;\n"], ["\n  position: absolute;\n  right: 0;\n  top: 0;\n  margin: 0;\n"])));
export var TeamNumber = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n  padding: 0 5px;\n  justify-content: center;\n  align-items: center;\n"], ["\n  font-size: 14px;\n  color: ", ";\n  padding: 0 5px;\n  justify-content: center;\n  align-items: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
