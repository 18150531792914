import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions, tenantSelectors } from 'store';
import { CALLBACK_PATH } from 'consts';
import { Mask } from 'base-components';
import { captureSentryError } from 'utils/analytics/sentry';
import { changeLocationHref } from 'utils/helpers';

const clientId = __CONF__.CLIENT_ID;
const redirectUri = __DEV__ ? window.location.origin : __CONF__.REDIRECT_URI;

const generateSSOURL = ({ tenantId, clientId, callbackURL }) =>
  `https://${tenantId}.serraview.com/api/v1/engage_auth/auth?client_id=${clientId}&redirect_uri=${callbackURL}&response_type=token`;

export const SsoLoginRouter = () => {
  const tenantId = useSelector(tenantSelectors.getTenantIdToLogin);
  const dispatch = useDispatch();
  const callbackURL = `${redirectUri}${CALLBACK_PATH}`;
  const ssoURL = generateSSOURL({ tenantId, clientId, callbackURL });

  React.useEffect(() => {
    if (tenantId) {
      dispatch(tenantActions.clearNextTenantId());
      if (tenantId === 'cibc') {
        captureSentryError(`[SsoLoginRouter] ssoURL: ${ssoURL}`);
      }

      changeLocationHref(ssoURL);
    }
  }, [tenantId, dispatch, ssoURL]);

  return <Mask isLoading />;
};
