var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import LocationStatusLabel from 'components/base/LocationStatusLabel/LocationStatusLabel';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { testProps } from 'utils';
var SpaceCardStatus = function (_a) {
    var _b = _a.status, status = _b === void 0 ? '' : _b;
    var t = useTranslation().t;
    var color1 = useTheme().color1;
    if (status) {
        var _c = testProps(t, status), a11yAndTestProps = _c.props, statusText = _c.text[0];
        return (React.createElement(LocationStatusLabel, __assign({}, a11yAndTestProps, { text: statusText, backgroundColor: color1 })));
    }
    return null;
};
export default memo(SpaceCardStatus);
