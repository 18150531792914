import * as React from 'react';
import PropTypes from 'prop-types';
import { calculateTransform } from 'floorplan/utils/openseadragon';
import {
  ANIMATION_EVENT,
  RESIZE_EVENT,
  ROTATE_EVENT,
} from 'floorplan/constants/OpenSeadragon';
import FloorplanViewerContext from 'floorplan/contexts/FloorplanViewerContext';
import { useTranslation } from 'react-i18next';

const getTransformString = (transform) =>
  transform
    ? `translate(${transform.translateX}, ${transform.translateY}) ` +
      `scale(${transform.scale}) ` +
      `rotate(${transform.rotate})`
    : null;

const FloorplanLabels = ({ showLabels, children }) => {
  const viewer = React.useContext(FloorplanViewerContext);
  const { t } = useTranslation();

  const parentRef = React.useRef(null);
  const svgRef = React.useRef(null);

  const [transform, setTransform] = React.useState(null);

  const setParentRef = (element) => {
    parentRef.current = element;
  };

  const setSvgRef = (element) => {
    svgRef.current = element;
  };

  const handleAnimation = React.useCallback(() => {
    const calcTransform = calculateTransform(viewer);
    setTransform(calcTransform);
  }, [viewer]);

  const addViewerHandlers = React.useCallback(() => {
    if (viewer) {
      viewer.addHandler(ANIMATION_EVENT, handleAnimation);
      viewer.addHandler(RESIZE_EVENT, handleAnimation);
      viewer.addHandler(ROTATE_EVENT, handleAnimation);
      viewer.canvas.appendChild(svgRef.current);
    }
  }, [handleAnimation, viewer]);

  const removeViewerHandlers = React.useCallback(() => {
    if (viewer) {
      viewer.removeHandler(ANIMATION_EVENT, handleAnimation);
      viewer.removeHandler(RESIZE_EVENT, handleAnimation);
      viewer.removeHandler(ROTATE_EVENT, handleAnimation);
      if (parentRef.current) {
        parentRef.current.appendChild(svgRef.current);
      }
    }
  }, [handleAnimation, viewer]);

  React.useEffect(() => {
    if (viewer) {
      removeViewerHandlers();
      addViewerHandlers();
      handleAnimation();
    }
    return () => {
      removeViewerHandlers();
    };
  }, [addViewerHandlers, handleAnimation, removeViewerHandlers, viewer]);

  const transformString = getTransformString(transform);

  return (
    <div ref={setParentRef}>
      <svg
        ref={setSvgRef}
        className="floorplan-labels"
        aria-label={t('accessibilityLabels.floorplanLabels')}
      >
        {showLabels && viewer && <g transform={transformString}>{children}</g>}
      </svg>
    </div>
  );
};

FloorplanLabels.propTypes = {
  showLabels: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

FloorplanLabels.defaultProps = {
  showLabels: false,
  children: null,
};

export default FloorplanLabels;
