import { getLocalizedDate } from '@serraview/engage-shared/utils';
import { usePickerTimeOptions } from 'components/base/DateTime/DateTimePicker/usePickerTimeOptions';
import { getTimePickerValues } from 'components/base/DateTime/TimePicker/utils';
import { useLocalTimeFormatH12 } from 'utils';
export var useTimePickerConfig = function (_a) {
    var minDate = _a.minDate, selectedDate = _a.selectedDate, timeZone = _a.timeZone;
    var isTimeFormatH12 = useLocalTimeFormatH12();
    var localizedMinDate = getLocalizedDate({ date: minDate, timeZone: timeZone });
    var timePickerOptions = usePickerTimeOptions({
        date: selectedDate,
        minTime: localizedMinDate,
        timeZone: timeZone,
    });
    var _b = getTimePickerValues({
        date: selectedDate,
        timeZone: timeZone,
        isTimeFormatH12: isTimeFormatH12,
    }), roundedDate = _b.roundedDate, timeValue = _b.timeValue;
    return { timePickerOptions: timePickerOptions, roundedDate: roundedDate, timeValue: timeValue };
};
