import { useLoadKioskConfig } from 'utils/hooks/useLoadKioskConfig';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
// re-fetch interval is 1 hour
var REFETCH_INTERVAL = 60 * 60 * 1000;
/**
 * This component is used for conditional rendering to avoid having disabled useLoadKioskConfig
 * query in non-electron environment. The purpose of the component is to use useLoadKioskConfig
 * to load kiosk config with given interval.
 * @constructor
 */
var KioskConfigLoader = function () {
    var spaceId = useSelector(tenantSelectors.getKioskId);
    useLoadKioskConfig({
        spaceId: spaceId,
        options: {
            enabled: !!spaceId,
            refetchInterval: REFETCH_INTERVAL,
        },
    });
    return null;
};
export default KioskConfigLoader;
