import styled from 'styled-components';

export const BUTTON_WIDTH = '175';
export const BUTTON_WIDTH_PX = `${BUTTON_WIDTH}px`;

export const AppLaunchContainer = styled.div`
  background-color: ${({ theme }) => theme.color6};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 730px) {
    flex-direction: column;
    padding-left: 45px;
    padding-right: 45px;
  }
`;

export const PhoneImageWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 730px) {
    justify-content: center;
    align-items: flex-end;
    height: 25%;
  }
`;

export const PhoneImage = styled.img`
  padding-top: 80px; //SVG export has some bottom-padding and adding this to center the image
  width: 336px;
  @media only screen and (max-width: 730px) {
    width: 132px;
  }
`;

export const ContentWrapper = styled.div`
  min-width: ${BUTTON_WIDTH_PX};
  max-width: ${BUTTON_WIDTH * 2 + 20}px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 60px;
  @media only screen and (max-width: 730px) {
    height: 50%;
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.typo2};
  font-size: 32px;
  line-height: 44px;
  text-align: left;
  @media only screen and (max-width: 730px) {
    text-align: center;
  }
`;

export const Description = styled.div`
  color: ${(props) => props.theme.typo2};

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  text-align: left;
  padding-top: 12px;
  @media only screen and (max-width: 730px) {
    text-align: center;
    h3 {
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 5px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 30px;
  min-width: ${BUTTON_WIDTH_PX};
  max-width: ${BUTTON_WIDTH * 2 + 10}px; //buttons in row with 10px between them

  button {
    padding: 0;
  }

  @media only screen and (max-width: 730px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const StoreButtonImage = styled.img`
  width: ${BUTTON_WIDTH_PX};
  margin-bottom: 10px;
`;

export const QRCodeWrapper = styled.div`
  display: flex;
  width: ${BUTTON_WIDTH * 2 + 20}px;
  margin: 10px 0;

  @media only screen and (max-width: 730px) {
    justify-content: center;
  }
`;
