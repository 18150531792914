import PropTypes from 'prop-types';

const personPropTypes = {
  imageUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobTitle: PropTypes.string,
  department: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  locationName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  chatLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      chatType: PropTypes.oneOf(['Teams', 'Slack']),
      chatUrl: PropTypes.string,
    }),
  ]),
  isFirstAidOfficer: PropTypes.bool,
  isFireWarden: PropTypes.bool,
  checkedIn: PropTypes.bool,
  assigned: PropTypes.bool,
  reserved: PropTypes.bool,
};

Object.defineProperty(personPropTypes, 'defaultProps', {
  value: {
    imageUrl: null,
    firstName: null,
    lastName: null,
    jobTitle: null,
    department: null,
    location: null,
    locationName: null,
    phone: null,
    email: null,
    chatLink: null,
    isFirstAidOfficer: false,
    isFireWarden: false,
    checkedIn: false,
    assigned: false,
    reserved: false,
  },
});

export { personPropTypes };
