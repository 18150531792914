var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  align-items: center;\n  margin-left: -16px;\n"], ["\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  align-items: center;\n  margin-left: -16px;\n"])));
var Oval = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  width: 8px;\n  height: 8px;\n  border-radius: 50px;\n  border: 2px solid ", ";\n  background-color: ", ";\n  left: 0;\n"], ["\n  position: absolute;\n  width: 8px;\n  height: 8px;\n  border-radius: 50px;\n  border: 2px solid ", ";\n  background-color: ", ";\n  left: 0;\n"])), function (props) { return props.color; }, function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
var Line = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 1px;\n  width: 100%;\n  border-radius: 0.5px;\n  border: 0.5px solid ", ";\n"], ["\n  height: 1px;\n  width: 100%;\n  border-radius: 0.5px;\n  border: 0.5px solid ", ";\n"])), function (props) { return props.color; });
export { Wrapper, Oval, Line };
var templateObject_1, templateObject_2, templateObject_3;
