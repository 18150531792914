var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenInfo, Icon } from 'base-components';
import { useModal } from 'utils';
import { MODALS } from 'consts';
import { isElectron } from 'utils/electron';
var BookingLocationUnavailable = function (_a) {
    var event = _a.event;
    var t = useTranslation().t;
    var openModal = useModal().openModal;
    var onClose = function () {
        if (isElectron()) {
            openModal(MODALS.USER_PROFILE);
        }
        else {
            openModal(MODALS.AGENDA_ITEM, {
                event: __assign(__assign({}, event), { locationUnavailableWasDisplayed: true }),
            });
        }
    };
    var title = t('components.bookingLocationUnavailable.title');
    var message = t('components.bookingLocationUnavailable.message');
    var pageTitle = t('documentTitles.bookingLocationUnavailable');
    var renderIcon = function () { return React.createElement(Icon, { name: "warning", size: 100 }); };
    return (React.createElement(FullScreenInfo, { title: title, message: message, pageTitle: pageTitle, onClose: onClose, iconButtonAriaLabelKey: "components.bookingLocationUnavailable.a11yWarningIconLabel", iconComponent: renderIcon() }));
};
export default memo(BookingLocationUnavailable);
