import { useEffect } from 'react';

/**
 * Attach given handler function on POP history action (browser back button press)
 * @param {function} handler
 * @returns {void}
 */
export function useOnNavigateBack(handler) {
  useEffect(() => {
    window.addEventListener('popstate', handler);

    return () => {
      window.removeEventListener('popstate', handler);
    };
  }, [handler]);
}
