import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppLocation } from 'router/hooks';
import { generateFloorPath, joinPaths } from 'router/utils';
import { PATH_SEGMENT, ROOT_PATH } from 'consts';
import { EmployeeContainer } from './EmployeeContainer';
export function Employee() {
    var search = useAppLocation().search;
    var renderPersonRedirect = function (person) {
        var id = person.id, primaryLocation = person.primaryLocation;
        var path = joinPaths(ROOT_PATH, PATH_SEGMENT.PEOPLE, "".concat(id));
        if (primaryLocation === null || primaryLocation === void 0 ? void 0 : primaryLocation.floorId) {
            path = joinPaths(generateFloorPath("".concat(primaryLocation === null || primaryLocation === void 0 ? void 0 : primaryLocation.floorId)), PATH_SEGMENT.PEOPLE, "".concat(id));
        }
        return (React.createElement(Navigate, { replace: true, to: { pathname: path, search: search }, state: { person: person } }));
    };
    return React.createElement(EmployeeContainer, { render: renderPersonRedirect });
}
