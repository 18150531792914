import React from 'react';
import { useGenerateCurrentLocationPath } from 'utils';
import { useAppLocation } from 'router/hooks';
import { PATH_SEGMENT } from 'consts';
import { joinPaths } from 'router/utils';
import { TitleFirstRow } from './styled';
export function Title(_a) {
    var children = _a.children;
    var pathname = useAppLocation().pathname;
    var currentLocationPath = useGenerateCurrentLocationPath();
    return (React.createElement(TitleFirstRow, { to: joinPaths(currentLocationPath, PATH_SEGMENT.LOCATION_SWITCHER), state: { fromPath: pathname } }, children));
}
