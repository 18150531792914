var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { useTheme } from 'styled-components';
import { addMinutes, getMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatLocalizedDate, formatLocalizedTime, roundToInterval, } from '@serraview/engage-shared';
import { RoundingDirection } from '@serraview/engage-shared/constants';
import { A11yHiddenLabel } from 'styles';
import { isIntervalBooked } from './helpers';
import { Interval } from './Interval';
import { Label } from './Label';
import { GroupWrapper } from './styled';
var hourBlockMarginHorizontal = 1;
var intervalMarginHorizontal = 0;
export var FixSizeAvailabilityBar = memo(function (_a) {
    var _b = _a.barSize, barSize = _b === void 0 ? 0 : _b, bookingIntervalSize = _a.bookingIntervalSize, start = _a.start, _c = _a.startAvailable, startAvailable = _c === void 0 ? new Date().getTime() : _c, _d = _a.hourWidth, hourWidth = _d === void 0 ? 56 : _d, _e = _a.bookedIntervals, bookedIntervals = _e === void 0 ? [] : _e, timeZone = _a.timeZone, others = __rest(_a, ["barSize", "bookingIntervalSize", "start", "startAvailable", "hourWidth", "bookedIntervals", "timeZone"]);
    // @ts-ignore
    var _f = useTheme(), color2 = _f.color2, typo2 = _f.typo2;
    var t = useTranslation().t;
    // display only 15 minutes intervals or larger
    var intervalSize = Math.max(15, bookingIntervalSize);
    var intervalWidth = (hourWidth * intervalSize) / 60;
    var intervalsPerHour = 60 / intervalSize;
    var startDate = roundToInterval(new Date(start), intervalSize, RoundingDirection.DOWN);
    var hasIncompleteHourInterval = startDate.getMinutes() !== 0;
    var hoursNumber = Math.floor(barSize / 60);
    if (hasIncompleteHourInterval) {
        hoursNumber--;
    }
    var startAvailableDate = roundToInterval(new Date(startAvailable), intervalSize, RoundingDirection.DOWN);
    var styledLabelWidth = intervalWidth * intervalsPerHour +
        intervalsPerHour * 2 * intervalMarginHorizontal;
    var labelStyle = {
        minWidth: styledLabelWidth,
        borderColor: color2,
        opacity: 0.7,
    };
    var intervalStyle = {
        width: intervalWidth,
    };
    // style for intervals with rounded corners
    var leftIntervalStyle = __assign({}, intervalStyle);
    var rightIntervalStyle = __assign({}, intervalStyle);
    var hourBlockWidth = hourWidth +
        2 * hourBlockMarginHorizontal +
        2 * intervalsPerHour * intervalMarginHorizontal;
    var hourBlockStyle = {
        width: hourBlockWidth,
    };
    var currentDateInterval = startDate;
    var hourSegments = [];
    var incompleteIntervalMinutes = 60 - getMinutes(startDate);
    var leftIncompleteIntervalsNumber = incompleteIntervalMinutes / intervalSize;
    // add the incomplete hour intervals from on the left side of the bar
    if (hasIncompleteHourInterval) {
        var incompleteHourBlockStyle = {
            width: leftIncompleteIntervalsNumber * intervalWidth +
                2 * hourBlockMarginHorizontal +
                2 * leftIncompleteIntervalsNumber * intervalMarginHorizontal,
        };
        var currentHourTime = currentDateInterval.getTime();
        var barButtons = [];
        for (var i = 0; i < leftIncompleteIntervalsNumber; i++) {
            var currentIntervalTime = currentDateInterval.getTime();
            var isBooked = isIntervalBooked(startAvailableDate, bookedIntervals, currentDateInterval);
            barButtons.push(React.createElement(Interval, { isBooked: isBooked, intervalStyle: i === 0 ? leftIntervalStyle : intervalStyle, key: "interval_".concat(currentIntervalTime) }));
            currentDateInterval = addMinutes(currentDateInterval, intervalSize);
        }
        hourSegments.push(React.createElement("div", { className: "fix-size-availability-bar__segment", style: __assign(__assign({}, hourBlockStyle), incompleteHourBlockStyle), key: "hour_".concat(currentHourTime) },
            React.createElement(Label, { color: typo2, style: __assign(__assign({}, labelStyle), incompleteHourBlockStyle) }),
            React.createElement(GroupWrapper, null, barButtons)));
    }
    var displayHour = function (date) {
        return formatLocalizedTime(date, { timeZone: timeZone, format: DateFormat.hourNumeric });
    };
    var barsPerHour = 60 / intervalSize;
    // add the complete hour intervals
    for (var i = 0; i < hoursNumber; i++) {
        var hourText = displayHour(currentDateInterval);
        var currentHourTime = currentDateInterval.getTime();
        var barButtons = [];
        for (var j = 0; j < barsPerHour; j++) {
            var currentIntervalTime = currentDateInterval.getTime();
            // add rounded corners for first and last interval
            var completeIntervalStyle = {};
            if (!hasIncompleteHourInterval && i === 0 && j === 0) {
                completeIntervalStyle = leftIntervalStyle;
            }
            else if (!hasIncompleteHourInterval &&
                i === hoursNumber - 1 &&
                j === barsPerHour - 1) {
                completeIntervalStyle = rightIntervalStyle;
            }
            var isBooked = isIntervalBooked(startAvailableDate, bookedIntervals, currentDateInterval);
            barButtons.push(React.createElement(Interval, { isBooked: isBooked, intervalStyle: __assign(__assign({}, intervalStyle), completeIntervalStyle), key: "interval_".concat(currentIntervalTime) }));
            currentDateInterval = addMinutes(currentDateInterval, intervalSize);
        }
        hourSegments.push(React.createElement("div", { className: "fix-size-availability-bar__segment", style: hourBlockStyle, key: "hour_".concat(currentHourTime), "data-testid": "labelButton" },
            React.createElement(Label, { color: typo2, style: labelStyle }, hourText),
            React.createElement(GroupWrapper, null, barButtons)));
    }
    // add the incomplete hour intervals from on the right side of the bar
    if (hasIncompleteHourInterval) {
        var rightIncompleteIntervalNumbers = intervalsPerHour - leftIncompleteIntervalsNumber;
        var incompleteHourBlockStyle = {
            width: rightIncompleteIntervalNumbers * intervalWidth +
                2 * hourBlockMarginHorizontal +
                2 * rightIncompleteIntervalNumbers * intervalMarginHorizontal,
        };
        var currentHourTime = currentDateInterval.getTime();
        var barButtons = [];
        for (var i = 0; i < rightIncompleteIntervalNumbers; i++) {
            var currentIntervalTime = currentDateInterval.getTime();
            var isBooked = isIntervalBooked(startAvailableDate, bookedIntervals, currentDateInterval);
            barButtons.push(React.createElement(Interval, { isBooked: isBooked, intervalStyle: i === rightIncompleteIntervalNumbers - 1
                    ? rightIntervalStyle
                    : intervalStyle, key: "interval_".concat(currentIntervalTime) }));
            currentDateInterval = addMinutes(currentDateInterval, intervalSize);
        }
        hourSegments.push(React.createElement("div", { className: "fix-size-availability-bar__segment", style: __assign(__assign({}, hourBlockStyle), incompleteHourBlockStyle), key: "hour_".concat(currentHourTime) },
            React.createElement(Label, { color: typo2, style: __assign(__assign({}, labelStyle), incompleteHourBlockStyle) }),
            React.createElement(GroupWrapper, null, barButtons)));
    }
    return (React.createElement(GroupWrapper, __assign({}, others, { "data-testid": "barContainer" }),
        hourSegments,
        React.createElement(A11yHiddenLabel, null, "".concat(t('accessibilityLabels.availableFrom'), " ").concat(formatLocalizedDate(startAvailableDate, {
            timeZone: timeZone,
        })))));
});
