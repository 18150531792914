import tenantSlice, { tenantSelectors } from 'store/tenant';
import filtersSlice from 'store/filters';

/**
 * Use to update filter dates timeZone when user location is changed
 */
const timeZoneMiddleware = (store) => (next) => (action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case tenantSlice.actions.setCurrentLocation.type: {
      const currentLocation = tenantSelectors.getCurrentLocation(
        store.getState(),
      );
      const prevTimeZone = currentLocation?.building?.timeZone;
      const newTimeZone = payload?.building?.timeZone;
      store.dispatch(
        filtersSlice.actions.updateTimezone({ prevTimeZone, newTimeZone }),
      );
      break;
    }
    default:
      return next(action);
  }
  return next(action);
};

export default timeZoneMiddleware;
