export const getUserConfigSelector = ({ key, state, defaultValue }) => {
  const tenantId = state.tenant?.tenantId;
  const userId = state.tenant?.userId;

  return state.userConfig?.[tenantId]?.[userId]?.[key] ?? defaultValue;
};

export const updateUserConfigState = ({ key, payload, state }) => {
  const { tenantId, userId, value } = payload;

  if (!tenantId || !userId) {
    return state;
  }

  const tenant = state[tenantId];

  if (!tenant) {
    state[tenantId] = {
      [userId]: {
        [key]: value,
      },
    };

    return state;
  }

  const user = tenant[userId];

  if (!user) {
    state[tenantId][userId] = {
      [key]: value,
    };

    return state;
  }

  const prevValue = user[key];

  if (typeof value === 'object') {
    state[tenantId][userId][key] = {
      ...prevValue,
      ...value,
    };
  } else {
    state[tenantId][userId][key] = value;
  }

  return state;
};
