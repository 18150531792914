var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Button } from '../Button';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #ffffff;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  background-color: #ffffff;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 255px;\n  padding-left: 30px;\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n  background: ", ";\n"], ["\n  min-height: 255px;\n  padding-left: 30px;\n  display: flex;\n  justify-content: space-around;\n  flex-direction: column;\n  background: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
export var CardWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 10px;\n"], ["\n  display: flex;\n  margin-top: 10px;\n"])));
export var CardTitle = styled.h2(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-bottom: 10px;\n  color: ", ";\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 22px;\n  font-style: normal;\n  margin-top: 0;\n  margin-bottom: 0;\n"], ["\n  padding-bottom: 10px;\n  color: ", ";\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 22px;\n  font-style: normal;\n  margin-top: 0;\n  margin-bottom: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
export var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 20px 30px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow-y: auto;\n"], ["\n  padding: 20px 30px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow-y: auto;\n"])));
export var TitleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var Section = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 10px;\n  margin-bottom: 15px;\n"], ["\n  margin-top: 10px;\n  margin-bottom: 15px;\n"])));
export var Description = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n  max-width: 350px;\n"], ["\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n  max-width: 350px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color2;
});
export var SectionTitle = styled.h2(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: 15px;\n  font-weight: bold;\n  font-size: 12px;\n  line-height: 16px;\n  color: ", ";\n  margin-top: 0;\n"], ["\n  margin-bottom: 15px;\n  font-weight: bold;\n  font-size: 12px;\n  line-height: 16px;\n  color: ", ";\n  margin-top: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var LineWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 22px;\n"], ["\n  display: flex;\n  margin-top: 22px;\n"])));
export var DeleteButton = styled(Button)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  .btn {\n    border: 2px solid ", ";\n    border-radius: 30px;\n    padding-top: 5px;\n    flex: 0 0 30px;\n  }\n"], ["\n  .btn {\n    border: 2px solid ", ";\n    border-radius: 30px;\n    padding-top: 5px;\n    flex: 0 0 30px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var StatusMessage = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 14px;\n  color: ", ";\n"], ["\n  font-weight: bold;\n  font-size: 14px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, isClosed = _a.isClosed;
    return (isClosed ? theme.color11 : theme.typo2);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
