import { css } from 'styled-components';

import EpturaRegular from './EpturaFlexible-Regular.woff2';
import EpturaRegularItalic from './EpturaFlexible-RegularItalic.woff2';
import EpturaBold from './EpturaFlexible-Bold.woff2';
import EpturaBoldItalic from './EpturaFlexible-BoldItalic.woff2';

export default css`
  @font-face {
    font-family: 'Eptura Flexible';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url(${EpturaRegular}) format('woff2');
  }
  @font-face {
    font-family: 'Eptura Flexible';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url(${EpturaBold}) format('woff2');
  }
  @font-face {
    font-family: 'Eptura Flexible';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: url(${EpturaRegularItalic}) format('woff2');
  }
  @font-face {
    font-family: 'Eptura Flexible';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: url(${EpturaBoldItalic}) format('woff2');
  }
`;
