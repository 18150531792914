var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useContext, useEffect, useMemo, useRef, } from 'react';
import { isDateInArray, useConfig, useReservationFilters } from 'utils';
import { getDayStartTime } from '@serraview/engage-shared/utils';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { filtersSelectors } from 'store';
import { DaysSelector as DaysSelectorComponent } from 'base-components';
import { getDisabledDays } from '../../reserveCardHelpers';
import { ReserveCardContext } from '../ReserveCardContext';
import { DaysSelectorWrapper } from '../../styled';
var noDays = [];
export var DaysSelector = function () {
    var _a = useContext(ReserveCardContext), spaceItem = _a.spaceItem, isTeam = _a.isTeam, timeZone = _a.timeZone, durationObj = _a.durationObj, setDurationObj = _a.setDurationObj, bookings = _a.bookings, setReserveDisabled = _a.setReserveDisabled;
    var selectedDays = durationObj.selectedDays;
    var _b = spaceItem || {}, spaceId = _b.id, isDesk = _b.isDesk, buildingId = _b.buildingId, showDaysSelector = _b.showDaysSelector;
    var reservationFilter = useReservationFilters(isDesk);
    var filterDateStart = reservationFilter.dateStart;
    // Days from Reservation assist
    var selectedDates = useSelector(filtersSelectors.getFilterDates).selectedDates;
    var config = useConfig({ buildingId: buildingId });
    var futureBookingsLimit = config.futureBookingsLimit;
    var disabledDays = useMemo(function () {
        return isTeam
            ? noDays
            : getDisabledDays({
                bookings: bookings,
                futureBookingsLimit: futureBookingsLimit,
                filterDateStart: filterDateStart,
                timeZone: timeZone,
            });
    }, [bookings, filterDateStart, futureBookingsLimit, isTeam, timeZone]);
    var daysToPreselect = selectedDates.filter(function (date) { return !isDateInArray(date, disabledDays); });
    var daysToPreselectString = JSON.stringify(daysToPreselect);
    var firstDay = useMemo(function () {
        return getDayStartTime({
            date: filterDateStart || new Date(),
            timeZone: timeZone,
        });
    }, [filterDateStart, timeZone]);
    var isFirstDayEnabled = !isDateInArray(firstDay, disabledDays);
    useEffect(function () {
        if (selectedDays.length === 0) {
            var initialSelectedDays_1 = daysToPreselect.length > 0 ? daysToPreselect : [];
            // only preselect first day if no days selected in Reservation assist or previously (state)
            if (isFirstDayEnabled && selectedDates.length === 0) {
                initialSelectedDays_1 = [firstDay];
            }
            setDurationObj(function (state) { return (__assign(__assign({}, state), { selectedDays: initialSelectedDays_1 })); });
        }
        // we need to use daysToPreselect which is an array and can not be properly checked by useEffect
        // that's why we use string version of this array as dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstDay, daysToPreselectString, isFirstDayEnabled, spaceId]);
    var selectedWeekdaysString = selectedDays
        .map(function (day) { return format(day, 'EEE'); })
        .join(',');
    // if user changed days -> enable reserve button
    // if no days selected -> disable reserve button
    useEffect(function () {
        if (showDaysSelector) {
            // means "no selected days"
            if (!selectedWeekdaysString) {
                setReserveDisabled(true);
            }
            setReserveDisabled(false);
        }
    }, [selectedWeekdaysString, setReserveDisabled, showDaysSelector]);
    var onDaySelected = useCallback(function (selectedDays) {
        return setDurationObj(function (state) { return (__assign(__assign({}, state), { selectedDays: selectedDays })); });
    }, [setDurationObj]);
    var currentDateRef = useRef(new Date());
    return (React.createElement(DaysSelectorWrapper, null,
        React.createElement(DaysSelectorComponent, { onDaySelected: onDaySelected, selectedDays: selectedDays, disabledDays: disabledDays, dateStart: filterDateStart || currentDateRef.current, timeZone: timeZone, isMultiDayDeskReservation: true })));
};
