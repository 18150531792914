import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRegions } from '@serraview/engage-shared/api';
import { tenantSelectors, floorplanActions } from 'store';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { LocationSelectorWrapper } from './components/styled';
import Region from './components/Region';
import RegionSkeleton from './components/RegionSkeleton';
import useLocationSelector from './components/useLocationSelector';
var LocationSelector = function () {
    var _a = useSelector(tenantSelectors.getCurrentLocation), building = _a.building, floor = _a.floor;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var _b = useRegions({
        tenantId: tenantId,
        shouldSortRegions: true,
    }), data = _b.data, isLoading = _b.isLoading;
    var _c = useLocationSelector(), locationState = _c[0], locationDispatch = _c[1];
    var hasLocation = !!(building && floor);
    var title = t('layout.locationSelector.title');
    var subtitle = t('layout.locationSelector.subtitle');
    var regions = data || [];
    useEffect(function () {
        if (hasLocation) {
            dispatch(floorplanActions.setShowOverlay(true));
        }
        return function () {
            dispatch(floorplanActions.setShowOverlay(false));
        };
    }, [dispatch, hasLocation]);
    if (isLoading) {
        return (React.createElement(LocationSelectorWrapper, null,
            React.createElement(RegionSkeleton, null),
            React.createElement(RegionSkeleton, null),
            React.createElement(RegionSkeleton, null),
            React.createElement(RegionSkeleton, null)));
    }
    return (React.createElement(React.Fragment, null,
        hasLocation && (React.createElement(DrawerHeader, { title: title, subtitle: subtitle, hasButton: hasLocation, closeIconA11yLabel: "accessibilityLabels.closeLayout_locationSelector", isCloseIcon: true })),
        React.createElement(DrawerCardContent, null,
            React.createElement(LocationSelectorWrapper, { "data-testid": "location-selector" }, regions.map(function (region) { return (React.createElement(Region, { key: region.id, region: region, locationState: locationState, locationDispatch: locationDispatch })); })))));
};
export default memo(LocationSelector);
