var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled, { useTheme } from 'styled-components';
import FloorplanScaleContext from 'components/floorplan/contexts/FloorplanScaleContext';
import RadarPing from 'components/floorplan/RadarPing/RadarPing';
import { DEFAULT_FLOORPLAN_SCALE } from 'components/floorplan/constants/Floorplan';
import React from 'react';
var DoubleCircle = styled.circle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fill: ", ";\n  stroke: white;\n  stroke-width: ", "px;\n  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.7));\n"], ["\n  fill: ", ";\n  stroke: white;\n  stroke-width: ", "px;\n  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.7));\n"])), function (_a) {
    var theme = _a.theme;
    return theme.lightBlue;
}, function (_a) {
    var r = _a.r;
    return r / 3;
});
var RadarPingStyles = {
    fillOpacity: 1,
    stroke: 'white',
    strokeWidth: '2',
};
export var FocusMarker = function (_a) {
    var _b = _a.coordinate, x = _b.x, y = _b.y;
    var blue1 = useTheme().blue1;
    var radius = 35;
    return (React.createElement(FloorplanScaleContext.Consumer, null, function (scale) { return (React.createElement(React.Fragment, null,
        React.createElement(RadarPing, { shape: { coordinates: [{ x: x, y: y }] }, pointSize: radius + 5, repeat: "infinite", baseStyle: __assign(__assign({}, RadarPingStyles), { fill: blue1 }) }),
        React.createElement(DoubleCircle, { id: "here-marker", cx: x, cy: y, r: Math.ceil((radius * DEFAULT_FLOORPLAN_SCALE) / scale) }))); }));
};
var templateObject_1;
