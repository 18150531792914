import { RESTRICT_TO_VALUES } from '@serraview/engage-shared';
import { useGenerateCurrentLocationPath, useRestrictTo } from 'utils/hooks';
import { joinPaths, removeSegmentFromPath } from 'router/utils';
import { useAppLocation, useAppParams } from 'router/hooks';
import { PATH_SEGMENT } from 'consts';
export var useReserveRouteData = function () {
    var restrictTo = useRestrictTo();
    var _a = useAppLocation(), state = _a.state, pathname = _a.pathname;
    var _b = useAppParams(), deskId = _b.deskId, spaceId = _b.spaceId, teamId = _b.teamId;
    var currentLocationPath = useGenerateCurrentLocationPath();
    var isDesk = restrictTo === RESTRICT_TO_VALUES.DESK;
    var isTeam = restrictTo === RESTRICT_TO_VALUES.TEAM;
    switch (restrictTo) {
        case RESTRICT_TO_VALUES.DESK:
            return {
                id: deskId,
                data: state === null || state === void 0 ? void 0 : state.desk,
                closeLinkPath: joinPaths(currentLocationPath, PATH_SEGMENT.DESKS),
                closeLinkA11yLabel: 'accessibilityLabels.closeLayout_deskReserve',
                isDesk: isDesk,
                isTeam: isTeam,
                currentLocationPath: currentLocationPath,
            };
        case RESTRICT_TO_VALUES.SPACE:
            return {
                id: spaceId,
                data: state === null || state === void 0 ? void 0 : state.space,
                closeLinkPath: joinPaths(currentLocationPath, PATH_SEGMENT.SPACES),
                closeLinkA11yLabel: 'accessibilityLabels.closeLayout_spaceReserve',
                isDesk: isDesk,
                isTeam: isTeam,
                currentLocationPath: currentLocationPath,
            };
        case RESTRICT_TO_VALUES.TEAM:
            return {
                id: teamId,
                data: state === null || state === void 0 ? void 0 : state.team,
                closeLinkPath: removeSegmentFromPath('reserve', pathname),
                closeLinkA11yLabel: 'accessibilityLabels.closeLayout_teamReserve',
                isDesk: isDesk,
                isTeam: isTeam,
                currentLocationPath: currentLocationPath,
            };
        default:
            return null;
    }
};
