var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { COMMON_STYLES, Z_INDEX } from 'consts';
import { Link } from 'react-router-dom';
import { Avatar } from 'components/base';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  z-index: ", "; // after modal\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background-color: ", ";\n  width: 100%;\n  padding: 10px ", "px;\n  // force overlapping floorplan\n  pointer-events: none;\n  min-height: 54px;\n  backdrop-filter: ", ";\n\n  > * {\n    // Ensures that all direct children are equal width,\n    // no matter how many you have or what the content is inside of them.\n    flex-basis: 100%;\n  }\n\n  @media (max-width: 450px) {\n    padding: 0 5px;\n  }\n\n  @media (min-width: 451px) and (max-width: 800px) {\n    padding: 0 20px;\n  }\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  z-index: ", "; // after modal\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  background-color: ", ";\n  width: 100%;\n  padding: 10px ", "px;\n  // force overlapping floorplan\n  pointer-events: none;\n  min-height: 54px;\n  backdrop-filter: ", ";\n\n  > * {\n    // Ensures that all direct children are equal width,\n    // no matter how many you have or what the content is inside of them.\n    flex-basis: 100%;\n  }\n\n  @media (max-width: 450px) {\n    padding: 0 5px;\n  }\n\n  @media (min-width: 451px) and (max-width: 800px) {\n    padding: 0 20px;\n  }\n"])), function (_a) {
    var isKiosk = _a.isKiosk;
    return isKiosk ? Z_INDEX.KIOSK_HEADER : Z_INDEX.HEADER;
}, function (_a) {
    var isKiosk = _a.isKiosk, isDialogOpen = _a.isDialogOpen;
    if (isKiosk) {
        return isDialogOpen
            ? 'rgba(255, 255, 255, 0.9)'
            : 'rgba(255, 255, 255, 0.3)';
    }
    return 'transparent';
}, COMMON_STYLES.SPACE, function (_a) {
    var isKiosk = _a.isKiosk;
    return (isKiosk ? 'blur(60px)' : 'none');
});
export var Left = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  pointer-events: auto;\n"], ["\n  display: flex;\n  align-items: center;\n  pointer-events: auto;\n"])));
export var Center = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var Right = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
export var TimeLinesWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  pointer-events: auto;\n  position: relative;\n"], ["\n  pointer-events: auto;\n  position: relative;\n"])));
export var IconWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return props.$style || {}; });
export var UserWrapper = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  pointer-events: auto;\n  cursor: pointer;\n  outline: none;\n  margin-left: 10px;\n\n  &.focus-visible {\n    .avatar__initials,\n    .avatar__image {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n    }\n  }\n"], ["\n  pointer-events: auto;\n  cursor: pointer;\n  outline: none;\n  margin-left: 10px;\n\n  &.focus-visible {\n    .avatar__initials,\n    .avatar__image {\n      outline: 1px auto Highlight;\n      outline: 1px auto -webkit-focus-ring-color;\n    }\n  }\n"])));
export var MenuButtonWrapper = styled(Link)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  background-color: ", ";\n  width: 42px;\n  height: 42px;\n  border-radius: 42px;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  background-color: ", ";\n  width: 42px;\n  height: 42px;\n  border-radius: 42px;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
});
export var UserAvatar = styled(Avatar)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border: 4px solid white;\n"], ["\n  border: 4px solid white;\n"])));
export var TeamBookingStartDateWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex-direction: row;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"], ["\n  flex-direction: row;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"])));
export var SecondLineWrapper = styled(Wrapper)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  top: 50px;\n  z-index: ", ";\n"], ["\n  top: 50px;\n  z-index: ", ";\n"])), Z_INDEX.MODAL - 1);
export var FloorplanViewsWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding-right: 6px;\n  padding-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  padding-right: 6px;\n  padding-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
