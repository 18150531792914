var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { noop, replaceLineFeedWithSpace } from 'utils';
import { fontClassesHeights, fontTagHeights } from 'theme';
import SizeRecognitionWrapper from 'components/layouts/GlobalSearch/SizeRecognitionWrapper';
import { useTranslation } from 'react-i18next';
import AvatarImage from 'components/base/Avatar/AvatarImage';
import { LIST_ITEM_PADDING_VERTICAL, ListItemOuterWrapper, ListItemWrapper, TextWrapper, Title, SubtitleWrapper, } from '../styled';
import PersonDescription from './PersonDescription/PersonDescription';
var PersonItem = function (_a) {
    var style = _a.style, item = _a.item, _b = _a.onClick, onClick = _b === void 0 ? noop : _b, _c = _a.onSizeFound, onSizeFound = _c === void 0 ? noop : _c, index = _a.index, as = _a.as, shouldShowGuest = _a.shouldShowGuest, renderAvatar = _a.renderAvatar;
    var t = useTranslation().t;
    var name = item.name, imageUrl = item.imageUrl, initials = item.initials;
    var displayName = shouldShowGuest
        ? t('layout.reserve.guest')
        : replaceLineFeedWithSpace(name);
    var avatarProps = {
        initials: initials,
        name: name,
        source: shouldShowGuest ? undefined : imageUrl,
        icon: shouldShowGuest ? 'personNew' : null,
        size: 'small',
        style: { background: 'transparent' },
    };
    return (React.createElement(ListItemOuterWrapper, __assign({}, (!!as && { as: as }), { "$style": style }),
        React.createElement(SizeRecognitionWrapper, { onSizeFound: onSizeFound, index: index },
            React.createElement(ListItemWrapper, { "aria-label": "".concat(t('accessibilityLabels.viewPersonItem'), " ").concat(displayName), onClick: function () { return onClick(item); } },
                renderAvatar ? (renderAvatar(avatarProps)) : (React.createElement(AvatarImage, __assign({}, avatarProps))),
                React.createElement(TextWrapper, null,
                    React.createElement(Title, null, displayName),
                    React.createElement(SubtitleWrapper, null,
                        React.createElement(PersonDescription, { item: item })))))));
};
PersonItem.getSize = function () {
    return LIST_ITEM_PADDING_VERTICAL * 2 +
        // @ts-ignore
        fontTagHeights.h6.withMargins +
        // @ts-ignore
        fontClassesHeights.btn.withMargins;
};
export default PersonItem;
