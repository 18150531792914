var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '@serraview/engage-shared/utils';
import { tenantActions, tenantSelectors } from 'store';
import { useQuery, useQueryClient } from 'react-query';
import { USER_CACHE_TIME, USER_QUERY_KEY, USER_STALE_TIME } from 'consts';
export var useUser = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.enabled, enabled = _c === void 0 ? true : _c;
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var userId = useSelector(tenantSelectors.getUserId);
    return useQuery(userId, function () { return getUserData({ tenantId: tenantId, userId: userId }); }, {
        enabled: enabled && !!tenantId && !!userId,
        cacheTime: USER_CACHE_TIME,
        staleTime: USER_STALE_TIME,
    });
};
export var useFetchUser = function () {
    var queryClient = useQueryClient();
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var query = function (_a) {
        var userId = _a.userId;
        return queryClient.fetchQuery([USER_QUERY_KEY, "".concat(userId)], function () { return getUserData({ tenantId: tenantId, userId: userId }); }, { cacheTime: USER_CACHE_TIME, staleTime: USER_STALE_TIME });
    };
    var fetchUserQuery = useCallback(query, [queryClient, tenantId]);
    return { fetchUserQuery: fetchUserQuery };
};
export var useUserLocation = function () {
    var dispatch = useDispatch();
    var fetchUserQuery = useFetchUser().fetchUserQuery;
    var setUserLocation = React.useCallback(function (_a) {
        var userId = _a.userId;
        return __awaiter(void 0, void 0, void 0, function () {
            var userData, userLocation;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetchUserQuery({ userId: userId })];
                    case 1:
                        userData = _b.sent();
                        userLocation = userData.userLocation;
                        if (userLocation) {
                            dispatch(tenantActions.setCurrentLocation(userLocation));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }, [dispatch, fetchUserQuery]);
    return { setUserLocation: setUserLocation };
};
