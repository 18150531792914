import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import { useSelectedStyles } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { languages, LanguageTypes } from 'consts';
import PropTypes from 'prop-types';
import { useSetAppLanguage } from 'utils/hooks/useSetAppLanguage';
import { Select } from 'base-components';
import { LanguageSelectWrapper } from 'layouts/LanguageSelect/styled';

const LanguageSelect = ({ configurationLanguages }) => {
  const { t } = useTranslation();
  const styles = useSelectedStyles();
  const { i18n } = useTranslation();
  const currentLanguage = useSelector(userConfigSelectors.getUiLanguage);
  const setAppLanguage = useSetAppLanguage(i18n);

  const languageLabel = t('layout.configuration.languageLabel');

  // it extends container styles
  const selectContainerStyles = styles.container;

  styles.container = (base) => ({
    ...selectContainerStyles(base),
    minWidth: '200px',
    padding: 0,
  });

  const isLanguageIncluded = configurationLanguages.some(
    (language) => language.value === currentLanguage,
  );

  const changeLanguage = useCallback(
    ({ value }) => setAppLanguage(value),
    [setAppLanguage],
  );

  // When language is picked from configuration and removed in tenant config. Use fallback language english US
  useEffect(() => {
    if (!isLanguageIncluded) {
      changeLanguage({ value: LanguageTypes.enUS });
    }
  }, [changeLanguage, isLanguageIncluded]);

  const selectedLanguage = isLanguageIncluded
    ? {
        value: currentLanguage,
        label: languages[currentLanguage],
      }
    : { value: LanguageTypes.enUS, label: languages[LanguageTypes.enUS] };

  return (
    <LanguageSelectWrapper>
      <Select
        options={configurationLanguages}
        styles={styles}
        isSearchable={false}
        defaultValue={selectedLanguage}
        onChange={changeLanguage}
        focusLabel={languageLabel}
        id="language-selector"
      />
    </LanguageSelectWrapper>
  );
};

LanguageSelect.propTypes = {
  configurationLanguages: PropTypes.array,
};

LanguageSelect.defaultProps = {
  configurationLanguages: [LanguageTypes.enUS],
};

export default LanguageSelect;
