import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSpaceAttributesQuery, } from '@serraview/engage-shared';
import { useAppLocation, useAppParams } from 'router/hooks';
import { removeSegmentFromPath } from 'router/utils';
import { A11yHiddenLabel } from 'theme';
import { userConfigSelectors } from 'store';
import { PATH_SEGMENT } from 'consts';
import { FocusId, useFocus } from 'utils';
import { EmptyCard, Text } from 'base-components';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { SpaceAttributesSkeleton } from './SpaceAttributesSkeleton';
import { ContentWrapper, ItemWrapper, TextWrapper, } from './SpaceAttributes.style';
var SpaceAttributes = function () {
    var t = useTranslation().t;
    var spaceIdFromParams = useAppParams().spaceId;
    var _a = useAppLocation(), state = _a.state, pathname = _a.pathname;
    var spaceFromState = state === null || state === void 0 ? void 0 : state.space;
    var spaceAttributesFromState = state === null || state === void 0 ? void 0 : state.spaceAttributes;
    var spaceId = spaceIdFromParams !== null && spaceIdFromParams !== void 0 ? spaceIdFromParams : spaceFromState === null || spaceFromState === void 0 ? void 0 : spaceFromState.id;
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var a11yTeamMembersList = t('layout.spaceAttributes.a11ySpaceAttributesList');
    var title = t('layout.drawer.menu.equipment');
    var subtitle = "".concat(spaceId);
    useFocus({
        returnFocusTo: FocusId.SpaceAttributes,
        focusOn: FocusId.DrawerCloseButton,
    });
    var _b = useSpaceAttributesQuery({
        spaceId: Number(spaceId),
        locale: locale,
    }), isLoading = _b.isLoading, isError = _b.isError, data = _b.data;
    var spaceAttributes = (data !== null && data !== void 0 ? data : spaceAttributesFromState);
    var renderHeader = function () {
        var closeLinkPath = removeSegmentFromPath(PATH_SEGMENT.SPACE_ATTRIBUTES, pathname);
        return (React.createElement(DrawerHeader, { title: title, subtitle: subtitle, isCloseIcon: true, closeLinkPath: closeLinkPath, closeIconA11yLabel: "accessibilityLabels.closeLayout_equipmentList" }));
    };
    var renderContent = function () {
        if (isLoading) {
            return (React.createElement(ContentWrapper, { "data-testid": "loading-skeleton" },
                React.createElement(SpaceAttributesSkeleton, null)));
        }
        if (isError) {
            return React.createElement(EmptyCard, { iconName: "info" }, t('common.networkError'));
        }
        return (React.createElement(DrawerCardContent, null,
            React.createElement(ContentWrapper, { "data-testid": "locations" }, spaceAttributes.map(function (_a) {
                var id = _a.id, name = _a.name;
                return (React.createElement(ItemWrapper, { key: id },
                    React.createElement(TextWrapper, null,
                        React.createElement(Text, null, name))));
            }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(A11yHiddenLabel, null, a11yTeamMembersList),
        renderHeader(),
        renderContent()));
};
export default memo(SpaceAttributes);
