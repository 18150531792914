import React from 'react';
import { useTheme } from 'styled-components';
import { ClientLogo } from 'components/base';
import { isElectron } from 'utils/electron';
import { KioskCurrentDateTime } from 'components/kiosk';
import { HeaderWrapper } from './LightHeader.style';
var LightHeader = function (_a) {
    var _b = _a.useLogoColors, useLogoColors = _b === void 0 ? false : _b;
    var typo1 = useTheme().typo1;
    // enable kiosk mode here
    var isKiosk = isElectron();
    return (React.createElement(HeaderWrapper, null,
        React.createElement(ClientLogo, { textColor: useLogoColors ? undefined : typo1 }),
        isKiosk && React.createElement(KioskCurrentDateTime, { textColor: typo1 })));
};
export default LightHeader;
