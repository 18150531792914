var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Z_INDEX } from 'consts';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  color: ", ";\n  flex-direction: column;\n  align-items: center;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n  z-index: ", ";\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 36px;\n  box-shadow: 0px 40px 60px 0px #00000026;\n  max-width: 440px;\n"], ["\n  display: flex;\n  color: ", ";\n  flex-direction: column;\n  align-items: center;\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate3d(-50%, -50%, 0);\n  z-index: ", ";\n  background-color: #fff;\n  border-radius: 8px;\n  padding: 36px;\n  box-shadow: 0px 40px 60px 0px #00000026;\n  max-width: 440px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, Z_INDEX.DIALOG);
export var Title = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 36px;\n  text-align: center;\n"], ["\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 36px;\n  text-align: center;\n"])));
export var CommonMessage = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", "px;\n  text-align: center;\n"], ["\n  font-size: ", "px;\n  text-align: center;\n"])), function (_a) {
    var _b = _a.fontSize, fontSize = _b === void 0 ? 18 : _b;
    return fontSize;
});
export var PrimaryButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-flex;\n  width: 176px;\n  height: 60px;\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  text-decoration: none;\n  font-size: 18px;\n  font-weight: 700;\n  justify-content: center;\n  cursor: pointer;\n  padding: 18px 18px 18px 17px;\n  gap: 10px;\n  box-shadow: 0px 20px 20px rgba(84, 96, 111, 0.2);\n  border-radius: 200px;\n\n  &:active {\n    opacity: 0.85;\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: inline-flex;\n  width: 176px;\n  height: 60px;\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  text-decoration: none;\n  font-size: 18px;\n  font-weight: 700;\n  justify-content: center;\n  cursor: pointer;\n  padding: 18px 18px 18px 17px;\n  gap: 10px;\n  box-shadow: 0px 20px 20px rgba(84, 96, 111, 0.2);\n  border-radius: 200px;\n\n  &:active {\n    opacity: 0.85;\n  }\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
}, function (_a) {
    var theme = _a.theme;
    return theme.typo1;
});
export var SecondaryButton = styled(PrimaryButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: transparent;\n  color: ", ";\n  gap: 10px;\n  border-radius: 200px;\n  box-shadow: none;\n"], ["\n  background-color: transparent;\n  color: ", ";\n  gap: 10px;\n  border-radius: 200px;\n  box-shadow: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var ButtonsRow = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
