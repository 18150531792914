var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getShouldShowHealthDeclarationOnBooking } from '@serraview/engage-shared/utils';
import { useContext, useEffect } from 'react';
import { useBookingMutation, useHourlyTeamBookingMutation, useMultiDayBookingMutation, useMultiDayTeamBookingMutation, } from 'api/mutations';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useConfig, useModal } from 'utils';
import { filtersSelectors, inAppNotificationsActions, teamReservationsSelectors, } from 'store';
import { MODALS } from 'consts';
import useReservationFilters from 'utils/hooks/useReservationFilters';
import { getFailedDaysFromSelected } from '../reserveCardHelpers';
import { useTeamReserveCallbacks } from './useTeamReserveCallbacks';
import { useReserveCallbacks } from './useReserveCallbacks';
import { ReserveCardContext } from '../ReserveCard/ReserveCardContext';
export var useMakeReservation = function () {
    var _a = useContext(ReserveCardContext), item = _a.item, spaceItem = _a.spaceItem, isTeam = _a.isTeam, durationObj = _a.durationObj, setDurationObj = _a.setDurationObj, timeZone = _a.timeZone, user = _a.user, reservee = _a.reservee, setReserveDisabled = _a.setReserveDisabled;
    var isDesk = spaceItem.isDesk;
    var duration = durationObj.duration, allDayDuration = durationObj.allDayDuration, selectedDays = durationObj.selectedDays;
    // use reservee field if they reserve a desk for a different employee
    var reserveeId = reservee && (user === null || user === void 0 ? void 0 : user.id) !== spaceItem.id ? reservee.id : null;
    // Update reservation feature is currently disabled
    var isUpdate = false;
    var reservationFilter = useReservationFilters(isDesk);
    var bookingIntervalSize = reservationFilter.bookingIntervalSize, enforceAllDayDeskReservations = reservationFilter.enforceAllDayDeskReservations;
    var config = useConfig({ buildingId: spaceItem === null || spaceItem === void 0 ? void 0 : spaceItem.buildingId });
    var enableHealthDeclaration = config.enableHealthDeclaration;
    var teamReservationSelectedDesks = useSelector(teamReservationsSelectors.getTeamReservationSelectedDesks);
    var teamSelectedMembersIds = useSelector(teamReservationsSelectors.getTeamSelectedMembersIds);
    // Days from Reservation assist
    var _b = useSelector(filtersSelectors.getFilterDates), dateStart = _b.dateStart, dateEnd = _b.dateEnd, allDay = _b.allDay;
    var isMultiDayDeskReservation = isDesk && enforceAllDayDeskReservations && !isUpdate;
    var isMultiDayTeamReservation = isTeam && enforceAllDayDeskReservations;
    var t = useTranslation().t;
    var reduxDispatch = useDispatch();
    var openModal = useModal().openModal;
    var _c = useReserveCallbacks({ spaceItem: spaceItem }), onSuccessBooking = _c.onSuccessBooking, onErrorBooking = _c.onErrorBooking, onSuccessMultiDayBooking = _c.onSuccessMultiDayBooking;
    var _d = useTeamReserveCallbacks({ spaceItem: spaceItem }), onSuccessHourlyTeamBooking = _d.onSuccessHourlyTeamBooking, onErrorTeamBooking = _d.onErrorTeamBooking, onSuccessMultiDayTeamBooking = _d.onSuccessMultiDayTeamBooking;
    var bookingMutation = useBookingMutation({
        spaceItem: spaceItem,
        isDesk: isDesk,
        user: user,
        filter: reservationFilter,
        reserveeId: reserveeId,
        duration: duration,
        allDayDuration: allDayDuration,
        isUpdate: isUpdate,
        onSuccess: onSuccessBooking,
        onError: onErrorBooking,
    });
    var genericOnErrorHandler = function (error) {
        return reduxDispatch(inAppNotificationsActions.addErrorNotification({
            message: t("layout.reserve.desk.".concat(error), '', {
                defaultValue: error, // in case we do not have translation for error just display in as it is
            }),
        }));
    };
    var multiDayBookingMutation = useMultiDayBookingMutation({
        spaceItem: spaceItem,
        isDesk: isDesk,
        user: user,
        filter: reservationFilter,
        reserveeId: reserveeId,
        duration: duration,
        allDayDuration: allDayDuration,
        isUpdate: isUpdate,
        selectedDays: selectedDays,
        onSuccess: function (_a) {
            var successfullyReserved = _a.successfullyReserved, successfullyReservedDaysMessage = _a.successfullyReservedDaysMessage, failedDaysMessage = _a.failedDaysMessage, concurrentDaysMessage = _a.concurrentDaysMessage, limitPerIntervalMessage = _a.limitPerIntervalMessage, limitPerBuildingMessage = _a.limitPerBuildingMessage, bookedDays = _a.bookedDays;
            // display success UI notification if at least one successfully reservation was made
            // and display error message with the list of days which couldn't be reserved
            if (failedDaysMessage || concurrentDaysMessage) {
                setReserveDisabled(true);
            }
            var newSelectedDays = failedDaysMessage
                ? getFailedDaysFromSelected(selectedDays, failedDaysMessage)
                : [];
            setDurationObj(function (state) { return (__assign(__assign({}, state), { selectedDays: newSelectedDays })); });
            onSuccessMultiDayBooking({
                successfullyReserved: successfullyReserved,
                successfullyReservedDaysMessage: successfullyReservedDaysMessage,
                failedDaysMessage: failedDaysMessage,
                concurrentDaysMessage: concurrentDaysMessage,
                limitPerIntervalMessage: limitPerIntervalMessage,
                limitPerBuildingMessage: limitPerBuildingMessage,
                bookedDays: bookedDays,
            });
        },
        onError: genericOnErrorHandler,
    });
    var teamBookingSummary = t('layout.reserve.team.teamBooking');
    var hourlyTeamBookingMutation = useHourlyTeamBookingMutation({
        teamId: (item === null || item === void 0 ? void 0 : item.favPeopleTeam) ? null : item === null || item === void 0 ? void 0 : item.id,
        summary: teamBookingSummary,
        filter: reservationFilter,
        duration: duration,
        allDayDuration: allDayDuration,
        timeZone: timeZone,
        desks: teamReservationSelectedDesks,
        attendees: teamSelectedMembersIds,
        onSuccess: onSuccessHourlyTeamBooking,
        onError: onErrorTeamBooking,
    });
    var multiDayTeamBookingMutation = useMultiDayTeamBookingMutation({
        teamId: (item === null || item === void 0 ? void 0 : item.favPeopleTeam) ? null : item === null || item === void 0 ? void 0 : item.id,
        summary: teamBookingSummary,
        timeZone: timeZone,
        desks: teamReservationSelectedDesks,
        attendees: teamSelectedMembersIds,
        filter: reservationFilter,
        selectedDays: selectedDays,
        onSuccess: onSuccessMultiDayTeamBooking,
        onError: genericOnErrorHandler,
    });
    var spaceBookingMutation = isMultiDayDeskReservation
        ? multiDayBookingMutation
        : bookingMutation;
    var teamBookingMutation = isMultiDayTeamReservation
        ? multiDayTeamBookingMutation
        : hourlyTeamBookingMutation;
    var _e = isTeam
        ? teamBookingMutation
        : spaceBookingMutation, onBooking = _e.onBooking, isLoading = _e.isLoading, isError = _e.isError;
    var makeReservation = function () {
        var allDayValue = allDay || (isDesk && enforceAllDayDeskReservations) || allDayDuration;
        var shouldShowHealthDeclaration = !isTeam &&
            enableHealthDeclaration &&
            getShouldShowHealthDeclarationOnBooking({
                tenantConfig: config,
                isMultiDay: isMultiDayDeskReservation,
                dateStart: dateStart,
                dateEnd: dateEnd,
                allDay: allDayValue,
                duration: duration,
                bookingIntervalSize: bookingIntervalSize,
                selectedDays: selectedDays,
                showDaysSelector: spaceItem.showDaysSelector,
                showDurationSelection: spaceItem.showDurationSelection,
                timeZone: timeZone,
            });
        if (shouldShowHealthDeclaration) {
            openModal(MODALS.HEALTH_DECLARATION, { onConfirm: onBooking });
        }
        else {
            onBooking();
        }
    };
    useEffect(function () {
        if (isError) {
            setReserveDisabled(true);
        }
    }, [isError, setReserveDisabled]);
    return { makeReservation: makeReservation, isLoading: isLoading };
};
