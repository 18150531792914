var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { LIST_ITEM_PADDING_VERTICAL } from 'components/layouts/GlobalSearch/styled';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  padding: ", "px 5px;\n  border-bottom: 1px solid ", ";\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  direction: ltr;\n"], ["\n  display: flex;\n  flex-direction: row;\n  padding: ", "px 5px;\n  border-bottom: 1px solid ", ";\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  direction: ltr;\n"])), LIST_ITEM_PADDING_VERTICAL, function (_a) {
    var theme = _a.theme;
    return theme.color5;
});
export var BadgeWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 10px;\n  border: 2px solid ", ";\n  width: 16px;\n  height: 16px;\n  padding: 4px;\n  background-color: ", ";\n  color: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 10px;\n  border: 2px solid ", ";\n  width: 16px;\n  height: 16px;\n  padding: 4px;\n  background-color: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color6;
}, function (props) { return props.iconBackgroundColor; }, function (props) { return props.iconColor; });
export var OrganizerBadgeWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px;\n  width: 16px;\n  height: 16px;\n  color: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px;\n  width: 16px;\n  height: 16px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
export var DetailsWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-left: 20px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-left: 20px;\n"])));
export var Title = styled.h6(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color1;
});
export var SubtitleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 270px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  color: ", ";\n  opacity: 0.8;\n"], ["\n  width: 270px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  color: ", ";\n  opacity: 0.8;\n"])), function (props) { return props.theme.typo2; });
export var Subtitle = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return props.theme.typo2; });
export var IconWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-left: auto;\n  padding-right: 10px;\n  color: ", ";\n"], ["\n  margin-left: auto;\n  padding-right: 10px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color3;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
