import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSpaceMenu } from 'utils';
import { EmptyCard } from 'base-components';
import useSpaceCheck from 'components/scenes/drawer/hooks/useSpaceCheck';
import DrawerHeader from 'components/scenes/drawer/header/DrawerHeader/DrawerHeader';
import { DrawerCardContent } from 'components/scenes/drawer/DrawerCardContent';
import { ReserveCard } from './Card/ReserveCard/ReserveCard';
import ReserveCardSkeleton from './Card/ReserveCardSkeleton';
import { useReserveQueries } from './hooks/useReserveQueries';
import { useReserveRouteData } from './hooks/useReserveRouteData';
export function ReserveSpaceContainer() {
    var t = useTranslation().t;
    var reserveRouteData = useReserveRouteData();
    var _a = reserveRouteData !== null && reserveRouteData !== void 0 ? reserveRouteData : {}, id = _a.id, isDesk = _a.isDesk, closeLinkPath = _a.closeLinkPath, stateData = _a.data;
    var _b = useReserveQueries(id), isLoading = _b.isLoading, spaceItem = _b.spaceItem, userData = _b.userData, bookingsData = _b.bookingsData, spaceAttributes = _b.spaceAttributes;
    var menu = useSpaceMenu({
        spaceId: id,
        isDesk: isDesk,
        isFavourite: spaceItem === null || spaceItem === void 0 ? void 0 : spaceItem.isFavorite,
        spaceAttributes: spaceAttributes,
    });
    var reservee = (stateData !== null && stateData !== void 0 ? stateData : {}).reservee;
    useSpaceCheck({ id: id, isDesk: isDesk });
    if (isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, isCloseIcon: true }),
            React.createElement(ReserveCardSkeleton, null)));
    }
    // we still want to show Reserve button as part of ReserveCard even when space is not bookable
    // requirement for this change can be found inside P2-1760 description
    if (!reserveRouteData || !userData || !spaceItem) {
        return (React.createElement(React.Fragment, null,
            React.createElement(DrawerHeader, { closeLinkPath: closeLinkPath, isCloseIcon: true }),
            React.createElement(EmptyCard, { iconName: "warning" }, t('common.networkError'))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerHeader, { menu: menu, closeLinkPath: closeLinkPath, isCloseIcon: true, closeIconA11yLabel: isDesk
                ? 'accessibilityLabels.closeLayout_deskReserve'
                : 'accessibilityLabels.closeLayout_spaceReserve' }),
        React.createElement(DrawerCardContent, null,
            React.createElement(ReserveCard, { spaceItem: spaceItem, reservee: reservee, user: userData, bookings: bookingsData }))));
}
