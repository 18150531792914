import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color6};
`;

const PersonFilterWrapper = styled.div`
  flex: 1;
  padding: 0 30px;
`;

const TitleWrapper = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export { Wrapper, PersonFilterWrapper, TitleWrapper };
