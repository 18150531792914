import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DateButton from 'base-components/DateTime/DateButton/DateButton';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useLocalTimeFormatH12 } from 'utils';
import useDeepLinkDateTime from 'router/hooks/useDeepLinkDateTime';
import { TimeLinesWrapper } from './styled';

function TimelineSelector({
  onDateClick,
  onDateClear,
  timeZone,
  selectedDate,
  disableInteractions,
  isDatePickerOpened,
}) {
  const isFloorSelected = useSelector(tenantSelectors.getCurrentFloorId);
  const isTimeFormatH12 = useLocalTimeFormatH12();

  useDeepLinkDateTime();

  if (!isFloorSelected) {
    return null;
  }

  return (
    <TimeLinesWrapper data-testid="timelines-wrapper">
      <DateButton
        disableInteractions={disableInteractions}
        isTimeFormatH12={isTimeFormatH12}
        selectedDate={selectedDate}
        isOpen={isDatePickerOpened}
        onClick={onDateClick}
        timeZone={timeZone}
        onDateClear={onDateClear}
      />
    </TimeLinesWrapper>
  );
}

TimelineSelector.propTypes = {
  onDateClear: PropTypes.func.isRequired,
  onDateClick: PropTypes.func.isRequired,
  disableInteractions: PropTypes.bool.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  timeZone: PropTypes.string.isRequired,
  isDatePickerOpened: PropTypes.bool.isRequired,
};

export default memo(TimelineSelector);
