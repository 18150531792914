import { userConfigActions, floorplanActions } from 'store';
import { VISIBILITY } from 'consts';

const handleSocialDistancing = ({
  dispatch,
  spaceItem,
  showSocialDistancingInfo,
}) => {
  dispatch(floorplanActions.setReservedSpace(spaceItem));

  if (!showSocialDistancingInfo) {
    dispatch(userConfigActions.setShowSocialDistancingInfo(VISIBILITY.SHOW));
  }
};

export { handleSocialDistancing };
