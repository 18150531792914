import React from 'react';
import PropTypes from 'prop-types';
import { getActionFromEngageUrl } from '@serraview/engage-shared';

import Analytics from 'analytics';
import { CoverVideo } from 'base-components';
import { useFetchImageURL } from 'api/queries/useFetchImageURL';
import {
  CONCIERGE_MENU_IMAGE_KEY,
  menuItemPropType,
} from 'scenes/ConciergeMenu/constants';
import {
  ConciergeItem,
  ConciergeItemDescription,
  ConciergeItemText,
  ConciergeItemTitle,
  ConciergeMenuAction,
} from 'scenes/ConciergeMenu/styled';

export const ConciergeMenuItem = ({
  isActive,
  menuItem: { title, description, onMenuItemClick, backgroundImage, url },
}) => {
  const { data: imageFileUrl } = useFetchImageURL({
    url: backgroundImage?.path,
    queryKey: [CONCIERGE_MENU_IMAGE_KEY, backgroundImage?.id],
  });

  const hasAction = isActive && !!onMenuItemClick;

  const handleItemClick = () => {
    Analytics.track('EVT-014', {
      title,
      menuItemUrl: url,
      menuItemAction: getActionFromEngageUrl(url).action,
    });

    onMenuItemClick();
  };

  return (
    <ConciergeItem
      data-testid="concierge-menu-item-container"
      backgroundImage={imageFileUrl}
    >
      {isActive && <CoverVideo coverImage={imageFileUrl} />}
      <ConciergeItemText>
        <ConciergeItemTitle data-testid="concierge-menu-item-title">
          {title}
        </ConciergeItemTitle>
        <ConciergeItemDescription data-testid="concierge-menu-item-description">
          {description}
        </ConciergeItemDescription>
      </ConciergeItemText>
      {hasAction && (
        <ConciergeMenuAction
          data-testid="concierge-menu-item-button"
          onClick={handleItemClick}
        />
      )}
    </ConciergeItem>
  );
};

ConciergeMenuItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  menuItem: menuItemPropType.isRequired,
};
