import React from 'react';
import { useQueryClient } from 'react-query';
import {
  useBookingsQuery,
  BOOKINGS_QUERY_KEY,
} from '@serraview/engage-shared/api';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useCurrentTimeZone, useReservationFilters } from 'utils/hooks';

const useBookings = ({ spaceItem = {}, options = {} }) => {
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const userId = useSelector(tenantSelectors.getUserId);
  const { id, isDesk, bookable: isSpaceItemBookable } = spaceItem;
  const timeZone = useCurrentTimeZone();
  const reservationFilters = useReservationFilters(isDesk);

  return useBookingsQuery({
    tenantId,
    spaceItem,
    timeZone,
    reservationFilters,
    options: {
      ...options,
      enabled:
        !!tenantId && !!userId && !!id && (isSpaceItemBookable || isDesk),
    },
  });
};

const useRefreshBookings = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = ({ spaceItem }) =>
    queryClient.invalidateQueries([BOOKINGS_QUERY_KEY, spaceItem.id]);
  const refresh = React.useCallback(invalidateQuery, [queryClient]);
  return { refresh };
};

export { useBookings, useRefreshBookings, BOOKINGS_QUERY_KEY };
