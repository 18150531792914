import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Space, Shape as ShapeType } from 'floorplan/types';
import FloorplanScaleContext from 'floorplan/contexts/FloorplanScaleContext';
import { doubleClickTime } from 'floorplan/constants/OpenSeadragon';
import { DEFAULT_FLOORPLAN_SCALE } from 'floorplan/constants/Floorplan';

import { getPathString, getCoordinateString } from 'floorplan/utils/svgUtils';

const calculateStyle = (options) => {
  const { isPoint, style, styleFunction, space, shape } = options;

  const colorStyle = {
    fill: null,
    stroke: null,
  };
  const requiredStyle = {
    pointerEvents: 'visible',
    strokeWidth: '7px',
    cursor: 'pointer',
  };
  const functionStyle = styleFunction ? styleFunction(space, shape) : {};
  const inlineStyle = style;

  if (!isPoint) {
    requiredStyle.fillRule = shape.holes ? 'evenodd' : null;
  }

  return {
    ...colorStyle,
    ...requiredStyle,
    ...functionStyle,
    ...inlineStyle,
  };
};

const Shape = (props) => {
  const { space, shape, onShapeClicked, pointSize, isSelected } = props;

  const waitIfDoubleTimeoutRef = useRef(-1);

  const style = calculateStyle(props);
  const selectedString = isSelected ? '-selected' : '';

  const shapeEvents = {
    onClick: (event) => {
      if (waitIfDoubleTimeoutRef.current !== -1) {
        clearTimeout(waitIfDoubleTimeoutRef.current);
        waitIfDoubleTimeoutRef.current = -1;
        return;
      }

      if (event.persist) event.persist();

      waitIfDoubleTimeoutRef.current = setTimeout(() => {
        const eventInfo = { originalEvent: event, space, shape };
        onShapeClicked(eventInfo);
        waitIfDoubleTimeoutRef.current = -1;
      }, doubleClickTime);
    },
  };

  if (shape.holes) {
    return (
      <path
        id={`space-${space.id}-holes`}
        d={`${getPathString(shape.coordinates)} ${shape.holes
          .map(getPathString)
          .join(' ')} z`}
        style={style}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...shapeEvents}
      />
    );
  }

  if (shape.isPolygon) {
    return (
      <polygon
        id={`space-${space.id}-room${selectedString}`}
        points={getCoordinateString(shape)}
        style={style}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...shapeEvents}
      />
    );
  }

  return (
    <FloorplanScaleContext.Consumer>
      {(value) => (
        <circle
          id={`space-${space.id}-desk${selectedString}`}
          data-space-id={space.id}
          cx={shape.coordinates[0].x}
          cy={shape.coordinates[0].y}
          // This is calculated here instead of up there since floating point errors can occur at these sizes
          r={Math.ceil((pointSize * DEFAULT_FLOORPLAN_SCALE) / value)}
          style={style}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...shapeEvents}
        />
      )}
    </FloorplanScaleContext.Consumer>
  );
};

Shape.propTypes = {
  space: Space.isRequired,
  shape: ShapeType.isRequired,
  isPoint: PropTypes.bool,
  style: PropTypes.shape(),
  styleFunction: PropTypes.func,
  onShapeClicked: PropTypes.func,
  pointSize: PropTypes.number,
  isSelected: PropTypes.bool,
};

Shape.defaultProps = {
  isPoint: false,
  style: null,
  styleFunction: null,
  onShapeClicked: () => {},
  pointSize: 35.0,
  isSelected: false,
};

export default memo(Shape);
