import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSpaceFetch } from 'api/queries';
import { tenantSelectors, inAppNotificationsActions } from 'store';
import { generateFloorPath } from 'router/utils';
import { ROOT_PATH } from 'consts';

const SPACE_TYPE = {
  SPACE: 'Space',
  DESK: 'Desk',
};

const ERROR_MESSAGES = {
  [SPACE_TYPE.SPACE]: {
    INVALID_ID: 'router.location.invalidSpace',
    INVALID_LOCATION: 'router.location.invalidSpaceLocation',
  },
  [SPACE_TYPE.DESK]: {
    INVALID_ID: 'router.location.invalidDesk',
    INVALID_LOCATION: 'router.location.invalidDeskLocation',
  },
};

const useSpaceCheck = ({ id, isDesk = true } = {}) => {
  const spaceType = isDesk ? SPACE_TYPE.DESK : SPACE_TYPE.SPACE;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchSpaceQuery } = useSpaceFetch({ isDesk });
  const currentLocation = useSelector(tenantSelectors.getCurrentLocation);

  const checkSpace = async ({ id }) => {
    let data;

    try {
      data = await fetchSpaceQuery({
        id,
      });
    } catch (e) {
      return {
        errorMessage: ERROR_MESSAGES[spaceType].INVALID_ID,
      };
    }

    const { floorId, isDesk: _isDesk } = data;

    // Check if data has the same space type.
    // We can query a space by a valid desk id and that should throw an invalid spaceType error.
    if (_isDesk !== isDesk) {
      return {
        errorMessage: ERROR_MESSAGES[spaceType].INVALID_ID,
      };
    }

    // check desk is on same location
    if (currentLocation?.floor?.id === floorId) {
      return { data, errorMessage: null };
    }

    return {
      errorMessage: ERROR_MESSAGES[spaceType].INVALID_LOCATION,
    };
  };

  const checkSpaceQuery = useCallback(checkSpace, [
    currentLocation,
    fetchSpaceQuery,
    isDesk,
    spaceType,
  ]);

  useEffect(() => {
    (async () => {
      const { errorMessage } = await checkSpaceQuery({
        id,
      });

      if (errorMessage) {
        dispatch(
          inAppNotificationsActions.addWarningNotification({
            message: t(errorMessage),
          }),
        );
        navigate(
          currentLocation
            ? generateFloorPath(currentLocation?.floor?.id)
            : ROOT_PATH,
        );
      }
    })();
  }, [checkSpaceQuery, currentLocation, dispatch, id, navigate, t]);
};

export default useSpaceCheck;
