var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect, useReducer, useRef } from 'react';
import ReactFocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Wrapper, CommonMessage, Title, PrimaryButton, ButtonsRow, SecondaryButton, } from './styled';
var CountdownText = styled(CommonMessage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 36px;\n  color: ", ";\n  margin-bottom: 28px;\n  font-weight: 700;\n  margin-top: 20px;\n"], ["\n  line-height: 36px;\n  color: ", ";\n  margin-bottom: 28px;\n  font-weight: 700;\n  margin-top: 20px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
var Message = styled(CommonMessage)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 25px;\n  margin-top: 8px;\n  font-weight: 400;\n"], ["\n  line-height: 25px;\n  margin-top: 8px;\n  font-weight: 400;\n"])));
var KioskCountdownDialog = function (_a) {
    var _b = _a.seconds, seconds = _b === void 0 ? 20 : _b, onContinuePress = _a.onContinuePress, onTimeIsOver = _a.onTimeIsOver, onExitPress = _a.onExitPress;
    var t = useTranslation().t;
    var intervalRef = useRef();
    var _c = useReducer(function (left) { return left - 1; }, seconds), secondsLeft = _c[0], decrementSecondsLeft = _c[1];
    useEffect(function () {
        intervalRef.current = setInterval(decrementSecondsLeft, 1000);
        return function () {
            clearInterval(intervalRef.current);
        };
    }, []);
    useEffect(function () {
        if (secondsLeft === 0) {
            clearInterval(intervalRef.current);
            onTimeIsOver();
        }
    }, [onTimeIsOver, secondsLeft]);
    return (React.createElement(ReactFocusLock, null,
        React.createElement(Wrapper, null,
            React.createElement(Title, null, t('components.countdownDialog.title')),
            React.createElement(Message, null, t('components.countdownDialog.message')),
            React.createElement(CountdownText, null, t('components.countdownDialog.countdownMessage', { secondsLeft: secondsLeft })),
            React.createElement(ButtonsRow, null,
                React.createElement(SecondaryButton, { onClick: onExitPress }, t('common.exit')),
                React.createElement(PrimaryButton, { onClick: onContinuePress }, t('common.continue'))))));
};
export default KioskCountdownDialog;
var templateObject_1, templateObject_2;
