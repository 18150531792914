import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { tenantSelectors } from 'store';
import { useFloorLocationFetch } from '@serraview/engage-shared/api';
import { WAYFINDER_PATH } from 'consts';
import { useAppLocation } from 'router/hooks';

export const initialState = {
  regionPath: [],
  activeBuilding: null,
  activeFloor: null,
};

export const locationStateTypes = {
  regionPath: PropTypes.arrayOf(PropTypes.number).isRequired,
  activeBuilding: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    timeZone: PropTypes.string,
  }),
  activeFloor: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export const ADD_REGION_TO_PATH = 'addRegionToPath';
export const REMOVE_REGION_FROM_PATH = 'removeRegionFromPath';
export const SET_ACTIVE_BUILDING = 'setActiveBuilding';
export const INITIALIZE_FROM_CURRENT_LOCATION = 'initializeFromCurrentLocation';

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_REGION_TO_PATH: {
      const {
        payload: { region, parentId },
      } = action;
      const { id } = region;
      const parentIndex = state.regionPath.findIndex(
        (regionId) => regionId === parentId,
      );
      let _regionPath = [...state.regionPath];
      if (parentIndex !== -1) {
        _regionPath.splice(parentIndex + 1, 1, id);
      } else {
        _regionPath = [id];
      }
      return {
        ...state,
        regionPath: _regionPath,
      };
    }
    case REMOVE_REGION_FROM_PATH: {
      const { payload: region } = action;
      const regionIndex = state.regionPath.findIndex(
        (regionId) => regionId === region.id,
      );
      let _regionPath = [...state.regionPath];
      if (regionIndex !== -1) {
        _regionPath = state.regionPath.slice(0, regionIndex);
      }
      return {
        ...state,
        regionPath: _regionPath,
      };
    }
    case SET_ACTIVE_BUILDING: {
      return {
        ...state,
        activeBuilding: action.payload,
      };
    }
    case INITIALIZE_FROM_CURRENT_LOCATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const setInitFromCurrentLocation = (payload) => ({
  type: INITIALIZE_FROM_CURRENT_LOCATION,
  payload,
});

const init = (initialState) => ({
  regionPath: initialState.regionPath,
  activeBuilding: initialState.activeBuilding,
  activeFloor: initialState.activeFloor,
});

export default function useLocationSelector() {
  const { pathname } = useAppLocation();
  const tenantId = useSelector(tenantSelectors.getTenantId);
  const { building, floor } = useSelector(tenantSelectors.getCurrentLocation);
  const [state, dispatch] = React.useReducer(reducer, initialState, init);
  const { fetchFloorLocationQuery } = useFloorLocationFetch({ tenantId });

  const isLocationSelectorPath = pathname.endsWith(WAYFINDER_PATH);

  React.useEffect(() => {
    if (isLocationSelectorPath && building && floor) {
      const doFetchFloorRegions = async () => {
        const floorLocationData = await fetchFloorLocationQuery({
          id: floor.id,
        });
        // filter regions by type Region and return a list of region ids
        const regionPath = floorLocationData
          .filter((r) => r.type === 'Region')
          .map((r) => r.id);
        dispatch(
          setInitFromCurrentLocation({
            regionPath,
            activeBuilding: building,
            activeFloor: floor,
          }),
        );
      };
      doFetchFloorRegions();
    }
  }, [building, floor, fetchFloorLocationQuery, isLocationSelectorPath]);

  return [state, dispatch];
}
