import { getActionFromEngageUrl, isBrowserUrl, MENU_ACTION_TYPES, } from '@serraview/engage-shared';
import { isElectron } from 'utils/electron';
var HIDDEN_KIOSK_URLS = [
    'engage://bookings',
    'engage://keyboard_accessibility',
];
/**
 * Get menu item visibility. Returns false for non-engage urls.
 * The hidden menu items are removed by the server. The hidden property will always be false unless we hide the development features.
 * @param {string} url - Concierge menu item url
 * @param {boolean} hiddenForWeb - true if the item should be hidden for the web platform.
 * @param {boolean} hiddenForKiosk - true if the item should be hidden for kiosks.
 * @param {string} env - process.env.NODE_ENV it's passed as argument to make testing easier.
 * @returns {boolean}
 */
export var getIsMenuItemHidden = function (url, hiddenForWeb, hiddenForKiosk, env) {
    var isKiosk = isElectron();
    if (!isKiosk && hiddenForWeb)
        return true;
    if (isKiosk && (hiddenForKiosk || HIDDEN_KIOSK_URLS.includes(url)))
        return true;
    if (env === 'development' || isBrowserUrl(url))
        return false;
    var action = getActionFromEngageUrl(url).action;
    return (action === MENU_ACTION_TYPES.DEVELOPMENT ||
        action === MENU_ACTION_TYPES.DEMONSTRATION_CONFIG ||
        action === MENU_ACTION_TYPES.QR_SCAN);
};
