var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { GlobalSearchOuterWrapper } from 'components/layouts/GlobalSearch/styled';
export var TeamMembersListWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-height: 80vh;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-height: 80vh;\n"])));
export var SearchInputWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  margin: 15px 0;\n"], ["\n  width: 100%;\n  margin: 15px 0;\n"])));
export var TeamMembersSearchOuterWrapper = styled(GlobalSearchOuterWrapper)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-bottom: 60px;\n"], ["\n  padding-bottom: 60px;\n"])));
export var TeamReservationButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: ", ";\n  font-weight: bold;\n  font-size: 18px;\n  height: 80px;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  margin: 0;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: ", ";\n  font-weight: bold;\n  font-size: 18px;\n  height: 80px;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  margin: 0;\n"])), function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return disabled ? '#cccccc' : theme.color9;
}, function (_a) {
    var disabled = _a.disabled, theme = _a.theme;
    return (disabled ? '#000000' : theme.typo1);
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 'not-allowed' : 'pointer');
});
export var TeamMembersListSkeleton = styled.ul(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0 0 23px;\n  list-style: none;\n"], ["\n  margin: 0;\n  padding: 0 0 23px;\n  list-style: none;\n"])));
export var EmptyList = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  margin-top: 50px;\n  font-size: 15px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  margin-top: 50px;\n  font-size: 15px;\n"])));
export var DesksSelectionCardContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-height: 80vh;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  max-height: 80vh;\n"])));
export var SelectableItemWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), function (_a) {
    var isSelected = _a.isSelected;
    return isSelected ? 'rgba(195,216,238,0.4)' : 'transparent';
});
export var SelectionHint = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 0 30px 10px;\n  margin: 0;\n"], ["\n  padding: 0 30px 10px;\n  margin: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
