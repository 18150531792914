import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import {
  useSpaceTypeAttributesQuery,
  useSpaceTypesQuery,
} from '@serraview/engage-shared';

export const useSpaceTypes = ({ spaceType, options = {} }) => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  return useSpaceTypesQuery({ spaceType, locale, options });
};

export const useSpaceTypeAttributes = ({ spaceType, options = {} }) => {
  const locale = useSelector(userConfigSelectors.getUiLanguage);
  return useSpaceTypeAttributesQuery({ spaceType, locale, options });
};
