import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'base-components';
import { BuildingsListWrapper } from './styled';

const BuildingListSkeleton = () => (
  <SkeletonTheme>
    <BuildingsListWrapper data-testid="buildings-list-skeleton">
      <Skeleton width={200} height={15} />
      <Skeleton width={150} height={15} />
    </BuildingsListWrapper>
  </SkeletonTheme>
);

export default BuildingListSkeleton;
