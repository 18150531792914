var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { DurationButtonInput, DurationButtonLabel, } from 'components/base/Duration/styled';
import styled from 'styled-components';
export var DurationSelectorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n\n  ", " {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n\n  ", " {\n    margin-right: 0;\n  }\n"])), DurationButtonInput);
export var SelectorButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 20px;\n  width: 20px;\n  margin: 9px 0;\n  opacity: 0.5;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  height: 20px;\n  width: 20px;\n  margin: 9px 0;\n  opacity: 0.5;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color2;
});
export var Label = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  padding: 0 4px;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  padding: 0 4px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color2;
});
export var DurationSelectorContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  ", " {\n    margin: 0;\n    &:hover {\n      cursor: default;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  ", " {\n    margin: 0;\n    &:hover {\n      cursor: default;\n    }\n  }\n"])), DurationButtonLabel);
export var NumberInput = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  // styles to remove number field arrows\n  -moz-appearance: textfield;\n  ::-webkit-outer-spin-button,\n  ::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  color: ", ";\n  border: 2px solid ", ";\n  background-color: ", ";\n  font-size: ", ";\n  font-weight: bold;\n  height: 38px;\n  border-radius: 19px;\n  text-align: center;\n  width: 60px;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  // styles to remove number field arrows\n  -moz-appearance: textfield;\n  ::-webkit-outer-spin-button,\n  ::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  color: ", ";\n  border: 2px solid ", ";\n  background-color: ", ";\n  font-size: ", ";\n  font-weight: bold;\n  height: 38px;\n  border-radius: 19px;\n  text-align: center;\n  width: 60px;\n\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color1;
}, function (_a) {
    var theme = _a.theme;
    return theme.color9;
}, function (_a) {
    var theme = _a.theme;
    return theme.color4;
}, function (_a) {
    var theme = _a.theme;
    return (theme.isLayoutRTL ? '12px' : '14px');
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
