import { useEffect, useRef, useState } from 'react';
export var useTimer = function (_a) {
    var seconds = _a.seconds, onTimeOver = _a.onTimeOver, onTimerCancel = _a.onTimerCancel;
    var _b = useState(false), timerActive = _b[0], setTimerActive = _b[1];
    var _c = useState(seconds), secondsLeft = _c[0], setSecondsLeft = _c[1];
    var intervalRef = useRef();
    var activateTimer = function () {
        setTimerActive(true);
    };
    var cancelTimer = function () {
        clearInterval(intervalRef.current);
        setTimerActive(false);
        setSecondsLeft(seconds);
        onTimerCancel === null || onTimerCancel === void 0 ? void 0 : onTimerCancel();
    };
    useEffect(function () {
        if (timerActive) {
            intervalRef.current = setInterval(function () { return setSecondsLeft(function (sec) { return sec - 1; }); }, 1000);
        }
        return function () {
            clearInterval(intervalRef.current);
        };
    }, [timerActive]);
    useEffect(function () {
        if (secondsLeft === 0) {
            onTimeOver();
            cancelTimer();
        }
    }, [cancelTimer, onTimeOver, secondsLeft]);
    return { activateTimer: activateTimer, cancelTimer: cancelTimer, secondsLeft: secondsLeft };
};
