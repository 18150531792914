import { LanguageTypes } from '@serraview/engage-shared';
export var testProps = function (t, keys, cb) {
    if (typeof keys === 'string' && !!keys) {
        var translated = t(keys);
        return {
            text: [translated],
            props: {
                'aria-label': cb ? cb([translated]) : translated,
                'data-testid': cb
                    ? cb([t(keys, { lng: LanguageTypes.en })])
                    : t(keys, { lng: LanguageTypes.en }),
            },
        };
    }
    if (Array.isArray(keys)) {
        if (!cb && __DEV__)
            throw new Error('cb must be provided when keys is an array');
        var nonEmptyKeys = keys.filter(function (key) { return !!key; });
        var translated = nonEmptyKeys.map(t);
        return {
            text: translated,
            props: {
                'aria-label': cb(translated),
                'data-testid': cb(nonEmptyKeys.map(function (key) { return t(key, { lng: LanguageTypes.en }); })),
            },
        };
    }
    if (__DEV__) {
        throw new Error('keys should be string or an array of strings');
    }
};
