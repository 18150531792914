import { useSelector } from 'react-redux';
import { userConfigSelectors } from 'store';
import { LanguageTypes } from '@serraview/engage-shared';
export var useHasChineseCharacters = function () {
    var locale = useSelector(userConfigSelectors.getUiLanguage);
    var languagesWithChineseChars = [
        LanguageTypes.ja,
        LanguageTypes.jaJP,
        LanguageTypes.ko,
        LanguageTypes.koKR,
        LanguageTypes.zh,
        LanguageTypes.zhCN,
    ];
    return !!languagesWithChineseChars.find(function (lang) { return lang === locale; });
};
