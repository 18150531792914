import React, { Component } from 'react';
import { RESTRICT_TO_VALUES } from 'consts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';

// eslint-disable-next-line import/no-extraneous-dependencies
import { bindActionCreators, compose } from 'redux';

import { getSpaceSVLivePresenceEventOccupancy } from '@serraview/engage-shared/utils';

import FESContext from 'floorplan/contexts/FESContext';
import FloorplanContainer from 'floorplan/components/Floorplan/FloorplanContainer';
import FloorplanShapes from 'floorplan/components/FloorplanShapes/FloorplanShapes';
import Shape from 'floorplan/components/Shape/Shape';
import {
  FES_EVENT_FLOORPLAN_DATA_LOADED,
  FES_EVENT_SHOW_LABELS,
  FES_EVENTS,
} from 'floorplan/constants/FESEvents';
import { bindRetryCallback } from 'floorplan/utils/retry';
import {
  panToShape,
  panToShapeCollection,
  resetRotation,
} from 'floorplan/utils/viewport';
import Icon from 'floorplan/components/Icon/Icon';
import MarkerIcon from 'components/floorplan/components/Icon/MarkerIcon';
import Api from 'floorplan/services/api';
import ThemeManager from 'floorplan/services/theme';

import * as allActions from 'floorplan/store/actions';
import { svLivePersonLocationToShape } from 'floorplan/utils/shapeUtils';
import {
  boxesIntersect,
  calculateSelectionBox,
  DragSelection,
  isPointInsideBox,
} from 'floorplan/components/DragSelection';
import {
  initialState as teamReservationStoreDefaults,
  isTeamReservationsEnabled,
  TEAM_RESERVATION_DESKS_SELECTION_MODE,
} from 'store/teamReservations';
import FloorplanLabels from 'floorplan/components/FloorplanLabels/FloorplanLabels';
import {
  getLabelOffsetY,
  getLabelsForSpaces,
  isSpaceOccupiedBySensor,
} from 'floorplan/utils/space';
import Label from 'floorplan/components/FloorplanLabels/Label';
import HeatmapOverlay from 'floorplan/components/Floorplan/HeatmapOverlay';
import RemoteMarkerIcon from 'components/floorplan/components/Icon/RemoteMarkerIcon';
import { isTimeNow } from '@serraview/engage-shared';
import { HereMarker } from 'components/floorplan/components/HereMarker/HereMarker';
import { getDataFromSpaces } from 'components/floorplan/utils/heatmap';
import RadarPing from '../../RadarPing/RadarPing';

class Floorplan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layers: {
        showOccupants: true,
        showRooms: true,
        showDesks: true,
        all: true,
      },
      selectedSpace: [],
      distanceMarker: undefined,
      zoomTilesShown: false,
      reservedAlwaysEnabled: false,
      svLivePersonLocationShape: null,
      teamReservationDesksSelectionMode:
        teamReservationStoreDefaults.teamDesksSelectionMode,
      teamReservationSelectedDesks: [],
      teamReservationTeamSize: 0,
      shouldClearDesksSelection: false,
      showLabels: false,
      labelOptions: {
        showOccupantLabels: false,
        showSpaceNameLabels: false,
        showSpaceTypeLabels: false,
        labelFontSize: 10,
      },
      floorMarkers: [],
      kioskLocation: null,
    };
    this.initialized = false;
    this.startTime = undefined;
    this.endTime = undefined;
  }

  componentDidMount() {
    const {
      props: { floorId },
    } = this;

    this.currentFloorId = floorId;

    this.context.on(FES_EVENTS.INITIAL_LOAD, () => {
      this.loadSpaceData(this.currentFloorId);

      // Very ungraceful way to avoid the first time component render refresh of data.
      // The reason being the useEffect hook on floorplanHandler will trigger just after the initial_load
      // Handshake ends, thus always being late one frame.
      // This timeout ensures no api calls duplicates get called.
      setTimeout(() => {
        this.initialized = true;
      }, 100);
    });

    this.context.on(FES_EVENTS.FLOORPLAN_READY, () => {
      this.context.trigger(FES_EVENTS.FLOORPLAN_LOADED, {
        id: this.currentFloorId,
      });

      this.context.send({
        type: FES_EVENTS.FLOORPLAN_LOADED,
        id: this.currentFloorId,
      });
      // prevents overlay stacking on floor change
      this.removeHeatmapOverlay();
    });

    this.context.on(FES_EVENTS.REFRESH_SPACE_DATA, () => {
      if (!this.context.getViewer()) {
        return;
      }

      this.loadSpaceData(this.currentFloorId, true);
    });

    this.context.on(FES_EVENTS.SHOW_SPACE, (obj) => {
      if (!this.context.getViewer()) {
        this.context.on(FES_EVENTS.SET_VIEWER, () => {
          this.context.clearAllHandlers(FES_EVENTS.SET_VIEWER);
          setTimeout(() => {
            this.context.trigger(FES_EVENTS.SHOW_SPACE, obj);
          }, 500); // initial intro 300ms
        });

        return;
      }
      this.panToShape(obj);
    });

    this.context.on(FES_EVENTS.RESET_ORIENTATION, () => {
      if (this.context.getViewer()) {
        const {
          viewer: { viewport },
        } = this.context;

        resetRotation(viewport);
      }
    });

    this.context.on(FES_EVENTS.FLOORPLAN_CHANGED, (obj) => {
      this.onChangeFloorplan(obj.id);
    });

    this.context.on(FES_EVENTS.SET_AVAILABILITY_DATE, (obj) => {
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      if (this.initialized) {
        this.loadSpaceData(this.currentFloorId, true);
      }
    });

    this.context.on(FES_EVENTS.TOGGLE_VIEW, (obj) => {
      if (this.context.getViewer()) {
        this.updateState({
          layers: obj,
        });
      }
    });

    this.context.on(FES_EVENTS.SET_DISTANCE_MARKER, (obj) => {
      if (this.distanceMarkerTimer) this.distanceMarkerTimer = 0;
      this.showMarkerOnReservedSpace(obj);
    });

    this.context.on(FES_EVENTS.CLEAR_SHOW_SPACE, () => {
      if (this.context.getViewer()) {
        this.removeShapeSelected();
      }
    });

    this.context.on(FES_EVENTS.REFRESH_TOKEN, (obj) => {
      Api.setToken(obj.token);
    });

    this.context.on(FES_EVENTS.INIT_DATA, (obj) => {
      const {
        token,
        theme,
        zoomTilesShown,
        reservedAlwaysEnabled,
        labelOptions,
      } = obj;
      this.initialized = false;

      if (token) {
        Api.setToken(token);
      }

      if (theme) {
        ThemeManager.setTheme(theme);
      }

      this.updateState({
        reservedAlwaysEnabled,
        zoomTilesShown,
        labelOptions,
      });
    });

    this.context.send({ type: FES_EVENTS.INIT, data: {} });

    this.context.on(FES_EVENTS.SHOW_SV_LIVE_PERSON_LOCATION, (location) => {
      const personLocationShape = svLivePersonLocationToShape(location);

      this.context.trigger(FES_EVENTS.CLEAR_SHOW_SPACE);
      this.updateState({
        svLivePersonLocationShape: personLocationShape,
      });
      if (this.context.getViewer()) {
        panToShape(this.context.getViewer().viewport, personLocationShape);
      }
    });

    this.context.on(FES_EVENTS.HIDE_SV_LIVE_PERSON_LOCATION, () => {
      this.updateState({
        svLivePersonLocationShape: null,
      });
    });

    this.context.on(
      FES_EVENTS.SET_TEAM_RESERVATION_DESKS_SELECTION_MODE,
      (mode) => {
        this.updateState({
          teamReservationDesksSelectionMode: mode,
        });
      },
    );

    this.context.on(FES_EVENTS.SET_TEAM_RESERVATION_TEAM_SIZE, (size) => {
      this.updateState({
        teamReservationTeamSize: size,
      });
    });

    this.context.on(FES_EVENTS.CLEAR_TEAM_RESERVATION_DESKS_SELECTION, () => {
      this.eraseDesks(this.state.teamReservationSelectedDesks);
    });

    this.context.on(
      FES_EVENTS.TEAM_RESERVATION_SELECTED_DESKS_CHANGED,
      (desks) => {
        const { teamReservationSelectedDesks } = this.state;
        // check if arrays of desks are not the same to avoid additional render
        if (
          teamReservationSelectedDesks.length !== desks.length ||
          desks.some((deskId) => !teamReservationSelectedDesks.includes(deskId))
        ) {
          this.updateTeamReservationSelectedDesks(desks);
        }
      },
    );

    this.context.on(FES_EVENT_SHOW_LABELS, ({ showLabels }) => {
      this.updateState({
        showLabels,
      });
    });

    this.context.on(
      FES_EVENTS.ENTER_TEAM_BOOKING_AVAILABILITY_MODE,
      ({ teamBookingId }) => {
        this.props.actions.setTeamBookingAvailabilityMode(this.currentFloorId);

        this.props.actions.loadTeamBookingAvailabiltyData(
          {
            floorId: this.currentFloorId,
            startTime: this.startTime,
            endTime: this.endTime,
            teamBookingId,
          },
          () => {
            // set flag loaded to true so we know when all data was fetched
            // this is the last fetch that is done after the space data is loaded
            this.context.send({
              type: FES_EVENT_FLOORPLAN_DATA_LOADED,
              loaded: true,
            });
          },
          () => {
            this.context.send({
              type: FES_EVENTS.RECOVERABLE_ERROR,
              message: this.props.t(
                'layout.floorplanError.errorLoadingRoomAvailabilities',
              ),
            });
          },
        );
      },
    );

    this.context.on(FES_EVENTS.EXIT_TEAM_BOOKING_AVAILABILITY_MODE, () => {
      this.props.actions.resetMode();
      this.loadSpaceData(this.currentFloorId, true);
    });

    this.context.on(FES_EVENTS.SHOW_HEATMAP, () => {
      if (!this.context.getViewer()) {
        return;
      }
      const {
        space: {
          [this.currentFloorId]: {
            filteredSpaces: { occupantPoints, nonOccupantPoints },
          },
        },
      } = this.props;

      const data = [
        ...getDataFromSpaces(occupantPoints, {
          value: 5,
          radius: 100,
        }),
        ...getDataFromSpaces(nonOccupantPoints, {
          value: 3,
          radius: 80,
        }),
      ];

      this.addHeatmapOverlay({ data, max: 5 });
    });
    this.context.on(FES_EVENTS.HIDE_HEATMAP, () => {
      this.removeHeatmapOverlay();
    });

    this.context.on(
      FES_EVENTS.SET_FLOOR_MARKERS,
      ({ drawableMarkers: floorMarkers }) => {
        this.setState({ floorMarkers });
      },
    );

    this.context.on(FES_EVENTS.SHOW_HERE_MARKER, ({ kioskLocation }) => {
      this.updateState({ kioskLocation });
    });
    this.context.on(FES_EVENTS.HIDE_HERE_MARKER, () => {
      this.updateState({ kioskLocation: null });
    });
  }

  componentWillUnmount() {
    this.removeHeatmapOverlay();
  }

  teamReservationSelectedDesksSync = [];

  // eslint-disable-next-line react/sort-comp
  updateState(obj) {
    this.setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  }

  loadSpaceData(id, refresh = false) {
    const { t } = this.props;
    const {
      actions: {
        loadSpaceData,
        refreshSpaceData,
        loadDeskAvailabiltyData,
        loadSpaceAvailabiltyData,
      },
    } = this.props;
    const { startTime, endTime } = this;

    // everytime loading the data will reset the loader flag
    this.context.send({
      type: FES_EVENT_FLOORPLAN_DATA_LOADED,
      loaded: false,
    });

    const callback = () => {
      // Is not bound to a retry due to business logic which states that
      // Everything should proceed even if this call fails

      loadSpaceAvailabiltyData(
        { floorId: id, startTime, endTime },
        () => {
          // set flag loaded to true so we know when all data was fetched
          // this is the last fetch that is done after the space data is loaded
          this.context.send({
            type: FES_EVENT_FLOORPLAN_DATA_LOADED,
            loaded: true,
          });
        },
        () => {
          if (id === this.currentFloorId) {
            this.context.send({
              type: FES_EVENTS.RECOVERABLE_ERROR,
              message: t(
                'layout.floorplanError.errorLoadingRoomAvailabilities',
              ),
            });
          }
        },
      );
      bindRetryCallback(
        null,
        loadDeskAvailabiltyData.bind(
          null,
          { floorId: id, startTime, endTime },
          () => {},
        ),
        () => {
          if (id === this.currentFloorId) {
            this.context.send({
              type: FES_EVENTS.UNRECOVERABLE_ERROR,
              message: t('layout.floorplanError.errorRefreshingFloorData'),
            });
          }
        },
        1,
        15 * 1000,
      )();
    };

    if (refresh) {
      bindRetryCallback(
        null,
        refreshSpaceData.bind(
          null,
          { floorId: id, startTime, endTime },
          callback,
        ),
        () => {
          if (id === this.currentFloorId) {
            this.context.send({
              type: FES_EVENTS.UNRECOVERABLE_ERROR,
              message: t('layout.floorplanError.errorRefreshingFloorData'),
            });
          }
        },
        1,
        15 * 1000,
      )();
    } else {
      bindRetryCallback(
        null,
        loadSpaceData.bind(null, { floorId: id, startTime, endTime }, callback),
        () => {
          if (id === this.currentFloorId) {
            this.context.send({
              type: FES_EVENTS.UNRECOVERABLE_ERROR,
              message: t('layout.floorplanError.errorLoadingFloorData'),
            });
          }
        },
        1,
        15 * 1000,
      )();
    }
  }

  onChangeFloorplan(id) {
    this.props.hideRecoveryPane();
    this.context.trigger(FES_EVENTS.CLEAR_SHOW_SPACE);

    this.currentFloorId = id || this.currentFloorId;

    // reset show labels
    this.updateState({
      showLabels: false,
    });

    if (this.initialized) {
      this.loadSpaceData(id);
    }
  }

  shouldShowOccupants(isReserved) {
    const {
      layers: { all, showOccupants },
    } = this.state;

    return showOccupants && !all && isReserved;
  }

  isSearchRestrictedToPerson(isReserved) {
    return (
      this.props.restrictToSearchFilterValue === RESTRICT_TO_VALUES.PERSON &&
      isReserved
    );
  }

  getPointSize({ isReserved, reservedAlwaysEnabled, pointSizes }) {
    return this.shouldShowOccupants(isReserved) ||
      reservedAlwaysEnabled ||
      this.isSearchRestrictedToPerson(isReserved)
      ? pointSizes.important
      : pointSizes.unImportant;
  }

  getKey = (spaceId, shapeIndex) => `s.${spaceId}.${shapeIndex}`;

  /* Team Reservation Helpers */
  updateTeamReservationSelectedDesks = (teamReservationSelectedDesks) => {
    this.teamReservationSelectedDesksSync = teamReservationSelectedDesks;
    this.setState({ teamReservationSelectedDesks });
  };

  updateTeamReservationSelectedDesksStore = (desks) => {
    this.context.send({
      type: FES_EVENTS.TEAM_RESERVATION_ADD_DESKS,
      desks,
    });
  };

  isTeamReservationSelectedDesksLimitReached = () =>
    this.state.teamReservationSelectedDesks.length >=
    this.state.teamReservationTeamSize;

  /* Team Reservation Helpers End */

  handleShapeClicked = (event) => {
    const { space } = event;
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();

    const { teamReservationDesksSelectionMode } = this.state;

    if (isTeamReservationsEnabled(teamReservationDesksSelectionMode)) {
      return;
    }

    this.context.trigger(FES_EVENTS.FLOORPLAN_CLICKED, event);
    this.context.send({
      type: FES_EVENTS.FLOORPLAN_CLICKED,
      data: space,
    });

    this.setSelectedSpace(space);
  };

  setSelectedSpace = (space) => {
    this.updateState({
      selectedSpace: [space],
    });
  };

  removeShapeSelected = (event) => {
    if (event?.originalEvent) {
      event.originalEvent.preventDefault();
      event.originalEvent.stopPropagation();
    }

    const { selectedSpace } = this.state;

    if (selectedSpace?.length) {
      this.updateState({
        selectedSpace: [],
      });

      this.context.send({ type: FES_EVENTS.SHOW_SPACE, data: null });
    }
  };

  getShapeFromID(shape) {
    const {
      space: {
        [this.currentFloorId]: { spaces },
      },
    } = this.props;

    if (!spaces) {
      return null;
    }
    const spaceShape = spaces.filter((space) => space.id === shape.id);

    if (!spaceShape[0]) {
      return null;
    }

    return spaceShape[0];
  }

  panToShape(shape) {
    const isCollection = shape.id instanceof Array;

    if (isCollection) {
      const shapeList = shape.id
        .map((id) => this.getShapeFromID({ id }))
        .filter((s) => !!s);

      if (Array.isArray(shapeList) && shapeList.length > 0) {
        panToShapeCollection(
          this.context.getViewer().viewport,
          shapeList,
          false,
        );
      }

      if (shape.select) {
        this.updateState({
          selectedSpace: shapeList,
        });
      }
      return;
    }

    const fullShape = this.getShapeFromID(shape);

    if (fullShape) {
      panToShape(this.context.getViewer().viewport, fullShape, false);

      if (shape.select) {
        this.updateState({
          selectedSpace: [fullShape],
        });
      }
    }
  }

  // style of spaces (rooms, zones)
  spaceStyle = (space, shape) => {
    const {
      selectedSpace,
      layers: { all, showRooms },
    } = this.state;
    const { available, allowInteraction } = space;
    const isUnImportant = !allowInteraction;
    const hasOccupant = space.homeLocations;
    const isThereSelection = selectedSpace && selectedSpace.length;

    const isSpace = shape?.isPolygon;

    if (isUnImportant) {
      return ThemeManager.getStyle('unImportant', isSpace);
    }

    if (hasOccupant || !available) {
      return ThemeManager.getStyle('unAvailable', isSpace);
    }

    if (showRooms && !all && !isThereSelection) {
      return ThemeManager.getStyle('focused', isSpace);
    }

    return ThemeManager.getStyle('available', isSpace);
  };

  // Style of the space/desk rendered above others, when a space is selected
  selectedSpaceStyle = (space, shape) => {
    const isSpace = shape?.isPolygon;

    return ThemeManager.getStyle('focused', isSpace);
  };

  // Wall style is the background white color.
  basicWallStyle = () => {
    const obj = ThemeManager.getThemeObject('background');

    return {
      fill: obj.color,
      stroke: obj.color,
      strokeWidth: 0,
    };
  };

  // Extended wall style has the border in another color to indicate building walls
  extendedWallStyle = () => {
    const { zoomTilesShown } = this.state;
    const bobj = ThemeManager.getThemeObject('background');
    const wobj = ThemeManager.getThemeObject('wall');

    if (zoomTilesShown) {
      return {
        fill: '#FFFFFF',
        stroke: '#FFFFFF',
        opacity: 0,
        strokeOpacity: 0,
        strokeWidth: 100,
      };
    }

    return {
      fill: bobj.color,
      stroke: wobj.color,
      strokeWidth: 100,
    };
  };

  // occupant point is the point where someone is placed, it can be both for desks and spaces
  occupantPointStyle = (space, shape) => {
    const isNow = !!this.startTime && isTimeNow(new Date(this.startTime));

    const { reservedAlwaysEnabled } = this.state;
    const isSpace = shape?.isPolygon;

    if (
      isTeamReservationsEnabled(this.state.teamReservationDesksSelectionMode)
    ) {
      return ThemeManager.getStyle('teamReservationsDisabled');
    }

    if (
      this.shouldShowOccupants(space.isReserved) ||
      this.isSearchRestrictedToPerson(space.isReserved)
    ) {
      return ThemeManager.getStyle('focusedUnAvailable', isSpace);
    }

    if (
      this.shouldShowOccupants(space.isReserved) ||
      (reservedAlwaysEnabled && space.isReserved)
    ) {
      return ThemeManager.getStyle('focusedUnAvailable', isSpace);
    }

    // space occupied by SVLive presence
    const { isOccupied, isRemote } =
      getSpaceSVLivePresenceEventOccupancy(space);
    if (isOccupied && isNow) {
      if (isRemote) {
        return ThemeManager.getStyle('unAvailable', isSpace);
      }
      return ThemeManager.getStyle('focusedUnAvailable', isSpace);
    }

    // space occupied by sensor
    if (isSpaceOccupiedBySensor(space) && isNow) {
      return ThemeManager.getStyle('focusedUnAvailable', isSpace);
    }

    return ThemeManager.getStyle('unAvailable', isSpace);
  };

  // non occupant points are available desks (have isDesk on their space)
  nonOccupantPointStyle = (space, shape) => {
    const {
      selectedSpace,
      layers: { all, showDesks },
    } = this.state;
    const { available } = space;
    const isUnImportant = !space.allowInteraction;
    const isThereSelection = selectedSpace && selectedSpace.length;
    const isSpace = shape?.isPolygon;

    if (isUnImportant) {
      return ThemeManager.getStyle('unImportant', isSpace);
    }

    if (!available) {
      return ThemeManager.getStyle('unAvailable', isSpace);
    }

    if (showDesks && !all && !isThereSelection) {
      return ThemeManager.getStyle('focused', isSpace);
    }

    return ThemeManager.getStyle('available', isSpace);
  };

  showMarkerOnReservedSpace = (shape) => {
    if (Array.isArray(shape)) {
      // currently not allowed, return error to RN
      this.context.send({
        type: FES_EVENTS.RECOVERABLE_ERROR,
        message:
          'Only one distance marker is allowed, received Array of spaceId',
      });
      return;
    }
    const fullShape = this.getShapeFromID(
      shape.distanceMarker ? { id: shape.distanceMarker } : shape,
    );
    if (fullShape) {
      panToShape(fullShape, shape.zoom);

      // setTimer to reset distanceMarker to 0 and notify RN that marker was removed
      this.distanceMarkerTimer = setTimeout(
        this.resetDistanceMarker,
        13 * 1000, // animation time is currently 8 + 5 sec delay
        shape?.distanceMarker,
      );

      this.updateState({
        distanceMarker: { ...fullShape, time: shape.marker },
      });
    }
  };

  resetDistanceMarker = (spaceId) => {
    const { distanceMarker } = this.state;

    if (spaceId) {
      this.context.send({
        type: FES_EVENTS.DISTANCE_MARKER_EXPIRED,
        data: {
          spaceId,
          numberOfRepeats: distanceMarker?.time,
        },
      });
    }
    this.setState({
      distanceMarker: undefined,
    });
  };

  renderedSelectedSpaces = (spaces) => {
    const pointSizes = ThemeManager.getThemeObject('pointSizes');

    return (
      <FloorplanShapes>
        {spaces.map((space) => (
          <Shape
            key={this.getKey(space.id, 1)}
            shape={space.shapes[0] || space.shape}
            space={space}
            styleFunction={this.selectedSpaceStyle}
            pointSize={pointSizes.important}
            isSelected
            onShapeClicked={(event) =>
              space.allowInteraction && this.handleShapeClicked(event, space)
            }
          />
        ))}
        {spaces.length === 1 && <MarkerIcon space={spaces[0]} />}
      </FloorplanShapes>
    );
  };

  renderDistanceMarker = () => {
    const { distanceMarker } = this.state;

    if (!distanceMarker) return null;
    // do not render for spaces marked as desks
    if (distanceMarker.isDesk && distanceMarker?.shapes?.[0]?.isPolygon)
      return null;

    const pointSizes = ThemeManager.getThemeObject('pointSizes');

    return (
      <FloorplanShapes>
        <RadarPing
          pointSize={pointSizes.important}
          shape={distanceMarker.shape || distanceMarker.shapes[0]}
          baseStyle={ThemeManager.getStyle('distanceMarker', undefined)}
          repeat={`${distanceMarker.time || 1}`}
        />
      </FloorplanShapes>
    );
  };

  /* Drag Selection Handlers and Helpers */

  getNonOccupantPoints = () => {
    const { nonOccupantPoints } =
      this.props.space[this.currentFloorId].filteredSpaces;

    return nonOccupantPoints
      .filter((space) => space.isDesk)
      .map((space) => {
        const domNode = document.querySelector(`[data-space-id='${space.id}']`);
        if (!domNode) {
          return null;
        }
        const { left, top, width, height } = domNode.getBoundingClientRect();
        return { left, top, width, height, space };
      })
      .filter((item) => !!item);
  };

  eraseDesks = (desks) => {
    this.updateTeamReservationSelectedDesks(
      this.state.teamReservationSelectedDesks.filter((d) => !desks.includes(d)),
    );
  };

  addDesksToTeamReservation = (desks) => {
    let newDesks = Array.from(
      new Set(this.state.teamReservationSelectedDesks.concat(desks)),
    );
    // sometimes calculations is not as fast as mouse move
    // so we need to make sure we are not fall over limits
    if (newDesks.length > this.state.teamReservationTeamSize) {
      newDesks = newDesks.splice(0, this.state.teamReservationTeamSize);
    }
    this.updateTeamReservationSelectedDesks(newDesks);
  };

  getDragSelectedDesks = (dragSelectionData) => {
    const { startX, startY, endX, endY } = dragSelectionData;

    const selectionBox = calculateSelectionBox({
      startPoint: { x: startX, y: startY },
      endPoint: { x: endX, y: endY },
    });

    const selectableDeskBoxes = this.getNonOccupantPoints();

    return selectableDeskBoxes
      .map((box) => (boxesIntersect(box, selectionBox) ? box.space.id : null))
      .filter(Boolean);
  };

  onDragSelectionClick = (point) => {
    const selectableDeskBoxes = this.getNonOccupantPoints();
    const desk = selectableDeskBoxes.find((deskBox) =>
      isPointInsideBox(point, deskBox),
    );

    if (!desk?.space) {
      return;
    }

    const { teamReservationSelectedDesks } = this.state;
    const {
      space: { id: clickedDeskId },
    } = desk;

    const isAlreadySelected =
      teamReservationSelectedDesks.includes(clickedDeskId);

    if (
      this.isTeamReservationSelectedDesksLimitReached() &&
      !isAlreadySelected
    ) {
      return;
    }

    // if desk is already selected we should deselect it (remove from teamReservationSelectedDesks array)
    // otherwise we should add it to array
    const newTemReservationSelectedDesks = isAlreadySelected
      ? teamReservationSelectedDesks.filter(
          (deskId) => deskId !== clickedDeskId,
        )
      : [...teamReservationSelectedDesks, desk.space.id];

    this.updateTeamReservationSelectedDesks(newTemReservationSelectedDesks);
    this.updateTeamReservationSelectedDesksStore(
      newTemReservationSelectedDesks,
    );
  };

  onDragSelectionMove = (dragSelectionData) => {
    if (!dragSelectionData.isDragging) {
      return;
    }

    const { teamReservationDesksSelectionMode } = this.state;

    // if we reached team reservation desks limit we are only allowed to erase desks
    if (
      this.isTeamReservationSelectedDesksLimitReached() &&
      teamReservationDesksSelectionMode !==
        TEAM_RESERVATION_DESKS_SELECTION_MODE.ERASE
    ) {
      return;
    }

    const selectedDesks = this.getDragSelectedDesks(dragSelectionData);

    if (
      teamReservationDesksSelectionMode ===
      TEAM_RESERVATION_DESKS_SELECTION_MODE.ERASE
    ) {
      this.eraseDesks(selectedDesks);
    } else {
      this.addDesksToTeamReservation(selectedDesks);
    }
  };

  onDragSelectionComplete = () => {
    this.updateTeamReservationSelectedDesksStore(
      this.teamReservationSelectedDesksSync,
    );
  };

  isDragSelectionEnabled = () =>
    [
      TEAM_RESERVATION_DESKS_SELECTION_MODE.ERASE,
      TEAM_RESERVATION_DESKS_SELECTION_MODE.PAINT,
    ].includes(this.state.teamReservationDesksSelectionMode);

  onDragSelectionMoveThrottled = throttle(this.onDragSelectionMove, 150, {
    leading: false,
  });

  // should use 200ms 'wait' param to be sure that completion event will be fired after move event
  onDragSelectionCompleteDebounced = debounce(
    this.onDragSelectionComplete,
    200,
  );

  /* Drag Selection Handlers end */

  createLabel = (label, index) => {
    const {
      labelOptions: { labelFontSize },
    } = this.state;

    const offsetY = getLabelOffsetY(label, index, labelFontSize);

    return (
      <Label
        key={this.getKey(label.id, index)}
        spaceId={label.spaceId}
        label={label}
        lineIndex={index}
        labelSize={labelFontSize}
        maxLabelChars={30}
        offsetY={offsetY}
      />
    );
  };

  addHeatmapOverlay = ({ data, max }) => {
    this.removeHeatmapOverlay();
    const heatmap = new HeatmapOverlay(this.context.viewer);

    const defaultMax = data.reduce((prev, next) =>
      Math.max(prev.value || prev, next.value),
    );

    heatmap.setData({
      max: max || defaultMax,
      data,
    });

    this.heatmapOverlay = heatmap;
  };

  removeHeatmapOverlay = () => {
    if (!this.heatmapOverlay) return;
    this.heatmapOverlay.destroy();
    this.heatmapOverlay = null;
  };

  render() {
    const {
      layers: { all, showOccupants },
      selectedSpace,
      zoomTilesShown,
      reservedAlwaysEnabled,
      svLivePersonLocationShape,
      teamReservationSelectedDesks,
      showLabels,
      labelOptions: {
        showSpaceNameLabels,
        showOccupantLabels,
        showSpaceTypeLabels,
      },
      floorMarkers,
      kioskLocation,
    } = this.state;

    const floorId = this.currentFloorId;

    let hasSpaces;

    if (
      !floorId ||
      // eslint-disable-next-line react/destructuring-assignment
      !this.props.space[floorId]
    ) {
      return (
        <div className="spinner-container">
          <div className="spinner" />
        </div>
      );
    }

    const {
      space: {
        [floorId]: {
          filteredSpaces: {
            zones,
            occupantPoints,
            nonOccupantPoints,
            rooms,
            occupantRoomPoints,
            special,
          },
          spaces,
        },
      },
    } = this.props;

    if (spaces && spaces.length !== 0) {
      hasSpaces = spaces.length > 0;
    }

    const floorLabels = getLabelsForSpaces({
      spaces,
      includeName: showSpaceNameLabels,
      includeOccupants: showOccupantLabels,
      includeSpaceType: showSpaceTypeLabels,
    });

    let selectedComponents;
    if (selectedSpace && selectedSpace.length > 0) {
      selectedComponents = this.renderedSelectedSpaces(selectedSpace);
    }

    const pointSizes = ThemeManager.getThemeObject('pointSizes');

    const teamReservationSelectedSpaces = spaces.filter((s) =>
      teamReservationSelectedDesks.includes(s.id),
    );

    const dragSelectionEnabled = this.isDragSelectionEnabled();

    const _showLabels = !this.props.hidePeopleData && showLabels;

    return hasSpaces ? (
      <FloorplanContainer
        floorId={floorId}
        ref={this.floorRef}
        hideZoomTiles={!zoomTilesShown}
        viewerPanEnabled={!dragSelectionEnabled}
      >
        <DragSelection
          active={dragSelectionEnabled}
          viewer={this.context.viewer}
          onDragMove={this.onDragSelectionMoveThrottled}
          onClick={this.onDragSelectionClick}
          onDragComplete={this.onDragSelectionCompleteDebounced}
        />
        <FloorplanShapes>
          {zones.map((space, index) => (
            <Shape
              key={this.getKey(space.id, index)}
              onShapeClicked={this.removeShapeSelected}
              shape={space.shapes[0]}
              space={space}
              styleFunction={this.extendedWallStyle}
            />
          ))}

          {!zoomTilesShown &&
            zones.map((space, index) => (
              <Shape
                key={this.getKey(space.id, index)}
                onShapeClicked={this.removeShapeSelected}
                shape={space.shapes[0]}
                space={space}
                styleFunction={this.basicWallStyle}
              />
            ))}

          {rooms.map((space, index) => (
            <Shape
              isPoint={false}
              key={this.getKey(space.id, index)}
              shape={space.shapes[0]}
              space={space}
              styleFunction={() => this.spaceStyle(space)}
              onShapeClicked={(event) =>
                space.allowInteraction && this.handleShapeClicked(event, space)
              }
            />
          ))}
        </FloorplanShapes>

        <FloorplanShapes>
          {occupantPoints &&
            occupantPoints.map((space, index) => (
              <Shape
                isPoint
                key={this.getKey(space.id, index)}
                shape={space.shape || space.shapes[0]}
                space={space}
                styleFunction={() =>
                  this.occupantPointStyle(space, space.shape || space.shapes[0])
                }
                pointSize={this.getPointSize({
                  isReserved: space.isReserved,
                  reservedAlwaysEnabled,
                  pointSizes,
                })}
                onShapeClicked={(event) =>
                  // showOccupants &&
                  space.allowInteraction &&
                  this.handleShapeClicked(event, space)
                }
              />
            ))}

          {occupantRoomPoints &&
            occupantRoomPoints.map((space, index) => (
              <Shape
                isPoint
                key={this.getKey(space.id, index)}
                shape={space.shape || space.shapes[0]}
                space={space}
                styleFunction={() =>
                  this.occupantPointStyle(space, space.shape || space.shapes[0])
                }
                pointSize={
                  showOccupants && !all
                    ? pointSizes.important
                    : pointSizes.unImportant
                }
                onShapeClicked={(event) =>
                  // showOccupants &&
                  space.allowInteraction &&
                  this.handleShapeClicked(event, space)
                }
              />
            ))}
        </FloorplanShapes>

        <FloorplanShapes>
          {nonOccupantPoints &&
            nonOccupantPoints.map((space, index) => (
              <Shape
                isPoint
                key={this.getKey(space.id, index)}
                shape={space.shapes[0]}
                space={space}
                styleFunction={() =>
                  this.nonOccupantPointStyle(space, space.shapes[0])
                }
                pointSize={pointSizes.important}
                onShapeClicked={(event) =>
                  // showDesks &&
                  space.allowInteraction &&
                  this.handleShapeClicked(event, space)
                }
              />
            ))}

          {special &&
            Object.keys(special).map((spaceType) =>
              special[spaceType].map((space) => (
                <Icon
                  space={this.getShapeFromID(space)}
                  type={parseInt(spaceType, 10)}
                  key={`icon-${space.id}`}
                />
              )),
            )}
          {svLivePersonLocationShape && (
            <FloorplanShapes>
              <Shape
                isPoint
                space={svLivePersonLocationShape}
                shape={svLivePersonLocationShape.shape}
                style={ThemeManager.getStyle('unAvailable', true)}
                pointSize={200}
              />
              <MarkerIcon space={svLivePersonLocationShape} />
            </FloorplanShapes>
          )}
        </FloorplanShapes>
        <FloorplanShapes>
          {teamReservationSelectedSpaces &&
            teamReservationSelectedSpaces.map((space, index) => (
              <Shape
                isPoint
                key={this.getKey(space.id, index)}
                shape={space.shapes[0]}
                space={space}
                styleFunction={() =>
                  ThemeManager.getStyle('teamReservationsSelected')
                }
                pointSize={pointSizes.important * 0.75}
              />
            ))}
          {floorMarkers.map((marker) => (
            <RemoteMarkerIcon marker={marker} key={marker.id} />
          ))}
          {kioskLocation && <HereMarker coords={kioskLocation} />}
        </FloorplanShapes>
        <FloorplanLabels showLabels={_showLabels}>
          {floorLabels.map((label) =>
            label.lines.map((_, index) => this.createLabel(label, index)),
          )}
        </FloorplanLabels>
        {selectedComponents}
        {this.renderDistanceMarker()}
      </FloorplanContainer>
    ) : (
      <div className="spinner-container">
        <div className="spinner" />
      </div>
    );
  }
}

Floorplan.propTypes = {
  space: PropTypes.shape(),
  actions: PropTypes.shape(),
  floorId: PropTypes.number,
  hideRecoveryPane: PropTypes.func,
  restrictToSearchFilterValue: PropTypes.string,
  t: PropTypes.func,
  hidePeopleData: PropTypes.bool,
};

Floorplan.defaultProps = {
  floorId: 0,
  space: {},
  actions: {},
  hideRecoveryPane: () => {},
  restrictToSearchFilterValue: '',
  t: () => {},
  hidePeopleData: false,
};

Floorplan.contextType = FESContext;

const mapStateToProps = (state) => ({
  space: state.floorplanComponent,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(allActions, dispatch),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, null),
)(Floorplan);
