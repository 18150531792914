import OpenSeadragon from 'openseadragon';

export const isPointInsideBox = (point, box) => {
  const boxRightMax = box.left + box.width;
  const boxBottomMax = box.top + box.height;
  return (
    point.x >= box.left &&
    point.x <= boxRightMax &&
    point.y >= box.top &&
    point.y <= boxBottomMax
  );
};

export const boxesIntersect = (boxA, boxB) =>
  boxA.left <= boxB.left + boxB.width &&
  boxA.left + boxA.width >= boxB.left &&
  boxA.top <= boxB.top + boxB.height &&
  boxA.top + boxA.height >= boxB.top;

export const calculateSelectionBox = ({ startPoint, endPoint }) => ({
  left: Math.min(startPoint.x, endPoint.x),
  top: Math.min(startPoint.y, endPoint.y),
  width: Math.abs(startPoint.x - endPoint.x),
  height: Math.abs(startPoint.y - endPoint.y),
});

export const generateWebPoint = ({ event, element }) => {
  const { left, top } = element.getBoundingClientRect();
  // eslint-disable-next-line no-nested-ternary
  const x = event.clientX
    ? event.clientX
    : event.nativeEvent
    ? event.nativeEvent.clientX
    : 0;
  // eslint-disable-next-line no-nested-ternary
  const y = event.clientY
    ? event.clientY
    : event.nativeEvent
    ? event.nativeEvent.clientY
    : 0;
  return new OpenSeadragon.Point(x - left, y - top);
};

export const generateImagePoint = ({ webPoint, viewer }) => {
  const viewportPoint = viewer.viewport.pointFromPixel(webPoint);
  return viewer.viewport.viewportToImageCoordinates(viewportPoint);
};

export const handleWheel = (event) => {
  if (typeof event.cancelable !== 'boolean' || event.cancelable) {
    event.preventDefault();
  }
};
