import * as Sentry from '@sentry/react';
import { ignoreLoggingErrorToSentry } from '@serraview/engage-shared';

// do not put 401 statuses here, this status will be ignored for any API Url
const blacklistAPIUrls = {
  'stored_files/*': [], // ignore all errors no matter the status
  'spaces/\\d+/bookings': [422, 404], // ignore only 404 and 422
  'people/\\d+': [422, 404], // ignore only 404 and 422
  'spaces/\\d+/book': [422], // ignore only 422
  'spaces/\\d+/bookings/\\d+': [501], // ignore only 501
  'spaces/\\d+': [404], // ignore only 404
};

export const initializeSentry = () => {
  if (__CONF__.buildType === 'production') {
    Sentry.init({
      release: `engage-web@${__CONF__.version}`,
      environment: __CONF__.buildType,
      dsn: __CONF__.sentryDsn,
      tracesSampleRate: 0,
      attachStacktrace: !__DEV__,
      beforeSend: (event, hint) => {
        if (event.level === 'error') {
          // even thought we log only errors to sentry let's double-check this by level type
          const error = hint?.originalException;
          if (ignoreLoggingErrorToSentry(error, blacklistAPIUrls)) return null;
        }
        return event;
      },
    });
  }
};

export const setSentryUserId = (id) => {
  if (__CONF__.buildType === 'production') {
    Sentry.setUser({ id });
  }
};

export const setSentryTenantId = (tenantId) => {
  if (__CONF__.buildType === 'production') {
    Sentry.setTag('tenantId', tenantId);
  }
};

export const captureSentryError = (error) => {
  if (__CONF__.buildType === 'production') {
    if (typeof error === 'string') {
      Sentry.captureMessage(error);
    } else {
      Sentry.captureException(error);
    }
  }
};
