var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDispatch, useSelector } from 'react-redux';
import { useBookingMutation as useSharedBookingMutation } from '@serraview/engage-shared/api';
import { RESERVATION_ANALYTICS_TYPE } from 'consts';
import { floorplanActions, tenantSelectors, userConfigSelectors } from 'store';
import { useConfig, useReservationNotification } from 'utils/hooks';
import { trackReservationAnalytics } from 'utils/analytics/useReserveAnalytics';
import { handleSocialDistancing } from 'api/mutations/reserve/helpers';
import { getLocalTimeZone } from 'utils';
import { useRefreshBookings, useRefreshSpace } from 'api/queries';
export var useBookingMutation = function (_a) {
    var spaceItem = _a.spaceItem, isDesk = _a.isDesk, user = _a.user, filter = _a.filter, reserveeId = _a.reserveeId, duration = _a.duration, allDayDuration = _a.allDayDuration, isUpdate = _a.isUpdate, summaryText = _a.summaryText, onSuccess = _a.onSuccess, onError = _a.onError;
    var dispatch = useDispatch();
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var isCleverSocialDistancingActive = useConfig({
        buildingId: spaceItem.buildingId,
    }).isCleverSocialDistancingActive;
    var showSocialDistancingInfo = useSelector(userConfigSelectors.getShowSocialDistancingInfo);
    var refreshSpace = useRefreshSpace().refresh;
    var refreshBookings = useRefreshBookings().refresh;
    var handleLocalNotification = useReservationNotification().handleLocalNotification;
    var email = user === null || user === void 0 ? void 0 : user.email;
    var bookingMutation = useSharedBookingMutation({
        tenantId: tenantId,
        email: email,
        localTimeZone: getLocalTimeZone(),
        summaryText: summaryText,
        filter: filter,
        spaceItem: spaceItem,
        reserveeId: reserveeId,
        duration: duration,
        allDayDuration: allDayDuration,
        isUpdate: isUpdate,
        successCallback: function (reservation) {
            trackReservationAnalytics({
                reservation: reservation,
                spaceItem: spaceItem,
                analyticsType: RESERVATION_ANALYTICS_TYPE.SUCCESS,
            });
            refreshSpace({ spaceItem: spaceItem });
            refreshBookings({ spaceItem: spaceItem });
            dispatch(floorplanActions.refreshSpace());
            if (isCleverSocialDistancingActive && isDesk) {
                handleSocialDistancing({
                    dispatch: dispatch,
                    spaceItem: spaceItem,
                    showSocialDistancingInfo: showSocialDistancingInfo,
                });
            }
            if (isDesk) {
                // add/update scheduled local notification
                handleLocalNotification({
                    event: __assign(__assign(__assign({}, spaceItem), reservation), { timeZone: reservation.startTimeZone }),
                    isUpdate: isUpdate,
                });
            }
        },
        errorCallback: function () {
            trackReservationAnalytics({
                spaceItem: spaceItem,
                analyticsType: RESERVATION_ANALYTICS_TYPE.FAIL,
            });
        },
    }, {
        onSuccess: onSuccess,
        onError: onError,
    });
    var saveBooking = bookingMutation.saveBooking;
    var onBooking = function () {
        trackReservationAnalytics({
            spaceItem: spaceItem,
            analyticsType: RESERVATION_ANALYTICS_TYPE.START,
        });
        saveBooking();
    };
    return __assign(__assign({}, bookingMutation), { onBooking: onBooking });
};
