import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { calculateTransform } from 'floorplan/utils/openseadragon';
import FloorplanViewerContext from 'floorplan/contexts/FloorplanViewerContext';
import {
  ANIMATION_EVENT,
  RESIZE_EVENT,
  ROTATE_EVENT,
} from 'floorplan/constants/OpenSeadragon';
import { withTranslation } from 'react-i18next';

class FloorplanShapes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transform: null,
    };
  }

  /* lifecycle hooks */

  componentDidMount() {
    const viewer = this.context;
    if (viewer) {
      this.initialiseViewer(viewer);
    }
  }

  componentDidUpdate() {
    const viewer = this.context;
    if (viewer && viewer !== this.viewer) {
      this.initialiseViewer(viewer);
    }
  }

  componentWillUnmount() {
    this.removeViewerHandlers();
  }

  oldViewer = null;

  parentRef = null;

  svgRef = null;

  dragging = false;

  /* getters / setters */

  setParentRef = (element) => {
    this.parentRef = element;
  };

  setSvgRef = (element) => {
    this.svgRef = element;
  };

  getTransformString = (transform) =>
    transform
      ? `translate(${transform.translateX}, ${transform.translateY}) ` +
        `scale(${transform.scale}) ` +
        `rotate(${transform.rotate})`
      : null;

  /* event handlers */

  handleAnimation = () => {
    const viewer = this.context;
    const transform = calculateTransform(viewer);
    this.setState({ transform });
  };

  /* helpers */

  initialiseViewer(viewer) {
    this.removeViewerHandlers();
    this.viewer = viewer;
    this.addViewerHandlers();
    this.handleAnimation();
  }

  addViewerHandlers() {
    if (this.viewer) {
      this.viewer.addHandler(ANIMATION_EVENT, this.handleAnimation);
      this.viewer.addHandler(RESIZE_EVENT, this.handleAnimation);
      this.viewer.addHandler(ROTATE_EVENT, this.handleAnimation);
      this.viewer.canvas.appendChild(this.svgRef);
    }
  }

  removeViewerHandlers() {
    if (this.viewer) {
      this.viewer.removeHandler(ANIMATION_EVENT, this.handleAnimation);
      this.viewer.removeHandler(RESIZE_EVENT, this.handleAnimation);
      this.viewer.removeHandler(ROTATE_EVENT, this.handleAnimation);
      this.parentRef.appendChild(this.svgRef);
    }
  }

  render() {
    const viewer = this.context;
    const { children, t } = this.props;
    const { transform } = this.state;
    const transformString = this.getTransformString(transform);

    return (
      <div ref={this.setParentRef}>
        <svg
          ref={this.setSvgRef}
          className="floorplan-shapes"
          aria-label={t('accessibilityLabels.floorplanShapes')}
        >
          {viewer && <g transform={transformString}>{children}</g>}
        </svg>
      </div>
    );
  }
}

export default withTranslation()(FloorplanShapes);

FloorplanShapes.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

FloorplanShapes.defaultProps = {
  children: null,
};

FloorplanShapes.contextType = FloorplanViewerContext;
