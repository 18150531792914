var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n  padding: 0 20px;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n  padding: 0 20px;\n  width: 100%;\n"])));
export var TextWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  margin-top: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  margin-top: ", ";\n"])), function (props) { return (props.isReserved ? '0px' : '20px'); });
export var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: baseline;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: baseline;\n"])));
export var CapacityWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 8px;\n"], ["\n  margin-left: 8px;\n"])));
export var StyledDescription = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n  opacity: 0.8;\n"], ["\n  font-size: 14px;\n  color: ", ";\n  opacity: 0.8;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color2;
});
export var StyledTitle = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 20px;\n  margin-top: 3px;\n  margin-bottom: 6px;\n  color: ", ";\n"], ["\n  font-size: 20px;\n  margin-top: 3px;\n  margin-bottom: 6px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color1;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
