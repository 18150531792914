var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useTranslation } from 'react-i18next';
import IconButton from 'components/base/IconButton/IconButton';
import React from 'react';
import { FocusId, testProps, useGenerateCurrentLocationPath } from 'utils';
import { useNavigate } from 'react-router-dom';
import { joinPaths } from 'router/utils';
import { PATH_SEGMENT } from 'consts';
export function FloorplanViewsIcon() {
    var t = useTranslation().t;
    var navigate = useNavigate();
    var currentLocationPath = useGenerateCurrentLocationPath();
    var toggleFloorplanViewsModal = function () {
        navigate(joinPaths(currentLocationPath, PATH_SEGMENT.FLOORPLAN_VIEWS));
    };
    return (React.createElement(IconButton, __assign({ id: FocusId.FloorplanViewsButton, icon: "floorplan-views", onPress: toggleFloorplanViewsModal }, testProps(t, 'accessibilityLabels.floorplanViewsButton').props)));
}
