import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'base-components';
import { HEIGHT as MEDIA_LINKS_HEIGHT } from './MediaLinks/styled';
import { Wrapper } from './styled';
import DetailsSkeleton from './Details/DetailsSkeleton';
var PersonCardSkeleton = function () { return (React.createElement(SkeletonTheme, { style: { width: '100%' } },
    React.createElement(Wrapper, null,
        React.createElement(DetailsSkeleton, null),
        React.createElement(Skeleton, { height: MEDIA_LINKS_HEIGHT })))); };
export default PersonCardSkeleton;
