var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
export var Button = function (_a) {
    var children = _a.children, onClick = _a.onClick, disabled = _a.disabled, restProps = __rest(_a, ["children", "onClick", "disabled"]);
    var onClickHandler = function (e) {
        if (disabled)
            return;
        onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    var onKeyPress = function (e) {
        var enterOrSpace = e.key === 'Enter' ||
            e.key === ' ' ||
            e.key === 'Spacebar' ||
            e.which === 13 ||
            e.which === 32;
        if (enterOrSpace) {
            e.preventDefault();
            onClickHandler(e);
        }
    };
    return (React.createElement("div", __assign({ tabIndex: 0, role: "button", "aria-disabled": disabled, onClick: onClickHandler, onKeyPress: onKeyPress }, restProps), children));
};
