import React, { memo } from 'react';
import PropTypes from 'prop-types';

function NoCopy({ children, disableContextMenu, disableSelection }) {
  const copyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const menuHandler = (e) => {
    if (disableContextMenu) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <div
      className={disableSelection ? 'no_select' : ''}
      onCopy={copyHandler}
      onContextMenu={menuHandler}
    >
      {children}
    </div>
  );
}

NoCopy.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disableContextMenu: PropTypes.bool,
  disableSelection: PropTypes.bool,
};
NoCopy.defaultProps = {
  disableContextMenu: false,
  disableSelection: false,
};

export default memo(NoCopy);
