import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonTheme } from 'base-components';
import { DetailsWrapper, IconWrapper, Wrapper } from './styled';
var AttendeeSkeleton = function () { return (React.createElement(SkeletonTheme, { style: { width: '100%' } },
    React.createElement(Wrapper, null,
        React.createElement(Skeleton, { circle: true, height: 36, width: 36 }),
        React.createElement(DetailsWrapper, null,
            React.createElement("h4", null,
                React.createElement(Skeleton, { width: 200 })),
            React.createElement("h6", null,
                React.createElement(Skeleton, { width: 200 }))),
        React.createElement(IconWrapper, null,
            React.createElement(Skeleton, { circle: true, height: 18, width: 18 }))))); };
export default AttendeeSkeleton;
