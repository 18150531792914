import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions, filtersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { RESTRICT_TO_VALUES } from 'consts';
import { NumberInputCounter, SelectButton, GlobalSearchClear, } from 'base-components';
import { useCloseFilters, useReservationFilters, useRestrictTo, } from 'utils/hooks';
import DateInterval from 'components/layouts/ReservationAssist/DateInterval/DateInterval';
import AssetsAndTypesFilter from 'components/layouts/ReservationAssist/TypesAndAttributesFilter/TypesAndAttributesFilter';
import { countAppliedSpaceFilters } from '@serraview/engage-shared';
import Analytics from 'utils/analytics';
import { focusElementById, FocusId } from 'utils';
import { SearchFilterHeader, SearchFilterInputGroup, SearchFilterInputsWrapper, SearchFilterWrapper, Wrapper, } from './styled';
var SearchFilter = function () {
    // indicator that specifies whether draft state was synchronized or not
    var _a = useState(false), synchronized = _a[0], setSynchronized = _a[1];
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var closeFilters = useCloseFilters();
    var restrictTo = useRestrictTo();
    var enforceAllDayDeskReservations = useReservationFilters(restrictTo === RESTRICT_TO_VALUES.DESK).enforceAllDayDeskReservations;
    var draftFilters = useSelector(filtersSelectors.getDraftFilter);
    var capacity = draftFilters.capacity;
    var filtersChanged = countAppliedSpaceFilters(draftFilters) > 0;
    var isDesk = restrictTo === RESTRICT_TO_VALUES.DESK;
    var isSpace = restrictTo === RESTRICT_TO_VALUES.SPACE;
    // synchronize
    useEffect(function () {
        dispatch(filtersActions.synchronizeFiltersDraft());
        setSynchronized(true);
    }, [dispatch]);
    var setCapacity = function (capacity) {
        return dispatch(filtersActions.setFilterValues({ capacity: capacity, isDraft: true }));
    };
    var getComponentVisibility = function (searchType, enforceAllDayDeskReservations) { return ({
        showCapacity: isSpace,
        showTypes: isSpace || isDesk,
        showAllDaySwitch: isSpace ? false : !enforceAllDayDeskReservations,
    }); };
    var _b = getComponentVisibility(restrictTo, enforceAllDayDeskReservations), showCapacity = _b.showCapacity, showTypes = _b.showTypes;
    var onShowClick = function () {
        dispatch(filtersActions.saveFiltersDraft());
        closeFilters();
    };
    var buttonText = t('layout.searchFilter.apply');
    var onClearPress = useCallback(function () {
        dispatch(filtersActions.clearDraftFilterValues());
        focusElementById(FocusId.SearchFiltersApplyButton);
    }, [dispatch]);
    useEffect(function () {
        if (filtersChanged) {
            if (isDesk) {
                Analytics.track('EVT-069', draftFilters);
            }
            else if (isSpace) {
                Analytics.track('EVT-065', draftFilters);
            }
        }
        return function () {
            if (isDesk) {
                Analytics.track('EVT-067');
            }
            else if (isSpace) {
                Analytics.track('EVT-063');
            }
        };
    }, [filtersChanged, draftFilters, isDesk, isSpace]);
    // don't render content until draft state is synchronized with real one
    if (!synchronized) {
        return null;
    }
    return (React.createElement(Wrapper, { "data-testid": "SearchFilterComponent" },
        React.createElement(SearchFilterWrapper, null,
            React.createElement(SearchFilterHeader, null, t('layout.searchFilter.duration')),
            React.createElement(SearchFilterInputsWrapper, null,
                React.createElement(DateInterval, null),
                showCapacity && (React.createElement(SearchFilterInputGroup, null,
                    React.createElement(NumberInputCounter, { value: capacity, onChange: setCapacity, minValue: 1, label: t('layout.searchFilter.capacity') }))),
                showTypes && React.createElement(AssetsAndTypesFilter, { isDraft: true }))),
        React.createElement(GlobalSearchClear, { isFilterChanged: filtersChanged, onPress: onClearPress }),
        React.createElement(SelectButton, { id: FocusId.SearchFiltersApplyButton, "data-testid": "showButton", onClick: onShowClick }, buttonText)));
};
export default memo(SearchFilter);
