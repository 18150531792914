var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Button } from 'components/base';
export var LIST_ITEM_PADDING_VERTICAL = 16;
export var LIST_WIDTH = 400;
export var GLOBAL_SEARCH_COLLAPSED_HEIGHT = 130;
export var DESK_LIST_HEIGHT = {
    DEFAULT: 280,
    '570': 370,
    '700': 500,
    '900': 600,
};
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 10px 22px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  margin: 10px 22px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n"])));
export var WrapperFavourites = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return props.$style || {}; });
export var ListItemOuterWrapper = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  padding: 0 20px;\n  cursor: pointer;\n  width: 100%;\n"], ["\n  ", "\n  padding: 0 20px;\n  cursor: pointer;\n  width: 100%;\n"])), function (props) { return props.$style || {}; });
export var ListItemWrapper = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  direction: ltr;\n  padding: ", "px 4px;\n  border-bottom: 1px solid #edeff0;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"], ["\n  display: flex;\n  direction: ltr;\n  padding: ", "px 4px;\n  border-bottom: 1px solid #edeff0;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  text-align: start;\n  &.focus-visible {\n    outline: 1px auto Highlight;\n    outline: 1px auto -webkit-focus-ring-color;\n  }\n"])), LIST_ITEM_PADDING_VERTICAL);
export var TextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  padding-left: 20px;\n  width: 80%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  padding-left: 20px;\n  width: 80%;\n"])));
export var Title = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 16px;\n  margin-top: 3px;\n  margin-bottom: 5px;\n"], ["\n  font-size: 16px;\n  margin-top: 3px;\n  margin-bottom: 5px;\n"])));
export var SubtitleWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  color: ", ";\n  opacity: 0.7;\n"], ["\n  display: flex;\n  width: 100%;\n  color: ", ";\n  opacity: 0.7;\n"])), function (props) { return props.theme.typo2; });
export var Subtitle = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 700;\n  span {\n    font-weight: normal;\n    margin-left: 6px;\n  }\n"], ["\n  color: ", ";\n  font-weight: 700;\n  span {\n    font-weight: normal;\n    margin-left: 6px;\n  }\n"])), function (props) { return props.theme.typo2; });
export var IconWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding-right: 30px;\n  padding-left: 10px;\n  color: ", ";\n"], ["\n  padding-right: 30px;\n  padding-left: 10px;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
export var IconWrapperFavourites = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  padding: 10px;\n  right: 30px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  line-height: 0;\n"], ["\n  color: ", ";\n  padding: 10px;\n  right: 30px;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  line-height: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color9;
});
export var GlobalSearchWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: ", "px;\n  width: 100%;\n\n  @media screen and (min-height: 570px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 700px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 900px) {\n    height: ", "px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: ", "px;\n  width: 100%;\n\n  @media screen and (min-height: 570px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 700px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 900px) {\n    height: ", "px;\n  }\n"])), function (_a) {
    var showResults = _a.showResults;
    return showResults ? DESK_LIST_HEIGHT.DEFAULT : GLOBAL_SEARCH_COLLAPSED_HEIGHT;
}, function (_a) {
    var showResults = _a.showResults;
    return showResults ? DESK_LIST_HEIGHT['570'] : GLOBAL_SEARCH_COLLAPSED_HEIGHT;
}, function (_a) {
    var showResults = _a.showResults;
    return showResults ? DESK_LIST_HEIGHT['700'] : GLOBAL_SEARCH_COLLAPSED_HEIGHT;
}, function (_a) {
    var showResults = _a.showResults;
    return showResults ? DESK_LIST_HEIGHT['900'] : GLOBAL_SEARCH_COLLAPSED_HEIGHT;
});
var adjustGlobalSearchWrapperHeight = function (_a) {
    var parentHeight = _a.parentHeight, globalSearchFiltersHeight = _a.globalSearchFiltersHeight;
    return parentHeight - globalSearchFiltersHeight;
};
export var GlobalSearchOuterWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  height: ", "px;\n  width: 100%;\n  overflow: hidden;\n\n  @media screen and (min-height: 570px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 700px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 900px) {\n    height: ", "px;\n  }\n"], ["\n  display: flex;\n  height: ", "px;\n  width: 100%;\n  overflow: hidden;\n\n  @media screen and (min-height: 570px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 700px) {\n    height: ", "px;\n  }\n\n  @media screen and (min-height: 900px) {\n    height: ", "px;\n  }\n"])), DESK_LIST_HEIGHT.DEFAULT, function (_a) {
    var $isTeamFilter = _a.$isTeamFilter;
    return adjustGlobalSearchWrapperHeight({
        parentHeight: DESK_LIST_HEIGHT['570'],
        globalSearchFiltersHeight: $isTeamFilter ? 160 : 200,
    });
}, function (_a) {
    var $isTeamFilter = _a.$isTeamFilter;
    return adjustGlobalSearchWrapperHeight({
        parentHeight: DESK_LIST_HEIGHT['700'],
        globalSearchFiltersHeight: $isTeamFilter ? 160 : 200,
    });
}, function (_a) {
    var $isTeamFilter = _a.$isTeamFilter;
    return adjustGlobalSearchWrapperHeight({
        parentHeight: DESK_LIST_HEIGHT['900'],
        globalSearchFiltersHeight: $isTeamFilter ? 160 : 200,
    });
});
export var GlobalSearchResultsWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  flex: 1 1 auto;\n"], ["\n  flex: 1 1 auto;\n"])));
export var GlobalSearchNoData = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 50px;\n  font-size: 15px;\n  width: ", "px;\n\n  p {\n    font-size: inherit;\n    overflow-wrap: normal;\n    padding: 10px 30px;\n    text-align: center;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 50px;\n  font-size: 15px;\n  width: ", "px;\n\n  p {\n    font-size: inherit;\n    overflow-wrap: normal;\n    padding: 10px 30px;\n    text-align: center;\n  }\n"])), LIST_WIDTH);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
