import PropTypes from 'prop-types';
import { eventPropTypes } from 'utils/models/event';

const agendaEventPropTypes = {
  ...eventPropTypes,
  isLocalTimeZone: PropTypes.bool,
  timeZone: PropTypes.string,
  parent: PropTypes.string,
  buildingName: PropTypes.string,
};

Object.defineProperty(agendaEventPropTypes, 'defaultProps', {
  value: {
    ...agendaEventPropTypes.defaultProps,
    isLocalTimeZone: false,
    timeZone: null,
    parent: null,
    buildingName: null,
  },
});

export { agendaEventPropTypes };
