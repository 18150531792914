var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { testProps } from 'utils';
import { useTranslation } from 'react-i18next';
import { Container, WorkspaceInput, Domain } from './styled';
var TenantInput = function (_a) {
    var onChange = _a.onChange, value = _a.value;
    var _b = React.useState(false), hasFocus = _b[0], setHasFocus = _b[1];
    var onFocus = function () { return setHasFocus(true); };
    var onBlur = function () { return setHasFocus(false); };
    var t = useTranslation().t;
    var _c = testProps(t, 'scenes.tenant.workspaceUrlPlaceholder'), a11yAndTestProps = _c.props, placeholder = _c.text[0];
    return (React.createElement(Container, { hasFocus: hasFocus },
        React.createElement(WorkspaceInput, __assign({}, a11yAndTestProps, { placeholder: placeholder, onChange: onChange, value: value, onFocus: onFocus, onBlur: onBlur, autoComplete: "off", spellCheck: false })),
        React.createElement(Domain, null, ".serraview.com")));
};
export { TenantInput };
