import React from 'react';
import styled from 'styled-components';
import { Z_INDEX } from 'consts';

const CornerGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: ${Z_INDEX.GRADIENT};
  background: linear-gradient(
    ${(p) => p.rotation}deg,
    rgba(244, 247, 249, 1) 0%,
    rgba(255, 255, 255, 0) ${(p) => p.spread}%
  );
  background-blend-mode: screen;
`;

// eslint-disable-next-line react/prop-types
export default ({ rotation = 130, spread = 40 }) => (
  <CornerGradient rotation={rotation} spread={spread} />
);
