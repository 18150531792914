import { useEffect } from 'react';
export function useOnClickOutside(_a) {
    var ref = _a.ref, handler = _a.handler, _b = _a.disallowList, disallowList = _b === void 0 ? [''] : _b;
    useEffect(function () {
        var listener = function (event) {
            // Do nothing if clicking ref's element or descendent elements
            // @ts-ignore
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            var targetInDisallowList = disallowList.some(function (item) {
                var elem = document.getElementById(item);
                return elem === null || elem === void 0 ? void 0 : elem.contains(event.target);
            });
            if (targetInDisallowList) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, disallowList]);
}
