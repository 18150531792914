import { getShapeCoords } from './shapeUtils';

export const getCoordinateString = (shape) =>
  shape.coordinates
    .map((coordinate) => `${coordinate.x},${coordinate.y}`)
    .join(' ');

export const getPathString = (coordinates) =>
  `M ${coordinates
    .map((coordinate) => `${coordinate.x} ${coordinate.y}`)
    .join(' L ')}`;

export const getTransformString = ({ space, width, height, scale }) => {
  let coordinates = getShapeCoords(space);

  if (!coordinates) {
    coordinates = { x: 0, y: 0 };
  }

  return getTransformForCoordinates({ width, height, scale, coordinates });
};

export const getTransformForCoordinates = ({
  width = 0,
  height = 0,
  scale = 1,
  coordinates,
}) => ({
  transform: `translate(${coordinates.x - width}px, ${
    coordinates.y - (height || width)
  }px) scale(${scale})`,
});

export const getRotationString = ({ width, height, rotation }) => ({
  transformOrigin: `${width}px ${height || width}px`,
  transform: `rotate(${rotation}deg)`,
});
