import { useCallback, useEffect } from 'react';
export var ESC_KEY_CODE = 'Escape';
export var useEscapeListener = function (_a) {
    var onEscapePress = _a.onEscapePress;
    var onKeyPress = useCallback(function (event) {
        if (event.code === ESC_KEY_CODE) {
            onEscapePress();
        }
    }, [onEscapePress]);
    useEffect(function () {
        document.addEventListener('keydown', onKeyPress, false);
        return function () {
            document.removeEventListener('keydown', onKeyPress, false);
        };
    }, [onKeyPress]);
};
