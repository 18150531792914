var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #ffffff;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 30px;\n"], ["\n  background-color: #ffffff;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 30px;\n"])));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  > * {\n    flex-basis: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  > * {\n    flex-basis: 100%;\n  }\n"])));
export var Left = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var Center = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  & .title {\n    color: ", ";\n    font-weight: 600;\n    font-size: 15px;\n    line-height: 24px;\n    margin-bottom: 0;\n    margin-top: 0;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  & .title {\n    color: ", ";\n    font-weight: 600;\n    font-size: 15px;\n    line-height: 24px;\n    margin-bottom: 0;\n    margin-top: 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.typo2;
});
export var Right = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
export var Options = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1 2 auto;\n  align-self: auto;\n  justify-content: center;\n  align-items: stretch;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex: 1 2 auto;\n  align-self: auto;\n  justify-content: center;\n  align-items: stretch;\n  flex-direction: column;\n"])));
export var Row = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 1em 0;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 1em 0;\n"])));
export var Option = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0 1em;\n"], ["\n  margin: 0 1em;\n"])));
export var Label = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 16px;\n  line-height: 22px;\n"], ["\n  color: ", ";\n  font-size: 16px;\n  line-height: 22px;\n"])), function (props) { return props.theme.typo2; });
export var Footer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex: 0 1 auto;\n  align-self: auto;\n  justify-content: space-between;\n  margin: 15px 0;\n"], ["\n  display: flex;\n  flex: 0 1 auto;\n  align-self: auto;\n  justify-content: space-between;\n  margin: 15px 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
