var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var DetailsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 10px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 10px;\n"])));
var DetailsText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", "px;\n  font-weight: 600;\n  color: ", ";\n  opacity: ", ";\n"], ["\n  font-size: ", "px;\n  font-weight: 600;\n  color: ", ";\n  opacity: ", ";\n"])), function (p) { return p.$fontSize; }, function (_a) {
    var theme = _a.theme;
    return theme.typo2;
}, function (p) { return p.$opacity; });
export { DetailsContainer, DetailsText };
var templateObject_1, templateObject_2;
