var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { ListItemOuterWrapper, ListItemWrapper, } from 'components/layouts/GlobalSearch/styled';
export var ContentWrapper = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  max-height: 400px;\n  overlflow-y: auto;\n  overflow-x: hidden;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"], ["\n  display: flex;\n  flex-direction: column;\n  max-height: 400px;\n  overlflow-y: auto;\n  overflow-x: hidden;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"])));
export var ItemWrapper = styled(ListItemOuterWrapper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: default;\n"], ["\n  cursor: default;\n"])));
export var TextWrapper = styled(ListItemWrapper)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3;
