var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tenantSelectors, uiActions, uiSelectors } from 'store';
import { getInfoCardTitle, useSetPageTitle } from 'utils/hooks';
import { getBuildingAndFloorNames } from 'utils/helpers';
import { DrawerMenu, } from 'components/scenes/drawer/header/DrawerMenu/DrawerMenu';
import { testProps } from 'utils';
import { useDrawerContext } from 'scenes';
import { isElectron } from 'utils/electron';
import { CloseButton } from './CloseButton';
import { MenuButton } from './MenuButton';
import { Title } from './Title';
import { ButtonsWrapper, StyledSubtitleButton, TitleFirstRowAndIconWrapper, TitleFirstRowWrapper, TitleSecondRow, Wrapper, } from './styled';
// TODO show person location if it's a team member
var DrawerHeader = function (_a) {
    var _b = _a.title, title = _b === void 0 ? true : _b, _c = _a.subtitle, subtitle = _c === void 0 ? true : _c, _d = _a.icon, icon = _d === void 0 ? null : _d, isCloseIcon = _a.isCloseIcon, closeLinkPath = _a.closeLinkPath, onPress = _a.onPress, _e = _a.hasButton, hasButton = _e === void 0 ? true : _e, menu = _a.menu, closeIconA11yLabel = _a.closeIconA11yLabel, renderSubtitle = _a.renderSubtitle;
    // hide More menu button for kiosks
    var isKiosk = isElectron();
    var isMoreMenuVisible = useSelector(uiSelectors.getDrawerMenuVisibility) && !isKiosk;
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var _f = useDrawerContext(), setMinimized = _f.setMinimized, minimized = _f.minimized;
    var _g = useSelector(tenantSelectors.getCurrentLocation), building = _g.building, floor = _g.floor;
    var _h = getBuildingAndFloorNames(building, floor, t), defaultTitle = _h.buildingName, defaultSubtitle = _h.floorName;
    var _title = typeof title === 'string' ? title : defaultTitle;
    var _subtitle = typeof subtitle === 'string' ? subtitle : defaultSubtitle;
    var onCloseButtonClick = function (e) {
        if (isMoreMenuVisible) {
            e.preventDefault();
            dispatch(uiActions.setDrawerMenuVisibility(false));
            return;
        }
        if (minimized) {
            setMinimized(false);
        }
        onPress === null || onPress === void 0 ? void 0 : onPress(e);
    };
    var getMenuButton = function () {
        return menu && !isKiosk ? React.createElement(MenuButton, { isMenuVisible: isMoreMenuVisible }) : null;
    };
    var getCloseButton = function () { return (React.createElement(CloseButton, { isCloseIcon: isCloseIcon, closeLinkPath: closeLinkPath, onPress: onCloseButtonClick, isMoreMenuVisible: isMoreMenuVisible, closeIconA11yLabel: closeIconA11yLabel })); };
    useSetPageTitle(getInfoCardTitle({ title: _title, subtitle: _subtitle }));
    return (React.createElement(Wrapper, null,
        React.createElement("h2", null,
            React.createElement(TitleFirstRowWrapper, null,
                React.createElement(TitleFirstRowAndIconWrapper, { "data-testid": "info-card-title" },
                    icon,
                    React.createElement(Title, null, !!title && _title)),
                React.createElement(ButtonsWrapper, null,
                    menu && React.createElement("span", { className: "button-wrapper" }, getMenuButton()),
                    hasButton && (React.createElement("span", { className: "button-wrapper" }, getCloseButton())))),
            React.createElement(StyledSubtitleButton, __assign({}, testProps(t, 'accessibilityLabels.buildingGeoLocation').props), renderSubtitle ? (renderSubtitle(_subtitle)) : (React.createElement(TitleSecondRow, null, !!subtitle && _subtitle)))),
        menu && React.createElement(DrawerMenu, { menu: menu })));
};
export default memo(DrawerHeader);
