import { useMutation } from 'react-query';
import { logon } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions, tenantSelectors } from 'store';
import { useConfig } from 'utils/hooks';
import { TokenManager } from '@serraview/engage-shared/api';

const useLogon = (options = {}) => {
  const dispatch = useDispatch();
  const { instanceTitle, logo } = useConfig();
  const tenantId = useSelector(tenantSelectors.getTenantId);

  return useMutation(logon, {
    onSuccess: async (data, variables) => {
      const { token, refreshToken } = data;
      dispatch(
        tenantActions.addTenant({
          id: tenantId,
          name: instanceTitle,
          logo,
        }),
      );
      await TokenManager.setTokens({
        token,
        refreshToken,
      });
      dispatch(tenantActions.setUser(TokenManager.getUserIdFromToken(token)));
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
    onError: options?.onError,
  });
};

export { useLogon };
