var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useReducer, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPerson, fetchSpace } from '@serraview/engage-shared/api';
import { navigationActions, navigationSelectors, tenantSelectors } from 'store';
import logger from 'utils/logger/logger';
import { useAppLocation } from 'router/hooks/useAppLocation';
import { WAYFINDER_PATH } from 'consts';
import { hasTokens } from 'utils/config/tokenConfig';
import { deepLinkingReducer, setDeepLinkData } from './reducer';
import { getDeepLinkSearchParams, getDeepLinkString, getEmployeePath, getIsChecking, getPersonPath, getSpaceOrDeskPath, } from './utils';
export var useDeepLinking = function () {
    var _a = useAppLocation(), search = _a.search, pathname = _a.pathname;
    var reduxDispatch = useDispatch();
    var tenantId = useSelector(tenantSelectors.getTenantId);
    var deepLinkPath = useSelector(navigationSelectors.getDeepLinkPath);
    var deepLinkSearch = useSelector(navigationSelectors.getDeepLinkQueryString);
    var _b = useReducer(deepLinkingReducer, {
        isChecking: getIsChecking(deepLinkSearch, deepLinkPath, search),
        to: null,
    }), deepLinkState = _b[0], dispatch = _b[1];
    var _c = getDeepLinkSearchParams(deepLinkSearch), spaceId = _c.spaceId, deskId = _c.deskId, bookingSpaceId = _c.bookingSpaceId, employeeId = _c.employeeId, personId = _c.personId, teamBookingId = _c.teamBookingId, date = _c.date, time = _c.time;
    var spaceTypeId = spaceId || deskId || bookingSpaceId;
    var hasDeskOrSpaceId = !!(spaceId || deskId);
    var hasSpaceOrDeskDeepLink = !!spaceTypeId;
    var setDeepLinkString = useCallback(function (path, search) {
        dispatch(setDeepLinkData({
            isChecking: false,
            to: getDeepLinkString(path, search),
        }));
    }, [dispatch]);
    var checkSpaceDeepLink = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // prevent setting final deep link based on search query if deepLinkPath exists
                    if (deepLinkPath)
                        return [2 /*return*/];
                    data = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchSpace({
                            id: spaceTypeId,
                        })];
                case 2:
                    data = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    logger.error(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    setDeepLinkString(getSpaceOrDeskPath(data, hasDeskOrSpaceId, !!deskId), deepLinkSearch);
                    return [2 /*return*/];
            }
        });
    }); }, [
        setDeepLinkString,
        hasDeskOrSpaceId,
        deskId,
        deepLinkSearch,
        spaceTypeId,
        deepLinkPath,
    ]);
    var checkPersonDeepLink = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // prevent setting final deep link based on search query if deepLinkPath exists
                    if (deepLinkPath)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchPerson({ id: +personId })];
                case 2:
                    data = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    logger.error(e_2);
                    return [3 /*break*/, 4];
                case 4:
                    setDeepLinkString(getPersonPath(data), deepLinkSearch);
                    return [2 /*return*/];
            }
        });
    }); }, [personId, setDeepLinkString, deepLinkPath, deepLinkSearch]);
    var setEmployeeDeepLink = useCallback(function () {
        setDeepLinkString(getEmployeePath(employeeId), deepLinkSearch);
    }, [employeeId, setDeepLinkString, deepLinkSearch]);
    var setNonDrawerDeepLink = useCallback(function () {
        setDeepLinkString(deepLinkPath !== null && deepLinkPath !== void 0 ? deepLinkPath : WAYFINDER_PATH, deepLinkSearch);
    }, [setDeepLinkString, deepLinkPath, deepLinkSearch]);
    var setNewDeepLink = useCallback(function () {
        setDeepLinkString(deepLinkPath, deepLinkSearch);
    }, [setDeepLinkString, deepLinkPath, deepLinkSearch]);
    var clearDeepLink = function () {
        reduxDispatch(navigationActions.clearDeepLinkQueryString());
        reduxDispatch(navigationActions.clearDeepLinkPath());
        dispatch(setDeepLinkData({ to: null, isChecking: false }));
    };
    var isAuthenticated = hasTokens();
    // set old deep link based on search query
    useEffect(function () {
        if (!tenantId || !isAuthenticated)
            return;
        if (hasSpaceOrDeskDeepLink) {
            checkSpaceDeepLink();
        }
        else if (personId) {
            checkPersonDeepLink();
        }
        else if (employeeId) {
            setEmployeeDeepLink();
        }
        else if (date || time || teamBookingId) {
            setNonDrawerDeepLink();
        }
    }, [
        teamBookingId,
        employeeId,
        personId,
        date,
        time,
        tenantId,
        checkSpaceDeepLink,
        setEmployeeDeepLink,
        setNonDrawerDeepLink,
        hasSpaceOrDeskDeepLink,
        checkPersonDeepLink,
        isAuthenticated,
        pathname,
    ]);
    // set new deep link based on pathname and search query
    useEffect(function () {
        if (deepLinkPath) {
            setNewDeepLink();
        }
    }, [setNewDeepLink, setDeepLinkString, deepLinkPath]);
    return __assign(__assign({}, deepLinkState), { clearDeepLink: clearDeepLink });
};
